import { Navigate } from 'react-router-dom';

import PublishingPage from '../../pages/publishing/PublishingPage';
import HomePageTabContainer from '../../pages/publishing/HomePageTab/HomePageTabContainer';
import { MainImageAddFormContainer } from '../../pages/publishing/HomePageTab/mainImageForm';
import ArticlesTabContainer from '../../pages/publishing/ArticlesTab/ArticlesTabContainer';
import DidYouKnowTabContainer from '../../pages/publishing/DidYouKnowTab/DidYouKnowTabContainer';
import AddNewFactFormContainer from '../../pages/publishing/DidYouKnowTab/forms/AddNewFactFormContainer';
import ArticlesAddFormContainer from '../../pages/publishing/ArticlesTab/articlesForms/ArticlesAddFormContainer';
import HelpTopicsTab from '../../pages/publishing/HelpTopicsTab/HelpTopicsTab';
import AddHelpTopicFormContainer from '../../pages/publishing/HelpTopicsTab/helpTopicsForms/AddHelpTopicFormContainer';
import AdvertisementTab from '../../pages/publishing/AdvertisementTab/AdvertisementTab';
import { AddAdvertContentFormContainer } from '../../pages/publishing/AdvertisementTab/forms';

import { ADMIN, SYS_ADMIN } from '../consts';

export const publishingPageRoutes = [
  {
    path: '/publishing',
    element: <PublishingPage />,
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      { 
        index: true, 
        element: <Navigate to="advertisement" replace />, 
        showForRoles: [ADMIN, SYS_ADMIN],
      },
      {
        path: 'home-page',
        children: [
          {
            index: true,
            element: <HomePageTabContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'new-image',
            element: <MainImageAddFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
      {
        path: 'articles',
        children: [
          {
            index: true,
            element: <ArticlesTabContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'new-article',
            element: <ArticlesAddFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
      {
        path: 'did-you-know',
        children: [
          {
            index: true,
            element: <DidYouKnowTabContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'new-fact',
            element: <AddNewFactFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
      {
        path: 'help-topics',
        children: [
          {
            index: true,
            element: <HelpTopicsTab />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'new-topic',
            element: <AddHelpTopicFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
      {
        path: 'advertisement',
        children: [
          {
            index: true,
            element: <AdvertisementTab />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'new-advertisement',
            element: <AddAdvertContentFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
    ],
  },
];
