import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Box } from '@mui/material';

import PriceList from './PriceList';
import { PriceEditFormContainer } from './forms';

import {
  LoaderWrapper,
  PricesTabContainer,
  PriceAddButton,
  PricesTabHeader,
  PricesTabTitleWrapper,
} from './styled';
import { useNavigate } from 'react-router-dom';

const Prices = (props) => {
  const {
    appState,
    priceState,
    settingsState,
    attractionsState,
    getPricesListThunk,
    getCurrentPriceThunk,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showEditPriceForm, setShowEditPriceForm] = useState(false);

  const handlePriceAdd = () => {
    navigate('new-price');
  };

  useEffect(() => {
    getPricesListThunk(attractionsState.currentAttraction.id);
  }, [attractionsState.currentAttraction.id, getPricesListThunk]);

  if (!priceState.isLoadingList) {
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  }

  return (
    <PricesTabContainer>
      {!showEditPriceForm && (
        <>
          <PricesTabHeader>
            <PricesTabTitleWrapper>
              {attractionsState.currentAttraction.name}
            </PricesTabTitleWrapper>
            <Box mt="30px" mb="10px">
              <PriceAddButton onClick={handlePriceAdd} variant="contained">
                {t('attractions.price.add_button')}
              </PriceAddButton>
            </Box>
          </PricesTabHeader>
          <PriceList
            priceState={priceState}
            setShowPriceField={setShowEditPriceForm}
            getCurrentPriceThunk={getCurrentPriceThunk}
          />
        </>
      )}
      {showEditPriceForm && (
        <PriceEditFormContainer
          attractionData={attractionsState.currentAttraction}
          currentVat={settingsState.destinationData.filter(
            (item) => item.id === appState.selectedDestination.id
          )}
          setShowPriceForm={setShowEditPriceForm}
        />
      )}
    </PricesTabContainer>
  );
};

export default Prices;
