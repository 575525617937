import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  DatePickerContainer,
  FilterFormContainer,
  LogFormAutocompleteField,
} from '../styled';

import { DatePickerField } from './formFIelds/DatePickerField';
import SelectField from './formFIelds/SelectField';

const useStyles = makeStyles({
  button: {
    '&.MuiButton-root': {
      fontSize: '12px',
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&.Mui-selected': {
        borderRadius: '5px',
      },
    },
  },
  wrapper: {
    '& .MuiOutlinedInput-input': {
      color: '#000',
      '&::placeholder': {
        color: '#000',
      },
    },
  },
  clearButton: {
    position: 'relative',
    '&:hover': {
      backgroundColor: '#d9d9db',
    },
    backgroundColor: '#c5c5c7',
    height: '36px',
    width: '36px',
  },
});

const getAttractionOptionLabel = (option) => option.name;
const getUserOptionLabel = (option) => option.email;

const LogsFilterForm = (props) => {
  const {
    formMethods,
    attractionOptions,
    userOptions,
    logSectionOptions,
    logTypeOptions,
    selectedLanguage,
  } = props;
  const { reset } = formMethods;

  const classes = useStyles();
  const { t } = useTranslation();

  const handleClearButtonClick = () => reset();

  return (
    <FilterFormContainer>
      <FormProvider {...formMethods}>
        <LogFormAutocompleteField
          name="attraction"
          options={attractionOptions}
          getOptionLabel={getAttractionOptionLabel}
          label={t('logs.attraction')}
        />
        <SelectField
          name="section"
          options={logSectionOptions?.parameters}
          selectedLanguage={selectedLanguage}
          defaultOptionLabel="logs.section"
        />
        <SelectField
          name="type"
          options={logTypeOptions?.parameters}
          selectedLanguage={selectedLanguage}
          defaultOptionLabel="logs.typeOfLog"
        />
        <LogFormAutocompleteField
          name="user"
          options={userOptions}
          getOptionLabel={getUserOptionLabel}
          label={t('logs.user_email')}
        />
        <DatePickerContainer>
          <DatePickerField name="from" placeholder="logs.start_date" />
        </DatePickerContainer>
        <DatePickerContainer>
          <DatePickerField name="to" placeholder="logs.end_date" />
        </DatePickerContainer>
      </FormProvider>
      <IconButton
        disabled={false}
        className={classes.clearButton}
        size="small"
        onClick={handleClearButtonClick}
      >
        <DeleteIcon />
      </IconButton>
    </FilterFormContainer>
  );
};

export default LogsFilterForm;
