import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

export const UsersTabWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const UsersFilterWrapper = styled(Box)`
  display: flex;
  alignitems: center;
`;

export const LoaderWrapper = styled(Box)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UsersAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;
