import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import CategorySelectField from './CategorySelectField';
import CategoryDestinationSelectField from './CategoryDestinationSelectField';
import { CategoriesFaqFilterWrapper } from '../styled';

const CategoriesFilterForm = ({
  selectedLanguage,
  statusOptionsData,
  destinationOptionsData,
}) => {
  return (
    <form>
      <CategoriesFaqFilterWrapper>
        <Field
          name="status"
          component={CategorySelectField}
          selectedLanguage={selectedLanguage}
          optionsData={statusOptionsData}
        />
        <Field
          name="destination"
          component={CategoryDestinationSelectField}
          optionsData={destinationOptionsData}
        />
      </CategoriesFaqFilterWrapper>
    </form>
  );
};

export default connect((state) => ({
  initialValues: {
    status: state.categoryFaqReducer.status,
    destination: state.categoryFaqReducer.destination,
  },
}))(
  reduxForm({ form: 'CategoryFaqFilterForm', enableReinitialize: true })(
    CategoriesFilterForm
  )
);
