import clsx from 'clsx';
import PropTypes from 'prop-types';

import Fab from '@mui/material/Fab';
import { withStyles } from '@mui/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';

import { StyledImageBox, StyledImageList, StyledImageListItem } from './styled';

import { isImage, isImageUrl } from './utils';

const styles = ({ palette, shape, spacing }) => ({
  root: {},
  imageContainer: {
    position: 'relative',
    zIndex: 10,
    textAlign: 'center',
  },
  image: {
    height: 150,
    width: 'initial',
    maxWidth: '100%',
    color: palette.text.primary,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    borderRadius: shape.borderRadius,
    zIndex: 5,
    opacity: 1,
  },
  removeButton: {
    transition: '.5s ease',
    position: 'absolute',
    opacity: 0.7,
    top: spacing(0),
    right: spacing(0),
    width: 35,
    height: 30,
  },
});

const ImageViewList = ({
  imageList,
  disabled,
  onFileDelete,
  showFileNames,
  previewGridClasses,
  previewGridProps,
  classes,
  columns,
  rowHeight,
}) => {
  return (
    <StyledImageList cols={columns} rowHeight={rowHeight}>
      {!!imageList.length &&
        imageList.map((fileObject, i) => {
          return (
            <StyledImageListItem key={i}>
              {isImageUrl(fileObject) ? (
                <StyledImageBox
                  component="img"
                  src={fileObject}
                  alt="no data"
                  loading="lazy"
                />
              ) : isImage(fileObject.file) ? (
                <StyledImageBox
                  component="img"
                  src={fileObject.data}
                  alt="no data"
                  loading="lazy"
                />
              ) : (
                <AttachFileIcon className={classes.image} />
              )}
              <Fab
                disabled={disabled}
                onClick={onFileDelete(i)}
                className={clsx(classes.removeButton)}
              >
                <CloseIcon fontSize="inherit" />
              </Fab>
            </StyledImageListItem>
          );
        })}
    </StyledImageList>
  );
};

ImageViewList.propTypes = {
  classes: PropTypes.object.isRequired,
  fileObjects: PropTypes.arrayOf(PropTypes.object),
  onFileDelete: PropTypes.func.isRequired,
  previewGridClasses: PropTypes.object,
  previewGridProps: PropTypes.object,
  showFileNames: PropTypes.bool,
};

export default withStyles(styles, { name: 'MuiDropzonePreviewList' })(
  ImageViewList
);
