import { format } from 'date-fns';

import { TableCell } from '@mui/material';

import { StyledVisitsTableRow } from '../styled';

import { parseVisitorNumber } from '../../../users/visitorsTab/helpers';

const AttractionVisitTableDataRow = ({
  visitData,
  benefitOptions,
  languages,
}) => {
  const {
    confirmationCode,
    dateAndTime,
    attraction,
    dealNumber,
    benefit,
    visitorId,
  } = visitData;

  const translatedBenefit =
    benefitOptions &&
    benefitOptions.parameters.find((benefitItem) => benefitItem.id === benefit)
      .value[languages[0]];

  return (
    <StyledVisitsTableRow>
      <TableCell>{confirmationCode}</TableCell>
      <TableCell>{format(new Date(dateAndTime), 'dd/MM/yyyy HH:mm')}</TableCell>
      <TableCell>{attraction}</TableCell>
      <TableCell>{dealNumber}</TableCell>
      <TableCell>{translatedBenefit}</TableCell>
      <TableCell>{parseVisitorNumber(visitorId)}</TableCell>
    </StyledVisitsTableRow>
  );
};

export default AttractionVisitTableDataRow;
