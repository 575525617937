import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { PricesRow, PricesTable } from './pricesTable';
import { PricesFormDateField } from './formFields';

import {
  PricesFormHeader,
  PricesFormControlPanel,
  PricesFormSubmitButton,
  PricesFormButton,
  StyledPricesForm,
  PricesFormFieldWrapper,
  PricesFormFieldTitle,
  CurrencyFieldContainer,
  PricesFormFieldInputContainer,
  PricesFormCheckbox,
  ErrorMessageContainer,
  PricesTabTitleWrapper,
} from '../styled';

import { required } from '../validate.js';
import { calculateVatAndNetAmount } from '../utils';
import {
  PRICES_TABLE_ROW_LIST,
  PRICES_TABLE_ROW_LIST_STANDART,
  NEW_PRICE_OBJECT_DEFAULT_VALUES,
  VALUE_ADDED_TAX_AMOUNT,
  NET_AMOUNT,
  PRICE_FORM,
  CZECH_DESTINATION_STAGE,
  CZECH_DESTINATION_PROD
} from '../constants';
import { useNavigationPrompt } from '../../../../hooks/navigation';

let PricesForm = (props) => {
  const {
    attractionData,
    showSaveAnimation,
    error,
    currencySymbol,
    currentVat,
    contractPriceRowValues,
    isInEditMode,
    isInAddMode,
    handleSubmit,
    onEdit,
    onDelete,
    onCancel,
    isOpened,
    vatPercentage,
    dirty,
    submitSucceeded,
    hideControlPanel,
  } = props;

  const blockNavigation = dirty && !submitSucceeded;
  const currentDestinationId = useSelector(state => state.appReducer.selectedDestination.id);

  useNavigationPrompt(blockNavigation);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleVatPercentageChange = (vatValue) => {
    if (!vatValue) return;

    const { vatAmount, netAmount } = calculateVatAndNetAmount(
      vatValue,
      contractPriceRowValues
    );

    dispatch(change(PRICE_FORM, VALUE_ADDED_TAX_AMOUNT, vatAmount));
    dispatch(change(PRICE_FORM, NET_AMOUNT, netAmount));
  };

  const handleContractPriceRowChange = () => {
    if (!vatPercentage) return;

    const { vatAmount, netAmount } = calculateVatAndNetAmount(
      vatPercentage,
      contractPriceRowValues
    );

    dispatch(change(PRICE_FORM, VALUE_ADDED_TAX_AMOUNT, vatAmount));
    dispatch(change(PRICE_FORM, NET_AMOUNT, netAmount));
  };

  let tableRowsList = (currentDestinationId === CZECH_DESTINATION_STAGE || currentDestinationId === CZECH_DESTINATION_PROD) ? PRICES_TABLE_ROW_LIST_STANDART : PRICES_TABLE_ROW_LIST;

  return (
    <StyledPricesForm onSubmit={handleSubmit}>
      <PricesFormHeader>
        <PricesTabTitleWrapper>{attractionData.name}</PricesTabTitleWrapper>
        {!hideControlPanel && (
          <PricesFormControlPanel>
            <PricesFormButton
              onClick={onCancel}
              disabled={showSaveAnimation}
              label={t('attractions.price.cancel_button')}
            />
            {!isInAddMode && (
              <>
                <PricesFormButton
                  onClick={onEdit}
                  disabled={showSaveAnimation}
                  label={t('attractions.price.edit_button')}
                />
                <PricesFormButton
                  onClick={onDelete}
                  label={t('attractions.price.delete_button')}
                  disabled={showSaveAnimation}
                />
              </>
            )}
            <PricesFormSubmitButton
              isLoading={showSaveAnimation}
              disabled={!isInEditMode}
              label={t('attractions.price.save_button')}
            />
          </PricesFormControlPanel>
        )}
      </PricesFormHeader>
      <PricesFormFieldWrapper>
        <PricesFormFieldTitle>
          {t('attractions.price.start_data')}:
        </PricesFormFieldTitle>
        <PricesFormFieldInputContainer>
          <Field
            name="startDate"
            component={PricesFormDateField}
            validate={[required]}
            disabled={!isInAddMode && !isInEditMode}
          />
          {error && error.StartDate && (
            <ErrorMessageContainer
              variant="caption"
              display="block"
              component="span"
            >
              {`* ${error.StartDate[0]}`}
            </ErrorMessageContainer>
          )}
        </PricesFormFieldInputContainer>
      </PricesFormFieldWrapper>
      <PricesFormFieldWrapper>
        <PricesFormFieldTitle>
          {t('attractions.price.end_data')}:
        </PricesFormFieldTitle>
        <PricesFormFieldInputContainer>
          <Field
            name="endDate"
            component={PricesFormDateField}
            validate={isOpened ? [] : [required]}
            disabled={isOpened || !isInEditMode}
          />
          {error && error.EndDate && (
            <ErrorMessageContainer
              variant="caption"
              display="block"
              component="span"
            >
              {`* ${error.EndDate[0]}`}
            </ErrorMessageContainer>
          )}
        </PricesFormFieldInputContainer>
        <PricesFormFieldInputContainer>
          <Field
            disabled={!isInEditMode}
            component={PricesFormCheckbox}
            name="isOpened"
            label={t('attractions.price.open')}
            onChange={() => dispatch(change(PRICE_FORM, 'endDate', null))}
          />
        </PricesFormFieldInputContainer>
      </PricesFormFieldWrapper>
      <CurrencyFieldContainer>{`${t('attractions.price.currency')}: ${
        attractionData.currency
      }`}</CurrencyFieldContainer>
      <PricesTable>
        {tableRowsList.map((row, index) => (
          <PricesRow
            key={index}
            item={row}
            currencySymbol={currencySymbol}
            vatOptions={currentVat.valueAddedTaxes}
            onVatPercentageChange={handleVatPercentageChange}
            onContractPriceRowChange={handleContractPriceRowChange}
            isInEditMode={isInEditMode}
          />
        ))}
      </PricesTable>
    </StyledPricesForm>
  );
};

const selector = formValueSelector(PRICE_FORM);

const mapStateToProps = (state, ownProps) => {
  const contractPriceRowValues = selector(state, 'contractPrice');
  const vatPercentage = selector(state, 'valueAddedTaxAmount.valueAddedTax');
  const isOpened = selector(state, 'isOpened');

  if (ownProps.isInAddMode) {
    return {
      vatPercentage,
      isOpened,
      contractPriceRowValues,
      initialValues: { ...NEW_PRICE_OBJECT_DEFAULT_VALUES },
    };
  }

  return {
    vatPercentage,
    isOpened,
    contractPriceRowValues,
    initialValues: {
      standardPrice: {
        ...state.priceReducer.currentPrice.standardPrice,
      },
      contractPrice: {
        ...state.priceReducer.currentPrice.contractPrice,
      },
      valueAddedTaxAmount: {
        ...state.priceReducer.currentPrice.valueAddedTaxAmount,
        valueAddedTax: state.priceReducer.currentPrice.valueAddedTax,
      },
      netAmount: {
        ...state.priceReducer.currentPrice.netAmount,
      },
      startDate: new Date(
        state.priceReducer.currentPrice.relevancePeriod.validFrom
      ),
      endDate: new Date(
        Date.parse(state.priceReducer.currentPrice.relevancePeriod.validTo)
      ),
      isOpened: state.priceReducer.currentPrice.relevancePeriod.validTo
        ? false
        : true,
    },
  };
};

const reduxPricesForm = reduxForm({
  form: PRICE_FORM,
  enableReinitialize: true,
})(PricesForm);

export default connect(mapStateToProps)(reduxPricesForm);
