import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  toggleCustomInlineStyle,
  getSelectionCustomInlineStyle,
} from 'draftjs-utils';

import FontDropDown from './FontDropDown';

import {
  DEFAULT_EDITOR_FONT_FAMILY,
  FONT_FAMILY_OPTION_LIST,
} from '../constants';
import { shouldSetDefaultFontFamily } from './utils';

const FontExtension = (props) => {
  const { editorState, config, translations, onChange } = props;

  const [currentFontFamily, setCurrentFontFamily] = useState(
    editorState
      ? getSelectionCustomInlineStyle(editorState, ['FONTFAMILY']).FONTFAMILY
      : undefined
  );

  const toggleFontSize = useCallback(
    (fontFamily) => {
      const newState = toggleCustomInlineStyle(
        editorState,
        'fontFamily',
        fontFamily
      );

      if (newState) {
        onChange(newState);
      }
    },
    [editorState, onChange]
  );

  useEffect(() => {
    if (editorState) {
      const currentSelection = editorState.getSelection();

      const selectionFontFamily = getSelectionCustomInlineStyle(editorState, [
        'FONTFAMILY',
      ]).FONTFAMILY;

      if (shouldSetDefaultFontFamily(currentSelection, selectionFontFamily)) {
        toggleFontSize(DEFAULT_EDITOR_FONT_FAMILY);
      } else {
        setCurrentFontFamily(
          selectionFontFamily && selectionFontFamily.substring(11)
        );
      }
    }
  }, [editorState, toggleFontSize]);

  return (
    <FontDropDown
      options={FONT_FAMILY_OPTION_LIST}
      config={config}
      translations={translations}
      value={currentFontFamily}
      onChange={toggleFontSize}
    />
  );
};

FontExtension.propTypes = {
  onChange: PropTypes.func.isRequired,
  editorState: PropTypes.object,
  modalHandler: PropTypes.object,
  config: PropTypes.object,
  translations: PropTypes.object,
};

export default FontExtension;
