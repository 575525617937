import { Controller, useFormContext } from 'react-hook-form';

import { NotificationFormSelectField } from '../styled';

const createOptions = (options, selectedLanguage) => {
  if (!options) return null;

  return options.map((option, index) => (
    <option value={option.id} key={index}>
      {option.value[selectedLanguage] || option.value.en}
    </option>
  ));
};

const NotificationStatusField = ({
  name,
  options,
  selectedLanguage,
  disabled,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <NotificationFormSelectField
          native
          value={value}
          disabled={disabled}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          size="small"
        >
          {createOptions(options, selectedLanguage)}
        </NotificationFormSelectField>
      )}
    />
  );
};

export default NotificationStatusField;
