import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  FormHelperText,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { styled } from '@mui/system';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import OptionUnstyled, {
  optionUnstyledClasses,
} from '@mui/base/OptionUnstyled';
import SelectUnstyled, {
  selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { DateTimePicker } from '@mui/lab';

const HelperText = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return (
      <FormHelperText sx={{ color: '#d43131' }}>
        * {touched && error}
      </FormHelperText>
    );
  }
};

export const Input = ({
  label,
  input,
  meta: { touched, error },
  text,
  length,
  ...props
}) => {
  return (
    <Box>
      <TextField
        {...input}
        {...props}
        label={label}
        placeholder={text}
        error={touched && error}
        size="small"
      />
      {HelperText({ touched, error })}
    </Box>
  );
};

export const DatePickerInput = ({
  label,
  input,
  meta: { touched, error },
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={props.periodViews}
          inputFormat={props.formatViews}
          value={input.value || null}
          onChange={input.onChange}
          disabled={props.disabled}
          clearable
          clearText={t('clear')}
          renderInput={({ inputProps, ...restParams }) => (
            <TextField
              {...restParams}
              inputProps={{
                ...inputProps,
                placeholder: t('visitsPage.visitsFilterFields.date'),
              }}
              sx={{ width: '100%' }}
              size="small"
            />
          )}
        />
      </LocalizationProvider>
      {HelperText({ touched, error })}
    </>
  );
};

export const TimePickerInput = ({
  label,
  input,
  meta: { touched, error },
  ...props
}) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label={label}
          value={input.value || null}
          onChange={input.onChange}
          disabled={props.disabled}
          views={['hours', 'minutes']}
          ampm={false}
          inputFormat="HH:mm"
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ width: '100%', marginRight: '10px', marginBottom: '15px' }}
            />
          )}
        />
      </LocalizationProvider>
      {HelperText({ touched, error })}
    </>
  );
};

export const OpeningHoursTimePickerInput = ({
  label,
  input,
  meta: { touched, error },
  ...props
}) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          {...input}
          {...props}
          label={label}
          value={input.value || null}
          onChange={input.onChange}
          disabled={props.disabled}
          views={['hours', 'minutes']}
          inputFormat="HH:mm"
          ampm={false}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ width: '100%', marginRight: '10px', marginBottom: '15px' }}
            />
          )}
        />
      </LocalizationProvider>
      {HelperText({ touched, error })}
    </>
  );
};

export const CheckboxInput = ({
  input,
  label,
  meta: { touched, error },
  disabled,
  ...props
}) => {
  return (
    <>
      <FormControlLabel
        sx={{ width: '188px', marginRight: '15px' }}
        error={touched && error}
        labelPlacement="start"
        disabled={disabled}
        control={
          <Checkbox
            checked={input.value}
            onChange={input.onChange}
            error={touched && error}
          />
        }
        label={label}
      />
      {HelperText({ touched, error })}
    </>
  );
};

export const SpecialDateClosedCheckbox = ({
  input,
  label,
  meta: { touched, error },
  disabled,
  ...props
}) => {
  return (
    <>
      <FormControlLabel
        sx={{
          marginLeft: 0,
          '& span': {
            paddingLeft: 0,
          },
        }}
        error={touched && error}
        labelPlacement="end"
        control={
          <Checkbox
            checked={input.value}
            onChange={input.onChange}
            error={touched && error}
            disableRipple
          />
        }
        label={label}
      />
      {HelperText({ touched, error })}
    </>
  );
};

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: 'Open Sans', 'sans-serif';
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 35px;
  width: 100%;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 3.5px;
  padding: 5px 5px 5px 14px;
  text-align: left;
  line-height: 1.5;
  color: #000;
  opacity: 0.6;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
  width: 242.5px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };
  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export const Select = ({ input, label, ...props }) => {
  const { t } = useTranslation();
  return (
    <CustomSelect {...props} value={input.value} onChange={input.onChange}>
      <StyledOption sx={{ width: '100%' }} value={''}>
        {t('attractions.season.none')}
      </StyledOption>
      {props.data.map((item) => (
        <StyledOption sx={{ width: '100%' }} value={item.id} key={item.id}>
          {t(item.name)}
        </StyledOption>
      ))}
    </CustomSelect>
  );
};

export const SelectClosed = ({ input, label, ...props }) => {
  const { t } = useTranslation();

  return (
    <CustomSelect
      defaultValue={''}
      {...props}
      onChange={input.onChange}
      value={input.value}
    >
      <StyledOption sx={{ width: '100%' }} value={''}>
        {t('attractions.season.never')}
      </StyledOption>
      {props.data.map((item) => (
        <StyledOption
          sx={{ width: '100%' }}
          value={item.value}
          key={item.value}
        >
          {t(item.label)}
        </StyledOption>
      ))}
    </CustomSelect>
  );
};
