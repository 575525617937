export const getUniqueImages = (imageList) => {
  if (!imageList) return [];

  return imageList.filter((image) => typeof image !== 'string');
};

export const getExistingImages = (imageList) => {
  if (!imageList) return [];

  return imageList.filter((image) => typeof image === 'string');
};

export const uploadImageFiles = (imageList, imageUploader, imageType) => {
  if (!imageList.length) return { data: [] };

  const imageFormData = new FormData();

  imageList.forEach((imageFile) =>
    imageFormData.append('files', imageFile?.file)
  );

  return imageUploader(imageFormData, imageType);
};
