import { stopSubmit } from 'redux-form';
import {
    getDestinationsApi,
    addDestinationApi,
    deleteDestinationApi,
    editDestinationApi,
    addLocationApi,
    editLocationApi,
    deleteLocationApi, 
    getDestinationByIdApi
} from '../../api/settingsApi.js';
const SET_DESTINATION = 'SET_DESTINATION';
const SET_DICTIONARY_REGION_DATA = 'SET_DICTIONARY_REGION_DATA';
const SET_DICTIONARY_AREA_DATA = 'SET_DICTIONARY_AREA_DATA';
const SET_DICTIONARY_LOCATION_DATA = 'SET_DICTIONARY_LOCATION_DATA';
const SET_CURRENT_DESTINATION = 'SET_CURRENT_DESTINATION';
const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
const SET_PARENT_ID = 'SET_PARENT_ID';
const SET_ID_DESTINATIONS = 'SET_ID_DESTINATIONS';
const SET_IS_LOADING_DESTINATIONS_DATA = 'SET_IS_LOADING_DESTINATIONS_DATA';

const initialState = {
    destinationData: [],
    dictionaryRegionData: [],
    dictionaryAreaData: [],
    dictionaryLocationData: [],
    currentDestination: {},
    currentLocation: {},
    parentId: '',
    idDestinations: [],
    isLoadingDestinationsData: false,
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DESTINATION:
            return {
                ...state,
                destinationData: [...action.payload.data]
            };
        case SET_IS_LOADING_DESTINATIONS_DATA:
            return {
                ...state,
                isLoadingDestinationsData: action.isLoadingDestinationsData
            };
        case SET_DICTIONARY_REGION_DATA:
            return {
                ...state,
                dictionaryRegionData: [...action.payload.data]
            };
        case SET_DICTIONARY_AREA_DATA:
            return {
                ...state,
                dictionaryAreaData: [...action.payload.data]
            };
        case SET_CURRENT_DESTINATION:
            return {
                ...state,
                currentDestination: {...action.payload.data}
            };
        case SET_CURRENT_LOCATION:
            return {
                ...state,
                currentLocation: {...action.payload.data}
            };
        case SET_PARENT_ID:
            return {
                ...state,
                parentId: action.id
            };
        case SET_ID_DESTINATIONS:
            return {
                ...state,
                idDestinations: [...action.payload.data]
            };
        case SET_DICTIONARY_LOCATION_DATA:
            return {
                ...state,
                dictionaryLocationData: [...action.payload.data]
            };
        default:
            return state;
    }
};

let setDestination = (data) => {
    return { type: SET_DESTINATION, payload: { data } };
}
let setIsLoadingDestinationsData = (isLoadingDestinationsData) => {
    return { type: SET_IS_LOADING_DESTINATIONS_DATA, isLoadingDestinationsData };
}
let setDictionaryRegion = (data) => {
    return { type: SET_DICTIONARY_REGION_DATA, payload: { data } };
}
let setDictionaryArea = (data) => {
    return { type: SET_DICTIONARY_AREA_DATA, payload: { data } };
}
let setIdDestinations = (data) => {
    return { type: SET_ID_DESTINATIONS, payload: { data } };
}
let setDictionaryLocation = (data) => {
    return { type: SET_DICTIONARY_LOCATION_DATA, payload: { data } };
}
export let setCurrentDestination = (data) => {
    return { type: SET_CURRENT_DESTINATION, payload: { data } };
}
export let setCurrentLocation = (data) => {
    return { type: SET_CURRENT_LOCATION, payload: { data } };
}
export let setParentId = (id) => {
    return { type: SET_PARENT_ID, id};
}


export let getDestinationThunk = () => async (dispatch) => {
    let response = await getDestinationsApi();
    let dictRegion = [];
    let dictArea = [];
    let dictLocation = [];
    let idDestinations = [];

    response.data.map(item => {
        item.locations.map(region => {
            if (region.locationType === "Region" ) {
                dictRegion.push(region);
            } 
            if (region.locationType === "Area") {
                dictArea.push(region);
            } 
            if (region.locationType === "Location") {
                dictLocation.push(region);
            } 
            region.children.length && region.children.map(element => {
                if (element.locationType === "Region" ) {
                    dictRegion.push(element);
                } 
                if (element.locationType === "Area") {
                    dictArea.push(element);
                } 
                if (element.locationType === "Location") {
                    dictLocation.push(element);
                } 
                element.children.length && element.children.map(i => {
                    if (i.locationType === "Region" ) {
                        dictRegion.push(i);
                    } 
                    if (i.locationType === "Area") {
                        dictArea.push(i);
                    } 
                    if (i.locationType === "Location") {
                        dictLocation.push(i);
                    } 
                })
            })
        })
    });

    response.data.map(item => idDestinations.push(item.id))

    if (response.status >= 200 && response.status < 300) {
        dispatch(setDestination(response.data));
        dispatch(setDictionaryRegion(dictRegion));
        dispatch(setDictionaryArea(dictArea));
        dispatch(setDictionaryLocation(dictLocation));
        dispatch(setIdDestinations(idDestinations));
        dispatch(setIsLoadingDestinationsData(true));
    }
}

export let getCurrentDestinationThunk = (id) => async (dispatch) => {
    if(!!id){
        let response = await getDestinationByIdApi(id);

        if (response.status >= 200 && response.status < 300) {
            dispatch(setCurrentDestination(response.data));
        }
    }
}

export let addDestinationThunk = (data) => async (dispatch) => {
    let response = await addDestinationApi(data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Destination", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let editDestinationThunk = (id, data) => async (dispatch) => {
    let response = await editDestinationApi(id, data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Destination", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let deleteDestinationThunk = (id) => async () => {
    let response = await deleteDestinationApi(id);
    if(response.status < 200 || response.status >= 300) {
        return Promise.reject(response.data.errors)
    }
}

export let addLocationThunk = (id, data) => async (dispatch) => {
    let response =  await addLocationApi(id, data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Region", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let editLocationThunk = (id, data) => async (dispatch) => {
    let response = await editLocationApi(id, data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Region", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let deleteLocationThunk = (id) => async () => {
    let response = await deleteLocationApi(id);
    if(response.status < 200 || response.status >= 300) {
        return Promise.reject(response.data.errors)
    }
}

export default settingsReducer;