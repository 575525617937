import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { Box } from '@mui/system';
import { TableCell, TableRow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { parseVisitorNumber } from '../helpers';
import VisitorEditFormContainer from '../visitorsForm/VisitorEditFormContainer';
import { StyledVisitorsTableRow } from '../styled';

const statusColors = {
  Active: '#FFA94C',
  Registered: '#32D7F0',
  Expired: '#A0A0A0',
  Expiring: '#F7B781',
  Deleted: ' #EFEFEF',
  Inactive: '#f5c842',
};

const VisitorsTableRow = ({
  visitorData,
  paginationSettings,
  statusFilterValue,
  searchQuery,
  onExpand,
  isExpanded,
  resetActiveRow,
  productSettings,
  selectedLanguage,
  handleResetFilters,
}) => {
  const { t } = useTranslation();
  const [isInEditMode, setIsInEditMode] = useState(false);

  const { id, status, name, email, created, validFrom, validUntil } =
    visitorData;

  const translatedType =
    !!(visitorData.productType && productSettings.length) &&
    productSettings[0].parameters.filter(
      (productType) => productType.id === visitorData.productType
    )[0].value[selectedLanguage];

  const initialFormState = {
    number: parseVisitorNumber(visitorData.id),
    status: visitorData.status,
    name: visitorData.name,
    email: visitorData.email,
    address: visitorData.address,
    language: visitorData.language || '',
    product: visitorData.productType || '',
    created: visitorData.created,
    deleted: visitorData.deleted || '',
    validFrom: visitorData.validFrom || '',
    validUntil: visitorData.validUntil,
    lastLogin: visitorData.lastLogin || '',
  };

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...initialFormState,
    },
  });

  const { reset, setValue } = formMethods;

  const handleChangeEditMode = () => setIsInEditMode(!isInEditMode);

  const handleCancelChanges = () => {
    reset(initialFormState, { keepDefaultValues: true });
    handleChangeEditMode();
  };

  const handleExpandTableRow = () => {
    onExpand();
    reset(initialFormState, { keepDefaultValues: true });
    setIsInEditMode(false);
  };

  useEffect(() => {
    translatedType && setValue('product', translatedType);
  });

  return (
    <>
      <StyledVisitorsTableRow onClick={handleExpandTableRow}>
        <TableCell
          sx={{
            backgroundColor: statusColors[status],
          }}
        >
          {t(`visitors.statuses.${status.toLowerCase()}`)}
        </TableCell>
        <TableCell>{parseVisitorNumber(id)}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{format(new Date(created), 'dd/MM/yyyy')}</TableCell>
        <TableCell>
          {validFrom ? format(new Date(validFrom), 'dd/MM/yyyy') : '-'}
        </TableCell>
        <TableCell>
          {validUntil ? format(new Date(validUntil), 'dd/MM/yyyy') : '-'}
        </TableCell>
        <TableCell>
          <ExpandMoreIcon />
        </TableCell>
      </StyledVisitorsTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell
            colSpan={12}
            sx={{
              borderRight: '1px solid #e0e0e0',
              borderLeft: '1px solid #e0e0e0',
            }}
          >
            <Box>
              <FormProvider {...formMethods}>
                <VisitorEditFormContainer
                  visitorData={visitorData}
                  statusFilterValue={statusFilterValue}
                  searchQuery={searchQuery}
                  isInEditMode={isInEditMode}
                  handleCancelChanges={handleCancelChanges}
                  handleChangeEditMode={handleChangeEditMode}
                  resetActiveRow={resetActiveRow}
                  paginationSettings={paginationSettings}
                  handleResetFilters={handleResetFilters}
                />
              </FormProvider>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default VisitorsTableRow;
