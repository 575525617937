import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  CircularProgress,
} from '@mui/material';

import GeneralNotification from './GeneralNotification';
import { tableCellsDataStyle } from './TableCellsStyle';

function NotificationsTable(props) {
  const { t } = useTranslation();
  const [expandedId, setExpandedId] = useState(null);

  const notificationsData = props.notificationsData;
  const isItemsLoaded = props.isItemsLoaded;
  const isItemUpdate = props.isItemUpdate;

  const handlePanelExpandChange = (panelId) => (_, isExpanded) => {
    setExpandedId(isExpanded ? panelId : false);
  };

  const CreateTableHead = () => (
    <TableHead>
      <TableRow
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <TableCell padding="normal" style={tableCellsDataStyle.dataStyle}>
          {t('notifications.status')}
        </TableCell>
        <TableCell padding="normal" style={tableCellsDataStyle.mainDataStyle}>
          {t('notifications.attraction')}
        </TableCell>
        <TableCell padding="normal" style={tableCellsDataStyle.mainDataStyle}>
          {t('notifications.title')}
        </TableCell>
        <TableCell padding="normal" style={tableCellsDataStyle.dataStyle}>
          {t('notifications.start_date')}
        </TableCell>
        <TableCell padding="normal" style={tableCellsDataStyle.dataStyle}>
          {t('notifications.end_date')}
        </TableCell>
        <TableCell
          padding="normal"
          style={tableCellsDataStyle.dataStyle}
          align="center"
        >
          {t('notifications.view')}
        </TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      {isItemsLoaded ? (
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <CreateTableHead />
            <TableBody>
              {notificationsData.map((item, i) => {
                return (
                  <GeneralNotification
                    notification={item}
                    languages={props.languages}
                    statuses={props.statuses}
                    isAddingItem={props.isAddingItem}
                    onFormSubmit={props.onSubmitNotificationForm}
                    showSaveAnimation={props.showSaveAnimation}
                    onDeleteNotification={props.onDeleteNotification}
                    setIsUpdateNotification={props.setIsItemUpdate}
                    isUpdateNotification={isItemUpdate}
                    availableAttractions={props.availableAttractions}
                    expanded={expandedId === item.id}
                    onExpandChange={() => handlePanelExpandChange(item.id)}
                    key={i}
                    selectedLanguage={props.selectedLanguage}
                    selectedDestinationId={props.selectedDestinationId}
                    handleResetFilters={props.handleResetFilters}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
}

export default NotificationsTable;
