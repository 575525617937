import { MobileDatePicker } from '@mui/lab';
import { Box, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

import {
  FormButton,
  SaveButton,
} from '../../../../components/buttons/FormButtons';

export const DealFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;

export const DealFormSaveButton = styled(SaveButton)`
  color: white;
`;

export const DealFormCancelButton = styled(FormButton)``;

export const DealFormContainer = styled(Box)`
  padding-left: 16px;
`;

export const DealFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const DealFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const DealFormSelectField = styled(Select)`
  max-width: 215px;
  width: 100%;
`;

export const DealLanguageTabField = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const DealFormDateField = styled(MobileDatePicker)`
  max-width: 215px;
  width: 100%;
`;

export const DealFormDateFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 20px;
  align-items: center;

  & .MuiOutlinedInput-root {
    max-width: 215px;
    width: 100%;
  }
`;

export const DealFormLanguageTabField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const DealFormPercentageField = styled(TextField)`
  & .MuiOutlinedInput-root {
    max-width: 215px;
    width: 100%;
  }

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const EditDealFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const DealDateTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }

  & .MuiOutlinedInput-input {
    cursor: pointer;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;
