export const mapDealsTranslationsObjectToString = (
  transList,
  selectedLang,
  benefitTranslations
) => {
  const mappedBenefitList = benefitTranslations.reduce((acc, current) => {
    acc[current.id] = current.value;

    return acc;
  }, {});

  const updatedList = transList.reduce((acc, current) => {
    const newItem = {
      ...current,
      benefit:
        mappedBenefitList[current.benefit][selectedLang] ||
        mappedBenefitList[current.benefit]['en'],
      title: current.title[selectedLang] || current.title.en || '',
    };

    acc.push(newItem);

    return acc;
  }, []);

  return updatedList;
};
