import { connect } from 'react-redux';
import { compose } from 'redux';

import UserPage from './UserPage';

import { getSmallAttractionsDataThunk } from '../../redux/attractionsReducers/attractions/attractionsActions';
import { getSalesPointShortDataListThunk } from '../../redux/salesPointsReducer/salesPointsActions';

const mapStateToProps = (state) => {
  return {
    selectedDestinationId: state.appReducer.selectedDestination.id,
  };
};

const UserPageContainer = compose(
  connect(mapStateToProps, {
    getSmallAttractionsDataThunk,
    getSalesPointShortDataListThunk,
  })
)(UserPage);

export default UserPageContainer;
