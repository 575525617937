export const usersTabs = [
  {
    name: 'users.users',
    path: 'users',
    matchUrls: ['users', 'new-user'],
  },
  {
    name: 'companies.companies',
    path: 'companies',
    matchUrls: ['companies', 'new-company'],
  },
  {
    name: 'visitors.visitorTabName',
    path: 'visitors',
    matchUrls: ['visitors', 'new-visitor'],
  },
];

const firstTabIndex = 0;

export const getOpenedTabIdByUrlParameter = (tabs, location) => {
  if (!location) return firstTabIndex;

  const splitedLocation = location.split('/');
  const tabName = splitedLocation[2];

  if (!tabName) return firstTabIndex;

  const tabId = tabs.findIndex((tab) => tab.matchUrls.includes(tabName));

  if (tabId === -1) return firstTabIndex;

  return tabId;
};
