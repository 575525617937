import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link, Outlet } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import { a11yProps } from '../../components/tabPanel/TabPanel';

import {
  PublishingPageContainer,
  PublishingTabsContainer,
  PublishingTabs,
  PublishingTab,
} from './styled';

import {
  getOpenedTabIdByUrlParameter,
  publishingPageTabs,
} from './publishingPageTabs';
import { getCurrentDestinationThunk } from "../../redux/settingsReducers/settingsReducer";
import { setTitle } from '../../redux/appReducer';

const PublishingPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedTabId, setSelectedTabId] = useState(
    getOpenedTabIdByUrlParameter(publishingPageTabs, location.pathname)
  );

  const selectedDestinationId = useSelector(
      (state) => state.appReducer.selectedDestination.id
  );
  const handleActiveTabChange = (newValue) => {
    setSelectedTabId(newValue);
  };

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_publications')));
    handleActiveTabChange(
      getOpenedTabIdByUrlParameter(publishingPageTabs, location.pathname)
    );
    dispatch(getCurrentDestinationThunk(selectedDestinationId))
  }, [location.pathname, selectedDestinationId]);

  return (
    <PublishingPageContainer>
      <PublishingTabsContainer>
        <PublishingTabs
          value={selectedTabId}
          indicatorColor="primary"
          textColor="inherit"
        >
          {publishingPageTabs.map((tab, index) => (
            <PublishingTab
              label={t(tab.name)}
              component={Link}
              to={tab.path}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </PublishingTabs>
      </PublishingTabsContainer>
      <Outlet />
    </PublishingPageContainer>
  );
};

export default PublishingPage;
