import { connect } from 'react-redux';
import { compose } from 'redux';

import NotificationPage from './NotificationPage';

import {
  getDestinationNotificationsListThunk,
  getFilteredDestinationNotificationsThunk,
} from '../../redux/generalNotificationsReducer/generalNotificationsActions';
import { getSmallAttractionsDataThunk } from '../../redux/attractionsReducers/attractions/attractionsActions';

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    notificationReducer: state.generalNotificationsReducer,
    attractionsReducer: state.attractionsReducer,
  };
};

const NotificationPageContainer = compose(
  connect(mapStateToProps, {
    getDestinationNotificationsListThunk,
    getFilteredDestinationNotificationsThunk,
    getSmallAttractionsDataThunk,
  })
)(NotificationPage);

export default NotificationPageContainer;
