import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import PricesForm from '../../../attractions/prices/forms/PricesForm';

import { setCurrentPrice } from '../../../../redux/attractionsReducers/priceReducer';

import { CURRENCIES } from '../../../../consts/consts';

const PricesLogFormContainer = (props) => {
  const { item } = props;

  const currentPrice = useSelector((state) => state.priceReducer.currentPrice);
  const attractionData = useSelector(
    (state) => state.attractionsReducer.currentAttraction
  );
  const currentDestinationData = useSelector(
    (state) => state.appReducer.selectedDestination
  );
  const currentVat = useSelector((state) =>
    state.settingsReducer.destinationData.find(
      (item) => item.id === state.appReducer.selectedDestination.id
    )
  );

  const currenciesList = useSelector((state) =>
    state.appReducer.globalSettingsData.find(
      (setting) => setting.name.en === CURRENCIES
    )
  );

  const { currency } = currentDestinationData;

  const currencyCode =
    currenciesList &&
    currency &&
    currenciesList.parameters.find((curr) => curr.id === currency).value;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPrice(item));
  }, []);

  return (
    <>
      {currentPrice.valueAddedTaxAmount && currentVat && (
        <PricesForm
          currentVat={currentVat}
          isInEditMode={false}
          attractionData={attractionData}
          currencySymbol={currencyCode}
          hideControlPanel
        />
      )}
    </>
  );
};

export default PricesLogFormContainer;
