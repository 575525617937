import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Box, Typography } from '@mui/material';

import {
  ControlPanelContainer,
  HelpTopicFormSubmitButton,
  HelpTopicFormFieldWrapper,
  HelpTopicFormFieldTitle,
  HelpTopicFormButton,
} from '../styled';

import HelpTopicFormSelectField from './formFields/HelpTopicFormSelectField';
import HelpTopicFormLanguageTabs from './languageTabs/HelpTopicFormLanguageTabs';

const HelpTopicForm = (props) => {
  const {
    selectedLanguage,
    helpTopicStatuses,
    helpTopicTypes,
    onCancel,
    languageList,
    onSubmit,
    isLoading,
    isInEditMode,
    editableForm,
    onEdit,
    onDelete,
  } = props;

  const { t } = useTranslation();

  const [activeTabId, setActiveTabId] = useState(languageList.parameters[0].id);
  const handleLanguageTabChange = (_, newValue) => setActiveTabId(newValue);

  return (
    <form>
      <Box mt="12px" display="flex" justifyContent="space-between">
        {!editableForm ? (
          <Typography
            fontSize="20px"
            fontWeight={500}
            textTransform="uppercase"
          >
            {t('helpTopics.new_item_headline')}
          </Typography>
        ) : (
          <div></div>
        )}

        <ControlPanelContainer>
          <HelpTopicFormButton
            onClick={onCancel}
            label={t('cancel')}
            disabled={isLoading || !isInEditMode}
          />
          {editableForm && (
            <>
              <HelpTopicFormButton
                onClick={onEdit}
                label={t('edit')}
                disabled={isLoading || isInEditMode}
              />
              <HelpTopicFormButton
                onClick={onDelete}
                label={t('delete')}
                disabled={isLoading || isInEditMode}
              />
            </>
          )}

          <HelpTopicFormSubmitButton
            onClick={onSubmit}
            isLoading={isLoading}
            label={t('save')}
            disabled={!isInEditMode}
          />
        </ControlPanelContainer>
      </Box>

      <HelpTopicFormFieldWrapper>
        <HelpTopicFormFieldTitle>
          {t('helpTopics.formFieldLabels.status')}
        </HelpTopicFormFieldTitle>
        <HelpTopicFormSelectField
          name="status"
          selectedLanguage={selectedLanguage}
          options={helpTopicStatuses}
          required
          disabled={!isInEditMode}
        />
      </HelpTopicFormFieldWrapper>

      <HelpTopicFormFieldWrapper>
        <HelpTopicFormFieldTitle>
          {t('helpTopics.formFieldLabels.type')}
        </HelpTopicFormFieldTitle>
        <HelpTopicFormSelectField
            name="type"
            selectedLanguage={selectedLanguage}
            options={helpTopicTypes}
            required
            disabled={!isInEditMode}
        />
      </HelpTopicFormFieldWrapper>

      {languageList && (
        <HelpTopicFormLanguageTabs
          activeTabId={activeTabId}
          onLanguageTabChange={handleLanguageTabChange}
          languages={languageList}
          disabled={!isInEditMode}
        />
      )}
    </form>
  );
};

export default HelpTopicForm;
