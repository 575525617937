import { connect } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PricesForm from './PricesForm';
import useAPIError from '../../../../apIErrorProvider/useAPIError';
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';

import {
  editPricesThunk,
  getPricesListThunk,
  deletePriceThunk,
  setCurrentPrice,
} from '../../../../redux/attractionsReducers/priceReducer';
import { mapStringsToNumbers } from '../utils';
import { CURRENCIES } from '../../../../consts/consts';

const PriceEditFormContainer = (props) => {
  const {
    currentVat,
    setShowPriceForm,
    currentPrice,
    attractionData,
    editPricesThunk,
    getPricesListThunk,
    deletePriceThunk,
    currentDestinationData,
    currenciesList,
    setCurrentPrice,
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { currency } = currentDestinationData;

  const currencyCode =
    currenciesList &&
    currency &&
    currenciesList.parameters.find((curr) => curr.id === currency).value;

  const handleFormSubmit = (formData) => {
    setShowSaveAnimation(true);

    formData.startDate.setHours(12, 0, 0, 0);
    if (!formData.isOpened) formData.endDate.setHours(12, 0, 0, 0);

    const updateData = {
      id: currentPrice.id,
      isClosedPeriod: currentPrice.isClosedPeriod,
      attractionId: attractionData.id,
      currency: attractionData.currency,
      relevancePeriod: {
        validFrom: formData.startDate.toISOString(),
        validTo: formData.isOpened ? null : formData.endDate.toISOString(),
      },
      standardPrice: mapStringsToNumbers(formData.standardPrice),
      contractPrice: mapStringsToNumbers(formData.contractPrice),
      valueAddedTaxAmount: mapStringsToNumbers(formData.valueAddedTaxAmount),
      netAmount: mapStringsToNumbers(formData.netAmount),
      valueAddedTax: parseInt(formData.valueAddedTaxAmount.valueAddedTax) || 0,
    };

    editPricesThunk(updateData.attractionId, updateData.id, updateData)
      .then(() => {
        getPricesListThunk(attractionData.id);
        setCurrentPrice({});
        setIsInEditMode(false);
        setShowPriceForm(false);
        setShowSaveAnimation(false);
        addError(`${t('attractions.price.error_edit_text')}`, 'Success');
      })
      .catch((error) => {
        addError(
          `${t('attractions.price.error_not_edit_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleEditButtonClick = () => {
    setIsInEditMode(true);
  };

  const handleDeleteButtonClick = () => {
    setIsInEditMode(false);
    setShowDeletePopup(true);
  };

  const handleCancelDelete = () => {
    setShowDeletePopup(false);
  };

  const handleSubmitDelete = () => {
    deletePriceThunk(currentPrice.attractionId, currentPrice.id)
      .then(() => {
        setShowDeletePopup(false);
        setShowPriceForm(false);
        getPricesListThunk(attractionData.id);
        addError(`${t('attractions.price.error_deleted_text')}`, 'Success');
      })
      .catch((error) => {
        addError(
          `${t('attractions.price.error_not_deleted_text')}`,
          'Error',
          error
        );
        setShowDeletePopup(false);
      });
  };

  const handleCancelButtonClick = () => {
    setShowPriceForm(false);
    setIsInEditMode(false);
  };

  return (
    <>
      {currentPrice.valueAddedTaxAmount && currentVat && (
        <>
          <PricesForm
            onSubmit={handleFormSubmit}
            onEdit={handleEditButtonClick}
            onDelete={handleDeleteButtonClick}
            onCancel={handleCancelButtonClick}
            showSaveAnimation={showSaveAnimation}
            currentVat={currentVat}
            isInEditMode={isInEditMode}
            attractionData={attractionData}
            currencySymbol={currencyCode}
          />
          <ConfirmationDialog
            showAlert={showDeletePopup}
            handleClose={handleCancelDelete}
            handleCloseSuccess={handleSubmitDelete}
            text={t('attractions.price.delete_price')}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const currentVat = state.settingsReducer.destinationData.find(
    (item) => item.id === state.appReducer.selectedDestination.id
  );

  const currenciesList = state.appReducer.globalSettingsData.find(
    (setting) => setting.name.en === CURRENCIES
  );

  return {
    currenciesList,
    currentVat,
    currentPrice: state.priceReducer.currentPrice,
    attractionData: state.attractionsReducer.currentAttraction,
    currentDestinationData: state.appReducer.selectedDestination,
  };
};

export default connect(mapStateToProps, {
  editPricesThunk,
  getPricesListThunk,
  deletePriceThunk,
  setCurrentPrice,
})(PriceEditFormContainer);
