import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        supportedLngs: ['en', 'cs'],
        fallbackLng: 'en',
        debug: false,
        detection: {
            order: ['localStorage', 'cookie'],
            caches: ['localStorage', 'cookie']
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/translations.json',
        },
    });

export default i18n;   