import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TableCell, TableRow, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { mapProductTypeTranslations } from '../helpers';
import { StyledProductsTableRow } from '../styled';
import ProductEditFormContainer from '../productEditForm/ProductEditFormContainer';

import ProductTableDataRow from './ProductTableDataRow';

const ProductTableRow = ({
  productData,
  isExpanded,
  onExpand,
  vatOptions,
  currency,
  resetActiveRow,
  destinationId,
  languages,
  productSettings,
}) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [currentTabId, setCurrentTabId] = useState(languages[0]);
  const [translatedType, setTranslatedType] = useState([]);

  const { id, type, validity, netPrice, vat, grossPrice, vatInPercent } =
    productData;

  const initialFormState = {
    id,
    type,
    validity,
    netPrice,
    vatPercentage: vatInPercent,
    vatCost: vat,
    grossPrice,
    ...mapProductTypeTranslations(languages, productData),
  };

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...initialFormState,
    },
  });

  const { reset, setValue, watch } = formMethods;

  const productNameValue = watch(currentTabId);

  const handleChangeLanguageTab = (_, newValue) => setCurrentTabId(newValue);

  const handleChangeEditMode = () => setIsInEditMode(!isInEditMode);

  const handleExpandTableRow = () => {
    onExpand();
    reset(initialFormState, { keepDefaultValues: true });
    setIsInEditMode(false);
  };

  const handleCancelChanges = () => {
    reset(initialFormState, { keepDefaultValues: true });
    handleChangeEditMode();
  };

  useEffect(() => {
    if(productSettings.length > 0) {
      setTranslatedType(productSettings[0].parameters.filter(
        (productType) => productType.id === type
      )[0].value[languages[0]]);

      setValue('type', translatedType);
    }
  }, [productSettings]);

  return (
    <>
      <StyledProductsTableRow onClick={handleExpandTableRow}>
        <ProductTableDataRow
          productData={productData}
          currency={currency}
          translatedType={translatedType}
          productName={productNameValue[0].name}
          productSpecification={productNameValue[0].specification}
        />
        <TableCell>
          <ExpandMoreIcon />
        </TableCell>
      </StyledProductsTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell
            colSpan={12}
            sx={{
              borderRight: '1px solid #e0e0e0',
              borderLeft: '1px solid #e0e0e0',
            }}
          >
            <Box>
              <FormProvider {...formMethods}>
                <ProductEditFormContainer
                  productData={productData}
                  handleChangeEditMode={handleChangeEditMode}
                  isInEditMode={isInEditMode}
                  handleCancelChanges={handleCancelChanges}
                  currency={currency}
                  vatOptions={vatOptions}
                  resetActiveRow={resetActiveRow}
                  destinationId={destinationId}
                  handleChangeLanguageTab={handleChangeLanguageTab}
                  currentLanguageTabId={currentTabId}
                  languages={languages}
                />
              </FormProvider>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ProductTableRow;
