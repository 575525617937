import { ApplicationPaths, LoginActions, LogoutActions } from "../auth/ApiAuthorizationConstants";
import Login from "../auth/Login";
import Logout from "../auth/Logout";

export const authRoutes = [
    {
        path: ApplicationPaths.Login,
        element: <Login action={LoginActions.Login}/>
    },
    {
        path: ApplicationPaths.LoginFailed,
        element: <Login action={LoginActions.LoginFailed}/>
    },
    {
        path: ApplicationPaths.LoginCallback,
        element: <Login action={LoginActions.LoginCallback}/>
    },
    {
        path: ApplicationPaths.LogOut,
        element: <Logout action={LogoutActions.Logout}/>
    },
    {
        path: ApplicationPaths.LogOutCallback,
        element: <Logout action={LogoutActions.LogoutCallback}/>
    },
    {
        path: ApplicationPaths.LoggedOut,
        element: <Login action={LoginActions.Login}/>
    }
] 