import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, CircularProgress, Typography } from '@mui/material';

import FactsTable from './factsTable/FactsTable';
import FactsRow from './factsTable/FactsRow';

import { FactsTabWrapper, LoaderWrapper } from './styled';

const DidYouKnowTab = ({
  activeRowId,
  onRowExpand,
  languageList,
  factsList,
  isLoading,
  resetActiveRow,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAddFact = () => navigate('new-fact');

  return (
    <FactsTabWrapper>
      <Typography
        fontSize="20px"
        fontWeight={500}
        textTransform="uppercase"
        mb="10px"
      >
        {t('facts.headline')}
      </Typography>
      <Button
        sx={{
          color: '#ffffff',
          maxWidth: 'fit-content',
          marginBottom: '10px',
        }}
        onClick={handleAddFact}
        variant="contained"
        disableRipple
      >
        {t('facts.add_new_fact')}
      </Button>
      {!isLoading && (
        <FactsTable>
          {factsList.map((article, idx) => (
            <FactsRow
              isExpanded={idx === activeRowId}
              onClick={() => onRowExpand(idx)}
              languageList={languageList}
              item={article}
              resetActiveRow={resetActiveRow}
              key={idx}
            />
          ))}
        </FactsTable>
      )}

      {isLoading && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </FactsTabWrapper>
  );
};

export default DidYouKnowTab;
