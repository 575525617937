import { styled } from '@mui/system';
import { Box, Tab } from '@mui/material';

export const LanguageTabsWrapper = styled(Box)`
  width: 100%;
`;

export const LanguageTabsContainer = styled(Box)`
  width: 100%;
  min-width: 320px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 16px;
  & .MuiTabs-flex-container {
    justify-content: flex-start;
  }
  & .MuiTab-root {
    margin-right: 7px;
  }
`;

export const LanguageTabButton = styled(Tab)`
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.51);
  width: 100%;
  max-width: 50px;
  min-width: 20px;
  max-height: 36px;
  min-height: 20px;
  padding: 12px 16px;
  margin: 6px 0px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  indicator-color: secondary;
  margin-right: 7px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid #ffa94c;
    outline-offset: 2px;
  }

  &:first-of-type {
    margin-left: 4px;
  }
  &:last-of-type {
    margin-right: 4px;
  }
  &.Mui-selected {
    background-color: #ffa94c;
    color: #fff;
    border-radius: 3px;
    outline: 2px solid #ffa94c;
    outline-offset: 2px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const LanguageTabPanel = styled(Box)`
  width: 100%;
  font-size: 0.875rem;
  div {
    padding: 0;
  }
`;
