import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ProductFormField } from '../../styled';

const useStyles = makeStyles({
  textWithCurrency: {
    '& .MuiOutlinedInput-root': {
      maxWidth: '215px',
      width: '100%',
    },
  },
});

const ProductEditFormTextField = ({
  name,
  disabled,
  required,
  maxLength,
  hasCurrency,
  currency,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: required,
          message: t('products.error_required'),
        },
        maxLength: {
          value: maxLength,
          message: t('visitors.error_max_length', {
            chars: maxLength,
          }),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ProductFormField
          className={clsx(hasCurrency && classes.textWithCurrency)}
          disabled={disabled}
          fullWidth
          size="small"
          onChange={(e) => onChange(e.target.value)}
          value={value}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: hasCurrency ? (
              <InputAdornment position="end">{currency}</InputAdornment>
            ) : null,
          }}
        />
      )}
    />
  );
};

export default ProductEditFormTextField;
