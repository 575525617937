import { connect } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PricesForm from './PricesForm';
import useAPIError from '../../../../apIErrorProvider/useAPIError';

import {
  addPricesThunk,
  getPricesListThunk,
} from '../../../../redux/attractionsReducers/priceReducer';
import { mapStringsToNumbers } from '../utils';
import { CURRENCIES } from '../../../../consts/consts';

const PriceAddFormContainer = (props) => {
  const {
    currentVat,
    attractionData,
    addPricesThunk,
    getPricesListThunk,
    currentDestinationData,
    currenciesList,
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const { currency } = currentDestinationData;

  const currencyCode =
    currenciesList &&
    currency &&
    currenciesList.parameters.find((curr) => curr.id === currency).value;

  const handleFormSubmit = (formData) => {
    setShowSaveAnimation(true);

    formData.startDate.setHours(12, 0, 0, 0);
    if (!formData.isOpened) formData.endDate.setHours(12, 0, 0, 0);

    const newPrice = {
      attractionId: attractionData.id,
      currency: attractionData.currency,
      relevancePeriod: {
        validFrom: formData.startDate.toISOString(),
        validTo: formData.isOpened ? null : formData.endDate.toISOString(),
      },
      standardPrice: mapStringsToNumbers(formData.standardPrice),
      contractPrice: mapStringsToNumbers(formData.contractPrice),
      valueAddedTaxAmount: mapStringsToNumbers(formData.valueAddedTaxAmount),
      netAmount: mapStringsToNumbers(formData.netAmount),
      valueAddedTax: parseInt(formData.valueAddedTaxAmount.valueAddedTax) || 0,
    };

    addPricesThunk(newPrice.attractionId, newPrice)
      .then(() => {
        getPricesListThunk(attractionData.id);
        setShowSaveAnimation(false);
        navigate(-1);
        addError(`${t('attractions.price.error_add_text')}`, 'Success');
      })
      .catch((error) => {
        addError(
          `${t('attractions.price.error_not_add_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleCancelButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      {currentVat && (
        <PricesForm
          onSubmit={handleFormSubmit}
          currentVat={currentVat}
          attractionData={attractionData}
          onCancel={handleCancelButtonClick}
          showSaveAnimation={showSaveAnimation}
          isInAddMode
          isInEditMode
          currencySymbol={currencyCode}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const currentVat = state.settingsReducer.destinationData.find(
    (item) => item.id === state.appReducer.selectedDestination.id
  );

  const currenciesList = state.appReducer.globalSettingsData.find(
    (setting) => setting.name.en === CURRENCIES
  );

  return {
    currenciesList,
    currentVat,
    attractionData: state.attractionsReducer.currentAttraction,
    currentDestinationData: state.appReducer.selectedDestination,
  };
};

export default connect(mapStateToProps, {
  addPricesThunk,
  getPricesListThunk,
})(PriceAddFormContainer);
