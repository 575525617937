import { stopSubmit } from 'redux-form';
import {
  addSeasonApi,
  getSeasonsListApi,
  editSeasonApi,
  deleteSeasonApi,
} from '../../api/attractionsApi.js';
const SET_SEASONS = 'SET_SEASONS';
const SET_CURRENT_SEASONS = 'SET_CURRENT_SEASONS';
const SET_IS_LOADING = 'SET_IS_LOADING';

const initialState = {
  seasonsList: [],
  currentSeason: {},
  isLoading: false,
};

const availabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEASONS:
      return {
        ...state,
        seasonsList: [...action.payload.data],
      };
    case SET_CURRENT_SEASONS:
      return {
        ...state,
        currentSeason: { ...action.payload.data },
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export const setSeason = (data) => {
  return { type: SET_SEASONS, payload: { data } };
};
export let setCurrentSeason = (data) => {
  return { type: SET_CURRENT_SEASONS, payload: { data } };
};
let setIsLoading = () => {
  return { type: SET_IS_LOADING };
};

export let getSeasonsThunk = (attractionId) => async (dispatch) => {
  let response = await getSeasonsListApi(attractionId);
  if (response.status >= 200 && response.status < 300) {
    dispatch(setSeason(response.data));
    dispatch(setIsLoading());
  }
};

export let addSeasonsThunk = (attractionId, data) => async (dispatch) => {
  let response = await addSeasonApi(attractionId, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Availability', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export let editSeasonsThunk = (seasonId, data) => async (dispatch) => {
  let response = await editSeasonApi(seasonId, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Availability', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export let deleteSeasonThunk = (seasonId) => async (dispatch) => {
  let response = await deleteSeasonApi(seasonId);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export default availabilityReducer;
