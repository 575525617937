import {
  Box,
  Button,
  Select,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

export const DealsPageContainer = styled(Box)`
  max-width: 1300px;
  width: 100%;
`;

export const DealAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const DealsTableFilterFormWrapper = styled(Box)`
  display: flex;
  gap: 24px;
  margin-top: 30px;
`;
