import { useTranslation } from 'react-i18next';

import { StyledSelect } from '../styled';

const createOptions = (options, selectedLanguage) => {
  if (!options) return null;

  return options.parameters.map((option, index) => (
    <option value={option.id} key={index}>
      {option.value[selectedLanguage]}
    </option>
  ));
};

const CategorySelectField = ({ optionsData, selectedLanguage, input }) => {
  const { t } = useTranslation();
  return (
    <StyledSelect native {...input} size="small">
      <option value="">{t('faq_page.categories.all_status')}</option>
      {createOptions(optionsData[0], selectedLanguage)}
    </StyledSelect>
  );
};

export default CategorySelectField;
