import { useEffect, useContext } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export const useNavigationBlocker = (
  onBlockedNavigation,
  shouldBlock = true
) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!shouldBlock) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      onBlockedNavigation(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, onBlockedNavigation, shouldBlock]);
};
