import { compose } from 'redux';
import { connect } from 'react-redux';

import AttractionVisitsList from './AttractionVisitsList';

import { getAttractionThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions';
import { DEAL_BENEFITS } from '../../../consts/consts';
import {
  getVisitsListThunk,
  getAttractionVisitsThunk,
} from '../../../redux/visits/visitsActions';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';

const mapStateToProps = (state) => {
  const benefitOptions =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_BENEFITS
    ) || null;

  return {
    isDataLoading: state.visitsReducer.isDataLoading,
    visitsList: state.visitsReducer.visitsList,
    paginationSettings: state.visitsReducer.paginationSettings,
    languages: state.appReducer.selectedDestination.languages,
    selectedLanguage: state.appReducer.selectedLanguage,
    benefitOptions,
  };
};

const AttractionVisitsListContainer = compose(
  connect(mapStateToProps, {
    getVisitsListThunk,
    getPredefinedSettingsThunk,
    getAttractionThunk,
    getAttractionVisitsThunk,
  })
)(AttractionVisitsList);

export default AttractionVisitsListContainer;
