import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';

import { makeStyles } from '@mui/styles';

import AttractionLanguageTabs from './AttractionLanguageTabs';
import ReduxImageDrop from './reduxImageDrop/ReduxImageDrop';

import {
  ContentFormHeader,
  ContentFormControlPanel,
  ContentFormSubmitButton,
  ContentFormButton,
  StyledFormControl,
  ContentFormFieldTitle,
  ContentFormImageTextContainer,
  ImageDropContainer,
  ContentFormTitleWrapper,
  ContentFormImageDropContainer,
} from './styled';

import { useNavigationPrompt } from '../../../hooks/navigation';
import { IMAGE_DROP_FILE_LIMIT } from './constants';
import { asyncValidate } from './validate';

const useStyles = makeStyles({
  form: {
    width: '900px',
    '& .MuiTextField-root': {
      width: '900px',
    },
    '& .MuiButton-root': {
      marginLeft: '10px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px;',
      '&.Mui-disabled': {
        textFillColor: 'rgba(0, 0, 0, 0.90)',
      },
    },
    '& .MuiImageListItem-root': {
      position: 'relative',
      '& .MuiImageListItem-img': {
        objectFit: 'cover',
        height: '100%',
        position: 'relative',
      },
    },
  },
  order: {
    width: '185px',
    '& .MuiTextField-root': {
      width: '185px',
      margin: '0px 0px 30px 0px',
    },
  },
  status: {
    width: '185px',
    marginBottom: '30px',
    '& .MuiTextField-root': {
      width: '185px',
      margin: '0px 0px 30px 0px',
    },
  },
});

let ContentForm = (props) => {
  const {
    handleSubmit,
    attractionData,
    isInEditMode,
    isSave,
    languages,
    setIsEditContent,
    dirty,
    selectedLanguage,
  } = props;

  useNavigationPrompt(dirty);

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onEditContent = () => {
    setIsEditContent(true);
  };

  const handleCancelButtonClick = () => {
    setIsEditContent(false);
    dispatch(reset('Content'));
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <ContentFormHeader>
        <ContentFormTitleWrapper>
          {attractionData.name ? attractionData.name : 'New price'}
        </ContentFormTitleWrapper>
        <ContentFormControlPanel>
          <ContentFormButton
            label={t('cancel')}
            onClick={handleCancelButtonClick}
            disabled={!isInEditMode}
          />
          <ContentFormButton
            label={t('attractions.content.edit_button')}
            onClick={onEditContent}
            disabled={isInEditMode}
          />
          <ContentFormSubmitButton
            isLoading={isSave}
            label={t('attractions.content.save_button')}
            disabled={!isInEditMode}
          />
        </ContentFormControlPanel>
      </ContentFormHeader>
      <StyledFormControl>
        <AttractionLanguageTabs
          isInEditMode={isInEditMode}
          languages={languages[0].languages}
          selectedLanguage={selectedLanguage}
        />
        <ImageDropContainer>
          <ContentFormFieldTitle>
            {t('attractions.content.image_for_app')}:
          </ContentFormFieldTitle>
          <ContentFormImageTextContainer>
            {t('attractions.content.image_text')}
          </ContentFormImageTextContainer>
        </ImageDropContainer>
        <ContentFormImageDropContainer>
          <Field
            name="appImages"
            component={ReduxImageDrop}
            disabled={!isInEditMode}
            allowMultipleFileSelect
            filesLimit={IMAGE_DROP_FILE_LIMIT}
            rowHeight={145}
            //validate={[maxImageSize]}
          />
        </ContentFormImageDropContainer>
        <ImageDropContainer>
          <ContentFormFieldTitle>
            {t('attractions.content.image_for_web')}:
          </ContentFormFieldTitle>
          <ContentFormImageTextContainer>
            {t('attractions.content.image_text_web')}
          </ContentFormImageTextContainer>
          <ContentFormImageDropContainer>
            <Field
              name="webImages"
              component={ReduxImageDrop}
              disabled={!isInEditMode}
              allowMultipleFileSelect
              filesLimit={IMAGE_DROP_FILE_LIMIT}
              rowHeight={145}
            />
          </ContentFormImageDropContainer>
        </ImageDropContainer>
      </StyledFormControl>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    initialValues: {
      title: state.attractionsReducer.currentAttraction.title,
      subtitle: state.attractionsReducer.currentAttraction.subtitle,
      introduction: state.attractionsReducer.currentAttraction.introduction,
      description: state.attractionsReducer.currentAttraction.description,
      tips: state.attractionsReducer.currentAttraction.tips,
      highlights: state.attractionsReducer.currentAttraction.highlights,
      directions: state.attractionsReducer.currentAttraction.directions,
      appImages: state.attractionsReducer.currentAttraction.appImages,
      webImages: state.attractionsReducer.currentAttraction.webImages,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'Content',
    enableReinitialize: true,
    asyncValidate,
    asyncChangeFields: ['appImages', 'webImages'],
  })(ContentForm)
);
