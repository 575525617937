import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import NotificationDateField from '../formFields/NotificationDateField';
import NotificationStatusField from '../formFields/NotificationStatusField';
import NotificationCheckboxField from '../formFields/NotificationCheckboxField';
import NotificationMultiselectField from '../formFields/NotificationMultiselectField';

import {
  NewNotificationFormControlPanel,
  NotificationAddFormControlPanel,
  NotificationFormFieldTitle,
  NotificationFormFieldWrapper,
} from '../styled';

import {
  FormButton,
  SaveButton,
} from '../../../../components/buttons/FormButtons';
import NotificationLanguageTabFields from '../formFields/NotificationLanguageTabFields';

const AddNewNotificationForm = ({
  selectedLanguage,
  statusOptions,
  availableAttractions,
  languages,
  showSaveAnimation,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentTabId, setCurrentTabId] = useState(languages[0]);

  const languageFormFieldArrays = useMemo(
    () =>
      languages.reduce((acc, lang) => {
        acc[lang] = [
          {
            title: '',
            text: '',
          },
        ];

        return acc;
      }, {}),
    [languages]
  );

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      startDate: null,
      endDate: null,
      startDateEvent: null,
      endDateEvent: null,
      status: statusOptions[0].id,
      isGeneralNotification: false,
      attractions: [],
      ...languageFormFieldArrays,
    },
  });

  const { watch, handleSubmit, setValue } = formMethods;

  const startDateFieldValue = watch('startDate');
  const endDateFieldValue = watch('endDate');
  const startDateEventFieldValue = watch('startDateEvent');
  const endDateEventFieldValue = watch('endDateEvent');
  const isGeneralNotificationValue = watch('isGeneralNotification');
  const hasEvent = watch('hasEvent');

  useEffect(()=>{
    if (!hasEvent) {
      setValue('startDateEvent', null); 
      setValue('endDateEvent', null); 
    }
  }, [hasEvent])

  const handleChangeLanguageTab = (_, newValue) => setCurrentTabId(newValue);

  const onCancelClick = () => navigate(-1);

  return (
    <Box maxWidth="1300px" width="100%" margin="95px auto 20px auto">
      <FormProvider {...formMethods}>
        <form>
          <Box display="flex" flexDirection="column">
            <NotificationAddFormControlPanel>
              <Typography
                fontWeight={500}
                fontSize="20px"
                textTransform="uppercase"
              >
                {t('notifications.new_notification')}
              </Typography>
              <Box display="flex" gap="10px">
                <FormButton label={t('cancel')} onClick={onCancelClick} />
                <SaveButton
                  label={t('attractions.deals.buttons.save')}
                  onClick={handleSubmit(onSubmit)}
                  isLoading={showSaveAnimation}
                />
              </Box>
            </NotificationAddFormControlPanel>

            <Box display="flex">
              <NotificationLanguageTabFields
                languages={languages}
                handleChangeLanguageTab={handleChangeLanguageTab}
                currentTabId={currentTabId}
              />
            </Box>

            <NotificationFormFieldWrapper>
              <NotificationFormFieldTitle>
                {t('notifications.status')}:
              </NotificationFormFieldTitle>
              <NotificationStatusField
                name="status"
                selectedLanguage={selectedLanguage}
                options={statusOptions}
              />
            </NotificationFormFieldWrapper>

          <div style = {{display: 'flex', gap: '50px'}}>
            <div>
              <NotificationFormFieldWrapper>
                <NotificationFormFieldTitle>
                  {t('notifications.start_date')}:
                </NotificationFormFieldTitle>
                <NotificationDateField
                  name="startDate"
                  placeholder={t('notifications.start_date')}
                  maxDate={endDateFieldValue}
                  required
                />
              </NotificationFormFieldWrapper>

              <NotificationFormFieldWrapper>
                <NotificationFormFieldTitle>
                  {t('notifications.end_date')}:
                </NotificationFormFieldTitle>
                <NotificationDateField
                  name="endDate"
                  placeholder={t('notifications.end_date')}
                  minDate={startDateFieldValue}
                  required
                />
              </NotificationFormFieldWrapper>
            </div>
            <div style= {{marginLeft: '60px', display: 'flex', alignItems: 'center'}}>
              <NotificationFormFieldWrapper>
                <NotificationCheckboxField
                  name="hasEvent"
                  label={t('notifications.has_event')}
                />
              </NotificationFormFieldWrapper>
            </div>
            <div>
              <NotificationFormFieldWrapper>
                <NotificationFormFieldTitle>
                  {t('notifications.start_event_date')}:
                </NotificationFormFieldTitle>
                <NotificationDateField
                  name="startDateEvent"
                  placeholder={t('notifications.start_event_date')}
                  maxDate={endDateEventFieldValue}
                  required = {hasEvent}
                  disabled={!hasEvent}
                />
                </NotificationFormFieldWrapper>

              <NotificationFormFieldWrapper>
                <NotificationFormFieldTitle>
                  {t('notifications.end_event_date')}:
                </NotificationFormFieldTitle>
                <NotificationDateField
                  name="endDateEvent"
                  placeholder={t('notifications.end_event_date')}
                  minDate={startDateEventFieldValue}
                  required = {hasEvent}
                  disabled={!hasEvent}
                />
              </NotificationFormFieldWrapper>
            </div>
          </div>
            <NotificationFormFieldWrapper>
              <NotificationCheckboxField name="isGeneralNotification" />
            </NotificationFormFieldWrapper>

            {!isGeneralNotificationValue && (
              <NotificationFormFieldWrapper>
                <NotificationCheckboxField
                  name="isVisibleForGeneralSection"
                  label={t('notifications.visible_for_general_section')}
                />
              </NotificationFormFieldWrapper>
            )}

            <NotificationFormFieldWrapper>
              <NotificationFormFieldTitle>
                {t('notifications.attraction')}:
              </NotificationFormFieldTitle>
              <NotificationMultiselectField
                name="attractions"
                optionsData={availableAttractions}
                selectedLanguage={selectedLanguage}
                disabled={isGeneralNotificationValue}
                required={!isGeneralNotificationValue}
              />
            </NotificationFormFieldWrapper>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AddNewNotificationForm;
