import { useState, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';

import {
  expandFilteredNodesWithoutParrent,
  filterTreeWithoutParrentNode,
  getUniqueToggledNodeIDs,
} from './filterTreeHelpers';

import cl from '../Destination.module.css';
import { ICON_DEFAULT_COLOR } from '../constants';
import { getExpandedIdsFromUrl, getSelectedItemIdFromUrl } from '../utils';

const LocationTreeView = ({
  locationData,
  onFilterFieldChange,
  onDestinationClick,
  onRegionClick,
  onAreaClick,
  onLocationClick,
  onAddRegion,
  onAddLocation,
  onAddArea,
  classes,
  ...props
}) => {
  const { t } = useTranslation();
  const { destinationId, regionId, areaId, locationId } = useParams();
  const [expandedItemIDs, setExpandedItemIDs] = useState(
    getExpandedIdsFromUrl(destinationId, regionId, areaId, locationId)
  );
  const [selectedItemId, setSelectedItemID] = useState(
    getSelectedItemIdFromUrl(destinationId, regionId, areaId, locationId)
  );
  const filterInputRef = useRef(null);

  const handleFilterFieldChange = (e) => {
    const value = e.target.value;
    const filter = value.trim();

    let expandedTemp = expandedItemIDs;

    if (filter.length >= 3) {
      let filtered = filterTreeWithoutParrentNode(locationData, filter);

      filtered = expandFilteredNodesWithoutParrent(filtered, filter);

      if (filter.length !== 0) {
        expandedTemp = [];
        expandedTemp.push(...getUniqueToggledNodeIDs(filtered));
      } else {
        expandedTemp = [];
      }

      setExpandedItemIDs(expandedTemp);
    }

    if (filter.length < 3) {
      setExpandedItemIDs([]);
      return;
    }
  };

  const handleTreeItemToggle = (_, nodeIds) => {
    setExpandedItemIDs(nodeIds);
  };

  const handleTreeItemSelect = (_, nodeId) => {
    setSelectedItemID(nodeId);
  };

  const handleResetFilter = () => {
    setExpandedItemIDs([]);
    filterInputRef.current.value = '';
  };

  return (
    <>
      <Box mb="20px">
        <TextField
          name="locationFilter"
          label="Location filter"
          onChange={(e) => handleFilterFieldChange(e)}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ color: ICON_DEFAULT_COLOR }} />
              </InputAdornment>
            ),
            inputRef: filterInputRef,
          }}
        />
        <IconButton
          onClick={handleResetFilter}
          sx={{
            marginLeft: '5px',
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>

      <TreeView
        className={classes.treeView}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ flexGrow: 1 }}
        onNodeToggle={handleTreeItemToggle}
        onNodeSelect={handleTreeItemSelect}
        selected={selectedItemId}
        expanded={expandedItemIDs}
      >
        {locationData?.map((destination, index) => (
          <TreeItem
            className={classes.item}
            nodeId={destination.id}
            label={destination.name}
            onClick={(event) => onDestinationClick(event, destination)}
            key={destination.id}
          >
            {destination.enabledLocationTypes.length
              ? destination.locations.map((region) => (
                  <Fragment key={region.id}>
                    <TreeItem
                      className={classes.item}
                      nodeId={region.id}
                      label={region.title[destination.languages[0]]}
                      onClick={(event) => onRegionClick(event, region)}
                      sx={{ marginLeft: '20px' }}
                    >
                      {region.children.map((area) => (
                        <TreeItem
                          className={classes.item}
                          sx={{ marginLeft: '20px' }}
                          nodeId={area.id}
                          label={area.title[destination.languages[0]]}
                          onClick={(event) => onAreaClick(event, area)}
                          key={area.id}
                        >
                          {area.children.map((location) => (
                            <TreeItem
                              nodeId={location.id}
                              label={location.title[destination.languages[0]]}
                              onClick={(event) =>
                                onLocationClick(event, location)
                              }
                              key={location.id}
                            />
                          ))}
                          {destination.enabledLocationTypes.includes(
                            'Region',
                            0
                          ) &&
                          destination.enabledLocationTypes.includes(
                            'Area',
                            0
                          ) &&
                          destination.enabledLocationTypes.includes(
                            'Location',
                            0
                          ) ? (
                            <div className={cl.addLocalButton}>
                              <Button
                                color="inherit"
                                onClick={() =>
                                  onAddLocation(index, area, destination)
                                }
                                sx={{ fontSize: '12px' }}
                              >
                                {t(
                                  'settings_page.destination.add_location_button'
                                )}
                              </Button>
                            </div>
                          ) : null}
                        </TreeItem>
                      ))}
                      {destination.enabledLocationTypes.includes('Region', 0) &&
                      !destination.enabledLocationTypes.includes('Area', 0) &&
                      !destination.enabledLocationTypes.includes(
                        'Location',
                        0
                      ) ? null : !destination.enabledLocationTypes.includes(
                          'Region',
                          0
                        ) &&
                        destination.enabledLocationTypes.includes('Area', 0) &&
                        !destination.enabledLocationTypes.includes(
                          'Location',
                          0
                        ) ? null : !destination.enabledLocationTypes.includes(
                          'Region',
                          0
                        ) &&
                        !destination.enabledLocationTypes.includes('Area', 0) &&
                        destination.enabledLocationTypes.includes(
                          'Location',
                          0
                        ) ? null : !destination.enabledLocationTypes.includes(
                          'Region',
                          0
                        ) &&
                        destination.enabledLocationTypes.includes('Area', 0) &&
                        destination.enabledLocationTypes.includes(
                          'Location',
                          0
                        ) ? (
                        <div className={cl.addAreaButton}>
                          <Button
                            color="inherit"
                            onClick={() =>
                              onAddLocation(index, region, destination)
                            }
                            sx={{ fontSize: '12px' }}
                          >
                            {t('settings_page.destination.add_location_button')}
                          </Button>
                        </div>
                      ) : destination.enabledLocationTypes.includes(
                          'Region',
                          0
                        ) &&
                        !destination.enabledLocationTypes.includes('Area', 0) &&
                        destination.enabledLocationTypes.includes(
                          'Location',
                          0
                        ) ? (
                        <div className={cl.addAreaButton}>
                          <Button
                            color="inherit"
                            onClick={() =>
                              onAddLocation(index, region, destination)
                            }
                            sx={{ fontSize: '12px' }}
                          >
                            {t('settings_page.destination.add_location_button')}
                          </Button>
                        </div>
                      ) : (
                        <div className={cl.addAreaButton}>
                          <Button
                            color="inherit"
                            onClick={() =>
                              onAddArea(index, region, destination)
                            }
                            sx={{ fontSize: '12px' }}
                          >
                            {t('settings_page.destination.add_area_button')}
                          </Button>
                        </div>
                      )}
                    </TreeItem>
                  </Fragment>
                ))
              : null}
            {destination.enabledLocationTypes.includes('Region', 0) ? (
              <div className={cl.addLocationButton}>
                <Button
                  color="inherit"
                  onClick={() => onAddRegion(index, destination)}
                  sx={{ fontSize: '12px' }}
                >
                  {t('settings_page.destination.add_region_button')}
                </Button>
              </div>
            ) : !destination.enabledLocationTypes.includes('Region', 0) &&
              destination.enabledLocationTypes.includes('Area', 0) ? (
              <div className={cl.addLocationButton}>
                <Button
                  color="inherit"
                  onClick={() => onAddArea(index, null, destination)}
                  sx={{ fontSize: '12px' }}
                >
                  {t('settings_page.destination.add_area_button')}
                </Button>
              </div>
            ) : !destination.enabledLocationTypes.includes('Region', 0) &&
              !destination.enabledLocationTypes.includes('Area', 0) &&
              destination.enabledLocationTypes.includes('Location', 0) ? (
              <div className={cl.addLocationButton}>
                <Button
                  color="inherit"
                  onClick={() => onAddLocation(index, null, destination)}
                  sx={{ fontSize: '12px' }}
                >
                  {t('settings_page.destination.add_location_button')}
                </Button>
              </div>
            ) : null}
          </TreeItem>
        ))}
      </TreeView>
    </>
  );
};
export default LocationTreeView;
