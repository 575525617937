import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import {
  AdvertContentFormDatePicker,
  AdvertContentFormImageDrop,
  AdvertContentFormSelectField,
} from './formFields';
import AdvertContentFormLanguageTabs from './languageTabs/AdvertContentFormLanguageTabs';

import {
  AdvertContentFormWrapper,
  AdvertContentFormFieldWrapper,
  AdvertContentFormFieldTitle,
  ImageDropWrapper,
  AdvertContentFormUploadInstructionWrapper,
  AdvertContentFormImageUploadBlock,
} from '../styled';

import { ALLOWED_FILE_TYPES } from '../constants';

const AdvertContentForm = (props) => {
  const {
    isInEditMode,
    languageList,
    advertContentStatuses,
    selectedLanguage,
    redirectToOptions,
  } = props;

  const { t } = useTranslation();

  const [activeTabId, setActiveTabId] = useState(languageList.parameters[0].id);
  const handleLanguageTabChange = (_, newValue) => setActiveTabId(newValue);

  return (
    <AdvertContentFormWrapper>
      <form>
        <AdvertContentFormFieldWrapper>
          <AdvertContentFormFieldTitle>
            {t('advertWindow.formFieldLabels.status')}
          </AdvertContentFormFieldTitle>
          <AdvertContentFormSelectField
            name="status"
            selectedLanguage={selectedLanguage}
            options={advertContentStatuses}
            disabled={!isInEditMode}
            required
          />
        </AdvertContentFormFieldWrapper>

        <AdvertContentFormFieldWrapper>
          <AdvertContentFormFieldTitle>
            {t('advertWindow.formFieldLabels.redirectTo')}
          </AdvertContentFormFieldTitle>
          <AdvertContentFormSelectField
            name="redirectTo"
            selectedLanguage={selectedLanguage}
            options={redirectToOptions}
            disabled={!isInEditMode}
            required
          />
        </AdvertContentFormFieldWrapper>

        <AdvertContentFormFieldWrapper>
          <AdvertContentFormFieldTitle>
            {t('advertWindow.formFieldLabels.date')}
          </AdvertContentFormFieldTitle>
          <AdvertContentFormDatePicker disabled={!isInEditMode} name="date" />
        </AdvertContentFormFieldWrapper>
        <AdvertContentFormLanguageTabs
          activeTabId={activeTabId}
          onLanguageTabChange={handleLanguageTabChange}
          languages={languageList}
          disabled={!isInEditMode}
        />
        <AdvertContentFormFieldWrapper>
          <AdvertContentFormFieldTitle>
            {t('advertWindow.formFieldLabels.mobileImage')}
          </AdvertContentFormFieldTitle>
          <AdvertContentFormImageUploadBlock>
            <AdvertContentFormUploadInstructionWrapper>
              {t('advertWindow.mobile_image_text')}
            </AdvertContentFormUploadInstructionWrapper>
            <ImageDropWrapper>
              <AdvertContentFormImageDrop
                name="mobileImage"
                disabled={!isInEditMode}
                columns={1}
                filesLimit={1}
                allowedFileTypes={ALLOWED_FILE_TYPES}
              />
            </ImageDropWrapper>
          </AdvertContentFormImageUploadBlock>
        </AdvertContentFormFieldWrapper>
        <AdvertContentFormFieldWrapper>
          <AdvertContentFormFieldTitle>
            {t('advertWindow.formFieldLabels.webImage')}
          </AdvertContentFormFieldTitle>
          <AdvertContentFormImageUploadBlock>
            <AdvertContentFormUploadInstructionWrapper>
              {t('advertWindow.web_image_text')}
            </AdvertContentFormUploadInstructionWrapper>
            <ImageDropWrapper>
              <AdvertContentFormImageDrop
                  name="webImage"
                  disabled={!isInEditMode}
                  columns={1}
                  filesLimit={1}
                  allowedFileTypes={ALLOWED_FILE_TYPES}
              />
            </ImageDropWrapper>
          </AdvertContentFormImageUploadBlock>
        </AdvertContentFormFieldWrapper>
      </form>
    </AdvertContentFormWrapper>
  );
};

export default AdvertContentForm;
