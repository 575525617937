import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../consts/validation';

export function required(value) {
  return value == null ? 'Required' : undefined;
}

export function requiredTextEditor(value) {
  return value && (value.length > 8 ? undefined : 'Required');
}

export function hasNotOnlySpaces(value) {
  return value
    ? value.trim().length === 0
      ? 'Cant contain only spaces'
      : undefined
    : undefined;
}

export function isNotEmpty(value) {
  return value.trim().length === 0 ? 'Must be not empty' : undefined;
}

export function ListIsNotEmpty(value) {
  if (!value == null) {
    return value.length === 0 ? 'Must be not empty' : undefined;
  }
  return 'Must be not empty';
}

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);
export const maxLength1000 = maxLength(1000);
export const maxLength3000 = maxLength(3000);
