import { styled } from '@mui/system';
import { Button, Box, Typography } from '@mui/material';

export const FacilityAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const FacilityFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const FacilityFormFieldTitle = styled(Typography)`
  max-width: 150px;
  width: 100%;
  color: black;
`;

export const FacilityFormHeader = styled(Box)`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
`;

export const FacilityFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const FacilityFormImageDropWrapper = styled(Box)`
  max-width: 400px;
  width: 100%;
`;

export const FacilityFormWrapper = styled(Box)`
  margin-top: 30px;
`;
