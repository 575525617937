import {
  DEFAULT_EMAIL_FIELD_MAX_LENGTH,
  DEFAULT_TEXT_FIELD_MAX_LENGTH,
  DEFAULT_PHONE_FIELD_MAX_LENGTH
} from '../../../../consts/validation';

export function isNotEmpty(value) {
  return value.trim().length === 0 ? 'Must be not empty' : undefined;
}

export function ListIsNotEmpty(value) {
  return value && value.length === 0 ? 'Must be not empty' : undefined;
}

export const isPasswordValid = (password) => {
  const isNotValid = false;
  const isValid = true;
  const lowerCaseLetters = '(?=.*[a-z])';
  const upperCaseLetters = '(?=.*[A-Z])';
  const numbers = '(?=.*[0-9])';
  const length = '(?=.{8,})';
  const validation = new RegExp(
    '^' +
      lowerCaseLetters +
      upperCaseLetters +
      numbers +
      length
  );

  if (validation.test(password)) return isValid;
  else return isNotValid;
};

export const onlyNumber = (value) =>
  value && isNaN(Number(value)) ? 'Enter only number' : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLengthTextField = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);
export const maxLengthPhoneTextField = maxLength(DEFAULT_PHONE_FIELD_MAX_LENGTH);
export const maxLengthEmail = maxLength(DEFAULT_EMAIL_FIELD_MAX_LENGTH);
