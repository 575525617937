import { TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const NotificationTableHead = styled(TableHead)`
  .MuiTableCell-root {
    :nth-of-type(1) {
      width: 0%;
    }
    :nth-of-type(2) {
      width: 20%;
    }
    :nth-of-type(3) {
      width: 20%;
    }
    :nth-of-type(4) {
      width: 5%;
    }
    :nth-of-type(5) {
      width: 5%;
    }
    :nth-of-type(6) {
      width: 0%;
    }
  }
`;
export const NotificationTableHeadRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const NotificationTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  height: 57px;
  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    background-color: #ffa94c;
  }
`;
