import * as axios from 'axios';
import authService from '../../auth/AuthorizeService';
import { BASE_URL } from '../../config.js';

const URL = `${BASE_URL}/api/MainImages`;

export const createImageRecord = async (data) => {
  const token = await authService.getAccessToken();

  return await axios
    .post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getImageRecordList = async (destinationId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/destinations/${destinationId}/mainImages`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateImageRecord = async (recordId, data) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/${recordId}`;

  return await axios
    .put(targetUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteImageRecord = async (recordId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/${recordId}`;

  return await axios
    .delete(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const uploadImageFile = async (formData, type) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/files/images/upload?type=${type}`;

  return await axios
    .post(targetUrl, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
