import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';

const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);
export const maxLength1000 = maxLength(1000);

export const requiredTitle = (values) => (!values ? 'Enter title' : undefined);
export const requiredText = (values) => (!values ? 'Enter text' : undefined);

export function requiredCategory(value) {
  return !value ? 'Select category' : undefined;
}

export function requiredStatus(value) {
  return !value ? 'Select status' : undefined;
}

export const noSpaceName = (values) =>
  values && values.trim() === ''
    ? 'Name cannot consist of just spaces'
    : undefined;
