import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import HelpTopicForm from './HelpTopicForm';
import useAPIError from '../../../../apIErrorProvider/useAPIError';

import { createHelpTopicThunk } from '../../../../redux/publishingReducers/helpTopicsReducer/helpTopicActions';

import { sortLanguageListInDestinationSettingsOrder } from '../../../../helpers/sortLanguageListInDestinationSettingsOrder';
import { formatLanguageFieldsData } from '../utils';
import { removeTextEditorValueKeysWithNoPlainText } from '../../../../helpers/translationObjectUtils';

import {ACTIVITY_STATUS, CONTENT_LANGUAGES, HELP_TOPIC_TYPE} from '../../../../consts/consts';
import {DEFAULT_STATUS_VALUE, DEFAULT_TYPE_VALUE} from "../constants";

const HelpTopicFormContainer = () => {
  const { addError } = useAPIError();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const selectedAppLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );
  const languageList = useSelector((state) =>
    state.appReducer?.globalSettingsData?.find(
      (item) => item.name.en === CONTENT_LANGUAGES
    )
  );

  const selectedDestinationLanguages = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );
  const selectedDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );

  const activityStatuses = useSelector((state) =>
    state.appReducer?.globalSettingsData.find(
      (item) => item.name.en === ACTIVITY_STATUS
    )
  );

  const helpTopicTypes = useSelector((state) =>
      state.appReducer?.globalSettingsData.find(
          (item) => item.name.en === HELP_TOPIC_TYPE
      )
  );

  const destinationLanguageList = {
    ...languageList,
    parameters: sortLanguageListInDestinationSettingsOrder(
      languageList.parameters,
      selectedDestinationLanguages
    ),
  };

  const languageFormFieldArrays =
    selectedDestinationLanguages &&
    selectedDestinationLanguages.reduce((acc, lang) => {
      acc[lang] = [
        {
          title: '',
          text: '',
        },
      ];

      return acc;
    }, {});

  const defaultValues = {
    ...languageFormFieldArrays,
    status: DEFAULT_STATUS_VALUE,
    type: DEFAULT_TYPE_VALUE
  };
  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { handleSubmit, reset } = formMethods;
  const navigate = useNavigate();

  const handleFormSubmit = (formValues) => {
    setShowSaveAnimation(true);
    const fieldsWithTranslations = formatLanguageFieldsData(formValues);

    const newHelpTopicData = {
      destinationId: selectedDestinationId,
      status: formValues.status,
      type: formValues.type,
      title: removeTextEditorValueKeysWithNoPlainText(
        fieldsWithTranslations.title
      ),
      text: removeTextEditorValueKeysWithNoPlainText(
        fieldsWithTranslations.text
      ),
    };

    dispatch(createHelpTopicThunk(newHelpTopicData))
      .then(() => {
        setShowSaveAnimation(false);
        addError(`${t('helpTopics.notifications.create_success')}`, 'Success');
        navigate(-1, { replace: true });
      })
      .catch((e) => {
        setShowSaveAnimation(false);
        addError(`${t('helpTopics.notifications.create_error')}`, 'Error', e);
      });
  };

  const handleFormCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (selectedDestinationLanguages) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDestinationLanguages, reset]);

  return (
    <>
      {destinationLanguageList?.parameters?.[0]?.id && (
        <FormProvider {...formMethods}>
          <HelpTopicForm
            languageList={destinationLanguageList}
            isLoading={showSaveAnimation}
            onSubmit={handleSubmit(handleFormSubmit)}
            onCancel={handleFormCancel}
            helpTopicStatuses={activityStatuses}
            helpTopicTypes={helpTopicTypes}
            selectedLanguage={selectedAppLanguage}
            isInEditMode
          />
        </FormProvider>
      )}
    </>
  );
};

export default HelpTopicFormContainer;
