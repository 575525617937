import { connect } from 'react-redux';

import Company from './Company';

import {
  getUsersListThunk,
  getUserSettingsThunk,
} from '../../../redux/usersReducers/users/userActions';
import {
  editCompanyThunk,
  getCurrentCompanyThunk,
  getAttractionListOfCompaniesThunk,
} from '../../../redux/usersReducers/company/companyActions';
import { getSmallAttractionsDataThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions';

import { USER_STATUS, USER_ROLES } from '../../../consts/consts';
import { isDirty } from 'redux-form';

const mapStateToProps = (state) => {
  const isCompanyFormDirty = isDirty('CompanyForm')(state);
  const isManagedAttractionsFormDirty = isDirty('ManagedAttractionsForm')(
    state
  );

  const statusOptions = state.userReducer.userSettings.find(
    (item) => item.name.en === USER_STATUS
  );
  const userRoles = state.userReducer.userSettings.find(
    (item) => item.name.en === USER_ROLES
  );
  return {
    isCompanyFormDirty,
    isManagedAttractionsFormDirty,
    userRoles,
    userList: state.userReducer.usersData,
    companyData: state.companyReducer.currentCompany,
    statusOptions,
    userSettings: state.userReducer.userSettings,
    availableAttractions: state.attractionsReducer.attractionsSmallData,
    globalSettings: state.appReducer.globalSettingsData,
    selectedDestinationId: state.appReducer.selectedDestination.id,
    settingsReducer: state.settingsReducer,
    selectedLanguage: state.appReducer.selectedLanguage,
    userReducer: state.userReducer,
    attractionsData: state.attractionsReducer.attractionsSmallData,
    currentAttraction: state.attractionsReducer.currentAttraction,
    attractionListOfCompanies: state.companyReducer.attractionListOfCompanies,
  };
};

const CompanyContainer = connect(mapStateToProps, {
  editCompanyThunk,
  getAttractionListThunk: getSmallAttractionsDataThunk,
  getUserSettingsThunk,
  getUsersListThunk,
  getCurrentCompanyThunk,
  getAttractionListOfCompaniesThunk,
})(Company);

export default CompanyContainer;
