import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Tab, TextField } from '@mui/material';

import { ReduxTextField } from '../../components/reduxFormComponents/reduxFormComponents';

export const LanguageTabsContainer = styled(Box)`
  min-width: 320px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
  & .MuiTabs-flexContainer {
    justify-content: flex-start;
  }

  & .MuiTab-root {
    margin-right: 7px;
  }
`;

export const LanguageTab = styled(Tab)`
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.51);
  width: 100%;
  max-width: 50px;
  min-width: 20px;
  max-height: 36px;
  min-height: 20px;
  padding: 12px 16px;
  margin: 6px 0px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid #ffa94c;
    outline-offset: 2px;
  }
  &:first-of-type {
    margin-left: 4px;
  }
  &:last-child {
    margin-right: 4px;
  }
  &.Mui-selected {
    background-color: #ffa94c;
    color: #fff;
    border-radius: 3px;
    outline: 2px solid #ffa94c;
    outline-offset: 2px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const LanguageTabPanel = styled(Box)`
  width: 100%;
  font-size: 0.875rem;
  & .MuiBox-root {
    padding: 0;
  }
  & .MuiOutlinedInput-root {
    & textarea {
      height: 65px !important;
    }
    & .Mui-disabled {
      color: #000;
    }
  }
`;

export const LanguageTabsFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const LanguageTabsFieldTitle = styled(Box)`
  white-space: nowrap;
  max-width: 215px;
  width: 100%;
`;

export const LanguageTabsTextField = styled(ReduxTextField)`
  width: 100%;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const LanguageTabsTextFieldDisabled = styled(TextField)`
  width: 100%;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;
