import {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection, getFormValues, change} from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Box, Tabs, Stack } from '@mui/material';

import TextEditor from '../RichTextEditor/TextEditor';

import {
  LanguageTabsFieldWrapper,
  LanguageTabsFieldTitle,
  LanguageTabsTextField,
  LanguageTabsContainer,
  LanguageTab,
  LanguageTabPanel,
} from './styled';

import {
  maxLength250,
  hasNotOnlySpaces,
  requiredTextEditor,
  maxLength3000,
} from './validation';
import { TranslateButton } from '../buttons/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { removeKeysWithEmptyValueArrays, removeTextEditorValueKeysWithNoPlainText } from '../../helpers/translationObjectUtils';
import { editAttractionNotificationTranslationThunk, setNotification } from '../../redux/attractionsReducers/notificationReducer/notificationActions';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <LanguageTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            fontSize: '16px',
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </LanguageTabPanel>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const NotificationLanguageTabs = (props) => {
  const { item, languages, isEdit, selectedLanguage } = props;
  const [tabsValue, setTabsValue] = useState(0);
  const { t } = useTranslation();
  const [valueDirty, setValueDirty] = useState(false);
  const dispatch = useDispatch();
  const handleChangeTabs = (_, newValue) => {
    setTabsValue(newValue);
    setValueDirty(true);
  };

  const defaultLanguage = useSelector(state => state.appReducer.selectedDestination.languages[0]);

  const attractionData = useSelector(state => state.attractionsReducer.currentAttraction);
  const currentAttractionId = attractionData.id;
  const selectedDestinationId =  useSelector(state => state.appReducer.selectedDestination.id);

  const values = useSelector(state => getFormValues('NotificationForm')(state));
  const [valuesState, setValuesState] = useState(values);
  
  const onTranslate = async(translationField,translationLanguages) => {
    const updatedNotification = {
      destinationId: selectedDestinationId,
      attractionId: currentAttractionId,
      notificationId: values.id,
      title: removeKeysWithEmptyValueArrays(values.title),
      text: removeTextEditorValueKeysWithNoPlainText(values.text),
      attractions: [...values.attractions],
      isGeneralNotification: values.isGeneralNotification,
      isVisibleForGeneralSection: values.isVisibleForGeneralSection,
      translationField,
      translationLanguages,
    };
    
    return dispatch(editAttractionNotificationTranslationThunk(updatedNotification))
      .then((response) => {
        Object.entries(response[translationField]).forEach(([lang, translatedValue]) => {
          dispatch(change('NotificationForm', `${translationField}.${lang}`, translatedValue));
        });
        
        const newNotification = {
          ...item,
          notTranslatedFields: response.notTranslatedFields,
        }        
        
        dispatch(setNotification(newNotification));

        setValuesState(prev => ({
          ...prev,
          [translationField]: response[translationField],
        }))
      }).catch(error => {
        throw error;
      });
  };

  const required = useCallback(value => (value ? undefined : t('required')), [selectedLanguage]);

  const LanguageTabs = languages?.map((tab, index) => (
    <LanguageTab label={tab.value} key={tab.id} {...a11yProps(index)} />
  ));
  
  return (
    <>
      <LanguageTabsContainer>
        <Tabs
          value={tabsValue}
          onChange={handleChangeTabs}
          indicatorColor={undefined}
          sx={{
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          {LanguageTabs}
        </Tabs>
      </LanguageTabsContainer>
      {languages?.map((tabPanel, index) => (
        <TabPanel value={tabsValue} index={index} key={index}>
          <Stack spacing={2}>
            <LanguageTabsFieldWrapper>
              <LanguageTabsFieldTitle>
                {t('notifications.title')}:
              </LanguageTabsFieldTitle>
              <FormSection name="title">
                <div style={{display: 'flex', width:'100%', gap: '10px', alignItems: 'center'}}>
                  <Field
                    name={tabPanel.value}
                    component={LanguageTabsTextField}
                    validate={[required, maxLength250, hasNotOnlySpaces]}
                    disabled={!isEdit}
                  />
                  <TranslateButton 
                    title={t('notifications.title')} 
                    tab={tabsValue}
                    disabled={!isEdit}
                    field={'title'}
                    data = {values && values.title}
                    notTranslated={item?.notTranslatedFields && item.notTranslatedFields.title}
                    differentData = {values?.title && values?.title[defaultLanguage] !== valuesState?.title[defaultLanguage]}
                    handleSendTranslation={onTranslate}
                  />
                </div>
              </FormSection>
            </LanguageTabsFieldWrapper>
            <LanguageTabsFieldWrapper>
              <LanguageTabsFieldTitle>
                {t('notifications.text')}:
              </LanguageTabsFieldTitle>
              <FormSection name="text">
                <div style={{display: 'flex', width:'100%', gap: '10px'}}>
                  <Field
                    name={tabPanel.value}
                    component={TextEditor}
                    isEdit={isEdit}
                    valueDirty={valueDirty}
                    setValueDirty={setValueDirty}
                    validate={[
                      requiredTextEditor,
                      maxLength3000,
                      hasNotOnlySpaces,
                    ]}
                  />
                  <TranslateButton 
                    title={t('notifications.text')} 
                    tab={tabsValue}
                    disabled={!isEdit}
                    field={'text'}
                    data = {values && values.text}
                    notTranslated={item?.notTranslatedFields && item.notTranslatedFields.text}
                    differentData = {values?.text && values?.text[defaultLanguage] !== valuesState?.text[defaultLanguage]}
                    handleSendTranslation={onTranslate}
                  />
                </div>
              </FormSection>
            </LanguageTabsFieldWrapper>
          </Stack>
        </TabPanel>
      ))}
    </>
  );
};

export default NotificationLanguageTabs;
