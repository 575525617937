import { MobileDatePicker } from '@mui/lab';
import { Box, TableHead, TableRow, TextField } from '@mui/material';

import { styled } from '@mui/system';

export const VisitsPageContainer = styled(Box)`
  max-width: 1300px;
  width: 100%;
  margin: 95px auto 20px auto;
`;

export const StyledVisitsTableHead = styled(TableHead)`
  .MuiTableCell-root {
    padding: 5px 16px;

    :nth-of-type(1) {
      text-align: center;
      width: 10%;
    }
    :nth-of-type(2) {
      width: 12%;
    }
    :nth-of-type(3) {
      width: 25%;
    }
    :nth-of-type(4) {
      text-align: center;
      width: 5%;
    }
    :nth-of-type(5) {
      width: 10%;
      text-align: center;
    }
    :nth-of-type(6) {
      width: 5%;
      text-align: center;
    }
  }
`;

export const StyledTableHeadRow = styled(TableRow)`
  th {
    border-right: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;

    :nth-of-type(2) {
      cursor: pointer;
    }
  }

  th:first-of-type {
    border-left: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    padding: 20px;
  }
`;

export const StyledVisitsTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;

    :nth-of-type(1) {
      text-align: center;
    }
    :nth-of-type(4) {
      text-align: center;
    }
    :nth-of-type(5) {
      text-align: center;
    }
    :nth-of-type(6) {
      text-align: center;
    }
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    background-color: #ffa94c;
  }
`;

export const VisitsFormField = styled(TextField)`
  max-width: 200px;
  width: 100%;
`;

export const VisitsTableFilterFormWrapper = styled(Box)`
  display: flex;
  gap: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const VisitsFilterFormDateField = styled(MobileDatePicker)`
  max-width: 200px;
  width: 100%;
`;

export const VisitsFilterDateTextField = styled(TextField)`
  & .MuiOutlinedInput-input {
    cursor: pointer;
    max-width: 169px;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const StyledVisitsFilterAttractionsField = styled(TextField)`
  max-width: 300px;
  width: 100%;
`;
