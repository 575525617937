export const getAvailableCategoriesForDestination = (
  allCategories,
  destinationId
) => {
  if (!destinationId) return [];

  const availableForDestinationCategories = allCategories.reduce(
    (acc, currentCategory) => {
      if (currentCategory.availableForDestination.includes(destinationId)) {
        acc.push({
          id: currentCategory.id,
          name: currentCategory.name,
        });
      }

      return acc;
    },
    []
  );

  return availableForDestinationCategories;
};

export const getFormatedItemsByDestinationId = (allRegions, destinationId) => {
  if (!destinationId) return [];

  let regions = [];
  let areas = [];

  allRegions.forEach(currentRegion => {
    if(currentRegion.destinationId === destinationId) {
      regions.push({
        id: currentRegion.id,
        name: currentRegion.title,
      });
    
      if(currentRegion.children.length) {
        currentRegion.children.forEach((area) => {
          areas.push({
            id: area.id,
            name: area.title
          })
        })
      }
    }
  })

  return [regions, areas];
};
