import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Box, Typography, Tab, Tabs } from '@mui/material';
import { Field, FormSection } from 'redux-form';
import { Input } from '../../../components/reduxFormComponents/reduxFormComponents';
import { maxLength50, requiredName, noSpaceName } from './validate';
import { useTranslation } from 'react-i18next';
import { validateTextFieldValue } from '../../../helpers/validateTextFieldValue';

const useStyles = makeStyles({
  root: {
    justifyContent: 'start',
  },
  tabs: {
    minWidth: '320px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    marginBottom: '16px',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    '& .MuiTab-root': {
      marginRight: '7px',
    },
    float: 'left',
    width: '100%',
  },
  tabPanel: {
    float: 'left',
    width: '100%',
    fontSize: '0.875rem',
    '& .MuiBox-root': {
      padding: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& textarea': {
        height: '65px !important',
      },
      '& .Mui-disabled': {
        color: '#000',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.90)',
      },
    },
  },
  panelBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  label: {
    display: 'flex',
    whiteSpace: 'nowrap',
    width: '140px',
    alignItems: 'center',
  },
  inputBox: {
    width: '500px',
  },
  input: {
    width: '252px',
  },
});

const styleTab = {
  color: '#fff',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  backgroundColor: 'rgba(0,0,0, 0.51)',
  width: '100%',
  maxWidth: '50px',
  minWidth: '20px',
  maxHeight: '36px',
  minHeight: '20px',
  padding: '12px 16px',
  margin: '6px 0px',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  indicatorColor: 'secondary',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0, 0.40)',
  },
  '&:focus': {
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&:first-child': {
    marginLeft: '4px',
  },
  '&:last-child': {
    marginRight: '4px',
  },
  '&.Mui-selected': {
    backgroundColor: '#FFA94C',
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&.disabled': {
    opacity: '0.5',
    cursor: ' not-allowed',
  },
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
      <Box
          component={'div'}
          sx={{ position: 'relative' }}
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
        )}
      </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const SeasonNameLanguageTabs = ({ currentItem, languages, ...props }) => {
  const classes = useStyles();
  const [tabsValue, setTabsValue] = useState(0);
  const { t } = useTranslation();
  const handleChangeTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const CreateTab = languages.map((language, index) => (
      <Tab label={language} key={index} {...a11yProps(index)} sx={styleTab} />
  ));

  return (
      <Box className={classes.root}>
        <Box className={classes.tabs}>
          <Tabs
              value={tabsValue}
              indicatorColor="none"
              onChange={handleChangeTabs}
          >
            {CreateTab}
          </Tabs>
        </Box>
        {languages.map((language, index) => (
            <TabPanel
                className={classes.tabPanel}
                value={tabsValue}
                index={index}
                key={index}
            >
              <Box component={'div'} className={classes.panelBox}>
                <div className={classes.label}>{t('attractions.season.name')}:</div>
                <div className={classes.inputBox}>
                  <FormSection name="name">
                    <Field
                        name={language}
                        component={Input}
                        validate={[maxLength50, requiredName, noSpaceName]}
                        className={classes.input}
                        parse={(value) => validateTextFieldValue(value)}
                        disabled={!props.isEdit}
                    />
                  </FormSection>
                </div>
              </Box>
            </TabPanel>
        ))}
      </Box>
  );
};

export default SeasonNameLanguageTabs;