/* global google */
import React from "react";

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polygon,
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";


const Map = ({ geoCoordinate, setGeoCoordinate, addLocation, isEdit }) => {

    function getPaths(polygon) {
        let bounds = [];
        let polygonBounds = polygon.getPath();
        for (let i = 0; i < polygonBounds.length; i++) {
            let point = {
                lat: polygonBounds.getAt(i).lat(),
                lng: polygonBounds.getAt(i).lng()
            };
            bounds.push(point);
            setGeoCoordinate(bounds);
        }
    }

    const defaultOptions = {
        panControl: true,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        clickableIcons: false,
        keyboardShortcuts: false,
        scrollwheel: false,
        disableDoubleClickZoom: false,
        fullscreenControl: true
    }

    const onDeleteSelectedArea = (e) => {
        e.preventDefault();
    }

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={new google.maps.LatLng(50.073988727436216, 14.436026387041693)}
            defaultOptions={defaultOptions}
        >
            <Polygon
                path={geoCoordinate}
                key={1}
                editable={true}
                options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35
                }}
            />
            {(addLocation || isEdit) && (
                <DrawingManager
                    defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
                    onPolygonComplete={value => getPaths(value)}
                    defaultOptions={{
                        drawingControl: addLocation || isEdit ? true : false,
                        drawingControlOptions: {
                            position: google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [google.maps.drawing.OverlayType.POLYGON]
                        },
                        polygonOptions: { editable: true }
                    }}
                />
            )}
        </GoogleMap>
    );
}

const PolygonMap = withScriptjs(withGoogleMap(Map))

export default PolygonMap;
