import { makeStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';

import { PricesFormTableTextField } from '../../styled';

import { parseCurrencyValue } from '../../utils';

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
  noPadding: {
    paddingRight: 0,
  },
}));

const PricesFormCurrencyField = (props) => {
  const { disabled, input, currencySymbol } = props;

  const classes = useStyles();

  return (
    <PricesFormTableTextField
      value={input.value}
      onChange={input.onChange}
      disabled={disabled}
      {...props}
      InputProps={{
        onBlur: () => input.onBlur(parseCurrencyValue(input.value)),
        classes: {
          notchedOutline: classes.noBorder,
          adornedEnd: classes.noPadding,
        },
        endAdornment: (
          <InputAdornment position="start">{currencySymbol}</InputAdornment>
        ),
      }}
    />
  );
};

export default PricesFormCurrencyField;
