import { useTranslation } from 'react-i18next';

import { Select } from '@mui/material';
import { StyledSelect } from '../styled';

const createOptions = (options) => {
  if (!options) return null;

  return options.map((option, index) => (
    <option value={option.id} key={index}>
      {option.name}
    </option>
  ));
};

const CategoryDestinationSelectField = ({ optionsData, input }) => {
  const { t } = useTranslation();

  return (
    <StyledSelect native {...input} size="small">
      <option value="">{t('faq_page.categories.all_destinations')}</option>
      {createOptions(optionsData)}
    </StyledSelect>
  );
};

export default CategoryDestinationSelectField;
