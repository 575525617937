import {
  Settings,
  People,
  QrCode,
  Login,
  Leaderboard,
  ThumbsUpDown,
  Payment,
  ChromeReaderMode,
  AccountBox,
  MonetizationOn,
  Dashboard,
  ViewList,
  Forum,
  WifiProtectedSetup,
  Language,
  NotificationsActive,
  History,
} from '@mui/icons-material';

const admin = 'admin';
const systemmanager = 'systemmanager';
const attraction = 'attraction';

export const menuItems = [
  {
    link: '/',
    label: 'top_bar.main_title_dashboard',
    roles: [admin, systemmanager, attraction],
    icon: <Dashboard />,
  },
  {
    link: '/attractions',
    label: 'top_bar.main_title_attractions',
    roles: [admin, systemmanager, attraction],
    icon: <ViewList />,
  },
  {
    link: '/deals',
    label: 'top_bar.main_title_deals',
    roles: [admin, systemmanager],
    icon: <ViewList />,
  },
  {
    link: '/points',
    label: 'top_bar.main_title_points',
    roles: [admin, systemmanager],
    icon: <ViewList />,
  },
  {
    link: '/passes',
    label: 'top_bar.main_title_passes',
    roles: [admin, systemmanager],
    icon: <QrCode />,
  },
  {
    link: '/visits',
    label: 'top_bar.main_title_visits',
    roles: [admin, systemmanager],
    icon: <Login />,
  },
  {
    link: '/products',
    label: 'top_bar.main_title_products',
    roles: [admin, systemmanager],
    icon: <MonetizationOn />,
  },
  {
    link: '/notifications',
    label: 'top_bar.main_title_notifications',
    roles: [admin, systemmanager],
    icon: <NotificationsActive />,
  },
  {
    link: '/reports',
    label: 'top_bar.main_title_reports',
    roles: [admin, systemmanager],
    icon: <Leaderboard />,
  },
  {
    link: '/reviews',
    label: 'top_bar.main_title_reviews',
    roles: [admin, systemmanager],
    icon: <ThumbsUpDown />,
  },
  {
    link: '/terminals',
    label: 'top_bar.main_title_terminals',
    roles: [admin, systemmanager],
    icon: <Payment />,
  },
  {
    link: '/publishing/advertisement',
    label: 'top_bar.main_title_publications',
    roles: [admin, systemmanager],
    icon: <ChromeReaderMode />,
  },
  {
    link: '/users/users',
    label: 'top_bar.main_title_users',
    roles: [admin, systemmanager],
    icon: <People />,
  },
  {
    link: '/logs',
    label: 'top_bar.main_title_logs',
    roles: [admin, systemmanager],
    icon: <History />,
  },
  {
    link: '/settings/destination',
    label: 'top_bar.main_title_settings',
    roles: [admin, systemmanager],
    icon: <Settings />,
  },
  {
    link: '/faq/questions',
    label: 'top_bar.main_title_faq',
    roles: [admin, systemmanager],
    icon: <Forum />,
  },
  {
    link: '/my-profile',
    label: 'top_bar.main_title_my_profile',
    roles: [admin, systemmanager, attraction],
    icon: <AccountBox />,
  },
  {
    link: '/translation',
    label: 'top_bar.main_title_translation',
    roles: [admin, systemmanager],
    icon: <Language />,
  },
  {
    link: '/app-version',
    label: 'top_bar.main_title_app_version',
    roles: [admin, systemmanager],
    icon: <WifiProtectedSetup />,
  },
];
