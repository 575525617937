import React from "react";
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {
    Box,
    TextField,
    Button,
    FormHelperText,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { 
    requiredName, 
    noSpaceName, 
    requiredNameForDate,
    requiredAppStore,
    requiredGooglePlay
} from './validate.js';

const useStyles = makeStyles({
    form: {
        '& .MuiOutlinedInput-input': {
            width: '100%',
            '&.Mui-disabled': { textFillColor: 'rgba(0, 0, 0, 0.5)'}
        }
    }
});


const AppVersionForm = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const HelperText = ({ touched, error }) => {
        if (!(touched && error)) {
            return
        } else {
            return <FormHelperText sx={{ color: '#d43131' }}>* {touched && error}</FormHelperText>
        }
    }

    const DatePickerInput = ({ label, input, meta: { touched, invalid, error }, ...props }) => {
        return (
            <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        inputFormat='dd/MM/yyyy'
                        value={input.value || null}
                        onChange={input.onChange}
                        disabled={props.disabled}
                        renderInput={(props) => <TextField size='small' {...props} />}
                    />
                </LocalizationProvider>
                {HelperText({ touched, error })}
            </>
        )
    }

    const Input = ({ label, input, meta: {touched, invalid, error }, text, length, ...props }) => {
        return (
            <>
                <TextField
                    label={label}
                    placeholder={text}
                    error={error && touched}
                    {...input}
                    {...props}
                    size="small"
                />
                {HelperText({ touched, error })}
            </>
        )
    }

    return (
        <Box component='form' onSubmit={props.handleSubmit} sx={{ width: '100%', marginTop: '100px' }} className={classes.form}>
            <Box component='div' sx={{ width: '500px', marginBottom: '30px' }}>
                <Box component='div' sx={{ margin: '0 0 7px 4px' }}>{t("settings_page.app_version.version")}:</Box>
                <Field
                    name='version'
                    placeholder={t("settings_page.app_version.version")}
                    component={Input}
                    validate={[requiredName, noSpaceName]}
                    size='small'
                    sx={{ width: '100%' }}
                    disabled={!props.addAppVersion && !props.isEdit}
                />
                {props.error && props.error.Version && (
                    <Typography
                        sx={{ color: '#d43131' }}
                        variant="caption"
                        display="block"
                        component="span"
                    >
                        {`* ${props.error.Version[0]}`}
                    </Typography>
                )}
            </Box>
            <Box component='div' sx={{ width: '500px', marginBottom: '30px' }}>
                <Box component='div' sx={{ margin: '0 0 7px 4px' }}>{t("settings_page.app_version.app_store_url")}:</Box>
                <Field
                    name='urlAppStore'
                    placeholder={!props.appVersionState.version.version ? `${t("settings_page.app_version.app_store_url")}` : props.appVersionState.version.urlAppStore}
                    component={Input}
                    validate={[requiredAppStore, noSpaceName]}
                    size='small'
                    sx={{ width: '100%' }}
                    disabled={!props.isEdit && props.appVersionState.version.version}
                />
                {props.error && props.error.UrlAppStore && (
                    <Typography
                        sx={{ color: '#d43131' }}
                        variant="caption"
                        display="block"
                        component="span"
                    >
                        {`* ${props.error.UrlAppStore[0]}`}
                    </Typography>
                )}
            </Box>
            <Box component='div' sx={{ width: '500px', marginBottom: '30px' }}>
                <Box component='div' sx={{ margin: '0 0 7px 4px' }}>{t("settings_page.app_version.google_play_url")}:</Box>
                <Field
                    name='urlGooglePlay'
                    placeholder={!props.appVersionState.version.version ? `${t("settings_page.app_version.google_play_url")}` : props.appVersionState.version.urlGooglePlay}
                    component={Input}
                    validate={[requiredGooglePlay, noSpaceName]}
                    size='small'
                    sx={{ width: '100%' }}
                    disabled={!props.isEdit && props.appVersionState.version.version}
                />
                {props.error && props.error.UrlGooglePlay && (
                    <Typography
                        sx={{ color: '#d43131' }}
                        variant="caption"
                        display="block"
                        component="span"
                    >
                        {`* ${props.error.UrlGooglePlay[0]}`}
                    </Typography>
                )}
            </Box>
            <Box component='div' sx={{ marginBottom: '30px' }}>
                <Box component='div' sx={{ margin: '0 0 7px 4px' }}>{t("settings_page.app_version.published_by")}:</Box>
                <Box component='div'>
                    <Field
                        name='publishDate'
                        placeholder={props.appVersionState.version ? props.appVersionState.version.publishDate : `${t("settings_page.app_version.no_version")}`}
                        component={DatePickerInput}
                        size='small'
                        validate={[requiredNameForDate]}
                        disabled={!props.addAppVersion && !props.isEdit}
                    />
                    {props.error && props.error.PublishDate && (
                        <Typography
                            sx={{ color: '#d43131' }}
                            variant="caption"
                            display="block"
                            component="span"
                        >
                            {`* ${props.error.PublishDate[0]}`}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box component={'div'} sx={{ width: '500px', display: 'flex', justifyContent: 'flex-end' }}>
                {!props.appVersionState.version.version ? (
                    <LoadingButton
                        loading={props.isSave}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        sx={{ color: '#fff', marginLeft: '10px' }}
                        type="submit"
                    >
                        {t("settings_page.app_version.save_button")}
                    </LoadingButton>
                ) : (
                    <>
                        <Button 
                            variant="outlined" 
                            onClick={props.onEditAppVersion} 
                            type="button"
                            disabled={props.isSave}
                        >
                            {t("settings_page.app_version.edit_button")}
                        </Button>
                        <LoadingButton
                            loading={props.isSave}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            sx={{ color: '#fff', marginLeft: '10px' }}
                            disabled={props.addAppVersion || props.isEdit ? false : true}
                            type="submit"
                        >
                            {t("settings_page.app_version.save_button")}
                        </LoadingButton>
                    </>
                )}
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        initialValues:
        {
            version: state.appVersionsReducer.version.version,
            urlAppStore: state.appVersionsReducer.version.urlAppStore,
            urlGooglePlay: state.appVersionsReducer.version.urlGooglePlay,
            publishDate: state.appVersionsReducer.version.publishDate,
        }
    }
}

export default connect(mapStateToProps)(reduxForm({ form: 'AppVersionForm', enableReinitialize: true })(AppVersionForm))