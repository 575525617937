import { connect } from 'react-redux';
import { compose } from 'redux';
import AppVersionPage from './AppVersionPage';
import { 
  getAppVersionThunk,
  addAppVersionThunk,
  editAppVersionThunk,
} from '../../redux/appVersionReducers/appVersionsReducer';

const mapStateToProps = (state) => {
  return {
    appVersionsReducer: state.appVersionsReducer,
  }
};

export default compose(
  connect(mapStateToProps,
    {
      getAppVersionThunk,
      addAppVersionThunk,
      editAppVersionThunk
    }),
)(AppVersionPage);