import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TodayIcon from '@mui/icons-material/Today';

import {
  StyledMobileDatePicker,
  MainImageFormDatePickerTextField,
} from '../../styled';

const useStyles = makeStyles({
  dateFieldInputDisabled: {
    '& .MuiOutlinedInput-input': {
      cursor: 'default',
    },

    '& .MuiInputAdornment-root': {
      cursor: 'default',
    },
  },
});

const MainImageFormDatePicker = ({ name, disabled }) => {
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const handleOpenCalendar = () => setIsOpen(true);
  const handleCloseCalendar = () => setIsOpen(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: true,
          message: t('mainImage.validation.required'),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledMobileDatePicker
            inputFormat="dd.MM.yyyy"
            onChange={(event) => onChange(event)}
            value={value}
            disabled={disabled}
            mask={'__.__.____'}
            showToolbar={false}
            open={isOpen}
            onOpen={handleOpenCalendar}
            onAccept={handleCloseCalendar}
            onClose={handleCloseCalendar}
            clearText={t('clear')}
            okText={t('ok')}
            cancelText={t('cancel')}
            clearable
            renderInput={(params) => (
              <MainImageFormDatePickerTextField
                size="small"
                placeholder="dd/mm/yyyy"
                {...params}
                className={clsx(disabled && classes.dateFieldInputDisabled)}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      disablePointerEvents={disabled}
                      position="start"
                      onClick={handleOpenCalendar}
                    >
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default MainImageFormDatePicker;
