export const parseCurrencyValue = (value) => {
  if (!value) return null;

  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue)) return parseFloat(0).toFixed(2);

  return parsedValue.toFixed(2);
};

export const calculateVatAmount = (price, vatPercentage) => {
  if (!price || !vatPercentage) return null;

  const formatedPrice = parseFloat(price);
  const formatedVatPercentage = parseFloat(vatPercentage);

  const vatAmount =
    (formatedPrice * formatedVatPercentage) / (100 + formatedVatPercentage);

  return vatAmount.toFixed(2);
};

export const calculateNetAmount = (price, vatAmount) => {
  if (!price || !vatAmount) return null;

  const netAmount = parseFloat(price) - parseFloat(vatAmount);

  return netAmount.toFixed(2);
};

export const calculateVatAndNetAmount = (
  vatPercentage,
  contractPriceRowValues
) => {
  const vatAmount = { valueAddedTax: vatPercentage };
  const netAmount = {};

  for (const field in contractPriceRowValues) {
    vatAmount[field] = calculateVatAmount(
      contractPriceRowValues[field],
      vatPercentage
    );

    netAmount[field] = calculateNetAmount(
      contractPriceRowValues[field],
      vatAmount[field]
    );
  }

  return { vatAmount, netAmount };
};

export const mapStringsToNumbers = (fieldsObject) => {
  const mappedObject = {};

  for (const field in fieldsObject) {
    mappedObject[field] = parseFloat(fieldsObject[field]) || null;
  }

  return mappedObject;
};

export const parseToNumber = (value) => {
  if (!value) return null;

  const result = value
    .replace(/[^0-9.]/g, '')
    .replace('.', 'x')
    .replace(/\./g, '')
    .replace('x', '.');

  return result;
};
