import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
});

const SideBarMenuList = ({
  toggleDrawer,
  anchor = 'left',
  itemsList,
  userRoles,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      sx={{ width: 250, marginTop: '65px' }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {itemsList.map(
          (item, idx) =>
            item.roles.some((role) => userRoles.role.includes(role)) && (
              <Link to={item.link} className={classes.link} key={idx}>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={t(item.label)} />
                </ListItemButton>
              </Link>
            )
        )}
      </List>
    </Box>
  );
};

export default SideBarMenuList;
