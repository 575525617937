export const STATUS_ACTIVE = 'Active';
export const STATUS_INACTIVE = 'Inactive';

export const NEW_CATEGORY_OBJECT = {
  order: null,
  status: STATUS_INACTIVE,
  name: {},
  imageList: [],
  iconList: [],
};

export const ALLOWED_FILE_TYPES = ['svg'];
export const CATEGORY_PAGE_ROUTE = '/settings/category';
