import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { deleteArticleRecordThunk } from '../../../../redux/publishingReducers/articlesReducer/articlesActions';
import { formatLanguageFieldsData } from '../utils';
import { uploadImageFile } from '../../../../api/publishing/mainImageApi';
import { ARTICLE_STATUS } from '../../../../consts/consts';
import { ARTICLES_TYPES } from '../constants';
import { useDispatch} from 'react-redux';

import {
  getExistingImages,
  getUniqueImages,
  uploadImageFiles,
} from '../../../attractions/content/reduxImageDrop/utils';
import {
  updateArticleRecordThunk,
  getArticleListThunk,
} from '../../../../redux/publishingReducers/articlesReducer/articlesActions';
import {
  removeKeysWithEmptyValueArrays,
  removeTextEditorValueKeysWithNoPlainText,
} from '../../../../helpers/translationObjectUtils';

import ArticlesForm from './ArticlesForm';
import ArticlesFormControlPanel from '../articlesFormControlPanel/ArticlesFormControlPanel';
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';
import useAPIError from '../../../../apIErrorProvider/useAPIError';

const ArticlesFormContainer = (props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const {
    languageList,
    item,
    updateArticleRecordThunk,
    getArticleListThunk,
    currentDestinationId,
    resetActiveRow,
    selectedLanguage,
    articleStatuses,
    resetFilters,
  } = props;
const dispatch=useDispatch()
  const { addError } = useAPIError();
  const { t } = useTranslation();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [activeTabId, setActiveTabId] = useState(languageList.parameters[0].id);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const articleTypeList = useSelector((state) =>
    state.appReducer.globalSettingsData.find(
      (item) => item.name.en === ARTICLES_TYPES
    )
  );
  const languageFormFieldArrays = useMemo(
    () =>
      languageList.parameters.reduce((acc, lang) => {
        const { value } = lang;

        acc[value] = [
          {
            title: item.title[value] || '',
            text: item.text[value] || '',
          },
        ];

        return acc;
      }, {}),
    [item.title, item.text, languageList.parameters]
  );

  const defaultValues = {
    status: item.status,
    images: item.images,
    type: item.type,
    ...languageFormFieldArrays,
  };

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  const handleLanguageTabChange = (_, newValue) => setActiveTabId(newValue);

  const hanldeEditButtonClick = () => {
    setIsInEditMode(true);
  };

  const handleCancelButtonClick = () => {
    setIsInEditMode(false);
    reset(defaultValues, { keepDefaultValues: true });
  };

  const handleDeleteButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handlePopupConfirm = () => {
    dispatch(deleteArticleRecordThunk(item.id))
      .then(() => {
        addError(`${t('articles.notifications.delete_success')}`, 'Success');
        getArticleListThunk(currentDestinationId);
        resetActiveRow();
        resetFilters();
        setShowSaveAnimation(false);
      })
      .catch((e) => {
        addError(`${t('articles.notifications.delete_error')}`, 'Error', e);
      });
      handleClosePopup();  
  };

  const handleSubmitButtonClick = (formData) => {
    setIsInEditMode(false);
    setShowSaveAnimation(true);

    const uniqueAppImageFiles = getUniqueImages(formData.images);
    const existingAppImageFiles = getExistingImages(formData.images);

    Promise.all([uploadImageFiles(uniqueAppImageFiles, uploadImageFile, 'App')])
      .then((responses) => {
        const updatedAppImageUrlList = [
          ...existingAppImageFiles,
          ...responses[0].data,
        ];
        const fieldsWithTranslations = formatLanguageFieldsData(formData);

        const submitData = {
          id: item.id,
          status: formData.status || null,
          type: formData.type,
          images: updatedAppImageUrlList || [],
          title: removeKeysWithEmptyValueArrays(fieldsWithTranslations.title),
          text: removeTextEditorValueKeysWithNoPlainText(
            fieldsWithTranslations.text
          ),
        };

        return updateArticleRecordThunk(submitData.id, submitData);
      })
      .then(() => {
        addError(`${t('articles.notifications.update_success')}`, 'Success');
        getArticleListThunk(currentDestinationId);
        resetActiveRow();
        resetFilters();
        setShowSaveAnimation(false);
      })
      .catch((error) => {
        addError(`${t('articles.notifications.update_error')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  return (
  <> 
    <FormProvider {...formMethods}>
      <ArticlesFormControlPanel
        isInEditMode={isInEditMode}
        onEdit={hanldeEditButtonClick}  
        onDelete={handleDeleteButtonClick}
        onCancel={handleCancelButtonClick}
        onSubmit={handleSubmit(handleSubmitButtonClick)}
        showSaveAnimation={showSaveAnimation}
  
      />
      <ArticlesForm
        activeTabId={activeTabId}
        onLanguageTabChange={handleLanguageTabChange}
        languageList={languageList}
        isInEditMode={isInEditMode}
        selectedLanguage={selectedLanguage}
        articleStatuses={articleStatuses}
        articleTypeList={articleTypeList}
      />
    </FormProvider>
    <ConfirmationDialog
      showAlert={isPopupOpen}
      handleClose={handleClosePopup}
      handleCloseSuccess={handlePopupConfirm}
      text={t('articles.delete_article_title')}
    />
  </>
  );
};

const mapStateToProps = (state) => {
  const articleStatuses =
    state.appReducer.globalSettingsData.find(
      (item) => item.name.en === ARTICLE_STATUS
    ) || null;

  return {
    currentDestinationId: state.appReducer.selectedDestination.id,
    selectedLanguage: state.appReducer.selectedLanguage,
    articleStatuses,
  };
};

export default connect(mapStateToProps, {
  updateArticleRecordThunk,
  getArticleListThunk,
})(ArticlesFormContainer);
