import { Autocomplete, FormControl, TextField, Chip, Box } from '@mui/material';

const AutocompleteTextField = ({ ...props }) => {
  const {
    onChange,
    value,
    options,
    getOptionLabel,
    multiple,
    label,
    required,
  } = props;
  return (
    <FormControl fullWidth>
      <Autocomplete
        id="combo-box-demo"
        options={options}
        value={value}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        defaultValue={multiple ? [] : null}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            required={required}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
        renderTags={(value, getTagProps) => {
          return (
            <Box>
              {value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={getOptionLabel(option)}
                />
              ))}
            </Box>
          );
        }}
        {...props}
      />
    </FormControl>
  );
};

export default AutocompleteTextField;
