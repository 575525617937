import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export const removeKeysWithEmptyValueArrays = (sourceObj) => {
  const objectKeys = Object.keys(sourceObj);

  const parsedTranslationObject = objectKeys.reduce((acc, currentKey) => {
    if (!!sourceObj[currentKey].length) acc[currentKey] = sourceObj[currentKey];

    return acc;
  }, {});

  return parsedTranslationObject;
};

export const removeTextEditorValueKeysWithNoPlainText = (sourceObj) => {
  const translationKeys = Object.keys(sourceObj);

  const parsedObject = translationKeys.reduce((accObj, currentKey) => {
    const contentBlock = htmlToDraft(sourceObj[currentKey]);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const plainText = contentState.getPlainText();

    if (!!plainText.trim().length) {
      accObj[currentKey] = sourceObj[currentKey];
    }

    return accObj;
  }, {});

  return parsedObject;
};
