import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import { StyledIconButton, StyledClearIcon, LoaderWrapper } from './styled';

import UserForm from './UserForm';
import useAPIError from '../../../../apIErrorProvider/useAPIError';

import {
  editUserThunk,
  deleteUserThunk,
  changeUserPasswordThunk,
  getUserSettingsThunk,
  getSelectedUserDataThunk,
  setSelectedUserData, createVisitorThunk,
} from '../../../../redux/usersReducers/users/userActions';
import { getSmallAttractionsDataThunk } from '../../../../redux/attractionsReducers/attractions/attractionsActions';
import { getSalesPointShortDataListThunk } from '../../../../redux/salesPointsReducer/salesPointsActions';
import {
  getAllCompaniesShortDataThunk,
  getAttractionListOfCompaniesThunk,
  getCompaniesListThunk,
} from '../../../../redux/usersReducers/company/companyActions';
import { getCategoryThunk } from '../../../../redux/settingsReducers/categoryReducer';

import { isAdminRole } from '../UserRoles';
import { replaceFieldName } from '../helpers';

import { USER_STATUS, USER_ROLES, MANAGER_ROLE_LIST } from '../../../../consts/consts';
import {
  ID_REGION,
  ID_ATTRACTION,
  ID_CATEGORY,
  SALES_POINT_ID,
  USER_FORM_NAME,
  ID_AREA,
  REGIONAL_MANAGER_ROLE
} from '../consts';

const UserFormContainer = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const userData = useSelector((state) => state.userReducer.selectedUser);

  const userStatuses = useSelector((state) =>
    state.userReducer.userSettings.find((item) => item.name.en === USER_STATUS)
  );

  const userRoles = useSelector((state) =>
    state.userReducer.userSettings.find((item) => item.name.en === USER_ROLES)
  );

  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );

  const attractionList = [
    ...useSelector((state) => state.attractionsReducer.attractionsSmallData),
  ];

  const sortedAttractions = attractionList.sort(function (a, b) {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const selectedDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );
  const categoryList = useSelector(
    (state) => state.categoryReducer.categoryData
  );
  const regionList = useSelector(
    (state) => state.settingsReducer.dictionaryRegionData
  );

  const { allCompaniesShortDataList } = useSelector(
    (state) => state.companyReducer
  );

  const companyFieldInitialValue = allCompaniesShortDataList?.find(
    (company) => company.id === userData?.company.id
  );

  const attractionListOfCompanies = useSelector(
    (state) => state.companyReducer.attractionListOfCompanies
  );

  const pointList = useSelector((state) => [
    ...state.salesPointsReducer.pointShortDataList,
  ]);

  const sortedPoints = pointList.sort(function (a, b) {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const defaultCompany = useSelector(
    (state) => state.companyReducer.defaultCompany
  );

  const currentUserRole = useSelector((state) => state.authReducer.role);

  const isSelectedCompanyAttractionsLoaded = useSelector(
    (state) => state.companyReducer.isAttractionListOfCompaniesLoaded
  );

  const attractionsAndPointsLinkedToCompaniesWithDefaultCompany = [
    ...attractionListOfCompanies,
    {
      id: defaultCompany.id,
      attractions: sortedAttractions,
      salesPoints: sortedPoints,
    },
  ];

  const handleCancelButtonClick = () => {
    setIsInEditMode(false);
    dispatch(reset(USER_FORM_NAME));
  };

  const handleEditButtonClick = () => {
    setIsInEditMode(true);
  };

  const handleFormSubmit = (formData) => {
    setShowSaveAnimation(true);
    setIsInEditMode(false);
    let updatedUserData;
    if (isAdminRole(formData.role)) {
      updatedUserData = {
        id: formData.id,
        name: formData.name ? formData.name.trim() : '',
        position: formData.position ? formData.position.trim() : '',
        email: formData.email ? formData.email.trim() : '',
        phone: formData.phone ? formData.phone.trim() : '',
        message: formData.message,
        role: formData.role,
        isTranslations: formData.isTranslations || false,
        status: formData.status,
        idCompany: defaultCompany.id,
        isAllAttractions: false,
        isAllPoints: false,
        regions: [],
        areas: [],
        attractionCategories: [],
        attractions: [],
        salesPoints: [],
        destination: selectedDestinationId,
        language: formData.language,
      };
    } else {
      updatedUserData = {
        id: formData.id,
        name: formData.name ? formData.name.trim() : '',
        position: formData.position ? formData.position.trim() : '',
        email: formData.email ? formData.email.trim() : '',
        phone: formData.phone ? formData.phone.trim() : '',
        message: formData.message,
        role: formData.role,
        isTranslations: formData.isTranslations || false,
        status: formData.status,
        idCompany: formData.company.id,
        isAllAttractions: formData.isAllAttractionsAndPoints || false,
        isAllSalesPoints: formData.isAllAttractionsAndPoints || false,
        regions: formData.isAllAttractions
          ? []
          : replaceFieldName(formData.regions, 'id', ID_REGION),
        areas: formData.isAllAttractions
          ? []
          : replaceFieldName(formData.areas, 'id', ID_AREA),
        attractionCategories: formData.isAllAttractions
          ? []
          : replaceFieldName(formData.categories, 'id', ID_CATEGORY),
        attractions: formData.isAllAttractions
          ? []
          : replaceFieldName(formData.attractions, 'id', ID_ATTRACTION),
        salesPoints: formData.salesPoints
          ? replaceFieldName(formData.salesPoints, 'id', SALES_POINT_ID)
          : [],
        destination: selectedDestinationId,
        language: formData.language,
      };
    }

    dispatch(editUserThunk(updatedUserData.id, updatedUserData))
      .then(() => {
        setShowSaveAnimation(false);
        dispatch(getSelectedUserDataThunk(userId));
      })
      .then(() => addError(`${t('users.success_edit_user')}`, 'Success'))
      .catch((e) => {
        addError(`${t('users.error_edit_user')}`, 'Error', e);
        setShowSaveAnimation(false);
      });
  };

  const handleDeleteUser = (userId) => {
    setShowSaveAnimation(true);
    dispatch(deleteUserThunk(userId))
      .then(() => {
        navigate(-1);
        setShowSaveAnimation(false);
        addError(`${t('users.success_delete_user')}`, 'Success');
      })
      .catch(() => {
        addError(`${t('users.error_delete_user')}`, 'Error');
        setShowSaveAnimation(false);
      });
  };

  const handleUserPasswordChange = (userData) => {
    setShowSaveAnimation(true);
    dispatch(changeUserPasswordThunk(userData.userId, userData))
      .then(() => {
        dispatch(getSelectedUserDataThunk(userId));
        addError(`${t('users.password_change_success')}`, 'Success');
        setIsInEditMode(false);
        setShowSaveAnimation(false);
      })
      .catch(() => {
        addError(`${t('users.error_password_change')}`, 'Error');
        setShowSaveAnimation(false);
      });
  };

  const handleVisitorCreation = (id) => {
    setShowSaveAnimation(true);
    dispatch(createVisitorThunk(id))
        .then(() => {
          dispatch(getSelectedUserDataThunk(userId));
          addError(`${t('users.success_edit_user')}`, 'Success');
          setIsInEditMode(false);
          setShowSaveAnimation(false);
        })
        .catch(() => {
          addError(`${t('users.error_edit_user')}`, 'Error');
          setShowSaveAnimation(false);
        });
  };

  const handleDeleteDialogSubmit = () => {
    handleDeleteUser(userData.id);
    setShowDeleteDialog(false);
    setIsInEditMode(false);
  };

  const handleReturnButtonClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    Promise.all([
      dispatch(getCategoryThunk()),
      dispatch(getUserSettingsThunk()),
      dispatch(getSelectedUserDataThunk(userId)),
      dispatch(getAllCompaniesShortDataThunk()),
    ]);
  }, [dispatch, selectedDestinationId, userId]);

  useEffect(() => {
    if(!!userData && userData.role === REGIONAL_MANAGER_ROLE) {
      dispatch(getSmallAttractionsDataThunk(selectedDestinationId));
      dispatch(getCompaniesListThunk(selectedDestinationId));
      dispatch(getAttractionListOfCompaniesThunk(selectedDestinationId));
      dispatch(getSalesPointShortDataListThunk(selectedDestinationId));
    } else if (!!userData && !isAdminRole(userData.role)) {
      dispatch(getCompaniesListThunk(userData.company.idDestination));
      dispatch(
        getAttractionListOfCompaniesThunk(userData.company.idDestination)
      );
      dispatch(getSmallAttractionsDataThunk(userData.company.idDestination));
      dispatch(getSalesPointShortDataListThunk(userData.company.idDestination));
    } else if (!!userData && (isAdminRole(userData.role) || userData.role == REGIONAL_MANAGER_ROLE)) {
      dispatch(getSmallAttractionsDataThunk(selectedDestinationId));
      dispatch(getCompaniesListThunk(selectedDestinationId));
      dispatch(getAttractionListOfCompaniesThunk(selectedDestinationId));
      dispatch(getSalesPointShortDataListThunk(selectedDestinationId));
    }
  }, [userData, selectedDestinationId, dispatch]);

  useEffect(() => {
    return () => dispatch(setSelectedUserData(null));
  }, [dispatch]);

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  const isFormReady = (!isManagerRoleSelected ? !!defaultCompany?.id : true) && !!userData;

  return (
    <>
      {isFormReady && (
        <>
          {!isManagerRoleSelected &&
            <>
              <Box display="flex" justifyContent="flex-end">
                <StyledIconButton onClick={handleReturnButtonClick}>
                  <StyledClearIcon />
                </StyledIconButton>
              </Box>
            </>
          }
          <UserForm
            onSubmit={handleFormSubmit}
            onEdit={handleEditButtonClick}
            onCancel={handleCancelButtonClick}
            userData={userData}
            isInEditMode={isInEditMode}
            setIsInEditMode={setIsInEditMode}
            onDeleteItem={handleDeleteUser}
            onChangeUserPassword={handleUserPasswordChange}
            onCreateVisitor={handleVisitorCreation}
            showSaveAnimation={showSaveAnimation}
            attractionsAndSalesPointsLinkedToCompanies={
              attractionsAndPointsLinkedToCompaniesWithDefaultCompany
            }
            isSelectedCompanyAttractionsLoaded={
              isSelectedCompanyAttractionsLoaded
            }
            currentUserRole={currentUserRole}
            roles={userRoles}
            statuses={userStatuses}
            selectedLanguage={selectedLanguage}
            categoryList={categoryList}
            regionList={regionList}
            companyList={allCompaniesShortDataList}
            defaultUserCompany={defaultCompany}
            onDeleteDialogConfirmation={handleDeleteDialogSubmit}
            showDeleteDialog={showDeleteDialog}
            setShowDeleteDialog={setShowDeleteDialog}
            companyFieldInitialValue={companyFieldInitialValue}
            selectedDestinationId={selectedDestinationId}
            currentUser = {currentUser}
          />
        </>
      )}
      {!isFormReady && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </>
  );
};

export default UserFormContainer;
