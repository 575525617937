import {useEffect, useState} from 'react';
import cl from './Buttons.module.css';
import cl2 from './FormButtons.module.scss';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import ErrorIcon from '@mui/icons-material/Error';
import {
    Box,
    Button,
    Checkbox,
    Modal,
    Typography,
    FormControlLabel,
    FormGroup,  
    CircularProgress,
    Tooltip
  } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAPIError from '../../apIErrorProvider/useAPIError';
import { useLocation } from 'react-router-dom';

export const SaveButton = ({addElement, isEdit, type}) => (
    <button className={addElement || isEdit ? cl.button : cl.disabled} type={type}>Save</button>
)

export const AddButton = ({text, onAdd}) => (
    <Button variant="contained" sx={{color: '#fff', width: '100%', height: '100%'}} onClick={onAdd}>{text}</Button>
)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 2,
};


export const TranslateButton = ({title, tab, disabled, field, data, notTranslated, differentData, handleSendTranslation}) => {
    const { addError } = useAPIError();
    const { t } = useTranslation();
    const {currentUser} = useSelector(state => state.userReducer)
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
      setOpen(false);
      setSelectLanguages([languages[0]]);
      setTranslateForAll(true);
      setIsLoading(false);
    }

    const {pathname} = useLocation();

    const language = useSelector(state => state.appReducer.selectedDestination.languages[0]);
    const languages = useSelector((state) => state.appReducer.selectedDestination.languages.slice(1));
    
    const sendRequest = async() => {
      setIsLoading(true);
      const selectedLanguages = translateForAll ? languages : selectLanguages;
      
      await handleSendTranslation(field, selectedLanguages)
      .then((resp) => {
        addError(`${t('settings_page.translation.success_translate')}`, 'Success');
      }).catch((e) => {
        addError(`${t('settings_page.translation.error_translate')}`, 'Error', e);
      }).finally(() => {
        handleClose();
      });

      setIsLoading(false);
    }
    
    const [otherTranslate, setOtherTranslate] = useState([]);
    const [hasOtherTranslate, setHasOtherTranslate] = useState(false);
    

    useEffect(() => {
      if (data) {
        const keysWithValues = Object.keys(data).filter((key) => {
          if(key === language) return;
          const translations = data[key];
          return Object.values(translations).some((value) => value && value.trim() !== '');
        });
        setOtherTranslate(keysWithValues);
        setHasOtherTranslate(keysWithValues.length > 0);
      } else {
        setOtherTranslate([]);
        setHasOtherTranslate(false);
      }
    }, [open])
    
    const [selectLanguages, setSelectLanguages] = useState([languages[0]])
    
    const [translateForAll, setTranslateForAll] = useState(true); 
    const toggleTranslateForAll = () => setTranslateForAll(!translateForAll);

    
    const handleChangeLanguageTab = (_, newLanguage) => {
        setSelectLanguages((prevLanguages) => {
          if (prevLanguages.length === 1 && prevLanguages.includes(newLanguage)) {
            return prevLanguages;
          }
          if (prevLanguages.includes(newLanguage)) {
            return prevLanguages.filter(lang => lang !== newLanguage); 
          } else {
            return [...prevLanguages, newLanguage]; 
          }
        });
    };
    
    const isVisible = (tab === 0 || tab === language) && (currentUser.role === "Administrator" || currentUser.isTranslations) && !pathname.includes('new-');      
    if(isVisible) {
      return (
        <div>
          <div style={{width: '130px',position: 'relative', display: 'flex', justifyContent:'start !important'}}>
            <Button 
              disabled = {disabled} 
              className={cl.translateButton} 
              onClick={handleOpen}
            >
              {t('attractions.translate')}
            </Button>
            {(notTranslated && notTranslated.length > 0) &&
              <Tooltip
                title={
                  notTranslated.length === 1 
                  ? 
                    <>
                    {t('settings_page.translation.not_translation')}: <br />
                    <span style={{textAlign: 'center'}}>{notTranslated[0]}</span>
                    </>
                  :
                    <>
                      {t('settings_page.translation.not_translations')}: <br />
                      <span style={{textAlign: 'center'}}>{notTranslated.join(', ')}</span>
                    </>
                }
                arrow 
                placement = "top"
                style={{position: 'absolute', top: '0px', right: '-15px'}}
              >
                <ErrorIcon color="warning" />
              </Tooltip>
            }
            {differentData && 
              <Tooltip
                title = {t('settings_page.translation.translations_changes_not_saved')}
                arrow 
                placement = "bottom"
                style={{position: 'absolute', bottom: '0px', right: '-15px'}}
              >
                <ErrorIcon color="warning" />
              </Tooltip>
            }
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            { 
            !hasOtherTranslate ?
              <Box sx={style}>
                <Typography style={{textAlign:'center'}} id="modal-modal-title" variant="h5" component="h2">
                  {title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {t('settings_page.translation.choose_languages')}
                </Typography>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      disabled = {isLoading}
                      checked={translateForAll}
                      onChange={toggleTranslateForAll}
                      />
                    }
                    label={t('settings_page.translation.choose_all_languages')}
                  />
                </FormGroup>
                {!translateForAll && (
                  <FormGroup sx={{ mt: 2 }}>
                  <div className={cl2.translationTabsContainer}>
                  {languages.map((lang) => (
                    <Button
                      key={lang}
                      disabled = {isLoading}
                      onClick={() => handleChangeLanguageTab(null, lang)}
                      sx={{
                        color: '#fff',
                        cursor: 'pointer',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        backgroundColor: selectLanguages.includes(lang) ? '#ffa000' : 'rgba(0,0,0,0.51)',
                        width: '100%',
                        maxWidth: '50px',
                        minWidth: '20px',
                        maxHeight: '36px',
                        minHeight: '20px',
                        margin: '6px 0px',
                        border: 'none',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        marginRight: '7px',
                        '&:hover': {
                          backgroundColor: selectLanguages.includes(lang) ? '#ffa000' : 'rgba(0,0,0,0.40)',
                        },
                        '&:disabled': {
                          color: 'rgba(0, 0, 0, 0.26)',
                          boxShadow: 'none',
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        },
                      }}
                    >
                      {lang}
                  </Button>
                  ))}
                  </div>
                  </FormGroup>
                )}
                <div style={{display: 'flex', justifyContent: 'end', marginTop: '40px'}}>
                  <Button className={cl.translateButton} disabled = {isLoading} onClick={sendRequest}>
                    {isLoading ? 
                    <CircularProgress color="inherit"  size='20px' style={{marginRight: '5px'}}/> :
                    <GTranslateIcon fontSize='small' style={{marginRight: '5px'}}/>
                    }
                    {t('attractions.translate')}
                  </Button>
                </div>
              </Box>
            :  
            <Box sx={style}>
              <Typography style={{textAlign:'center'}} id="modal-modal-description" sx={{ mt: 2 }}>
                <div>
                  {t('settings_page.translation.has_translate_for')} 
                  {' '}{otherTranslate.join(', ')}
                </div>
                {t('settings_page.translation.want_to_translate')} 
              </Typography>
              <div style = {{marginTop: '20px', display: "flex", justifyContent: 'center'}}> 
                <Button onClick={handleClose}>{t('no')} </Button>
                <Button onClick={() => setHasOtherTranslate(false)}>{t('yes')} </Button>
              </div>
            </Box>
            }
          </Modal>
        </div>
      )
    }
}