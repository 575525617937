import { useTranslation } from 'react-i18next';
import { TableCell } from '@mui/material';

import { CompanyTableRow } from './styled';

const colors = {
  blue: '#32D7F0',
  orange: '#FFA94C',
  grey: '#A0A0A0',
  white: '#FFF',
  red: '#E84E33',
};

const statusColor = {
  Inactive: colors.blue,
  Active: colors.orange,
  Closed: colors.grey,
  Pending: colors.red,
};

const CompanyRow = (props) => {
  const { item, onClick } = props;
  const { t } = useTranslation();

  const contactPerson = {
    name: item.personContacts[0]?.name || null,
    phone: item.personContacts[0]?.phone || null,
    email: item.personContacts[0]?.email || null,
  };

  return (
    <CompanyTableRow onClick={onClick}>
      <TableCell
        sx={{
          bgcolor: statusColor[item.status],
        }}
      >
        {t(`companies.statuses.${item.status.toLowerCase()}`)}
      </TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.city}</TableCell>
      <TableCell>{contactPerson.name}</TableCell>
      <TableCell>{contactPerson.phone}</TableCell>
      <TableCell>{contactPerson.email}</TableCell>
    </CompanyTableRow>
  );
};

export default CompanyRow;
