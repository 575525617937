import { styled } from '@mui/system';
import { Box, FormControl, Tab, Typography, IconButton } from '@mui/material';

import { ReduxTextField } from '../../../components/reduxFormComponents/reduxFormComponents';

import {
  FormButton,
  SaveButton,
} from '../../../components/buttons/FormButtons';

export const ContentFormTitleWrapper = styled(Box)`
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 500;
`;

export const LoaderWrapper = styled(Box)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentFormHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: 45px 0 30px;
`;

export const ContentFormControlPanel = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const ContentFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const ContentFormButton = styled(FormButton)`
  max-width: fit-content;
`;

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  max-width: 900px;
`;

export const ContentFormFieldTitle = styled(Box)`
  margin-bottom: 7px;
`;

export const ContentFormImageTextContainer = styled(Box)`
  width: 100%;
  max-width: 1200px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
`;

export const ImageDropContainer = styled(Box)`
  margin-top: 30px;
  max-width: 1200px;
  width: 100%;
`;

export const StyledTabButton = styled(Tab)`
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.51);
  width: 100%;
  max-width: 50px;
  min-width: 20px;
  max-height: 36px;
  min-height: 20px;
  padding: 12px 16px;
  margin: 6px 0px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  indicator-color: secondary;
  &:hover: {
    background-color: rgba(0, 0, 0, 0.4);
  }
  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid #ffa94c;
    outline-offset: 2px;
  }
  &:first-child {
    margin-left: 4px;
  }
  &:last-child {
    margin-right: 4px;
  }
  &.Mui-selected {
    background-color: #ffa94c;
    color: #fff;
    border-radius: 3px;
    outline: 2px solid #ffa94c;
    outline-offset: 2px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const LanguageTabPanel = styled(Box)`
  width: 100%;
  font-size: 0.875rem;
  div {
    padding: 0;
  }
`;

export const TabButtonsContainer = styled(Box)`
  min-width: 320px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
  & .MuiTabs-flexContainer {
    justify-content: flex-start;
  }
  & .MuiTab-root {
    margin-right: 7px;
  }
`;

export const ContentFormErrorContainer = styled(Typography)`
  color: #d43131;
  display: block;
`;

export const ContentFormIconButton = styled(IconButton)`
  height: 48px;
  width: 48px;
`;

export const ContentFormFieldWrapper = styled(Box)`
  margin-top: 16px;
  align-items: center;
`;

export const FieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const FieldListContainer = styled(Box)`
  margin-top: 30px;
`;

export const ContentFormTextField = styled(ReduxTextField)`
  width: 100%;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const ContentFormImageDropContainer = styled(Box)`
  max-height: 600px;
  overflow-y: auto;
`;
