import {
  addFact,
  deleteFact,
  getFactsList,
  updateFact,
} from '../../../api/publishing/factsApi';

export const SET_FACTS_LIST = 'SET_FACTS_LIST';
export const SET_IS_FACTS_LOADING = 'SET_IS_FACTS_LOADING';

export const setFactsList = (data) => {
  return {
    type: SET_FACTS_LIST,
    payload: { data },
  };
};

export const setIsFactsListLoading = (data) => {
  return {
    type: SET_IS_FACTS_LOADING,
    payload: { data },
  };
};

export const addFactThunk = (data) => async () => {
  const response = await addFact(data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const getFactsListThunk = (destinationId) => async (dispatch) => {
  dispatch(setIsFactsListLoading(true));

  const response = await getFactsList(destinationId);

  if (response.status === 200) {
    dispatch(setFactsList(response.data));
    dispatch(setIsFactsListLoading(false));
  }
};

export const updateFactThunk = (data) => async () => {
  const response = await updateFact(data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const deleteFactThunk = (recordId) => async () => {
  const response = await deleteFact(recordId);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
