import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import {
  CircularProgress,
}  from '@mui/material';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/reduxStore';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
} from "react-router-dom";
import './index.module.css';
import './i18/i18n.js';


const root = createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<CircularProgress sx={{ position: 'absolute', right: '50%', top: '200px', color: '#FFA94C' }} />}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
) 

reportWebVitals();