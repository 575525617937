import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StyledFormHelperText } from '../../styled';

import ImageDrop from '../../../HomePageTab/imageDrop/ImageDrop';

import { validateImageListTotalSize } from '../../../../attractions/content/validate';

const ArticlesFormImageField = ({
  name,
  disabled,
  columns,
  filesLimit,
  allowedFileTypes,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: async (value) =>
          (await validateImageListTotalSize(value, 50))
            ? null
            : t('articles.validation.images_size_error'),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <ImageDrop
            fileList={value}
            onFileListChange={(value) => {
              onChange(value);
            }}
            filesLimit={filesLimit}
            disabled={disabled}
            columns={columns}
            allowedFileTypes={allowedFileTypes}
            rowHeight={300}
          />
          <StyledFormHelperText>{error?.message}</StyledFormHelperText>
        </>
      )}
    />
  );
};
export default ArticlesFormImageField;
