import React from 'react';
import {
    FormControl,
    FormHelperText,
    Select,
} from '@mui/material';

const HelperText = ({ touched, error }) => {
    if (!(touched && error)) {
        return
    } else {
        return <FormHelperText sx={{ color: '#d43131' }}>* {touched && error}</FormHelperText>
    }
}

export const SelectInput = ({ input, label, meta: { touched, error }, children, ...props}) => {
    return (
        <FormControl error={touched && error} sx={{width: '100%'}}>
            <Select
                displayEmpty
                native
                value={input.value}
                onChange={input.onChange}
                size="small"
                sx={{height: '37px', opacity: input.value !== '' ? 1 : 0.6, '&.Mui-disabled': {
                    textFillColor: 'rgba(0, 0, 0, 0.6)',
                    opacity: 0.6
                }}}
            >
                {children}
            </Select>
            {HelperText({ touched, error })}
        </FormControl>
    )
}

export const SelectTimeInput = ({ input, label, meta: { touched, error }, children, ...props}) => {
    return (
        <FormControl error={touched && error} sx={{width: '100%'}}>
            <Select
                displayEmpty
                native
                value={input.value}
                onChange={input.onChange}
                size="small"
                sx={{height: '37px', opacity: input.value !== '' ? 1 : 0.6, '&.Mui-disabled': {
                    textFillColor: 'rgba(0, 0, 0, 0.6)',
                    opacity: 0.6
                }}}
            >
                {children}
            </Select>
            {HelperText({ touched, error })}
        </FormControl>
    )
}