export const PRICES_TABLE_ROW_LIST = [
  { sectionName: 'standardPrice', label: 'attractions.price.standard_prices' },
  { sectionName: 'contractPrice', label: 'attractions.price.contract_prices' },
  {
    sectionName: 'valueAddedTaxAmount',
    label: 'attractions.price.contract_prices',
    hasVatSelect: true,
    disabled: true,
  },
  {
    sectionName: 'netAmount',
    label: 'attractions.price.net_amount',
    disabled: true,
  },
];

export const PRICES_TABLE_ROW_LIST_STANDART = [
  { sectionName: 'standardPrice', label: 'attractions.price.standard_prices' }
];

export const PRICES_TABLE_ROW_FIELDS = [
  'adultPrice',
  'childPrice',
  'studentPrice',
  'seniorPrice',
  'disabledPrice',
  'familyPrice',
];

export const NEW_PRICE_OBJECT_DEFAULT_VALUES = {
  standardPrice: {
    adultPrice: null,
    childPrice: null,
    studentPrice: null,
    seniorPrice: null,
    disabledPrice: null,
    familyPrice: null,
  },
  contractPrice: {
    adultPrice: null,
    childPrice: null,
    studentPrice: null,
    seniorPrice: null,
    disabledPrice: null,
    familyPrice: null,
  },
  valueAddedTaxAmount: {
    valueAddedTax: '',
    adultPrice: null,
    childPrice: null,
    studentPrice: null,
    seniorPrice: null,
    disabledPrice: null,
    familyPrice: null,
  },
  netAmount: {
    adultPrice: null,
    childPrice: null,
    studentPrice: null,
    seniorPrice: null,
    disabledPrice: null,
    familyPrice: null,
  },
  startDate: null,
  endDate: null,
  isOpened: false,
};

export const TABLE_HEAD_LABELS = [
  '',
  'attractions.price.adult',
  'attractions.price.child',
  'attractions.price.student',
  'attractions.price.senior',
  'attractions.price.disabled',
  'attractions.price.family',
];

export const VALUE_ADDED_TAX_AMOUNT = 'valueAddedTaxAmount';
export const NET_AMOUNT = 'netAmount';
export const PRICE_FORM = 'Price';

export const CZECH_DESTINATION_STAGE = '62876c41e0643d1f419b1a88';
export const CZECH_DESTINATION_PROD = '6321caa86d46069b4d8d200a';