import {
  DEFAULT_RESPONDENT_POSITION_FIELD_MAX_LENGTH,
  DEFAULT_TEXT_FIELD_MAX_LENGTH
} from '../../../../consts/validation';

export function hasNotOnlySpaces(value) {
  return value
    ? value.trim().length === 0
      ? 'Cant contain only spaces'
      : undefined
    : undefined;
}

export function ListIsNotEmpty(value) {
  if (!value == null) {
    return value.length === 0 ? 'Must be not empty' : undefined;
  }
  return 'Must be not empty';
}

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const onlyNumber = (value) =>
  value && isNaN(Number(value)) ? 'Enter only number' : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);
export const maxLength50 = maxLength(DEFAULT_RESPONDENT_POSITION_FIELD_MAX_LENGTH);
export const maxLength2000 = maxLength(2000);
