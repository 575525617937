import { useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValid } from 'date-fns';

import { Box, Typography } from '@mui/material';

import DealFormLanguageTabs from './DealFormLanguageTabs';
import DealStatusField from './dealFormFields/DealStatusField';
import DealBenefitField from './dealFormFields/DealBenefitField';
import DealPercentageField from './dealFormFields/DealPercentageField';
import DealDateField from './dealFormFields/DealDateField';
import DealEndDateCheckboxField from './dealFormFields/DealEndDateCheckboxField';
import DealUsesLimitedCheckboxField from './dealFormFields/DealUsesLimitedCheckboxField';
import DealUsesLimitedField from './dealFormFields/DealUsesLimitedField';

import {
  DealFormControlPanel,
  DealFormFieldWrapper,
  DealFormFieldTitle,
  DealFormDateFieldWrapper,
} from './styled';

import { formatLanguageFieldsData } from '../helpers';
import {
  EXACT,
  NOT_LIMITED,
  DISCOUNT,
  USES_NUMBER_SELECT_OPTIONS,
  MANAGER_ROLE_LIST
} from '../consts';

import {
  FormButton,
  SaveButton,
} from '../../../../components/buttons/FormButtons';
import { useNavigationPrompt } from '../../../../hooks/navigation';
import { useSelector } from 'react-redux';

const NewDealAddForm = (props) => {
  const {
    dealStatuses,
    benefitOptions,
    selectedLanguage,
    languages,
    handleSubmitAddForm,
    showSaveAnimation,
    handleCancelAddForm,
  } = props;

  const { t } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(languages[0]);

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  const languageFormFieldArrays = useMemo(
    () =>
      languages.reduce((acc, lang) => {
        acc[lang] = [
          {
            title: '',
            formula: '',
            note: '',
            redeemInstructions: ''
          },
        ];

        return acc;
      }, {}),
    [languages]
  );

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      dealStatus: dealStatuses.parameters[0].id,
      benefits: benefitOptions.parameters[0].id,
      percentage: '',
      dealStartDate: new Date(),
      dealEndDate: null,
      numberOfUses: USES_NUMBER_SELECT_OPTIONS[0],
      isEndDateOpen: true,
      isUsesLimited: false,
      ...languageFormFieldArrays,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitted },
  } = formMethods;

  const blockNavigation = isDirty && !isSubmitted;

  useNavigationPrompt(blockNavigation);

  const isEndDateOpenCheckbox = watch('isEndDateOpen');
  const usesLimitCheckboxValue = watch('isUsesLimited');
  const activeBenefitValue = watch('benefits');
  const startDateFieldValue = watch('dealStartDate');

  const dealStartDateRules = {
    validate: (value) =>
      isValid(value) ? value : t('attractions.deals.error_invalid_date'),
    required: {
      value: true,
      message: t('attractions.deals.error_required'),
    },
  };

  const dealEndDateRules = {
    required: {
      value: !isEndDateOpenCheckbox,
      message: t('attractions.deals.error_required'),
    },
  };

  const handleChangeLanguageTab = (_, newValue) => setCurrentTabId(newValue);

  const onSubmit = (data) => {
    const generalFieldsData = {
      status: isManagerRoleSelected ? 'Inactive' : data.dealStatus,
      benefit: data.benefits,
      percentage: data.percentage,
      startDate: data.dealStartDate,
      endDate: data.dealEndDate || null,
      numberOfUses: data.isUsesLimited ? null : data.numberOfUses,
      numberOfUsesType: data.isUsesLimited ? NOT_LIMITED : EXACT,
      ...formatLanguageFieldsData(data),
    };

    handleSubmitAddForm(generalFieldsData);
  };

  return (
    <Box display="flex" flexDirection="column">
      <DealFormControlPanel>
        <Typography fontWeight={500} fontSize="20px" textTransform="uppercase">
          {t('attractions.deals.new_deal_title')}
        </Typography>
        <Box display="flex" gap="10px">
          <FormButton
            label={t('attractions.deals.buttons.cancel')}
            onClick={handleCancelAddForm}
          />
          <SaveButton
            label={t('attractions.deals.buttons.save')}
            onClick={handleSubmit(onSubmit)}
            isLoading={showSaveAnimation}
          />
        </Box>
      </DealFormControlPanel>
      <FormProvider {...formMethods}>
        <form aria-label="form">
          {!isManagerRoleSelected && (
            <DealFormFieldWrapper>
              <DealFormFieldTitle>
                {t('attractions.deals.status')}:
              </DealFormFieldTitle>
              <DealStatusField
                name="dealStatus"
                options={dealStatuses}
                selectedLanguage={selectedLanguage}
                disabled={isManagerRoleSelected}
              />
            </DealFormFieldWrapper>
          )}
          <DealFormFieldWrapper>
            <DealFormFieldTitle>
              {t('attractions.deals.benefit')}:
            </DealFormFieldTitle>
            <DealBenefitField
              name="benefits"
              options={benefitOptions}
              selectedLanguage={selectedLanguage}
            />
          </DealFormFieldWrapper>

          {activeBenefitValue === DISCOUNT && (
            <DealFormFieldWrapper>
              <DealFormFieldTitle>
                {t('attractions.deals.percentage')}:
              </DealFormFieldTitle>
              <DealPercentageField name="percentage" />
            </DealFormFieldWrapper>
          )}

          <Box display="flex">
            <DealFormLanguageTabs
              languages={languages}
              handleChangeLanguageTab={handleChangeLanguageTab}
              currentTabId={currentTabId}
              activeBenefitValue={activeBenefitValue}
            />
          </Box>

          <Box display="flex" flexDirection="column">
            <DealFormDateFieldWrapper>
              <DealFormFieldTitle>
                {t('attractions.deals.start_date')}:
              </DealFormFieldTitle>
              <DealDateField name="dealStartDate" rules={dealStartDateRules} />
            </DealFormDateFieldWrapper>

            <DealFormDateFieldWrapper>
              <DealFormFieldTitle>
                {t('attractions.deals.end_date')}:
              </DealFormFieldTitle>
              <Box>
                <DealDateField
                  name="dealEndDate"
                  rules={dealEndDateRules}
                  disabled={isEndDateOpenCheckbox}
                  minDate={startDateFieldValue}
                />
                <DealEndDateCheckboxField name="isEndDateOpen" />
              </Box>
            </DealFormDateFieldWrapper>

            <DealFormFieldWrapper>
              <DealFormFieldTitle>
                {t('attractions.deals.number_of_uses')}:
              </DealFormFieldTitle>
              <Box display="flex" flexGrow={1}>
                <DealUsesLimitedField
                  name="numberOfUses"
                  required={usesLimitCheckboxValue}
                />
                <DealUsesLimitedCheckboxField name="isUsesLimited" />
              </Box>
            </DealFormFieldWrapper>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default NewDealAddForm;
