import React, { useState } from 'react';
import {
  TextField,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Select,
  Checkbox,
} from '@mui/material';

const HelperText = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return (
      <FormHelperText sx={{ color: '#d43131' }}>
        * {touched && error}
      </FormHelperText>
    );
  }
};

export const CheckboxInput = ({ input, label, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.isEdit ? input.value : props.isChecked}
          onChange={input.onChange}
          sx={{ padding: '4px 4px 4px 8px' }}
        />
      }
      className={props.className}
      label={label}
    />
  );
};

export const CheckboxDisabledInput = ({ input, label, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={props.disabled}
          checked={props.isChecked}
          sx={{ padding: '4px 4px 4px 8px' }}
        />
      }
      className={props.className}
      label={label}
    />
  );
};

export const SelectInput = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...props
}) => {
  return (
    <FormControl error={touched && error} fullWidth disabled={props.disabled}>
      <Select
        displayEmpty
        native
        value={input.value}
        onChange={input.onChange}
        size="small"
        disabled={props.disabled}
      >
        {children}
      </Select>
      {HelperText({ touched, error })}
    </FormControl>
  );
};

export const InputForm = ({
  label,
  input,
  meta: { touched, invalid, error },
  text,
  length,
  ...props
}) => {
  return (
    <>
      <TextField
        label={label}
        placeholder={text}
        error={touched && error}
        {...input}
        {...props}
        size="small"
      />
      {HelperText({ touched, error })}
    </>
  );
};
