import { useTranslation } from 'react-i18next';

import { Table, TableContainer, TableBody, TableCell } from '@mui/material';

import { ReviewTableHead, ReviewTableHeadRow } from './styled';

const ReviewsTable = ({ children }) => {
  const { t } = useTranslation();

  const tableHeaderLables = t('reviews.tableHeader', {
    returnObjects: true,
  });

  return (
    <TableContainer>
      <Table>
        <ReviewTableHead>
          <ReviewTableHeadRow>
            {tableHeaderLables.map((label, idx) => (
              <TableCell key={idx}>{label}</TableCell>
            ))}
          </ReviewTableHeadRow>
        </ReviewTableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReviewsTable;
