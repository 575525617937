export const getAcceptedFileTypes = (fileTypes) => {
  const acceptedFileTypes = {};

  if (!fileTypes.length) {
    acceptedFileTypes['image/*'] = [];
  } else {
    fileTypes.forEach((fileType) => {
      acceptedFileTypes[`image/${fileType}`] = [`.${fileType}`];
    });
  }

  return acceptedFileTypes;
};

export const isImageUrl = (file) => {
  return typeof file === 'string';
};

export const isImage = (file) => {
  if (file.type.split('/')[0] === 'image') {
    return true;
  }

  if (file.type.split('/')[0] === 'application') {
    return true;
  }
};

const megabyteSize = 1048576;
const kilobyteSize = 1024;

export const convertBytesToMbsOrKbs = (filesize) => {
  let size = '';

  if (filesize >= megabyteSize) {
    size = filesize / megabyteSize + ' megabytes';
  } else if (filesize >= kilobyteSize) {
    size = filesize / kilobyteSize + ' kilobytes';
  } else {
    size = filesize + ' bytes';
  }

  return size;
};

export const convertBytesToMbs = (filesize) => {
  return filesize / megabyteSize;
};

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event?.target?.result);
    };

    reader.onerror = (event) => {
      reader.abort();
      reject(event);
    };

    reader.readAsDataURL(file);
  });
};
