import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { validateTextFieldValue } from '../../helpers';
import { VisitorFormTextField } from '../styled';

const TextInput = (props) => {
  const { name, disabled, required } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('attractions.deals.error_required'),
        },
        maxLength: {
          value: 200,
          message: t('visitors.error_max_length', { chars: 200 }),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <VisitorFormTextField
          value={value}
          disabled={disabled}
          onChange={(event) =>
            onChange(validateTextFieldValue(event.target.value))
          }
          fullWidth
          size="small"
          error={!!error}
          helperText={error?.message}
          type="text"
        />
      )}
    />
  );
};

export default TextInput;
