export const ALL_WEEK = 'all Week';
export const WEEK_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const TYPE_OPENING_HOURS = 'OpeningHours';
export const TYPE_STARTING_TIMES = 'StartingTimes';
export const TYPE_ALL_WEEK = 'AllWeek';

export const TYPE_OPTION_LIST = [
  { id: TYPE_OPENING_HOURS, name: 'attractions.season.opening_hours' },
  { id: TYPE_STARTING_TIMES, name: 'attractions.season.starting_times' },
  { id: TYPE_ALL_WEEK, name: 'attractions.season.all_Week' },
];

export const CLOSED_ON_WEEK_DAYS_OPTIONS = [
  { value: 'Monday', label: 'attractions.season.monday' },
  { value: 'Tuesday', label: 'attractions.season.tuesday' },
  { value: 'Wednesday', label: 'attractions.season.wednesday' },
  { value: 'Thursday', label: 'attractions.season.thursday' },
  { value: 'Friday', label: 'attractions.season.friday' },
  { value: 'Saturday', label: 'attractions.season.saturday' },
  { value: 'Sunday', label: 'attractions.season.sunday' },
];

export const ADMIN_ROLE_LIST = ['Administrator', 'SystemManager'];
export const MANAGER_ROLE_LIST = ['AttractionManager', 'AttractionManagerPrimary'];