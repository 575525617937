import { Box, Select, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { MobileDateTimePicker } from '@mui/lab';

import { AutocompleteField } from './forms/formFIelds/AutocompleteField';

export const LogsPageContentContainer = styled(Box)`
  width: 100%;
  margin-top: 100px;
  position: relative;
`;

export const FilterFormContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledMobileDateTimePicker = styled(MobileDateTimePicker)`
  max-width: 200px;
  width: 100%;
`;

export const LogFormDatePickerTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }

  & .MuiOutlinedInput-input {
    cursor: pointer;
    max-width: 169px;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const LogFormAutocompleteField = styled(AutocompleteField)`
  max-width: 300px;
  width: 100%;
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
  .MuiChip-root.Mui-disabled {
    opacity: 0.9;
    pointer-events: none;
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const LogFormSelectField = styled(Select)`
  max-width: 185px;
  width: 100%;
`;

export const DatePickerContainer = styled(Box)`
  max-width: 215px;
  width: 100%;
`;
