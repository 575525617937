import { connect } from 'react-redux';
import { compose } from 'redux';

import ProductsList from './ProductsList';

import {
  getProductsThunk,
  getProductSettingsThunk,
} from '../../redux/productsReducer/productActions';
import { CURRENCIES } from '../../consts/consts';

const mapStateToProps = (state) => {
  const languages = state.appReducer.selectedDestination.languages;

  const currenciesList = state.appReducer.globalSettingsData.find(
    (setting) => setting.name.en === CURRENCIES
  );

  return {
    currentDestinationData: state.appReducer.selectedDestination,
    products: state.productsReducer.products,
    productSettings: state.productsReducer.productSettings,
    isDataLoading: state.productsReducer.isDataLoading,
    isProductSettingsLoading: state.productsReducer.isProductSettingsLoading,
    languages,
    currenciesList,
  };
};

const ProductsListContainer = compose(
  connect(mapStateToProps, {
    getProductsThunk,
    getProductSettingsThunk,
  })
)(ProductsList);

export default ProductsListContainer;
