import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';

import ProductEditForm from './ProductEditForm';

import { formatLanguageFieldsData } from '../helpers';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import {
  getProductsThunk,
  updateProductThunk,
} from '../../../redux/productsReducer/productActions';

const ProductEditFormContainer = ({
  productData,
  handleChangeEditMode,
  isInEditMode,
  handleCancelChanges,
  currency,
  vatOptions,
  resetActiveRow,
  destinationId,
  handleChangeLanguageTab,
  currentLanguageTabId,
  languages,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const { addError } = useAPIError();
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const vatPercentageValue = watch('vatPercentage');
  const netPriceValue = watch('netPrice');

  const handleCalcVatCost = (value) => {
    const newVatCost = ((vatPercentageValue / 100) * value).toFixed(2);
    const newGrossPrice = (Number(value) + Number(newVatCost)).toFixed(2);

    setValue('vatCost', newVatCost);
    setValue('grossPrice', newGrossPrice);
  };

  const handleCalcPrice = (value) => {
    const floatedVatPercentage = (value / 100).toFixed(2);
    const newVatCost = (netPriceValue * floatedVatPercentage).toFixed(2);
    const newGrossPrice = (Number(netPriceValue) + Number(newVatCost)).toFixed(
      2
    );

    setValue('vatCost', newVatCost);
    setValue('grossPrice', newGrossPrice);
  };

  const onSubmit = (data) => {
    const submitData = {
      id: data.id,
      name: data.name,
      specification: data.specification,
      validity: data.validity,
      netPrice: data.netPrice,
      vatInPercent: data.vatPercentage,
      ...formatLanguageFieldsData(data),
    };

    setShowSaveAnimation(true);

    dispatch(updateProductThunk(submitData.id, submitData))
      .then(() => {
        setShowSaveAnimation(false);
        resetActiveRow();

        dispatch(getProductsThunk(destinationId));

        addError(t('products.update_product_success'), 'Success');
      })
      .catch((error) => {
        addError(t('products.update_product_error'), 'Error', error);
      });
  };

  return (
    <ProductEditForm
      productData={productData}
      handleChangeEditMode={handleChangeEditMode}
      isInEditMode={isInEditMode}
      handleCancelChanges={handleCancelChanges}
      handleCalcVatCost={handleCalcVatCost}
      handleCalcPrice={handleCalcPrice}
      currency={currency}
      vatOptions={vatOptions}
      onSubmit={onSubmit}
      isLoading={showSaveAnimation}
      handleChangeLanguageTab={handleChangeLanguageTab}
      currentLanguageTabId={currentLanguageTabId}
      languages={languages}
    />
  );
};

export default connect(null, { updateProductThunk, getProductsThunk })(
  ProductEditFormContainer
);
