import React from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Stack, Button, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@mui/styles';

import { requiredCategory, requiredStatus } from './FieldLevelFormValidation';
import QuestionLanguageTabs from './questionsComponents/QuestionLanguageTabs';
import { SelectInput } from './questionsComponents/reduxFormComponents';
import { Input } from './questionsComponents/reduxFormComponents';

import { maxLength10, onlyNumber, negativeOrder } from '../category/validate';

import { CONTENT_LANGUAGES } from '../../../consts/consts';
import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';

const useStyles = makeStyles({
  form: {
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.6,
      },
      '&.Mui-disabled': {
        textFillColor: 'rgba(0, 0, 0, 0.9)',
      },
    },
  },
  textField: {
    '& .MuiInputBase-root': {
      height: '40px',
      '& .MuiOutlinedInput-input': {
        padding: '8px 0px 8px 14px',
        height: '40px',
        width: '125px',
      },
    },
  },
});

const QuestionsForm = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const filterCategoriesByDestinationId =
    props.categoryFaqState?.categoryData?.filter((item) =>
      item.availableForDestination.includes(
        props.appState.selectedDestination.id
      )
    );

  const onEditQuestion = () => {
    props.setIsEdit(true);
  };

  const onDeleteQuestion = () => {
    props.setShowAlert(true);
  };

  const handleClose = () => {
    props.setIsEdit(false);
    props.setShowAlert(false);
  };

  const handleCancelChanges = () => {
    props.setIsEdit(false);
    dispatch(
      reset(`QuestionsForm${props.questionFaqState.currentQuestion.id}`)
    );
  };

  return (
    <Box
      component="form"
      onSubmit={props.handleSubmit}
      className={classes.form}
    >
      {props.addQuestion ? (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: '30px', marginRight: '18px' }}
        >
          <Button
            variant="outlined"
            onClick={props.onCancel}
            type="button"
            disabled={props.isSave}
          >
            {t('faq_page.questions.cancel_button')}
          </Button>
          <LoadingButton
            loading={props.isSave}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{ color: '#fff', marginLeft: '16px' }}
            disabled={props.addQuestion || props.isEdit ? false : true}
            type="submit"
          >
            {t('faq_page.questions.save_button')}
          </LoadingButton>
        </Stack>
      ) : (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Button
            variant="outlined"
            onClick={handleCancelChanges}
            type="button"
            disabled={!props.isEdit}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="outlined"
            onClick={onEditQuestion}
            type="button"
            disabled={props.isEdit}
          >
            {t('faq_page.questions.edit_button')}
          </Button>
          <Button
            variant="outlined"
            onClick={onDeleteQuestion}
            type="button"
            disabled={props.isSave}
          >
            {t('faq_page.questions.delete_button')}
          </Button>
          <LoadingButton
            loading={props.isSave}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{ color: '#fff' }}
            disabled={props.addQuestion || props.isEdit ? false : true}
            type="submit"
          >
            {t('faq_page.questions.save_button')}
          </LoadingButton>
        </Stack>
      )}
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={6}
        >
          <Box sx={{ whiteSpace: 'nowrap', width: '10%' }}>
            {t('faq_page.questions.status')}
          </Box>
          <Box sx={{ display: 'flex', width: '85%' }}>
            <Box sx={{ display: 'flex', width: '30%' }}>
              <Field
                name="status"
                component={SelectInput}
                validate={[requiredStatus]}
                disabled={props.addQuestion || props.isEdit ? false : true}
              >
                <option value={''}>{t('faq_page.questions.status')}</option>
                {props.predefinedState[0].parameters.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.value[props.selectedLanguage]}
                  </option>
                ))}
              </Field>
            </Box>
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={6}
        >
          <Box sx={{ whiteSpace: 'nowrap', width: '10%' }}>
            {t('faq_page.questions.order')}
          </Box>
          <Box sx={{ display: 'flex', width: '85%' }}>
            <Box sx={{ display: 'flex', width: '30%' }}>
              <Field
                name="order"
                placeholder={
                  props.addQuestion
                    ? `${t('faq_page.categories.order')}`
                    : props.questionFaqState.currentQuestion.order ||
                      `${t('faq_page.categories.no_order')}`
                }
                component={Input}
                validate={[maxLength10, onlyNumber, negativeOrder]}
                disabled={props.addQuestion || props.isEdit ? false : true}
              >
                {props.questionFaqState.currentQuestion.order}
              </Field>
            </Box>
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={6}
        >
          <Box sx={{ whiteSpace: 'nowrap', width: '10%' }}>
            {t('faq_page.questions.category')}
          </Box>
          <Box sx={{ display: 'flex', width: '85%' }}>
            <Box sx={{ display: 'flex', width: '30%' }}>
              <Field
                name="categoryId"
                component={SelectInput}
                disabled={props.addQuestion || props.isEdit ? false : true}
                validate={[requiredCategory]}
              >
                <option value={''}>{t('faq_page.questions.category')}</option>
                {!!filterCategoriesByDestinationId.length &&
                  filterCategoriesByDestinationId.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name[props.selectedLanguage] || item.name.en}
                    </option>
                  ))}
              </Field>
            </Box>
          </Box>
        </Stack>
        <QuestionLanguageTabs
          addElement={props.addQuestion}
          updateElement={props.updateQuestion}
          isEdit={props.isEdit}
          currentQuestion={props.questionFaqState.currentQuestion}
          languages={props.appState.globalSettingsData.filter(
            (item) => item.name.en === CONTENT_LANGUAGES
          )}
          selectedLanguage={props.appState.selectedLanguage}
          error={props.error}
        />
      </Stack>

      <ConfirmationDialog
        showAlert={props.showAlert}
        handleClose={handleClose}
        handleCloseSuccess={props.handleCloseSuccess}
        text={t('faq_page.questions.delete_question')}
      />
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.addQuestion) {
    return {
      form: 'QuestionsForm',
      initialValues: {
        order: '',
        title: {},
        text: {},
        categoryId: null,
        status: null,
      },
    };
  }

  if (state.faqQuestionReducer.currentQuestion) {
    return {
      form: `QuestionsForm${state.faqQuestionReducer.currentQuestion.id}`,
      initialValues: {
        order: state.faqQuestionReducer.currentQuestion.order,
        title: { ...state.faqQuestionReducer.currentQuestion.title },
        text: { ...state.faqQuestionReducer.currentQuestion.text },
        categoryId: state.faqQuestionReducer.currentQuestion.categoryId,
        status: state.faqQuestionReducer.currentQuestion.status,
      },
    };
  }

  if (!ownProps.addQuestion && !ownProps.isEdit) {
    return {
      form: `QuestionsForm${state.faqQuestionReducer.currentQuestion.id}`,
      initialValues: {
        order: state.faqQuestionReducer.currentQuestion.order,
        title: { ...state.faqQuestionReducer.currentQuestion.title },
        text: { ...state.faqQuestionReducer.currentQuestion.text },
        categoryId: state.faqQuestionReducer.currentQuestion.categoryId,
        status: state.faqQuestionReducer.currentQuestion.status,
      },
    };
  }
};

const reduxQuestionsForm = reduxForm({
  destroyOnUnmount: false,
})(QuestionsForm);

export default connect(mapStateToProps)(reduxQuestionsForm);
