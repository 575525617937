export const POINTS_PAGE_URL = '/points';
const TAB_POSITION = 3;

export const salesPointTabs = [
  {
    name: 'attractions.setup_tab',
    path: 'setup',
    matchUrls: ['setup'],
  },
  {
    name: 'attractions.content_tab',
    path: 'content',
    matchUrls: ['content'],
  },
  {
    name: 'attractions.availability_tab',
    path: 'availability',
    matchUrls: ['availability'],
  },
  {
    name: 'attractions.prices_tab',
    path: 'prices',
    matchUrls: ['prices'],
  },
  {
    name: 'attractions.deals_tab',
    path: 'deals',
    matchUrls: ['deals', 'new-deal'],
  },
  {
    name: 'attractions.notifications_tab',
    path: 'notifications',
    matchUrls: ['notifications'],
  },
  {
    name: 'attractions.reviews_tab',
    path: 'reviews',
    matchUrls: ['reviews', 'new-review'],
  },
  {
    name: 'attractions.visits_tab',
    path: 'visits',
    matchUrls: ['visits'],
  },
  {
    name: 'attractions.reports_tab',
    path: 'reports',
    matchUrls: ['reports'],
  },
  {
    name: 'attractions.company_tab',
    path: 'company',
    matchUrls: ['company'],
  },
];

export const getOpenedTabIdByUrlParameter = (tabs, path) => {
  const splittedPath = path.split('/');

  const tabName = splittedPath[TAB_POSITION];

  if (!tabName) return 0;

  const tabId = tabs.findIndex((tab) => tab.matchUrls.includes(tabName));

  if (tabId === -1) return 0;

  return tabId;
};
