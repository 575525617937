import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { SaveButton } from "../../components/buttons/FormButtons";

export const AgreementPageWrapper = styled(Box)`
  width: 1300px;
  margin: 0 0 0 -15px;
`;

export const AgreementPageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const AgreementFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
  width: 100%;
`;

export const AgreementFieldTitle = styled(Box)`
  max-width: 210px;
  width: 100%;
`;

export const AgreementConfirmButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;