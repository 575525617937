import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { validateTextFieldValue } from '../../helpers';
import { VisitorsFormField } from '../../styled';

const VisitorEditFormTextField = ({ name, disabled, required, maxLength }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: required,
          message: t('visitors.error_required'),
        },
        maxLength: {
          value: maxLength,
          message: t('visitors.error_max_length', {
            chars: maxLength,
          }),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <VisitorsFormField
          disabled={disabled}
          fullWidth
          size="small"
          onChange={(e) => onChange(validateTextFieldValue(e.target.value))}
          value={value}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default VisitorEditFormTextField;
