import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { CheckboxInput } from '../../../../components/reduxFormComponents/reduxFormComponents';

import { isPasswordValid } from '../forms/validation';

const PasswordChangeDialog = (props) => {
  const { onDialogSubmit, isOpen, onClose } = props;

  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleDialogSubmit = () => {
    if (isPasswordValid(password)) {
      setIsPasswordInvalid(false);
      onDialogSubmit(password);
      setPassword('');
    } else {
      setIsPasswordInvalid(true);
    }
  };

  const handlePasswordValueChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleDialogClose = () => {
    setPassword('');
    setShowPassword(false);
    setIsPasswordInvalid(false);
    onClose();
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>{t('passwordChangeDialog.label')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('passwordChangeDialog.helperText')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t('passwordChangeDialog.password')}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant="standard"
            value={password}
            onChange={handlePasswordValueChange}
            required
            error={isPasswordInvalid}
            helperText={
              isPasswordInvalid
                ? t('passwordChangeDialog.invalidPassword')
                : null
            }
          />
          <CheckboxInput
            input={{ value: showPassword, onChange: handleShowPassword }}
            label={t('passwordChangeDialog.showPassword')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{t('cancel')}</Button>
          <Button onClick={handleDialogSubmit}>{t('save')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PasswordChangeDialog;
