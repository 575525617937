import { stopSubmit } from 'redux-form';

import {
  addAttractionNotificationApi,
  getAttractionNotificationsListApi,
  getFilteredAttractionNotificationsApi,
  deleteAttractionNotificationApi,
  editAttractionNotificationApi,
  editAttractionNotificationTranslationApi,
} from '../../../api/attractionsApi.js';

export const SET_NOTIFICATIONS_LIST = 'notificationReducer/setNotificationList';
export const SET_NOTIFICATION = 'notificationReducer/setNotification';
export const IS_LOADED_NOTIFICATIONS = 'notificationReducer/setIsLoaded';

export const setNotificationsList = (data) => {
  return { type: SET_NOTIFICATIONS_LIST, payload: { data } };
};

export const setNotification = (data) => {
  return { type: SET_NOTIFICATION, payload: { data } };
};

export const setIsLoadedNotifications = (isLoadedNotification) => {
  return { type: IS_LOADED_NOTIFICATIONS, isLoadedNotification };
};

export const getAttractionNotificationsListThunk =
  (attractionId) => async (dispatch) => {
    dispatch(setIsLoadedNotifications(false));
    const response = await getAttractionNotificationsListApi(attractionId);
    if (response.status === 200) {
      dispatch(setNotificationsList(response.data));
      dispatch(setIsLoadedNotifications(true));
    }
  };
export const getAttractionNotificationsListAfterTranslateThunk =
  (attractionId) => async (dispatch) => {
    const response = await getAttractionNotificationsListApi(attractionId);
    if (response.status === 200) {
      dispatch(setNotificationsList(response.data));
    }
  };

export const getFilteredAttractionNotificationsThunk =
  (filter) => async (dispatch) => {
    const response = await getFilteredAttractionNotificationsApi(filter);
    if (response.status === 200) {
      dispatch(setNotificationsList(response.data));
    }
  };

export const addAttractionNotificationThunk = (data) => async (dispatch) => {
  const response = await addAttractionNotificationApi(data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('NotificationForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const editAttractionNotificationThunk =
  (notificationData) => async (dispatch) => {
    const response = await editAttractionNotificationApi(notificationData);
    if (response.status < 200 || response.status >= 300) {
      dispatch(
        stopSubmit('NotificationForm', { _error: response.data.errors })
      );
      return Promise.reject(response.data.errors);
    }
  };

export const editAttractionNotificationTranslationThunk =
  (notificationData) => async (dispatch) => {
    const response = await editAttractionNotificationTranslationApi(notificationData);
    if(response.status === 200) {
      return response.data;
    }
    if (response.status < 200 || response.status >= 300) {
      dispatch(
        stopSubmit('NotificationForm', { _error: response.data.errors })
      );
      return Promise.reject(response.data.errors || response.data.detail);
    }
  };

export const deleteAttractionNotificationThunk =
  (attractionId, notificationId) => async () => {
    const response = await deleteAttractionNotificationApi(
      attractionId,
      notificationId
    );
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(response.data.errors);
    }
  };
