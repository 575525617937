const VISITOR_NUMBER_MAX_LENGTH = 7;

export const parseVisitorNumber = (number) =>
  String(number).padStart(VISITOR_NUMBER_MAX_LENGTH, '0');

const compareByOrder = (a, b, orderBy) => {
  const leftItem = a[orderBy];
  const rightItem = b[orderBy];

  if (rightItem < leftItem) {
    return -1;
  }
  if (rightItem > leftItem) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => compareByOrder(a, b, orderBy)
    : (a, b) => -compareByOrder(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const mappedValues = array.map((el, index) => [el, index]);
  mappedValues.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return mappedValues.map((el) => el[0]);
};

export const validateTextFieldValue = (value) => {
  return value.trimStart().replace(/\s\s+/g, ' ');
};
