import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TextField, Box, Select } from '@mui/material';

import AutocompleteTextField from './AutocompleteTextField';

const useStyles = makeStyles({
  field: {
    maxWidth: '215px',
    width: '100%',
  },
  iconButton: {
    position: 'absolute',
    right: '10px',
    top: '7px',
    width: '25px',
    height: '25px',
    color: '#cfcaca',
  },
  searchFieldWrapper: {
    position: 'relative',
    width: '300px',
    height: '40px',
    '& .MuiOutlinedInput-root': {
      height: '40px',
      width: '300px',
    },
  },
});

const UserFilterForm = (props) => {
  const {
    availableCompanies,
    availableStatus,
    availableRoles,
    selectedLanguage,
    status,
    onStatusChange,
    nameOrEmail,
    onNameOrEmailChange,
    onCompanyChange,
    onRoleChange,
    handleResetFilters,
    role,
    company,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const clearNameOrEmailValue = () => {
    onNameOrEmailChange('');
  };

  const handleChangeUserNameorEmail = (event) => {
    onNameOrEmailChange(event.target.value);
  };

  return (
    <Box display="flex" gap="24px" mt="30px" mb="20px">
      <Box sx={{ minWidth: 120 }}>
        <Select
          size="small"
          native
          className={classes.field}
          onChange={onStatusChange}
          value={status}
        >
          <option value={''}>{t('users.status')}</option>
          {availableStatus?.parameters.map((status, i) => (
            <option value={status.id} key={i}>
              {status.value[selectedLanguage] || status.value.en}
            </option>
          ))}
        </Select>
      </Box>
      <Box className={classes.searchFieldWrapper}>
        <TextField
          placeholder={t('users.name_or_email')}
          onChange={handleChangeUserNameorEmail}
          size="small"
          value={nameOrEmail}
        />
        {nameOrEmail !== '' ? (
          <IconButton
            onClick={clearNameOrEmailValue}
            className={classes.iconButton}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <SearchIcon className={classes.iconButton} />
        )}
      </Box>

      <Box sx={{ minWidth: 300 }}>
        <AutocompleteTextField
          options={availableCompanies}
          getOptionLabel={(option) => option.name}
          disablePortal
          label={t('users.company')}
          onChange={onCompanyChange}
          value={company}
        />
      </Box>
      <Box sx={{ minWidth: 120 }}>
        <Select
          size="small"
          native
          className={classes.field}
          onChange={onRoleChange}
          value={role}
        >
          <option value={''}>{t('users.role')}</option>
          {availableRoles?.parameters.map((role, i) => (
            <option value={role.id} key={i}>
              {role.value[selectedLanguage] || role.value.en}
            </option>
          ))}
        </Select>
      </Box>
      <IconButton onClick={handleResetFilters}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default UserFilterForm;
