import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, MenuItem, Typography } from '@mui/material';

import { SelectHelperText, SetupFormSelectFieldInput } from '../styled';

import { TYPE_AREA, TYPE_LOCATION, TYPE_REGION } from '../../constants';
import { ACTIVE_STATUS } from '../constants';

const getLocationMenuItems = (
  optionList,
  selectedLanguage,
  defaultDestinationLanguage
) =>
  optionList.map(
    (location) =>
      location.locationStatus === ACTIVE_STATUS && (
        <MenuItem value={location.id} key={location.id}>
          <Typography variant="inherit" noWrap>
            {location.title[selectedLanguage] ||
              location.title.en ||
              location.title[defaultDestinationLanguage]}
          </Typography>
        </MenuItem>
      )
  );

const createLocationOption = (
  optionsData,
  selectedLanguage,
  regionId = '',
  areaId = ''
) => {
  if (!optionsData) return null;

  if (optionsData.enabledLocationTypes.includes(TYPE_REGION)) {
    const selectedRegion = optionsData.locations.find(
      (reg) => reg.id === regionId
    );

    if (!selectedRegion) return null;

    if (optionsData.enabledLocationTypes.includes(TYPE_AREA)) {
      const selectedArea = selectedRegion.children.find(
        (area) => area.id === areaId
      );

      if (!selectedArea) return null;

      return getLocationMenuItems(
        selectedArea.children,
        selectedLanguage,
        optionsData.languages[0]
      );
    }

    return getLocationMenuItems(
      selectedRegion.children,
      selectedLanguage,
      optionsData.languages[0]
    );
  }

  if (optionsData.enabledLocationTypes.includes(TYPE_AREA)) {
    const selectedArea = optionsData.locations.find(
      (area) => area.id === areaId
    );

    if (!selectedArea) return null;

    return getLocationMenuItems(
      selectedArea.children,
      selectedLanguage,
      optionsData.languages[0]
    );
  }

  if (optionsData.enabledLocationTypes.includes(TYPE_LOCATION)) {
    return getLocationMenuItems(
      optionsData.locations,
      selectedLanguage,
      optionsData.languages[0]
    );
  }

  return null;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const SetupFormLocationSelectField = ({
  disabled,
  optionsData,
  selectedLanguage,
  name,
  emptyOption,
  regionId,
  areaId,
  required,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) =>
          required && !value.length ? '* Required' : undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} fullWidth>
          <SetupFormSelectFieldInput
            MenuProps={MenuProps}
            value={value}
            disabled={disabled}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            size="small"
          >
            {emptyOption && (
              <MenuItem value={emptyOption.value} id={emptyOption.id}>
                <Typography variant="inherit" noWrap>
                  {emptyOption.label}
                </Typography>
              </MenuItem>
            )}
            {createLocationOption(
              optionsData,
              selectedLanguage,
              regionId,
              areaId
            )}
          </SetupFormSelectFieldInput>
          <SelectHelperText>{error?.message}</SelectHelperText>
        </FormControl>
      )}
    />
  );
};

export default SetupFormLocationSelectField;
