import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TableCell } from '@mui/material';

import { UserTableRow } from './styled';

import { USER_ROLES } from '../../../../consts/consts';
import { MANAGER_ROLE_LIST, ATTRACTION_MANAGER_PRIMARY_SPACED } from '../consts';

const colors = {
  blue: '#32D7F0',
  orange: '#FFA94C',
  grey: '#A0A0A0',
  white: '#FFF',
  red: '#E84E33'
};

const statusColor = {
  Inactive: colors.blue,
  Active: colors.orange,
  Closed: colors.grey,
  Pending: colors.red
};

const UsersTableRow = (props) => {
  const { isExpanded, onClick, user, selectedLanguage } = props;

  const { t } = useTranslation();

  const userRoles = useSelector((state) =>
    state.userReducer.userSettings.find((item) => item.name.en === USER_ROLES)
  );

  const userRole = userRoles?.parameters.find((role) => role.id === user.role)
    ?.value[selectedLanguage];

  const userCreationDate = dayjs(user.created).format('DD.MM.YYYY');
  const userLastLoginDate = user.lastLogin
    ? dayjs(user.lastLogin).format('DD.MM.YYYY')
    : null;

  const isUserRolePrimary = ATTRACTION_MANAGER_PRIMARY_SPACED === userRole;

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  return (
    <UserTableRow 
      sx={{
        ...(isUserRolePrimary && isManagerRoleSelected && { bgcolor: statusColor.Active })
      }} 
      onClick={
        isUserRolePrimary && isManagerRoleSelected ? null : onClick
      }
    >
      <TableCell
        sx={{
          bgcolor: statusColor[user.status],
        }}
      >
        {t(`users.statuses.${user.status.toLowerCase()}`)}
      </TableCell>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      {isManagerRoleSelected ? (
        <>
          <TableCell>{user.phone}</TableCell>
          <TableCell>
            {user.isAllAttractions ? t('users.all_attractions') :
              user.userAttractions.map(attraction => attraction.name).join(', ')
            }
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>{user.company.name || 'No company matched'}</TableCell>  
        </>
      )}      
      <TableCell>{userRole}</TableCell>
      <TableCell>{userCreationDate}</TableCell>
      <TableCell>{userLastLoginDate || null}</TableCell>
    </UserTableRow>
  );
};

export default UsersTableRow;
