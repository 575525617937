import AttractionsPageContainer from '../../pages/attractions/AttractionsPageContainer';
import AttractionContainer from '../../pages/attractions/AttractionContainer';
import SetupContainer from '../../pages/attractions/setup/SetupContainer';
import ContentContainer from '../../pages/attractions/content/ContentContainer';
import PricesContainer from '../../pages/attractions/prices/PricesContainer';
import NotificationTabContainer from '../../pages/attractions/notificationsTab/NotificationTabContainer';
import ReviewsTabContainer from '../../pages/attractions/reviewsTab/ReviewsTabContainer';
import CompanyContainer from '../../pages/attractions/company/CompanyContainer';
import DealsContainer from '../../pages/attractions/deals/DealsContainer';
import NewDealAddForm from '../../pages/attractions/deals/newDealForm/NewDealAddForm';
import DealAddFormContainer from '../../pages/attractions/deals/newDealForm/DealAddFormContainer';
import AttractionVisitsListContainer from '../../pages/attractions/visits/AttractionVisitsListContainer';
import ReviewAddFormContainer from '../../pages/attractions/reviewsTab/forms/ReviewAddFormContainer';
import { PriceAddFormContainer } from '../../pages/attractions/prices/forms';
import AddNotificationForm from '../../pages/attractions/notificationsTab/forms/AddNotificationForm';
import AvailabilityList from '../../pages/attractions/availability/AvailabilityList';
import SeasonContainer from '../../pages/attractions/availability/SeasonContainer';
import AttractionReportPageContainer from '../../pages/attractions/reports/AttractionReportPageContainer';

export const attractionPageRoutes = [
  {
    path: '/attractions',
    element: <AttractionsPageContainer />,
  },
  {
    path: '/attractions',
    element: <AttractionContainer />,
    children: [
      {
        element: <SetupContainer />,
        path: 'new',
      },
    ],
  },
  {
    path: '/attractions/:id',
    element: <AttractionContainer />,
    children: [
      {
        element: <SetupContainer />,
        path: 'setup',
      },
      {
        element: <ContentContainer />,
        path: 'content',
      },
      {
        path: 'availability',
        children: [
          {
            index: true,
            element: <AvailabilityList />,
          },
          {
            element: <SeasonContainer />,
            path: 'new-season',
          },
          {
            element: <SeasonContainer />,
            path: 'edit/:seasonId',
          },
        ],
      },
      {
        path: 'prices',
        children: [
          {
            index: true,
            element: <PricesContainer />,
          },
          {
            element: <PriceAddFormContainer />,
            path: 'new-price',
          },
        ],
      },
      {
        element: <DealsContainer />,
        path: 'deals',
        children: [
          {
            element: <NewDealAddForm />,
            path: 'new',
          },
        ],
      },
      {
        element: <DealAddFormContainer />,
        path: 'new-deal',
      },
      {
        path: 'notifications',
        children: [
          {
            index: true,
            element: <NotificationTabContainer />,
          },
          {
            path: 'new-notification',
            element: <AddNotificationForm />,
          },
        ],
      },
      {
        path: 'reviews',
        children: [
          {
            index: true,
            element: <ReviewsTabContainer />,
          },
          {
            path: 'new-review',
            element: <ReviewAddFormContainer />,
          },
        ],
      },
      {
        element: <AttractionVisitsListContainer />,
        path: 'visits',
      },
      {
        element: <AttractionReportPageContainer />,
        path: 'reports',
      },
      {
        element: <CompanyContainer />,
        path: 'company',
      },
    ],
  },
];
