import { Controller, useFormContext } from 'react-hook-form';

import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { validateTextFieldValue } from '../helpers/validateTextFieldValue';

const ICON_DEFAULT_COLOR = '#cfcaca';

const SearchField = ({
  name,
  handleChange,
  placeholder,
  newValue,
  className,
}) => {
  const { control, resetField } = useFormContext();

  const handleClearSearchField = () => resetField(name);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TextField
          className={className}
          variant="outlined"
          size="small"
          placeholder={placeholder}
          value={newValue || value}
          onChange={(e) =>
            handleChange
              ? handleChange(e)
              : onChange(validateTextFieldValue(e.target.value))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value ? (
                  <IconButton
                    onClick={handleClearSearchField}
                    sx={{ padding: 0, color: ICON_DEFAULT_COLOR }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <SearchIcon sx={{ color: ICON_DEFAULT_COLOR }} />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchField;
