import { useTranslation } from 'react-i18next';

import { TableCell, TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { tableHeadCells } from '../constants';

import { StyledTableHeadRow, StyledVisitorsTableHead } from '../styled';

const VisitorsTableHeader = (props) => {
  const { t } = useTranslation();

  const { order, orderBy, onSort } = props;

  const createSortHandler = (property) => (event) => {
    onSort(event, property);
  };

  return (
    <StyledVisitorsTableHead>
      <StyledTableHeadRow>
        {tableHeadCells.map((item, idx) => {
          return item.sortable ? (
            <TableCell
              key={idx}
              sortDirection={orderBy === item.id ? order : false}
            >
              {
                <TableSortLabel
                  active={orderBy === item.id}
                  direction={orderBy === item.id ? order : 'asc'}
                  onClick={createSortHandler(item.id)}
                >
                  {t(item.label)}
                  {orderBy === item.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              }
            </TableCell>
          ) : (
            <TableCell key={idx}>{t(item.label)}</TableCell>
          );
        })}
      </StyledTableHeadRow>
    </StyledVisitorsTableHead>
  );
};

export default VisitorsTableHeader;
