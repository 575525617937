import { connect } from 'react-redux';
import { compose } from 'redux';
import Home from './Home';

import { setTitle } from '../../redux/appReducer';

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    authReducer: state.authReducer
  }
};

export default compose(
  connect(mapStateToProps,
  {setTitle}),
)(Home);