import {useState, useMemo, useCallback, useEffect} from 'react';
import {
  Field,
  reduxForm,
  untouch,
  change,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  UserFormHeaderLabel,
  UserFormContainer,
  UserFormFieldWrapper,
  UserFormFieldTitle,
  UserFormSelectField,
  UserFormTextField,
  UserFormControlPanel,
  UserFormAutocomplete,
  UserFormHeader,
  UserFormCheckbox,
  UserFormSubmitButton,
  UserFormButton,
  LoaderWrapper,
} from './styled';

import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';
import PasswordChangeDialog from '../components/PasswordChangeDialog';

import {
  isNotEmpty,
  maxLengthTextField,
  ListIsNotEmpty,
  maxLengthEmail,
} from './validation';
import {
  ADMIN_ROLE_LIST,
  MANAGER_ROLE_LIST,
  DEFAULT_USER_OBJECT,
  USER_FORM_NAME,
  MANAGER_ROLE_LIST_APP,
  ATTRACTION_MANAGER_PRIMARY,
  userRoles,
  REGIONAL_MANAGER_ROLE
} from '../consts';

import {
  createOptions,
  getSelectedCompanyAttractions,
  hideControlPanelForSystemManager,
} from './helpers';
import { excludeMorePrioritedRoles, ROLE_PRIORITY_LIST } from '../UserRoles';
import {
  getRegionsIncludedInDestination,
  getAreasIncludedInDestination
} from '../helpers';
import { getAttractionListOfCompaniesThunk } from '../../../../redux/usersReducers/company/companyActions';
import { getSmallAttractionsDataThunk } from '../../../../redux/attractionsReducers/attractions/attractionsActions';
import { getSalesPointShortDataListThunk } from '../../../../redux/salesPointsReducer/salesPointsActions';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '16px',
  },
});

const UserForm = (props) => {
  const {
    onEdit,
    onCancel,
    userData,
    isInAddMode,
    isInEditMode,
    showSaveAnimation,
    isTranslations,
    isAllAttractionsAndPoints,
    isAttractionEmpty,
    isCategoryEmpty,
    isRegionEmpty,
    role,
    companyFieldValue,
    regionFieldValue,
    areaFieldValue,
    attractionFieldValue,
    dispatch,
    setIsInEditMode,
    attractionsAndSalesPointsLinkedToCompanies,
    currentUserRole,
    roles,
    statuses,
    selectedLanguage,
    categoryList,
    regionList,
    companyList,
    defaultUserCompany,
    onChangeUserPassword,
    onCreateVisitor,
    onDeleteDialogConfirmation,
    showDeleteDialog,
    setShowDeleteDialog,
    isSalesPointsEmpty,
    selectedDestinationId,
    currentUser,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const [showPassChangeAlert, setShowPassChangeAlert] = useState(false);
  const [showVisitorCreateAlert, setVisitorCreateAlert] = useState(false);
  const [isPasswordChangeDialogOpen, setIsPasswordChangeDialogOpen] = useState(false);
  const [fieldsOptionsUpdating, setFieldsOptionsUpdating] = useState(false);

  const isAdminRoleSelected = ADMIN_ROLE_LIST.includes(role);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(role);
  const isRegionalManagerRoleSelecterd = REGIONAL_MANAGER_ROLE === role;
  const email = useCallback((value) => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value)
      ? t('users.invalid_email')
      : undefined,[selectedLanguage])

  const required = useCallback(value => (value ? undefined : t('required')), [selectedLanguage]);


  const regionOptionList = useMemo(
    () =>
      getRegionsIncludedInDestination(
        regionList,
        companyFieldValue?.idDestination,
        defaultUserCompany?.idDestination,
        selectedDestinationId
      ),
    [
      companyFieldValue?.idDestination,
      regionList,
      defaultUserCompany?.idDestination,
      selectedDestinationId,
    ]
  );

  const areaOptionList = useMemo(
    () => 
       getAreasIncludedInDestination(
        regionOptionList,
        regionFieldValue || [],
        companyFieldValue?.idDestination,
      ),
    [ 
      regionOptionList,
      companyFieldValue?.idDestination,
      defaultUserCompany?.idDestination,
      regionFieldValue,
    ]
  )

  const attractionOptionList = useMemo(
    () =>
      getSelectedCompanyAttractions(
        companyFieldValue,
        attractionsAndSalesPointsLinkedToCompanies,
        role,
        areaFieldValue,
        regionFieldValue
      ),
    [companyFieldValue, attractionsAndSalesPointsLinkedToCompanies, areaFieldValue, regionFieldValue]
  );

  const handlePassChangeButtonClick = () => {
    setShowPassChangeAlert(true);
  };

  const handleVisitorCreationButtonClick = () => {
    setVisitorCreateAlert(true);
  };

  const handleItemDeleteButtonClick = () => {
    setShowDeleteDialog(true);
  };

  const handleAlertCancel = () => {
    setShowDeleteDialog(false);
    setShowPassChangeAlert(false);
  };

  const handlePasswordChangeDialogSubmit = (newPassword) => {
    const newUserData = {
      userId: userData.id,
      password: newPassword,
    };

    onChangeUserPassword(newUserData);
    setIsPasswordChangeDialogOpen(false);
    setShowPassChangeAlert(false);
    setIsInEditMode(false);
  };

  const handleVisitorCreationDialogSubmit = () => {
    onCreateVisitor(userData.id);
    setVisitorCreateAlert(false);
    setIsInEditMode(false);
  };

  const handlePasswordChangeDialogOpen = () => {
    setIsPasswordChangeDialogOpen(true);
  };

  const handlePasswordChangeDialogClose = () => {
    setIsPasswordChangeDialogOpen(false);
    setShowPassChangeAlert(false);
  };

  const handleVisitorCreationDialogClose = () => {
    setVisitorCreateAlert(false);
  };

  const clearReduxField = (formName, fieldName, newValue) => {
    dispatch(change(formName, fieldName, newValue));
    dispatch(untouch(formName, fieldName));
  };

  const setFormFieldValue = (fieldName, newValue) => {
    dispatch(change(USER_FORM_NAME, fieldName, newValue));
  };

  const resetAttractionsField = () => {
    clearReduxField(USER_FORM_NAME, 'attractions', []);
  };

  const resetSalesPointsField = () => {
    clearReduxField(USER_FORM_NAME, 'salesPoints', []);
  };
  const resetRegionsField = () => {
    clearReduxField(USER_FORM_NAME, 'regions', []);
  };

  const resetAreasField = () => {
    clearReduxField(USER_FORM_NAME, 'areas', [])
  };

  const resetCategoriesField = () => {
    clearReduxField(USER_FORM_NAME, 'categories', []);
  };

  const handleCompanyChange = (_, newCompanyFieldValue) => {
    resetAttractionsField();
    resetSalesPointsField();
    resetRegionsField();
    resetAreasField();
    resetCategoriesField();

    setFieldsOptionsUpdating(true);

    if (
      !newCompanyFieldValue ||
      newCompanyFieldValue.id === defaultUserCompany.id
    ) {
      Promise.allSettled([
        dispatch(getSmallAttractionsDataThunk(selectedDestinationId)),
        dispatch(getSalesPointShortDataListThunk(selectedDestinationId)),
      ]).then(() => {
        setFieldsOptionsUpdating(false);
      });
      return;
    }

    dispatch(
      getAttractionListOfCompaniesThunk(newCompanyFieldValue.idDestination)
    ).then(() => {
      setFieldsOptionsUpdating(false);
    });
  };

  const handleRoleChange = (newValue, prevValue) => {
    setFormFieldValue('isAllAttractionsAndPoints', false);
    if(newValue === 'Administrator') {
      setFormFieldValue('isTranslations', true);
    } else {
      setFormFieldValue('isTranslations', false);
    }

    if(MANAGER_ROLE_LIST.includes(newValue)) {
      setFormFieldValue('salesPoints', []);
      setFormFieldValue('categories', []);
      setFormFieldValue('regions', []);
      setFormFieldValue('company', null);
    }

    if (
      !ADMIN_ROLE_LIST.includes(prevValue) &&
      ADMIN_ROLE_LIST.includes(newValue)
    ) {
      setFormFieldValue('company', defaultUserCompany);
      setFormFieldValue('attractions', []);
      setFormFieldValue('salesPoints', []);
      setFormFieldValue('categories', []);
      setFormFieldValue('regions', []);
    } else if (
      ADMIN_ROLE_LIST.includes(prevValue) &&
      !ADMIN_ROLE_LIST.includes(newValue)
    ) {
      setFormFieldValue('company', null);
    }
    if(REGIONAL_MANAGER_ROLE === newValue) {
      setFormFieldValue('company', defaultUserCompany);
      setFormFieldValue('regions', []);
      setFormFieldValue('attractions', []);
      setFormFieldValue('areas', []);
    } 
  };

  const handleRegionChange = () => {
    if(areaFieldValue) {
      const areaValue = areaFieldValue.filter((area) =>
        areaOptionList.some((option) => option.id === area.idArea)
      );

      setFormFieldValue('areas', areaValue);
    }
  };

  useEffect(() => {
    handleRegionChange();
  }, [regionFieldValue, areaOptionList]);

  const handleAreaChange = () => {
    if(attractionFieldValue) {
      const attractionValue = attractionFieldValue.filter((attraction) => 
        attractionOptionList.some((option) => option.id === attraction.idAttraction)
      )
  
      setFormFieldValue('attractions', attractionValue);
    }
  }

  useEffect(() => {
     handleAreaChange();
  },[areaFieldValue, regionFieldValue])

  const handleChangeIsAllAttractionsAndPointsField = () => {
    setFormFieldValue('attractions', []);
    setFormFieldValue('salesPoints', []);
  };

  const disableAttractionsField =
    isAdminRoleSelected ||
    isAllAttractionsAndPoints ||
    !isCategoryEmpty;

  const disableRegionField =
    isAdminRoleSelected ||
    isManagerRoleSelected ||
    !isSalesPointsEmpty;

  const disableAreaField = 
    isAdminRoleSelected ||
    isManagerRoleSelected ||
    !isSalesPointsEmpty;

  const hideControlPanel = hideControlPanelForSystemManager(
    userData,
    currentUserRole,
    currentUser
  );

  const rolesOptionList = hideControlPanel
    ? roles?.parameters
    : excludeMorePrioritedRoles(
        roles?.parameters,
        currentUserRole,
        ROLE_PRIORITY_LIST
      );

  const isManagerRole = MANAGER_ROLE_LIST_APP.includes(currentUserRole);

  return (
    <UserFormContainer component="form" onSubmit={props.handleSubmit}>
      <UserFormHeader className={isManagerRole? classes.wrapper : null}>
        {isInAddMode && (
          <UserFormHeaderLabel>
            <Typography fontWeight="bold" textTransform="uppercase">
              {t('users.new_user_label')}
            </Typography>
          </UserFormHeaderLabel>
        )}
        {!hideControlPanel && (
          <UserFormControlPanel>
            <UserFormButton
              onClick={onCancel}
              label={t('users.buttons.cancel')}
              disabled={!isInEditMode}
            />
            {!isInAddMode && (
              <>
                <UserFormButton
                  onClick={onEdit}
                  disabled={isInEditMode}
                  label={t('users.buttons.edit')}
                />
                <UserFormButton
                  onClick={handlePassChangeButtonClick}
                  label={t('users.buttons.change_password')}
                />
                {(!userData.isLinkedVisitorExist) && (
                    <UserFormButton
                        onClick={handleVisitorCreationButtonClick}
                        label={t('users.buttons.create_visitor')}
                    />
                )}
                {!(role === ATTRACTION_MANAGER_PRIMARY && currentUserRole === userRoles.AttractionManagerPrimary) && (
                  <UserFormButton
                    onClick={handleItemDeleteButtonClick}
                    label={t('users.buttons.delete_account')}
                  />
                )}
              </>
            )}
            <UserFormSubmitButton
              isLoading={showSaveAnimation}
              disabled={!isInEditMode}
              label={t('users.buttons.save')}
            />
          </UserFormControlPanel>
        )}
      </UserFormHeader>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.visitorAccount')}:</UserFormFieldTitle>
        {userData?.isLinkedVisitorExist ? t('users.visitor_already_exist') : t('users.visitor_does_not_exist')}
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.status')}:</UserFormFieldTitle>
        <Field
          name="status"
          component={UserFormSelectField}
          validate={isInAddMode ? [required] : [required, isNotEmpty]}
          disabled={!isInEditMode}
        >
          {createOptions(statuses.parameters, selectedLanguage)}
        </Field>
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.name')}:</UserFormFieldTitle>
        <Field
          component={UserFormTextField}
          name="name"
          validate={[maxLengthTextField, required]}
          disabled={!isInEditMode}
        />
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.position')}:</UserFormFieldTitle>
        <Field
          component={UserFormTextField}
          name="position"
          validate={[maxLengthTextField]}
          disabled={!isInEditMode}
        />
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.email')}:</UserFormFieldTitle>
        <Field
          component={UserFormTextField}
          name="email"
          validate={[required, email, maxLengthEmail]}
          disabled={!isInEditMode}
        />
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.phone')}:</UserFormFieldTitle>
        <Field
          component={UserFormTextField}
          name="phone"
          validate={[maxLengthTextField]}
          disabled={!isInEditMode}
        />
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.message')}:</UserFormFieldTitle>
        <Field
          component={UserFormTextField}
          name="message"
          disabled
          multiline
          rows={3}
        />
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.role')}:</UserFormFieldTitle>
        <Field
          name="role"
          component={UserFormSelectField}
          validate={[required, isNotEmpty]}
          disabled={!isInEditMode || isManagerRole}
          onChange={(_, newValue, prevValue) => {
            handleRoleChange(newValue, prevValue);
          }}
        >
          <option value={''}>{t('users.role')}</option>
          {createOptions(rolesOptionList, selectedLanguage)}
        </Field>
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
          <Field
            disabled={!isInEditMode || currentUserRole !== 'admin' || role === 'Administrator'}
            component={UserFormCheckbox}
            name="isTranslations"
            label={t('users.can_translate')}
          />
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.company')}:</UserFormFieldTitle>
        <Field
          name="company"
          component={UserFormAutocomplete}
          options={companyList}
          getOptionLabel={(option) => {
            if (!option) return '';

            return option.name;
          }}
          disabled={!isInEditMode || isAdminRoleSelected || isManagerRole || isRegionalManagerRoleSelecterd}
          validate={isAdminRoleSelected ? [] : [required]}
          onChange={handleCompanyChange}
          isOptionEqualToValue={(option, value) => {
            return value.id === option.id;
          }}
          disablePortal
          getOptionDisabled={(option) => option.id === defaultUserCompany.id}
        />
      </UserFormFieldWrapper>
      <UserFormFieldWrapper>
        <Field
          disabled={!isInEditMode || isAdminRoleSelected}
          component={UserFormCheckbox}
          name="isAllAttractionsAndPoints"
          label={t('users.all_attractions_and_points')}
          onChange={handleChangeIsAllAttractionsAndPointsField}
        />
      </UserFormFieldWrapper>
      {fieldsOptionsUpdating && (
        <LoaderWrapper>
          <CircularProgress></CircularProgress>
        </LoaderWrapper>
      )}
      {!fieldsOptionsUpdating && (
        <>
        {!isManagerRoleSelected ? 
          <UserFormFieldWrapper>
            <UserFormFieldTitle>{t('users.link_region')}:</UserFormFieldTitle>
            <Field
              name="regions"
              component={UserFormAutocomplete}
              options={regionOptionList}
              label={t('users.regions')}
              multiple
              disablePortal
              disabled={!isInEditMode || disableRegionField}
              validate={disableRegionField ? [] : [required, ListIsNotEmpty]}
              isOptionEqualToValue={(option, value) => {
                return value.idRegion === option.id;
              }}
              getOptionLabelForOptionList={(option) => {
                if (!option) return '';
                return option.name[selectedLanguage] || option.name.en;
              }}
              getOptionLabel={(option) => {
                const optionLabel = regionOptionList.find(
                  (region) => region.id === option.idRegion
                );
                if (!optionLabel) return '';

                return (
                  optionLabel.name[selectedLanguage] ||
                  optionLabel.name.en ||
                  ''
                );
              }}
              normalize={(regionList) => {
                return regionList.map((region) => {
                  if (region.idRegion) return region;
                  return { idRegion: region.id };
                });
              }}
            />
          </UserFormFieldWrapper> : null }
          {!isManagerRoleSelected ? <UserFormFieldWrapper>
            <UserFormFieldTitle>{t('users.link_area')}:</UserFormFieldTitle>
            <Field
              name="areas"
              component={UserFormAutocomplete}
              options={areaOptionList}
              label={t('users.areas')}
              multiple
              disablePortal
              disabled={disableAreaField || !isInEditMode}
              validate={[]}
              isOptionEqualToValue={(option, value) => {
                return value.idArea === option.id;
              }}
              getOptionLabelForOptionList={(option) => {
                if (!option) return '';
                return option.name[selectedLanguage] || option.name.en || option.name.cs;
              }}
              getOptionLabel={(option) => {
                const optionLabel = areaOptionList.find(
                  (area) => area.id === option.idArea
                );
                if (!optionLabel) return '';  

                return (
                  optionLabel.name[selectedLanguage] ||
                  optionLabel.name.en ||
                  optionLabel.name.cs ||
                  ''
                );
              }}
              normalize={(areaList) => {
                return areaList.map((area) => {
                  if (area.idArea) {
                    return area;
                  }
                  return { idArea: area.id };
                });
              }}
            />
          </UserFormFieldWrapper> : null
          }
          <UserFormFieldWrapper>
            <UserFormFieldTitle>
              {t('users.link_attraction')}:
            </UserFormFieldTitle>
            <Field
              name="attractions"
              component={UserFormAutocomplete}
              options={attractionOptionList}
              multiple
              disablePortal
              label={t('users.attractions')}
              validate={
                disableAttractionsField ? [] : [required, ListIsNotEmpty]
              }
              disabled={!isInEditMode || disableAttractionsField}
              getOptionLabel={(option) => {
                const optionLabel = attractionOptionList.find(
                  (attr) => attr.id === option.idAttraction
                );

                return optionLabel?.name || '';
              }}
              getOptionLabelForOptionList={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) =>
                value.idAttraction === option.id
              }
              normalize={(attractionList) => {
                return attractionList.map((attraction) => {
                  if (attraction.idAttraction) return attraction;
                  return { idAttraction: attraction.id };
                });
              }}
            />
          </UserFormFieldWrapper>
        </>
      )}

      <ConfirmationDialog
        showAlert={showDeleteDialog}
        handleClose={handleAlertCancel}
        handleCloseSuccess={onDeleteDialogConfirmation}
        text={t('users.delete_user_account')}
      />
      <ConfirmationDialog
        showAlert={showPassChangeAlert}
        handleClose={handleAlertCancel}
        handleCloseSuccess={handlePasswordChangeDialogOpen}
        text={t('users.change_account_password')}
      />
      <ConfirmationDialog
          showAlert={showVisitorCreateAlert}
          handleClose={handleVisitorCreationDialogClose}
          handleCloseSuccess={handleVisitorCreationDialogSubmit}
          text={t('users.create_visitor_alert')}
      />
      <PasswordChangeDialog
        onClose={handlePasswordChangeDialogClose}
        isOpen={isPasswordChangeDialogOpen}
        onDialogSubmit={handlePasswordChangeDialogSubmit}
      />
    </UserFormContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('UserForm');
  const isAllAttractionsAndPoints = selector(
    state,
    'isAllAttractionsAndPoints'
  );
  const isTranslations = selector(state, 'isTranslations')
  const isAttractionEmpty = !selector(state, 'attractions')?.length;
  const isCategoryEmpty = !selector(state, 'categories')?.length;
  const isRegionEmpty = !selector(state, 'regions')?.length;
  const role = selector(state, 'role');
  const companyFieldValue = selector(state, 'company');
  const regionFieldValue = selector(state, 'regions');
  const areaFieldValue = selector(state, 'areas');
  const attractionFieldValue = selector(state, 'attractions');
  const isSalesPointsEmpty = !selector(state, 'salesPoints')?.length;

  if (ownProps.isInAddMode) {
    const newRole = MANAGER_ROLE_LIST_APP.includes(ownProps.currentUserRole) ? 'AttractionManager' : '';
    const newCompany = MANAGER_ROLE_LIST_APP.includes(ownProps.currentUserRole) ? ownProps.companyList[0] : null;

    return {
      isAllAttractionsAndPoints,
      isAttractionEmpty,
      isCategoryEmpty,
      isRegionEmpty,
      role,
      companyFieldValue,
      regionFieldValue,
      areaFieldValue,
      attractionFieldValue,
      isSalesPointsEmpty,
      initialValues: { 
        ...DEFAULT_USER_OBJECT,
        role: newRole,
        company: newCompany,
      },
    };
  }
  if (ownProps.userData) {
    const { userData, companyFieldInitialValue } = ownProps;

    return {
      isAllAttractionsAndPoints,
      isAttractionEmpty,
      isCategoryEmpty,
      isRegionEmpty,
      role,
      companyFieldValue,
      regionFieldValue,
      areaFieldValue,
      attractionFieldValue,
      isSalesPointsEmpty,
      initialValues: {
        id: userData.id,
        name: userData.name,
        position: userData.position,
        email: userData.email,
        isTranslations: userData.isTranslations,
        phone: userData.phone,
        message: userData.message,
        company: companyFieldInitialValue,
        role: userData.role,
        attractions: userData.attractions,
        salesPoints: userData.salesPoints,
        categories: userData.categories,
        regions: userData.regions,
        areas: userData.areas,
        isAllAttractionsAndPoints:
          userData.isAllAttractions && userData.isAllSalesPoints,
        status: userData.status,
        language: userData.language,
      },
    };
  }
};

const reduxUserForm = reduxForm({
  form: USER_FORM_NAME,
  enableReinitialize: true,
})(UserForm);

export default connect(mapStateToProps)(reduxUserForm);
