import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';

import EditFactForm from './EditFactForm';

import { formatLanguageFieldsData } from '../../utils';

import useAPIError from '../../../../../apIErrorProvider/useAPIError';
import {
  updateFactThunk,
  getFactsListThunk,
  deleteFactThunk,
} from '../../../../../redux/publishingReducers/factsReducer/factsActions';

const EditFactFormContainer = ({
  getFactsListThunk,
  updateFactThunk,
  currentDestinationId,
  languageList,
  item,
  resetActiveRow,
  deleteFactThunk,
}) => {
  const { t } = useTranslation();
  const { addError } = useAPIError();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const languageFormFieldArrays = languageList.reduce((acc, lang) => {
    acc[lang] = [
      {
        title: item.title[lang] || '',
        text: item.text[lang] || '',
      },
    ];

    return acc;
  }, {});

  const defaultValues = {
    ...languageFormFieldArrays,
  };

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { reset } = formMethods;

  const handleSubmit = (formData) => {
    setShowSaveAnimation(true);

    const submitData = {
      id: item.id,
      ...formatLanguageFieldsData(formData),
    };

    updateFactThunk(submitData)
      .then(() => {
        addError(`${t('facts.notifications.update_success')}`, 'Success');
        setShowSaveAnimation(false);
        resetActiveRow();
        getFactsListThunk(currentDestinationId);
      })
      .catch((error) => {
        addError(`${t('facts.notifications.update_error')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleDeleteFact = (factId) => {
    deleteFactThunk(factId)
      .then(() => {
        addError(`${t('facts.notifications.delete_success')}`, 'Success');
        resetActiveRow();
        getFactsListThunk(currentDestinationId);
      })
      .catch((e) => {
        addError(`${t('facts.notifications.delete_error')}`, 'Error', e);
      });
  };

  const handleAlertConfirm = () => {
    setShowAlert(false);
    setIsInEditMode(false);
    setShowSaveAnimation(false);
    handleDeleteFact(item.id);
  };

  const handleEditMode = () => setIsInEditMode(!isInEditMode);

  const onCancel = () => {
    setIsInEditMode(false);
    reset(defaultValues, { keepDefaultValues: true });
  };

  return (
    <FormProvider {...formMethods}>
      <EditFactForm
        languageList={languageList}
        isLoading={showSaveAnimation}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        isInEditMode={isInEditMode}
        handleEditMode={handleEditMode}
        handleDeleteFact={handleAlertConfirm}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    currentDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, {
  updateFactThunk,
  getFactsListThunk,
  deleteFactThunk,
})(EditFactFormContainer);
