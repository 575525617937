import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TableCell } from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

import { styled } from '@mui/material/styles';

import { tableCellsDataStyle } from './TableCellsStyle';

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  })
);

const NotificationAccordionTitle = ({
  notification,
  attractions,
  expanded,
  startDate,
  endDate,
  languages,
}) => {
  const { t } = useTranslation();

  const colors = {
    blue: '#32D7F0',
    orange: '#FFA94C',
    grey: '#A0A0A0',
    white: '#FFF',
  };

  const statusColorPair = {
    Inactive: colors.blue,
    Active: colors.orange,
    Closed: colors.grey,
  };

  const statusCellStyle = {
    ...tableCellsDataStyle.dataStyle,
    backgroundColor: statusColorPair[notification.status],
  };

  return (
    <AccordionSummary
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      style={{ display: 'flex', flexDirection: 'row', padding: 0 }}
      sx={{
        bgcolor: expanded ? '#FFA94C' : '#FFF',
        '&:hover': {
          bgcolor: '#FFA94C',
        },
      }}
    >
      <TableCell align="left" style={statusCellStyle}>
        {t(`notifications.statuses.${notification.status.toLowerCase()}`)}
      </TableCell>
      <TableCell align="left" style={tableCellsDataStyle.mainDataStyle}>
        {attractions}
      </TableCell>
      <TableCell align="left" style={tableCellsDataStyle.mainDataStyle}>
        {notification.title[languages[0]]}
      </TableCell>
      <TableCell align="left" style={tableCellsDataStyle.dataStyle}>
        {startDate}
      </TableCell>
      <TableCell align="left" style={tableCellsDataStyle.dataStyle}>
        {endDate}
      </TableCell>
      <TableCell align="center" style={tableCellsDataStyle.dataStyle}>
        <ExpandMoreIcon />
      </TableCell>
    </AccordionSummary>
  );
};

export default NotificationAccordionTitle;
