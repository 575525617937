import { ADMIN_ROLE_LIST } from './consts';

export const ROLE_PRIORITY_LIST = [
  {
    priority: 1,
    appRole: 'admin',
    optionListRole: 'Administrator',
    canCreateSameRole: true,
  },
  {
    priority: 2,
    appRole: 'systemmanager',
    optionListRole: 'SystemManager',
    canCreateSameRole: false,
  },
  {
    priority: 3,
    appRole: 'attractionprimary',
    optionListRole: 'AttractionManagerPrimary',
    canCreateSameRole: true,
  },
  {
    priority: 3,
    appRole: 'attraction',
    optionListRole: 'AttractionManager',
    canCreateSameRole: true,
  },
];

const getRolePriority = (role, rolePriorityList) => {
  const roleInfo = rolePriorityList.find(
    (currentRole) => currentRole.optionListRole === role
  );

  if (roleInfo) return roleInfo.priority;

  return -1;
};

const checkIsRoleValid = (userRole, currentRolePriority) => {
  if (currentRolePriority === -1) return true;

  if (userRole.priority < currentRolePriority) return true;

  if (userRole.canCreateSameRole && userRole.priority === currentRolePriority)
    return true;

  return false;
};

export const excludeMorePrioritedRoles = (
  roleOptionList,
  userAppRole,
  rolePriorityList
) => {
  const userRoleInfo = rolePriorityList.find(
    (item) => item.appRole === userAppRole
  );

  const filteredRoleList = roleOptionList.reduce((acc, currentRole) => {
    const currentRolePriority = getRolePriority(
      currentRole.id,
      rolePriorityList
    );

    if (checkIsRoleValid(userRoleInfo, currentRolePriority))
      acc.push(currentRole);

    return acc;
  }, []);

  return filteredRoleList;
};

export const isAdminRole = (userRole) => {
  return ADMIN_ROLE_LIST.includes(userRole);
};
