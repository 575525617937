import { useState } from 'react';

import { Menu, MenuItem } from '@mui/material';

import { ToolbarButton } from './styled';

const FontDropDown = (props) => {
  const { options, value, onChange } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    onChange(value);
    setAnchorEl(null);
  };

  return (
    <div>
      <ToolbarButton
        id="basic-button"
        aria-controls={!!anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={!!anchorEl ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        {value || 'Font'}
      </ToolbarButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        autoFocus={false}
        disableAutoFocusItem={true}
        disableAutoFocus
      >
        {options.map((item, idx) => (
          <MenuItem onClick={() => handleMenuItemClick(item)} key={idx}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FontDropDown;
