export const shouldSetDefaultFontFamily = (
  currentSelection,
  selectionFontFamily
) => {
  return (
    currentSelection.isCollapsed() &&
    currentSelection.getHasFocus() &&
    !selectionFontFamily
  );
};
