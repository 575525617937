import clsx from 'clsx';
import { connect } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import EditFactFormContainer from '../forms/editFactForm/EditFactFormContainer';
import { FactsTableRow } from '../styled';

const useStyles = makeStyles({
  activeRow: {
    backgroundColor: '#FFA94C',
  },
});

const FactsRow = (props) => {
  const { item, onClick, isExpanded, languageList, resetActiveRow } = props;

  const classes = useStyles();

  return (
    <>
      <FactsTableRow
        onClick={onClick}
        className={clsx({ [classes.activeRow]: isExpanded })}
      >
        <TableCell>{item.title[languageList[0]] || item.title.en}</TableCell>
      </FactsTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell>
            <EditFactFormContainer
              languageList={languageList}
              item={item}
              resetActiveRow={resetActiveRow}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps)(FactsRow);
