import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ProductEditFormNumberField from './productEditFormFields/ProductEditFormNumberField';
import ProductEditFormSelectField from './productEditFormFields/ProductEditFormSelectField';
import ProductEditFormTextField from './productEditFormFields/ProductEditFormTextField';

import { priceNumberValidator, validityNumberValidator } from '../helpers';
import {
  ProductFormButton,
  ProductFormControlPanel,
  ProductFormFieldTitle,
  ProductFormFieldWrapper,
  ProductFormSubmitButton,
} from '../styled';
import ProductFormLanguageTabs from './productLanguageTabs/ProductFormLanguageTabs';

import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';

const ProductEditForm = ({
  productData,
  isInEditMode,
  handleChangeEditMode,
  handleCancelChanges,
  handleCalcVatCost,
  handleCalcPrice,
  currency,
  vatOptions,
  isLoading,
  onSubmit,
  handleChangeLanguageTab,
  currentLanguageTabId,
  languages,
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();

  const { id } = productData;

  return (
    <form>
      <ProductFormControlPanel>
        {isInEditMode && (
          <>
            <ProductFormButton
              label={t('visitors.cancelFormButton')}
              onClick={handleCancelChanges}
            />
            <ProductFormSubmitButton
              label={t('visitors.saveFormButton')}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            />
          </>
        )}

        {!isInEditMode && (
          <ProductFormButton
            label={t('visitors.editFormButton')}
            onClick={handleChangeEditMode}
          />
        )}
      </ProductFormControlPanel>

      <ProductFormFieldWrapper>
        <ProductFormFieldTitle>
          {t('products.formFieldLabels.id')}
        </ProductFormFieldTitle>
        <Typography>{id}</Typography>
      </ProductFormFieldWrapper>

      <ProductFormFieldWrapper>
        <ProductFormFieldTitle>
          {t('products.formFieldLabels.type')}
        </ProductFormFieldTitle>
        <ProductEditFormTextField
          name="type"
          disabled
          maxLength={DEFAULT_TEXT_FIELD_MAX_LENGTH}
          required
        />
      </ProductFormFieldWrapper>

      <Box display="flex">
        <ProductFormLanguageTabs
          disabled={!isInEditMode}
          languages={languages}
          currentTabId={currentLanguageTabId}
          handleChangeLanguageTab={handleChangeLanguageTab}
        />
      </Box>

      <ProductFormFieldWrapper>
        <ProductFormFieldTitle>
          {t('products.formFieldLabels.validity')}
        </ProductFormFieldTitle>
        <ProductEditFormNumberField
          name="validity"
          disabled={!isInEditMode}
          numberValidator={validityNumberValidator}
        />
      </ProductFormFieldWrapper>

      <ProductFormFieldWrapper>
        <ProductFormFieldTitle>
          {t('products.formFieldLabels.netPrice')}
        </ProductFormFieldTitle>
        <ProductEditFormNumberField
          name="netPrice"
          disabled={!isInEditMode}
          hasCurrency
          handleChange={handleCalcVatCost}
          currency={currency}
          numberValidator={priceNumberValidator}
        />
      </ProductFormFieldWrapper>

      <ProductFormFieldWrapper>
        <ProductFormFieldTitle>
          {t('products.formFieldLabels.vatPercentage')}
        </ProductFormFieldTitle>
        <ProductEditFormSelectField
          name="vatPercentage"
          options={vatOptions}
          disabled={!isInEditMode}
          handleChange={handleCalcPrice}
        />
      </ProductFormFieldWrapper>

      <ProductFormFieldWrapper>
        <ProductFormFieldTitle>
          {t('products.formFieldLabels.vatCost')}
        </ProductFormFieldTitle>
        <ProductEditFormTextField
          name="vatCost"
          disabled
          hasCurrency
          currency={currency}
        />
      </ProductFormFieldWrapper>

      <ProductFormFieldWrapper>
        <ProductFormFieldTitle>
          {t('products.formFieldLabels.grossPrice')}
        </ProductFormFieldTitle>
        <ProductEditFormTextField
          name="grossPrice"
          disabled
          hasCurrency
          currency={currency}
        />
      </ProductFormFieldWrapper>
    </form>
  );
};

export default ProductEditForm;
