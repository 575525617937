import { connect } from 'react-redux';
import { compose } from 'redux';

import CategoriesFaq from './CategoriesFaq';

import {
  getCategoryFaqListThunk,
  getSearchCategoryThunk,
  addCategoryFaqThunk,
  editCategoryFaqThunk,
  deleteCategoryFaqThunk,
  setCurrentCategory,
} from '../../../redux/faqReducers/categoryFaqReducer';
import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    categoryFaqReducer: state.categoryFaqReducer,
    settingsReducer: state.settingsReducer,
    predefinedReducer: state.predefinedReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    getCategoryFaqListThunk,
    getSearchCategoryThunk,
    addCategoryFaqThunk,
    editCategoryFaqThunk,
    deleteCategoryFaqThunk,
    getPredefinedSettingsThunk,
    setCurrentCategory,
  })
)(CategoriesFaq);
