import { styled } from '@mui/system';
import { Box, ImageList, ImageListItem } from '@mui/material';

export const StyledImageList = styled(ImageList)`
  width: 100%;
  height: 100%;
  margin: 0;
`;

export const ImageDropPreviewContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const StyledImageListItem = styled(ImageListItem)``;

export const StyledImageBox = styled(Box)`
  width: auto;
  object-fit: cover;
  max-width: 100%;
  height: auto;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  z-index: 5;
  opacity: 1;
`;
