import React from 'react';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AttractionDateFieldController from './formFields/AttractionDateFieldController';

import { VisitsTableFilterFormWrapper } from '../styled';

import SearchField from '../../../../components/SearchField';

const useStyles = makeStyles({
  searchField: {
    '& .MuiOutlinedInput-root': {
      width: '300px',
    },
  },
});

const AttractionVisitsFilterForm = ({
  handlePaginationSettings,
  handleChangeSearchValue,
  handleResetFilters,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <VisitsTableFilterFormWrapper>
      <SearchField
        name="searchQuery"
        className={classes.searchField}
        placeholder={t('visitsPage.visitsFilterFields.attraction_page_search')}
        handleChange={handleChangeSearchValue}
      />
      <AttractionDateFieldController
        handlePaginationSettings={handlePaginationSettings}
      />
      <IconButton onClick={handleResetFilters}>
        <DeleteIcon />
      </IconButton>
    </VisitsTableFilterFormWrapper>
  );
};

export default AttractionVisitsFilterForm;
