import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';

export const ARTICLES_TITLE_FIELD_MAX_LENGTH = DEFAULT_TEXT_FIELD_MAX_LENGTH;
export const ARTICLES_TEXT_FIELD_MAX_LENGTH = 50000;
export const ARTICLES_TEXT_FIELD_ROWS = 15;
export const EMPTY_HTML_TAG_LENGTH = 8;
export const ARTICLE_ORDER_FIELD_MAX_LENGTH = 10;
export const ARTICLES_TYPES = 'Article Types';
export const SORTING_OPTIONS = [
  {
    value: '',
    label: 'articles.all',
  },
  {
    value: 'OrderAsc',
    label: 'articles.ascending',
  },
  {
    value: 'OrderDesc',
    label: 'articles.descending',
  },
];
