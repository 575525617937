import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import VisitorAddForm from './VisitorAddForm';

import { PRODUCT_TYPES, VISITOR_LANGUAGE } from '../constants';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import {
  getProductsThunk,
  getProductSettingsThunk,
} from '../../../../redux/productsReducer/productActions';
import { addVisitorThunk } from '../../../../redux/usersReducers/visitors/visitorsActions';
import { getUserSettingsThunk } from '../../../../redux/usersReducers/users/userActions';

const VisitorAddFormContainer = (props) => {
  const navigate = useNavigate();
  const {
    selectedLanguage,
    getProductsThunk,
    productList,
    destinationId,
    addVisitorThunk,
    getProductSettingsThunk,
    productTypes,
    languageList,
    getUserSettingsThunk,
  } = props;

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const { addError } = useAPIError();
  const { t } = useTranslation();

  const handleFormSubmit = (data) => {
    setShowSaveAnimation(true);
    addVisitorThunk(data)
      .then(() => {
        setShowSaveAnimation(false);
        navigate('/users/visitors');
      })
      .then(() => addError(`${t('visitors.success_add_visitor')}`, 'Success'))
      .catch((error) => {
        addError(`${t('visitors.error_add_visitor')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    navigate('/users/visitors');
  };

  useEffect(() => {
    if (destinationId) getProductsThunk(destinationId);
    getProductSettingsThunk();
    getUserSettingsThunk();
  }, [
    getProductsThunk,
    destinationId,
    getProductSettingsThunk,
    getUserSettingsThunk,
  ]);

  return productList.length ? (
    <VisitorAddForm
      onFormSubmit={handleFormSubmit}
      onFormCancel={handleFormCancel}
      productList={productList}
      productTypes={productTypes}
      showSaveAnimation={showSaveAnimation}
      selectedLanguage={selectedLanguage}
      languageList={languageList}
    />
  ) : null;
};

const mapStateToProps = (state) => {
  const productTypes =
    state.productsReducer.productSettings.filter(
      (item) => item.name.en === PRODUCT_TYPES
    )[0] || null;
  const languageList =
    state.userReducer.userSettings.filter(
      (item) => item.name.en === VISITOR_LANGUAGE
    )[0] || null;

  return {
    selectedLanguage: state.appReducer.selectedLanguage,
    productList: state.productsReducer.products,
    destinationId: state.appReducer.selectedDestination.id,
    productSettings: state.productsReducer.productSettings,
    productTypes,
    languageList,
  };
};

export default connect(mapStateToProps, {
  addVisitorThunk,
  getProductsThunk,
  getProductSettingsThunk,
  getUserSettingsThunk,
})(VisitorAddFormContainer);
