import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useParams,
  Outlet,
  useLocation,
  Link,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Box, Tab, Tabs, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { salesPointTabs, getOpenedTabIdByUrlParameter } from './salesPointTabs';
import { a11yProps } from '../../components/tabPanel/TabPanel';
import {
  LAST_ACTIVE_TAB_INDEX,
  SALES_POINTS_PAGE_ROUTE,
  SETUP_TAB_INDEX,
} from './constants';

import { setTitle } from '../../redux/appReducer';

const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
  },
  icon: {
    '&.MuiSvgIcon-root': {
      width: '100%',
      height: '100%',
    },
  },
  iconButton: {
    '&.MuiIconButton-root': {
      width: '30px',
      height: '30px',
      padding: 0,
      content: '',
      position: 'absolute',
      top: '7px',
      left: '-76px',
    },
  },
  wrapper: {
    maxWidth: '1300px',
    width: '100%',
    margin: '95px auto',
    position: 'relative',
  },
});

const SalesPointPage = (props) => {
  const {
    appState,
    settingsState,
    getSalesPointThunk,
    setAddElement,
    getCompaniesListThunk,
    getCategoryThunk,
    getPredefinedSettingsThunk,
    getFacilitiesThunk,
    getTagsThunk,
    isAddElement,
  } = props;

  const { t } = useTranslation();
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [activeTabId, setActiveTabId] = useState(
    getOpenedTabIdByUrlParameter(salesPointTabs, location.pathname)
  );

  const handleTabsChange = (_, tabIndex) => {
    setActiveTabId(tabIndex);
  };

  const onGetBack = () => {
    navigate(SALES_POINTS_PAGE_ROUTE);
  };

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_points')));
  }, [])

  useEffect(() => {
    if (id) {
      getSalesPointThunk(id);
      setAddElement(false);
    } else {
      setAddElement(true);
    }
    getCompaniesListThunk(
      appState.selectedDestination.id !== undefined
        ? appState.selectedDestination.id
        : settingsState.destinationData[0].id
    );
    getCategoryThunk();
    getPredefinedSettingsThunk();
    getFacilitiesThunk();
    getTagsThunk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, activeTabId]);

  useEffect(() => {
    setActiveTabId(
      getOpenedTabIdByUrlParameter(salesPointTabs, location.pathname)
    );
  }, [location.pathname, activeTabId]);

  return (
    <Box className={classes.wrapper}>
      <Box mb="20px">
        <Button
          sx={{ color: '#ffffff' }}
          variant="contained"
          onClick={onGetBack}
        >
          {/* BACK TO ATTRACTIONS LIST */}
          {t('attractions.back_to_sales_point_list')}
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTabId}
          className={classes.tabs}
          onChange={handleTabsChange}
          indicatorColor="primary"
          textColor="inherit"
        >
          {salesPointTabs.map((tab, tabIndex) => {
            return (
              <Tab
                component={Link}
                to={tab.path}
                label={t(tab.name)}
                x={{ width: '10%' }}
                disabled={
                  (tabIndex > SETUP_TAB_INDEX && isAddElement) ||
                  tabIndex > LAST_ACTIVE_TAB_INDEX
                }
                {...a11yProps(tabIndex)}
                key={tabIndex}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box sx={{ p: 2 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default SalesPointPage;
