import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StyledTextField } from '../../styled';

const AdvertContentFormTextField = ({
  name,
  disabled,
  required,
  maxLength,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: required,
          message: t('mainImage.validation.required'),
        },
        maxLength: {
          value: maxLength,
          message: t('mainImage.validation.max_length', {
            chars: maxLength,
          }),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StyledTextField
          disabled={disabled}
          fullWidth
          size="small"
          onChange={(e) => onChange(e.target.value)}
          value={value}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default AdvertContentFormTextField;
