import {
  SET_TRANSLATIONS_DATA,
  SET_CURRENT_KEY,
  SET_IS_LOADING_TRANSLATIONS_DATA,
  SET_CATEGORY_DATA,
  SET_IS_LOADING_CATEGORY_DATA,
} from './translationsActions';

const initialState = {
  translationsData: [],
  currentKey: {},
  translationsCategoryData: [],
  isLoadingData: false,
  isLoadingCategoryData: false,
};

const translationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSLATIONS_DATA:
      return {
        ...state,
        translationsData: [...action.payload.data],
      };
    case SET_CURRENT_KEY:
      return {
        ...state,
        currentKey: { ...action.payload.data },
      };
    case SET_IS_LOADING_TRANSLATIONS_DATA:
      return {
        ...state,
        isLoadingData: action.isLoading,
      };
    case SET_CATEGORY_DATA:
      return {
        ...state,
        translationsCategoryData: [...action.payload.data],
      };
    case SET_IS_LOADING_CATEGORY_DATA:
      return {
        ...state,
        isLoadingCategoryData: action.isLoading,
      };
    default:
      return state;
  }
};

export default translationsReducer;
