import axios from 'axios';

import { PRODUCTS_BASE_URL } from '../config';

import authService from '../auth/AuthorizeService';

export const getVisitsList = async (
  destinationId,
  pageNumber,
  pageSize,
  searchQuery,
  date,
  attraction,
  dateSortDirection
) => {
  const token = await authService.getAccessToken();

  const targetUrl = `${PRODUCTS_BASE_URL}/api/destinations/${destinationId}/visits?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${encodeURIComponent(
    searchQuery
  )}&date=${date}&attraction=${encodeURIComponent(attraction)}${
    dateSortDirection ? `&sort=${dateSortDirection}` : ''
  }`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getAttractionVisits = async (
  attractionId,
  pageNumber,
  pageSize,
  searchQuery,
  date,
  dateSortDirection
) => {
  const token = await authService.getAccessToken();

  const targetUrl = `${PRODUCTS_BASE_URL}/api/attractions/${attractionId}/visits?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${encodeURIComponent(
    searchQuery
  )}&date=${date}${dateSortDirection ? `&sort=${dateSortDirection}` : ''}`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const checkConfirmationCode = async (attractionId, code) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${PRODUCTS_BASE_URL}/api/attractions/${attractionId}/visits/${code}`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const postConfirmationCode = async (attractionId, data) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${PRODUCTS_BASE_URL}/api/attractions/${attractionId}/visits/${data.confirmationCode}`;

  return await axios
    .post(targetUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
