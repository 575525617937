export const formatLanguageFieldsData = (values) =>
  Object.keys(values).reduce(
    (acc, item) => {
      if (Array.isArray(values[item])) {
        if (item !== 'image') {
          const currLangObj = values[item][0];

          if (currLangObj.subject) {
            acc.subject[item] = currLangObj.subject.trim();
          }

          if (currLangObj.text) {
            acc.text[item] = currLangObj.text.trim();
          }
        }
      }

      return acc;
    },
    { subject: {}, text: {} }
  );

export const stripHtmlTags = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};