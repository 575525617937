import * as axios from 'axios';
import authService from '../../auth/AuthorizeService';
import { ACCOUNT_BASE_URL } from '../../config.js';

const URL = ACCOUNT_BASE_URL;

export const getCompany = async (id) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/api/companies/${id}`;
  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getCompanyList = async (destinationId, status, companyOrCity) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/api/destinations/${destinationId}/companies?status=${status}&search=${companyOrCity}`;
  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getAllCompanyShortDataList = async () => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/api/companies`;
  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getAttractionListOfCompanies = async (destinationId) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/destinations/${destinationId}/companiesWithAttractionsAndSalesPoints`;
  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addCompany = async (destinationId, data) => {
  const token = await authService.getAccessToken();
  const url = `${URL}/api/destinations/${destinationId}/companies`;
  return await axios
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateCompany = async (companyId, data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${URL}/api/companies/${companyId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteCompany = async (companyId) => {
  const token = await authService.getAccessToken();
  return await axios
    .delete(`${URL}/api/companies/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
