import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';
import { convertBytesToMbs } from '../../publishing/HomePageTab/imageDrop/utils';
import { TOTAL_IMAGE_SIZE_MESSAGE } from './constants';

export const maxLength = (max) => (value) =>
  value && value.length >= max
    ? `Must be ${max} characters or less`
    : undefined;
export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);

export const requiredName = (values) => (!values ? 'Enter name' : undefined);

export const onlyNumber = (value) =>
  value && isNaN(Number(value)) ? 'Enter only number' : undefined;
export const noSpaceName = (values) =>
  values && values.trim() === ''
    ? 'Name cannot consist of just spaces'
    : undefined;

export function requiredType(value) {
  return value === null ? 'Select type' : undefined;
}

export function requiredStatus(value) {
  return value === null ? 'Select status' : undefined;
}

export function requiredPopular(value) {
  return value == null ? 'Enter the number' : undefined;
}

export function requiredRegion(value) {
  return value === null ? 'Select region' : undefined;
}
export function requiredArea(value) {
  return value === null ? 'Select area' : undefined;
}

export function requiredCategory(value) {
  return value === null ? 'Select category' : undefined;
}

export function requiredLocation(value) {
  return value === null ? 'Select location' : undefined;
}

export const requiredEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const listItemsMaxLength = (values) => {
  if (!values) return undefined;
  let error = undefined;
  values.forEach((value) => {
    if (value.length >= 200) error = 'Must be 200 characters or less';
  });
  return error;
};

export const required = (value) => (!value ? 'Required' : undefined);

export const createFileFromUrl = async (url) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: data.type };
  const filename = url.replace(/\?.+/, '').split('/').pop();
  const fileObj = new File([data], filename, metadata);
  fileObj.isRemote = true;
  return fileObj;
};

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event?.target?.result);
    };

    reader.onerror = (event) => {
      reader.abort();
      reject(event);
    };

    reader.readAsDataURL(file);
  });
};

export const validateImageListTotalSize = async (fileList, maxSizeMbytes) => {
  if (!fileList) return true;

  try {
    const fileObjList = await Promise.all(
      fileList.map(async (fileObj) => {
        if (typeof fileObj === 'string') {
          const file = await createFileFromUrl(fileObj);

          const data = await readFile(file);

          return {
            file,
            data,
          };
        } else {
          return fileObj;
        }
      })
    );

    const totalSize = fileObjList.reduce((acc, current) => {
      return acc + current.file.size;
    }, 0);

    const mbytesSize = convertBytesToMbs(totalSize);

    return maxSizeMbytes > mbytesSize;
  } catch (err) {
    return true;
  }
};

export const asyncValidate = async (values) => {
  const error = {};

  const isAppImagesValid = await validateImageListTotalSize(
    values.appImages,
    50
  );

  if (!isAppImagesValid) {
    error.appImages = TOTAL_IMAGE_SIZE_MESSAGE;
  }

  const isWebImagesValid = await validateImageListTotalSize(
    values.webImages,
    50
  );

  if (!isWebImagesValid) {
    error.webImages = TOTAL_IMAGE_SIZE_MESSAGE;
  }

  if (!!Object.keys(error).length) return Promise.reject(error);
  else return Promise.resolve(error);
};
