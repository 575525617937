import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

export const ReviewPageWrapper = styled(Box)`
  width: 1300px;
  margin: 95px auto;
`;

export const LoaderWrapper = styled(Box)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReviewsFilterWrapper = styled(Box)`
  display: flex;
  alignitems: center;
  margin-bottom: 20px;
  margin-top: 30px;
`;

export const ReviewAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;
