import { styled } from '@mui/system';
import { Box, Tab, Tabs } from '@mui/material';

export const PublishingPageContainer = styled(Box)`
  max-width: 1300px;
  width: 100%;
  margin: 95px auto;
`;

export const PublishingTabsContainer = styled(Box)`
  border-bottom: 1;
  border-color: divider;
`;

export const PublishingTabs = styled(Tabs)`
  & .MuiTabs-flexContainer {
    justifycontent: flex-start;
  }
`;

export const PublishingTab = styled(Tab)`
  width: 20%;
`;
