import {
  createImageRecord,
  getImageRecordList,
  updateImageRecord,
  deleteImageRecord,
} from '../../../api/publishing/mainImageApi';

export const SET_IMAGE_RECORD_LIST = 'mainImage/setImageRecordList';
export const SET_IS_LOADING = 'mainImage/setIsLoading';

export const setImageList = (data) => {
  return {
    type: SET_IMAGE_RECORD_LIST,
    payload: { data },
  };
};

export const setIsImageListLoading = (data) => {
  return {
    type: SET_IS_LOADING,
    payload: { data },
  };
};

export const createImageRecordThunk = (data) => async () => {
  const response = await createImageRecord(data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const getImageRecordListThunk = (destinationId) => async (dispatch) => {
  dispatch(setIsImageListLoading(true));

  const response = await getImageRecordList(destinationId);

  if (response.status === 200) {
    dispatch(setImageList(response.data));
    dispatch(setIsImageListLoading(false));
  }
};

export const updateImageRecordThunk = (recordId, data) => async () => {
  const response = await updateImageRecord(recordId, data);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export const deleteImageRecordThunk = (recordId) => async () => {
  const response = await deleteImageRecord(recordId);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
