export const getImageUrl = async (imageList, uploadHandler, type) => {
  let imageUrl;

  if (!imageList.length) {
    imageUrl = null;
    return imageUrl;
  }

  if (typeof imageList[0] === 'string') {
    imageUrl = imageList[0];
    return imageUrl;
  }

  const imageFormData = new FormData();
  imageFormData.append('files', imageList[0].file);

  const response = await uploadHandler(imageFormData, type);

  if (response.status === 200) {
    imageUrl = response.data[0];
  } else {
    console.error(`${type} upload error:`, response.data.errors);
    imageUrl = null;
  }

  return imageUrl;
};

export const findDestinationLanguages = (destinationId, settingsState) => {
  const languages = settingsState.destinationData.find(
    (destination) => destination.id === destinationId
  )?.languages;

  return languages;
};

// export const parseCurrentLocation = (
//   regionId,
//   areaId,
//   locationId,
//   settingsState
// ) => {
//   if (locationId) {
//     return settingsState.dictionaryLocationData?.find(
//       (location) => location.id === locationId
//     );
//   }

//   if (areaId) {
//     return settingsState.dictionaryAreaData?.find((area) => area.id === areaId);
//   }

//   if (regionId) {
//     return settingsState.dictionaryRegionData?.find(
//       (region) => region.id === regionId
//     );
//   }

//   return undefined;
// };
export const parseCurrentLocation = (locationId, settingsState) => {

  const location = settingsState.dictionaryLocationData?.find(
    (location) => location.id === locationId
  );

  if (location) return location;

  const area = settingsState.dictionaryAreaData?.find(
    (area) => area.id === locationId
  );

  if (area) return area;

  const region = settingsState.dictionaryRegionData?.find(
    (region) => region.id === locationId
  );

  if (region) return region;

  return undefined;
};

export const getExpandedIdsFromUrl = (
  destinationId,
  regionId,
  areaId,
  locationId
) => {
  const expandedIdList = [];

  destinationId && expandedIdList.push(destinationId);
  regionId && expandedIdList.push(regionId);
  areaId && expandedIdList.push(areaId);
  locationId && expandedIdList.push(locationId);

  return expandedIdList;
};

export const getSelectedItemIdFromUrl = (
  destinationId,
  regionId,
  areaId,
  locationId
) => {
  const defaultIdValue = '';

  if (locationId) return locationId;
  if (areaId) return areaId;
  if (regionId) return regionId;
  if (destinationId) return destinationId;

  return defaultIdValue;
};
