import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import { List, ListItem, Typography, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { SelectStatus, SelectDestination } from './Select';

import { CategoryAddButton } from './styled';
import cl from './Categories.module.css';

import { ATTRACTION_CATEGORY_STATUS } from '../../../consts/consts';

const Categories = (props) => {
  const {
    categoryState,
    stateDestinations,
    predefinedState,
    selectedLanguage,
    getPredefinedSettingsThunk,
    getCategoryThunk,
    getSearchCategoryThunk,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const match = useMatch('/settings/category');

  const [statusValue, setStatusValue] = useState('');
  const [destinationsValue, setDestinationsValue] = useState('');

  const onChangeStatus = (value) => {
    setStatusValue(value);
    getSearchCategoryThunk(
      destinationsValue !== '' ? destinationsValue : null,
      value
    );
  };

  const onChangeDestination = (value) => {
    setDestinationsValue(value);
    getSearchCategoryThunk(value, statusValue !== '' ? statusValue : null);
  };

  const onAddCategory = () => {
    navigate('new-category');
  };

  const handleListItemClick = (item, index) => {
    navigate(`view/${item.id}`);
  };

  useEffect(() => {
    Promise.all([getPredefinedSettingsThunk(), getCategoryThunk()]);
  }, [getPredefinedSettingsThunk, getCategoryThunk]);

  useEffect(() => {
    if (match) {
      setDestinationsValue('');
      setStatusValue('');
    }
  }, [match]);

  if (!categoryState.isLoadingCategoryData) {
    return (
      <Box
        component="div"
        sx={{ position: 'absolute', top: '50%', right: '50%' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={cl.wrapper}>
      <div className={cl.leftBox}>
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('settings_page.category.headline')}
        </Typography>
        <Box mt="10px" mb="10px">
          <CategoryAddButton variant="contained" onClick={onAddCategory}>
            {t('settings_page.category.add_button')}
          </CategoryAddButton>
        </Box>
        <div className={cl.searchBox}>
          <div className={cl.searchSelect}>
            <SelectStatus
              className={cl.searchInput}
              data={predefinedState.filter(
                (item) => item.name.en === ATTRACTION_CATEGORY_STATUS
              )}
              selectedLanguage={selectedLanguage}
              onChangeStatus={onChangeStatus}
              value={statusValue}
            />
          </div>
          <div className={cl.search}>
            <SelectDestination
              className={cl.searchInput}
              data={stateDestinations}
              selectedLanguage={selectedLanguage}
              onChangeDestination={onChangeDestination}
              value={destinationsValue}
            />
          </div>
        </div>
        <div>
          <div className={cl.header}>
            <div className={cl.headerStatus}>
              {t('settings_page.category.status')}
            </div>
            <div className={cl.headerOrder}>
              {t('settings_page.category.order')}
            </div>
            <div className={cl.headerName}>
              {t('settings_page.category.name')}
            </div>
          </div>
          {!categoryState.categoryData.length ? (
            <div className={cl.absent}>
              {t('settings_page.category.no_category')}
            </div>
          ) : (
            <List sx={{ padding: '0', borderBottom: '1px solid #CDD2D7' }}>
              {categoryState.categoryData.map((item, index) => (
                <ListItem
                  button
                  key={item.id}
                  selected={categoryState.currentCategory.id === item.id}
                  onClick={() => handleListItemClick(item, index)}
                  sx={{ padding: '0' }}
                >
                  <div
                    className={clsx(cl.status, {
                      [cl.active]: item.status === 'Active',
                      [cl.inactive]: item.status === 'Inactive',
                    })}
                  >
                    {item.status === 'Active' ? t('settings_page.category.active') : item.status === 'Inactive' ? t('settings_page.category.inactive') : null}
                  </div>
                  <div className={cl.order}>{item.order}</div>
                  <div className={cl.name}>
                    {item.name[selectedLanguage] || item.name.en}
                  </div>
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </div>
      <div className={cl.rightBox}>
        <Outlet />
      </div>
    </div>
  );
};

export default Categories;
