import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TodayIcon from '@mui/icons-material/Today';

import { VisitorFormDateField, VisitorFormDateTextField } from '../../styled';

const useStyles = makeStyles({
  dateFieldInputDisabled: {
    '& .MuiOutlinedInput-input': {
      cursor: 'default',
    },

    '& .MuiInputAdornment-root': {
      cursor: 'default',
    },
  },
});

const VisitorEditFormDateFIeld = ({ name, disabled }) => {
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();

  const handleOpenCalendar = () => setIsOpen(true);
  const handleCloseCalendar = () => setIsOpen(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <VisitorFormDateField
            inputFormat="dd.MM.yyyy"
            onChange={(event) => onChange(event)}
            value={value}
            disabled={disabled}
            mask={'__.__.____'}
            showToolbar={false}
            open={isOpen}
            onOpen={handleOpenCalendar}
            onAccept={handleCloseCalendar}
            onClose={handleCloseCalendar}
            renderInput={(params) => (
              <VisitorFormDateTextField
                size="small"
                placeholder="dd/mm/yyyy"
                {...params}
                className={clsx(disabled && classes.dateFieldInputDisabled)}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      disablePointerEvents={disabled}
                      position="start"
                      onClick={handleOpenCalendar}
                    >
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default VisitorEditFormDateFIeld;
