import { useTranslation } from 'react-i18next';
import { useState, useMemo, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { reset } from 'redux-form';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import RegionForm from './RegionForm';

import { DestinationFormWrapper } from './styled';

import {
  getDestinationThunk,
  editLocationThunk,
  setCurrentLocation,
  deleteLocationThunk,
} from '../../../../redux/settingsReducers/settingsReducer';
import { CONTENT_LANGUAGES, ACTIVITY_STATUS } from '../../../../consts/consts';
import { removeObjectField } from '../../../attractions/content/utils';
import { getImageUrl } from '../utils';
import { uploadImageFile } from '../../../../api/publishing/mainImageApi';
import { DESTINATION_PAGE_ROUTE } from '../constants';
import { findDestinationLanguages, parseCurrentLocation } from '../utils';

const RegionEditFormContainer = (props) => {
  const {
    settingsState,
    globalSettings,
    getDestinationThunk,
    appState,
    setCurrentLocation,
    editLocationThunk,
    deleteLocationThunk,
  } = props;
  const { currentLocation } = settingsState;
  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { destinationId, regionId, areaId, locationId } = useParams();
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [geoCoordinate, setGeoCoordinate] = useState([{lat: 0, lng:0}]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const selectedLanguages = useMemo(
    () => findDestinationLanguages(destinationId, settingsState),
    [destinationId]
  );

  const currentDestination = useMemo(
    () =>
      settingsState.destinationData.find(
        (destination) => destination.id === destinationId
      ),
    [destinationId]
  );

  const handleRegionFormSubmit = async (values) => {
    setShowSaveAnimation(true);

    const imageUrl = await getImageUrl(values.imageUrl, uploadImageFile, 'App');

    removeObjectField(values.tips, 'new-tips');
    removeObjectField(values.highlights, 'new-highlights');
    removeObjectField(values.directions, 'new-directions');

    const updateData = {
      id: currentLocation.id,
      destinationId: destinationId,
      locationType: currentLocation.locationType,
      title: values.title,
      locationStatus: values.locationStatus,
      order: values.order,
      isHideForMainPage: values.isHideForMainPage,
      subtitle: values.subtitle,
      introduction: values.introduction,
      description: values.description,
      tips: values.tips,
      highlights: values.highlights,
      directions: values.directions,
      imageUrl: imageUrl,
      mapCoordinates: geoCoordinate,
    };

    editLocationThunk(currentLocation.id, updateData)
      .then(() => {
        getDestinationThunk();
        setIsInEditMode(false);
        setShowSaveAnimation(false);
        addError(
          t('settings_page.destination.success_edit_location_type', {
            locationType: t(
              `attractions.list.${currentLocation.locationType.toLowerCase()}`
            ),
          }),
          'Success'
        );
        navigate(DESTINATION_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          t('settings_page.destination.error_edit_location_type', {
            locationType: t(
              `attractions.list.${currentLocation.locationType.toLowerCase()}`
            ),
          }),
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    setIsInEditMode(false);
    dispatch(reset('RegionForm'));
  };

  const handleAlertCancel = () => {
    setShowAlert(false);
  };

  const handleCloseSuccess = () => {
    setShowSaveAnimation(true);
    setIsInEditMode(false);
    deleteLocationThunk(currentLocation.id)
      .then(() => {
        setShowAlert(false);
        setShowSaveAnimation(false);
        getDestinationThunk();
        addError(
          t('settings_page.destination.success_delete_location_type', {
            locationType: t(
              `attractions.list.${currentLocation.locationType.toLowerCase()}`
            ),
          }),
          'Success'
        );
        navigate(DESTINATION_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          t('settings_page.destination.error_delete_location_type', {
            locationType: t(
              `attractions.list.${currentLocation.locationType.toLowerCase()}`
            ),
          }),
          'Error',
          [error.Id[0]]
        );
        setShowAlert(false);
        setShowSaveAnimation(false);
      });
  };

  const onDeleteLocation = () => {
    setShowAlert(true);
  };

  useEffect(() => {
    const currentLocation = parseCurrentLocation(
      // regionId,
      //areaId,
      locationId || areaId || regionId,
      settingsState
    );
    setGeoCoordinate(currentLocation.mapCoordinates);
    setCurrentLocation(currentLocation);

    return () => setIsInEditMode(false);
  }, [regionId, areaId, locationId, setCurrentLocation]);

  return (
    <DestinationFormWrapper>
      {currentDestination?.id && currentLocation.id && (
        <RegionForm
          currentItem={currentLocation}
          onSubmit={handleRegionFormSubmit}
          updateLocation={true}
          isEdit={isInEditMode}
          setIsEdit={setIsInEditMode}
          geoCoordinate={geoCoordinate}
          setGeoCoordinate={setGeoCoordinate}
          languages={selectedLanguages}
          status={globalSettings.filter(
            (item) => item.name.en === CONTENT_LANGUAGES
          )}
          globalSettings={globalSettings.filter(
            (item) => item.name.en === ACTIVITY_STATUS
          )}
          selectedLanguages={appState.selectedLanguage}
          isSave={showSaveAnimation}
          setIsSave={setShowSaveAnimation}
          locationType={currentLocation.locationType}
          onCancel={handleFormCancel}
          onCloseSuccess={handleCloseSuccess}
          onAlertCancel={handleAlertCancel}
          showAlert={showAlert}
          onDeleteLocation={onDeleteLocation}
        />
      )}
    </DestinationFormWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    settingsState: state.settingsReducer,
    globalSettings: state.appReducer.globalSettingsData,
    appState: state.appReducer,
  };
};

export default connect(mapStateToProps, {
  getDestinationThunk,
  editLocationThunk,
  setCurrentLocation,
  deleteLocationThunk,
})(RegionEditFormContainer);
