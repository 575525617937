import * as axios from 'axios';
import authService from '../auth/AuthorizeService';
import { BASE_URL } from '../config.js';

export const getTranslationsApi = async (destinationId) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/destinations/${destinationId}/translations`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getTranslationCategoryApi = async () => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/Translations/categories`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getSearchKeyApi = async (destinationId, category) => {
  const token = await authService.getAccessToken();
  let url = `${BASE_URL}/api/destinations/${destinationId}/Translations?`;
  if (category) {
    url += `filter=${category}`;
  }
  return await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addKeyApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .post(
      `${BASE_URL}/api/destinations/${data.destinationId}}/translations`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((error) => {
      return error.response;
    });
};

export const editKeyApi = async (id, data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${BASE_URL}/api/Translations/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteKeyApi = async (id) => {
  const token = await authService.getAccessToken();
  return await axios
    .delete(`${BASE_URL}/api/Translations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
