import React from "react";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

const defaultOptions = {
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: false,
    keyboardShortcuts: false,
    scrollwheel: false,
    disableDoubleClickZoom: false,
    fullscreenControl: true
}

const Map = ({geoCoordinate, setGeoCoordinate}) => {
    
    const onPositionClick =(e) => {
        setGeoCoordinate([
            { lat: e.latLng.lat(), lng: e.latLng.lng() }
        ])
    }

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: 50.073988727436216, lng: 14.436026387041693 }}
            onClick={(e)=> onPositionClick (e)}
            defaultOptions={defaultOptions}
        >
            <Marker 
                position={geoCoordinate[0]}
            />
        </GoogleMap>
    );
}

const SimpleMap = withScriptjs(withGoogleMap(Map))

export default SimpleMap;


