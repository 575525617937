import { Controller, useFormContext } from 'react-hook-form';

import { RedeemCodeTextField } from '../../styled';

import { validateTextFieldValue } from '../../../../../helpers/validateTextFieldValue';

const RedeemCodeFormTextField = ({ name, disabled, customClass }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <RedeemCodeTextField
          className={customClass}
          disabled={disabled}
          fullWidth
          size="small"
          onChange={(e) => onChange(validateTextFieldValue(e.target.value))}
          value={value}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default RedeemCodeFormTextField;
