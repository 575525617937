import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TodayIcon from '@mui/icons-material/Today';
import { LocalizationProvider } from '@mui/lab';
import { Box, Button, InputAdornment } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { makeStyles } from '@mui/styles';

import {
  PricesDatePickerTextField,
  PricesFormDatePicker,
  StyledFormHelperText,
} from '../../styled';

const useStyles = makeStyles({
  dialogActionBar: {
    '.MuiDialog-container': {
      '.MuiDialogActions-root': {
        position: 'absolute',
        right: '0px',
        bottom: '0px',
      },
    },
  },
  dialogCancelButton: {
    position: 'absolute',
    bottom: '8px',
    right: '72px',

    button: {
      zIndex: 1,
    },
  },
});

const HelperText = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <StyledFormHelperText>{touched && error}</StyledFormHelperText>;
  }
};

const PricesFormDateField = (props) => {
  const {
    input,
    meta: { touched, error },
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenCalendar = () => setIsOpen(true);
  const handleCloseCalendar = () => setIsOpen(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <PricesFormDatePicker
        inputFormat="dd.MM.yyyy"
        onChange={(event) => {
          input.onChange(event);
        }}
        value={input.value}
        mask={'__.__.____'}
        open={isOpen}
        onOpen={handleOpenCalendar}
        onClose={handleCloseCalendar}
        disableCloseOnSelect={false}
        clearable
        cancelText=""
        clearText={t('clear')}
        okText={t('ok')}
        {...props}
        ToolbarComponent={() => (
          <Box className={classes.dialogCancelButton}>
            <Button variant="text" onClick={handleCloseCalendar}>
              {t('cancel')}
            </Button>
          </Box>
        )}
        DialogProps={{
          className: classes.dialogActionBar,
        }}
        renderInput={(params) => (
          <PricesDatePickerTextField
            {...params}
            size="small"
            variant="outlined"
            placeholder={t('visitsPage.visitsFilterFields.date')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" onClick={handleOpenCalendar}>
                  <TodayIcon />
                </InputAdornment>
              ),
            }}
            error={false}
          />
        )}
      />
      {HelperText({ touched, error })}
    </LocalizationProvider>
  );
};

export default PricesFormDateField;
