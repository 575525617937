import {
  SET_GENERAL_NOTIFICATIONS_LIST,
  IS_LOADED_NOTIFICATIONS,
  SET_GENERAL_NOTIFICATION,
} from './generalNotificationsActions';

const initialState = {
  notificationData: [],
  isLoaded: false,
};

const generalNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GENERAL_NOTIFICATIONS_LIST:
      return {
        ...state,
        notificationData: [...action.payload.data],
      };
    case SET_GENERAL_NOTIFICATION:
      const {data} = action.payload;

      const newNotifications = state.notificationData.map(notification => {
        if(notification.id === data.id) {
          return data;
        }
        return notification
      })

      return {
        ...state,
        notificationData: newNotifications,
      };
    case IS_LOADED_NOTIFICATIONS:
      return {
        ...state,
        isLoaded: action.isLoadedNotification,
      };
    default:
      return state;
  }
};

export default generalNotificationsReducer;
