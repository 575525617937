import { styled } from '@mui/system';
import { Box, Select, TextField, Typography } from '@mui/material';

export const VisitorFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;

export const VisitorFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const VisitorFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const VisitorFormTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    width: 100%;
  }

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const VisitorFormDateField = styled(TextField)`
  & .MuiOutlinedInput-root {
    width: 100%;
    max-width: 215px;
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const VisitorFormSelectField = styled(Select)`
  width: 100%;
`;

export const VisitorFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 14px;
`;

export const VisitorFormHeader = styled(Box)`
  display: flex;
`;

export const VisitorFormHeaderLabel = styled(Box)`
  display: flex;
  width: 100%;
`;
