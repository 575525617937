import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Typography } from '@mui/material';

import AccountAccessForm from './forms/AccountAccessForm';
import CompanyForm from '../../users/companiesTab/forms/CompanyForm';
import ManagedAttractionsForm from '../../users/companiesTab/forms/ManagedAttractionsForm';

import { LoaderWrapper } from './styled';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import {
  COMPANY_FORM,
  MANAGED_ATTRACTIONS_FORM,
} from '../../users/companiesTab/constants';
import {
  removeEmptyObjects,
  mapAttractionsToAttractionContacts,
  excludeAttractionNames,
} from '../../users/companiesTab/utils';
import { getAllowedUserAccounts } from './utils';
import { useNavigationPrompt } from '../../../hooks/navigation';

const Company = (props) => {
  const {
    statusOptions,
    selectedLanguage,
    attractionsData,
    editCompanyThunk,
    selectedDestinationId,
    getUserSettingsThunk,
    getUsersListThunk,
    userRoles,
    userList,
    currentAttraction,
    getCurrentCompanyThunk,
    getAttractionListOfCompaniesThunk,
    attractionListOfCompanies,
    companyData,
    isCompanyFormDirty,
    isManagedAttractionsFormDirty,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addError } = useAPIError();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [isCompanyFormInEditMode, setIsCompanyFormInEditMode] = useState(false);
  const [
    isManagedAttractionsFormInEditMode,
    setIsManagedAttractionsFormInEditMode,
  ] = useState(false);

  const allowedUserAccounts = getAllowedUserAccounts(
    userList,
    currentAttraction
  );

  const companyAttractionsList = attractionListOfCompanies.find(
    (company) => currentAttraction.companyId === company.id
  );

  const resetForm = (formName) => {
    dispatch(reset(formName));
  };

  const handleCompanyFormSubmit = (formData) => {
    setIsCompanyFormInEditMode(false);
    setShowSaveAnimation(true);

    const updatedCompanyData = {
      id: formData.id,
      status: formData.status,
      name: formData.name,
      website: formData.website,
      streetAddress: formData.streetAddress,
      city: formData.city,
      zipCode: parseInt(formData.zipCode) || null,
      country: formData.country,
      registrationNumber: parseInt(formData.registrationNumber) || null,
      valueAddedTaxNumber: formData.valueAddedTaxNumber,
      personContacts: removeEmptyObjects(formData.personContacts),
      billingContacts: removeEmptyObjects(formData.billingContacts),
      bankAccount: formData.bankAccount,
      attractionContacts: companyData.attractionContacts,
    };

    editCompanyThunk(updatedCompanyData.id, updatedCompanyData)
      .then(() => {
        getCurrentCompanyThunk(currentAttraction?.companyId);
        setShowSaveAnimation(false);
        addError(`${t('companies.success_edit_company')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('companies.error_edit_company')}`, 'Error', error);
        setShowSaveAnimation(false);
        resetForm(COMPANY_FORM);
      });
  };

  const handleManagedAttractionsFormSubmit = (formData) => {
    setIsManagedAttractionsFormInEditMode(false);
    setShowSaveAnimation(true);

    const updatedCompanyData = {
      ...companyData,
      attractionContacts: excludeAttractionNames(formData.attractionContacts),
    };

    editCompanyThunk(updatedCompanyData.id, updatedCompanyData)
      .then(() => {
        getCurrentCompanyThunk(currentAttraction?.companyId);
        setShowSaveAnimation(false);
        addError(`${t('companies.success_edit_company')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('companies.error_edit_company')}`, 'Error', error);
        setShowSaveAnimation(false);
        resetForm(MANAGED_ATTRACTIONS_FORM);
      });
  };

  const handleCompanyFormEdit = () => {
    setIsCompanyFormInEditMode(true);
  };

  const handleCompanyFormCancel = () => {
    setIsCompanyFormInEditMode(false);
    resetForm(COMPANY_FORM);
  };

  const handleManagedAttractionsFormEdit = () => {
    setIsManagedAttractionsFormInEditMode(true);
  };

  const handleManagedAttractionsFormCancel = () => {
    setIsManagedAttractionsFormInEditMode(false);
    resetForm(MANAGED_ATTRACTIONS_FORM);
  };

  const mappedAttractionContacts = useMemo(
    () =>
      mapAttractionsToAttractionContacts(
        companyAttractionsList?.attractions,
        companyData?.attractionContacts
      ),
    [companyAttractionsList?.attractions, companyData?.attractionContacts]
  );

  useEffect(() => {
    if (currentAttraction?.companyId)
      getCurrentCompanyThunk(currentAttraction?.companyId);
    getUserSettingsThunk();
    getUsersListThunk({});
    getAttractionListOfCompaniesThunk(selectedDestinationId);
  }, [
    getUsersListThunk,
    getUserSettingsThunk,
    selectedDestinationId,
    getCurrentCompanyThunk,
    getAttractionListOfCompaniesThunk,
    currentAttraction?.companyId,
  ]);

  useNavigationPrompt(isCompanyFormDirty || isManagedAttractionsFormDirty);

  return (
    <>
      {currentAttraction?.companyId !== '' ? (
        <>
          {companyData && statusOptions ? (
            <>
              <CompanyForm
                item={companyData}
                onSubmit={handleCompanyFormSubmit}
                onFormCancel={handleCompanyFormCancel}
                onFormEdit={handleCompanyFormEdit}
                isInEditMode={isCompanyFormInEditMode}
                showSaveAnimation={showSaveAnimation}
                statusOptions={statusOptions}
                selectedLanguage={selectedLanguage}
                preventDelete
              />
              <ManagedAttractionsForm
                onSubmit={handleManagedAttractionsFormSubmit}
                onFormCancel={handleManagedAttractionsFormCancel}
                onFormEdit={handleManagedAttractionsFormEdit}
                isInEditMode={isManagedAttractionsFormInEditMode}
                companyData={companyData}
                attractionsData={attractionsData}
                showSaveAnimation={showSaveAnimation}
                mappedAttractionContacts={mappedAttractionContacts}
              />
              <AccountAccessForm
                userList={allowedUserAccounts}
                userRoles={userRoles}
              />
            </>
          ) : (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}
        </>
      ) : (
        <Typography fontWeight={400} fontSize={20}>
          {t('companies.no_company_for_attraction')}
        </Typography>
      )}
    </>
  );
};

export default Company;
