import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import { Button, Box, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import SetupFormTextField from './formFields/SetupFormTextField';
import SetupFormSelectField from './formFields/SetupFormSelectField';
import SetupFormRegionSelectField from './formFields/SetupFormRegionSelectField';
import SetupFormAreaSelectField from './formFields/SetupFormAreaSelectField';
import SetupFormLocationSelectField from './formFields/SetupFormLocationSelectField';
import SetupFormCompanySelectField from './formFields/SetupFormCompanySelectField';

import {
  SetupFormControlPanelWrapper,
  SetupFormFieldTitle,
  SetupFormFieldWrapper,
  SetupFormLocationTitlesWrapper,
  SetupFormTitleWrapper,
} from './styled';

import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import WrappedMap from '../../../components/map/map';

import { validateTextFieldValue } from '../../../helpers/validateTextFieldValue';
import { validateNumberValue } from '../../../helpers/validateNumberValue';

import { ACTIVITY_STATUS, POINT_TYPES } from '../../../consts/consts';
import { TYPE_AREA, TYPE_REGION, TYPE_LOCATION } from '../constants';
import { ACTIVE_STATUS } from './constants';
import { GOOGLE_MAP_KEY } from '../../../config';

const SetupForm = (props) => {
  const {
    salesPointsReducer,
    setShowAlert,
    setIsEdit,
    handleCancelChanges,
    isEdit,
    isSave,
    hasAttractionManagerRole,
    onSubmit,
    statusValue,
    appState,
    settingsState,
    regionValue,
    areaValue,
    handleChangeMapLatitude,
    handleUpdateCoordinates,
    coordinates,
    handleChangeMapLongtitude,
    showAlert,
    handleDeleteAttraction,
    companyState,
  } = props;

  const { t } = useTranslation();
  const { id } = useParams();
  const { handleSubmit } = useFormContext();
  const location = useLocation();

  const isNewAttraction = location.pathname.includes('new');
  const currentDestinationData = settingsState.destinationData.find(
    (dest) => dest.id === appState.selectedDestination.id
  );
  const isAreaEnabled =
    currentDestinationData?.enabledLocationTypes.includes(TYPE_AREA);
  const isRegionEnabled =
    currentDestinationData?.enabledLocationTypes.includes(TYPE_REGION);
  const isLocationEnabled =
    currentDestinationData?.enabledLocationTypes.includes(TYPE_LOCATION);

  const onDeleteAttraction = () => {
    setShowAlert(true);
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  const onEditAttraction = () => {
    setIsEdit(true);
  };

  return (
    <form>
      <SetupFormControlPanelWrapper>
        <SetupFormTitleWrapper>
          {!id
            ? `${t('attractions.new_point')}`
            : salesPointsReducer.currentPoint.name}
        </SetupFormTitleWrapper>
        <Box display="flex" gap="10px">
          {id && (
            <>
              <Button
                variant="outlined"
                onClick={handleCancelChanges}
                type="button"
                disabled={!isEdit}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="outlined"
                onClick={onEditAttraction}
                type="button"
                disabled={isEdit || isSave}
              >
                {t('attractions.setup.edit_button')}
              </Button>
              {!hasAttractionManagerRole && (
                <Button
                  variant="outlined"
                  onClick={onDeleteAttraction}
                  type="button"
                  disabled={isSave}
                >
                  {t('attractions.setup.delete_button')}
                </Button>
              )}
            </>
          )}
          <LoadingButton
            loading={isSave}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{ color: '#fff' }}
            disabled={!isNewAttraction && !isEdit}
            onClick={handleSubmit(onSubmit)}
          >
            {t('attractions.setup.save_button')}
          </LoadingButton>
        </Box>
      </SetupFormControlPanelWrapper>

      {/* Main section */}
      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.name')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="name"
          placeholder={t('attractions.setup.name')}
          validator={validateTextFieldValue}
          required
          disabled={isNewAttraction ? false : !isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.type')}:
        </SetupFormFieldTitle>
        <SetupFormSelectField
          name="type"
          disabled={isNewAttraction ? false : !isEdit}
          optionsData={appState.globalSettingsData}
          optionsName={POINT_TYPES}
          required={statusValue === ACTIVE_STATUS}
          selectedLanguage={appState.selectedLanguage}
          emptyOption={{
            id: 'type',
            value: '',
            label: t('attractions.setup.type'),
          }}
        />
      </SetupFormFieldWrapper>

      {/* <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.entry_type')}:
        </SetupFormFieldTitle>
        <SetupFormSelectField
          name="entryType"
          disabled={isNewAttraction ? false : !isEdit}
          optionsData={predefinedState.predefinedData}
          optionsName={ATTRACTION_ENTRY_TYPES}
          required={statusValue === ACTIVE_STATUS}
          selectedLanguage={appState.selectedLanguage}
          emptyOption={{
            id: 'entryType',
            value: '',
            label: t('attractions.setup.entry_type'),
          }}
        />
      </SetupFormFieldWrapper> */}

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.status')}:
        </SetupFormFieldTitle>
        <SetupFormSelectField
          name="status"
          disabled={isNewAttraction ? false : !isEdit}
          optionsData={appState.globalSettingsData}
          optionsName={ACTIVITY_STATUS}
          selectedLanguage={appState.selectedLanguage}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.company')}:
        </SetupFormFieldTitle>
        <SetupFormCompanySelectField
          name="companyId"
          disabled={isNewAttraction ? false : !isEdit}
          optionsData={companyState.companiesData}
          emptyOption={{
            id: 'companyId',
            value: '',
            label: t('attractions.setup.company'),
          }}
        />
      </SetupFormFieldWrapper>

      {/* Location section */}
      <Typography fontWeight="500px" fontSize="20px" margin="20px 0 20px">
        {t('attractions.setup.location')}
      </Typography>

      <Box display="flex" gap="10px">
        <SetupFormLocationTitlesWrapper>
          <SetupFormFieldTitle>
            {t('attractions.setup.destination')}:
          </SetupFormFieldTitle>

          {isRegionEnabled && (
            <SetupFormFieldTitle lineHeight="40px" mt="20px">
              {t('attractions.setup.region')}:
            </SetupFormFieldTitle>
          )}

          {isAreaEnabled && (
            <SetupFormFieldTitle lineHeight="40px" mt="20px">
              {t('attractions.setup.area')}:
            </SetupFormFieldTitle>
          )}

          {isLocationEnabled && (
            <SetupFormFieldTitle lineHeight="40px" mt="20px">
              {t('attractions.setup.location')}:
            </SetupFormFieldTitle>
          )}
        </SetupFormLocationTitlesWrapper>

        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          <Typography overflow="hidden" textOverflow="ellipsis">
            {appState.selectedDestination.name}
          </Typography>

          {isRegionEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormRegionSelectField
                name="regionId"
                disabled={isNewAttraction ? false : !isEdit}
                optionsData={currentDestinationData}
                selectedLanguage={appState.selectedLanguage}
                selectedDestinationId={appState.selectedDestination.id}
                emptyOption={{
                  id: 'regionId',
                  value: '',
                  label: t('attractions.setup.none'),
                }}
              />
            </SetupFormFieldWrapper>
          )}

          {isAreaEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormAreaSelectField
                name="areaId"
                disabled={isNewAttraction ? false : !isEdit}
                optionsData={currentDestinationData}
                selectedLanguage={appState.selectedLanguage}
                regionId={regionValue}
                selectedDestinationId={appState.selectedDestination.id}
                emptyOption={{
                  id: 'areaId',
                  value: '',
                  label: t('attractions.setup.none'),
                }}
              />
            </SetupFormFieldWrapper>
          )}

          {isLocationEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormLocationSelectField
                name="locationId"
                disabled={isNewAttraction ? false : !isEdit}
                optionsData={currentDestinationData}
                selectedLanguage={appState.selectedLanguage}
                selectedDestinationId={appState.selectedDestination.id}
                regionId={regionValue}
                areaId={areaValue}
                emptyOption={{
                  id: 'locationId',
                  value: '',
                  label: t('attractions.setup.none'),
                }}
              />
            </SetupFormFieldWrapper>
          )}
        </Box>

        <Box display="flex" flexDirection="column">
          <Typography>{t('attractions.setup.order')}:</Typography>
          {isRegionEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormTextField
                name="regionOrder"
                placeholder={t('attractions.setup.no_order')}
                disabled={isNewAttraction ? false : !isEdit}
                validator={validateNumberValue}
              />
            </SetupFormFieldWrapper>
          )}

          {isAreaEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormTextField
                name="areaOrder"
                placeholder={t('attractions.setup.no_order')}
                disabled={isNewAttraction ? false : !isEdit}
                validator={validateNumberValue}
              />
            </SetupFormFieldWrapper>
          )}

          {isLocationEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormTextField
                name="locationOrder"
                placeholder={t('attractions.setup.no_order')}
                disabled={isNewAttraction ? false : !isEdit}
                validator={validateNumberValue}
              />
            </SetupFormFieldWrapper>
          )}
        </Box>
      </Box>

      {/* <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.most_popular_destination')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="mostPopular"
          placeholder={t('attractions.setup.order')}
          disabled={isNewAttraction ? false : !isEdit}
          validator={validateNumberValue}
        />
      </SetupFormFieldWrapper> */}

      {/* Category section */}
      {/* <Typography fontWeight="500px" fontSize="20px" margin="20px 0 20px">
        {t('attractions.setup.category')}
      </Typography>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.categories')}:
        </SetupFormFieldTitle>
        <SetupFormCategoryMultiselectField
          name="categories"
          optionsData={categoryState.categoryData}
          selectedDestinationId={appState.selectedDestination.id}
          selectedLanguage={appState.selectedLanguage}
          required={statusValue === ACTIVE_STATUS}
          label={t('attractions.setup.categories')}
          disabled={isNewAttraction ? false : !isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.facilities')}:
        </SetupFormFieldTitle>
        <SetupFormCommonMultiselectField
          name="facilities"
          optionsData={facilityState.facilitiesData}
          selectedLanguage={appState.selectedLanguage}
          label={t('attractions.setup.facilities')}
          disabled={isNewAttraction ? false : !isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.tags')}:
        </SetupFormFieldTitle>
        <SetupFormCommonMultiselectField
          name="tags"
          optionsData={tagsState.tagsData}
          selectedLanguage={appState.selectedLanguage}
          label={t('attractions.setup.tags')}
          disabled={isNewAttraction ? false : !isEdit}
        />
      </SetupFormFieldWrapper> */}

      {/* Nearby locations section */}
      {/* <Typography fontWeight="500px" fontSize="20px" margin="20px 0 20px">
        {t('attractions.setup.nearby')}
      </Typography>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.nearby')}:
        </SetupFormFieldTitle>
        <SetupFormCommonMultiselectField
          name="nearby"
          optionsData={getAttractionsWithSameRegionIdWithoutCurrent(
            salesPointsReducer.pointList,
            salesPointsReducer.currentPoint,
            regionValue
          )}
          selectedLanguage={appState.selectedLanguage}
          label={t('attractions.setup.nearby')}
          hasAttractionsList
          disabled={isNewAttraction ? false : !isEdit}
        />
      </SetupFormFieldWrapper> 

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.similar')}:
        </SetupFormFieldTitle>
        <SetupFormCommonMultiselectField
          name="similar"
          optionsData={getAttractionsWithSameRegionIdWithoutCurrent(
            salesPointsReducer.pointList,
            salesPointsReducer.currentPoint,
            regionValue
          )}
          selectedLanguage={appState.selectedLanguage}
          label={t('attractions.setup.similar')}
          hasAttractionsList
          disabled={isNewAttraction ? false : !isEdit}
        />
      </SetupFormFieldWrapper> */}

      {/* Contact section */}
      <Typography fontWeight="500px" fontSize="20px" margin="20px 0 20px">
        {t('attractions.setup.contact')}
      </Typography>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.address')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="address"
          disabled={isNewAttraction ? false : !isEdit}
          placeholder={t('attractions.setup.no_address')}
          validator={validateTextFieldValue}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.phone')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="phone"
          placeholder={t('attractions.setup.no_phone')}
          disabled={isNewAttraction ? false : !isEdit}
          validator={validateTextFieldValue}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.website')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="website"
          placeholder={t('attractions.setup.no_website')}
          validator={validateTextFieldValue}
          disabled={isNewAttraction ? false : !isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.email')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="email"
          placeholder={t('attractions.setup.no_email')}
          validator={validateTextFieldValue}
          isEmail
          disabled={isNewAttraction ? false : !isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.map_latitude')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="mapLatitude"
          placeholder={t('attractions.setup.no_coordinates')}
          disabled={isNewAttraction ? false : !isEdit}
          validator={validateTextFieldValue}
          customOnChange={handleChangeMapLatitude}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.map_longitude')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="mapLongtitude"
          placeholder={t('attractions.setup.no_coordinates')}
          disabled={isNewAttraction ? false : !isEdit}
          validator={validateTextFieldValue}
          customOnChange={handleChangeMapLongtitude}
        />
      </SetupFormFieldWrapper>

      <Box mt="60px">
        <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAP_KEY}`}
          loadingElement={<div style={{ height: `250px` }} />}
          containerElement={<div style={{ height: `420px` }} />}
          mapElement={<div style={{ height: `350px` }} />}
          handleUpdateCoordinates={handleUpdateCoordinates}
          coordinates={coordinates}
        />
      </Box>
      <ConfirmationDialog
        showAlert={showAlert}
        handleClose={handleClose}
        handleCloseSuccess={handleDeleteAttraction}
        text={t('attractions.setup.delete_point')}
      />
    </form>
  );
};

export default SetupForm;
