export const mapAttractionListToOptionList = (attractionList) => {
  if (!attractionList) return [];

  const attractionOptionList = attractionList.map((company) => company.id);

  return attractionOptionList;
};

export const getAttractionOptionLabel = (attractionList) => {
  return (attractionId) => {
    if (!attractionId) return '';

    for (let i = 0; i < attractionList.length; i++) {
      if (attractionList[i].id === attractionId) return attractionList[i].name;
    }
  };
};

export const getAverageReviewRating = (reviews) => {
  const ratingSum = reviews.reduce((acc, current) => acc + current.rating, 0);

  const averageRating = ratingSum / reviews.length;

  return isNaN(averageRating) ? 0 : averageRating;
};
