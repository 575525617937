export const adminCompanyName = '*** COOLPASS ***';
export const roleAdministrator = 'Administrator';
export const userRoles = {
  Administrator: 'admin',
  SystemManager: 'systemmanager',
  AttractionManager: 'attraction',
  AttractionManagerPrimary: 'attractionprimary',
  RegionalManager: 'regionalmanager'
};

export const REGIONAL_MANAGER_ROLE = "RegionalManager";
export const ADMIN_ROLE_LIST = ['Administrator', 'SystemManager'];
export const MANAGER_ROLE_LIST = ['AttractionManager', 'AttractionManagerPrimary'];

export const ADMIN_ROLE_LIST_APP = ['admin', 'systemmanager'];
export const MANAGER_ROLE_LIST_APP = ['attraction', 'attractionprimary'];

export const ATTRACTION_MANAGER = 'AttractionManager';
export const ATTRACTION_MANAGER_PRIMARY = 'AttractionManagerPrimary';
export const ATTRACTION_MANAGER_PRIMARY_SPACED = 'Attraction Manager Primary';

export const ID_REGION = 'idRegion';
export const ID_AREA = 'idArea';
export const ID_ATTRACTION = 'idAttraction';
export const ID_CATEGORY = 'idCategory';
export const SALES_POINT_ID = 'salesPointId';
export const DEFAULT_LANGUAGE = 'cs';
export const NEW_USER_STATUS = 'Inactive';

export const DEFAULT_USER_OBJECT = {
  status: NEW_USER_STATUS,
  name: '',
  email: '',
  company: null,
  role: '',
  isAllAttractionsAndPoints: false,
  attractions: [],
  salesPoints: [],
  categories: [],
  regions: [],
  areas: [],
  language: 'en',
};

export const USER_FORM_NAME = 'UserForm';
