import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TodayIcon from '@mui/icons-material/Today';

import { DealDateTextField, DealFormDateField } from '../styled';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  dateFieldInputDisabled: {
    '& .MuiOutlinedInput-input': {
      cursor: 'default',
    },

    '& .MuiInputAdornment-root': {
      cursor: 'default',
    },
  },
});

const DealDateField = ({ name, disabled, rules, minDate }) => {
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DealFormDateField
            inputFormat="dd.MM.yyyy"
            onChange={(event) => onChange(event)}
            value={value}
            disabled={disabled}
            mask={'__.__.____'}
            showToolbar={false}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onAccept={() => setIsOpen(false)}
            onClose={() => setIsOpen(false)}
            minDate={minDate}
            renderInput={(params) => (
              <DealDateTextField
                size="small"
                placeholder={t('date_placeholder')}
                {...params}
                className={clsx(disabled && classes.dateFieldInputDisabled)}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      disablePointerEvents={disabled}
                      position="start"
                      onClick={() => setIsOpen(true)}
                    >
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DealDateField;
