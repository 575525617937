import { connect } from 'react-redux';
import { compose } from 'redux';
import SettingsPage from './SettingsPage';

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  }
};

const SettingsPageContainer = compose(
  connect(mapStateToProps, null),
)(SettingsPage);

export default SettingsPageContainer;