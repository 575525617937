import { connect } from 'react-redux';
import { compose } from 'redux';

import MyProfilePage from './MyProfilePage';
import { getCompaniesListThunk } from '../../redux/usersReducers/company/companyActions';
import {
  getCurrentUserThunk,
  getUserSettingsThunk,
  deleteCurrentUserThunk,
  editUserThunk,
  changeCurrentUserPasswordThunk,
  editCurrentUserThunk,
} from '../../redux/usersReducers/users/userActions';
import { getCategoryThunk } from '../../redux/settingsReducers/categoryReducer';
import { getSmallAttractionsDataThunk } from '../../redux/attractionsReducers/attractions/attractionsActions';
import { getSalesPointShortDataListThunk } from '../../redux/salesPointsReducer/salesPointsActions';

import { SYSTEM_LANGUAGES } from '../../consts/consts';

const mapStateToProps = (state) => {
  const availableLanguages = state.appReducer.globalSettingsData.find(
    (setting) => setting.name.en === SYSTEM_LANGUAGES
  );

  const sortedPoints = state.salesPointsReducer.pointShortDataList.sort(
    function (a, b) {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
  );

  return {
    settingsReducer: state.settingsReducer,
    userReducer: state.userReducer,
    companyReducer: state.companyReducer,
    categoryReducer: state.categoryReducer,
    attractionsReducer: state.attractionsReducer,

    availableLanguages,
    userData: state.userReducer.currentUser,
    selectedLanguage: state.appReducer.selectedLanguage,
    selectedDestinationId: state.appReducer.selectedDestination.id,
    pointList: sortedPoints,
  };
};

const MyProfilePageContainer = compose(
  connect(mapStateToProps, {
    getCompaniesListThunk,
    getCurrentUserThunk,
    getCategoryThunk,
    getUserSettingsThunk,
    getSmallAttractionsDataThunk,
    deleteCurrentUserThunk,
    editUserThunk,
    changeCurrentUserPasswordThunk,
    editCurrentUserThunk,
    getSalesPointShortDataListThunk,
  })
)(MyProfilePage);

export default MyProfilePageContainer;
