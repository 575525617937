import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import {
  ArticlesFormTextField,
  ArticlesFormTextEditorField,
} from '../formFields';

import { LanguageTabPanel } from './styled';
import { ArticlesFormFieldTitle, ArticlesFormFieldWrapper } from '../../styled';

import {
  ARTICLES_TITLE_FIELD_MAX_LENGTH,
  ARTICLES_TEXT_FIELD_MAX_LENGTH,
  ARTICLES_TEXT_FIELD_ROWS,
} from '../../constants';

const TabPanel = (props) => {
  const { fieldArrayName, activeTabId, tabId, disabled, ...other } = props;

  const { t } = useTranslation();

  const { control } = useFormContext();

  const { fields } = useFieldArray({
    name: fieldArrayName,
    control,
  });

  const isActiveTab = activeTabId === tabId;

  return (
    <LanguageTabPanel
      role="tabpanel"
      hidden={!isActiveTab}
      id={`simple-tabpanel-${tabId}`}
      aria-labelledby={`simple-tab-${tabId}`}
      {...other}
    >
      {isActiveTab && (
        <Box sx={{ p: 3 }}>
          {fields.map((field, index) => {
            return (
              <Box id={field.id} key={field.id}>
                <ArticlesFormFieldWrapper>
                  <ArticlesFormFieldTitle>
                    {t('articles.formFieldLabels.title')}
                  </ArticlesFormFieldTitle>
                  <ArticlesFormTextField
                    name={`${fieldArrayName}.${index}.title`}
                    maxLength={ARTICLES_TITLE_FIELD_MAX_LENGTH}
                    disabled={disabled}
                    required
                  />
                </ArticlesFormFieldWrapper>
                <ArticlesFormFieldWrapper>
                  <ArticlesFormFieldTitle>
                    {t('articles.formFieldLabels.text')}
                  </ArticlesFormFieldTitle>
                  <ArticlesFormTextEditorField
                    name={`${fieldArrayName}.${index}.text`}
                    maxLength={ARTICLES_TEXT_FIELD_MAX_LENGTH}
                    disabled={disabled}
                    multiline
                    rows={ARTICLES_TEXT_FIELD_ROWS}
                  />
                </ArticlesFormFieldWrapper>
              </Box>
            );
          })}
        </Box>
      )}
    </LanguageTabPanel>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  tabId: PropTypes.string.isRequired,
  activeTabId: PropTypes.string.isRequired,
};

export default TabPanel;
