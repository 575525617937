import { TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/system';

export const UserTableHeadRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const UserTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  height: 57px;
  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    cursor: pointer;
    background-color: #ffa94c;
  }
`;

export const UserTableContentCell = styled(TableCell)`
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
`;
