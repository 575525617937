import {
  DEFAULT_EMAIL_FIELD_MAX_LENGTH,
  DEFAULT_PHONE_FIELD_MAX_LENGTH,
  DEFAULT_TEXT_FIELD_MAX_LENGTH,
} from '../../../../consts/validation';

export function requiredCallback(message) {
  return (value) => {
    return !value ? message : undefined;
  };
}

export function required(value) {
  return !value ? '* Required' : undefined;
}

export function isNotEmpty(value) {
  return value && !value.trim().length ? 'Must be not empty' : undefined;
}

export function ListIsNotEmpty(value) {
  if (!value === null) {
    return !value.length ? 'Must be not empty' : undefined;
  }
  return 'Must be not empty';
}

export const email = (value) => {
  const trimmedValue = value?.trim();
  return trimmedValue && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(trimmedValue)
    ? 'Invalid email address'
    : undefined;
};

export const onlyNumber = (value) =>
  value && isNaN(Number(value)) ? 'Enter only number' : undefined;
export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);
export const maxLengthEmail = maxLength(DEFAULT_EMAIL_FIELD_MAX_LENGTH);
export const maxLengthPhone = maxLength(DEFAULT_PHONE_FIELD_MAX_LENGTH);

export const parseToNumber = (value) => {
  return value.replace(/\D/g, '');
};

export const cutToIntLength = (value) => {
  return value.slice(0, 9);
};

export const noSpaces = (value) => {
  return value && !/\S/.test(value) ? '* Required' : undefined;
};