import { Field } from 'redux-form';

import {
  PersonItemContainer,
  PersonItemFieldWrapper,
  PersonItemTextField,
} from './styled';
import {
  email,
  maxLength250,
  maxLengthEmail,
  maxLengthPhone,
} from '../forms/validation';

const ContactPersonItem = ({ person, disabled, onDelete }) => {
  return (
    <PersonItemContainer>
      <PersonItemFieldWrapper>
        <Field
          component={PersonItemTextField}
          name={`${person}.name`}
          size="small"
          disabled={disabled}
          validate={[maxLength250]}
        />
      </PersonItemFieldWrapper>
      <PersonItemFieldWrapper>
        <Field
          component={PersonItemTextField}
          name={`${person}.email`}
          size="small"
          disabled={disabled}
          validate={[email, maxLengthEmail]}
        />
      </PersonItemFieldWrapper>
      <PersonItemFieldWrapper>
        <Field
          component={PersonItemTextField}
          name={`${person}.phone`}
          size="small"
          disabled={disabled}
          validate={[maxLengthPhone]}
        />
      </PersonItemFieldWrapper>
    </PersonItemContainer>
  );
};

export default ContactPersonItem;
