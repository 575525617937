import { useTranslation } from 'react-i18next';

import { Table, TableContainer, TableBody, TableCell } from '@mui/material';

import { FactsTableHead, FactsTableHeadRow } from '../styled';

const FactsTable = ({ children }) => {
  const { t } = useTranslation();

  const tableHeaderLables = t('facts.tableLabels', {
    returnObjects: true,
  });

  return (
    <TableContainer>
      <Table>
        <FactsTableHead>
          <FactsTableHeadRow>
            {tableHeaderLables.map((label) => (
              <TableCell key={label}>{label}</TableCell>
            ))}
          </FactsTableHeadRow>
        </FactsTableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default FactsTable;
