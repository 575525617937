import {
  SET_ERROR,
  SET_LOG_LIST,
  SET_IS_LOADING,
  SET_PAGE_SIZE,
  SET_PAGE_NUMBER,
  SET_TOTAL_COUNT,
  SET_TOTAL_PAGES,
} from './logsActions';

const initialState = {
  logList: [],
  isLoading: false,
  paginationSettings: {
    totalCount: 0,
    totalPages: 0,
    pageSize: 1000,
    pageNumber: 0,
  },
};

const logsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOG_LIST:
      return {
        ...state,
        logList: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_PAGE_SIZE:
      return {
        ...state,
        paginationSettings: {
          ...state.paginationSettings,
          pageSize: action.payload,
        },
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        paginationSettings: {
          ...state.paginationSettings,
          pageNumber: action.payload,
        },
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        paginationSettings: {
          ...state.paginationSettings,
          totalCount: action.payload,
        },
      };
    case SET_TOTAL_PAGES:
      return {
        ...state,
        paginationSettings: {
          ...state.paginationSettings,
          totalPages: action.payload,
        },
      };
    default:
      return state;
  }
};

export default logsReducer;
