import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material';

const ConfirmationDialog = ({
  showAlert,
  handleClose,
  handleCloseSuccess,
  text,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={showAlert}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ padding: '25px 70px' }}>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={handleClose}>{t('no')}</Button>
        <Button onClick={handleCloseSuccess}>{t('yes')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
