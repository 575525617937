import { connect } from 'react-redux';
import { compose } from 'redux';
import TranslationPage from './TranslationPage';
import {
  getTranslationsSearchThunk,
  setCurrentKey,
  getCategoryDataThunk,
  addKeyThunk,
  editKeyThunk,
  deleteKeyThunk,
} from '../../redux/translationsReducer/translationsActions';

const mapStateToProps = (state) => {
  return {
    appVersionsReducer: state.appVersionsReducer,
    appReducer: state.appReducer,
    translationsReducer: state.translationsReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    getTranslationsSearchThunk,
    setCurrentKey,
    getCategoryDataThunk,
    addKeyThunk,
    editKeyThunk,
    deleteKeyThunk,
  })
)(TranslationPage);
