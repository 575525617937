import axios from 'axios';

import { PRODUCTS_BASE_URL } from '../config';

import authService from '../auth/AuthorizeService';

export const getReports = async ({ attractionId, from, to, isDetailed }) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${PRODUCTS_BASE_URL}/api/Reports?attractionId=${attractionId}&from=${from}&to=${to}&isDetailed=${isDetailed}`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getReportPdfLink = async (reportData, currentLanguage) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${PRODUCTS_BASE_URL}/api/Reports/generatePdf`;

  return await axios
    .post(targetUrl, reportData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': currentLanguage,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
