import { useSelector } from 'react-redux';

import NotificationForm from '../../../attractions/notificationsTab/forms/NotificationForm';
import { NOTIFICATION_STATUS } from '../../../../consts/consts';

const NotificationsLogFormContainer = ({ logData }) => {
  const destinationLanguageList = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );
  const { globalSettingsData, selectedLanguage } = useSelector(
    (state) => state.appReducer
  );
  const { attractionsSmallData } = useSelector(
    (state) => state.attractionsReducer
  );

  const statusOptions = globalSettingsData.find(
    (item) => item.name.en === NOTIFICATION_STATUS
  );

  const mappedDestinationLanguages = destinationLanguageList?.map((lang) => {
    return {
      id: lang,
      value: lang,
    };
  });

  const currentAttractionName = attractionsSmallData.find(
    (attr) => attr.id === logData.attractionId
  );

  return (
    <NotificationForm
      languages={mappedDestinationLanguages}
      isAddingItem={false}
      isFormEdit={false}
      currentItem={logData.entity}
      availableStatuses={[statusOptions]}
      selectedLanguage={selectedLanguage}
      attractionName={currentAttractionName?.name}
      hideControlPanel
    />
  );
};

export default NotificationsLogFormContainer;
