import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import DidYouKnowTab from './DidYouKnowTab';

import { CONTENT_LANGUAGES } from '../../../consts/consts';
import { sortLanguageListInDestinationSettingsOrder } from '../../../helpers/sortLanguageListInDestinationSettingsOrder';
import { getFactsListThunk } from '../../../redux/publishingReducers/factsReducer/factsActions';

const DidYouKnowTabContainer = ({
  languageList,
  factsList,
  getFactsListThunk,
  isLoading,
  currentDestinationId,
}) => {
  const [activeRowId, setActiveRowId] = useState(-1);

  const handleRowExpand = (id) => {
    setActiveRowId((currentValue) => (currentValue !== id ? id : -1));
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };

  useEffect(() => {
    currentDestinationId && getFactsListThunk(currentDestinationId);
  }, [getFactsListThunk, currentDestinationId]);

  return (
    <DidYouKnowTab
      onRowExpand={handleRowExpand}
      activeRowId={activeRowId}
      setActiveRowId={setActiveRowId}
      languageList={languageList}
      factsList={factsList}
      isLoading={isLoading}
      resetActiveRow={resetActiveRow}
    />
  );
};

const mapStateToProps = (state) => {
  const languageList = state.appReducer.globalSettingsData.find(
    (item) => item.name.en === CONTENT_LANGUAGES
  );

  const selectedDestinationLanguages =
    state.appReducer.selectedDestination.languages;

  const destinationLanguageList = {
    ...languageList,
    parameters: sortLanguageListInDestinationSettingsOrder(
      languageList.parameters,
      selectedDestinationLanguages
    ),
  };

  const mappedDestinationgLanguages = destinationLanguageList.parameters.map(
    (lang) => lang.value
  );

  return {
    languageList: mappedDestinationgLanguages,
    factsList: state.factsReducer.factsList,
    isLoading: state.factsReducer.isLoading,
    currentDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, {
  getFactsListThunk,
})(DidYouKnowTabContainer);
