import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
} from '@mui/material';
import ListIcon from '@mui/icons-material/List';

const PriceList = (props) => {
  const { setShowPriceField, getCurrentPriceThunk, priceState } = props;

  const { t } = useTranslation();

  const handleListItemClick = (item) => {
    setShowPriceField(true);
    getCurrentPriceThunk(item.attractionId, item.id);
  };

  return !priceState.pricesData.length ? (
    <Box component="div" sx={{ margin: '125px 0 0 5px' }}>
      {t('attractions.price.no_price')}
    </Box>
  ) : (
    <TableContainer component={Paper} sx={{ maxWidth: 650 }}>
      <Table sx={{ maxWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              padding="normal"
              align="center"
              sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
            >
              {t('attractions.price.view')}
            </TableCell>
            <TableCell
              padding="normal"
              align="center"
              sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
            >
              {t('attractions.price.start_data')}
            </TableCell>
            <TableCell padding="normal" align="center">
              {t('attractions.price.end_data')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {priceState.pricesData.map((item) => {
            const endDate = item.relevancePeriod.validTo
              ? format(
                  new Date(Date.parse(item.relevancePeriod.validTo)),
                  'dd.MM.yyyy'
                )
              : t('attractions.price.open');
            return (
              <TableRow key={item.id}>
                <TableCell
                  align="center"
                  padding="none"
                  sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                  component="th"
                  scope="row"
                >
                  <IconButton
                    size="medium"
                    onClick={() => handleListItemClick(item)}
                  >
                    <ListIcon fontSize="inherit" />
                  </IconButton>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {format(
                    new Date(Date.parse(item.relevancePeriod.validFrom)),
                    'dd.MM.yyyy'
                  ) || ''}
                </TableCell>
                <TableCell align="center">{endDate}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PriceList;
