import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import FactsFormLanguageTabs from '../languageTabs/FactsFormLanguageTabs';

import {
  ControlPanelContainer,
  FactsFormButton,
  FactsFormSubmitButton,
} from '../../styled';

import ConfirmationDialog from '../../../../../components/confirmationDialog/ConfirmationDialog';

const EditFactForm = ({
  languageList,
  isLoading,
  onSubmit,
  onCancel,
  isInEditMode,
  handleEditMode,
  handleDeleteFact,
  showAlert,
  setShowAlert,
}) => {
  const { t } = useTranslation();
  const [activeTabId, setActiveTabId] = useState(languageList[0]);

  const handleLanguageTabChange = (_, newValue) => setActiveTabId(newValue);

  const { handleSubmit } = useFormContext();

  return (
    <form>
      <ControlPanelContainer>
        <FactsFormButton
          onClick={onCancel}
          label={t('cancel')}
          disabled={!isInEditMode}
        />
        <FactsFormButton
          onClick={handleEditMode}
          label={t('edit')}
          disabled={isInEditMode}
        />
        <FactsFormButton
          label={t('delete')}
          onClick={() => setShowAlert(true)}
        />

        <FactsFormSubmitButton
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          label={t('save')}
          disabled={!isInEditMode}
        />
      </ControlPanelContainer>
      <FactsFormLanguageTabs
        activeTabId={activeTabId}
        onLanguageTabChange={handleLanguageTabChange}
        languages={languageList}
        disabled={!isInEditMode}
      />

      <ConfirmationDialog
        showAlert={showAlert}
        handleClose={() => setShowAlert(false)}
        handleCloseSuccess={handleDeleteFact}
        text={t('facts.delete_fact_title')}
      />
    </form>
  );
};

export default EditFactForm;
