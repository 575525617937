import { styled } from '@mui/system';
import { Box, Typography, TextField } from '@mui/material';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import {
  ReduxTextField,
  ReduxSelect,
} from '../../../../components/reduxFormComponents/reduxFormComponents';
import {
  FormButton,
  SaveButton,
} from '../../../../components/buttons/FormButtons';

export const CompanyFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;

export const CompanyFormCombinedFieldWrapper = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
`;

export const CompanyFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
  width: 100%;
`;

export const CompanyFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const CompanyFormFieldShortTitle = styled(Typography)`
  max-width: 215px;
  min-width: fit-content;
  margin-right: 16px;
`;

export const CompanyFormTextField = styled(ReduxTextField)`
  width: 100%;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }

  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const CompanyFormSelectField = styled(ReduxSelect)`
  max-width: 215px;
  width: 100%;
  margin-left: 0;
  margin-top: 0;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9) !important;
  }
`;

export const CompanyFormDateField = styled(TextField)`
  & .MuiOutlinedInput-root {
    width: 100%;
    max-width: 215px;
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const CompanyFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 14px;
`;

export const CompanyFormHeader = styled(Box)`
  display: flex;
`;

export const CompanyFormHeaderLabel = styled(Box)`
  display: flex;
  width: 100%;
`;

export const ManagedAttractionsFormContainer = styled(Box)`
  margin-top: 50px;
`;
export const ManagedAttractionsFormControlPanel = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 16px;
`;

export const AttractionsContactListContainer = styled(Box)`
  max-height: 500px;
`;

export const ManagedAttractionsFormFieldWrapper = styled(Box)`
  display: flex;
  margin-bottom: 10px;
`;

export const ManagedAttractionsFormCellWrapper = styled(Box)`
  margin-right: 10px;
  width: 100%;
`;

export const ManagedAttractionsFormRowTitle = styled(Typography)`
  width: 100%;
`;

export const StyledClearIcon = styled(ClearIcon)`
  &.MuiSvgIcon-root {
    width: 100%;
    height: 100%;
  }
`;

export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    width: 30px;
    height: 30px;
    padding: 0;
    content: '';
    margin: 10px;
  }
`;

export const CompanyFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const CompanyFormButton = styled(FormButton)`
  max-width: fit-content;
`;
