import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Button, Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import LanguageTabs from '../../../components/languageTabs/LanguageTabs';
import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import { TagFormControlPanel } from './styled';

const useStyles = makeStyles({
  form: {
    width: '100%',
    '& .MuiTextField-root': {
      marginBottom: '0px',
    },
    '& .MuiButton-root': {
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px;',
    },
    maxWidth: '800px',
  },
});

let TagsForm = (props) => {
  const { onCancel } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const onEditTag = () => {
    props.setIsEdit(true);
  };

  const onDeleteTag = () => {
    props.setShowAlert(true);
  };

  const handleClose = () => {
    props.setIsEdit(false);
    props.setShowAlert(false);
  };

  return (
    <form className={classes.form} onSubmit={props.handleSubmit}>
      <Box
        margin="0 0 25px 0"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          fontWeight="500px"
          fontSize="20px"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {props.updateTag
            ? props.tagsState.currentTag.name[props.selectedLanguage]
            : `${t('settings_page.tags.new_tag')}`}
        </Typography>

        <TagFormControlPanel>
          <Button
            variant="outlined"
            onClick={onCancel}
            type="button"
            disabled={props.addTag ? false : !props.isEdit}
          >
            {t('cancel')}
          </Button>
          {props.updateTag && (
            <>
              <Button
                variant="outlined"
                onClick={onEditTag}
                type="button"
                disabled={props.isEdit}
              >
                {t('settings_page.tags.edit_button')}
              </Button>
              <Button
                variant="outlined"
                onClick={onDeleteTag}
                type="button"
                disabled={props.isSave}
              >
                {t('settings_page.tags.delete_button')}
              </Button>
            </>
          )}
          <LoadingButton
            loading={props.isSave}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{ color: '#fff' }}
            disabled={props.addTag || props.isEdit ? false : true}
            type="submit"
          >
            {t('settings_page.tags.save_button')}
          </LoadingButton>
        </TagFormControlPanel>
      </Box>
      <Box>
        <LanguageTabs
          tagsState={props.tagsState}
          addElement={props.addTag}
          updateElement={props.updateTag}
          isEdit={props.isEdit}
          placeholder={t('settings_page.tags.new_tag')}
          languages={props.languages}
          error={props.error}
        />
      </Box>

      <ConfirmationDialog
        showAlert={props.showAlert}
        handleClose={handleClose}
        handleCloseSuccess={props.handleCloseSuccess}
        text={t('settings_page.tags.delete_tag')}
      />
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.isEdit) {
    return { initialValues: { ...state.tagsReducer.currentTag.name } };
  } else {
    return { initialValues: {} };
  }
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'Tags', enableReinitialize: true })(TagsForm)
);
