import { connect } from 'react-redux';
import { compose } from 'redux';

import NotificationTab from './NotificationTab';

import {
  getAttractionNotificationsListThunk,
  editAttractionNotificationThunk,
  deleteAttractionNotificationThunk,
  getFilteredAttractionNotificationsThunk,
} from '../../../redux/attractionsReducers/notificationReducer/notificationActions';

const mapStateToProps = (state) => {
  return {
    settingsState: state.settingsReducer,
    appReducer: state.appReducer,
    notificationReducer: state.notificationReducer,
    currentAttraction: state.attractionsReducer.currentAttraction,
    availableAttractions: state.attractionsReducer.attractionsSmallData,
  };
};

const NotificationTabContainer = compose(
  connect(mapStateToProps, {
    getAttractionNotificationsListThunk,
    editAttractionNotificationThunk,
    deleteAttractionNotificationThunk,
    getFilteredAttractionNotificationsThunk,
  })
)(NotificationTab);

export default NotificationTabContainer;
