import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    button: {
        color: '#fff',
        height: "36.5px"
    }
})

const SaveButton = ({label, disabled, isLoading, ...props}) => {
    const classes = useStyles();
    return (
        <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
        className={classes.button}
        disabled={disabled} 
        type="submit"
        {...props}
        >
            {label}
        </LoadingButton>
    )
}

export default SaveButton;