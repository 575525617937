import {
  SET_TARGET_LOCATION,
  SET_SHOW_UNSAVED_CHANGES_ALERT,
  SET_IS_ROUTE_CHANGE_CONFIRMED,
} from './routeChangeActions';

const initialState = {
  targetLocation: null,
  showUnsavedChangesAlert: false,
  isRouteChangeConfirmed: false,
};

const routeChangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TARGET_LOCATION: {
      return {
        ...state,
        targetLocation: action.payload.data,
      };
    }
    case SET_SHOW_UNSAVED_CHANGES_ALERT: {
      return {
        ...state,
        showUnsavedChangesAlert: action.payload.data,
      };
    }
    case SET_IS_ROUTE_CHANGE_CONFIRMED: {
      return {
        ...state,
        isRouteChangeConfirmed: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default routeChangeReducer;
