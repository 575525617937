import { authRoutes } from './authRoutes';
import { useRoutes } from "react-router-dom";

import { routes } from './routes';
import { useEffect, useState } from 'react';

import { getDecodedToken } from '../auth/AuthorizeToken';

const AppRoutes = ({initialized, isLoading, ...props}) => {
    const [role, setRole] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            const responseToken = await getDecodedToken();
            const filteredRole = Array.isArray(responseToken.role) 
                ? responseToken.role.filter(role => role !== 'visitor')[0]
                : responseToken.role === 'visitor' ? null : responseToken.role;
            setRole(filteredRole);
        };

        fetchToken();
    }, []);

    const filteredRoutes = routes.filter(route => {
        return !route.showForRoles || route.showForRoles.includes(role);
    });

    let Routes = [...authRoutes]
    if(initialized && isLoading)
        Routes.push(...filteredRoutes)
    const AppRoutes = useRoutes(Routes);

    return AppRoutes;
}

export default AppRoutes;