import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AdvertContentForm from './AdvertContentForm';
import AdvertContentFormControlPanel from './controlPanel/AdvertContentFormControlPanel';
import useAPIError from '../../../../apIErrorProvider/useAPIError';
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';

import { uploadImageFile } from '../../../../api/publishing/mainImageApi';
import {
  getAdvertContentListThunk,
  updateAdvertContentThunk,
  deleteAdvertContentThunk,
} from '../../../../redux/publishingReducers/advertContentReducer/advertContentActions';

import { removeKeysWithEmptyValueArrays } from '../../../../helpers/translationObjectUtils';
import { formatLanguageFieldsData } from '../utils';
import { sortLanguageListInDestinationSettingsOrder } from '../../../../helpers/sortLanguageListInDestinationSettingsOrder';

import {
  ACTIVITY_STATUS,
  REDIRECTION_TARGETS,
} from '../../../../consts/consts';

const AdvertContentFormContainer = (props) => {
  const { item, resetActiveRow, languageList } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const currentDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );

  const redirectToOptions = useSelector((state) =>
    state.appReducer?.globalSettingsData.find(
      (item) => item.name.en === REDIRECTION_TARGETS
    )
  );
  const activityStatuses = useSelector((state) =>
    state.appReducer?.globalSettingsData.find(
      (item) => item.name.en === ACTIVITY_STATUS
    )
  );

  const selectedAppLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );

  const selectedDestinationLanguages = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );

  const destinationLanguageList = {
    ...languageList,
    parameters: sortLanguageListInDestinationSettingsOrder(
      languageList.parameters,
      selectedDestinationLanguages
    ),
  };

  const languageFormFieldArrays = useMemo(
    () =>
      languageList.parameters.reduce((acc, lang) => {
        const { value } = lang;

        acc[value] = [
          {
            subject: item.subject[value] || '',
            text: item.text[value] || '',
          },
        ];

        return acc;
      }, {}),
    [item.subject, item.text, languageList.parameters]
  );

  const initialFormState = {
    status: item.status,
    redirectTo: item.redirectTo,
    date: item.date,
    mobileImage: [item.mobileImage],
    webImage: [item.webImage],
    ...languageFormFieldArrays,
  };

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...initialFormState,
    },
  });

  const { reset, handleSubmit } = formMethods;

  const resetFormValues = () => {
    reset(initialFormState, { keepDefaultValues: true });
  };

  const handleFormSubmit = async (formData) => {
    setShowSaveAnimation(true);
    setIsInEditMode(false);

    if (typeof formData.mobileImage[0] === 'string' && typeof formData.webImage[0] === 'string') {
      const fieldsWithTranslations = formatLanguageFieldsData(formData);

      const updatedAdvertContent = {
        destinationId: currentDestinationId,
        status: formData.status,
        redirectTo: formData.redirectTo,
        date: formData.date,
        id: item.id,
        mobileImage: formData.mobileImage[0],
        webImage: formData.webImage[0],
        subject: removeKeysWithEmptyValueArrays(fieldsWithTranslations.subject),
        text: removeKeysWithEmptyValueArrays(fieldsWithTranslations.text),
      };

      dispatch(
        updateAdvertContentThunk(updatedAdvertContent.id, updatedAdvertContent)
      )
        .then(() => {
          setShowSaveAnimation(false);
          dispatch(getAdvertContentListThunk(currentDestinationId));
          resetActiveRow();
          addError(
            `${t('advertWindow.notifications.update_success')}`,
            'Success'
          );
        })
        .catch((e) => {
          setShowSaveAnimation(false);
          resetFormValues();
          addError(
            `${t('advertWindow.notifications.update_error')}`,
            'Error',
            e
          );
        });
    } else {
      const imageFormData = new FormData();
      
      const fieldsWithTranslations = formatLanguageFieldsData(formData);
      
      const updatedAdvertContent = {
        destinationId: currentDestinationId,
        status: formData.status,
        redirectTo: formData.redirectTo,
        date: formData.date,
        id: item.id,
        mobileImage: formData.mobileImage[0],
        webImage: formData.webImage[0],
        subject: removeKeysWithEmptyValueArrays(
            fieldsWithTranslations.subject
        ),
        text: removeKeysWithEmptyValueArrays(
            fieldsWithTranslations.text
        ),
      };
      
      if (typeof formData.mobileImage[0] !== 'string'){
        imageFormData.append('files', formData.mobileImage[0]?.file);

        const mobileImageUploadResponse = await uploadImageFile(imageFormData, 'Advert')

        updatedAdvertContent.mobileImage = mobileImageUploadResponse.data[0]

        imageFormData.delete('files')
      }
      if (typeof formData.webImage[0] !== 'string'){
        imageFormData.append('files', formData.webImage[0]?.file);

        const webImageUploadResponse = await uploadImageFile(imageFormData, 'Web');

        updatedAdvertContent.webImage = webImageUploadResponse.data[0]

        imageFormData.delete('files')
      }

      return dispatch(
          updateAdvertContentThunk(
              updatedAdvertContent.id,
              updatedAdvertContent
          )
      )
      .then(() => {
        setShowSaveAnimation(false);
        resetActiveRow();
        dispatch(getAdvertContentListThunk(currentDestinationId));
        addError(
            `${t('advertWindow.notifications.update_success')}`,
            'Success'
        );
      })
      .catch((e) => {
        setShowSaveAnimation(false);
        resetFormValues();
        addError(
            `${t('advertWindow.notifications.update_error')}`,
            'Error',
            e
        );
      });
    }
  };

  const handleFormDelete = (itemId) => {
    dispatch(deleteAdvertContentThunk(itemId))
      .then(() => {
        addError(
          `${t('advertWindow.notifications.delete_success')}`,
          'Success'
        );
        resetActiveRow();
        dispatch(getAdvertContentListThunk(currentDestinationId));
      })
      .catch((e) => {
        addError(`${t('advertWindow.notifications.delete_error')}`, 'Error', e);
      });
  };

  const handleFormEdit = () => {
    setIsInEditMode(true);
  };

  const handleFormCancel = () => {
    resetFormValues();
    setIsInEditMode(false);
  };

  const handleDeleteButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handlePopupConfirm = () => {
    setIsPopupOpen(false);
    setIsInEditMode(false);
    setShowSaveAnimation(false);
    handleFormDelete(item.id);
  };

  return (
    <FormProvider {...formMethods}>
      <AdvertContentFormControlPanel
        isInEditMode={isInEditMode}
        onSubmit={handleSubmit(handleFormSubmit)}
        onCancel={handleFormCancel}
        onEdit={handleFormEdit}
        onDelete={handleDeleteButtonClick}
        showSaveAnimation={showSaveAnimation}
      />
      <AdvertContentForm
        isInEditMode={isInEditMode}
        languageList={destinationLanguageList}
        redirectToOptions={redirectToOptions}
        advertContentStatuses={activityStatuses}
        selectedLanguage={selectedAppLanguage}
      />

      <ConfirmationDialog
        showAlert={isPopupOpen}
        handleClose={handleClosePopup}
        handleCloseSuccess={handlePopupConfirm}
        text={t('advertWindow.delete_item_title')}
      />
    </FormProvider>
  );
};

export default AdvertContentFormContainer;
