import { connect } from 'react-redux';
import { compose } from 'redux';

import Setup from './Setup';

import {
  createSalesPointThunk,
  getSalesPointListThunk,
  updateSalesPointThunk,
  deleteSalesPointThunk,
  setAddElement,
  setCurrentPoint,
  getSalesPointThunk,
} from '../../../redux/salesPointsReducer/salesPointsActions';

const mapStateToProps = (state) => {
  return {
    settingsReducer: state.settingsReducer,
    appReducer: state.appReducer,
    categoryReducer: state.categoryReducer,
    facilitiesReducer: state.facilitiesReducer,
    tagsReducer: state.tagsReducer,
    predefinedReducer: state.predefinedReducer,
    companyReducer: state.companyReducer,
    authState: state.authReducer,
    salesPointsReducer: state.salesPointsReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    createSalesPointThunk,
    getSalesPointListThunk,
    getSalesPointThunk,
    updateSalesPointThunk,
    deleteSalesPointThunk,
    setAddElement,
    setCurrentPoint,
  })
)(Setup);
