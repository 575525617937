import {
  getProducts,
  updateProduct,
  getProductSettings,
} from '../../api/productsApi';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_IS_DATA_LOADING = 'SET_IS_PRODUCTS_DATA_LOADING';
export const SET_PRODUCT_SETTINGS = 'SET_PRODUCT_SETTINGS';
export const SET_IS_PRODUCT_SETTINGS_LOADING =
  'SET_IS_PRODUCT_SETTINGS_LOADING';

export const setProducts = (data) => {
  return { type: SET_PRODUCTS, payload: { data } };
};

export const setIsDataLoading = (data) => {
  return { type: SET_IS_DATA_LOADING, payload: { data } };
};

export const setProductSettings = (data) => {
  return { type: SET_PRODUCT_SETTINGS, payload: { data } };
};

export const setIsProductSettingsLoading = (data) => {
  return { type: SET_IS_DATA_LOADING, payload: { data } };
};

export const getProductsThunk = (destinationId) => async (dispatch) => {
  dispatch(setIsDataLoading(true));

  const response = await getProducts(destinationId);
  if (response.data) {
    dispatch(setProducts(response.data));
    dispatch(setIsDataLoading(false));
  }
};

export const getProductSettingsThunk = () => async (dispatch) => {
  dispatch(setIsProductSettingsLoading(true));

  const response = await getProductSettings();

  if (response.data) {
    dispatch(setProductSettings(response.data));
    dispatch(setIsProductSettingsLoading(false));
  }
};

export const updateProductThunk = (productId, productData) => async (_) => {
  const response = await updateProduct(productId, productData);

  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
