import React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  topBarSelect: {
    marginRight: '20px',
  },
});

const LanguageSelect = (props) => {
  const { setCurrentLanguage, appState } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const handleLanguageChange = (event) => {
    setCurrentLanguage(event.target.value);
    i18next.changeLanguage(event.target.value);
    // window.location.reload(); // should be reworked on next iterration
  };

  return (
    <Box className={classes.topBarSelect}>
      <FormControl fullWidth size="small">
        <InputLabel color="divider" id="demo-simple-select-label">
          {t('top_bar.destination_language_label')}
        </InputLabel>
        <Select
          color="divider"
          labelId="demo-simple-select-label"
          value={localStorage.getItem('i18nextLng')}
          label={t('top_bar.destination_language_label')}
          onChange={handleLanguageChange}
        >
          {appState?.parameters.map((item) => (
            <MenuItem value={item.value} key={item.id}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageSelect;
