export const publishingPageTabs = [
  {
    name: 'advertWindow.tabTitle',
    path: 'advertisement',
    matchUrls: ['advertisement'],
  },
  {
    name: 'helpTopics.tabTitle',
    path: 'help-topics',
    matchUrls: ['help-topics'],
  },
  {
    name: 'mainImage.tabTitle',
    path: 'home-page',
    matchUrls: ['home-page'],
  },
  {
    name: 'articles.tabTitle',
    path: 'articles',
    matchUrls: ['articles'],
  },
  {
    name: 'facts.tabTitle',
    path: 'did-you-know',
    matchUrls: ['did-you-know'],
  },
];

const firstTabIndex = 0;

export const getOpenedTabIdByUrlParameter = (tabs, location) => {
  if (!location) return firstTabIndex;

  const splitedLocation = location.split('/');
  const tabName = splitedLocation[2];

  if (!tabName) return firstTabIndex;

  const tabId = tabs.findIndex((tab) => tab.matchUrls.includes(tabName));

  if (tabId === -1) return firstTabIndex;

  return tabId;
};
