import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FactsFormStyledTextField } from '../../styled';

import { validateTextFieldValue } from '../../../../../helpers/validateTextFieldValue';

const FactsFormTextField = ({
  name,
  disabled,
  required,
  maxLength,
  ...props
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: required,
          message: t('articles.validation.required'),
        },
        maxLength: {
          value: maxLength,
          message: t('articles.validation.max_length', {
            chars: maxLength,
          }),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FactsFormStyledTextField
          {...props}
          disabled={disabled}
          fullWidth
          size="small"
          onChange={(e) => onChange(validateTextFieldValue(e.target.value))}
          value={value}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default FactsFormTextField;
