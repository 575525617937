import { connect } from 'react-redux';
import { compose } from 'redux';
import FaqPage from './FaqPage';

import { getPredefinedSettingsThunk } from '../../redux/settingsReducers/predefinedReducer';
import { getCategoryFaqListThunk } from '../../redux/faqReducers/categoryFaqReducer';

export default compose(
  connect(null, {
    getPredefinedSettingsThunk,
    getCategoryFaqListThunk,
  })
)(FaqPage);
