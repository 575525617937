import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import DealBenefitField from './dealFormFields/DealBenefitField';
import DealDateField from './dealFormFields/DealDateField';
import DealEndDateCheckboxField from './dealFormFields/DealEndDateCheckboxField';
import DealPercentageField from './dealFormFields/DealPercentageField';
import DealStatusField from './dealFormFields/DealStatusField';
import DealUsesLimitedCheckboxField from './dealFormFields/DealUsesLimitedCheckboxField';
import DealUsesLimitedField from './dealFormFields/DealUsesLimitedField';
import DealFormLanguageTabs from './DealFormLanguageTabs';

import {
  DealFormDateFieldWrapper,
  DealFormFieldTitle,
  DealFormFieldWrapper,
} from './styled';

import { DISCOUNT } from '../consts';

const DealEditForm = ({
  dealStatus,
  item,
  dealStatuses,
  selectedLanguage,
  disabled,
  benefitOptions,
  activeBenefitValue,
  languages,
  handleChangeLanguageTab,
  currentTabId,
  dealStartDateRules,
  dealEndDateRules,
  isEndDateOpenCheckbox,
  usesLimitCheckboxValue,
  dealNumber,
  minDate,
  isDirty,
}) => {
  const { t } = useTranslation();
  return (
    <form aria-label="form">
      <DealFormFieldWrapper>
        <DealFormFieldTitle>
          {t('attractions.deals.number')}:
        </DealFormFieldTitle>
        <DealFormFieldTitle>{dealNumber}</DealFormFieldTitle>
      </DealFormFieldWrapper>
      <DealFormFieldWrapper>
        <DealFormFieldTitle>
          {t('attractions.deals.status')}:
        </DealFormFieldTitle>
        <DealStatusField
          name="dealStatus"
          options={dealStatuses}
          selectedLanguage={selectedLanguage}
          disabled={disabled}
        />
      </DealFormFieldWrapper>

      <DealFormFieldWrapper>
        <DealFormFieldTitle>
          {t('attractions.deals.benefit')}:
        </DealFormFieldTitle>
        <DealBenefitField
          name="benefits"
          options={benefitOptions}
          selectedLanguage={selectedLanguage}
          disabled={disabled}
        />
      </DealFormFieldWrapper>

      {activeBenefitValue === DISCOUNT && (
        <DealFormFieldWrapper>
          <DealFormFieldTitle>
            {t('attractions.deals.percentage')}:
          </DealFormFieldTitle>
          <DealPercentageField name="percentage" disabled={disabled} />
        </DealFormFieldWrapper>
      )}

      <Box display="flex">
        <DealFormLanguageTabs
          dealStatus = {dealStatus}
          item = {item}
          languages={languages}
          handleChangeLanguageTab={handleChangeLanguageTab}
          currentTabId={currentTabId}
          activeBenefitValue={activeBenefitValue}
          disabled={disabled}
        />
      </Box>

      <Box display="flex" flexDirection="column">
        <DealFormDateFieldWrapper>
          <DealFormFieldTitle>
            {t('attractions.deals.start_date')}:
          </DealFormFieldTitle>
          <DealDateField
            name="dealStartDate"
            disabled={disabled}
            rules={dealStartDateRules}
          />
        </DealFormDateFieldWrapper>

        <DealFormDateFieldWrapper>
          <DealFormFieldTitle>
            {t('attractions.deals.end_date')}:
          </DealFormFieldTitle>
          <Box>
            <DealDateField
              name="dealEndDate"
              rules={dealEndDateRules}
              disabled={disabled ? disabled : isEndDateOpenCheckbox}
              minDate={minDate}
            />
            <DealEndDateCheckboxField
              name="isEndDateOpen"
              disabled={disabled}
            />
          </Box>
        </DealFormDateFieldWrapper>

        <DealFormFieldWrapper>
          <DealFormFieldTitle>
            {t('attractions.deals.number_of_uses')}:
          </DealFormFieldTitle>
          <Box display="flex" flexGrow={1}>
            <DealUsesLimitedField
              name="numberOfUses"
              required={disabled ? disabled : usesLimitCheckboxValue}
            />
            <DealUsesLimitedCheckboxField
              name="isUsesLimited"
              disabled={disabled}
            />
          </Box>
        </DealFormFieldWrapper>
      </Box>
    </form>
  );
};

export default DealEditForm;
