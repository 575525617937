import { useState, useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { format, parseISO } from 'date-fns';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import NotificationAccordionTitle from './NotificationAccordionTitle';

import EditNotificationForm from '../forms/editNotificationForm/EditNotificationForm';
import { formatLanguageFieldsData } from '../forms/helpers';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import {
  deleteDestinationNotificationThunk,
  editDestinationNotificationThunk,
  getDestinationNotificationsListThunk,
} from '../../../redux/generalNotificationsReducer/generalNotificationsActions';

import {
  removeKeysWithEmptyValueArrays,
  removeTextEditorValueKeysWithNoPlainText,
} from '../../../helpers/translationObjectUtils';

function GeneralNotification(props) {
  const { t } = useTranslation();
  const { addError } = useAPIError();

  const [isFormEdit, setIsFormEdit] = useState(false);
  const [currentTabId, setCurrentTabId] = useState(props.languages[0]);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const dispatch = useDispatch();

  const { notification, expanded, onExpandChange } = props;

  const parsedStartDate = new Date(Date.parse(props.notification.startDate));
  const startDate = format(parsedStartDate, 'dd.MM.yyyy');

  const parsedStartDateEvent = props.notification.startDateEvent ? new Date(Date.parse(props.notification.startDateEvent)) : null;
  const startDateEvent = parsedStartDateEvent ? format(parsedStartDateEvent, 'dd.MM.yyyy') : null;

  const parsedEndDate = new Date(Date.parse(props.notification.endDate));
  const endDate = format(parsedEndDate, 'dd.MM.yyyy');

  const parsedEndDateEvent = props.notification.startDateEvent ? new Date(Date.parse(props.notification.endDate)): null;
  const endDateEvent = parsedEndDateEvent ? format(parsedEndDateEvent, 'dd.MM.yyyy') : null;

  const parsedLanguageFormFieldArrays = useMemo(
    () =>
      props.languages.reduce((acc, lang) => {
        acc[lang] = [
          {
            title: notification.title[lang] || '',
            text: notification.text[lang] || '',
          },
        ];

        return acc;
      }, {}),
    [notification.title, notification.text, props.languages]
  );

  const defaultValues = {
    ...notification,
    ...parsedLanguageFormFieldArrays,
    startDate: parseISO(notification.startDate),
    endDate: parseISO(notification.endDate),
    startDateEvent: parseISO(notification.startDateEvent),
    endDateEvent: parseISO(notification.endDateEvent),
    hasEvent: startDateEvent && endDateEvent ? true : false,
  };

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { watch, reset, setValue } = formMethods;

  const isGeneralNotificationValue = watch('isGeneralNotification');
  const startDateFieldValue = watch('startDate');
  const endDateFieldValue = watch('endDate');
  const startDateEventFieldValue = watch('startDateEvent');
  const endDateEventFieldValue = watch('endDateEvent');
  const hasEvent = watch('hasEvent');

  useEffect(()=>{
    if (!hasEvent) {
      setValue('startDateEvent', null); 
      setValue('endDateEvent', null); 
    }
  }, [hasEvent])

  const getAttractionNamesByID = (allAttractionsData, attractionIndexes) => {
    let attractionNames = [];
    attractionIndexes.forEach(id => {
      const searchedAttraction = allAttractionsData.find(attraction => attraction.id === id)
      if(!!searchedAttraction)
        attractionNames.push(searchedAttraction.name);
    })

    return attractionNames;
  };

  const attractions =
    props.notification.attractions.length === 0
      ? '*** COOLPASS ***'
      : getAttractionNamesByID(
          props.availableAttractions,
          props.notification.attractions
        ).join(', ');

  const handleChangeLanguageTab = (_, newValue) => setCurrentTabId(newValue);
  const handleFormEdit = () => {
    setIsFormEdit(true);
  };

  const handleCancelChanges = () => {
    setIsFormEdit(false);
    reset(defaultValues, { keepDefaultValues: true });
  };

  const handleExpandDealAccordion = (e) => {
    onExpandChange(notification.id)(e, !expanded);
    handleCancelChanges();
  };

  const onSubmit = (values) => {
    setShowSaveAnimation(true);

    const fieldsWithTranslations = formatLanguageFieldsData(values);

    const updatedNotification = {
      ...values,
      isVisibleForGeneralSection: values.isGeneralNotification
        ? true
        : values.isVisibleForGeneralSection,
      title: removeKeysWithEmptyValueArrays(fieldsWithTranslations.title),
      text: removeTextEditorValueKeysWithNoPlainText(
        fieldsWithTranslations.text
      ),
      destinationId: props.selectedDestinationId,
      notificationId: notification.id,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      startDateEvent: hasEvent && values.startDateEvent ? new Date(values.startDateEvent).toISOString(): null,
      endDateEvent: hasEvent && values.endDateEvent ? new Date(values.endDateEvent).toISOString(): null,
    };

    dispatch(editDestinationNotificationThunk(updatedNotification))
      .then(() => {
        dispatch(
          getDestinationNotificationsListThunk(props.selectedDestinationId)
        );
        setShowSaveAnimation(false);
        props.handleResetFilters();
      })
      .then(() =>
        addError(`${t('notifications.success_edit_notification')}`, 'Success')
      )
      .catch(() => {
        addError(`${t('notifications.error_edit_notification')}`, 'Error');
        setShowSaveAnimation(false);
      });
    onExpandChange(null)(null);
  };

  const onDeleteNotification = () => {
    setShowSaveAnimation(true);

    dispatch(
      deleteDestinationNotificationThunk(
        props.selectedDestinationId,
        notification.id
      )
    )
      .then(() => {
        props.handleResetFilters();
        dispatch(
          getDestinationNotificationsListThunk(props.selectedDestinationId)
        );
        setShowSaveAnimation(false);
      })
      .then(() =>
        addError(`${t('notifications.success_delete_notification')}`, 'Success')
      )
      .catch(() => {
        addError(`${t('notifications.error_delete_notification')}`, 'Error');
        setShowSaveAnimation(false);
      });
  };

  return (
    <MuiAccordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={handleExpandDealAccordion}
      disableGutters
      elevation={0}
      square
    >
      <NotificationAccordionTitle
        notification={notification}
        expanded={props.expanded}
        attractions={attractions}
        startDate={startDate}
        endDate={endDate}
        languages={props.languages}
      />
      <MuiAccordionDetails
        sx={{
          padding: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, .125)',
        }}
      >
        <FormProvider {...formMethods}>
          <EditNotificationForm
            item = {notification}
            selectedLanguage={props.selectedLanguage}
            availableAttractions={props.availableAttractions}
            isGeneralNotificationValue={isGeneralNotificationValue}
            languages={props.languages}
            currentTabId={currentTabId}
            handleChangeLanguageTab={handleChangeLanguageTab}
            statusOptions={props.statuses[0].parameters}
            isFormEdit={isFormEdit}
            handleFormEdit={handleFormEdit}
            handleCancelChanges={handleCancelChanges}
            startDateFieldValue={startDateFieldValue}
            endDateFieldValue={endDateFieldValue}
            startDateEventFieldValue={startDateEventFieldValue}
            endDateFieldEVentValue={endDateEventFieldValue}
            hasEvent = {hasEvent}
            showSaveAnimation={showSaveAnimation}
            onSubmit={onSubmit}
            onDeleteNotification={onDeleteNotification}
          />
        </FormProvider>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}

export default GeneralNotification;
