import { useTranslation } from 'react-i18next';

import { TableCell } from '@mui/material';

import { StyledLogsTableHead, StyledTableHeadRow } from './styled';

const LogsTableHeader = () => {
  const { t } = useTranslation();

  const tableHeaderLables = t('logs.tableLabels', {
    returnObjects: true,
  });

  return (
    <StyledLogsTableHead>
      <StyledTableHeadRow>
        {tableHeaderLables.map((label) => (
          <TableCell padding="normal">{label}</TableCell>
        ))}
      </StyledTableHeadRow>
    </StyledLogsTableHead>
  );
};

export default LogsTableHeader;
