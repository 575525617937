import {
  Box,
  FormControl,
  FormHelperText,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

export const SetupFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

export const SetupFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const SetupFormSelectFieldInput = styled(Select)`
  width: 100%;
`;

export const SetupFormTextFieldInput = styled(TextField)`
  width: 100%;

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const SelectHelperText = styled(FormHelperText)`
  position: absolute;
  margin-left: 0;
  bottom: -18px;
`;

export const StyledFormControl = styled(FormControl)`
  .MuiInputLabel-root[data-shrink='false'] {
    top: -8px;
  }
`;

export const SetupFormControlPanelWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: 45px 0 30px;
`;

export const SetupFormTitleWrapper = styled(Box)`
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 500;
`;

export const SetupFormLocationTitlesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 205px;
  width: 100%;
`;
