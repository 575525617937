import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';

import { DealFormPercentageField } from '../styled';

const DealPercentageField = ({ name, disabled }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: true,
          message: t('attractions.deals.error_required'),
        },
        maxLength: { value: 200, message: 'max length is 200' },
        validate: (value) => (value < 0 || value > 100 ? '0-100' : undefined),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DealFormPercentageField
          value={value}
          disabled={disabled}
          onChange={(event) => onChange(event.target.value)}
          fullWidth
          size="small"
          error={!!error}
          helperText={error?.message}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      )}
    />
  );
};

export default DealPercentageField;
