export const getDestinationAvailability = (destinationsId, allDestinations) => {
  let destinations = {};
  allDestinations.map((destination) => {
    if (destinationsId.includes(`${destination.id}`, 0))
      destinations[destination.name] = true;
    else destinations[destination.name] = false;
  });
  return destinations;
};

export const getImageUrl = async (imageList, uploadHandler, type) => {
  let imageUrl;

  if (!imageList.length) {
    imageUrl = null;
    return imageUrl;
  }

  if (typeof imageList[0] === 'string') {
    imageUrl = imageList[0];
    return imageUrl;
  }

  const iconFormData = new FormData();
  iconFormData.append('files', imageList[0].file);

  const response = await uploadHandler(iconFormData, type);

  if (response.status === 200) {
    imageUrl = response.data[0];
  } else {
    console.error(`${type} upload error:`, response.data.errors);
    imageUrl = null;
  }

  return imageUrl;
};
