import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength10 = maxLength(10);
export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);

export const requiredOrder = (values) => (!values ? 'Enter order' : undefined);
export const onlyNumber = (value) =>
  value && isNaN(Number(value)) ? 'Enter the number' : undefined;
