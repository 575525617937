import ImageDrop from '../../../publishing/HomePageTab/imageDrop/ImageDrop';

import { StyledFormHelperText } from './styled';

import { IMAGE_DROP_COLUMN_AMOUNT } from '../constants';

const ReduxImageDrop = (props) => {
  const {
    input,
    disabled,
    columns,
    meta: { error },
  } = props;

  return (
    <>
      <ImageDrop
        {...props}
        fileList={input.value}
        onFileListChange={input.onChange}
        filesLimit={props.filesLimit}
        columns={columns || IMAGE_DROP_COLUMN_AMOUNT}
        rowHeight={props.rowHeight}
        disabled={disabled}
      />
      <StyledFormHelperText>{error}</StyledFormHelperText>
    </>
  );
};

export default ReduxImageDrop;
