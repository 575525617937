import { TableCell } from '@mui/material';

const ProductTableDataRow = ({
  productData,
  currency,
  translatedType,
  productName,
  productSpecification,
}) => {
  const { id, validity, netPrice, vat, grossPrice, vatInPercent } = productData;

  return (
    <>
      <TableCell>{id}</TableCell>
      <TableCell>{translatedType}</TableCell>
      <TableCell>{productName}</TableCell>
      <TableCell>{productSpecification}</TableCell>
      <TableCell>{validity}</TableCell>
      <TableCell>{`${netPrice} ${currency}`}</TableCell>
      <TableCell>{vatInPercent}</TableCell>
      <TableCell>{`${vat} ${currency}`}</TableCell>
      <TableCell>{`${grossPrice} ${currency}`}</TableCell>
    </>
  );
};

export default ProductTableDataRow;
