export function required(value) {
    return !value ? 'Required' : undefined;
}

export function isNotEmpty(value) {
    return value.trim().length === 0 ? 'Must be not empty' : undefined;
}

export function ListIsNotEmpty(value) {
    if(!value == null){
        return value.length === 0 ? 'Must be not empty' : undefined;
    }
    return 'Must be not empty';
}

export const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength200 = maxLength(200);
export const maxLength1000 = maxLength(1000);

const yearLimit = 10;
const maxDate = new Date();
const minDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() + yearLimit);
minDate.setFullYear(minDate.getFullYear() - yearLimit);

export const maximalDate = maxDate => value => value && value > maxDate ? `Must be less than  ${maxDate}` : undefined;
export const maximalDateValidation = maximalDate(maxDate);

export const minimalDate = minDate => value => value && value < minDate ? `Must be greater than ${minDate}` : undefined;
export const minimalDateValidation = minimalDate(minDate);

export const dateIsValid = (date) => {
    if (
        typeof date === 'object' &&
        date !== null &&
        typeof date.getTime === 'function' &&
        !isNaN(date)
      ) {
        return true;
      }
    
      return false;
}