import React from 'react';
import { useDispatch } from 'react-redux';

import { TablePagination, TableContainer, Table, Box, CircularProgress } from '@mui/material';

import PointsTableDataRow from './pointsTable/PointsTableDataRow';
import PointsTableHeader from './pointsTable/PointsTableHeader';

import {
  setPageNumber,
  setPageSize,
} from '../../redux/salesPointsReducer/salesPointsActions';
import {
  TYPE_AREA,
  TYPE_LOCATION,
  TYPE_REGION,
} from './constants';
import {DEFAULT_PAGE_SIZE} from "../constants";
import {useTranslation} from "react-i18next";

const SalesPointsTable = (props) => {
  const {
    appState,
    categoryState,
    settingsState,
    sortBy,
    sortField,
    sortStatus,
    onSalesPointDataRowClick,
    pageNumber,
    pageSize,
    totalCount,
    salesPointList,
    isPointListLoading,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const destinationDefaultLanguage = appState.selectedDestination.languages[0];

  const isRegionEnabledInDestination =
    appState.selectedDestination.enabledLocationTypes?.includes(TYPE_REGION);
  const isAreaEnabledInDestination =
    appState.selectedDestination.enabledLocationTypes?.includes(TYPE_AREA);
  const isLocationEnabledInDestination =
    appState.selectedDestination.enabledLocationTypes?.includes(TYPE_LOCATION);

  const dictionaryCategoryData = Object.assign(
    {},
    ...categoryState.categoryData.map((item) => ({
      [item.id]: item.name[destinationDefaultLanguage],
    }))
  );

  const dictionaryRegionData = Object.assign(
    {},
    ...settingsState.dictionaryRegionData.map((item) => ({
      [item.id]: item.title[destinationDefaultLanguage],
    }))
  );

  const dictionaryAreaData = Object.assign(
    {},
    ...settingsState.dictionaryAreaData.map((item) => ({
      [item.id]: item.title[destinationDefaultLanguage],
    }))
  );

  const dictionaryLocationData = Object.assign(
    {},
    ...settingsState.dictionaryLocationData.map((item) => ({
      [item.id]: item.title[destinationDefaultLanguage],
    }))
  );

  const TABLE_PAGINATION_OPTIONS = [
    { label: t('all_pagination_option'), value: DEFAULT_PAGE_SIZE },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
  ];

  const createData = (
    status,
    name,
    title,
    categories,
    region,
    regionOrder,
    area,
    areaOrder,
    location,
    locationOrder,
    id
  ) => {
    return {
      status,
      name,
      title,
      categories,
      region,
      regionOrder,
      area,
      areaOrder,
      location,
      locationOrder,
      id,
    };
  };

  const createRows = salesPointList.map((item) =>
    createData(
      item.status,
      item.name,
      item.title[destinationDefaultLanguage] || 'No title',
      dictionaryCategoryData[item.category] || 'No category',
      dictionaryRegionData[item.regionId] || 'No region',
      item.regionOrder || 0,
      dictionaryAreaData[item.areaId] || 'No area',
      item.areaOrder || 0,
      dictionaryLocationData[item.locationId] || 'No location',
      item.locationOrder || 0,
      item.id
    )
  );

  const handleChangePageNumber = (_, newPage) => {
    dispatch(setPageNumber(newPage));
  };

  const handleChangePageSize = (event) => {
    dispatch(setPageSize(event.target.value));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TablePagination
        rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
        onRowsPerPageChange={handleChangePageSize}
        component="div"
        count={totalCount}
        page={pageNumber}
        onPageChange={handleChangePageNumber}
        rowsPerPage={pageSize}
      />
      {
        isPointListLoading
         ? <Box
            component="div"
            sx={{ width: '100%', position: 'relative'}}
          >
            <CircularProgress
              sx={{ position: 'absolute', right: '50%', top: '200px' }}
            />
          </Box>
        : 
        <>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <PointsTableHeader
              onSort={sortBy}
              value={sortField}
              status={sortStatus}
              showRegionColumn={isRegionEnabledInDestination}
              showAreaColumn={isAreaEnabledInDestination}
              showLocationColumn={isLocationEnabledInDestination}
            />
            {createRows.map((item, index) => (
              <PointsTableDataRow
                key={index}
                onClickRow={onSalesPointDataRowClick}
                attractionRow={item}
                showRegionColumn={isRegionEnabledInDestination}
                showAreaColumn={isAreaEnabledInDestination}
                showLocationColumn={isLocationEnabledInDestination}
              />
            ))}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
          onRowsPerPageChange={handleChangePageSize}
          component="div"
          count={totalCount}
          page={pageNumber}
          onPageChange={handleChangePageNumber}
          rowsPerPage={pageSize}
        />
        </>
      }
    </Box>
  );
};

export default SalesPointsTable;
