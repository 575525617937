import { LocalizationProvider } from '@mui/lab';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledMobileDateTimePicker } from '../../styled';
import { useState } from 'react';
import { LogFormDatePickerTextField } from '../../styled';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { InputAdornment } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';

const useStyles = makeStyles({
  dateFieldInputDisabled: {
    '& .MuiOutlinedInput-input': {
      cursor: 'default',
    },

    '& .MuiInputAdornment-root': {
      cursor: 'default',
    },
  },
});

export const DatePickerField = (props) => {
  const { name, disabled, placeholder } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const handleOpenCalendar = () => setIsOpen(true);
  const handleCloseCalendar = () => setIsOpen(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledMobileDateTimePicker
            inputFormat="dd.MM.yyyy HH:mm"
            onChange={(event) => onChange(event)}
            ampm={false}
            value={value}
            disabled={disabled}
            mask={'__.__.____ __:__'}
            showToolbar={false}
            open={isOpen}
            onOpen={handleOpenCalendar}
            onAccept={handleCloseCalendar}
            onClose={handleCloseCalendar}
            clearText={t('clear')}
            okText={t('ok')}
            cancelText={t('cancel')}
            clearable
            renderInput={(params) => (
              <LogFormDatePickerTextField
                size="small"
                placeholder={t(placeholder)}
                {...params}
                className={clsx(disabled && classes.dateFieldInputDisabled)}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      disablePointerEvents={disabled}
                      position="start"
                      onClick={handleOpenCalendar}
                    >
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};
