import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  setCheckedDestination,
  setCurrentLanguage,
} from '../../redux/appReducer';
import {
  getAttractionsListThunk,
  getSmallAttractionsDataThunk,
} from '../../redux/attractionsReducers/attractions/attractionsActions';
import { getDestinationThunk } from '../../redux/settingsReducers/settingsReducer';
import TopBar from './TopBar';

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    settingsReducer: state.settingsReducer,
    attractionsReducer: state.attractionsReducer,
    authReducer: state.authReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    setCheckedDestination,
    setCurrentLanguage,
    getAttractionsListThunk,
    getSmallAttractionsDataThunk,
    getDestinationThunk,
  })
)(TopBar);
