import { useTranslation } from 'react-i18next';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box, IconButton, TableCell, Typography } from '@mui/material';

import { StyledVisitsTableHead, StyledTableHeadRow } from '../styled';

import {
  DATE_AND_TIME_HEADER_LABEL,
  DATE_SORT_ASC_DIRECTION,
  DATE_SORT_DESC_DIRECTION,
} from '../../../visits/constants';

const AttractionVisitsTableHeader = ({
  handleChangeSortDirection,
  sortDirection,
  handleResetDateSortDirection,
}) => {
  const { t } = useTranslation();

  const tableHeaderLables = t('visitsPage.visitsTableLabels', {
    returnObjects: true,
  });

  return (
    <StyledVisitsTableHead>
      <StyledTableHeadRow>
        {tableHeaderLables.map((label, idx) =>
          label === DATE_AND_TIME_HEADER_LABEL ? (
            <TableCell
              padding="normal"
              key={idx}
              onClick={
                sortDirection === DATE_SORT_DESC_DIRECTION
                  ? handleResetDateSortDirection
                  : handleChangeSortDirection
              }
            >
              <Box display="flex" justifyContent="space-between">
                <Typography>{label}</Typography>
                <IconButton
                  sx={{ padding: '2px', opacity: sortDirection ? 1 : 0.3 }}
                >
                  {sortDirection === DATE_SORT_ASC_DIRECTION ||
                  !sortDirection ? (
                    <ArrowUpwardIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardIcon fontSize="small" />
                  )}
                </IconButton>
              </Box>
            </TableCell>
          ) : (
            <TableCell padding="normal" key={idx}>
              {label}
            </TableCell>
          )
        )}
      </StyledTableHeadRow>
    </StyledVisitsTableHead>
  );
};

export default AttractionVisitsTableHeader;
