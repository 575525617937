import { TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const StyledLogsTableHead = styled(TableHead)`
  .MuiTableCell-root {
    :nth-of-type(1) {
      width: 20%;
    }
    :nth-of-type(2) {
      width: 10%;
    }
    :nth-of-type(3) {
      width: 10%;
    }
    :nth-of-type(4) {
      width: 10%;
    }
    :nth-of-type(5) {
      width: 20%;
    }
    :nth-of-type(6) {
      width: 0%;
    }
  }
`;

export const StyledTableHeadRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
  }

  &:hover {
    cursor: pointer;
  }

  td:last-child {
    text-align: center;
  }
`;

export const StyledLogsTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 1.43;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: #ffa94c;
    cursor: pointer;
  }

  td:last-child {
    text-align: center;
  }
`;

export const StyledLogsTableContentRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
`;
