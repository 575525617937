export const ATTRACTION_MANAGER_ROLE = 'attraction';
export const ATTRACTION_MANAGER_PRIMARY_ROLE = 'attractionprimary';
export const REGIONAL_MANAGER_ROLE = 'regionalmanager';
export const REGIONAL_MANAGER = 'RegionalManager';

export const ADMIN_ROLE_LIST = ['admin', 'systemmanager'];
export const MANAGER_ROLE_LIST = ['attraction', 'attractionprimary', 'regionalmanager'];

export const ADMIN_ROLE_LIST_APP = ['Administrator', 'SystemManager'];
export const MANAGER_ROLE_LIST_APP = ['AttractionManager', 'AttractionManagerPrimary']

export const DEFAULT_CATEGORY_INPUT_VALUE = {
  id: '',
  name: '',
};

export const DEFAULT_AUTOCOMPLETE_VALUE = {
  id: '',
  title: '',
};

export const TYPE_LOCATION = 'Location';
export const TYPE_AREA = 'Area';
export const TYPE_REGION = 'Region';
