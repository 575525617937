import {
  Box,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { FormButton, SaveButton } from '../../components/buttons/FormButtons';
import {
  ReduxSelect,
  ReduxTextField,
} from '../../components/reduxFormComponents/reduxFormComponents';

export const TranslationPageWrapper = styled(Box)`
  width: 100%;
  margin-top: 130px;
`;

export const TranslationPageHeader = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TranslationPageButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const TranslationPageFilterWrapper = styled(Box)`
  max-width: 300px;
  width: 100%;
`;

export const LoaderWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  right: 50%;
`;

export const StyledTableContainer = styled(TableContainer)`
  width: 100%;
  margin-top: 56px;
  box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 44%);
`;

export const StyledTableCellKey = styled(TableCell)`
  border-right: 1px solid rgba(224, 224, 224, 1);
`;

export const StyledTableCellValue = styled(TableCell)`
  padding: 0 0 0 16px;
`;

export const LanguageTabsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledLanguageTabs = styled(Tabs)`
  width: 100%;
  align-items: center;
`;

export const TranslationTableHead = styled(TableHead)`
  .MuiTableCell-root {
    :nth-of-type(1) {
      width: 20%;
    }
    :nth-of-type(2) {
      width: 80%;
    }
  }
`;

export const TranslationTableRow = styled(TableRow)`
  cursor: pointer;
  overflow-x: hidden;

  td {
    border-right: 1px solid #e0e0e0;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 0;
  }
`;

export const StyledTranslationForm = styled(Box)`
  margin-top: 130px;
  width: 100%;
  max-width: 1500px;
`;

export const TranslationFormHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

export const TranslationFormHeading = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TranslationFormControlPanel = styled(Box)`
  width: 400px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const TranslationFormButton = styled(FormButton)`
  max-width: fit-content;
`;
export const TranslationFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const TranslationFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const TranslationFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const TranslationFormTextField = styled(ReduxTextField)`
  width: 100%;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const TranslationFormSelectField = styled(ReduxSelect)`
  max-width: 215px;
  width: 100%;
  margin-left: 0;
  margin-top: 0;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9) !important;
  }
`;

export const TranslationFormLanguageTabsWrapper = styled(Box)`
  margin-top: 16px;
`;
