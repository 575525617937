import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';
import ContactPersonList from '../components/ContactPersonList';

import {
  CompanyFormControlPanel,
  CompanyFormFieldWrapper,
  CompanyFormFieldTitle,
  CompanyFormTextField,
  CompanyFormSelectField,
  CompanyFormContainer,
  CompanyFormHeader,
  CompanyFormHeaderLabel,
  CompanyFormCombinedFieldWrapper,
  CompanyFormSubmitButton,
  CompanyFormButton,
  CompanyFormFieldShortTitle,
} from './styled';

import { 
  DEFAULT_COMPANY_STATUS,
  EMPTY_COMPANY_OBJECT,
  MANAGER_ROLE_LIST,
} from '../constants';

import {
  required,
  isNotEmpty,
  maxLength250,
  parseToNumber,
  cutToIntLength,
} from './validation';
import { setCurrentCompany } from '../../../../redux/usersReducers/company/companyActions';

const useStyles = makeStyles({
  wrapper: {
    margin: '0',
  },
});

let CompanyForm = (props) => {
  const {
    item,
    onFormCancel,
    onFormDelete,
    onFormEdit,
    isAddingItem,
    isInEditMode,
    handleSubmit,
    showSaveAnimation,
    statusOptions,
    selectedLanguage,
    preventDelete,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(setCurrentCompany({}));
  }, [])
  
  const { t } = useTranslation();
  const [showDeleteAccAlert, setShowDeleteAccAlert] = useState(false);
  const classes = useStyles();

  const onItemDelete = () => {
    setShowDeleteAccAlert(true);
  };

  const onItemDeleteAlertCancel = () => {
    setShowDeleteAccAlert(false);
  };

  const onDialogDeleteConfirmation = () => {
    onFormDelete(setShowDeleteAccAlert);
  };

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  return (
    <CompanyFormContainer className={isManagerRoleSelected? classes.wrapper : null} component="form" onSubmit={handleSubmit}>
      <CompanyFormHeader>
        {isAddingItem && (
          <CompanyFormHeaderLabel>
            <Typography fontWeight="bold" textTransform="uppercase">
              {t('companies.new_company')}
            </Typography>
          </CompanyFormHeaderLabel>
        )}
        <CompanyFormHeaderLabel>
          <Typography
            fontWeight="bold"
            textTransform="uppercase"
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth="500px"
            width="100%"
          >
            {props.item.name}
          </Typography>
        </CompanyFormHeaderLabel>
        <CompanyFormControlPanel>
          <Box display="flex" gap="10px">
            <CompanyFormButton
              onClick={onFormCancel}
              label={t('companies.buttons.cancel')}
              disabled={!isInEditMode}
            />
            {!isAddingItem && (
              <>
                <CompanyFormButton
                  onClick={onFormEdit}
                  disabled={isInEditMode || showSaveAnimation}
                  label={t('companies.buttons.edit')}
                />
                {!preventDelete && !isManagerRoleSelected && (
                  <CompanyFormButton
                    label={t('companies.buttons.delete_account')}
                    onClick={onItemDelete}
                  />
                )}
              </>
            )}
            <CompanyFormSubmitButton
              isLoading={showSaveAnimation}
              disabled={!isInEditMode}
              label={t('companies.buttons.save')}
            />
          </Box>
        </CompanyFormControlPanel>
      </CompanyFormHeader>
      {!isAddingItem && (
        <CompanyFormFieldWrapper>
          <CompanyFormFieldTitle>
            {t('companies.company_id')}:
          </CompanyFormFieldTitle>
          <Typography>{item.id || 'Company id'}</Typography>
        </CompanyFormFieldWrapper>
      )}
      {!isManagerRoleSelected && (
        <CompanyFormFieldWrapper>
          <CompanyFormFieldTitle>{t('companies.status')}:</CompanyFormFieldTitle>
          <Field
            name="status"
            component={CompanyFormSelectField}
            validate={[required, isNotEmpty]}
            disabled={!isInEditMode}
          >
            {statusOptions.parameters.map((status, i) => (
              <option value={status.id} key={i}>
                {status.value[selectedLanguage] || status.value.en}
              </option>
            ))}
          </Field>
        </CompanyFormFieldWrapper>
      )}
      <CompanyFormFieldWrapper>
        <CompanyFormFieldTitle>
          {t('companies.company_name')}:
        </CompanyFormFieldTitle>
        <Field
          component={CompanyFormTextField}
          name="name"
          validate={[required, maxLength250]}
          disabled={!isInEditMode}
        />
      </CompanyFormFieldWrapper>
      <CompanyFormFieldWrapper>
        <CompanyFormFieldTitle>
          {t('companies.company_website')}:
        </CompanyFormFieldTitle>
        <Field
          component={CompanyFormTextField}
          name="website"
          validate={[maxLength250]}
          disabled={!isInEditMode}
        />
      </CompanyFormFieldWrapper>
      <CompanyFormFieldWrapper>
        <CompanyFormFieldTitle>
          {t('companies.street_address')}:
        </CompanyFormFieldTitle>
        <Field
          component={CompanyFormTextField}
          name="streetAddress"
          validate={[maxLength250]}
          disabled={!isInEditMode}
        />
      </CompanyFormFieldWrapper>
      <CompanyFormFieldWrapper>
        <CompanyFormFieldTitle>{t('companies.city')}:</CompanyFormFieldTitle>
        <CompanyFormCombinedFieldWrapper>
          <Field
            component={CompanyFormTextField}
            name="city"
            validate={[maxLength250]}
            disabled={!isInEditMode}
          />
          <CompanyFormFieldShortTitle>
            {t('companies.zip_code')}:
          </CompanyFormFieldShortTitle>
          <Field
            component={CompanyFormTextField}
            name="zipCode"
            disabled={!isInEditMode}
            parse={(value) => {
              const cuttedNumber = cutToIntLength(value);
              return parseToNumber(cuttedNumber);
            }}
          />
        </CompanyFormCombinedFieldWrapper>
      </CompanyFormFieldWrapper>
      <CompanyFormFieldWrapper>
        <CompanyFormFieldTitle>{t('companies.country')}:</CompanyFormFieldTitle>
        <Field
          component={CompanyFormTextField}
          name="country"
          validate={[maxLength250]}
          disabled={!isInEditMode}
        />
      </CompanyFormFieldWrapper>
      <CompanyFormFieldWrapper>
        <CompanyFormFieldTitle>
          {t('companies.reg_number')}:
        </CompanyFormFieldTitle>
        <CompanyFormCombinedFieldWrapper>
          <Field
            component={CompanyFormTextField}
            name="registrationNumber"
            disabled={!isInEditMode}
            parse={(value) => {
              return parseToNumber(value);
            }}
          />
          <CompanyFormFieldShortTitle>
            {t('companies.vat_number')}:
          </CompanyFormFieldShortTitle>
          <Field
            component={CompanyFormTextField}
            name="valueAddedTaxNumber"
            validate={[maxLength250]}
            disabled={!isInEditMode}
          />
        </CompanyFormCombinedFieldWrapper>
      </CompanyFormFieldWrapper>
      {!isManagerRoleSelected && (
        <>
          <FieldArray
            component={ContactPersonList}
            personLabel={t('companies.contact_person')}
            name="personContacts"
            addElement={isAddingItem}
            disabled={!isInEditMode}
          />
        </>
      )}
      <FieldArray
        component={ContactPersonList}
        personLabel={t('companies.billing_contact')}
        name="billingContacts"
        addElement={isAddingItem}
        disabled={!isInEditMode}
      />
      <CompanyFormFieldWrapper>
        <CompanyFormFieldTitle>
          {t('companies.bank_account')}:
        </CompanyFormFieldTitle>
        <Field
          component={CompanyFormTextField}
          name="bankAccount"
          disabled={!isInEditMode}
          validate={[maxLength250]}
        />
      </CompanyFormFieldWrapper>
      <ConfirmationDialog
        showAlert={showDeleteAccAlert}
        handleClose={onItemDeleteAlertCancel}
        handleCloseSuccess={onDialogDeleteConfirmation}
        text={t('companies.delete_account_dialog')}
      />
    </CompanyFormContainer>
  );
};

const mapStateToProps = (_, ownProps) => {
  if (!ownProps.isAddingItem) {
    if (ownProps.item) {
      return {
        initialValues: {
          ...ownProps.item,
        },
      };
    }
    return {
      initialValues: {
        ...EMPTY_COMPANY_OBJECT,
        id: null,
      },
    };
  }
  return {
    initialValues: {
      ...EMPTY_COMPANY_OBJECT,
      status: DEFAULT_COMPANY_STATUS,
    },
  };
};

CompanyForm = reduxForm({
  form: 'CompanyForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CompanyForm);

export default connect(mapStateToProps)(CompanyForm);
