import {
  TYPE_ALL_WEEK,
  TYPE_OPENING_HOURS,
  TYPE_STARTING_TIMES,
} from './constants';

export const getOpeningHoursValue = (type, timeArray, allWeekValue) => {
  if (type === TYPE_STARTING_TIMES) return null;

  if (type === TYPE_OPENING_HOURS) {
    const openingHoursValue = {
      monday: {
        isClosed: timeArray.monday.length ? false : true,
        hours: timeArray.monday ? timeArray.monday : null,
      },
      tuesday: {
        isClosed: timeArray.tuesday.length ? false : true,
        hours: timeArray.tuesday ? timeArray.tuesday : null,
      },
      wednesday: {
        isClosed: timeArray.wednesday.length ? false : true,
        hours: timeArray.wednesday ? timeArray.wednesday : null,
      },
      thursday: {
        isClosed: timeArray.thursday.length ? false : true,
        hours: timeArray.thursday ? timeArray.thursday : null,
      },
      friday: {
        isClosed: timeArray.friday.length ? false : true,
        hours: timeArray.friday ? timeArray.friday : null,
      },
      saturday: {
        isClosed: timeArray.saturday.length ? false : true,
        hours: timeArray.saturday ? timeArray.saturday : null,
      },
      sunday: {
        isClosed: timeArray.sunday.length ? false : true,
        hours: timeArray.sunday ? timeArray.sunday : null,
      },
    };

    return openingHoursValue;
  }

  if (type === TYPE_ALL_WEEK) {
    const openingHoursValue = {
      monday: {
        isClosed: !!allWeekValue ? allWeekValue.closedOn === 'Monday' : false,
        hours: !!allWeekValue ? [allWeekValue.timeInterval] : [],
      },
      tuesday: {
        isClosed: !!allWeekValue ? allWeekValue.closedOn === 'Tuesday' : false,
        hours: !!allWeekValue ? [allWeekValue.timeInterval] : [],
      },
      wednesday: {
        isClosed: !!allWeekValue ? allWeekValue.closedOn === 'Wednesday' : false,
        hours: !!allWeekValue ? [allWeekValue.timeInterval] : [],
      },
      thursday: {
        isClosed: !!allWeekValue ? allWeekValue.closedOn === 'Thursday' : false,
        hours: !!allWeekValue ? [allWeekValue.timeInterval] : [],
      },
      friday: {
        isClosed: !!allWeekValue ? allWeekValue.closedOn === 'Friday' : false,
        hours: !!allWeekValue ? [allWeekValue.timeInterval] : [],
      },
      saturday: {
        isClosed: !!allWeekValue ? allWeekValue.closedOn === 'Saturday' : false,
        hours: !!allWeekValue ? [allWeekValue.timeInterval] : [],
      },
      sunday: {
        isClosed: !!allWeekValue ? allWeekValue.closedOn === 'Sunday' : false,
        hours: !!allWeekValue ? [allWeekValue.timeInterval] : [],
      },
    };

    return openingHoursValue;
  }
};

export const getTypeValue = (type) => {

  if (type === TYPE_ALL_WEEK) {
    return TYPE_OPENING_HOURS
        .split(' ')
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
        .join('');
  }

  return type
    .split(' ')
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join('');
};
