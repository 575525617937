import authService from "./AuthorizeService";

export const getDecodedToken = async () => {
  const token = await authService.getAccessToken();

  const tokenParts = token.split('.');
  const decodedToken = {
      header: JSON.parse(atob(tokenParts[0])),
      payload: JSON.parse(atob(tokenParts[1])),
      signature: tokenParts[2]
  };

  return decodedToken.payload;
};