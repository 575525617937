import { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  setIsRouteChangeConfirmed,
  setShowUnsavedChangesAlert,
  setTargetLocation,
} from '../../redux/routeChangeReducer/routeChangeActions';

import { useNavigationBlocker } from './useNavigationBlocker';

export const useNavigationPrompt = (shouldBlock = true) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isRouteChangeConfirmed = useSelector(
    (state) => state.routeChangeReducer.isRouteChangeConfirmed
  );
  const targetLocation = useSelector(
    (state) => state.routeChangeReducer.targetLocation
  );
  const storedSearchParamsString = useSelector(
    (state) => state.attractionsReducer.attractionSearchParams
  );

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (
        !isRouteChangeConfirmed &&
        nextLocation.location.pathname !== location.pathname
      ) {
        dispatch(setShowUnsavedChangesAlert(true));
        dispatch(setTargetLocation(nextLocation));
        return false;
      }
      return true;
    },

    [dispatch, isRouteChangeConfirmed, location]
  );

  useEffect(() => {
    if (targetLocation && isRouteChangeConfirmed) {
      const targetUrl = targetLocation.location?.pathname;
      const newUrl = targetUrl === '/attractions' ? `${targetUrl + '?' + storedSearchParamsString}` : targetUrl
      navigate(newUrl);
      dispatch(setIsRouteChangeConfirmed(false));
    }
  }, [navigate, dispatch, targetLocation, isRouteChangeConfirmed]);

  useNavigationBlocker(handleBlockedNavigation, shouldBlock);
};
