import {
  SET_VISITORS,
  LOAD_VISITORS,
  SET_IS_DATA_LOADING,
  SET_PAGINATION_SETTINGS,
} from './visitorsActions';

const initialState = {
  visitors: [],
  isDataLoading: false,
  paginationSettings: {
    pageSize: 50,
    pageNumber: 0,
    pageNumberLazy: 1,
    totalPages: 0,
    totalCount: 0,
  },
};

const visitorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VISITORS: {
      return {
        ...state,
        visitors: [...action.payload.data],
      };
    }
    case LOAD_VISITORS: {
      return {
        ...state,
        visitors: [...state.visitors, ...action.payload.data],
      };
    }
    case SET_IS_DATA_LOADING: {
      return {
        ...state,
        isDataLoading: action.payload.data,
      };
    }
    case SET_PAGINATION_SETTINGS: {
      return {
        ...state,
        paginationSettings: {
          ...action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default visitorsReducer;
