import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { Box, TableBody, TableCell } from '@mui/material';

import {
    AttractionReportDealTable,
    AttractionReportDealTableHead,
    AttractionReportDealTableRow,
} from '../styled';

import { parseVisitorNumber } from '../../../users/visitorsTab/helpers';
import { DealTableTitle } from '../../visits/styled';

const ReportDealDetailsTable = ({ dealData, selectedLanguage }) => {
    const { t } = useTranslation();

    const tableHeaderLables = t('attractions.reports.dealDetailsTableLables', {
        returnObjects: true,
    });

    const dealNumber = dealData.number;

    return (
        <Box mt="20px">
            <DealTableTitle pl="10px">
                {t('attractions.reports.deal').toUpperCase()} {dealNumber} -{' '}
                {dealData.title[selectedLanguage] || dealData.title.en}
            </DealTableTitle>
            <AttractionReportDealTable>
                <AttractionReportDealTableHead>
                    {tableHeaderLables.map((label, idx) => (
                        <TableCell key={idx}>{label}</TableCell>
                    ))}
                </AttractionReportDealTableHead>

                <TableBody>
                    {dealData.visits.map((visit, idx) => (
                        <AttractionReportDealTableRow key={idx}>
                            <TableCell>{visit.confirmationCode}</TableCell>
                            <TableCell>
                                {format(new Date(visit.dateAndTime), 'dd/MM/yyyy HH:mm')}
                            </TableCell>
                            <TableCell>{visit.attraction}</TableCell>
                            <TableCell>{parseVisitorNumber(visit.visitorId)}</TableCell>
                        </AttractionReportDealTableRow>
                    ))}
                </TableBody>
            </AttractionReportDealTable>
        </Box>
    );
};

export default ReportDealDetailsTable;
