export const createDealObject = (attractionId, values) => {
  return {
    attractionId: attractionId,
    number: values.number || null,
    status: values.status || null,
    benefit: values.benefit || null,
    title: values.title || null,
    formula: values.formula || null,
    note: values.note || null,
    redeemInstructions: values.redeemInstructions || null,
    startDate: values.startDate || null,
    endDate: values.endDate || null,
    numberOfUses: values.numberOfUses,
    numberOfUsesType: values.numberOfUsesType,
    percentage: parseInt(values.percentage) || null,
  };
};

export const editDealObject = (attractionId, values) => {
  return {
    attractionId: attractionId,
    id: values.id,
    number: values.number || null,
    status: values.status || null,
    benefit: values.benefit || null,
    title: values.title || null,
    formula: values.formula || null,
    note: values.note || null,
    redeemInstructions: values.redeemInstructions || null,
    startDate: values.startDate || null,
    endDate: values.endDate || null,
    numberOfUses: parseInt(values.numberOfUses) || null,
    numberOfUsesType: values.numberOfUsesType,
    percentage: parseInt(values.percentage) || null,
  };
};

export const formatLanguageFieldsData = (values) =>
  Object.keys(values).reduce(
    (acc, item) => {
      if (Array.isArray(values[item])) {
        const currLangObj = values[item][0];

        if (currLangObj.title.length)
          acc.title[item] = currLangObj.title.trim();

        if (currLangObj.note.length)
          acc.note[item] = currLangObj.note.trim();

        if (currLangObj.redeemInstructions.length)
          acc.redeemInstructions[item] = currLangObj.redeemInstructions.trim();

        if (currLangObj.formula.length)
          acc.formula[item] = currLangObj.formula.trim();
      }

      return acc;
    },
    { note: {}, title: {}, redeemInstructions: {}, formula: {} }
  );

export const validateTextFieldValue = (value) => {
  return value.trimStart().replace(/\s\s+/g, ' ');
};
