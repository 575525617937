import { DEFAULT_TEXT_FIELD_MAX_LENGTH, DEFAULT_TRANSLATION_TEXT_FIELD_MAX_LENGTH } from '../../consts/validation';

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);
export const maxLength1000 = maxLength(DEFAULT_TRANSLATION_TEXT_FIELD_MAX_LENGTH);

export const requiredName = (values) => (!values ? 'Enter key' : undefined);
export const noSpaceName = (values) =>
  values.trim() === '' ? 'Key cannot consist of just spaces' : undefined;
