import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { VisitorFormDateField } from '../styled';

const DatePickerField = (props) => {
  const { name, disabled, required } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('attractions.deals.error_required'),
        },
        validate: (value) =>
          dayjs(value).isValid()
            ? value
            : t('attractions.deals.error_invalid_date'),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            inputFormat="dd.MM.yyyy"
            onChange={(event) => onChange(event)}
            value={value}
            disabled={disabled}
            mask={'__.__.____'}
            renderInput={(params) => (
              <VisitorFormDateField
                size="small"
                {...params}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DatePickerField;
