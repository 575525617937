import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DesktopDatePicker,
  LocalizationProvider,
  DateTimePicker,
} from '@mui/x-date-pickers';
import { HelperText } from '../reduxFormComponents/reduxFormComponents';

export const DateTimePickerInput = ({
  value,
  handler,
  input,
  meta: { touched, error },
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} noValidate>
      <DateTimePicker
        ampm={false}
        inputFormat="dd.MM.yyyy HH:mm"
        value={input.value || null}
        onChange={input.onChange} //handler
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            sx={{
              '& .Mui-disabled': {
                WebkitTextFillColor: 'rgba(0, 0, 0, 0.90)',
              },
            }}
            required={props.isRequired}
          />
        )}
        allowSameDateSelection
        InputProps={{ disableUnderline: true }}
        mask={'__.__.____ __:__'}
        error={touched && error}
        {...props}
      />
      {HelperText({ touched, error })}
    </LocalizationProvider>
  );
};

export const DatePicker = ({
  value,
  handler,
  input,
  meta: { touched, error },
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} noValidate>
      <DesktopDatePicker
        inputFormat="dd.MM.yyyy"
        value={input.value || props.disabledValue || null}
        onChange={input.onChange} //handler
        renderInput={(params) => <TextField size="small" {...params} />}
        allowSameDateSelection
        // InputProps={{ disableUnderline: true }}
        mask={'__.__.____'}
        error={touched && error}
        {...props}
      />
      {HelperText({ touched, error })}
    </LocalizationProvider>
  );
};
