import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { reset } from 'redux-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CategoryForm from './CategoryForm';

import {
  CONTENT_LANGUAGES,
  ATTRACTION_CATEGORY_STATUS,
} from '../../../consts/consts';
import useAPIError from '../../../apIErrorProvider/useAPIError';
import { uploadImageFile } from '../../../api/publishing/mainImageApi';
import { getImageUrl } from './utils';
import { CATEGORY_PAGE_ROUTE } from './constants';

import {
  getCategoryThunk,
  deleteCategoryThunk,
  editCategoryThunk,
  setCurrentCategory,
} from '../../../redux/settingsReducers/categoryReducer';

const CategoryEditFormContainer = (props) => {
  const {
    categoryState,
    stateDestinations,
    predefinedState,
    globalSettingsState,
    selectedLanguage,
    deleteCategoryThunk,
    getCategoryThunk,
    editCategoryThunk,
    setCurrentCategory,
  } = props;

  const { id } = useParams();
  const dispatch = useDispatch();
  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormSubmit = async (values) => {
    setShowSaveAnimation(true);
    const destinationArray = [];

    stateDestinations.map((item) => {
      Object.keys(values).map((value) => {
        if (value === item.name && values[value] === true) {
          destinationArray.push(item.id);
        }
      });
    });

    const imageUrl = await getImageUrl(
      values.imageList,
      uploadImageFile,
      'App'
    );
    const iconUrl = await getImageUrl(values.iconList, uploadImageFile, 'Icon');

    const updateData = {
      id: categoryState.currentCategory.id,
      order: values.order,
      name: values.name,
      status: values.status,
      iconUrl: iconUrl,
      imageUrl: imageUrl,
      availableForDestination: destinationArray,
    };

    editCategoryThunk(categoryState.currentCategory.id, updateData)
      .then(() => {
        getCategoryThunk();
        setShowSaveAnimation(false);
        addError(`${t('settings_page.category.error_edit_text')}`, 'Success');
        navigate(CATEGORY_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.category.error_not_edit_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleCloseSuccess = () => {
    setIsInEditMode(false);

    deleteCategoryThunk(categoryState.currentCategory.id)
      .then(() => {
        getCategoryThunk();
        setShowAlert(false);
        addError(
          `${t('settings_page.category.error_deleted_text')}`,
          'Success'
        );
        navigate(CATEGORY_PAGE_ROUTE);
      })
      .catch((error) =>
        addError(
          `${t('settings_page.category.error_not_deleted_text')}`,
          'Error',
          error
        )
      );
  };

  const handleFormCancel = () => {
    setIsInEditMode(false);
    dispatch(reset('Category'));
  };

  useEffect(() => {
    const currentCategory = categoryState.categoryData.find(
      (category) => category.id === id
    );

    setCurrentCategory(currentCategory);

    return () => {
      setIsInEditMode(false);
    };
  }, [id, setCurrentCategory, categoryState.categoryData]);

  return (
    <>
      {categoryState.currentCategory.id && (
        <CategoryForm
          onSubmit={handleFormSubmit}
          categoryState={categoryState}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          isEdit={isInEditMode}
          setIsEdit={setIsInEditMode}
          updateCategory={true}
          handleCloseSuccess={handleCloseSuccess}
          stateDestinations={stateDestinations}
          predefinedState={predefinedState.filter(
            (item) => item.name.en === ATTRACTION_CATEGORY_STATUS
          )}
          languages={globalSettingsState.filter(
            (item) => item.name.en === CONTENT_LANGUAGES
          )}
          selectedLanguage={selectedLanguage}
          isSave={showSaveAnimation}
          onCancel={handleFormCancel}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryState: state.categoryReducer,
    stateDestinations: state.settingsReducer.destinationData,
    predefinedState: state.predefinedReducer.predefinedData,
    globalSettingsState: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  deleteCategoryThunk,
  getCategoryThunk,
  editCategoryThunk,
  setCurrentCategory,
})(CategoryEditFormContainer);
