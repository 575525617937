import {
  getVisitorsList,
  addVisitor,
  updateVisitorData,
  changeVisitorPassword,
  createUser,
} from '../../../api/visitorsApi';

export const SET_VISITORS = 'SET_VISITORS';
export const LOAD_VISITORS = 'LOAD_VISITORS';
export const SET_PAGINATION_SETTINGS = 'SET_PAGINATION_SETTINGS';
export const SET_IS_DATA_LOADING = 'SET_IS_VISITOR_DATA_LOADING';

export const setVisitors = (data) => {
  return { type: SET_VISITORS, payload: { data } };
};
export const loadVisitors = (data) => {
  return { type: LOAD_VISITORS, payload: { data } };
};

export const setPaginationSettings = (data) => {
  return { type: SET_PAGINATION_SETTINGS, payload: { data } };
};

export const setIsDataLoading = (data) => {
  return { type: SET_IS_DATA_LOADING, payload: { data } };
};

export const getVisitorsThunk =
  (pageNumber, pageSize, status = '', searchQuery = '') =>
  async (dispatch) => {
    dispatch(setIsDataLoading(true));

    const response = await getVisitorsList(
      pageNumber,
      pageSize,
      status,
      searchQuery
    );

    if (response.data) {
      dispatch(setVisitors(response.data.items));
      dispatch(setIsDataLoading(false));
      dispatch(
        setPaginationSettings({
          pageNumber,
          pageNumberLazy: 1,
          pageSize,
          totalPages: response.data.totalPages,
          totalCount: response.data.totalCount,
        })
      );
    }
  };

export const loadVisitorsThunk =
  (pageNumberLazy, pageSize, status = '', searchQuery = '') =>
  async (dispatch) => {
    const response = await getVisitorsList(
      pageNumberLazy,
      pageSize,
      status,
      searchQuery
    );

    if (response.data) {
      dispatch(loadVisitors(response.data.items));
    }
  };

export let addVisitorThunk = (data) => {
  return async (_) => {
    const response = await addVisitor(data);
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(response.data.errors);
    }
  };
};

export const updateVisitorThunk = (visitorId, visitorData) => {
  return async (_) => {
    const response = await updateVisitorData(visitorId, visitorData);
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(response.data.errors);
    }
  };
};

export let changeUserPasswordThunk = (visitorId, data) => async () => {
  const response = await changeVisitorPassword(visitorId, data);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export let createUserThunk = (visitorId, destinationId, companyId, role, regions, areas, attractions, isAllAttractions) => async () => {
  const response = await createUser(visitorId, destinationId, companyId, role, regions, areas, attractions, isAllAttractions);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
}