import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import VisitorEditFormTextField from './formFields/VisitorEditFormTextField';
import VisitorEditFormDateFIeld from './formFields/VisitorEditFormDateFIeld';
import VisitorEditFormEmailField from './formFields/VisitorEditFormEmailField';
import { VisitorSelectField } from '../visitorsAddForm/formFields';

import {
  VisitorsFormButton,
  VisitorsFormControlPanel,
  VisitorsFormFieldTitle,
  VisitorsFormFieldWrapper,
  VisitorsFormSubmitButton,
} from '../styled';

import {
    ADDRESS_MAX_LENGTH,
    EMAIL_MAX_LENGTH,
    VISITOR_DELETED_STATUS,
    VISITOR_NAME_MAX_LENGTH,
    VISITOR_LANGUAGE,
    VISITOR_REGISTERED_STATUS,
    VISITOR_EXPIRED_STATUS, ACTIVE_COMPANY_STATUS
} from '../constants';
import { parseVisitorNumber } from '../helpers';

import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';
import UserCreationDialog from "./components/UserCreationDialog";
import {useSelector} from "react-redux";

const VisitorEditForm = ({
  isLoading, 
  onSubmit,
  userSettings,
  isInEditMode,
  handleChangeEditMode,
  handleCancelChanges,
  handleUserCreation,
  visitorId,
  handleClosePopup,
  handleOpenPopup,
  onPopupConfirm,
  visitorName,
  isPopupOpen,
  visitorStatus, 
  isLinkedUserExist,
  handleCloseChangePasswordPopup,
  handleOpenChangePasswordPopup,
  isChangePasswordPopupOpen,
  onChangePasswordPopupConfirm,
  isVisitorActivationPopupOpen,
  isUserCreationPopupOpen,
  handleOpenVisitorActivationPopup,
  handleCloseVisitorActivationPopup,
  handleCloseUserCreationPopup,
  handleOpenUserCreationPopup,
  onVisitorActivationPopupConfirm
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();

  const languageList = userSettings.filter(setting => setting.name.en === VISITOR_LANGUAGE)[0] || null;
  const isVisitorDeleted = visitorStatus === VISITOR_DELETED_STATUS;
  const companies = useSelector(state => state.companyReducer.companiesData.filter(x => x.status === ACTIVE_COMPANY_STATUS));
  
  return (
    <form>
      {!isVisitorDeleted && (
        <VisitorsFormControlPanel>
          <VisitorsFormButton label={t('visitors.visitListButton')} disabled />
          <VisitorsFormButton
            label={t('visitors.changePasswordButton')}
            onClick={handleOpenChangePasswordPopup}
          />
          {(visitorStatus === VISITOR_REGISTERED_STATUS || visitorStatus === VISITOR_EXPIRED_STATUS) && 
              <VisitorsFormButton 
                  label={t('visitors.activateAccountButton')} 
                  onClick={handleOpenVisitorActivationPopup}
              />
          }
          {(!isLinkedUserExist && companies?.length > 0) &&
              <VisitorsFormButton
                  label={t('visitors.registerToAdminButton')}
                  onClick={handleOpenUserCreationPopup}
              />
          }
          <VisitorsFormButton
            label={t('visitors.deleteAccountButton')}
            onClick={handleOpenPopup}
          />

          {isInEditMode && (
            <>
              <VisitorsFormButton
                label={t('visitors.cancelFormButton')}
                onClick={handleCancelChanges}
              />
              <VisitorsFormSubmitButton
                label={t('visitors.saveFormButton')}
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
              />
            </>
          )}

          {!isInEditMode && (
            <VisitorsFormButton
              onClick={handleChangeEditMode}
              label={t('visitors.editFormButton')}
            />
          )}
        </VisitorsFormControlPanel>
      )}
        
      <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
              {t('visitors.formFieldLabels.userAccount')}
          </VisitorsFormFieldTitle>
          <Typography>{isLinkedUserExist ? t("visitors.user_already_exist") : t("visitors.user_does_not_exist")}</Typography>
      </VisitorsFormFieldWrapper>

      <VisitorsFormFieldWrapper>
        <VisitorsFormFieldTitle>
          {t('visitors.formFieldLabels.number')}
        </VisitorsFormFieldTitle>
        <Typography>{parseVisitorNumber(visitorId)}</Typography>
      </VisitorsFormFieldWrapper>

      <VisitorsFormFieldWrapper>
        <VisitorsFormFieldTitle>
          {t('visitors.formFieldLabels.status')}
        </VisitorsFormFieldTitle>
        <Typography>
          {t(`visitors.statuses.${visitorStatus.toLowerCase()}`)}
        </Typography>
      </VisitorsFormFieldWrapper>

      {!isVisitorDeleted && (
        <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
            {t('visitors.formFieldLabels.name')}
          </VisitorsFormFieldTitle>
          <VisitorEditFormTextField
            name="name"
            required
            disabled={!isInEditMode}
            maxLength={VISITOR_NAME_MAX_LENGTH}
          />
        </VisitorsFormFieldWrapper>
      )}

      {!isVisitorDeleted && (
        <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
            {t('visitors.formFieldLabels.email')}
          </VisitorsFormFieldTitle>
          <VisitorEditFormEmailField
            name="email"
            required
            disabled={!isInEditMode}
            maxLength={EMAIL_MAX_LENGTH}
          />
        </VisitorsFormFieldWrapper>
      )}

      {!isVisitorDeleted && (
        <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
            {t('visitors.formFieldLabels.address')}
          </VisitorsFormFieldTitle>
          <VisitorEditFormTextField
            name="address"
            disabled={!isInEditMode}
            maxLength={ADDRESS_MAX_LENGTH}
          />
        </VisitorsFormFieldWrapper>
      )}

      {!isVisitorDeleted && (
        <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
            {t('visitors.formFieldLabels.language')}
          </VisitorsFormFieldTitle>
          <VisitorSelectField
            name="language"
            options={languageList?.parameters}
            renderOptions={(options) => {
              if (!options || !options.length) return null;

              return options.map((option, index) => {
                return (
                  <option value={option.id} key={index}>
                    {option.value}
                  </option>
                );
              });
            }}
            required
            disabled={!isInEditMode}
          />
        </VisitorsFormFieldWrapper>
      )}

      {!isVisitorDeleted && (
        <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
            {t('visitors.formFieldLabels.product')}
          </VisitorsFormFieldTitle>
          <VisitorEditFormTextField disabled name="product" />
        </VisitorsFormFieldWrapper>
      )}

      <VisitorsFormFieldWrapper>
        <VisitorsFormFieldTitle>
          {t('visitors.formFieldLabels.created')}
        </VisitorsFormFieldTitle>
        <VisitorEditFormDateFIeld disabled name="created" />
      </VisitorsFormFieldWrapper>

      {!isVisitorDeleted && (
        <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
            {t('visitors.formFieldLabels.validFrom')}
          </VisitorsFormFieldTitle>
          <VisitorEditFormDateFIeld disabled name="validFrom" />
        </VisitorsFormFieldWrapper>
      )}

      {isVisitorDeleted && (
        <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
            {t('visitors.statuses.deleted')}:
          </VisitorsFormFieldTitle>
          <VisitorEditFormDateFIeld disabled name="deleted" />
        </VisitorsFormFieldWrapper>
      )}

      {!isVisitorDeleted && (
        <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
            {t('visitors.formFieldLabels.validUntil')}
          </VisitorsFormFieldTitle>
          <VisitorEditFormDateFIeld name="validUntil" required disabled />
        </VisitorsFormFieldWrapper>
      )}

      {!isVisitorDeleted && (
        <VisitorsFormFieldWrapper>
          <VisitorsFormFieldTitle>
            {t('visitors.formFieldLabels.lastLogin')}
          </VisitorsFormFieldTitle>
          <VisitorEditFormDateFIeld name="lastLogin" disabled />
        </VisitorsFormFieldWrapper>
      )}

      <ConfirmationDialog 
          showAlert={isVisitorActivationPopupOpen}
          handleClose={handleCloseVisitorActivationPopup}
          handleCloseSuccess={onVisitorActivationPopupConfirm}
          text={t('visitors.activate_visitor_title', {
              visitorName,
          })}
      />

      <ConfirmationDialog
        showAlert={isChangePasswordPopupOpen}
        handleClose={handleCloseChangePasswordPopup}
        handleCloseSuccess={onChangePasswordPopupConfirm}
        text={t('visitors.change_visitor_password_title', {
          visitorName,
        })}
      />
        
      <UserCreationDialog
          showAlert={isUserCreationPopupOpen}
          handleClose={handleCloseUserCreationPopup}
          handleCloseSuccess={handleUserCreation}
          text={t('visitors.user_creation_title')}
      />

      <ConfirmationDialog
        showAlert={isPopupOpen}
        handleClose={handleClosePopup}
        handleCloseSuccess={onPopupConfirm}
        text={t('visitors.delete_visitor_title', {
          visitorName,
        })}
      />
    </form>
    
  );
};

export default VisitorEditForm;
