import * as axios from 'axios';
import authService from '../auth/AuthorizeService';
import { BASE_URL } from '../config.js';

export const getLogList = async ({
  pageNumber,
  pageSize,
  attractionId,
  from,
  to,
  section,
  type,
  user,
}) => {
  const token = await authService.getAccessToken();
  const targetUrl = new URL(
    `${BASE_URL}/api/AttractionLogs?pageSize=${pageSize}&pageNumber=${pageNumber}&attractionId=${attractionId}`
  );

  from && targetUrl.searchParams.append('from', from);
  to && targetUrl.searchParams.append('to', to);
  section && targetUrl.searchParams.append('section', section);
  type && targetUrl.searchParams.append('type', type);
  user && targetUrl.searchParams.append('user', user);

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
