import { SET_IMAGE_RECORD_LIST, SET_IS_LOADING } from './mainImageActions';

const initialState = {
  mainImageRecordList: [],
  isLoading: false,
};

const mainImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IMAGE_RECORD_LIST:
      return {
        ...state,
        mainImageRecordList: [...action.payload.data],
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.data,
      };
    default:
      return state;
  }
};

export default mainImageReducer;
