import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';

export const maxLength = (max) => (value) =>
  value && value.length > max
    ? `Facility must be ${max} characters or less`
    : undefined;
export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);

export const requiredName = (values) => (!values ? 'Enter name' : undefined);
export const noSpaceName = (values) =>
  values.trim() === '' ? 'Name cannot consist of just spaces' : undefined;
