import { styled } from '@mui/system';
import { Box, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { ReduxAutocomplete } from '../../../../components/notificationComponents/AutocompleteInput';
import {
  CheckboxInput,
  ReduxTextField,
  ReduxSelect,
} from '../../../../components/reduxFormComponents/reduxFormComponents';
import {
  FormButton,
  SaveButton,
} from '../../../../components/buttons/FormButtons';

export const UsersAddFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 14px;
`;

export const UserFormHeaderLabel = styled(Box)`
  display: flex;
  width: 100%;
`;

export const UserFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const UserFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const UserFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const UserFormSelectField = styled(ReduxSelect)`
  max-width: 215px;
  width: 100%;
  margin-left: 0;
  margin-top: 0;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9) !important;
  }
`;

export const UserFormTextField = styled(ReduxTextField)`
  width: 100%;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const UserFormCheckbox = styled(CheckboxInput)`
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const UserFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;

export const UserFormAutocomplete = styled(ReduxAutocomplete)`
  max-width: 400px;
  width: 100%;
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9) !important;
  }
  .MuiChip-root.Mui-disabled {
    opacity: 0.9;
    pointer-events: none;
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const UserFormHeader = styled(Box)`
  display: flex;
  margin-top: 30px;
`;

export const LoaderWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const UserFormButton = styled(FormButton)`
  max-width: fit-content;
`;

export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    width: 30px;
    height: 30px;
    padding: 0;
    content: '';
    margin: 10px;
  }
`;
export const StyledClearIcon = styled(ClearIcon)`
  &.MuiSvgIcon-root {
    width: 100%;
    height: 100%;
  }
`;
