import { Controller, useFormContext } from 'react-hook-form';

import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Chip,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material';

import {
  SelectHelperText,
  SetupFormSelectFieldInput,
  StyledFormControl,
} from '../styled';
import { ACTIVE_STATUS } from '../constants';
import {useTranslation} from "react-i18next";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const createCategoriesOption = (
  optionsData,
  selectedDestinationId,
  selectedLanguage
) => {
  return optionsData
    .filter((option) => option.status === ACTIVE_STATUS)
    .map(
      (item) =>
        item.availableForDestination.includes(selectedDestinationId, 0) && (
          <MenuItem value={item.id} key={item.id}>
            <Typography variant="inherit" noWrap>
              {item.name[selectedLanguage] || item.name.en}
            </Typography>
          </MenuItem>
        )
    );
};

const SetupFormCategoryMultiselectField = ({
  name,
  required,
  disabled,
  optionsData,
  selectedDestinationId,
  selectedLanguage,
  label,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) =>
          required && !value.length ? t('required') : undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StyledFormControl error={!!error} fullWidth disabled={disabled}>
          <InputLabel disabled={disabled}>{label}</InputLabel>
          <SetupFormSelectFieldInput
            disabled={disabled}
            multiple
            onChange={(e) => onChange(e.target.value)}
            value={value}
            input={<OutlinedInput label={label} />}
            size="small"
            MenuProps={MenuProps}
            renderValue={(selectedValues) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedValues.map((selectedValue) =>
                  optionsData.map(
                    (option) =>
                      option.id === selectedValue && (
                        <Chip
                          key={selectedValue}
                          label={
                            option.name[selectedLanguage] || option.name.en
                          }
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => {
                            const newValue = value.filter(
                              (category) => category !== selectedValue
                            );

                            onChange(newValue);
                          }}
                          deleteIcon={
                            <IconButton
                              disabled={disabled}
                              sx={{
                                padding: 0,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          }
                        />
                      )
                  )
                )}
              </Box>
            )}
          >
            {!!optionsData.length &&
              createCategoriesOption(
                optionsData,
                selectedDestinationId,
                selectedLanguage
              )}
          </SetupFormSelectFieldInput>

          <SelectHelperText>{error?.message}</SelectHelperText>
        </StyledFormControl>
      )}
    />
  );
};

export default SetupFormCategoryMultiselectField;
