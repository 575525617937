import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { makeStyles } from '@mui/styles';
import { Box, Button, FormGroup, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  InputComponent,
  CheckboxInput,
  CheckboxDisabledInput,
} from '../reduxFormComponents';
import { SelectInput, SelectTimeInput } from '../Select';
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';
import SaveButton from '../buttons/SaveButton';
import FormButton from '../buttons/FormButton';

import cl from './DestinationForm.module.css';

import {
  requiredDestinationsName,
  noSpaceName,
  maxLength250,
} from '../validate';

import {
  CURRENCIES,
  TIME_ZONES,
  CONTENT_LANGUAGES
} from '../../../../consts/consts';
import { useEffect } from 'react';

const useStyles = makeStyles({
  form: {
    width: '650px',
    '& .MuiButton-root': {
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px;',
      width: '100%',
      '&.Mui-disabled': {
        height: '100%',
        textFillColor: 'rgba(0, 0, 0, 0.90)',
      },
      '&::placeholder': {
        opacity: 0.6,
      },
    },
  },
  input: {
    '& .MuiOutlinedInput-input': {
      height: '37px',
      padding: '0px 5px 0px 10px',
    },
    '&.MuiFormControl-root': {
      width: '175px',
    },
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      padding: '0px 0px 0px 14px',
      height: '37px',
      '&.Mui-disabled': {
        height: '37px',
        textFillColor: 'rgba(0, 0, 0, 0.90)',
        width: '100%',
      },
    },
  },
});

let DestinationForm = (props) => {
  const { onCancel } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [valueLevel, setValueLevel] = useState('');
  const [valueLanguages, setValueLanguages] = useState('');
  const [valueActiveLanguages, setValueActiveLanguages] = useState('');

  const onEditDestination = () => {
    props.setIsEdit(true);
  };

  const onDeleteDestination = () => {
    props.setShowAlert(true);
  };

  const handleClose = () => {
    props.setShowAlert(false);
  };

  const onChangeLevelsValue = (event) => {
    setValueLevel(event.target.value);
  };

  const onAddLevel = () => {
    if (!props.levelArray.includes(valueLevel, 0)) {
      props.setLevelArray([...props.levelArray, valueLevel]);
    }
  };

  const onDeleteLevel = (index) => {
    const filteredItems = props.levelArray
      .slice(0, index)
      .concat(props.levelArray.slice(index + 1, props.levelArray.length));
    props.setLevelArray(filteredItems);
  };

  const onChangeLanguagesValue = (event) => {
    setValueLanguages(event.target.value);
  };

  const onChangeActiveLanguagesValue = (event) => {
    setValueActiveLanguages(event.target.value);
  };

  const onAddLanguages = () => {
    if (!props.languages.includes(valueLanguages, 0)) {
      props.setLanguages([...props.languages, valueLanguages]);
      props.setShowNoLanguagesError(false);
    }
  };

  const onAddActiveLanguages = () => {
    if (!props.activeLanguages.includes(valueActiveLanguages, 0)) {
      props.setActiveLanguages([...props.activeLanguages, valueActiveLanguages]);
      props.setShowNoActiveLanguagesError(false);
    }
  };

  const onDeleteLanguages = (index) => {
    const filteredItems = props.languages
      .slice(0, index)
      .concat(props.languages.slice(index + 1, props.languages.length));
    props.setLanguages(filteredItems);
  };

  const onDeleteActiveLanguages = (index) => {
    const filteredItems = props.activeLanguages
      .slice(0, index)
      .concat(props.activeLanguages.slice(index + 1, props.activeLanguages.length));
    props.setActiveLanguages(filteredItems);
  };

  const currencyList = props.globalSettings.filter(
    (setting) => setting.name.en === CURRENCIES
  );

  const timeZoneList = props.globalSettings.filter(
    (setting) => setting.name.en === TIME_ZONES
  );

  const languageList = props.globalSettings.filter(
    (setting) => setting.name.en === CONTENT_LANGUAGES
  );

  const disabledCurrencyFieldValue = currencyList[0]?.parameters.filter(
    (currency) =>
      currency.id === props.destinationsState.currentDestination.currency
  );

  useEffect(() => {
    if (props.isEdit && props.updateDestination) {
      props.setLevelArray(
        props.destinationsState.currentDestination.valueAddedTaxes
      );
      props.setLanguages(props.destinationsState.currentDestination.languages);
      props.setActiveLanguages(props.destinationsState.currentDestination.activeLanguages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEdit, props.updateDestination]);

  return (
    <Box
      className={classes.form}
      component="form"
      onSubmit={props.handleSubmit}
    >
      <div className={cl.header}>
        <div className={cl.title}>
          {props.updateDestination
            ? props.destinationsState.currentDestination.name
            : `${t('settings_page.destination.new_destination')}`}
        </div>
        <div className={cl.buttons}>
          <FormButton
            onClick={onCancel}
            disabled={props.addDestination ? false : !props.isEdit}
            label={t('cancel')}
          />
          {props.updateDestination && (
            <>
              <FormButton
                onClick={onEditDestination}
                disabled={props.isEdit || props.isSave}
                label={t('settings_page.destination.edit_button')}
              />
              <FormButton
                onClick={onDeleteDestination}
                disabled={props.isSave}
                label={t('settings_page.destination.delete_button')}
              />
            </>
          )}
          <SaveButton
            label={t('settings_page.destination.save_button')}
            isLoading={props.isSave}
            disabled={props.addDestination || props.isEdit ? false : true}
          />
        </div>
      </div>
      <div>
        <div className={cl.currencyTitle}>
          {t('settings_page.destination.name')}:
        </div>
        <div className={cl.inputField}>
          {props.addDestination ? (
            <>
              <Field
                name="name"
                placeholder={t('settings_page.destination.name')}
                validate={[requiredDestinationsName, maxLength250, noSpaceName]}
                sx={{ width: '100%' }}
                component={InputComponent}
              />
            </>
          ) : props.updateDestination && props.isEdit ? (
            <>
              <Field
                name="name"
                placeholder={
                  props.destinationsState.currentDestination.name ||
                  `${t('settings_page.destination.name')}`
                }
                validate={[requiredDestinationsName, maxLength250, noSpaceName]}
                sx={{ width: '100%' }}
                component={InputComponent}
              />
            </>
          ) : (
            <TextField
              sx={{ width: '100%' }}
              disabled
              placeholder={
                props.destinationsState.currentDestination.name ||
                `${t('settings_page.destination.name')}`
              }
            />
          )}
        </div>
      </div>

      <div className={cl.checkboxField}>
        <label className={cl.label}>
          {t('settings_page.destination.enable')}:
        </label>
        <div className={cl.box}>
          {props.addDestination ? (
            <FormGroup row>
              <Field
                name="Region"
                component={CheckboxInput}
                value="region"
                label={t('settings_page.destination.region')}
              />
              <Field
                name="Area"
                component={CheckboxInput}
                value="area"
                label={t('settings_page.destination.area')}
              />
              <Field
                name="Location"
                component={CheckboxInput}
                value="location"
                label={t('settings_page.destination.location')}
              />
            </FormGroup>
          ) : props.updateDestination && props.isEdit ? (
            <FormGroup row>
              <Field
                name="Region"
                component={CheckboxInput}
                value="region"
                label={t('settings_page.destination.region')}
                disabled={
                  props.destinationsState.currentDestination.locations.length
                }
              />
              <Field
                name="Area"
                component={CheckboxInput}
                value="area"
                label={t('settings_page.destination.area')}
                disabled={
                  props.destinationsState.currentDestination.locations.length
                }
              />
              <Field
                name="Location"
                component={CheckboxInput}
                value="location"
                label={t('settings_page.destination.location')}
                disabled={
                  props.destinationsState.currentDestination.locations.length
                }
              />
            </FormGroup>
          ) : (
            <FormGroup row>
              <Field
                name="region"
                component={CheckboxDisabledInput}
                label={t('settings_page.destination.region')}
                disabled
                isChecked={props.destinationsState.currentDestination.enabledLocationTypes.includes(
                  'Region',
                  0
                )}
              />
              <Field
                name="area"
                component={CheckboxDisabledInput}
                label={t('settings_page.destination.area')}
                disabled
                isChecked={props.destinationsState.currentDestination.enabledLocationTypes.includes(
                  'Area',
                  0
                )}
              />
              <Field
                name="location"
                component={CheckboxDisabledInput}
                label={t('settings_page.destination.location')}
                disabled
                isChecked={props.destinationsState.currentDestination.enabledLocationTypes.includes(
                  'Location',
                  0
                )}
              />
            </FormGroup>
          )}
        </div>
      </div>
      <div className={cl.timeWrapper}>
        <div className={cl.currencyTitle}>
          {t('settings_page.destination.time_zone')}:
        </div>
        <div className={cl.addBoxTimeZone}>
          {props.addDestination ? (
            <Field name="timeZoneId" component={SelectTimeInput}>
              {timeZoneList[0]?.parameters.map((item) => (
                <option value={item.value.id} key={item.id}>
                  {item.value.displayName}
                </option>
              ))}
            </Field>
          ) : props.updateDestination && props.isEdit ? (
            <Field
              name="timeZoneId"
              component={SelectTimeInput}
              valuetext={
                props.destinationsState.currentDestination.timeZone.displayName
              }
              isEdit={props.isEdit}
            >
              {timeZoneList[0]?.parameters.map((item) => (
                <option value={item.value.id} key={item.id}>
                  {item.value.displayName}
                </option>
              ))}
            </Field>
          ) : (
            <TextField
              className={classes.textField}
              name="timeZoneId"
              placeholder={
                props.destinationsState.currentDestination.timeZone
                  .displayName || `${t('settings_page.destination.time_zone')}`
              }
              disabled
              sx={{ width: '100%' }}
            />
          )}
        </div>
      </div>
      <div className={cl.currencyWrapper}>
        <div className={cl.currencyTitle}>
          {t('settings_page.destination.currency_code')}:
        </div>
        <div className={cl.currencyAddBox}>
          {props.addDestination ? (
            <Field name="currency" component={SelectInput}>
              {currencyList[0]?.parameters.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.value}
                </option>
              ))}
            </Field>
          ) : props.updateDestination && props.isEdit ? (
            <Field
              name="currency"
              component={SelectInput}
              valuetext={props.destinationsState.currentDestination.currency}
              isEdit={props.isEdit}
            >
              {currencyList[0]?.parameters.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.value}
                </option>
              ))}
            </Field>
          ) : (
            <TextField
              className={classes.textField}
              name="currency"
              placeholder={
                disabledCurrencyFieldValue[0]?.value ||
                `${t('settings_page.destination.currency')}`
              }
              disabled
            />
          )}
        </div>
      </div>
      <div className={cl.levelWrapper}>
        <div className={cl.levelTitle}>
          {t('settings_page.destination.vat_levels')}:
        </div>
        {props.addDestination ? (
          <>
            <div className={cl.addBox}>
              <TextField
                className={classes.input}
                value={valueLevel}
                placeholder={t('settings_page.destination.vat_levels')}
                onChange={onChangeLevelsValue}
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
              />
              <Button
                variant="contained"
                sx={{ color: '#fff', height: '35px', marginLeft: '10px' }}
                type="button"
                onClick={onAddLevel}
              >
                {t('settings_page.destination.add_levels_button')}
              </Button>
            </div>
            {props.error && props.error.ValueAddedTaxes && (
              <Typography
                sx={{ color: '#d43131' }}
                variant="caption"
                display="block"
                component="span"
              >
                {`* ${props.error.ValueAddedTaxes[0]}`}
              </Typography>
            )}
          </>
        ) : props.updateDestination && props.isEdit ? (
          <>
            <div className={cl.addBox}>
              <TextField
                className={classes.input}
                value={valueLevel}
                placeholder={t('settings_page.destination.vat_levels')}
                onChange={onChangeLevelsValue}
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
              />
              <Button
                variant="contained"
                sx={{ color: '#fff', height: '35px', marginLeft: '10px' }}
                type="button"
                onClick={onAddLevel}
                disabled={!valueLevel}
              >
                {t('settings_page.destination.add_levels_button')}
              </Button>
            </div>
            {props.error && props.error.ValueAddedTaxes && (
              <Typography
                sx={{ color: '#d43131' }}
                variant="caption"
                display="block"
                component="span"
              >
                {`* ${props.error.ValueAddedTaxes[0]}`}
              </Typography>
            )}
          </>
        ) : null}
        <div className={cl.levelBox}>
          {props.addDestination
            ? props.levelArray.map((item, index) => (
                <div className={cl.levelItem} key={index}>
                  {`${item}%`}
                  <CloseIcon
                    className={cl.closeIcon}
                    onClick={(e) => onDeleteLevel(index)}
                    sx={{ width: '15px', height: '15px' }}
                  />
                </div>
              ))
            : props.updateDestination && props.isEdit
            ? props.levelArray.map((item, index) => (
                <div className={cl.levelItem} key={index}>
                  {`${item}%`}
                  <CloseIcon
                    className={cl.closeIcon}
                    onClick={(e) => onDeleteLevel(index)}
                    sx={{ width: '15px', height: '15px' }}
                  />
                </div>
              ))
            : props.destinationsState.currentDestination.valueAddedTaxes.map(
                (item, index) => (
                  <div className={cl.levelItem} key={index}>
                    {`${item}%`}
                  </div>
                )
              )}
        </div>
      </div>
      <div className={cl.languagesWrapper}>
        <div className={cl.languagesTitle}>
          {t('settings_page.destination.content_languages')}:
        </div>
        <div className={cl.languageAddBox}>
          {props.addDestination ? (
            <>
              <Field
                name="languages"
                component={SelectInput}
                onChange={onChangeLanguagesValue}
                valuetext={valueLanguages}
              >
                <option value="">{t('settings_page.destination.none')}</option>
                {languageList[0]?.parameters.map((item) => (
                  <option value={item.value} key={item.id}>
                    {item.value}
                  </option>
                ))}
              </Field>

              <Button
                variant="contained"
                sx={{ color: '#fff', height: '35px', marginLeft: '10px' }}
                type="button"
                onClick={onAddLanguages}
              >
                {t('settings_page.destination.add_languages_button')}
              </Button>
            </>
          ) : props.updateDestination && props.isEdit ? (
            <>
              <Field
                name="languages"
                component={SelectInput}
                sx={{ width: '125px', height: '37px' }}
                onChange={onChangeLanguagesValue}
                valuetext={valueLanguages}
              >
                <option value="">{t('settings_page.destination.none')}</option>
                {languageList[0]?.parameters.map((item) => (
                  <option value={item.value} key={item.id}>
                    {item.value}
                  </option>
                ))}
              </Field>
              <Button
                variant="contained"
                sx={{ color: '#fff', height: '35px', marginLeft: '10px' }}
                type="button"
                onClick={onAddLanguages}
                disabled={!valueLanguages}
              >
                {t('settings_page.destination.add_languages_button')}
              </Button>
            </>
          ) : null}
        </div>
        {props.showNoLanguagesError && (
          <Typography variant="caption" color="error">
            {t('settings_page.destination.error_no_languages_selected')}
          </Typography>
        )}
        <div className={cl.languagesBox}>
          {props.addDestination
            ? props.languages.map((item, index) => (
                <div className={cl.languagesItem} key={index}>
                  {`${item}`}
                  <CloseIcon
                    className={cl.closeIcon}
                    onClick={(e) => onDeleteLanguages(index)}
                    sx={{ width: '15px', height: '15px' }}
                  />
                </div>
              ))
            : props.updateDestination && props.isEdit
            ? props.languages.map((item, index) => (
                <div className={cl.languagesItem} key={index}>
                  {`${item}`}
                  <CloseIcon
                    className={cl.closeIcon}
                    onClick={(e) => onDeleteLanguages(index)}
                    sx={{ width: '15px', height: '15px' }}
                  />
                </div>
              ))
            : props.destinationsState.currentDestination.languages.map(
                (item, index) => (
                  <div className={cl.levelItem} key={index}>
                    {`${item}`}
                  </div>
                )
              )}
        </div>
      </div>

      <div className={cl.languagesWrapper}>
        <div className={cl.languagesTitle}>
          {t('settings_page.destination.active_languages')}:
        </div>
        <div className={cl.languageAddBox}>
          {props.addDestination ? (
            <>
              <Field
                name="activeLanguages"
                component={SelectInput}
                onChange={onChangeActiveLanguagesValue}
                valuetext={valueActiveLanguages}
              >
                <option value="">{t('settings_page.destination.none')}</option>
                {languageList[0]?.parameters.map((item) => (
                  <option value={item.value} key={item.id}>
                    {item.value}
                  </option>
                ))}
              </Field>

              <Button
                variant="contained"
                sx={{ color: '#fff', height: '35px', marginLeft: '10px' }}
                type="button"
                onClick={onAddActiveLanguages}
              >
                {t('settings_page.destination.add_languages_button')}
              </Button>
            </>
          ) : props.updateDestination && props.isEdit ? (
            <>
              <Field
                name="activeLanguages"
                component={SelectInput}
                sx={{ width: '125px', height: '37px' }}
                onChange={onChangeActiveLanguagesValue}
                valuetext={valueActiveLanguages}
              >
                <option value="">{t('settings_page.destination.none')}</option>
                {languageList[0]?.parameters.map((item) => (
                  <option value={item.value} key={item.id}>
                    {item.value}
                  </option>
                ))}
              </Field>
              <Button
                variant="contained"
                sx={{ color: '#fff', height: '35px', marginLeft: '10px' }}
                type="button"
                onClick={onAddActiveLanguages}
                disabled={!valueActiveLanguages}
              >
                {t('settings_page.destination.add_languages_button')}
              </Button>
            </>
          ) : null}
        </div>
        {props.showNoActiveLanguagesError && (
          <Typography variant="caption" color="error">
            {t('settings_page.destination.error_no_languages_selected')}
          </Typography>
        )}
        <div className={cl.languagesBox}>
          {props.addDestination
            ? props.activeLanguages.map((item, index) => (
                <div className={cl.languagesItem} key={index}>
                  {`${item}`}
                  <CloseIcon
                    className={cl.closeIcon}
                    onClick={(e) => onDeleteActiveLanguages(index)}
                    sx={{ width: '15px', height: '15px' }}
                  />
                </div>
              ))
            : props.updateDestination && props.isEdit
            ? props.activeLanguages.map((item, index) => (
                <div className={cl.languagesItem} key={index}>
                  {`${item}`}
                  <CloseIcon
                    className={cl.closeIcon}
                    onClick={(e) => onDeleteActiveLanguages(index)}
                    sx={{ width: '15px', height: '15px' }}
                  />
                </div>
              ))
            : props.destinationsState.currentDestination.activeLanguages.map(
                (item, index) => (
                  <div className={cl.levelItem} key={index}>
                    {`${item}`}
                  </div>
                )
              )}
        </div>
      </div>
      <ConfirmationDialog
        showAlert={props.showAlert}
        handleClose={handleClose}
        handleCloseSuccess={props.handleCloseSuccess}
        text={t('settings_page.destination.delete_destination')}
      />
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.isEdit) {
    return {
      initialValues: {
        name: state.settingsReducer.currentDestination.name,
        Region:
          state.settingsReducer.currentDestination.enabledLocationTypes.includes(
            'Region',
            0
          )
            ? true
            : false,
        Area: state.settingsReducer.currentDestination.enabledLocationTypes.includes(
          'Area',
          0
        )
          ? true
          : false,
        Location:
          state.settingsReducer.currentDestination.enabledLocationTypes.includes(
            'Location',
            0
          )
            ? true
            : false,
        currency: state.settingsReducer.currentDestination.currency,
        timeZoneId: state.settingsReducer.currentDestination.timeZone.id,
      },
    };
  } else return { initialValues: {} };
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'Destination', enableReinitialize: true })(DestinationForm)
);
