import { useTranslation } from 'react-i18next';

import {
  AdvertContentFormSubmitButton,
  AdvertContentFormButton,
  ControlPanelContainer,
} from '../../styled';
import { Box, Typography } from '@mui/material';

const AdvertContentFormControlPanel = (props) => {
  const {
    showSaveAnimation,
    isInEditMode,
    onSubmit,
    onCancel,
    onEdit,
    onDelete,
    isInAddMode,
  } = props;

  const { t } = useTranslation();

  return (
    <Box
      mt="12px"
      display="flex"
      pl="16px"
      pr="16px"
      justifyContent="space-between"
    >
      {isInAddMode ? (
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('advertWindow.new_item_headline')}
        </Typography>
      ) : (
        <div></div>
      )}
      <ControlPanelContainer>
        <AdvertContentFormButton
          onClick={onCancel}
          label={t('cancel')}
          disabled={!isInEditMode}
        />
        {!isInAddMode && (
          <>
            <AdvertContentFormButton
              onClick={onEdit}
              label={t('edit')}
              disabled={isInEditMode}
            />
            <AdvertContentFormButton onClick={onDelete} label={t('delete')} />
          </>
        )}
        <AdvertContentFormSubmitButton
          onClick={onSubmit}
          label={t('save')}
          disabled={!isInEditMode}
          isLoading={showSaveAnimation}
        />
      </ControlPanelContainer>
    </Box>
  );
};

export default AdvertContentFormControlPanel;
