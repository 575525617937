import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';

import { CircularProgress } from '@mui/material';

import NewDealAddForm from './NewDealAddForm';
import { createDealObject } from '../helpers';

import useAPIError from '../../../../apIErrorProvider/useAPIError';

import { addDealThunk } from '../../../../redux/attractionsReducers/deals/dealsActions';

import { DEAL_STATUS, DEAL_BENEFITS } from '../../../../consts/consts';

const DealAddFormContainer = (props) => {
  const {
    dealStatuses,
    benefitOptions,
    languages,
    currentAttractionId,
    selectedLanguage,
  } = props;

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addError } = useAPIError();

  const handleSubmitForm = (values) => {
    setShowSaveAnimation(true);
    const newDeal = createDealObject(currentAttractionId, values);
    dispatch(addDealThunk(newDeal.attractionId, newDeal))
      .then(() => {
        setShowSaveAnimation(false);
        navigate(-1);
        addError(`${t('attractions.deals.success_add_deal')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('attractions.deals.error_add_deal')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleCancelForm = () => {
    navigate(-1);
  };

  return (
    <>
      {dealStatuses && benefitOptions && languages ? (
        <NewDealAddForm
          dealStatuses={dealStatuses}
          benefitOptions={benefitOptions}
          selectedLanguage={selectedLanguage}
          languages={languages}
          handleSubmitAddForm={handleSubmitForm}
          handleCancelAddForm={handleCancelForm}
          showSaveAnimation={showSaveAnimation}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const dealStatuses =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_STATUS
    ) || null;
  const benefitOptions =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_BENEFITS
    ) || null;

  const languages = state.appReducer.selectedDestination.languages;

  return {
    dealStatuses,
    benefitOptions,
    languages,
    currentAttractionId: state.attractionsReducer.currentAttraction.id,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps)(DealAddFormContainer);
