import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import DestinationForm from './DestinationForm';
import { DestinationFormWrapper } from './styled';

import {
  getDestinationThunk,
  addDestinationThunk,
} from '../../../../redux/settingsReducers/settingsReducer';

import { DESTINATION_PAGE_ROUTE } from '../constants';

const DestinationAddFormContainer = (props) => {
  const {
    settingsState,
    globalSettings,
    getDestinationThunk,
    addDestinationThunk,
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [levelArray, setLevelArray] = useState([]);
  const [newDestinationLanguages, setNewDestinationLanguages] = useState([]);
  const [newDestinationActiveLanguages, setNewDestinationActiveLanguages] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showNoLanguagesError, setShowNoLanguagesError] = useState(false);
  const [showNoActiveLanguagesError, setShowNoActiveLanguagesError] = useState(false);

  const handleDestinationFormSubmit = (values) => {
    if (!newDestinationLanguages.length) {
      setShowNoLanguagesError(true);
      addError(t('settings_page.destination.error_no_languages_selected'), 'Error');
    } else if (!newDestinationActiveLanguages.length) {
      setShowNoActiveLanguagesError(true);
      addError(t('settings_page.destination.error_no_languages_selected'), 'Error');
    } else {
      setShowNoLanguagesError(false);
      setShowNoActiveLanguagesError(false)
      setShowSaveAnimation(true);

      let array = [];

      if (values.Region) {
        array.push('Region');
      }
      if (values.Area) {
        array.push('Area');
      }
      if (values.Location) {
        array.push('Location');
      }

      const data = {
        name: values.name.trim(),
        enabledLocationTypes: array,
        valueAddedTaxes: levelArray,
        timeZoneId: values.timeZoneId || 'Europe/Berlin',
        currency: values.currency || 'USD',
        languages: newDestinationLanguages,
        activeLanguages: newDestinationActiveLanguages
      };

      addDestinationThunk(data)
        .then(() => {
          getDestinationThunk();
          setShowSaveAnimation(false);
          addError(
            `${t('settings_page.destination.error_add_text')}`,
            'Success'
          );
          navigate(DESTINATION_PAGE_ROUTE);
        })
        .catch((error) => {
          addError(
            `${t('settings_page.destination.error_not_add_text')}`,
            'Error',
            error
          );
          setShowSaveAnimation(false);
        });
    }
  };

  const handleFormCancel = () => {
    navigate(DESTINATION_PAGE_ROUTE);
  };

  return (
    <DestinationFormWrapper>
      <DestinationForm
        addDestination
        destinationsState={settingsState}
        onSubmit={handleDestinationFormSubmit}
        updateDestination={false}
        isEdit={false}
        setIsEdit={null}
        levelArray={levelArray}
        setLevelArray={setLevelArray}
        languages={newDestinationLanguages}
        activeLanguages={newDestinationActiveLanguages}
        globalSettings={globalSettings}
        setLanguages={setNewDestinationLanguages}
        setActiveLanguages={setNewDestinationActiveLanguages}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        handleCloseSuccess={null}
        isSave={showSaveAnimation}
        showNoLanguagesError={showNoLanguagesError}
        setShowNoLanguagesError={setShowNoLanguagesError}
        showNoActiveLanguagesError={showNoActiveLanguagesError}
        setShowNoActiveLanguagesError={setShowNoActiveLanguagesError}
        onCancel={handleFormCancel}
      />
    </DestinationFormWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    settingsState: state.settingsReducer,
    globalSettings: state.appReducer.globalSettingsData,
  };
};

export default connect(mapStateToProps, {
  getDestinationThunk,
  addDestinationThunk,
})(DestinationAddFormContainer);
