import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';

import { CircularProgress } from '@mui/material';

import NewDealAddForm from './NewDealAddForm';
import { createDealObject } from '../helpers';

import useAPIError from '../../../apIErrorProvider/useAPIError';

import { addDealThunk } from '../../../redux/attractionsReducers/deals/dealsActions';
import { getSmallAttractionsDataThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions';

import { DEAL_STATUS, DEAL_BENEFITS } from '../../../consts/consts';

const DealGeneralAddFormContainer = (props) => {
  const {
    dealStatuses,
    benefitOptions,
    availableAttractions,
    languages,
    selectedLanguage,
    selectedDestinationId
  } = props;

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addError } = useAPIError();

  const handleSubmitForm = (values) => {
    setShowSaveAnimation(true);
    const newDeal = createDealObject(values.attractionId, values);
    dispatch(addDealThunk(newDeal.attractionId, newDeal))
      .then(() => {
        setShowSaveAnimation(false);
        navigate(-1);
        addError(`${t('attractions.deals.success_add_deal')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('attractions.deals.error_add_deal')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleCancelForm = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getSmallAttractionsDataThunk(selectedDestinationId));
  }, [dispatch, selectedDestinationId]);

  return (
    <>
      {dealStatuses && benefitOptions && languages ? (
        <NewDealAddForm
          dealStatuses={dealStatuses}
          benefitOptions={benefitOptions}
          availableAttractions={availableAttractions}
          selectedLanguage={selectedLanguage}
          languages={languages}
          handleSubmitAddForm={handleSubmitForm}
          handleCancelAddForm={handleCancelForm}
          showSaveAnimation={showSaveAnimation}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const dealStatuses =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_STATUS
    ) || null;
  const benefitOptions =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_BENEFITS
    ) || null;

  const languages = state.appReducer.selectedDestination.languages;

  const selectedDestinationId = state.appReducer.selectedDestination.id;

  const availableAttractions =
    state.attractionsReducer.attractionsSmallData.sort(function (a, b) {
      var textA = a.name.toUpperCase().trim();
      var textB = b.name.toUpperCase().trim();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

  return {
    dealStatuses,
    benefitOptions,
    availableAttractions,
    languages,
    selectedLanguage: state.appReducer.selectedLanguage,
    selectedDestinationId
  };
};

export default connect(mapStateToProps)(DealGeneralAddFormContainer);
