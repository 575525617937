import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Box, Typography } from '@mui/material';

import FactsFormLanguageTabs from './languageTabs/FactsFormLanguageTabs';

import {
  ControlPanelContainer,
  FactsFormButton,
  FactsFormSubmitButton,
} from '../styled';

const AddNewFactForm = ({ languageList, isLoading, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [activeTabId, setActiveTabId] = useState(languageList[0]);

  const handleLanguageTabChange = (_, newValue) => setActiveTabId(newValue);

  const { handleSubmit } = useFormContext();

  return (
    <form>
      <Box mt="30px" display="flex" justifyContent="space-between">
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('facts.new_fact_headline')}
        </Typography>
        <ControlPanelContainer>
          <FactsFormButton onClick={onCancel} label={t('cancel')} />
          <FactsFormSubmitButton
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            label={t('save')}
          />
        </ControlPanelContainer>
      </Box>

      <FactsFormLanguageTabs
        activeTabId={activeTabId}
        onLanguageTabChange={handleLanguageTabChange}
        languages={languageList}
      />
    </form>
  );
};

export default AddNewFactForm;
