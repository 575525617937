import { styled } from '@mui/system';
import { Button, Box, Typography } from '@mui/material';

import {
  FormButton,
  SaveButton,
} from '../../../components/buttons/FormButtons';
import { CheckboxDisabledInput, CheckboxInput } from './FormComponents';

export const CategoryAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const CategoryFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const CategoryFormFieldTitle = styled(Typography)`
  max-width: 150px;
  width: 100%;
  color: black;
`;

export const CategoryFormSelectWrapper = styled(Box)`
  max-width: 185px;
  width: 100%;
`;

export const CategoryFormHeader = styled(Box)`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
`;

export const CategoryFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const CategoryFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const CategoryFormButton = styled(FormButton)`
  max-width: fit-content;
`;

export const CategoryFormImageDropWrapper = styled(Box)`
  max-width: 400px;
  width: 100%;
`;

export const CategoryFormWrapper = styled(Box)`
  margin-top: 30px;
`;

export const CategoryFormCheckbox = styled(CheckboxInput)`
  white-space: nowrap;
  width: 100%;

  .MuiFormControlLabel-label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const CategoryFormCheckboxDisabled = styled(CheckboxDisabledInput)`
  white-space: nowrap;
  width: 100%;

  .MuiFormControlLabel-label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
