import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { ReviewFormDateField } from './formFields';

import {
  maxLength250,
  hasNotOnlySpaces,
  required,
  maxLength2000, 
  maxLength50,
} from './validation';

import {
  ReviewFormControlPanel,
  ReviewFormFieldWrapper,
  ReviewFormFieldTitle,
  ReviewFormTextField,
  ReviewFormSelectField,
  ReviewFormSubmitButton,
  ReviewFormButton,
  ReviewFormContainer,
  ReviewFormHeader,
  ReviewFormHeaderLabel,
  ReviewFormDateFieldWrapper,
  ReviewFormAutocomplete,
} from '../styled';

import {
  NEW_REVIEW_OBJECT_DEFAULT_VALUES,
  REVIEW_FORM,
  REVIEW_RATING,
  REVIEW_TEXT_FIELD_ROWS_AMOUNT,
} from '../constants';

import {
  mapAttractionListToOptionList,
  getAttractionOptionLabel,
} from '../utils';
import { useNavigationPrompt } from '../../../../hooks/navigation';
import {useCallback} from "react";

let ReviewForm = (props) => {
  const {
    onEdit,
    isInEditMode,
    selectedLanguage,
    onDelete,
    handleSubmit,
    onCancel,
    showSaveAnimation,
    statusOptionList,
    isInAddMode,
    isGlobalReview,
    attractionList,
    dirty,
    submitSucceeded,
  } = props;

  const { t } = useTranslation();

  const attractionOptionList = mapAttractionListToOptionList(attractionList);

  const blockNavigation = dirty && !submitSucceeded;

  const required = useCallback(value => (value ? undefined : t('required')), [selectedLanguage]);

  useNavigationPrompt(blockNavigation);

  return (
    <form onSubmit={handleSubmit}>
      <ReviewFormContainer>
        <ReviewFormHeader>
          {isInAddMode && (
            <ReviewFormHeaderLabel>
              <Typography fontWeight="bold" textTransform="uppercase">
                {t('reviews.new_review')}
              </Typography>
            </ReviewFormHeaderLabel>
          )}
          <ReviewFormControlPanel>
            <ReviewFormButton
              onClick={onCancel}
              disabled={!isInEditMode}
              label={t('reviews.cancel')}
            />
            {!isInAddMode && (
              <>
                <ReviewFormButton
                  disabled={isInEditMode}
                  onClick={onEdit}
                  label={t('reviews.edit')}
                />
                <ReviewFormButton
                  disabled={isInEditMode}
                  onClick={onDelete}
                  label={t('reviews.delete')}
                />
              </>
            )}
            <ReviewFormSubmitButton
              loading={showSaveAnimation}
              disabled={!isInEditMode}
              label={t('notifications.save')}
            />
          </ReviewFormControlPanel>
        </ReviewFormHeader>

        {isInAddMode && (
          <ReviewFormFieldWrapper>
            <ReviewFormFieldTitle>{t('reviews.status')}</ReviewFormFieldTitle>
            <Field
              name="status"
              component={ReviewFormSelectField}
              disabled={!isInEditMode}
            >
              {statusOptionList?.parameters.map((status) => (
                <option value={status.id} key={status.id}>
                  {status.value[selectedLanguage] || status.value.en}
                </option>
              ))}
            </Field>
          </ReviewFormFieldWrapper>
        )}

        {isGlobalReview && (
          <ReviewFormFieldWrapper>
            <ReviewFormFieldTitle>
              {t('reviews.attraction')}:
            </ReviewFormFieldTitle>
            <Field
              name="attractionId"
              component={ReviewFormAutocomplete}
              options={attractionOptionList}
              getOptionLabel={getAttractionOptionLabel(attractionList)}
              validate={[required]}
              isOptionEqualToValue={(option, value) => {
                return value === option;
              }}
              disablePortal
            />
          </ReviewFormFieldWrapper>
        )}

        {isInAddMode && (
          <ReviewFormFieldWrapper>
            <ReviewFormFieldTitle>{t('reviews.rating')}</ReviewFormFieldTitle>
            <Field
              name="rating"
              component={ReviewFormSelectField}
              disabled={!isInEditMode}
            >
              {REVIEW_RATING.map((rating, i) => (
                <option value={rating.id} key={i}>
                  {rating.id}
                </option>
              ))}
            </Field>
          </ReviewFormFieldWrapper>
        )}

        {isInAddMode && (
          <ReviewFormFieldWrapper>
            <ReviewFormFieldTitle>{t('reviews.name')}</ReviewFormFieldTitle>
            <Field
              name="userName"
              component={ReviewFormTextField}
              disabled={!isInEditMode}
              validate={[hasNotOnlySpaces, maxLength250]}
            />
          </ReviewFormFieldWrapper>
        )}

        {isInAddMode && (
          <ReviewFormFieldWrapper>
            <ReviewFormFieldTitle>{t('reviews.date')}</ReviewFormFieldTitle>
            <ReviewFormDateFieldWrapper>
              <Field
                name="date"
                component={ReviewFormDateField}
                disabled={!isInEditMode}
                validate={[required]}
              />
            </ReviewFormDateFieldWrapper>
          </ReviewFormFieldWrapper>
        )}

        {isInAddMode && (
          <ReviewFormFieldWrapper>
            <ReviewFormFieldTitle>{t('reviews.place')}</ReviewFormFieldTitle>
            <Field
              name="userPlace"
              component={ReviewFormTextField}
              validate={[hasNotOnlySpaces, maxLength250]}
            />
          </ReviewFormFieldWrapper>
        )}

        <ReviewFormFieldWrapper>
          <ReviewFormFieldTitle>
            {t('reviews.review_text')}
          </ReviewFormFieldTitle>
          <Field
            name="text"
            component={ReviewFormTextField}
            multiline
            rows={REVIEW_TEXT_FIELD_ROWS_AMOUNT}
            disabled={!isInEditMode}
            validate={[hasNotOnlySpaces]}
          />
        </ReviewFormFieldWrapper>

        {!isInAddMode && (
          <ReviewFormFieldWrapper>
            <ReviewFormFieldTitle>{t('reviews.status')}</ReviewFormFieldTitle>
            <Field
              name="status"
              component={ReviewFormSelectField}
              disabled={!isInEditMode}
            >
              {statusOptionList?.parameters.map((status) => (
                <option value={status.id} key={status.id}>
                  {status.value[selectedLanguage] || status.value.en}
                </option>
              ))}
            </Field>
          </ReviewFormFieldWrapper>
        )}

        <ReviewFormFieldWrapper>
          <ReviewFormFieldTitle>{t('reviews.respondentPosition')}</ReviewFormFieldTitle>
          <Field
              name="respondentPosition"
              component={ReviewFormTextField}
              disabled={!isInEditMode}
              validate={[hasNotOnlySpaces, maxLength50]}
          />
        </ReviewFormFieldWrapper>

        <ReviewFormFieldWrapper>
          <ReviewFormFieldTitle>{t('reviews.reply')}</ReviewFormFieldTitle>
          <Field
            name="reply"
            component={ReviewFormTextField}
            multiline
            rows={REVIEW_TEXT_FIELD_ROWS_AMOUNT}
            disabled={!isInEditMode}
            validate={[hasNotOnlySpaces, maxLength2000]}
          />
        </ReviewFormFieldWrapper>
      </ReviewFormContainer>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.reviewData) {
    return {
      form: REVIEW_FORM + ownProps.reviewData.id,

      initialValues: {
        status: ownProps.reviewData.status,
        text: ownProps.reviewData.text,
        respondentPosition: ownProps.reviewData.respondentPosition,
        reply: ownProps.reviewData.reply,
      },
    };
  }

  return {
    initialValues: {
      ...NEW_REVIEW_OBJECT_DEFAULT_VALUES,
    },
  };
};

ReviewForm = reduxForm({
  form: REVIEW_FORM,
  enableReinitialize: true,
})(ReviewForm);

export default connect(mapStateToProps)(ReviewForm);
