export const REVIEW_FORM = 'ReviewForm';
export const DEFAULT_REVIEW_LANGUAGE = 'en';

export const REVIEW_RATING = [
  { id: '5', value: '*****' },
  { id: '4', value: '****' },
  { id: '3', value: '***' },
  { id: '2', value: '**' },
  { id: '1', value: '*' },
];

export const NEW_REVIEW_OBJECT_DEFAULT_VALUES = {
  status: 'New',
  attractionId: null,
  userName: '',
  text: '',
  language: DEFAULT_REVIEW_LANGUAGE,
  rating: '5',
  date: null,
  userPlace: '',
  reply: '',
};

export const REVIEW_ROW_CELLS_AMOUNT = 8;
export const REVIEW_TEXT_FIELD_ROWS_AMOUNT = 5;
