import { useState, useMemo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { isValid } from 'date-fns';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import DealEditFormContainer from '../newDealForm/DealEditFormContainer';
import DealEditForm from '../newDealForm/DealEditForm';

import { formatLanguageFieldsData } from '../helpers';
import { EXACT, EXPIRED, NOT_LIMITED } from '../consts';
import { DealContext } from '../context/DealContext';
import { useNavigationPrompt } from '../../../hooks/navigation';

import cl from './styles/Deal.module.scss';

const getFormatedDate = (date) => {
  return dayjs(date).format('DD.MM.YYYY');
};

const statusIndicator = {
  active: cl.backgroundOrange,
  inactive: cl.backgroundBlue,
  expired: cl.backgroundGrey,
  pending: cl.backgroundRed
};

const CreateAccordionTitle = (
  item,
  dealStatuses,
  expanded,
  selectedLanguage,
  benefitOptions,
  languages
) => {
  const { t } = useTranslation();
  const benefit = benefitOptions.parameters.filter(
    (benefit) => benefit.id === item.benefit
  )[0].value[languages[0]];

  return (
    <AccordionSummary
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      className={clsx(cl.accordionTitle, { [cl.backgroundOrange]: expanded })}
    >
      <TableCell
        padding="normal"
        className={clsx(
          cl.accTitleCell,
          statusIndicator[item.status.toLowerCase()]
        )}
      >
        {t(`attractions.deals.statuses.${item.status.toLowerCase()}`)}
      </TableCell>

      <TableCell padding="normal" className={cl.accTitleCell}>
        {item.attractionName ? item.attractionName : "REMOVED"}
      </TableCell>
      <TableCell align="center" padding="normal" className={cl.accTitleCell}>
        {benefit}
      </TableCell>
      <TableCell padding="normal" className={cl.accTitleCell}>
        {item.title[languages[0]]}
        {/* {getTranslatedField(item.title, selectedLanguage)} */}
      </TableCell>
      <TableCell padding="normal" className={cl.accTitleCell}>
        {getFormatedDate(item.startDate)}
      </TableCell>
      <TableCell padding="normal" className={cl.accTitleCell}>
        {item.endDate !== null
          ? getFormatedDate(item.endDate)
          : t('attractions.deals.open')}
      </TableCell>
      <TableCell align="center" className={cl.accTitleCell}>
        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </TableCell>
    </AccordionSummary>
  );
};

const Deal = ({dealStatus, item, expanded, onExpandChange }) => {
  const { t } = useTranslation();
  const {
    formActions,
    selectedLanguage,
    benefitOptions,
    languages,
    dealStatuses,
  } = useContext(DealContext);

  const [isFormEdit, setIsFormEdit] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [currentTabId, setCurrentTabId] = useState(languages[0]);

  const parsedLanguageFormFieldArrays = useMemo(
    () =>
      languages.reduce((acc, lang) => {
        acc[lang] = [
          {
            title: item.title[lang] || '',
            formula: item.formula[lang] || '',
            note: item.note[lang] || '',
            redeemInstructions: item.redeemInstructions[lang] || '',
          },
        ];

        return acc;
      }, {}),
    [item.formula, item.note, item.title, item.redeemInstructions, languages]
  );
  
  const defaultValues = {
    dealStatus:
      item.status === EXPIRED
        ? dealStatuses.parameters[0].id
        : item.status,
    benefits: item.benefit,
    percentage: item.percentage,
    dealStartDate: new Date(item.startDate),
    dealEndDate: item.endDate,
    numberOfUses: item.numberOfUses,
    isEndDateOpen: item.endDate ? false : true,
    isUsesLimited: item.numberOfUsesType === NOT_LIMITED ? true : false,
    ...parsedLanguageFormFieldArrays,
  };

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = formMethods;

  useNavigationPrompt(isDirty);

  const isEndDateOpenCheckbox = watch('isEndDateOpen');
  const usesLimitCheckboxValue = watch('isUsesLimited');
  const activeBenefitValue = watch('benefits');
  const startDateFieldValue = watch('dealStartDate');

  const dealStartDateRules = {
    validate: (value) =>
      isValid(value) ? value : t('attractions.deals.error_invalid_date'),
    required: {
      value: true,
      message: t('attractions.deals.error_required'),
    },
  };

  const dealEndDateRules = {
    required: {
      value: !isEndDateOpenCheckbox,
      message: t('attractions.deals.error_required'),
    },
  };

  const handleChangeLanguageTab = (_, newValue) => setCurrentTabId(newValue);

  const handleFormSubmit = (formData) => {
    setIsFormEdit(false);
    formActions.save(formData);
    onExpandChange(null)(null);
  };

  const handleFormEdit = () => {
    setIsFormEdit(true);
  };

  const handleFormCancel = () => {
    setIsFormEdit(false);
  };

  const handleFormDelete = () => {
    setIsFormEdit(false);
    setShowDeleteAlert(true);
  };

  const handleDeleteConfirmation = (itemId) => {
    setShowDeleteAlert(false);
    setIsFormEdit(false);
    formActions.delete(itemId);
  };

  const handleDeleteCancellation = () => {
    setShowDeleteAlert(false);
  };

  const handleExpandDealAccordion = (e) => {
    onExpandChange(item.id)(e, !expanded);
    handleCancelChanges();
  };

  const handleCancelChanges = () => {
    handleFormCancel();
    reset(defaultValues, { keepDefaultValues: true });
  };

  const handleDealApprove = () => {
    setIsFormEdit(false);
    formActions.approve(item.id);
  }

  const onSave = (data) => {
    const generalFieldsData = {
      id: item.id,
      status: data.dealStatus,
      benefit: data.benefits,
      percentage: data.percentage,
      startDate: data.dealStartDate,
      endDate: data.dealEndDate || null,
      numberOfUses: data.isUsesLimited ? null : data.numberOfUses,
      numberOfUsesType: data.isUsesLimited ? NOT_LIMITED : EXACT,
      ...formatLanguageFieldsData(data),
    };

    handleFormSubmit(generalFieldsData);
  };

  const createDealBody = () => {
    return (
      <DealEditFormContainer
        formData={item}
        disabled={!isFormEdit}
        onSubmit={handleSubmit(onSave)}
        onEdit={handleFormEdit}
        onCancel={handleCancelChanges}
        onDelete={handleFormDelete}
        onDeleteConfirmation={handleDeleteConfirmation}
        onDeleteCancellation={handleDeleteCancellation}
        onDealApprove={handleDealApprove}
        showDeleteAlert={showDeleteAlert}
      >
        <FormProvider {...formMethods}>
          <DealEditForm
            dealStatus = {dealStatus}
            item = {item}
            dealStatuses={dealStatuses}
            benefitOptions={benefitOptions}
            languages={languages}
            selectedLanguage={selectedLanguage}
            disabled={!isFormEdit}
            activeBenefitValue={activeBenefitValue}
            isEndDateOpenCheckbox={isEndDateOpenCheckbox}
            usesLimitCheckboxValue={usesLimitCheckboxValue}
            handleChangeLanguageTab={handleChangeLanguageTab}
            currentTabId={currentTabId}
            dealEndDateRules={dealEndDateRules}
            dealStartDateRules={dealStartDateRules}
            dealNumber={item.number}
            minDate={startDateFieldValue}
            isDirty={isDirty}
          />
        </FormProvider>
      </DealEditFormContainer>
    );
  };

  return (
    <TableRow className={cl.itemRow}>
      <TableCell colSpan={7} className={cl.itemCell}>
        <Accordion
          disableGutters
          square
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded}
          onChange={handleExpandDealAccordion}
          className={cl.accordion}
        >
          {CreateAccordionTitle(
            item, 
            dealStatuses,
            expanded,
            selectedLanguage,
            benefitOptions,
            languages
          )}
          <AccordionDetails>{createDealBody(item)}</AccordionDetails>
        </Accordion>
      </TableCell>
    </TableRow>
  );
};

export default Deal;
