import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, FormControl, Typography } from '@mui/material';

import cl from './RegionForm.module.css';

import {
  InputComponent,
  SelectInput,
  CheckboxInput,
} from '../reduxFormComponents';
import RegionLanguageTabs from '../RegionLanguageTabs';
import PolygonMap from '../PolygonMap';
import SimpleMap from '../SimpleMap';
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';
import SaveButton from '../buttons/SaveButton';
import FormButton from '../buttons/FormButton';
import ReduxImageDrop from '../../../attractions/content/reduxImageDrop/ReduxImageDrop';

import { GOOGLE_MAP_KEY } from '../../../../config';
import { onlyNumber, negativeNumber, maxLength10 } from '../validate';
import { ALLOWED_FILE_TYPES } from '../constants';
import { required } from '../../../attractions/content/validate';

const useStyles = makeStyles({
  form: {
    width: '100%',
    maxWidth: '688px',
    '& .MuiTextField-root': {
      width: '100%',
      marginBottom: '7px',
    },
    '& .MuiButton-root': {
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px;',
      '&.Mui-disabled': {
        textFillColor: 'rgba(0, 0, 0, 0.9)',
      },
      '&::placeholder': {
        opacity: 0.6,
      },
    },
  },
  order: {
    width: '185px',
    marginBottom: '10px',
    '& .MuiTextField-root': {
      width: '185px',
    },
  },
  areasVisibilityCheckbox: {
    width: '300px',
    marginBottom: '30px',
    '& .MuiTextField-root': {
      width: '300px',
    },
  },
  status: {
    width: '185px',
    marginBottom: '20px',
    '& .MuiTextField-root': {
      width: '185px',
    },
  },
  input: {
    '&.MuiButton-root': {
      position: 'absolute',
      content: '',
      bottom: '10px',
      left: '15px',
    },
  },
});

let RegionForm = (props) => {
  const {
    status,
    onCancel,
    showAlert,
    onAlertCancel,
    onCloseSuccess,
    onDeleteLocation,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const onEditLocation = () => {
    props.setIsEdit(true);
  };
  
  return (
    <form className={classes.form} onSubmit={props.handleSubmit}>
      <div className={cl.header}>
        <div className={cl.title}>
          {props.updateLocation
            ? props.currentItem.title[props.languages[0]]
            : props.locationType === 'Region'
            ? `${t('settings_page.destination.new_region')}`
            : props.locationType === 'Area'
            ? `${t('settings_page.destination.new_area')}`
            : props.locationType === 'Location'
            ? `${t('settings_page.destination.new_location')}`
            : null}
        </div>
        <Box className={cl.buttons}>
          <FormButton
            onClick={onCancel}
            disabled={props.addLocation ? false : !props.isEdit}
            label={t('cancel')}
          />
          {props.updateLocation && (
            <>
              <FormButton
                onClick={onEditLocation}
                disabled={props.isEdit || props.isSave}
                label={t('settings_page.destination.edit_button')}
              />
              <FormButton
                onClick={onDeleteLocation}
                disabled={props.isSave}
                label={t('settings_page.destination.delete_button')}
              />
            </>
          )}
          <SaveButton
            label={t('settings_page.destination.save_button')}
            isLoading={props.isSave}
            disabled={props.addLocation || props.isEdit ? false : true}
          />
        </Box>
      </div>
      <FormControl fullWidth>
        <Box className={classes.status}>
          <Typography mb="7px">
            {t('settings_page.category.formFieldLabels.status')}
          </Typography>
          <Field
            name="locationStatus"
            component={SelectInput}
            label="status"
            disabled={!props.addLocation && !props.isEdit}
          >
            {props.globalSettings[0].parameters.map((item) => (
              <option value={item.id} key={item.id}>
                {item.value[props.selectedLanguages] || item.value.en}
              </option>
            ))}
          </Field>
        </Box>
        <Box className={classes.order}>
          <Typography mb="7px">
            {t('settings_page.category.formFieldLabels.order')}
          </Typography>
          <Field
            name="order"
            component={InputComponent}
            validate={
              status === 'Active'
                ? [onlyNumber, negativeNumber, maxLength10, required]
                : [onlyNumber, negativeNumber, maxLength10]
            }
            disabled={!props.addLocation && !props.isEdit}
            placeholder={props.addLocation ? '0' : props.currentItem.order}
          />
          {props.error && props.error.Order && (
            <Typography
              sx={{ color: '#d43131' }}
              variant="caption"
              display="block"
              component="span"
            >
              {`* ${props.error.Order[0]}`}
            </Typography>
          )}
        </Box>
        {props.locationType === 'Region' && (
          <Box className={classes.areasVisibilityCheckbox}>
            <Field
              name="isHideForMainPage"
              component={CheckboxInput}
              label={t('settings_page.destination.hide_region_areas')}
              disabled={!props.addLocation && !props.isEdit}
            />
          </Box>
        )}
        <Box className={cl.tabsBox}>
          <RegionLanguageTabs
            addElement={props.addLocation}
            updateElement={props.updateLocation}
            isEdit={props.isEdit}
            currentItem={props.currentItem}
            languages={props.languages}
            error={props.error}
          />
        </Box>
        <div className={cl.imageLabel}>
          {t('settings_page.destination.image')}:
        </div>
        <div className={cl.helperText}>
          {t('settings_page.destination.image_text')}
        </div>
        <Box style={{ marginBottom: '10px' }}>
          <Field
            name="imageUrl"
            component={ReduxImageDrop}
            disabled={props.addLocation ? false : !props.isEdit}
            filesLimit={1}
            showFileNames
            allowedFileTypes={ALLOWED_FILE_TYPES}
          />
        </Box>
        {props.error && props.error.ImageUrl && props.image === '' && (
          <Box className={cl.imageBoxErrorText}>
            * {props.error && props.error.ImageUrl[0]}
          </Box>
        )}
        <SimpleMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAP_KEY}`}
            loadingElement={<div style={{ height: `250px` }} />}
            containerElement={<div style={{ height: `420px` }} />}
            mapElement={<div style={{ height: `350px` }} />}
            setGeoCoordinate={props.setGeoCoordinate}
            geoCoordinate={props.geoCoordinate}
        />
      </FormControl>

      <ConfirmationDialog
        showAlert={showAlert}
        handleClose={onAlertCancel}
        handleCloseSuccess={onCloseSuccess}
        text={t('settings_page.destination.delete_location', {
          locationType: t(
            `attractions.list.${props.locationType.toLowerCase()}`
          ),
        })}
      />
    </form>
  );
};

const selector = formValueSelector('Region');

const mapStateToProps = (state, ownProps) => {
  const status = selector(state, 'locationStatus');

  if (ownProps.addLocation) {
    return {
      status,
      initialValues: {
        locationStatus: 'Inactive',
        isHideForMainPage: false,
        order: '',
        title: '',
        subtitle: '',
        introduction: {},
        description: {},
        imageUrl: [],
        tips: {},
        highlights: {},
        directions: {},
      },
    };
  }
  if (ownProps.isEdit && ownProps.currentItem) {
    return {
      status,
      initialValues: {
        ...ownProps.currentItem,
        isHideForMainPage: ownProps.currentItem.isHideForMainPage,
        locationStatus: ownProps.currentItem.locationStatus,
        order: ownProps.currentItem.order,
        title: ownProps.currentItem.title,
        subtitle: ownProps.currentItem.subtitle,
        introduction: ownProps.currentItem.introduction,
        description: ownProps.currentItem.description,
        imageUrl:
          ownProps.currentItem.imageUrl === null
            ? []
            : [ownProps.currentItem.imageUrl],
        tips: ownProps.currentItem.tips,
        highlights: ownProps.currentItem.highlights,
        directions: ownProps.currentItem.directions,
      },
    };
  } else
    return {
      status,
      initialValues: {
        locationStatus: state.settingsReducer.currentLocation.locationStatus,
        isHideForMainPage:
          state.settingsReducer.currentLocation.isHideForMainPage,
        order: state.settingsReducer.currentLocation.order,
        title: state.settingsReducer.currentLocation.title,
        subtitle: state.settingsReducer.currentLocation.subtitle,
        introduction: state.settingsReducer.currentLocation.introduction,
        description: state.settingsReducer.currentLocation.description,
        imageUrl: state.settingsReducer.currentLocation.imageUrl
          ? [state.settingsReducer.currentLocation.imageUrl]
          : [],
        tips: state.settingsReducer.currentLocation.tips,
        highlights: state.settingsReducer.currentLocation.highlights,
        directions: state.settingsReducer.currentLocation.directions,
      },
    };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'Region',
    enableReinitialize: true,
    touchOnChange: true,
    forceUnregisterOnUnmount: true,
    destroyOnUnmount: false,
  })(RegionForm)
);
