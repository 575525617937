import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import { FormControl, Select } from "@mui/material";

const StatusSelect = ({
  value,
  onChange,
  label,
  children,
  disabled,
  disabledValue,
  ...props
}) => {
  return (
    <FormControl fullWidth disabled={disabled}>
      <Select
        displayEmpty
        native
        value={value}
        onChange={onChange}
        size="small"
      >
        {children}
      </Select>
    </FormControl>
  );
};

const createOptions = (options, selectedLanguage) => {
  if (!options || !options.parameters.length) return null;
  return options.parameters.map((option, index) => (
    <option value={option.id} key={index}>
      {option.value[selectedLanguage] || option.value.en}
    </option>
  ));
};

const DealsFilterForm = (props) => {
  const { availableStatuses, selectedLanguage, value, onChange } = props;
  const { t } = useTranslation();

  return (
    <Box component={"div"}>
      <Box sx={{ minWidth: 120 }}>
        <StatusSelect value={value} onChange={onChange}>
          <option value={""}>{t("attractions.deals.all_status")}</option>
          {createOptions(availableStatuses, selectedLanguage)}
        </StatusSelect>
      </Box>
    </Box>
  );
};

export default DealsFilterForm;
