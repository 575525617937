import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { ReduxSelect } from '../../../../components/reduxFormComponents/reduxFormComponents';
import { DesktopDatePicker } from '@mui/lab';

export const NotificationFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 14px;
`;

export const NotificationFormHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NotificationFormHeaderLabel = styled(Box)`
  display: flex;
  width: 100%;
`;

export const NotificationFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;

export const NotificationFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: baseline;
`;

export const NotificationFormFieldTitle = styled(Box)`
  white-space: nowrap;
  max-width: 215px;
  width: 100%;
  font-size: 16px;
`;

export const NotificationFormSelect = styled(ReduxSelect)`
  max-width: 215px;
  width: 100%;
`;

export const NotificationFormDatePicker = styled(DesktopDatePicker)`
  max-width: 215px;
  width: 100%;
  height: 80px;
`;
