import { connect, useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TranslationForm from './TranslationForm';

import { addKeyThunk } from '../../redux/translationsReducer/translationsActions';
import useAPIError from '../../apIErrorProvider/useAPIError';
import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import { CONTENT_LANGUAGES } from '../../consts/consts';
import { sortLanguageListInDestinationSettingsOrder } from '../../helpers/sortLanguageListInDestinationSettingsOrder';

const TranslationAddFormContainer = (props) => {
  const { appState, translationsState, addKeyThunk } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState();

  const destinationLanguageList = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );

  const contentLanguageList = appState.globalSettingsData.find(
    (item) => item.name.en === CONTENT_LANGUAGES
  );

  const sortedLanguageList = sortLanguageListInDestinationSettingsOrder(
    contentLanguageList.parameters,
    destinationLanguageList
  );

  const onSubmitForm = (values) => {
    setShowSaveAnimation(true);

    Object.keys(values.value).forEach((item) => {
      values.value[item] = validateTextFieldValue(values.value[item]);
    });

    const data = {
      destinationId: appState.selectedDestination.id,
      key: validateTextFieldValue(values.key),
      value: values.value,
      categoryKey: values.category,
    };

    addKeyThunk(data)
      .then(() => {
        setShowSaveAnimation(false);
        addError(`${t('settings_page.translation.error_add_text')}`, 'Success');
        navigate(-1);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.translation.error_not_add_text')}`,
          'Error',
          error
        );
        setTimeout(() => {
          setShowSaveAnimation(false);
        }, 2000);
      });
  };

  const handleFormCancel = () => {
    navigate(-1);
  };

  return (
    <TranslationForm
      addKey
      isEdit
      onSubmit={onSubmitForm}
      appState={appState}
      translationsState={translationsState}
      updateKey={false}
      isSave={showSaveAnimation}
      onCancel={handleFormCancel}
      languageList={sortedLanguageList}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    appVersionsReducer: state.appVersionsReducer,
    appState: state.appReducer,
    translationsState: state.translationsReducer,
  };
};

export default connect(mapStateToProps, {
  addKeyThunk,
})(TranslationAddFormContainer);
