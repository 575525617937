import axios from 'axios';

import authService from '../auth/AuthorizeService';
import { PRODUCTS_BASE_URL } from '../config';

export const getProducts = async (destinationId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${PRODUCTS_BASE_URL}/api/Products?destinationId=${destinationId}`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateProduct = async (productId, productData) => {
  const token = await authService.getAccessToken();
  const url = `${PRODUCTS_BASE_URL}/api/Products/${productId}`;

  return await axios
    .put(url, productData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getProductSettings = async () => {
  const token = await authService.getAccessToken();
  const targetUrl = `${PRODUCTS_BASE_URL}/api/ProductSettings`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
