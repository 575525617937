/* eslint-disable array-callback-return */

// Helper functions for filtering
export const defaultMatcher = (filterText, node) => {
  const nodeLanguagesValues = Object.values(node.title);
  const hasMatches = nodeLanguagesValues.some(
    (lang) => lang.toLowerCase() === filterText.toLowerCase()
  );

  return hasMatches;
};

export const findNodeByFilter = (node, filter, matcher) => {
  return (
    matcher(filter, node) || // i match
    (node.children && // or i have decendents and one of them match
      node.children.length &&
      !!node.children.find((child) => findNodeByFilter(child, filter, matcher)))
  );
};

export const filterTree = (node, filter, matcher = defaultMatcher) => {
  // If im an exact match then all my children get to stay
  if (matcher(filter, node) || !node.children) {
    return node;
  }
  // If not then only keep the ones that match or have matching descendants
  const filtered = node.children
    .filter((child) => findNodeByFilter(child, filter, matcher))
    .map((child) => filterTree(child, filter, matcher));

  return Object.assign({}, node, { children: filtered });
};

export const filterTreeWithoutParrentNode = (
  nodes,
  filter,
  matcher = defaultMatcher
) => {
  const destinations = [];

  nodes.map((destination) => {
    const filteredLocations = destination.locations
      .filter((child) => findNodeByFilter(child, filter, matcher))
      .map((child) => filterTree(child, filter, matcher));

    if (filteredLocations.length !== 0) {
      destinations.push(
        Object.assign({}, destination, { locations: filteredLocations })
      );
    }
  });

  return destinations;
};

export const expandFilteredNodes = (node, filter, matcher = defaultMatcher) => {
  let children = node.children;

  if (!children || children.length === 0) {
    return Object.assign({}, node, { toggled: false });
  }

  const childrenWithMatches = node.children.filter((child) =>
    findNodeByFilter(child, filter, matcher)
  );

  const shouldExpand = childrenWithMatches.length > 0;

  if (shouldExpand) {
    children = childrenWithMatches.map((child) => {
      return expandFilteredNodes(child, filter, matcher);
    });
  }

  return Object.assign({}, node, {
    children: children,
    toggled: shouldExpand,
  });
};

export const expandFilteredNodesWithoutParrent = (
  nodes,
  filter,
  matcher = defaultMatcher
) => {
  const destinations = [];

  nodes.map((destination) => {
    let destinationLocations = destination.locations;

    const locationsWithMatches = destination.locations.filter((child) =>
      findNodeByFilter(child, filter, matcher)
    );

    const shouldExpand = locationsWithMatches.length > 0;

    if (shouldExpand) {
      destinationLocations = locationsWithMatches.map((child) => {
        return expandFilteredNodes(child, filter, matcher);
      });
    }

    destinations.push(
      Object.assign({}, destination, {
        locations: destinationLocations,
        toggled: shouldExpand,
      })
    );
  });

  return destinations;
};

const getNodeIDsIfToggled = (node) => {
  let childIDs = [];

  if (node.children.length !== 0)
    node.children.map((childNode) => {
      childIDs.push(...getNodeIDsIfToggled(childNode));
    });

  if (node.toggled === true) return [node.id, ...childIDs];

  return [...childIDs];
};

export const getUniqueToggledNodeIDs = (destinations) => {
  const filteredIDs = [];

  destinations.map((destination) => {
    if (destination.toggled === true) filteredIDs.push(destination.id);
    destination.locations.map((location) => {
      filteredIDs.push(...getNodeIDsIfToggled(location));
    });
  });

  return filteredIDs;
};
