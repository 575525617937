import { Field, FieldArray } from 'redux-form';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';

import {
  ManagedAttractionsFormFieldWrapper,
  ManagedAttractionsFormCellWrapper,
  AttractionsContactListContainer,
  CompanyFormTextField,
  ManagedAttractionsFormRowTitle,
} from './styled';

import { MAX_VISIBLE_ATTRACTION_CONTACTS_AMOUNT } from '../constants';
import FieldList from '../components/FieldList';

const useStyles = makeStyles({
  scrollableList: {
    overflowY: 'scroll',
  },
  cellWrapper: {
    width: '25%',
    paddingTop: '4px'
  },
  parentCellFieldWrapper: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column'
  },
  childCellFieldWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > div:nth-child(n+1)': {
      marginBottom: '5px'
    },
    '& > div:nth-child(n+2)': {
      marginTop: '10px'
    }
  }
});

const AttractionContactsList = (props) => {
  const { fields, disabled } = props;

  const classes = useStyles();

  return (
    <AttractionsContactListContainer
      className={clsx({
        [classes.scrollableList]:
          fields.length > MAX_VISIBLE_ATTRACTION_CONTACTS_AMOUNT,
      })}
    >
      {fields.map((item, i) => {
        const prefix = `attractionContacts[${i}]`;
        return (
          <ManagedAttractionsFormFieldWrapper key={i}>
            <ManagedAttractionsFormCellWrapper className={classes.cellWrapper}>
              <ManagedAttractionsFormRowTitle>
                <Field
                  component={CompanyFormTextField}
                  size="small"
                  name={`${prefix}.attractionName`}
                  disabled
                />
              </ManagedAttractionsFormRowTitle>
            </ManagedAttractionsFormCellWrapper>
            <ManagedAttractionsFormFieldWrapper className={classes.parentCellFieldWrapper}>
              <ManagedAttractionsFormFieldWrapper className={classes.childCellFieldWrapper}>
                <FieldArray
                  component={FieldList}
                  disabled={disabled}
                  name={`${prefix}.contacts`}
                  sectionName="managedContacts"
                  formName="Content"
                  prefix={`${prefix}`}
                />
              </ManagedAttractionsFormFieldWrapper>
            </ManagedAttractionsFormFieldWrapper>
          </ManagedAttractionsFormFieldWrapper>
        )
        
      })}
    </AttractionsContactListContainer>
  );
};

export default AttractionContactsList;
