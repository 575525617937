import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { CircularProgress, Box, Typography } from '@mui/material';

import ReviewSearchForm from '../attractions/reviewsTab/forms/ReviewSearchForm';
import {
  ReviewRow,
  ReviewsTable,
} from '../attractions/reviewsTab/reviewsTable';

import {
  ReviewPageWrapper,
  LoaderWrapper,
  ReviewsFilterWrapper,
  ReviewAddButton,
} from './styled';

import { REVIEW_STATUS } from '../../consts/consts';
import { REVIEW_RATING } from '../attractions/reviewsTab/constants';

import { setTitle } from '../../redux/appReducer';

const ReviewPage = (props) => {
  const {
    reviewList,
    selectedLanguage,
    globalSettings,
    selectedDestinationId,
    attractionsReducer,
    getFilteredAttractionReviewsThunk,
    getSmallAttractionsDataThunk,
    isReviewListLoading,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState('');
  const [rating, setRating] = useState('');
  const [date, setDate] = useState(null);
  const [attraction, setAttraction] = useState(null);
  const [activeRowId, setActiveRowId] = useState(-1);

  const attractionList = attractionsReducer.attractionsSmallData.sort(function (
    a,
    b
  ) {
    var textA = a.name.toUpperCase().trim();
    var textB = b.name.toUpperCase().trim();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const handleReviewAdd = () => {
    navigate('new-review');
  };

  const updateReviewList = () => {
    const filter = {
      attractionId: attraction ? attraction.id : null,
      status: status ? status : null,
      rating: rating ? parseInt(rating) : null,
      date: date ? new Date(date.setHours(12, 0, 0, 0)).toISOString() : null,
    };
    getFilteredAttractionReviewsThunk(filter);
  };

  const handleExpandTableRow = (panelId) => {
    return () => {
      setActiveRowId((currentValue) =>
        currentValue !== panelId ? panelId : -1
      );
    };
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };

  const handleFilterReset = () => {
    setStatus('');
    setRating('');
    setDate(null);
    setAttraction(null);
  };

  useEffect(() => {
    const filter = {
      attractionId: attraction ? attraction.id : null,
      status: status ? status : null,
      rating: rating ? parseInt(rating) : null,
      date: date ? new Date(date.setHours(12, 0, 0, 0)).toISOString() : null,
    };
    getFilteredAttractionReviewsThunk(filter);
  }, [attraction, status, rating, date, getFilteredAttractionReviewsThunk]);

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_reviews')));
    getSmallAttractionsDataThunk(selectedDestinationId);
  }, [getSmallAttractionsDataThunk, selectedDestinationId]);

  return (
    <ReviewPageWrapper>
      <Box>
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('reviews.list_of_reviews')}
        </Typography>
        <Box mt="10px" mb="10px">
          <ReviewAddButton variant="contained" onClick={handleReviewAdd}>
            {t('reviews.add_new_review')}
          </ReviewAddButton>
        </Box>
        <ReviewsFilterWrapper>
          <ReviewSearchForm
            status={status}
            setStatus={setStatus}
            rating={rating}
            setRating={setRating}
            date={date}
            setDate={setDate}
            attraction={attraction}
            setAttraction={setAttraction}
            availableStatuses={globalSettings.find(
              (item) => item.name.en === REVIEW_STATUS
            )}
            availableRating={REVIEW_RATING}
            selectedLanguage={selectedLanguage}
            isGlobalReview
            attractionList={attractionList}
            onFilterReset={handleFilterReset}
          />
        </ReviewsFilterWrapper>
        {!isReviewListLoading ? (
          <ReviewsTable>
            {reviewList.map((reviewPost, idx) => (
              <ReviewRow
                item={reviewPost}
                key={idx}
                onClick={handleExpandTableRow(idx)}
                isExpanded={activeRowId === idx}
                resetActiveRow={resetActiveRow}
                attractionList={attractionList}
                updateReviewList={updateReviewList}
              />
            ))}
          </ReviewsTable>
        ) : (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}
      </Box>
    </ReviewPageWrapper>
  );
};

export default ReviewPage;
