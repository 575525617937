import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

import { ReduxAutocomplete } from '../../components/notificationComponents/AutocompleteInput';
import {
  CheckboxInput,
  ReduxTextField,
  ReduxSelect,
} from '../../components/reduxFormComponents/reduxFormComponents';
import { FormButton, SaveButton } from '../../components/buttons/FormButtons';

export const MyProfilePageWrapper = styled(Box)`
  width: 1300px;
  margin: 95px auto;
`;

export const UserInfoAddFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const UserInfoFormHeaderLabel = styled(Box)`
  display: flex;
  width: 65%;
`;

export const UserInfoFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const UserInfoFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const UserInfoFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const UserInfoFormSelectField = styled(ReduxSelect)`
  max-width: 215px;
  width: 100%;
  margin-left: 0;
  margin-top: 0;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  && .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const UserInfoFormTextField = styled(ReduxTextField)`
  width: 100%;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const UserInfoFormCheckbox = styled(CheckboxInput)`
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const UserInfoFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;

export const UserInfoFormAutocomplete = styled(ReduxAutocomplete)`
  max-width: 400px;
  width: 100%;
  && .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
  && .MuiChip-root.Mui-disabled {
    opacity: 0.9;
    pointer-events: none;
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const UserInfoFormHeader = styled(Box)`
  display: flex;
  align-items: center;
`;

export const LoaderWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserInfoFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const UserInfoFormButton = styled(FormButton)`
  max-width: fit-content;
`;
