import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { Box, Typography } from '@mui/material';
import { AttractionReportHeaderContainer } from './styled';

const AttractionReportHeader = ({ docHeaderInfo }) => {
  const { t } = useTranslation();

  const { reportDateFrom, reportDateTo, attraction } = docHeaderInfo;

  return (
    <AttractionReportHeaderContainer>
      <Typography fontWeight="bold" mt="30px" mb="15px" fontSize="16px">
        {attraction}
      </Typography>

      <Box display="flex" fontSize="14px">
        <Typography mr="10px" fontSize="14px">
          {t('attractions.reports.reportFormLabels.from')}
        </Typography>
        {format(new Date(reportDateFrom), 'dd/MM/yyyy')}
      </Box>

      <Box display="flex" fontSize="14px">
        <Typography mr="10px" fontSize="14px">
          {t('attractions.reports.reportFormLabels.to')}
        </Typography>
        {format(new Date(reportDateTo), 'dd/MM/yyyy')}
      </Box>

      <Box display="flex" mt="20px" fontSize="14px">
        <Typography mr="10px" fontSize="14px">
          {t('attractions.reports.generated')}
        </Typography>
        {format(new Date(), 'dd/MM/yyyy')}
      </Box>
    </AttractionReportHeaderContainer>
  );
};

export default AttractionReportHeader;
