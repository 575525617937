import { Navigate } from 'react-router-dom';

import SettingsPageContainer from '../../pages/settingsPage/SettingsPageContainer';
import DestinationContainer from '../../pages/settingsPage/destination/DestinationContainer';
import CategoryContainer from '../../pages/settingsPage/categories/CategoriesContainer';
import FacilitiesContainer from '../../pages/settingsPage/facilities/FacilitiesContainer';
import PredefinedContainer from '../../pages/settingsPage/predefined/PredefinedContainer';
import TagsContainer from '../../pages/settingsPage/tags/TagsContainer';
import DestinationAddFormContainer from '../../pages/settingsPage/destination/forms/DestinationAddFormContainer';
import RegionAddFormContainer from '../../pages/settingsPage/destination/forms/RegionAddFormContainer';
import CategoryAddFormContainer from '../../pages/settingsPage/categories/CategoryAddFormContainer';
import FacilityAddFormContainer from '../../pages/settingsPage/facilities/FacilityAddFormContainer';
import TagAddFormContainer from '../../pages/settingsPage/tags/TagAddFormContainer';
import DestinationEditFormContainer from '../../pages/settingsPage/destination/forms/DestinationEditFormContainer';
import RegionEditFormContainer from '../../pages/settingsPage/destination/forms/RegionEditFormContainer';
import CategoryEditFormContainer from '../../pages/settingsPage/categories/CategoryEditFormContainer';
import FacilityEditFormContainer from '../../pages/settingsPage/facilities/FacilityEditFormContainer';
import TagEditFormContainer from '../../pages/settingsPage/tags/TagEditFormContainer';

import { ADMIN, SYS_ADMIN } from '../consts';

export const settingsPageRoutes = [
  {
    path: '/settings',
    element: <SettingsPageContainer />,
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      {
        index: true,
        element: <Navigate to="destination" replace />,
        showForRoles: [ADMIN, SYS_ADMIN],
      },
      {
        path: 'destination',
        element: <DestinationContainer />,
        showForRoles: [ADMIN, SYS_ADMIN],
        children: [
          {
            path: 'new',
            children: [
              {
                path: 'destination',
                element: <DestinationAddFormContainer />,
                showForRoles: [ADMIN, SYS_ADMIN],
              },
              {
                path: ':locationType/:destinationId',
                element: <RegionAddFormContainer />,
                showForRoles: [ADMIN, SYS_ADMIN],
              },
              {
                path: ':locationType/:destinationId/:parentId',
                element: <RegionAddFormContainer />,
                showForRoles: [ADMIN, SYS_ADMIN],
              },
            ],
          },
          {
            path: 'view',
            children: [
              {
                path: ':destinationId',
                element: <DestinationEditFormContainer />,
                showForRoles: [ADMIN, SYS_ADMIN],
              },
              {
                path: ':destinationId/:regionId',
                element: <RegionEditFormContainer />,
                showForRoles: [ADMIN, SYS_ADMIN],
              },
              {
                path: ':destinationId/:regionId/:areaId',
                element: <RegionEditFormContainer />,
                showForRoles: [ADMIN, SYS_ADMIN],
              },
              {
                path: ':destinationId/:regionId/:areaId/:locationId',
                element: <RegionEditFormContainer />,
                showForRoles: [ADMIN, SYS_ADMIN],
              },
            ],
          },
        ],
      },
      {
        path: 'category',
        element: <CategoryContainer />,
        showForRoles: [ADMIN, SYS_ADMIN],
        children: [
          {
            path: 'view/:id',
            element: <CategoryEditFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'new-category',
            element: <CategoryAddFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
      {
        path: 'facility',
        element: <FacilitiesContainer />,
        showForRoles: [ADMIN, SYS_ADMIN],
        children: [
          {
            path: 'new-facility',
            element: <FacilityAddFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'view/:id',
            element: <FacilityEditFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
      {
        path: 'predefined',
        element: <PredefinedContainer />,
        showForRoles: [ADMIN, SYS_ADMIN],
      },
      {
        path: 'tags',
        element: <TagsContainer />,
        showForRoles: [ADMIN, SYS_ADMIN],
        children: [
          {
            path: 'new-tag',
            element: <TagAddFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'view/:id',
            element: <TagEditFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
    ],
  },
];
