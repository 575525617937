import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CompanyForm from './CompanyForm';
import useAPIError from '../../../../apIErrorProvider/useAPIError';

import { addCompanyThunk } from '../../../../redux/usersReducers/company/companyActions';
import { getUserSettingsThunk } from '../../../../redux/usersReducers/users/userActions';
import { filledEmptyFields } from '../utils';
import { USER_STATUS } from '../../../../consts/consts';

const CompanyAddFormContainer = (props) => {
  const {
    addCompanyThunk,
    selectedDestinationId,
    selectedLanguage,
    getUserSettingsThunk,
    statusOptions,
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleSubmitAddCompanyForm = (formData) => {
    setShowSaveAnimation(true);

    const newCompany = {
      destinationId: selectedDestinationId,
      name: formData.name,
      website: formData.website,
      status: formData.status,
      streetAddress: formData.streetAddress,
      city: formData.city,
      zipCode: parseInt(formData.zipCode) || null,
      country: formData.country,
      registrationNumber: parseInt(formData.registrationNumber) || null,
      valueAddedTaxNumber: formData.valueAddedTaxNumber,
      personContacts: filledEmptyFields(formData.personContacts),
      billingContacts: filledEmptyFields(formData.billingContacts),
      bankAccount: formData.bankAccount,
      attractionContacts: [],
    };

    addCompanyThunk(newCompany.destinationId, newCompany)
      .then(() => {
        setShowSaveAnimation(false);
        addError(`${t('companies.success_add_company')}`, 'Success');
        navigate('/users/companies');
      })
      .catch((error) => {
        addError(`${t('companies.error_add_company')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleAddCompanyFormCancel = () => {
    navigate('/users/companies');
  };

  useEffect(() => {
    getUserSettingsThunk();
  }, [selectedDestinationId, getUserSettingsThunk]);

  return (
    statusOptions && (
      <CompanyForm
        item={{}}
        onSubmit={handleSubmitAddCompanyForm}
        onFormCancel={handleAddCompanyFormCancel}
        isInEditMode
        isAddingItem
        showSaveAnimation={showSaveAnimation}
        statusOptions={statusOptions}
        selectedLanguage={selectedLanguage}
      />
    )
  );
};

const mapStateToProps = (state) => {
  const statusOptions = state.userReducer.userSettings.find(
    (item) => item.name.en === USER_STATUS
  );

  return {
    companyReducer: state.companyReducer,
    statusOptions,
    userSettings: state.userReducer.userSettings,
    availableAttractions: state.attractionsReducer.attractionsSmallData,
    globalSettings: state.appReducer.globalSettingsData,
    selectedDestinationId: state.appReducer.selectedDestination.id,
    settingsReducer: state.settingsReducer,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  addCompanyThunk,
  getUserSettingsThunk,
})(CompanyAddFormContainer);
