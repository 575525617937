import dayjs from 'dayjs';

import { TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { NotificationTableRow } from './styled';
import { NOTIFICATION_ROW_CELLS_AMOUNT } from '../constants';
import NotificationFormContainer from '../forms/NotificationFormContainer';

const colors = {
  blue: '#32D7F0',
  orange: '#FFA94C',
  grey: '#A0A0A0',
  white: '#FFF',
};

const statusColor = {
  Inactive: colors.blue,
  Active: colors.orange,
  Closed: colors.grey,
};

const NotificationRow = (props) => {
  const {
    item,
    onClick,
    isExpanded,
    languages,
    currentAttraction,
    onSubmitNotificationForm,
    statuses,
    onDeleteNotification,
    selectedLanguage,
  } = props;

  const startDate = dayjs(item.startDate).format('DD.MM.YYYY');

  const endDate = dayjs(item.endDate).format('DD.MM.YYYY');

  const translatableStatus = statuses?.parameters.find(
    (status) => status.id === item.status
  );

  return (
    <>
      <NotificationTableRow onClick={onClick}>
        <TableCell
          sx={{
            bgcolor: statusColor[item.status],
          }}
        >
          {translatableStatus?.value[selectedLanguage]}
        </TableCell>
        <TableCell>{currentAttraction.name}</TableCell>
        <TableCell>{item.title[languages[0].value]}</TableCell>
        <TableCell>{startDate}</TableCell>
        <TableCell>{endDate}</TableCell>
        <TableCell>
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
      </NotificationTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={NOTIFICATION_ROW_CELLS_AMOUNT}>
            <NotificationFormContainer
              onFormSubmit={onSubmitNotificationForm}
              languages={languages}
              currentItem={item}
              statuses={[statuses]}
              onDeleteNotification={onDeleteNotification}
              currentAttractionId={currentAttraction.id}
              selectedLanguage={selectedLanguage}
              currentAttractionName={currentAttraction.name}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default NotificationRow;
