const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength50 = maxLength(50);

export const noSpaceName = (values) =>
  values.trim() === '' ? 'Name cannot consist of just spaces' : undefined;

export function required(value) {
  return value == null ? 'Required' : undefined;
}

export const onlyNumber = (value) =>
  value && isNaN(Number(value)) ? 'Only number' : undefined;
