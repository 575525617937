import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CircularProgress, Box, Typography } from '@mui/material';

import ReviewSearchForm from './forms/ReviewSearchForm';
import { ReviewRow, ReviewsTable } from './reviewsTable';

import {
  ReviewsTabWrapper,
  ReviewsFilterWrapper,
  LoaderWrapper,
  ReviewAddButton,
  ReviewStatisticWrapper,
} from './styled';

import { getAverageReviewRating } from './utils';
import { REVIEW_STATUS } from '../../../consts/consts';
import { REVIEW_RATING } from './constants';

const ReviewsTab = (props) => {
  const {
    getFilteredAttractionReviewsThunk,
    getAttractionReivewsListThunk,
    currentAttraction,
    reviewList,
    selectedLanguage,
    globalSettings,
    attractionList,
    getSmallAttractionsDataThunk,
    selectedDestinationId,
    isLoading,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [status, setStatus] = useState('');
  const [rating, setRating] = useState('');
  const [date, setDate] = useState(null);
  const [activeRowId, setActiveRowId] = useState(-1);

  const averageRating = getAverageReviewRating(reviewList).toFixed(1);

  const handleReviewAdd = () => {
    navigate('new-review');
  };

  const handleExpandTableRow = (panelId) => {
    return () => {
      setActiveRowId((currentValue) =>
        currentValue !== panelId ? panelId : -1
      );
    };
  };

  const resetActiveRow = () => {
    setActiveRowId(-1);
  };

  const updateReviewList = () => {
    const filter = {
      attractionId: currentAttraction.id,
      status: status ? status : null,
      rating: rating ? parseInt(rating) : null,
      date: date ? new Date(date.setHours(12, 0, 0, 0)).toISOString() : null,
    };
    getFilteredAttractionReviewsThunk(filter);
  };

  const handleFilterReset = () => {
    setStatus('');
    setRating('');
    setDate(null);
  };

  useEffect(() => {
    const filter = {
      attractionId: currentAttraction.id,
      status: status ? status : null,
      rating: rating ? parseInt(rating) : null,
      date: date ? new Date(date.setHours(12, 0, 0, 0)).toISOString() : null,
    };
    if (currentAttraction.id) getFilteredAttractionReviewsThunk(filter);
  }, [
    status,
    rating,
    date,
    currentAttraction.id,
    getFilteredAttractionReviewsThunk,
  ]);

  useEffect(() => {
    getSmallAttractionsDataThunk(selectedDestinationId);
  }, [getSmallAttractionsDataThunk, selectedDestinationId]);

  return (
    <ReviewsTabWrapper>
      <Box>
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('reviews.list_of_reviews')}
        </Typography>
        <ReviewStatisticWrapper>
          <Box>
            {t('reviews.average_rating')}: {averageRating}
          </Box>
          <Box>
            {t('reviews.number_of_reviews')}: {reviewList.length}
          </Box>
        </ReviewStatisticWrapper>
        <Box mt="10px" mb="10px">
          <ReviewAddButton variant="contained" onClick={handleReviewAdd}>
            {t('reviews.add_new_review')}
          </ReviewAddButton>
        </Box>
        <ReviewsFilterWrapper>
          <ReviewSearchForm
            status={status}
            setStatus={setStatus}
            rating={rating}
            setRating={setRating}
            date={date}
            setDate={setDate}
            availableStatuses={globalSettings.find(
              (item) => item.name.en === REVIEW_STATUS
            )}
            availableRating={REVIEW_RATING}
            selectedLanguage={selectedLanguage}
            onFilterReset={handleFilterReset}
          />
        </ReviewsFilterWrapper>
        {!isLoading ? (
          <ReviewsTable>
            {reviewList.map((reviewPost, idx) => (
              <ReviewRow
                item={reviewPost}
                key={idx}
                onClick={handleExpandTableRow(idx)}
                isExpanded={activeRowId === idx}
                resetActiveRow={resetActiveRow}
                attractionList={attractionList}
                updateReviewList={updateReviewList}
              />
            ))}
          </ReviewsTable>
        ) : (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}
      </Box>
    </ReviewsTabWrapper>
  );
};

export default ReviewsTab;
