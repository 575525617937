import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, CircularProgress, Typography } from '@mui/material';

import AttractionReportHeader from './AttractionReportHeader';
import ReportForm from './reportForm/ReportForm';
import ReportSummaryTable from './reportSummaryTable/ReportSummaryTable';
import ReportDealDetailsTable from './reportDealDetailsTable/ReportDealDetailsTable';

import { getReportPdfLink, getReports } from '../../../api/reportsApi';
import {
    AttractionReportFormWrapper,
    AttractionReportPdfButton,
    AttractionReportSummaryHeadline,
    LoaderWrapper,
} from './styled';

import {
    mapDealsTranslationsObjectToString,
} from './utils';

const AttractionReportPage = ({
    benefitOptions,
    selectedLanguage,
    attractionsData,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();

    const [reportsData, setReportsData] = useState({});
    const [docHeaderInfo, setDocHeaderInfo] = useState({});
    const [loading, setLoading] = useState(false);

    const formMethods = useForm({
        mode: 'onSubmit',
        defaultValues: {
            attraction: '',
            reportDateFrom: null,
            reportDateTo: null,
            showDetailedReport: false,
        },
    });

    const { watch, setValue } = formMethods;

    const dateFromValue = watch('reportDateFrom');
    const dateToValue = watch('reportDateTo');

    const onSubmit = (data) => {
        const submitData = {
            attractionId: id,
            from: new Date(data.reportDateFrom).toISOString(),
            to: new Date(data.reportDateTo).toISOString(),
            isDetailed: data.showDetailedReport,
        };

        setLoading(true);
        setReportsData({});
        setDocHeaderInfo(data);

        getReports({ ...submitData }).then((res) => {
            if (res.status === 200) {
                setReportsData(res.data);
                setLoading(false);
            }
        });
    };

    const handleFormatToPdf = () => {
        const reportData = {
            report: {
                attraction: docHeaderInfo.attraction,
                from: docHeaderInfo.reportDateFrom.toISOString(),
                to: docHeaderInfo.reportDateTo.toISOString(),
                deals: mapDealsTranslationsObjectToString(
                    reportsData.deals,
                    selectedLanguage,
                    benefitOptions.parameters
                ),
            },
        };

        getReportPdfLink(reportData, selectedLanguage).then((response) => {
            if (response.status === 200) {
                const link = document.createElement('a');
                link.href = response.data;

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            } else console.error(response.data);
        });
    };

    useEffect(() => {
        attractionsData &&
            setValue('attraction', attractionsData.currentAttraction.name);
    }, [attractionsData, setValue]);

    return (
        <Box display="flex" gap="100px">
            <AttractionReportFormWrapper>
                <Typography fontWeight="bold" mb="10px">
                    {t('attractions.reports.reportsHeadline')}
                </Typography>
                <FormProvider {...formMethods}>
                    <ReportForm
                        onSubmit={onSubmit}
                        dateFromValue={dateFromValue}
                        dateToValue={dateToValue}
                    />
                </FormProvider>
            </AttractionReportFormWrapper>

            <Box display="flex" flex="1" flexGrow="1">
                <Box display="flex" flexDirection="column" width="100%">
                    {loading ? (
                        <LoaderWrapper>
                            <CircularProgress />
                        </LoaderWrapper>
                    ) : (
                        reportsData.deals === null && (
                            <Box>
                                <Typography>
                                    {t('attractions.reports.noReportsText')}
                                </Typography>
                            </Box>
                        )
                    )}

                    {reportsData.deals && reportsData.deals !== null && (
                        <>
                            <Typography mb="10px" fontWeight="bold">
                                {t('attractions.reports.reportsHeadline')}
                            </Typography>
                            <Box display="flex" alignItems="center" gap="16px">
                                <Box width="100%" maxWidth="78px">
                                    <AttractionReportPdfButton
                                        variant="outlined"
                                        onClick={handleFormatToPdf}
                                    >
                                        PDF
                                    </AttractionReportPdfButton>
                                </Box>
                            </Box>

                            <>
                                <AttractionReportHeader docHeaderInfo={docHeaderInfo} />

                                <Box mt="30px">
                                    <AttractionReportSummaryHeadline pl="10px">
                                        {t('attractions.reports.summaryTableHeadline')}
                                    </AttractionReportSummaryHeadline>
                                    <ReportSummaryTable
                                        dealsData={reportsData.deals}
                                        benefitOptions={benefitOptions}
                                        selectedLanguage={selectedLanguage}
                                    />
                                </Box>
                                {reportsData.deals.every(deal => deal.visits &&
                                                            deal.visits !== null &&
                                                            deal.visits.length > 0) && (
                                        <Box mt="30px">
                                            {reportsData.deals.map((dealData, idx) => (
                                                <Box key={idx}>
                                                    <ReportDealDetailsTable
                                                        dealData={dealData}
                                                        selectedLanguage={selectedLanguage}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                            </>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default AttractionReportPage;
