import { useTranslation } from 'react-i18next';

import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Table,
} from '@mui/material';

import { CategoriesFaqTableHead, CategoriesFaqTableRow } from './styled';

const TranslationList = (props) => {
  const { t } = useTranslation();

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: '100%',
        boxShadow:
          '0px -1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 44%)',
      }}
    >
      <Table>
        <CategoriesFaqTableHead>
          <TableRow>
            <TableCell
              padding="normal"
              sx={{
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              {t('faq_page.categories.status')}
            </TableCell>
            <TableCell
              padding="none"
              align="center"
              sx={{
                borderRight: '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              {t('faq_page.categories.order')}
            </TableCell>
            <TableCell padding="none" sx={{ paddingLeft: '16px' }}>
              {t('faq_page.categories.name')}
            </TableCell>
          </TableRow>
        </CategoriesFaqTableHead>
        {!props.categoryState.length ? (
          <Typography component={'div'} sx={{ padding: '16px' }}>
            {t('faq_page.categories.no_category')}
          </Typography>
        ) : (
          <TableBody>
            {props.categoryState.map((item, index) => (
              <CategoriesFaqTableRow
                onClick={() => props.handleListItemClick(index, item)}
                key={item.id}
                hover
                sx={{ cursor: 'pointer' }}
              >
                <TableCell
                  sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {item.status}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {item.order}
                </TableCell>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.name[props.selectedLanguage] || item.name.en}
                </TableCell>
              </CategoriesFaqTableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default TranslationList;
