import { MobileDatePicker, MobileDateTimePicker } from '@mui/lab';
import {
  Box,
  Button,
  Select,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { styled } from '@mui/system';

import { SaveButton } from '../../../components/buttons/FormButtons';

export const VisitsPageContainer = styled(Box)`
  max-width: 1300px;
  width: 100%;
`;

export const StyledVisitsTableHead = styled(TableHead)`
  .MuiTableCell-root {
    padding: 5px 16px;

    :nth-of-type(1) {
      text-align: center;
      width: 10%;
    }
    :nth-of-type(2) {
      width: 12%;
    }
    :nth-of-type(3) {
      width: 25%;
    }
    :nth-of-type(4) {
      width: 5%;
      text-align: center;
    }
    :nth-of-type(5) {
      width: 10%;
      text-align: center;
    }
    :nth-of-type(6) {
      width: 5%;
      text-align: center;
    }
  }
`;

export const StyledTableHeadRow = styled(TableRow)`
  user-select: none;

  th {
    border-right: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;

    :nth-of-type(2) {
      cursor: pointer;
    }
  }

  th:first-of-type {
    border-left: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    padding: 20px;
  }
`;

export const StyledVisitsTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;

    :nth-of-type(1) {
      text-align: center;
    }
    :nth-of-type(4) {
      text-align: center;
    }
    :nth-of-type(5) {
      text-align: center;
    }
    :nth-of-type(6) {
      text-align: center;
    }
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    background-color: #ffa94c;
  }
`;

export const VisitsTableFilterFormWrapper = styled(Box)`
  display: flex;
  gap: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const VisitsFilterFormDateField = styled(MobileDatePicker)`
  max-width: 200px;
  width: 100%;
`;

export const VisitsFilterDateTextField = styled(TextField)`
  & .MuiOutlinedInput-input {
    cursor: pointer;
    max-width: 169px;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const StyledVisitsFilterAttractionsField = styled(TextField)`
  max-width: 290px;
  width: 100%;
`;

export const VisitAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const RedeemCodeFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const RedeemCodeFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const RedeemCodeFormDateField = styled(MobileDateTimePicker)`
  max-width: 215px;
  width: 100%;
`;

export const RedeemCodeFormDateTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }

  & .MuiOutlinedInput-input {
    cursor: pointer;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const RedeemCodeDateFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;

  & .MuiOutlinedInput-root {
    max-width: 215px;
    width: 100%;
  }
`;

export const RedeemCodeSelectField = styled(Select)`
  max-width: 215px;
  width: 100%;
`;

export const RedeemCodeAttractionTextField = styled(TextField)`
  max-width: 400px;
  width: 100%;
`;

export const RedeemCodeTextField = styled(TextField)`
  max-width: 215px;
  width: 100%;

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const RedeemCodeFormSaveButton = styled(SaveButton)`
  color: white;
  min-width: fit-content;
`;

export const DealTableTitle = styled(Typography)`
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 14px;
  max-width: 500px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
