import { Controller, useFormContext } from 'react-hook-form';

import { AttractionReportTextInput } from '../../styled';

const AttractionReportTextField = ({ name, disabled }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <AttractionReportTextInput
          disabled={disabled}
          fullWidth
          size="small"
          onChange={(e) => onChange(e.target.value)}
          value={value}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default AttractionReportTextField;
