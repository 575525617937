import * as axios from 'axios';

import authService from '../../auth/AuthorizeService';
import { BASE_URL } from '../../config.js';

const URL = `${BASE_URL}/api/facts`;

export const getFactsList = async (destinationId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/destinations/${destinationId}/facts`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addFact = async (data) => {
  const token = await authService.getAccessToken();

  return await axios
    .post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateFact = async (data) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/${data.id}`;

  return await axios
    .put(targetUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteFact = async (factId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/${factId}`;

  return await axios
    .delete(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
