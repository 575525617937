import { Controller, useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { SetupFormTextFieldInput } from '../styled';

import { EMAIL_PATTERN } from '../../../users/visitorsTab/constants';
import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../../consts/validation';

const SetupFormTextField = ({
  name,
  required,
  disabled,
  validator,
  placeholder,
  isEmail,
  customOnChange,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('required'),
        },
        maxLength: {
          value: DEFAULT_TEXT_FIELD_MAX_LENGTH,
          message: t('visitors.error_max_length', {
            chars: DEFAULT_TEXT_FIELD_MAX_LENGTH,
          }),
        },
        ...(isEmail && {
          validate: (value) =>
            value && !EMAIL_PATTERN.test(value)
              ? t('visitors.error_email')
              : undefined,
        }),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <SetupFormTextFieldInput
          value={value}
          onChange={(e) => {
            onChange(validator(e.target.value));
            customOnChange && customOnChange(e.target.value);
          }}
          fullWidth
          size="small"
          error={!!error}
          helperText={error?.message}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default SetupFormTextField;
