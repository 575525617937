export const ALLOWED_FILE_TYPES = ['jpg', 'jpeg', 'png'];
export const DEFAULT_ADVERT_STATUS = 'Inactive';
export const DEFAULT_REDIRECT_TO = 'NoRedirect';
export const EMPTY_HTML_TAG_LENGTH = 8;
const COLORS = {
  blue: '#32D7F0',
  orange: '#FFA94C',
  grey: '#A0A0A0',
  white: '#FFF',
};

export const STATUS_COLORS = {
  Inactive: COLORS.blue,
  Active: COLORS.orange,
};
