import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Typography, Tab, Tabs, TextField } from '@mui/material';
import { Field, FieldArray, FormSection } from 'redux-form';
import { Input } from './reduxFormComponents';
import TextEditor from '../../../components/RichTextEditor/TextEditor';
import {
  requiredRegionSpace,
  maxLength250,
  noSpaceName,
  requiredTitle,
  requiredSubtitle,
} from './validate';
import FieldList from '../../attractions/content/fieldList/FieldList';

const useStyles = makeStyles({
  tabs: {
    minWidth: '320px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    marginBottom: '16px',
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    '& .MuiTab-root': {
      marginLeft: '7px',
    },
  },
  tabPanel: {
    width: '100%',
    fontSize: '0.875rem',
    '& .MuiBox-root': {
      padding: 0,
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& textarea': {
        height: '65px !important',
      },
      '&.Mui-disabled': {
        textFillColor: 'rgba(0, 0, 0, 0.90)',
      },
    },
  },
  tipsBox: {
    marginBottom: '30px',
    marginTop: '30px',
  },
  highlightsBox: {
    marginBottom: '30px',
  },
  directionsBox: {
    marginBottom: '30px',
  },
});

const styleTab = {
  color: '#fff',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  backgroundColor: 'rgba(0,0,0, 0.51)',
  width: '100%',
  maxWidth: '50px',
  minWidth: '20px',
  maxHeight: '36px',
  minHeight: '20px',
  padding: '12px 16px',
  margin: '6px 0px',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  indicatorColor: 'secondary',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0, 0.40)',
  },
  '&:focus': {
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&:first-of-type': {
    marginLeft: '4px',
  },
  '&:last-child': {
    marginRight: '4px',
  },
  '&.Mui-selected': {
    backgroundColor: '#FFA94C',
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&.disabled': {
    opacity: '0.5',
    cursor: ' not-allowed',
  },
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, fontSize: '16px' }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const RegionLanguageTabs = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useState(0);
  const [valueDirty, setValueDirty] = useState(false);

  const handleChangeTabs = (_, newValue) => {
    setTabsValue(newValue);
    setValueDirty(true);
  };

  const CreateTab = props.languages.map((tab, index) => (
    <Tab label={tab} {...a11yProps(index)} sx={styleTab} key={tab} />
  ));

  return (
    <>
      <Box className={classes.tabs}>
        <Tabs
          value={tabsValue}
          onChange={handleChangeTabs}
          indicatorColor={undefined}
          sx={{
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          {CreateTab}
        </Tabs>
      </Box>
      {props.languages.map((tabPanel, index) => (
        <TabPanel
          className={classes.tabPanel}
          value={tabsValue}
          index={index}
          key={index}
        >
          {props.addElement ? (
            <>
              <Box sx={{ display: 'flex', margin: '0 0 7px 2px' }}>
                {t('settings_page.destination.title')}:
              </Box>
              <FormSection name="title">
                <Field
                  name={tabPanel}
                  component={Input}
                  placeholder={t('settings_page.destination.title')}
                  validate={[
                    requiredTitle,
                    maxLength250,
                    noSpaceName,
                    requiredRegionSpace,
                  ]}
                />
                {props.error && props.Title && (
                  <Typography
                    sx={{ color: '#d43131' }}
                    variant="caption"
                    display="block"
                    component="span"
                  >
                    {`* ${props.error.Title[0]}`}
                  </Typography>
                )}
              </FormSection>
              <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                {t('settings_page.destination.subtitle')}:
              </Box>
              <FormSection name="subtitle">
                <Field
                  name={tabPanel}
                  component={Input}
                  placeholder={t('settings_page.destination.subtitle')}
                  validate={[maxLength250, noSpaceName]}
                />
                {props.error && props.Subtitle && (
                  <Typography
                    sx={{ color: '#d43131' }}
                    variant="caption"
                    display="block"
                    component="span"
                  >
                    {`* ${props.error.Subtitle[0]}`}
                  </Typography>
                )}
              </FormSection>
              <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                {t('settings_page.destination.introduction')}:
              </Box>
              <FormSection name="introduction">
                <Field
                  name={tabPanel}
                  component={TextEditor}
                  isEdit
                  addElement={props.addElement}
                  placeholder={t('settings_page.destination.introduction')}
                  valueDirty={valueDirty}
                  setValueDirty={setValueDirty}
                />
              </FormSection>
              <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                {t('settings_page.destination.description')}:
              </Box>
              <FormSection name="description">
                <Field
                  name={tabPanel}
                  component={TextEditor}
                  isEdit
                  addElement={props.addElement}
                  placeholder={t('settings_page.destination.description')}
                  valueDirty={valueDirty}
                  setValueDirty={setValueDirty}
                />
              </FormSection>
              <Box className={classes.tipsBox}>
                <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                  {t('settings_page.destination.tips')}:
                </Box>
                <FormSection name="tips">
                  <FieldArray
                    name={tabPanel}
                    component={FieldList}
                    sectionName="tips"
                    formName="Region"
                  />
                </FormSection>
              </Box>
              <Box className={classes.highlightsBox}>
                <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                  {t('settings_page.destination.highlights')}:
                </Box>
                <FormSection name="highlights">
                  <FieldArray
                    name={tabPanel}
                    component={FieldList}
                    sectionName="highlights"
                    formName="Region"
                  />
                </FormSection>
              </Box>
              <Box className={classes.directionsBox}>
                <Box sx={{ display: 'flex', margin: '0px 0 7px 2px' }}>
                  {t('settings_page.destination.directions')}:
                </Box>
                <FormSection name="directions">
                  <FieldArray
                    name={tabPanel}
                    component={FieldList}
                    sectionName="directions"
                    formName="Region"
                  />
                </FormSection>
              </Box>
            </>
          ) : props.updateElement && props.isEdit ? (
            <>
              <Box sx={{ display: 'flex', margin: '0 0 7px 2px' }}>
                {t('settings_page.destination.title')}:
              </Box>
              <FormSection name="title">
                <Field
                  name={tabPanel}
                  component={Input}
                  placeholder={t('settings_page.destination.title')}
                  validate={[
                    requiredTitle,
                    maxLength250,
                    noSpaceName,
                    requiredRegionSpace,
                  ]}
                />
                {props.error && props.Title && (
                  <Typography
                    sx={{ color: '#d43131' }}
                    variant="caption"
                    display="block"
                    component="span"
                  >
                    {`* ${props.error.Title[0]}`}
                  </Typography>
                )}
              </FormSection>
              <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                {t('settings_page.destination.subtitle')}:
              </Box>
              <FormSection name="subtitle">
                <Field
                  name={tabPanel}
                  component={Input}
                  placeholder={t('settings_page.destination.subtitle')}
                  validate={[maxLength250, noSpaceName]}
                />
                {props.error && props.Subtitle && (
                  <Typography
                    sx={{ color: '#d43131' }}
                    variant="caption"
                    display="block"
                    component="span"
                  >
                    {`* ${props.error.Subtitle[0]}`}
                  </Typography>
                )}
              </FormSection>
              <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                {t('settings_page.destination.introduction')}:
              </Box>
              <FormSection name="introduction">
                <Field
                  name={tabPanel}
                  component={TextEditor}
                  isEdit={props.isEdit}
                  addElement={props.addElement}
                  placeholder={t('settings_page.destination.introduction')}
                  valueDirty={valueDirty}
                  setValueDirty={setValueDirty}
                />
              </FormSection>
              <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                {t('settings_page.destination.description')}:
              </Box>
              <FormSection name="description">
                <Field
                  name={tabPanel}
                  component={TextEditor}
                  isEdit={props.isEdit}
                  addElement={props.addElement}
                  placeholder={t('settings_page.destination.directions')}
                  valueDirty={valueDirty}
                  setValueDirty={setValueDirty}
                />
              </FormSection>
              <Box className={classes.tipsBox}>
                <Box sx={{ display: 'flex', marginBottom: '7px' }}>
                  {t('settings_page.destination.tips')}:
                </Box>
                <FormSection name="tips">
                  <FieldArray
                    name={tabPanel}
                    component={FieldList}
                    sectionName="tips"
                    disabled={!props.isEdit}
                    formName="Region"
                  />
                </FormSection>
              </Box>
              <Box className={classes.highlightsBox}>
                <Box sx={{ display: 'flex', marginBottom: '7px' }}>
                  {t('settings_page.destination.highlights')}:
                </Box>
                <FormSection name="highlights">
                  <FieldArray
                    name={tabPanel}
                    component={FieldList}
                    sectionName="highlights"
                    disabled={!props.isEdit}
                    formName="Region"
                  />
                </FormSection>
              </Box>
              <Box className={classes.directionsBox}>
                <Box sx={{ display: 'flex', marginBottom: '7px' }}>
                  {t('settings_page.destination.directions')}:
                </Box>
                <FormSection name="directions">
                  <FieldArray
                    name={tabPanel}
                    component={FieldList}
                    sectionName="directions"
                    disabled={!props.isEdit}
                    formName="Region"
                  />
                </FormSection>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', margin: '0 0 7px 2px' }}>
                {t('settings_page.destination.title')}:
              </Box>
              <TextField
                disabled
                placeholder={
                  props.currentItem.title[tabPanel] ||
                  `${t('settings_page.destination.no_translation')}`
                }
              />
              <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                {t('settings_page.destination.subtitle')}:
              </Box>
              <TextField
                disabled
                placeholder={
                  props.currentItem.subtitle[tabPanel] ||
                  `${t('settings_page.destination.no_translation')}`
                }
              />
              <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                {t('settings_page.destination.introduction')}:
              </Box>
              <FormSection name="introduction">
                <Field
                  name={tabPanel}
                  component={TextEditor}
                  disabled
                  isEdit={props.isEdit}
                  addElement={props.addElement}
                  placeholder={`${t('settings_page.destination.introduction')}`}
                  valueDirty={valueDirty}
                  setValueDirty={setValueDirty}
                />
              </FormSection>
              <Box sx={{ display: 'flex', margin: '15px 0 7px 2px' }}>
                {t('settings_page.destination.description')}:
              </Box>
              <FormSection name="description">
                <Field
                  name={tabPanel}
                  component={TextEditor}
                  disabled
                  isEdit={props.isEdit}
                  addElement={props.addElement}
                  placeholder={`${t('settings_page.destination.description')}`}
                  valueDirty={valueDirty}
                  setValueDirty={setValueDirty}
                />
              </FormSection>
              <Box className={classes.tipsBox}>
                <Box sx={{ display: 'flex', marginBottom: '7px' }}>
                  {t('settings_page.destination.tips')}:
                </Box>
                <FormSection name="tips">
                  <FieldArray
                    name={tabPanel}
                    component={FieldList}
                    sectionName="tips"
                    disabled
                    formName="Region"
                  />
                </FormSection>
              </Box>
              <Box className={classes.tipsBox}>
                <Box sx={{ display: 'flex', marginBottom: '7px' }}>
                  {t('settings_page.destination.highlights')}:
                </Box>
                <FormSection name="highlights">
                  <FieldArray
                    name={tabPanel}
                    component={FieldList}
                    sectionName="highlights"
                    disabled
                    formName="Region"
                  />
                </FormSection>
              </Box>
              <Box className={classes.tipsBox}>
                <Box sx={{ display: 'flex', marginBottom: '7px' }}>
                  {t('settings_page.destination.directions')}:
                </Box>
                <FormSection name="directions">
                  <FieldArray
                    name={tabPanel}
                    component={FieldList}
                    sectionName="directions"
                    disabled
                    formName="Region"
                  />
                </FormSection>
              </Box>
            </>
          )}
        </TabPanel>
      ))}
    </>
  );
};

export default RegionLanguageTabs;
