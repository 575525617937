import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, FormSection } from 'redux-form';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, FormGroup, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { InputForm, SelectInput } from './FormComponents';
import LanguageTabs from './LanguageTabs';
import { maxLength10, onlyNumber, negativeOrder } from './validate.js';

import cl from './CategoryForm.module.css';

import ReduxImageDrop from '../../attractions/content/reduxImageDrop/ReduxImageDrop';
import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import { StyledCheckboxDisabledInput, StyledCheckboxInput } from './styled';

const useStyles = makeStyles({
  form: {
    width: '100%',
    maxWidth: '650px',
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiButton-root': {
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px;',
      '&::placeholder': {
        opacity: 0.6,
      },
    },
  },
  order: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    '& .MuiTextField-root': {
      width: '185px',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      color: '#000',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.90)',
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
    '& .MuiFormControl-root': {
      maxWidth: '185px',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      color: '#000',
      WebkitTextFillColor: 'rgba(0, 0, 0, 0.90)',
    },
  },
  input: {
    '&.MuiButton-root': {
      position: 'absolute',
      content: '',
      bottom: '10px',
    },
  },
});

let CategoryForm = (props) => {
  const { onCancel } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const onEditCategory = () => {
    props.setIsEdit(true);
    props.setIcon(props.currentItem.iconUrl);
  };

  const onDeleteCategory = () => {
    props.setShowAlert(true);
  };

  const handleClose = () => {
    props.setShowAlert(false);
  };

  return (
    <form className={classes.form} onSubmit={props.handleSubmit}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="25px"
      >
        <Box className={cl.title}>
          {props.updateCategory
            ? props.categoryState.currentCategory.name[
                props.appState.selectedLanguage
              ]
            : `${t('faq_page.categories.new_category')}`}
        </Box>
        <Box className={cl.buttons}>
          <Button
            variant="outlined"
            onClick={onCancel}
            type="button"
            disabled={props.addCategory ? false : !props.isEdit}
          >
            {t('cancel')}
          </Button>
          {props.updateCategory && (
            <>
              <Button
                variant="outlined"
                onClick={onEditCategory}
                type="button"
                disabled={props.isEdit}
              >
                {t('faq_page.categories.edit_button')}
              </Button>
              <Button
                variant="outlined"
                onClick={onDeleteCategory}
                type="button"
                disabled={props.isSave}
              >
                {t('faq_page.categories.delete_button')}
              </Button>
            </>
          )}
          <LoadingButton
            loading={props.isSave}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{ color: '#fff' }}
            disabled={props.addCategory || props.isEdit ? false : true}
            type="submit"
          >
            {t('faq_page.categories.save_button')}
          </LoadingButton>
        </Box>
      </Box>

      <Box className={classes.order}>
        <Typography maxWidth="100px" width="100%">
          {t('faq_page.questions.order')}:
        </Typography>
        {props.addCategory ? (
          <Field
            name="order"
            placeholder={t('faq_page.categories.order')}
            autoFocus
            component={InputForm}
            validate={[maxLength10, onlyNumber, negativeOrder]}
          />
        ) : props.updateCategory && props.isEdit ? (
          <Field
            name="order"
            autoFocus
            placeholder={
              props.categoryState.currentCategory.order ||
              `${t('faq_page.categories.no_order')}`
            }
            component={InputForm}
            validate={[maxLength10, onlyNumber, negativeOrder]}
          />
        ) : (
          <TextField
            disabled
            placeholder={
              props.categoryState.currentCategory.order ||
              `${t('faq_page.categories.no_order')}`
            }
          />
        )}
      </Box>
      <Box className={classes.status}>
        <Typography maxWidth="100px" width="100%">
          {t('faq_page.questions.status')}:
        </Typography>
        {props.addCategory ? (
          <Field name="status" component={SelectInput}>
            {props.status[0].parameters.map((item) => (
              <option value={item.id} key={item.id}>
                {item.value[props.appState.selectedLanguage]}
              </option>
            ))}
          </Field>
        ) : props.updateCategory && props.isEdit ? (
          <Field name="status" component={SelectInput} disabled={!props.isEdit}>
            {props.status[0].parameters.map((item) => (
              <option value={item.id} key={item.id}>
                {item.value[props.appState.selectedLanguage]}
              </option>
            ))}
          </Field>
        ) : (
          <Field name="status" component={SelectInput} disabled>
            {props.status[0].parameters.map((item) => (
              <option value={item.id} key={item.id}>
                {item.value[props.appState.selectedLanguage]}
              </option>
            ))}
          </Field>
        )}
      </Box>
      <Box className={cl.tabsBox}>
        <FormSection name="name">
          <LanguageTabs
            addElement={props.addCategory}
            updateElement={props.updateCategory}
            isEdit={props.isEdit}
            currentItem={props.categoryState.currentCategory}
            text={t('faq_page.categories.new_category')}
            languages={props.languages}
            error={props.error}
          />
        </FormSection>
      </Box>
      <div className={cl.radioBox}>
        <FormGroup column>
          <label className={cl.label}>
            {t('faq_page.categories.available_for_destination')}
          </label>
          {props.addCategory
            ? props.settingsState.destinationData.map((item) => (
                <Field
                  name={item.name}
                  component={StyledCheckboxInput}
                  label={item.name}
                  key={item.id}
                  isEdit={props.isEdit}
                />
              ))
            : props.updateCategory && props.isEdit
            ? props.settingsState.destinationData.map((item) => (
                <Field
                  name={item.name}
                  component={StyledCheckboxInput}
                  label={item.name}
                  key={item.id}
                  isEdit={props.isEdit}
                />
              ))
            : props.settingsState.destinationData.map((item) => (
                <Field
                  name={item.name}
                  component={StyledCheckboxDisabledInput}
                  label={item.name}
                  disabled
                  isChecked={props.categoryState.currentCategory.availableForDestination.includes(
                    `${item.id}`,
                    0
                  )}
                  key={item.id}
                />
              ))}
        </FormGroup>
      </div>
      <div className={cl.imageLabel}>{t('settings_page.category.icon')}</div>
      {props.addCategory && (
        <div className={cl.helperText}>
          {t('settings_page.category.icon_text')}
        </div>
      )}
      {props.isEdit && (
        <div className={cl.helperText}>
          {t('settings_page.category.icon_text')}
        </div>
      )}
      <Box style={{ marginBottom: '10px' }}>
        <Field
          name="icon"
          component={ReduxImageDrop}
          disabled={props.addCategory ? false : !props.isEdit}
          filesLimit={1}
          showFileNames
          allowedFileTypes={['svg']}
        />
      </Box>

      <ConfirmationDialog
        showAlert={props.showAlert}
        handleClose={handleClose}
        handleCloseSuccess={props.handleCloseSuccess}
        text={t('faq_page.categories.delete_category')}
      />
    </form>
  );
};

const getDestinationAvailability = (destinationsId, allDestinations) => {
  let destinations = {};
  // eslint-disable-next-line array-callback-return
  allDestinations.map((destination) => {
    if (destinationsId.includes(`${destination.id}`, 0))
      destinations[destination.name] = true;
    else destinations[destination.name] = false;
  });
  return destinations;
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.addCategory) {
    return {
      initialValues: {
        order: '',
        name: '',
        status: 'Inactive',
        icon: [],
      },
    };
  }

  if (ownProps.isEdit) {
    return {
      initialValues: {
        order: state.categoryFaqReducer.currentCategory.order,
        name: { ...state.categoryFaqReducer.currentCategory.name },
        status: state.categoryFaqReducer.currentCategory.status,
        icon:
          state.categoryFaqReducer.currentCategory.icon === null
            ? []
            : [state.categoryFaqReducer.currentCategory.icon],
        ...getDestinationAvailability(
          state.categoryFaqReducer.currentCategory.availableForDestination,
          state.settingsReducer.destinationData
        ),
      },
    };
  }

  if (!ownProps.addCategory && !ownProps.isEdit) {
    return {
      initialValues: {
        order: state.categoryFaqReducer.currentCategory.order,
        name: { ...state.categoryFaqReducer.currentCategory.name },
        status: state.categoryFaqReducer.currentCategory.status,
        icon: state.categoryFaqReducer.currentCategory.icon
          ? [state.categoryFaqReducer.currentCategory.icon]
          : [],
        ...getDestinationAvailability(
          state.categoryFaqReducer.currentCategory.availableForDestination,
          state.settingsReducer.destinationData
        ),
      },
    };
  }
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'CategoryFaqForm', enableReinitialize: true })(CategoryForm)
);
