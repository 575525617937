import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Box,
  InputLabel,
  FormControl,
  Typography,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { StyledSelect } from './styled';

import { setPageSize } from '../../redux/attractionsReducers/attractions/attractionsActions';
import { setPageSize as setSalesPointsPageSize } from '../../redux/salesPointsReducer/salesPointsActions';
import {DEFAULT_PAGE_SIZE} from "../constants";

const useStyles = makeStyles({
  topBarSelect: {
    marginRight: '20px',
    maxWidth: '300px',
    width: '100%',
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const DestinationSelect = (props) => {
  const { settingsState, setCheckedDestination } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedDestinationName = localStorage.getItem('currentDestinationId');

  useEffect(() => {
    const checkedDestination = settingsState.destinationData.find(
      (item) => item.name === selectedDestinationName
    );
    checkedDestination && setCheckedDestination(checkedDestination);

    if (!checkedDestination) {
      localStorage.setItem(
        'currentDestinationId',
        settingsState.destinationData[0].name
      );
      setCheckedDestination(settingsState.destinationData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsState.destinationData, selectedDestinationName]);

  const handleChange = (event) => {
    localStorage.setItem('currentDestinationId', event.target.value);

    dispatch(setPageSize(DEFAULT_PAGE_SIZE));
    dispatch(setSalesPointsPageSize(DEFAULT_PAGE_SIZE));

    navigate('/');
  };

  return (
    <Box className={classes.topBarSelect}>
      <FormControl fullWidth size="small">
        <InputLabel color="divider" id="demo-simple-select-label">
          {t('top_bar.destination_select_label')}
        </InputLabel>
        {selectedDestinationName && (
          <StyledSelect
            color="divider"
            labelId="demo-simple-select-label"
            value={selectedDestinationName}
            label={t('top_bar.destination_select_label')}
            onChange={(event, index) => handleChange(event, index)}
            MenuProps={MenuProps}
          >
            {settingsState.destinationData.map((item) => (
              <MenuItem value={item.name} key={item.id}>
                <Typography variant="inherit" noWrap>
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
          </StyledSelect>
        )}
      </FormControl>
    </Box>
  );
};

export default DestinationSelect;
