import { useEffect, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import OtpInput from 'react18-input-otp';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { concatConfirmationCode, createSeparatedOTPinput } from '../helpers';
import RedeemCodeFormContainer from '../redeemCodeForm/RedeemCodeFormContainer';

import { parseVisitorNumber } from '../../../users/visitorsTab/helpers';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import {
  checkConfirmationCode,
  postConfirmationCode,
} from '../../../../api/visitsApi';
import { getAttractionVisitsThunk } from '../../../../redux/visits/visitsActions';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  codeLetterInput: {
    height: '40px',
    width: '40px !important',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '6px',
    border: '1px solid #c4c4c4',
    textAlign: 'center',

    '&:disabled': {
      backgroundColor: '#cccccc',
    },
  },
  otpInput: {
    height: '40px',
    width: '40px !important',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '6px',
    border: '1px solid #c4c4c4',
  },
  otpContainer: {
    gap: '10px',
  },
  separator: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
  firstSymbolSeparator: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginLeft: '10px',
    marginRight: '10px',
  },
  codeInputLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginRight: '20px',
  },
});

const CONFIRMATION_CODE_LENGTH = 10;

const RedeemCodePopupContainer = ({
  benefitOptions,
  selectedLanguage,
  handleClosePopup,
  requestVisitsData,
}) => {
  const { id } = useParams();
  const { addError } = useAPIError();
  const { t } = useTranslation();

  const [otp, setOtp] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      attraction: '',
      dateAndTime: null,
      dealNumber: '',
      benefit: '',
      visitorId: '',
    },
  });

  const { reset } = formMethods;

  const handleSetFormData = useCallback(
    (data) => {
      const translatedBenefit =
        benefitOptions &&
        benefitOptions.parameters.find(
          (benefitItem) => benefitItem.id === data.benefit
        ).value[selectedLanguage];

      const formattedData = {
        attraction: data.attraction,
        dateAndTime: data.dateAndTime,
        dealNumber: data.dealNumber,
        benefit: translatedBenefit,
        visitorId: parseVisitorNumber(data.visitorId),
      };

      reset(formattedData);
      setShowForm(true);
      setErrorMessage('');
    },
    [benefitOptions, reset, selectedLanguage]
  );

  const handleChange = (enteredOtp) => {
    const allowedValue = enteredOtp.replace(/[^A-Za-z0-9]/gi, '').toUpperCase();

    if (enteredOtp.length < CONFIRMATION_CODE_LENGTH) {
      setShowForm(false);
      setErrorMessage('');
    }

    setOtp(allowedValue);
  };

  const handleSubmitCode = (data) => {
    const submitData = {
      attractionId: id,
      confirmationCode: concatConfirmationCode(otp),
      dateAndTime: data.dateAndTime,
    };

    setShowSaveAnimation(true);

    postConfirmationCode(id, submitData)
      .then((res) => {
        if (res.status === 200) {
          setShowSaveAnimation(false);
          addError(t('visitsPage.notifications.code_submitted'), 'Success');
          handleClosePopup();
          dispatch(
            getAttractionVisitsThunk(...Object.values(requestVisitsData))
          );
        }
      })
      .catch((err) => {
        setShowSaveAnimation(false);
        addError(t('visitsPage.notifications.code_not_submitted'), 'Error', err);
        handleClosePopup();
      });
  };

  useEffect(() => {
    otp.length === CONFIRMATION_CODE_LENGTH &&
      checkConfirmationCode(id, concatConfirmationCode(otp)).then((res) => {
        if (res.data && res.status === 200) {
          handleSetFormData(res.data);
        }

        if (res.data && res.status === 400) {
          setErrorMessage(res.data.title);
          setShowForm(false);
        }
      });
  }, [handleSetFormData, id, otp, reset]);

  useEffect(() => {
    createSeparatedOTPinput(classes.separator);
  }, [classes.separator]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" alignItems="center" mb="30px">
        <Typography className={classes.codeInputLabel}>
          {t('visitsPage.enter_code')}
        </Typography>

        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={10}
          inputStyle={classes.otpInput}
          containerStyle={classes.otpContainer}
          shouldAutoFocus
        />
      </Box>

      {!!errorMessage.length && (
        <Box display="flex" justifyContent="center">
          <Typography color="error">{errorMessage}</Typography>
        </Box>
      )}

      {showForm && (
        <FormProvider {...formMethods}>
          <RedeemCodeFormContainer
            showSaveAnimation={showSaveAnimation}
            handleSubmitCode={handleSubmitCode}
          />
        </FormProvider>
      )}
    </Box>
  );
};

export default RedeemCodePopupContainer;
