import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { TableCell } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { StyledLogsTableContentRow, StyledLogsTableRow } from './styled';

import {
  PricesLogFormContainer,
  NotificationsLogFormContainer,
  DealsLogFormContainer,
} from '../forms/logsFormContainers';
import { ATTRACTION_LOG_SECTIONS, ATTRACTION_LOG_TYPES } from '../constants';

const LogsTableDataRow = ({
  isExpanded,
  onRowClick,
  logData,
  attractionList,
}) => {
  const { attractionId, dateAndTime, section, type, user } = logData;

  const { predefinedData } = useSelector((state) => state.predefinedReducer);
  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );

  const attraction = attractionList.find(
    (attraction) => attraction.id === attractionId
  );

  const attractionLogSections = predefinedData.find(
    (item) => item.name.en === ATTRACTION_LOG_SECTIONS
  );

  const attractionLogTypes = predefinedData.find(
    (item) => item.name.en === ATTRACTION_LOG_TYPES
  );

  const sectionTranslation = attractionLogSections?.parameters.find(
    (option) => option.id === section
  );

  const typeTranslation = attractionLogTypes?.parameters.find(
    (option) => option.id === type
  );

  const dateAndTimeOfLog = dayjs(dateAndTime).format('DD.MM.YYYY HH:mm');

  return (
    <>
      <StyledLogsTableRow
        onClick={() => onRowClick()}
        sx={{ ...(isExpanded && { bgcolor: '#FFA94C' }) }}
      >
        <TableCell>{attraction?.name}</TableCell>
        <TableCell>{dateAndTimeOfLog}</TableCell>
        <TableCell>{sectionTranslation.value[selectedLanguage]}</TableCell>
        <TableCell>{typeTranslation.value[selectedLanguage]}</TableCell>
        <TableCell>{user}</TableCell>
        <TableCell>
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
      </StyledLogsTableRow>
      {isExpanded && (
        <StyledLogsTableContentRow>
          <TableCell colSpan={6}>
            {section === 'Prices' && (
              <PricesLogFormContainer item={logData.entity} />
            )}
            {section === 'Notifications' && (
              <NotificationsLogFormContainer logData={logData} />
            )}
            {section === 'Deals' && <DealsLogFormContainer logData={logData} />}
          </TableCell>
        </StyledLogsTableContentRow>
      )}
    </>
  );
};

export default LogsTableDataRow;
