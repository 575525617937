import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TodayIcon from '@mui/icons-material/Today';
import { LocalizationProvider } from '@mui/lab';
import { InputAdornment } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
  AttractionReportDateTextField,
  AttractionReportFormDateField,
} from '../../styled';

const AttractionReportDateFieldController = ({
  name,
  required,
  minDate,
  maxDate,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenCalendar = () => setIsOpen(true);
  const handleCloseCalendar = () => setIsOpen(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('attractions.deals.error_required'),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AttractionReportFormDateField
            clearable
            inputFormat="dd.MM.yyyy"
            onChange={(event) => onChange(event)}
            value={value}
            mask={'__.__.____'}
            showToolbar={false}
            open={isOpen}
            onOpen={handleOpenCalendar}
            onClose={handleCloseCalendar}
            disableCloseOnSelect={false}
            minDate={minDate}
            maxDate={maxDate}
            cancelText={t('cancel')}
            okText={t('ok')}
            clearText={t('clear')}
            renderInput={(params) => (
              <AttractionReportDateTextField
                {...params}
                size="small"
                placeholder={t('visitsPage.visitsFilterFields.date')}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      onClick={handleOpenCalendar}
                    >
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default AttractionReportDateFieldController;
