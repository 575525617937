import { stopSubmit } from 'redux-form';
import {
  getSalesPointListApi,
  createSalesPointApi,
  updateSalesPointApi,
  deleteSalesPointApi,
  getSalesPointShortDataListApi,
  getSalesPointsSearchApi,
  getSalesPointApi,
} from '../../api/salesPointsApi';

export const SET_POINT_LIST = 'salesPoints/setPointList';
export const SET_POINT_SHORT_DATA_LIST = 'salesPoints/setPointShortDataList';
export const SET_CURRENT_POINT = 'salesPoints/setCurrentPoint';
export const SET_SELECTED_REGION = 'salesPoints/setSelectedRegion';
export const SET_SELECTED_AREA = 'salesPoints/setSelectedArea';
export const SET_PAGE_SIZE = 'salesPoints/setPageSize';
export const SET_PAGE_NUMBER = 'salesPoints/setPageNumber';
export const SET_TOTAL_COUNT = 'salesPoints/setTotalCount';
export const SET_TOTAL_PAGES = 'salesPoints/setTotalPages';
export const IS_POINT_LIST_LOADING = 'salesPoints/isPointListLoading';
export const IS_POINT_LOADING = 'salesPoints/isPointLoading';
export const SET_ADD_ELEMENT = 'salesPoints/setAddPoint';

export const SET_STATUS_VALUE = 'salesPoints/setStatusValue';
export const SET_POINT_VALUE = 'salesPoints/setPointValue';
export const SET_CATEGORY_VALUE = 'salesPoints/setCategoryValue';
export const SET_REGION_VALUE = 'salesPoints/setRegionValue';
export const SET_AREA_VALUE = 'salesPoints/setAreaValue';
export const SET_LOCATION_VALUE = 'salesPoints/setLocationValue';

const setPointList = (data) => {
  return { type: SET_POINT_LIST, payload: { data } };
};
const setPointShortDataList = (data) => {
  return { type: SET_POINT_SHORT_DATA_LIST, payload: { data } };
};
export const setCurrentPoint = (data) => {
  return { type: SET_CURRENT_POINT, payload: { data } };
};
export const setSelectedRegion = (selectedRegion) => {
  return { type: SET_SELECTED_REGION, selectedRegion };
};
export const setSelectedArea = (selectedArea) => {
  return { type: SET_SELECTED_AREA, selectedArea };
};
export const setPageSize = (pageSize) => {
  return { type: SET_PAGE_SIZE, pageSize };
};
export const setPageNumber = (pageNumber) => {
  return { type: SET_PAGE_NUMBER, pageNumber };
};
export const setTotalCount = (totalCount) => {
  return { type: SET_TOTAL_COUNT, totalCount };
};
const setTotalPages = (totalPages) => {
  return { type: SET_TOTAL_PAGES, totalPages };
};
export const setIsPointListLoading = (data) => {
  return { type: IS_POINT_LIST_LOADING, payload: { data } };
};
export const setIsPointLoading = (data) => {
  return { type: IS_POINT_LOADING, payload: { data } };
};
export const setAddElement = (addElement) => {
  return { type: SET_ADD_ELEMENT, addElement };
};

export const setStatusValue = (statusValue) => {
  return { type: SET_STATUS_VALUE, statusValue };
};
export const setPointValue = (attractionsValue) => {
  return { type: SET_POINT_VALUE, attractionsValue };
};
export const setCategoryValue = (categoryValue) => {
  return { type: SET_CATEGORY_VALUE, categoryValue };
};
export const setRegionValue = (regionValue) => {
  return { type: SET_REGION_VALUE, regionValue };
};
export const setAreaValue = (areaValue) => {
  return { type: SET_AREA_VALUE, areaValue };
};
export const setLocationValue = (locationValue) => {
  return { type: SET_LOCATION_VALUE, locationValue };
};

export const getSalesPointListThunk =
  ({
    destinationId,
    pageNumber = 0,
    pageSize = 10,
    search = '',
    status = '',
    category = '',
    region = '',
    area = '',
    location = '',
    sort = 'NameAsc',
  }) =>
  async (dispatch) => {
    dispatch(setIsPointListLoading(true));
    const response = await getSalesPointListApi({
      destinationId,
      pageNumber,
      pageSize,
      search,
      status,
      category,
      region,
      area,
      location,
      sort,
    });
    if (response.status >= 200 && response.status < 300) {
      dispatch(setPointList(response.data.items));

      if (response.data.totalPages <= pageNumber) dispatch(setPageNumber(0));
      else dispatch(setPageNumber(pageNumber));

      dispatch(setPageSize(pageSize));
      dispatch(setTotalCount(response.data.totalCount));
      dispatch(setTotalPages(response.data.totalPages));
      dispatch(setIsPointListLoading(false));
    }
  };

export const getSalesPointShortDataListThunk =
  (destinationId) => async (dispatch) => {
    const response = await getSalesPointShortDataListApi(destinationId);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setPointShortDataList(response.data));
    }
  };

export const getSalesPointSearchThunk =
  (
    destinationId,
    pageNumber,
    pageSize,
    attractions,
    status,
    category,
    region,
    area,
    location,
    sort
  ) =>
  async (dispatch) => {
    const response = await getSalesPointsSearchApi(
      destinationId,
      pageNumber,
      pageSize,
      attractions,
      status,
      category,
      region,
      area,
      location,
      sort
    );
    if (response.status >= 200 && response.status < 300) {
      dispatch(setPointList(response.data.items));
      dispatch(setIsPointLoading(true));
      dispatch(setTotalCount(response.data.totalCount));
      dispatch(setIsPointListLoading(false));
    }
  };

export const createSalesPointThunk = (data) => async (dispatch) => {
  const response = await createSalesPointApi(data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Setup', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
  return response;
};

export const getSalesPointThunk = (id) => async (dispatch) => {
  const response = await getSalesPointApi(id);
  if (response.status >= 200 && response.status < 300) {
    dispatch(setCurrentPoint(response.data));
    dispatch(setIsPointLoading(true));
  }
};

export const updateSalesPointThunk = (id, data) => async (dispatch) => {
  const response = await updateSalesPointApi(id, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Setup', { _error: response.data.errors }));
    dispatch(stopSubmit('Content', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const deleteSalesPointThunk = (pointId) => async () => {
  const response = await deleteSalesPointApi(pointId);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
