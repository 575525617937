import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, StyledEngineProvider } from '@mui/material';
import { MainImageFormFieldTitle, MainImageFormFieldWrapper, StyledTextField } from './styled'; 
import cl from '../../attractions/deals/newDealForm/DealFormLanguageTabs.module.scss';
import { useTranslation } from 'react-i18next';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const MainImageFormTextLanguageTabs = ({
  formLabels,
  languages,
  handleChangeLanguageTab,
  currentTabId,
  name, 
  disabled,
  maxLength,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Box className={cl.wrapper}>
      <StyledEngineProvider injectFirst>
        <Box className={cl.tabsContainer}>
          <Tabs
            value={currentTabId}
            onChange={handleChangeLanguageTab}
            indicatorColor={undefined}
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            {languages.map((lang, index) => (
              <Tab
                key={lang}
                value={lang}
                label={lang.toUpperCase()}
                {...a11yProps(index)}
                className={cl.tab}
              />
            ))}
          </Tabs>
        </Box>
        <Box>
          {languages.map((lang) => (
            <TabPanel key={lang} value={currentTabId} index={lang}>
              <MainImageFormFieldWrapper>
                <MainImageFormFieldTitle>{formLabels[1]}</MainImageFormFieldTitle>
                <Controller
                  name={`${name}.${lang}`} 
                  control={control}
                  rules={{
                    maxLength: {
                      value: maxLength,
                      message: t('mainImage.validation.max_length', {
                        chars: maxLength,
                      }),
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <StyledTextField
                      disabled={disabled}
                      fullWidth
                      size="small"
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </MainImageFormFieldWrapper>
            </TabPanel>
          ))}
        </Box>
      </StyledEngineProvider>
    </Box>
  );
};

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
};

MainImageFormTextLanguageTabs.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChangeLanguageTab: PropTypes.func.isRequired,
  currentTabId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default MainImageFormTextLanguageTabs;
