import React from 'react';
import { Field, reduxForm, FormSection } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@mui/material';

import ConfirmationDialog from '../../components/confirmationDialog/ConfirmationDialog';
import LanguageTabs from './LanguageTabs';

import {
  LoaderWrapper,
  StyledTranslationForm,
  TranslationFormButton,
  TranslationFormControlPanel,
  TranslationFormFieldTitle,
  TranslationFormFieldWrapper,
  TranslationFormHeader,
  TranslationFormHeading,
  TranslationFormLanguageTabsWrapper,
  TranslationFormSelectField,
  TranslationFormSubmitButton,
  TranslationFormTextField,
} from './styled';

import { maxLength250, requiredName, noSpaceName } from './validate.js';

const TranslationForm = (props) => {
  const { onCancel } = props;

  const { t } = useTranslation();

  const onEditKey = () => {
    props.setIsEdit(true);
  };

  const onDeleteKey = () => {
    props.setShowAlert(true);
  };

  const handleClose = () => {
    props.setIsEdit(false);
    props.setShowAlert(false);
  };

  const createOptionElement = () => {
    return props.translationsState.translationsCategoryData.map((item) => (
      <option value={item.key} key={item.key}>
        {item.name}
      </option>
    ));
  };

  if (!props.translationsState.isLoadingCategoryData) {
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  }

  return (
    <StyledTranslationForm component="form" onSubmit={props.handleSubmit}>
      <TranslationFormHeader>
        <TranslationFormHeading>
          {!props.addKey
            ? props.translationsState.currentKey.key
            : `${t('settings_page.translation.new_key')}`}
        </TranslationFormHeading>
        <TranslationFormControlPanel>
          {props.addKey ? (
            <>
              <TranslationFormButton
                onClick={onCancel}
                label={t('settings_page.translation.cancel_button')}
                disabled={props.isSave}
              />
              <TranslationFormSubmitButton
                isLoading={props.isSave}
                label={t('settings_page.translation.save_button')}
              />
            </>
          ) : props.isEdit && props.updateKey ? (
            <>
              <TranslationFormButton
                disabled={props.isSave}
                onClick={onCancel}
                label={t('settings_page.translation.cancel_button')}
              />
              <TranslationFormSubmitButton
                label={t('settings_page.translation.save_button')}
                isLoading={props.isSave}
                disabled={props.addAppVersion || props.isEdit ? false : true}
              />
            </>
          ) : (
            <>
              <TranslationFormButton
                disabled={props.isSave}
                onClick={onCancel}
                label={t('settings_page.translation.cancel_button')}
              />
              <TranslationFormButton
                disabled={props.isSave}
                onClick={onEditKey}
                label={t('settings_page.translation.edit_button')}
              />

              <TranslationFormButton
                disabled={props.isSave}
                onClick={onDeleteKey}
                label={t('settings_page.translation.delete_button')}
              />
              <TranslationFormSubmitButton
                isLoading={props.isSave}
                disabled={props.addAppVersion || props.isEdit ? false : true}
                label={t('settings_page.translation.save_button')}
              />
            </>
          )}
        </TranslationFormControlPanel>
      </TranslationFormHeader>
      <TranslationFormFieldWrapper>
        <TranslationFormFieldTitle>
          {t('settings_page.translation.key')}:
        </TranslationFormFieldTitle>
        <Field
          name="key"
          placeholder={t('settings_page.translation.key')}
          component={TranslationFormTextField}
          validate={[maxLength250, requiredName, noSpaceName]}
          disabled={props.addKey ? false : !props.isEdit}
        />
      </TranslationFormFieldWrapper>
      <TranslationFormFieldWrapper>
        <TranslationFormFieldTitle>
          {t('settings_page.translation.category')}:
        </TranslationFormFieldTitle>
        <Field
          name="category"
          component={TranslationFormSelectField}
          disabled={props.addKey ? false : !props.isEdit}
        >
          <option value="">{t('settings_page.translation.none')}</option>
          {createOptionElement()}
        </Field>
      </TranslationFormFieldWrapper>
      <TranslationFormLanguageTabsWrapper>
        <FormSection name="value">
          <LanguageTabs
            addElement={props.addKey}
            updateElement={props.updateKey}
            isEdit={props.isEdit}
            currentElement={props.translationsState.currentKey}
            text={t('settings_page.translation.translation')}
            languages={props.appState.globalSettingsData}
            errorText={props.error}
            languageList={props.languageList}
          />
        </FormSection>
      </TranslationFormLanguageTabsWrapper>

      <ConfirmationDialog
        showAlert={props.showAlert}
        handleClose={handleClose}
        handleCloseSuccess={props.handleCloseSuccess}
        text="Delete this key?"
      />
    </StyledTranslationForm>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.addKey) {
    return { initialValues: {} };
  } else {
    return {
      initialValues: {
        key: state.translationsReducer.currentKey.key,
        category: state.translationsReducer.currentKey.category.key,
        value: { ...state.translationsReducer.currentKey.value },
      },
    };
  }
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'TranslationForm', enableReinitialize: true })(
    TranslationForm
  )
);
