import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StarRateIcon from '@mui/icons-material/StarRate';

import ReviewEditFormContainer from '../forms/ReviewEditFormContainer';

import { ReviewTableRow } from './styled';
import { REVIEW_ROW_CELLS_AMOUNT } from '../constants';

const colors = {
  blue: '#32D7F0',
  orange: '#FFA94C',
  grey: '#A0A0A0',
  white: '#FFF',
};

const statusColor = {
  New: colors.blue,
  Approved: colors.orange,
  Removed: colors.grey,
};

const getFormatedDate = (date) => {
  return dayjs(date).format('DD.MM.YYYY');
};

const getAttractionName = (attractionId, attractionList) => {
  return attractionList.find((attraction) => attraction.id === attractionId)
    ?.name;
};

const ReviewRow = (props) => {
  const {
    item,
    onClick,
    isExpanded,
    resetActiveRow,
    attractionList,
    updateReviewList,
  } = props;

  const { t } = useTranslation();
  const reviewRating = [];

  for (let i = 0; i < item.rating; i++) {
    reviewRating.push(<StarRateIcon />);
  }

  return (
    <>
      <ReviewTableRow onClick={onClick}>
        <TableCell
          sx={{
            bgcolor: statusColor[item.status],
          }}
        >
          {t(`reviews.statuses.${item.status.toLowerCase()}`)}
        </TableCell>
        <TableCell>
          {getAttractionName(item.attractionId, attractionList)}
        </TableCell>
        <TableCell>{reviewRating}</TableCell>
        <TableCell>{getFormatedDate(item.date)}</TableCell>
        <TableCell>{item.userName}</TableCell>
        <TableCell>{item.userPlace}</TableCell>
        <TableCell>{item.text}</TableCell>
        <TableCell>
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
      </ReviewTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={REVIEW_ROW_CELLS_AMOUNT}>
            <ReviewEditFormContainer
              reviewData={item}
              resetActiveRow={resetActiveRow}
              updateReviewList={updateReviewList}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ReviewRow;
