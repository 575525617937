import {
  Autocomplete,
  FormControl,
  Chip,
  Box,
  TextField,
} from '@mui/material';

export default function AutocompleteInput({
  input,
  getOptionLabel,
  options,
  meta: { touched, error },
  ...props
}) {
  return (
    <FormControl error={touched && error} fullWidth>
      <Autocomplete
        id="combo-box-demo"
        options={options}
        value={
          props.multiple
            ? input.value || []
            : props.disabled
            ? props.defaultValue
            : input.value || null
        }
        onChange={(_, newValue) => {
          input.onChange(newValue);
        }}
        defaultValue={props.multiple ? [] : null}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            size="small"
            required={props.required}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            error={touched && error}
            helperText={touched && error}
          />
        )}
        renderTags={(value, getTagProps) => {
          return (
            <Box>
              {value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={index}
                  label={getOptionLabel(option)}
                />
              ))}
            </Box>
          );
        }}
        {...props}
      />
    </FormControl>
  );
}
