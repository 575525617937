import { useTranslation } from "react-i18next";

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, FormControl, Select, Stack, IconButton } from "@mui/material";
import { Field, reduxForm } from "redux-form";

import AutocompleteInput from "../components/AutocompleteInput";

const StatusSelect = ({
  value,
  onChange,
  label,
  children,
  disabled,
  disabledValue,
  ...props
}) => {
  return (
    <FormControl fullWidth disabled={disabled}>
      <Select
        displayEmpty
        native
        value={value}
        onChange={onChange}
        size="small"
      >
        {children}
      </Select>
    </FormControl>
  );
};

const createOptions = (options, selectedLanguage) => {
  if (!options || !options.parameters.length) return null;
  return options.parameters.map((option, index) => (
    <option value={option.id} key={index}>
      {option.value[selectedLanguage] || option.value.en}
    </option>
  ));
};

let DealsFilterForm = ({
  availableStatuses,
  availableAttractions,
  attraction,
  setAttraction,
  dealStatus,
  setDealStatus,
  selectedLanguage,
  value,
  onSubmit,
  handleResetFilters
}) => {
  const { t } = useTranslation();

  const getFilteredDeals = (
    newAttraction,
    newStatus
  ) => {
    const filter = {
      attraction: newAttraction,
      status: newStatus
    };
    onSubmit(filter);
  };

  return (
    <Stack direction="row" spacing={3} alignItems="center">
      <Box sx={{ minWidth: 120 }}>
        <StatusSelect value={value} 
          onChange={(event) => {
            setDealStatus(event.target.value);
            getFilteredDeals(
              attraction,
              event.target.value
            );
          }}>
          <option value={""}>{t("attractions.deals.all_status")}</option>
          {createOptions(availableStatuses, selectedLanguage)}
        </StatusSelect>
      </Box>
      <Box sx={{ minWidth: 300 }}>
        <Field
          component={AutocompleteInput}
          options={[...availableAttractions]}
          getOptionLabel={(option) => option.name}
          name="attraction"
          label={t('notifications.attraction')}
          onChange={(event, newAttraction, prevValue, name) => {
            setAttraction(newAttraction);
            getFilteredDeals(
              newAttraction,
              dealStatus
            );
          }}
        ></Field>
      </Box>
      <IconButton onClick={handleResetFilters}>
        <DeleteIcon />
      </IconButton>
    </Stack>
  );
};

export default DealsFilterForm = reduxForm({
  form: 'DealsFilterForm'
})(DealsFilterForm);
