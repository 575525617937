import React from 'react';
import {
  TextField,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Select,
  Checkbox,
  Box,
} from '@mui/material';

const HelperText = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return (
      <FormHelperText
        sx={{ color: '#d43131', width: '300px', marginLeft: '0px' }}
      >
        * {touched && error}
      </FormHelperText>
    );
  }
};

export const CheckboxInput = ({
  input,
  label,
  meta: { touched, error },
  disabled,
  ...props
}) => {
  return (
    <>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            {...input}
            {...props}
            checked={input.value}
            onChange={input.onChange}
            sx={{ padding: '4px 4px 4px 8px' }}
          />
        }
        label={label}
      />
      {HelperText({ touched, error })}
    </>
  );
};

export const CheckboxDisabledInput = ({
  input,
  label,
  meta: { touched, error },
  ...props
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={props.disabled}
          checked={props.isChecked}
          sx={{ padding: '4px 4px 4px 8px' }}
        />
      }
      label={label}
    />
  );
};

export const Input = ({
  label,
  input,
  meta: { touched, invalid, error },
  text,
  length,
  ...props
}) => {
  return (
    <Box component={'div'}>
      <TextField
        label={label}
        placeholder={text}
        error={touched && error}
        {...input}
        {...props}
        size="small"
      />
      {HelperText({ touched, error })}
    </Box>
  );
};

export const SelectInput = ({
  input,
  label,
  meta: { touched, error },
  children,
  disabled,
  ...props
}) => {
  return (
    <FormControl error={touched && error} fullWidth>
      <Select
        displayEmpty
        native
        value={input.value}
        onChange={input.onChange}
        size="small"
        disabled={disabled}
        sx={{
          opacity: input.value !== '' ? 1 : 0.6,
          '&.Mui-disabled': {
            textFillColor: 'rgba(0, 0, 0, 0.6)',
            opacity: 0.6,
          },
        }}
      >
        {children}
      </Select>
      {HelperText({ touched, error })}
    </FormControl>
  );
};

export const InputComponent = ({
  label,
  input,
  meta: { touched, invalid, error },
  text,
  length,
  ...props
}) => {
  return (
    <Box component="div" sx={{ height: '75px' }}>
      <TextField
        {...input}
        {...props}
        value={input.value}
        onChange={input.onChange}
        label={label}
        placeholder={text}
        error={touched && error}
        size="small"
      />
      {HelperText({ touched, error })}
    </Box>
  );
};
