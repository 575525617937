import { connect } from 'react-redux';

import Deals from './Deals';

import {
  addDealThunk,
  editDealThunk,
  deleteDealThunk,
} from '../../../redux/attractionsReducers/deals/dealsActions';

import {
  DEAL_STATUS,
  DEAL_FILTER_STATUS,
  DEAL_BENEFITS,
} from '../../../consts/consts';

const mapStateToProps = (state) => {
  const dealFilterStatuses =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_FILTER_STATUS
    ) || null;

  const dealStatuses =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_STATUS
    ) || null;

  const benefitOptions =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_BENEFITS
    ) || null;

  const languages = state.appReducer.selectedDestination.languages;

  return {
    dealFilterStatuses,
    dealStatuses,
    benefitOptions,
    languages,
    dealsList: state.dealsReducer.dealsList,
    isDataLoading: state.dealsReducer.isDataLoading,
    selectedLanguage: state.appReducer.selectedLanguage,
    currentAttractionId: state.attractionsReducer.currentAttraction.id,
    tableSettings: state.dealsReducer.tableSettings,
  };
};

const DealsContainer = connect(mapStateToProps, {
  addDealThunk,
  editDealThunk,
  deleteDealThunk,
})(Deals);

export default DealsContainer;
