export const DEFAULT_EDITOR_FONT_FAMILY = 'Open Sans';

export const FONT_FAMILY_OPTION_LIST = [
  'Open Sans',
  'Arial',
  'Georgia',
  'Impact',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
