import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, FormSection } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TextEditor from '../../../components/RichTextEditor/TextEditor';
import FieldList from './fieldList/FieldList';

import {
  ContentFormFieldTitle,
  StyledTabButton,
  ContentFormErrorContainer,
  FieldListContainer,
  ContentFormTextField,
  TabButtonsContainer,
  LanguageTabPanel,
  ContentFormFieldWrapper,
} from './styled';

import { noSpaceName, maxLength250, required } from './validate.js';

const useStyles = makeStyles({
  tabsWrapper: {
    '& .MuiTabs-flexContainer': {
      flexWrap: 'wrap'
    },
  },
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <LanguageTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </LanguageTabPanel>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const AttractionLanguageTabs = (props) => {
  const { languages, isInEditMode, error } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useState(0);
  const [valueDirty, setValueDirty] = useState(false);

  const handleChangeTabs = (event, newValue) => {
    setTabsValue(newValue);
    setValueDirty(true);
  };

  return (
    <Box>
      <TabButtonsContainer>
        <Tabs
          value={tabsValue}
          indicatorColor="none"
          onChange={handleChangeTabs}
          className={classes.tabsWrapper}
        >
          {languages.map((tab, index) => (
            <StyledTabButton label={tab} {...a11yProps(index)} />
          ))}
        </Tabs>
      </TabButtonsContainer>
      {languages.map((tabPanel, index) => (
        <TabPanel value={tabsValue} index={index} key={index}>
          <ContentFormFieldWrapper>
            <ContentFormFieldTitle>
              {t('attractions.content.title')}:
            </ContentFormFieldTitle>
            <FormSection name="title">
              <Field
                name={tabPanel}
                component={ContentFormTextField}
                placeholder={t('attractions.content.title')}
                validate={[required, noSpaceName, maxLength250]}
                disabled={!isInEditMode}
              />
              {error && error.Title && (
                <ContentFormErrorContainer variant="caption" component="span">
                  {`* ${error.Title[0]}`}
                </ContentFormErrorContainer>
              )}
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <ContentFormFieldTitle>
              {t('attractions.content.subtitle')}:
            </ContentFormFieldTitle>
            <FormSection name="subtitle">
              <Field
                name={tabPanel}
                component={ContentFormTextField}
                placeholder={t('attractions.content.subtitle')}
                validate={[required, noSpaceName, maxLength250]}
                disabled={!isInEditMode}
              />
              {error && error.Subtitle && (
                <ContentFormErrorContainer variant="caption" component="span">
                  {`* ${error.Subtitle[0]}`}
                </ContentFormErrorContainer>
              )}
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <ContentFormFieldTitle>
              {t('attractions.content.introduction')}:
            </ContentFormFieldTitle>
            <FormSection name="introduction">
              <Field
                name={tabPanel}
                component={TextEditor}
                isEdit={isInEditMode}
                disabled={!isInEditMode}
                placeholder={t('attractions.content.introduction')}
                valueDirty={valueDirty}
                setValueDirty={setValueDirty}
              />
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <ContentFormFieldTitle>
              {t('attractions.content.description')}:
            </ContentFormFieldTitle>
            <FormSection name="description">
              <Field
                name={tabPanel}
                component={TextEditor}
                isEdit={isInEditMode}
                disabled={!isInEditMode}
                placeholder={t('attractions.content.description')}
                valueDirty={valueDirty}
                setValueDirty={setValueDirty}
              />
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <FormSection name="tips">
              <FieldListContainer>
                <ContentFormFieldTitle>
                  {t('attractions.content.tips')}:
                </ContentFormFieldTitle>
                <FieldArray
                  name={tabPanel}
                  component={FieldList}
                  disabled={!isInEditMode}
                  sectionName="tips"
                  formName="Content"
                />
              </FieldListContainer>
            </FormSection>
          </ContentFormFieldWrapper>
          <ContentFormFieldWrapper>
            <FormSection name="directions">
              <FieldListContainer>
                <ContentFormFieldTitle>
                  {t('attractions.content.directions')}:
                </ContentFormFieldTitle>
                <FieldArray
                  name={tabPanel}
                  component={FieldList}
                  disabled={!isInEditMode}
                  sectionName="directions"
                  formName="Content"
                />
              </FieldListContainer>
            </FormSection>
          </ContentFormFieldWrapper>
        </TabPanel>
      ))}
    </Box>
  );
};

export default AttractionLanguageTabs;
