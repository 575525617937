import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  UserInfoFormHeaderLabel,
  UserInfoFormContainer,
  UserInfoFormFieldWrapper,
  UserInfoFormFieldTitle,
  UserInfoFormSelectField,
  UserInfoFormTextField,
  UserInfoFormControlPanel,
  UserInfoFormAutocomplete,
  UserInfoFormHeader,
  UserInfoFormCheckbox,
  UserInfoFormSubmitButton,
  UserInfoFormButton,
} from '../styled';

import {
  createOptions,
  getCompanyOptionLabel,
  getAttractionOptionLabel,
  getCategoryOptionLabel,
  getRegionOptionLabel,
  getPointOptionLabel,
  getAreaOptionLabel
} from '../../users/usersTab/forms/helpers';
import { APP_ADMIN_ROLE, USER_INFO_FORM, APP_MANAGER_ROLE, APP_MANAGER_PRIMARY_ROLE } from '../constants';
import {
  maxLengthTextField,
  maxLengthEmail,
  maxLengthPhoneTextField
} from '../../users/usersTab/forms/validation';
import {UserFormButton, UserFormFieldTitle, UserFormFieldWrapper} from "../../users/usersTab/forms/styled";
import {useCallback} from "react";

const useStyles = makeStyles({
  wrapper: {
    padding: '0',
  },
});

let UserInfoForm = (props) => {
  const {
    onEdit,
    onCancel,
    onDelete,
    onFormPasswordChange,
    onFormVisitorCreate,
    showSaveAnimation,
    isInEditMode,
    userData,
    selectedLanguage,
    options,
    handleSubmit,
    userAppRole,
  } = props;

  const {
    attractionList,
    companyList,
    regionList,
    areaList,
    roleList,
    defaultUserCompany,
    languages,
  } = options;

  const { t } = useTranslation();
  const classes = useStyles();

  const companyListWithDefaultCompany = [...companyList, defaultUserCompany];
  const isAdminRole = userAppRole === APP_ADMIN_ROLE;
  const isManagerRole = (userAppRole === APP_MANAGER_ROLE) || (userAppRole === APP_MANAGER_PRIMARY_ROLE);

  const email = useCallback((value) => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value)
      ? t('users.invalid_email')
      : undefined,[selectedLanguage])

  const required = useCallback(value => (value ? undefined : t('required')), [selectedLanguage]);

  return (
    <UserInfoFormContainer className={isManagerRole ? classes.wrapper : null} component="form" onSubmit={handleSubmit}>
      <UserInfoFormHeader>
        <UserInfoFormHeaderLabel>
          <Typography
            fontWeight="bold"
            textTransform="uppercase"
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth="500px"
            width="100%"
          >
            {userData.name}
          </Typography>
        </UserInfoFormHeaderLabel>
        <UserInfoFormControlPanel>
          <UserInfoFormButton
            onClick={onCancel}
            label={t('users.buttons.cancel')}
            disabled={!isInEditMode}
          />
          <UserInfoFormButton
            onClick={onEdit}
            label={t('users.buttons.edit')}
            disabled={isInEditMode}
          />
          <UserInfoFormButton
            onClick={onFormPasswordChange}
            label={t('users.buttons.change_password')}
          />
          {(!userData.isLinkedVisitorExist) && (
              <UserFormButton
                  onClick={onFormVisitorCreate}
                  label={t('users.buttons.create_visitor')}
              />
          )}
          {isAdminRole && (
            <UserInfoFormButton
              onClick={onDelete}
              label={t('users.buttons.delete_account')}
            />
          )}

          <UserInfoFormSubmitButton
            isLoading={showSaveAnimation}
            label={t('users.buttons.save')}
            disabled={!isInEditMode}
          />
        </UserInfoFormControlPanel>
      </UserInfoFormHeader>
      <UserFormFieldWrapper>
        <UserFormFieldTitle>{t('users.visitorAccount')}:</UserFormFieldTitle>
        {userData?.isLinkedVisitorExist ? t('users.visitor_already_exist') : t('users.visitor_does_not_exist')}
      </UserFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.name')}</UserInfoFormFieldTitle>
        <Field
          name="name"
          component={UserInfoFormTextField}
          disabled={!isInEditMode}
          validate={[required, maxLengthTextField]}
        />
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.position')}</UserInfoFormFieldTitle>
        <Field
          name="position"
          component={UserInfoFormTextField}
          disabled={!isInEditMode}
          validate={[maxLengthTextField]}
        />
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.email')}</UserInfoFormFieldTitle>
        <Field
          name="email"
          component={UserInfoFormTextField}
          disabled={!isInEditMode}
          validate={[required, email, maxLengthEmail]}
        />
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.phone')}:</UserInfoFormFieldTitle>
        <Field
          component={UserInfoFormTextField}
          name="phone"
          validate={[maxLengthPhoneTextField]}
          disabled={!isInEditMode}
        />
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.message')}:</UserInfoFormFieldTitle>
        <Field
          component={UserInfoFormTextField}
          name="message"
          disabled
          multiline
          rows={3}
        />
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>
          {t('users.my_language')}
        </UserInfoFormFieldTitle>
        <Field
          name="language"
          component={UserInfoFormSelectField}
          disabled={!isInEditMode}
        >
          {languages?.parameters.map((language, i) => (
            <option value={language.id} key={i}>
              {language.value}
            </option>
          ))}
        </Field>
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.last_login')}</UserInfoFormFieldTitle>
        <Field name="lastLogin" component={UserInfoFormTextField} disabled />
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.role')}</UserInfoFormFieldTitle>

        <Field name="role" component={UserInfoFormSelectField} disabled>
          {createOptions(roleList?.parameters, selectedLanguage)}
        </Field>
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <Field
          disabled
          component={UserInfoFormCheckbox}
          name="isTranslations"
          label={t('users.can_translate')}
        />
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.company')}</UserInfoFormFieldTitle>
        <Field
          name="company"
          component={UserInfoFormAutocomplete}
          options={[]}
          getOptionLabel={getCompanyOptionLabel(companyListWithDefaultCompany)}
          isOptionEqualToValue={(option, value) => {
            return value === option;
          }}
          disablePortal
          disabled
        />
      </UserInfoFormFieldWrapper>
      <UserInfoFormFieldWrapper>
        <Field
          component={UserInfoFormCheckbox}
          name="isAllAttractions"
          label={t('users.all_attractions_and_points')}
          disabled
        />
      </UserInfoFormFieldWrapper>
      {!isManagerRole ?
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.regions')}</UserInfoFormFieldTitle>
        <Field
          name="regions"
          component={UserInfoFormAutocomplete}
          options={[]}
          getOptionLabel={getRegionOptionLabel(regionList, selectedLanguage)}
          isOptionEqualToValue={(option, value) =>
            option.idRegion === value.idRegion
          }
          label={t('users.regions')}
          multiple
          disablePortal
          disabled
        />
      </UserInfoFormFieldWrapper> : null }
      {!isManagerRole ?
      <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>{t('users.areas')}</UserInfoFormFieldTitle>
        <Field
          name="areas"
          component={UserInfoFormAutocomplete}
          options={[]}
          getOptionLabel={getAreaOptionLabel(areaList, selectedLanguage)}
          isOptionEqualToValue={(option, value) =>
            option.idArea === value.idArea
          }
          label={t('users.areas')}
          multiple
          disablePortal
          disabled
        />
      </UserInfoFormFieldWrapper> : null }
       <UserInfoFormFieldWrapper>
        <UserInfoFormFieldTitle>
          {t('users.attractions')}
        </UserInfoFormFieldTitle>
        <Field
          name="attractions"
          component={UserInfoFormAutocomplete}
          options={[]}
          multiple
          getOptionLabel={getAttractionOptionLabel(attractionList)}
          label={t('users.attractions')}
          isOptionEqualToValue={(option, value) =>
            value.idAttraction === option.idAttraction
          }
          disablePortal
          disabled
        />
      </UserInfoFormFieldWrapper>
    </UserInfoFormContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.userData?.id) {
    const lastLogin = dayjs(ownProps.userData.lastLogin).format(
      'DD.MM.YYYY HH:mm'
    );

    return {
      userAppRole: state.authReducer.role,
      initialValues: {
        name: ownProps.userData.name,
        position: ownProps.userData.position,
        email: ownProps.userData.email,
        phone: ownProps.userData.phone,
        message: ownProps.userData.message,
        language: ownProps.userData.language,
        lastLogin: lastLogin,
        role: ownProps.userData.role,
        isTranslations: ownProps.userData.isTranslations,
        company: ownProps.userData.idCompany,
        isAllAttractions:
          ownProps.userData.isAllAttractions &&
          ownProps.userData.isAllSalesPoints,
        categories: ownProps.userData.categories,
        regions: ownProps.userData.regions,
        areas: ownProps.userData.areas,
        attractions: ownProps.userData.attractions,
        salesPoints: ownProps.userData.salesPoints,
      },
    };
  } else return { initialValues: {} };
};

UserInfoForm = reduxForm({
  form: USER_INFO_FORM,
  enableReinitialize: true,
})(UserInfoForm);

export default connect(mapStateToProps)(UserInfoForm);
