import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormControlLabel } from '@mui/material';

const AttractionReportShowDetailsField = ({ name, disabled }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <FormControlLabel
          sx={{
            marginLeft: 0,
          }}
          label={t('attractions.reports.showDetailsCheckbox')}
          labelPlacement="end"
          control={
            <Checkbox
              disabled={disabled}
              disableRipple
              onChange={(event) => onChange(event.target.checked)}
              checked={value}
              name={name}
              sx={{
                padding: 0,
                paddingRight: '9px',
              }}
            />
          }
        />
      )}
    />
  );
};

export default AttractionReportShowDetailsField;
