import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength10 = maxLength(10);
export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);

export const onlyNumber = (value) =>
  value && isNaN(Number(value)) ? 'Enter the number' : undefined;

export const negativeOrder = (value) =>
  value && value < 0 ? 'Order cannot be a negative' : undefined;

export const requiredName = (values) => (!values ? 'Enter name' : undefined);

export const noSpaceName = (values) =>
  values.trim() === '' ? 'Name cannot consist of just spaces' : undefined;
