import { makeStyles } from '@mui/styles';

import RedeemCodeBenefitField from './formFields/RedeemCodeBenefitField';
import RedeemCodeDateField from './formFields/RedeemCodeDateField';
import RedeemCodeFormTextField from './formFields/RedeemCodeFormTextField';

import {
  RedeemCodeDateFieldWrapper,
  RedeemCodeFormFieldTitle,
  RedeemCodeFormFieldWrapper,
  RedeemCodeFormSaveButton,
} from '../styled';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles({
  attractionInput: {
    maxWidth: 'initial',
    width: '100%',
  },
});

const RedeemCodeForm = ({ showSaveAnimation, handleSubmitCode }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { handleSubmit } = useFormContext();

  return (
    <form>
      <RedeemCodeFormFieldWrapper>
        <RedeemCodeFormFieldTitle>
          {t('visitsPage.formFieldLabels.attraction')}
        </RedeemCodeFormFieldTitle>
        <RedeemCodeFormTextField
          customClass={classes.attractionInput}
          name="attraction"
          disabled
        />
      </RedeemCodeFormFieldWrapper>

      <RedeemCodeDateFieldWrapper>
        <RedeemCodeFormFieldTitle>
          {t('visitsPage.formFieldLabels.dateAndTime')}
        </RedeemCodeFormFieldTitle>
        <RedeemCodeDateField name="dateAndTime" />
      </RedeemCodeDateFieldWrapper>

      <RedeemCodeFormFieldWrapper>
        <RedeemCodeFormFieldTitle>
          {t('visitsPage.formFieldLabels.dealNumber')}
        </RedeemCodeFormFieldTitle>
        <RedeemCodeFormTextField name="dealNumber" disabled />
      </RedeemCodeFormFieldWrapper>

      <RedeemCodeFormFieldWrapper>
        <RedeemCodeFormFieldTitle>
          {t('visitsPage.formFieldLabels.benefit')}
        </RedeemCodeFormFieldTitle>
        <RedeemCodeBenefitField name="benefit" disabled />
      </RedeemCodeFormFieldWrapper>

      <RedeemCodeFormFieldWrapper>
        <RedeemCodeFormFieldTitle>
          {t('visitsPage.formFieldLabels.coolpassNumber')}
        </RedeemCodeFormFieldTitle>
        <RedeemCodeFormTextField name="visitorId" disabled />
      </RedeemCodeFormFieldWrapper>

      <Box display="flex" justifyContent="flex-end" mt="30px">
        <RedeemCodeFormSaveButton
          label={t('visitsPage.save_button')}
          onClick={handleSubmit(handleSubmitCode)}
          isLoading={showSaveAnimation}
        />
      </Box>
    </form>
  );
};

export default RedeemCodeForm;
