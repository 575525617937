import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import {
  TextField,
  Select,
  IconButton,
  Box,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { validateTextFieldValue } from '../../../../helpers/validateTextFieldValue';

const useStyles = makeStyles({
  field: {
    maxWidth: '215px',
    width: '100%',
  },
  searchField: {
    '& .MuiOutlinedInput-root': {
      maxWidth: '235px',
      width: '100%',
    },
  },
});
const ICON_DEFAULT_COLOR = '#cfcaca';

const CompanyFilterForm = (props) => {
  const {
    options,
    status,
    setStatus,
    companyOrCity,
    setCompanyOrCity,
    handleResetFilters,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const selectedLanguage = options.selectedLanguage;

  const resetComapnyOrCityValue = () => {
    setCompanyOrCity('');
  };

  return (
    <Box display="flex" gap="24px" mt="30px" mb="20px" width="100%">
      <Select
        size="small"
        native
        className={classes.field}
        onChange={(e) => setStatus(e.target.value)}
        value={status}
      >
        <option value={''}>{t('companies.status')}</option>
        {options.availableStatuses[0]?.parameters.map((status, i) => (
          <option value={status.id} key={i}>
            {status.value[selectedLanguage] || status.value.en}
          </option>
        ))}
      </Select>

      <TextField
        placeholder={t('companies.company') + '/' + t('companies.city')}
        onChange={(e) =>
          setCompanyOrCity(validateTextFieldValue(e.target.value))
        }
        size="small"
        value={companyOrCity}
        className={classes.searchField}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {companyOrCity ? (
                <IconButton
                  onClick={resetComapnyOrCityValue}
                  sx={{ padding: 0, color: ICON_DEFAULT_COLOR }}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <SearchIcon sx={{ color: ICON_DEFAULT_COLOR }} />
              )}
            </InputAdornment>
          ),
        }}
      />
      <IconButton onClick={handleResetFilters}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default CompanyFilterForm;
