import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import TodayIcon from '@mui/icons-material/Today';
import { InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
  NotificationDateTextField,
  NotificationFormDateField,
} from '../styled';

const useStyles = makeStyles({
  dateFieldInputDisabled: {
    '& .MuiOutlinedInput-input': {
      cursor: 'default',
    },

    '& .MuiInputAdornment-root': {
      cursor: 'default',
    },
  },
});

const NotificationDateField = ({
  name,
  disabled,
  minDate,
  placeholder,
  maxDate,
  required,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: '* Required',
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <NotificationFormDateField
            inputFormat="dd.MM.yyyy"
            onChange={(event) => onChange(event)}
            value={value}
            disabled={disabled}
            mask={'__.__.____'}
            showToolbar={false}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onAccept={() => setIsOpen(false)}
            onClose={() => setIsOpen(false)}
            minDate={minDate}
            maxDate={maxDate}
            clearable
            clearText={t('clear')}
            cancelText={t('cancel')}
            okText={t('ok')}
            renderInput={(params) => (
              <NotificationDateTextField
                {...params}
                size="small"
                placeholder={placeholder}
                className={clsx(disabled && classes.dateFieldInputDisabled)}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      disablePointerEvents={disabled}
                      position="start"
                      onClick={() => setIsOpen(true)}
                    >
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default NotificationDateField;
