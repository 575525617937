import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, MenuItem, Typography } from '@mui/material';

import { SelectHelperText, SetupFormSelectFieldInput } from '../styled';

import { TYPE_AREA, TYPE_REGION } from '../../constants';
import { ACTIVE_STATUS } from '../constants';

const getAreaMenuItems = (
  optionList,
  selectedLanguage,
  defaultDestinationLanguage
) =>
  optionList.map(
    (area) =>
      area.locationStatus === ACTIVE_STATUS && (
        <MenuItem value={area.id} key={area.id}>
          <Typography variant="inherit" noWrap>
            {area.title[selectedLanguage] ||
              area.title.en ||
              area.title[defaultDestinationLanguage]}
          </Typography>
        </MenuItem>
      )
  );

const createAreaOption = (optionsData, selectedLanguage, regionId = '') => {
  if (!optionsData) return null;

  if (optionsData.enabledLocationTypes.includes(TYPE_REGION)) {
    const selectedRegion = optionsData.locations.find(
      (reg) => reg.id === regionId
    );

    if (!selectedRegion) return null;

    return getAreaMenuItems(
      selectedRegion.children,
      selectedLanguage,
      optionsData.languages[0]
    );
  }

  if (optionsData.enabledLocationTypes.includes(TYPE_AREA)) {
    return getAreaMenuItems(
      optionsData.locations,
      selectedLanguage,
      optionsData.languages[0]
    );
  }

  return null;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const SetupFormAreaSelectField = ({
  disabled,
  optionsData,
  selectedLanguage,
  name,
  emptyOption,
  regionId,
  required,
}) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) =>
          required && !value.length ? '* Required' : undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} fullWidth>
          <SetupFormSelectFieldInput
            MenuProps={MenuProps}
            value={value}
            disabled={disabled}
            onChange={(event) => {
              onChange(event.target.value);
              setValue('locationId', '');
            }}
            size="small"
          >
            {emptyOption && (
              <MenuItem value={emptyOption.value} id={emptyOption.id}>
                <Typography variant="inherit" noWrap>
                  {emptyOption.label}
                </Typography>
              </MenuItem>
            )}
            {createAreaOption(optionsData, selectedLanguage, regionId)}
          </SetupFormSelectFieldInput>
          <SelectHelperText>{error?.message}</SelectHelperText>
        </FormControl>
      )}
    />
  );
};

export default SetupFormAreaSelectField;
