import React, {useState} from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import { Button, Box, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import SetupFormTextField from './formFields/SetupFormTextField';
import SetupFormSelectField from './formFields/SetupFormSelectField';
import SetupFormRegionSelectField from './formFields/SetupFormRegionSelectField';
import SetupFormAreaSelectField from './formFields/SetupFormAreaSelectField';
import SetupFormLocationSelectField from './formFields/SetupFormLocationSelectField';
import SetupFormCompanySelectField from './formFields/SetupFormCompanySelectField';
import SetupFormCommonMultiselectField from './formFields/SetupFormCommonMultiselectField';
import SetupFormCategoryMultiselectField from './formFields/SetupFormCategoryMultiselectField';

import {
  SetupFormControlPanelWrapper,
  SetupFormFieldTitle,
  SetupFormFieldWrapper,
  SetupFormLocationTitlesWrapper,
  SetupFormTitleWrapper,
} from './styled';

import {
  ATTRACTION_TYPES,
  ATTRACTION_ENTRY_TYPES,
  ATTRACTION_STATUS,
} from '../../../consts/consts';
import { GOOGLE_MAP_KEY } from '../../../config';
import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import WrappedMap from '../../../components/map/map';
import { validateTextFieldValue } from '../../../helpers/validateTextFieldValue';
import { validateNumberValue } from '../../../helpers/validateNumberValue';
import { TYPE_AREA, TYPE_LOCATION, TYPE_REGION, MANAGER_ROLE_LIST_APP, REGIONAL_MANAGER } from '../constants';
import { ACTIVE_STATUS } from './constants';

const getAttractionsWithSameRegionIdWithoutCurrent = (
  attractions,
  currentAttraction,
  regionId
) => {
  let filteredAttractions;

    if (currentAttraction.id) {
      filteredAttractions = attractions.filter(
        (attraction) =>
          attraction.regionId === regionId &&
          attraction.id !== currentAttraction.id
      );
    } else {
        filteredAttractions = attractions.filter(
          (attraction) => attraction.regionId === regionId
        );
    }

  filteredAttractions?.sort(function (a, b) {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return filteredAttractions || [];
};

const SetupForm = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { handleSubmit } = useFormContext();
  const location = useLocation();

  const [showPublishAlert, setPublishAlert] = useState(false);

  const currentDestinationData = props.settingsState.destinationData.find(
      (dest) => dest.id === props.appState.selectedDestination.id
  );
  const isAreaEnabled = currentDestinationData?.enabledLocationTypes.includes(TYPE_AREA);
  const isRegionEnabled = currentDestinationData?.enabledLocationTypes.includes(TYPE_REGION);
  const isLocationEnabled = currentDestinationData?.enabledLocationTypes.includes(TYPE_LOCATION);

  const currentCompany = props.companyState.companiesData
        .find(company => company.id === parseInt(props.attractionsState.currentAttraction.companyId));  
    
  const isNewAttraction = location.pathname.includes('new');

  const onDeleteAttraction = () => {
    props.setShowAlert(true);
  };

  const handleClose = () => {
    props.setShowAlert(false);
  };

  const handlePublishButtonClick = () => {
    setPublishAlert(true);
  };

  const handlePublishDialogClose = () => {
    setPublishAlert(false);
  };

  const handlePublishDialogSubmit = () => {
    setPublishAlert(false);
    props.onAttractionPublication();
  };

  const onEditAttraction = () => {
    props.setIsEdit(true);
  };

  const attractionStatus = props.attractionsState.currentAttraction.status;
  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST_APP.includes(currentUser.role);
  const isRegionalManagerRoleSelected = currentUser.role === REGIONAL_MANAGER;
  const [selectedCompany, setSelectedCompany] = useState(!!currentCompany ? {id: currentCompany.id, label: currentCompany.name} : null)

  return (
    <form>
      <SetupFormControlPanelWrapper>
        <SetupFormTitleWrapper>
          {!id
            ? `${t('attractions.new_attraction')}`
            : props.attractionsState.currentAttraction.name}
        </SetupFormTitleWrapper>
        <Box display="flex" gap="10px">
          {(id && !isManagerRoleSelected && !isRegionalManagerRoleSelected) && (
            <>
              <Button
                variant="outlined"
                onClick={props.handleCancelChanges}
                type="button"
                disabled={!props.isEdit}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="outlined"
                onClick={onEditAttraction}
                type="button"
                disabled={props.isEdit || props.isSave}
              >
                {t('attractions.setup.edit_button')}
              </Button>
              {!props.hasAttractionManagerRole && (
                <Button
                  variant="outlined"
                  onClick={onDeleteAttraction}
                  type="button"
                  disabled={props.isSave}
                >
                  {t('attractions.setup.delete_button')}
                </Button>
              )}
            </>
          )}
          {(!isManagerRoleSelected && !isRegionalManagerRoleSelected) && (
            <LoadingButton
              loading={props.isSave}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              sx={{ color: '#fff' }}
              disabled={!isNewAttraction && !props.isEdit}
              onClick={handleSubmit(props.onSubmit)}
            >
              {t('attractions.setup.save_button')}
            </LoadingButton>
          )}

          {(isRegionalManagerRoleSelected && isNewAttraction) && (
            <LoadingButton
              loading={props.isSave}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              sx={{ color: '#fff' }}
              disabled={!isNewAttraction && !props.isEdit}
              onClick={handleSubmit(props.onSubmit)}
            >
              {t('attractions.setup.save_button')}
            </LoadingButton>
          )}

          {(isManagerRoleSelected && (attractionStatus === "Inactive" || isNewAttraction)) && (
            <Button
              sx={{ color: '#ffffff' }}
              variant="contained"
              onClick={handlePublishButtonClick}
            >
              {t('attractions.setup.publish_button')}
            </Button>
          )}
          {(isRegionalManagerRoleSelected && attractionStatus === "Inactive") && (
            <>
            <Button
              variant="outlined"
              onClick={props.handleCancelChanges}
              type="button"
              disabled={!props.isEdit}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="outlined"
              onClick={onEditAttraction}
              type="button"
              disabled={props.isEdit || props.isSave}
            >
              {t('attractions.setup.edit_button')}
            </Button>
            {!props.hasAttractionManagerRole && (
              <Button
                variant="outlined"
                onClick={onDeleteAttraction}
                type="button"
                disabled={props.isSave}
              >
                {t('attractions.setup.delete_button')}
              </Button>
            )}
            <LoadingButton
              loading={props.isSave}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              sx={{ color: '#fff' }}
              disabled={!isNewAttraction && !props.isEdit}
              onClick={handleSubmit(props.onSubmit)}
            >
              {t('attractions.setup.save_button')}
            </LoadingButton>
            <Button
              sx={{ color: '#ffffff' }}
              variant="contained"
              onClick={handlePublishButtonClick}
              disabled={props.isEdit}
            >
              {t('attractions.setup.publish_button')}
            </Button>
          </>
          )}

          {((isManagerRoleSelected || isRegionalManagerRoleSelected) && !isNewAttraction && attractionStatus === "Active") && (
            <SetupFormFieldTitle fontWeight="bold" style={{maxWidth: '500px'}}>
              {t('attractions.setup.publish_status_active')}
            </SetupFormFieldTitle>
          )}

          {((isManagerRoleSelected || isRegionalManagerRoleSelected) && !isNewAttraction && attractionStatus === "Pending") && (
            <SetupFormTitleWrapper>
              <SetupFormFieldTitle fontWeight="bold" style={{maxWidth: '500px'}}>
                {t('attractions.setup.publish_status_pending_title')}
              </SetupFormFieldTitle>
              <SetupFormFieldTitle fontWeight="bold" style={{maxWidth: '500px'}}>
                {t('attractions.setup.publish_status_pending_subtitle')}
              </SetupFormFieldTitle>
            </SetupFormTitleWrapper>
          )}
        </Box>
      </SetupFormControlPanelWrapper>

      {/* Main section */}
      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.name')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="name"
          placeholder={t('attractions.setup.name')}
          validator={validateTextFieldValue}
          required
          disabled={isNewAttraction ? false : !props.isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.type')}:
        </SetupFormFieldTitle>
        <SetupFormSelectField
          name="type"
          disabled={isNewAttraction ? false : !props.isEdit}
          optionsData={props.predefinedState.predefinedData}
          optionsName={ATTRACTION_TYPES}
          required={props.statusValue === ACTIVE_STATUS}
          selectedLanguage={props.appState.selectedLanguage}
          emptyOption={{
            id: 'type',
            value: '',
            label: t('attractions.setup.type'),
          }}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.entry_type')}:
        </SetupFormFieldTitle>
        <SetupFormSelectField
          name="entryType"
          disabled={isNewAttraction ? false : !props.isEdit}
          optionsData={props.predefinedState.predefinedData}
          optionsName={ATTRACTION_ENTRY_TYPES}
          required={props.statusValue === ACTIVE_STATUS}
          selectedLanguage={props.appState.selectedLanguage}
          emptyOption={{
            id: 'entryType',
            value: '',
            label: t('attractions.setup.entry_type'),
          }}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.status')}:
        </SetupFormFieldTitle>
        <SetupFormSelectField
          name="status"
          isNewAttraction = {isNewAttraction}
          disabled={isNewAttraction ? false : !props.isEdit}
          optionsData={props.predefinedState.predefinedData.filter(
            (item) => item.name.en === ATTRACTION_STATUS
          )}
          optionsName={ATTRACTION_STATUS}
          selectedLanguage={props.appState.selectedLanguage}
          status={props.statusValue}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.company')}:
        </SetupFormFieldTitle>
        <SetupFormCompanySelectField
          name="companyId"
          disabled={isNewAttraction ? false : !props.isEdit}
          optionsData={props.companyState.companiesData}
          selectedOption={selectedCompany}
          handleSetOption={setSelectedCompany}
        />
          
      </SetupFormFieldWrapper>

      {/* Location section */}
      <Typography fontWeight="500px" fontSize="20px" margin="20px 0 20px">
        {t('attractions.setup.location_title')}
      </Typography>

      <Box display="flex" gap="10px">
        <SetupFormLocationTitlesWrapper>
          <SetupFormFieldTitle>
            {t('attractions.setup.destination')}:
          </SetupFormFieldTitle>
          {isRegionEnabled && (
            <SetupFormFieldTitle lineHeight="40px" mt="20px">
              {t('attractions.setup.region')}:
            </SetupFormFieldTitle>
          )}

          {isAreaEnabled && (
            <SetupFormFieldTitle lineHeight="40px" mt="20px">
              {t('attractions.setup.area')}:
            </SetupFormFieldTitle>
          )}

          {isLocationEnabled && (
            <SetupFormFieldTitle lineHeight="40px" mt="20px">
              {t('attractions.setup.location')}:
            </SetupFormFieldTitle>
          )}
        </SetupFormLocationTitlesWrapper>

        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          <Typography overflow="hidden" textOverflow="ellipsis">
            {props.appState.selectedDestination.name}
          </Typography>

          {isRegionEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormRegionSelectField
                name="regionId"
                disabled={isNewAttraction ? false : !props.isEdit}
                optionsData={currentDestinationData}
                selectedLanguage={props.appState.selectedLanguage}
                selectedDestinationId={props.appState.selectedDestination.id}
                required={props.statusValue === ACTIVE_STATUS}
                emptyOption={{
                  id: 'regionId',
                  value: '',
                  label: t('attractions.setup.none'),
                }}
              />
            </SetupFormFieldWrapper>
          )}

          {isAreaEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormAreaSelectField
                name="areaId"
                disabled={isNewAttraction ? false : !props.isEdit}
                optionsData={currentDestinationData}
                selectedLanguage={props.appState.selectedLanguage}
                regionId={props.regionValue}
                required={props.statusValue === ACTIVE_STATUS}
                emptyOption={{
                  id: 'areaId',
                  value: '',
                  label: t('attractions.setup.none'),
                }}
              />
            </SetupFormFieldWrapper>
          )}

          {isLocationEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormLocationSelectField
                name="locationId"
                disabled={isNewAttraction ? false : !props.isEdit}
                optionsData={currentDestinationData}
                selectedLanguage={props.appState.selectedLanguage}
                regionId={props.regionValue}
                areaId={props.areaValue}
                emptyOption={{
                  id: 'locationId',
                  value: '',
                  label: t('attractions.setup.none'),
                }}
              />
            </SetupFormFieldWrapper>
          )}
        </Box>

        <Box display="flex" flexDirection="column">
          <Typography>{t('attractions.setup.order')}:</Typography>
          {isRegionEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormTextField
                name="regionOrder"
                placeholder={t('attractions.setup.no_order')}
                disabled={isNewAttraction ? false : !props.isEdit}
                validator={validateNumberValue}
              />
            </SetupFormFieldWrapper>
          )}

          {isAreaEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormTextField
                name="areaOrder"
                placeholder={t('attractions.setup.no_order')}
                disabled={isNewAttraction ? false : !props.isEdit}
                validator={validateNumberValue}
              />
            </SetupFormFieldWrapper>
          )}

          {isLocationEnabled && (
            <SetupFormFieldWrapper>
              <SetupFormTextField
                name="locationOrder"
                placeholder={t('attractions.setup.no_order')}
                disabled={isNewAttraction ? false : !props.isEdit}
                validator={validateNumberValue}
              />
            </SetupFormFieldWrapper>
          )}
        </Box>
      </Box>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.most_popular_destination')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="mostPopular"
          placeholder={t('attractions.setup.order')}
          disabled={isNewAttraction ? false : !props.isEdit}
          validator={validateNumberValue}
        />
      </SetupFormFieldWrapper>

      {/* Category section */}
      <Typography fontWeight="500px" fontSize="20px" margin="20px 0 20px">
        {t('attractions.setup.category')}
      </Typography>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.categories')}:
        </SetupFormFieldTitle>
        <SetupFormCategoryMultiselectField
          name="categories"
          optionsData={props.categoryState.categoryData}
          selectedDestinationId={props.appState.selectedDestination.id}
          selectedLanguage={props.appState.selectedLanguage}
          required={props.statusValue === ACTIVE_STATUS}
          label={t('attractions.setup.categories')}
          disabled={isNewAttraction ? false : !props.isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.facilities')}:
        </SetupFormFieldTitle>
        <SetupFormCommonMultiselectField
          name="facilities"
          optionsData={props.facilityState.facilitiesData}
          selectedLanguage={props.appState.selectedLanguage}
          label={t('attractions.setup.facilities')}
          disabled={isNewAttraction ? false : !props.isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.tags')}:
        </SetupFormFieldTitle>
        <SetupFormCommonMultiselectField
          name="tags"
          optionsData={props.tagsState.tagsData}
          selectedLanguage={props.appState.selectedLanguage}
          label={t('attractions.setup.tags')}
          disabled={isNewAttraction ? false : !props.isEdit}
        />
      </SetupFormFieldWrapper>

      {/* Nearby locations section */}
      <Typography fontWeight="500px" fontSize="20px" margin="20px 0 20px">
        {t('attractions.setup.assigned')}
      </Typography>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.nearby')}:
        </SetupFormFieldTitle>
        <SetupFormCommonMultiselectField
          name="nearby"
          optionsData={getAttractionsWithSameRegionIdWithoutCurrent(
            props.attractionsState.attractionsListData,
            props.attractionsState.currentAttraction,
            props.regionValue
          )}
          selectedLanguage={props.appState.selectedLanguage}
          label={t('attractions.setup.nearby')}
          hasAttractionsList
          disabled={isNewAttraction ? false : !props.isEdit}
          enableSearch={true}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.similar')}:
        </SetupFormFieldTitle>
        <SetupFormCommonMultiselectField
          name="similar"
          optionsData={getAttractionsWithSameRegionIdWithoutCurrent(
            props.attractionsState.attractionsListData,
            props.attractionsState.currentAttraction,
            props.regionValue
          )}
          selectedLanguage={props.appState.selectedLanguage}
          label={t('attractions.setup.similar')}
          hasAttractionsList
          disabled={isNewAttraction ? false : !props.isEdit}
          enableSearch={true}
        />
      </SetupFormFieldWrapper>

      {/* Contact section */}
      <Typography fontWeight="500px" fontSize="20px" margin="20px 0 20px">
        {t('attractions.setup.contact')}
      </Typography>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.address')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="address"
          disabled={isNewAttraction ? false : !props.isEdit}
          placeholder={t('attractions.setup.no_address')}
          validator={validateTextFieldValue}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.phone')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="phone"
          placeholder={t('attractions.setup.no_phone')}
          disabled={isNewAttraction ? false : !props.isEdit}
          validator={validateTextFieldValue}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.website')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="website"
          placeholder={t('attractions.setup.no_website')}
          validator={validateTextFieldValue}
          disabled={isNewAttraction ? false : !props.isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.email')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="email"
          placeholder={t('attractions.setup.no_email')}
          validator={validateTextFieldValue}
          isEmail
          disabled={isNewAttraction ? false : !props.isEdit}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.map_latitude')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="mapLatitude"
          placeholder={t('attractions.setup.no_coordinates')}
          disabled={isNewAttraction ? false : !props.isEdit}
          validator={validateTextFieldValue}
          customOnChange={props.handleChangeMapLatitude}
        />
      </SetupFormFieldWrapper>

      <SetupFormFieldWrapper>
        <SetupFormFieldTitle>
          {t('attractions.setup.map_longitude')}:
        </SetupFormFieldTitle>
        <SetupFormTextField
          name="mapLongtitude"
          placeholder={t('attractions.setup.no_coordinates')}
          disabled={isNewAttraction ? false : !props.isEdit}
          validator={validateTextFieldValue}
          customOnChange={props.handleChangeMapLongtitude}
        />
      </SetupFormFieldWrapper>

      <Box mt="60px">
        <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAP_KEY}`}
          loadingElement={<div style={{ height: `250px` }} />}
          containerElement={<div style={{ height: `420px` }} />}
          mapElement={<div style={{ height: `350px` }} />}
          handleUpdateCoordinates={props.handleUpdateCoordinates}
          coordinates={props.coordinates}
        />
      </Box>
      <SetupFormFieldTitle style={{maxWidth: '700px'}}>
        {t('attractions.setup.help_text')}
      </SetupFormFieldTitle>
      <ConfirmationDialog
        showAlert={props.showAlert}
        handleClose={handleClose}
        handleCloseSuccess={props.handleDeleteAttraction}
        text={t('attractions.setup.delete_attraction')}
      />
      <ConfirmationDialog
        showAlert={showPublishAlert}
        handleClose={handlePublishDialogClose}
        handleCloseSuccess={handlePublishDialogSubmit}
        text={t('attractions.setup.dialog_confirmation_publishing')}
      />
    </form>
  );
};

export default SetupForm;
