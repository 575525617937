import React, { useCallback } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';

import {
  TimePickerInput,
  SelectClosed,
  DatePickerInput,
  OpeningHoursTimePickerInput,
} from './FormComponents';

import {
  CLOSED_ON_WEEK_DAYS_OPTIONS,
  TYPE_ALL_WEEK,
  TYPE_OPENING_HOURS,
  TYPE_OPTION_LIST,
  TYPE_STARTING_TIMES,
} from './constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4.5px',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  '& .MuiTextField-root': {
    margin: 0,
  },
};
const styleWeeksModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4.5px',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  '& .MuiTextField-root': {
    margin: 0,
  },
};

const HoursModal = (props) => {
  const {
    selectValue,
    isHoursModalOpen,
    handleCloseHoursModal,
    handleSubmit,
    openModal,
    handleCloseModal,
    currentDay,
  } = props;

  const { t } = useTranslation();

  const required = useCallback(values => !values ? t('attractions.season.error_required') : undefined, []);

  const typeValue = TYPE_OPTION_LIST.find(
    (option) => option.id === selectValue
  );

  return (
    <div>
      {selectValue === TYPE_OPENING_HOURS && (
        <Modal open={isHoursModalOpen} onClose={handleCloseHoursModal}>
          <Box sx={style} component="form" onSubmit={handleSubmit}>
            <Typography variant="h6" component="h6" textTransform="capitalize">
              {t(`attractions.season.${currentDay}`) || ''}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '20px 0 25px',
              }}
            >
              <Box sx={{ margin: '20px 5px 15px 0', width: '100%' }}>
                <Field
                  label={t('attractions.season.from')}
                  name="validFrom"
                  component={OpeningHoursTimePickerInput}
                  validate={[required]}
                />
              </Box>
              <Box sx={{ margin: '20px 0 15px 5px', width: '100%' }}>
                <Field
                  label={t('attractions.season.to')}
                  name="validTo"
                  component={OpeningHoursTimePickerInput}
                  disabled={selectValue === 'OpeningHours' ? false : true}
                  validate={[required]}
                />
              </Box>
            </Box>
            <Button type="submit" variant="contained" sx={{ color: '#fff' }}>
              {t('attractions.season.setup_time')}
            </Button>
            <Box
              sx={{
                position: 'absolute',
                right: '3px',
                top: '3px',
                cursor: 'pointer',
              }}
              onClick={handleCloseHoursModal}
            >
              <Close />
            </Box>
          </Box>
        </Modal>
      )}
      {selectValue === TYPE_STARTING_TIMES && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={style} component="form" onSubmit={handleSubmit}>
            <Typography variant="h6" component="h6">
              {t(`attractions.season.${currentDay}`) || ''}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '20px 0 25px',
              }}
            >
              <Box sx={{ margin: '20px 5px 15px 0', width: '100%' }}>
                <Field
                  label={t('attractions.season.from')}
                  name="validFrom"
                  component={TimePickerInput}
                  validate={[required]}
                />
              </Box>
            </Box>
            <Button type="submit" variant="contained" sx={{ color: '#fff' }}>
              {t('attractions.season.setup_time')}
            </Button>
            <Box
              sx={{
                position: 'absolute',
                right: '3px',
                top: '3px',
                cursor: 'pointer',
              }}
              onClick={handleCloseModal}
            >
              <Close />
            </Box>
          </Box>
        </Modal>
      )}
      {currentDay === 'Special Dates' && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={styleWeeksModal} component="form" onSubmit={handleSubmit}>
            <Typography variant="h6" component="h6">
              {t(`attractions.season.${currentDay}`) || ''}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{ margin: '20px 0 15px', width: '100%' }}>
                <Field
                  label={t('attractions.season.from')}
                  name="validFrom"
                  required
                  component={TimePickerInput}
                  validate={[required]}
                />
              </Box>
              <Box sx={{ marginBottom: '15px', width: '100%' }}>
                <Field
                  label={t('attractions.season.to')}
                  name="validTo"
                  required
                  component={TimePickerInput}
                  validate={[required]}
                />
              </Box>
              <Box sx={{ marginBottom: '10px', width: '100%' }}>
                <Field
                  name="date"
                  required
                  component={DatePickerInput}
                  periodViews={['day', 'month', 'year']}
                  formatViews="dd/MM/yyyy"
                  validate={[required]}
                />
              </Box>
            </Box>
            <Button
              type="submit"
              variant="contained"
              sx={{ color: '#fff', marginTop: '10px' }}
            >
              {t('attractions.season.setup_time')}
            </Button>
            <Box
              sx={{
                position: 'absolute',
                right: '3px',
                top: '3px',
                cursor: 'pointer',
              }}
              onClick={handleCloseModal}
            >
              <Close />
            </Box>
          </Box>
        </Modal>
      )}

      {selectValue === TYPE_ALL_WEEK && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={styleWeeksModal} component="form" onSubmit={handleSubmit}>
            <Typography variant="h6" component="h6">
              {t(typeValue.name)}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{ margin: '20px 0 15px', width: '100%' }}>
                <Field
                  label={t('attractions.season.from')}
                  name="validFrom"
                  required
                  component={TimePickerInput}
                />
              </Box>
              <Box sx={{ marginBottom: '15px', width: '100%' }}>
                <Field
                  label={t('attractions.season.to')}
                  name="validTo"
                  required
                  component={TimePickerInput}
                />
              </Box>
              <Box sx={{ marginBottom: '10px', width: '100%' }}>
                <Box sx={{ marginBottom: '5px', width: '100%' }}>
                  {t('attractions.season.closed_on')}
                </Box>
                <Field
                  name="closedOn"
                  component={SelectClosed}
                  data={CLOSED_ON_WEEK_DAYS_OPTIONS}
                />
              </Box>
            </Box>
            <Button
              type="submit"
              variant="contained"
              sx={{ color: '#fff', marginTop: '10px' }}
            >
              {t('attractions.season.setup_time')}
            </Button>
            <Box
              sx={{
                position: 'absolute',
                right: '3px',
                top: '3px',
                cursor: 'pointer',
              }}
              onClick={handleCloseModal}
            >
              <Close />
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default reduxForm({
  form: 'modal',
  enableReinitialize: true,
})(HoursModal);
