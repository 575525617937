import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm, Field, FormSection } from 'redux-form';

import { makeStyles } from '@mui/styles';
import { Button, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import LanguageTabs from './LanguageTabs';

import {
  FacilityFormHeader,
  FacilityFormControlPanel,
  FacilityFormImageDropWrapper,
} from './styled';
import cl from './FacilitiesForm.module.css';

import { ALLOWED_FILE_TYPES } from './constants';
import { FacilityFormImageDrop } from './formFields';

const useStyles = makeStyles({
  form: {
    width: '100%',
    '& .MuiTextField-root': {
      width: '600px',
      marginBottom: '0px',
    },
    '& .MuiButton-root': {
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px;',
    },
  },
  tabs: {
    minWidth: '320px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    marginBottom: '16px',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
  },
  tabPanel: {
    width: '100%',
    fontSize: '0.875rem',
    '& .MuiBox-root': {
      padding: '15px 0px',
    },
  },
  input: {
    '&.MuiButton-root': {
      position: 'absolute',
      content: '',
      bottom: '10px',
    },
  },
});

let FacilitiesForm = (props) => {
  const {
    setIsEdit,
    setIcon,
    facilitiesState,
    setShowAlert,
    handleSubmit,
    updateFacility,
    selectedLanguage,
    addFacility,
    isEdit,
    languages,
    error,
    isSave,
    showAlert,
    handleCloseSuccess,
    onCancel,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const onEditFacilities = () => {
    setIsEdit(true);
    setIcon(facilitiesState.currentFacility.iconUrl);
  };

  const onDeleteFacilities = () => {
    setShowAlert(true);
  };

  const handleClose = () => {
    setIsEdit(false);
    setShowAlert(false);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <FacilityFormHeader>
        <div className={cl.title}>
          {updateFacility
            ? facilitiesState.currentFacility.name[selectedLanguage] ||
              facilitiesState.currentFacility.name.en
            : `${t('settings_page.facility.new_facility')}`}
        </div>
        <FacilityFormControlPanel>
          <Button
            variant="outlined"
            onClick={onCancel}
            type="button"
            disabled={addFacility ? false : !isEdit}
          >
            {t('cancel')}
          </Button>
          {updateFacility && (
            <>
              <Button
                variant="outlined"
                onClick={onEditFacilities}
                type="button"
                disabled={isEdit}
              >
                {t('settings_page.facility.edit_button')}
              </Button>
              <Button
                variant="outlined"
                onClick={onDeleteFacilities}
                type="button"
                disabled={isSave}
              >
                {t('settings_page.facility.delete_button')}
              </Button>
            </>
          )}
          <LoadingButton
            loading={isSave}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{ color: '#fff' }}
            disabled={addFacility || isEdit ? false : true}
            type="submit"
          >
            {t('settings_page.facility.save_button')}
          </LoadingButton>
        </FacilityFormControlPanel>
      </FacilityFormHeader>
      <div className={cl.wrapper}>
        <FormSection name="name">
          <LanguageTabs
            addElement={addFacility}
            updateElement={updateFacility}
            isEdit={isEdit}
            currentItem={facilitiesState.currentFacility}
            text={t('settings_page.facility.new_facility')}
            languages={languages}
            error={error}
          />
        </FormSection>
      </div>
      <div className={cl.label}>{t('settings_page.facility.icon')}</div>
      <div className={cl.helperText}>
        {t('settings_page.facility.icon_text')}
      </div>
      <Box component="div" style={{ marginBottom: '10px' }}>
        <FacilityFormImageDropWrapper>
          <Field
            name="iconList"
            component={FacilityFormImageDrop}
            allowedFileTypes={ALLOWED_FILE_TYPES}
            filesLimit={1}
            columns={1}
            disabled={addFacility ? false : !isEdit}
          />
        </FacilityFormImageDropWrapper>
      </Box>
      <ConfirmationDialog
        showAlert={showAlert}
        handleClose={handleClose}
        handleCloseSuccess={handleCloseSuccess}
        text={t('settings_page.facility.delete_facility')}
      />
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.addFacility) {
    return {
      initialValues: {
        name: '',
        iconList: [],
      },
    };
  }

  if (ownProps.isEdit && ownProps.facilitiesState.currentFacility) {
    return {
      initialValues: {
        name: ownProps.facilitiesState.currentFacility.name,
        iconList: ownProps.facilitiesState.currentFacility.iconUrl
          ? [ownProps.facilitiesState.currentFacility.iconUrl]
          : [],
      },
    };
  } else {
    return {
      initialValues: {
        name: state.facilitiesReducer.currentFacility.name,
        iconList: state.facilitiesReducer.currentFacility.iconUrl
          ? [state.facilitiesReducer.currentFacility.iconUrl]
          : [],
      },
    };
  }
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'Facility', enableReinitialize: true })(FacilitiesForm)
);
