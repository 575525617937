import {
  SET_COMPANY_LIST,
  IS_COMPANY_LIST_LOADING,
  SET_COMPANY_SETTINGS,
  SET_ATTRACTION_LIST_OF_COMPANY,
  IS_ATTRACTION_LIST_OF_COMPANY_LOADED,
  SET_CURRENT_COMPANY,
  SET_IS_CURRENT_COMPANY_LOADING,
  SET_ALL_COMPANY_SHORT_DATA_LIST,
} from './companyActions';

const initialState = {
  currentCompany: null,
  isCurrentCompanyLoading: false,
  companiesData: [],
  isLoading: false,
  attractionListOfCompanies: [],
  isAttractionListOfCompaniesLoaded: false,
  defaultCompany: {},
  isSettingsLoaded: false,
  allCompaniesShortDataList: [],
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_LIST:
      return {
        ...state,
        companiesData: [...action.payload.data.companies],
        defaultCompany: { ...action.payload.data.defaultCompany },
      };
    case SET_ATTRACTION_LIST_OF_COMPANY:
      return {
        ...state,
        attractionListOfCompanies: [...action.payload.data],
      };
    case IS_COMPANY_LIST_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case IS_ATTRACTION_LIST_OF_COMPANY_LOADED:
      return {
        ...state,
        isAttractionListOfCompaniesLoaded: action.payload.isLoaded,
      };
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: { ...action.payload.data },
      };
    case SET_IS_CURRENT_COMPANY_LOADING:
      return {
        ...state,
        isCurrentCompanyLoading: action.payload.data,
      };
    case SET_ALL_COMPANY_SHORT_DATA_LIST:
      return {
        ...state,
        allCompaniesShortDataList: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
