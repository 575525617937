import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import {
  VisitorTextField,
  VisitorEmailField,
  VisitorSelectField,
  VisitorDatePickerField,
} from './formFields';

import {
  VisitorFormControlPanel,
  VisitorFormFieldWrapper,
  VisitorFormFieldTitle,
  VisitorFormContainer,
  VisitorFormHeader,
  VisitorFormHeaderLabel,
} from './styled';

import {
  FormButton,
  SaveButton,
} from '../../../../components/buttons/FormButtons';

import { NEW_VISITOR_STATUS, DEFAULT_VISITOR_LANGUAGE } from '../constants';

const renderProductOptions = (labels, selectedLanguage) => {
  if (!labels) return null;
  const reducedLabels = labels.parameters.reduce((acc, current) => {
    acc[current.id] = current.value;
    return acc;
  }, {});

  return (options) => {
    if (!options || !options.length) return null;
    return options.map((option, index) => {
      return (
        <option value={option.id} key={index}>
          {reducedLabels[option.type][selectedLanguage]}
        </option>
      );
    });
  };
};

const renderLanguageOptions = (options) => {
  if (!options || !options.length) return null;

  return options.map((option, index) => {
    return (
      <option value={option.id} key={index}>
        {option.value}
      </option>
    );
  });
};

const VisitorAddForm = (props) => {
  const { t } = useTranslation();
  const {
    onFormSubmit,
    productList,
    onFormCancel,
    showSaveAnimation,
    selectedLanguage,
    productTypes,
    languageList,
  } = props;

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      address: '',
      language: DEFAULT_VISITOR_LANGUAGE,
      productId: productList[0].id,
      validFrom: new Date(),
    },
  });

  const { handleSubmit } = formMethods;

  const onSubmit = (data) => {
    const newVisitor = {
      status: NEW_VISITOR_STATUS,
      name: data.name.trim(),
      email: data.email.trim(),
      address: data.address,
      language: data.language,
      productId: parseInt(data.productId),
      validFrom: data.validFrom,
    };
    onFormSubmit && onFormSubmit(newVisitor);
  };

  return (
    <VisitorFormContainer>
      <VisitorFormHeader>
        <VisitorFormHeaderLabel>
          <Typography fontWeight="bold" textTransform="uppercase">
            {t('visitors.new_visitor_label')}
          </Typography>
        </VisitorFormHeaderLabel>
        <VisitorFormControlPanel>
          <Box display="flex" gap="10px">
            <FormButton
              label={t('visitors.cancelFormButton')}
              onClick={onFormCancel}
            />
            <SaveButton
              label={t('visitors.saveFormButton')}
              onClick={handleSubmit(onSubmit)}
              isLoading={showSaveAnimation}
            />
          </Box>
        </VisitorFormControlPanel>
      </VisitorFormHeader>

      <FormProvider {...formMethods}>
        <form aria-label="form">
          <VisitorFormFieldWrapper>
            <VisitorFormFieldTitle>
              {t('visitors.formFieldLabels.name')}
            </VisitorFormFieldTitle>
            <VisitorTextField name="name" required />
          </VisitorFormFieldWrapper>
          <VisitorFormFieldWrapper>
            <VisitorFormFieldTitle>
              {t('visitors.formFieldLabels.email')}
            </VisitorFormFieldTitle>
            <VisitorEmailField name="email" required />
          </VisitorFormFieldWrapper>
          <VisitorFormFieldWrapper>
            <VisitorFormFieldTitle>
              {t('visitors.formFieldLabels.address')}
            </VisitorFormFieldTitle>
            <VisitorTextField name="address" />
          </VisitorFormFieldWrapper>
          <VisitorFormFieldWrapper>
            <VisitorFormFieldTitle>
              {t('visitors.formFieldLabels.language')}
            </VisitorFormFieldTitle>
            <VisitorSelectField
              name="language"
              options={languageList.parameters}
              renderOptions={renderLanguageOptions}
              required
            />
          </VisitorFormFieldWrapper>
          <VisitorFormFieldWrapper>
            <VisitorFormFieldTitle>
              {t('visitors.formFieldLabels.product')}
            </VisitorFormFieldTitle>
            <VisitorSelectField
              name="productId"
              options={productList}
              renderOptions={renderProductOptions(
                productTypes,
                selectedLanguage
              )}
              required
            />
          </VisitorFormFieldWrapper>
          <VisitorFormFieldWrapper>
            <VisitorFormFieldTitle>
              {t('visitors.formFieldLabels.validFrom')}
            </VisitorFormFieldTitle>
            <VisitorDatePickerField name="validFrom" required />
          </VisitorFormFieldWrapper>
        </form>
      </FormProvider>
    </VisitorFormContainer>
  );
};

export default VisitorAddForm;
