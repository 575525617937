import {
    getGlobalSettingsApi
} from '../api/settingsApi.js';
const SET_MAIN_TITLE = 'SET_MAIN_TITLE';
const ADD_GLOBAL_SETTINGS = 'ADD_GLOBAL_SETTINGS';
const SET_CHECKED_DESTINATION = 'SET_CHECKED_DESTINATION';
const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';

const initialState = {
    mainTitle: '',
    globalSettingsData: [],
    selectedDestination: {},
    selectedLanguage: localStorage.getItem('i18nextLng') || 'en',
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MAIN_TITLE:
            return {
                ...state,
                mainTitle: action.title
            };
        case ADD_GLOBAL_SETTINGS:
            return {
                ...state,
                globalSettingsData: [...action.payload.data]
            };
        case SET_CHECKED_DESTINATION:
            return {
                ...state,
                selectedDestination: action.selectedDestination,
            };
        case SET_CURRENT_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.currentLanguage,
            };
        default:
            return state;
    }
};

export let setTitle = (title) => {
    return { type: SET_MAIN_TITLE, title };
}
export let addGlobalSettings = (data) => {
    return { type: ADD_GLOBAL_SETTINGS, payload: { data } };
}
export let setCheckedDestination = (selectedDestination) => {
    return { type: SET_CHECKED_DESTINATION, selectedDestination };
}
export let setCurrentLanguage = (currentLanguage) => {
    return { type: SET_CURRENT_LANGUAGE, currentLanguage };
}

export let getGlobalSettingsThunk = () => async (dispatch) => {
    let response = await getGlobalSettingsApi();
    if (response.status >= 200 && response.status < 300) {
        dispatch(addGlobalSettings(response.data));
    }
}

export default appReducer;