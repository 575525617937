import { connect } from 'react-redux';
import { compose } from 'redux';

import VisitorsList from './VisitorsList';

import { getProductSettingsThunk } from '../../../redux/productsReducer/productActions';

const mapStateToProps = (state) => {
  return {
    visitors: state.visitorsReducer.visitors,
    paginationSettings: state.visitorsReducer.paginationSettings,
    isDataLoading: state.visitorsReducer.isDataLoading,
    productSettings: state.productsReducer.productSettings,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

const VisitorsListContainer = compose(
  connect(mapStateToProps, {
    getProductSettingsThunk,
  })
)(VisitorsList);

export default VisitorsListContainer;
