import React from 'react';
import {
  TextField,
  FormControl,
  FormHelperText,
  Select,
  Box,
} from '@mui/material';

export const HelperText = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return (
      <FormHelperText sx={{ color: '#d43131', marginLeft: '0px' }}>
        * {touched && error}
      </FormHelperText>
    );
  }
};

export const SelectInput = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...props
}) => {
  return (
    <FormControl error={touched && error} fullWidth>
      <Select
        displayEmpty
        native
        value={input.value}
        onChange={input.onChange}
        size="small"
        disabled={props.disabled}
        sx={{
          opacity: input.value !== '' ? 1 : 0.6,
          '&.Mui-disabled': {
            textFillColor: 'rgba(0, 0, 0, 0.6)',
            opacity: 0.6,
          },
        }}
      >
        {children}
      </Select>
      {HelperText({ touched, error })}
    </FormControl>
  );
};

export const Input = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...props
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <TextField
        label={label}
        value={input.value}
        onChange={input.onChange}
        error={touched && error}
        {...input}
        {...props}
        size="small"
      />
      {HelperText({ touched, error })}
    </Box>
  );
};
