import { Field } from 'redux-form';

import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { makeStyles } from '@mui/styles';

import { ContentFormIconButton, ContentFormTextField, FieldWrapper } from '../../../attractions/content/styled';
import { maxLength250, maxLengthPhone, required, email, noSpaces } from '../forms/validation';
import { ManagedAttractionsFormCellWrapper } from '../forms/styled';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '0',
  },
});

const FieldList = (props) => {
  const {
    fields,
    meta: { error },
    disabled,
    prefix
  } = props;

  const classes = useStyles();

  return (
    <>
      {fields.map((item, index) => (
        <FieldWrapper key={index} className={classes.wrapper}>
          <ManagedAttractionsFormCellWrapper>
            <Field
              name={`${prefix}.contacts[${index}].name`}
              component={ContentFormTextField}
              type="text"
              placeholder="Name"
              disabled={disabled}
              validate={[required, maxLength250, noSpaces]}
            />
          </ManagedAttractionsFormCellWrapper>
          <ManagedAttractionsFormCellWrapper>
            <Field
              name={`${prefix}.contacts[${index}].phone`}
              component={ContentFormTextField}
              type="text"
              placeholder="Phone"
              disabled={disabled}
              validate={[required, maxLengthPhone, noSpaces]}
            />
          </ManagedAttractionsFormCellWrapper>
          <ManagedAttractionsFormCellWrapper>
            <Field
              name={`${prefix}.contacts[${index}].email`}
              component={ContentFormTextField}
              type="text"
              placeholder="Email"
              disabled={disabled}
              validate={[required, email, maxLength250, noSpaces]}
            />
          </ManagedAttractionsFormCellWrapper>
          <ContentFormIconButton
            disabled={disabled}
            onClick={() => fields.remove(index)}
          >
            <DeleteIcon fontSize="large" />
          </ContentFormIconButton>
        </FieldWrapper>
      ))}
      <ContentFormIconButton
        disabled={disabled}
        onClick={() => fields.push({})}
      >
        <AddCircleOutlineIcon fontSize="large" />
      </ContentFormIconButton>
      {error && <span className="error">{error}</span>}
    </>
  );
};

export default FieldList;