import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import RegionForm from './RegionForm';
import { DestinationFormWrapper } from './styled';

import {
  getDestinationThunk,
  addLocationThunk,
} from '../../../../redux/settingsReducers/settingsReducer';
import { CONTENT_LANGUAGES, ACTIVITY_STATUS } from '../../../../consts/consts';
import { removeObjectField } from '../../../attractions/content/utils';
import { getImageUrl } from '../utils';
import { uploadImageFile } from '../../../../api/publishing/mainImageApi';
import {
  DESTINATION_PAGE_ROUTE,
  REGION_FORM_LOCATION_TYPE_LIST,
} from '../constants';
import { findDestinationLanguages } from '../utils';

const RegionAddFormContainer = (props) => {
  const {
    settingsState,
    globalSettings,
    getDestinationThunk,
    addLocationThunk,
    appState,
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locationType, destinationId, parentId } = useParams();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [geoCoordinate, setGeoCoordinate] = useState([
    {
      lat: 0,
      lng: 0,
    },
  ]);

  const selectedLanguages = useMemo(
    () => findDestinationLanguages(destinationId, settingsState),
    [destinationId]
  );

  const currentDestination = useMemo(
    () =>
      settingsState.destinationData.find(
        (destination) => destination.id === destinationId
      ),
    [destinationId]
  );

  const handleRegionFormSubmit = async (values) => {
    setShowSaveAnimation(true);
    const imageUrl = await getImageUrl(values.imageUrl, uploadImageFile, 'App');

    removeObjectField(values.tips, 'new-tips');
    removeObjectField(values.highlights, 'new-highlights');
    removeObjectField(values.directions, 'new-directions');

    values.subtitle = values.subtitle || { };

    const newLocationData = {
      parentId: parentId && locationType !== 'Region' ? parentId : null,
      destinationId: currentDestination.id,
      locationType: locationType,
      title: values.title,
      locationStatus: values.locationStatus
        ? values.locationStatus
        : 'Inactive',
      order: values.order || 0,
      isHideForMainPage: values.isHideForMainPage,
      subtitle: values.subtitle,
      introduction: values.introduction,
      description: values.description,
      tips: values.tips,
      highlights: values.highlights,
      directions: values.directions,
      imageUrl: imageUrl,
      mapCoordinates: geoCoordinate,
    };

    addLocationThunk(destinationId, newLocationData)
      .then(() => {
        getDestinationThunk();
        setShowSaveAnimation(false);
        addError(
          t('settings_page.destination.success_add_location_type', {
            locationType: t(`attractions.list.${locationType.toLowerCase()}`),
          }),
          'Success'
        );
        navigate(DESTINATION_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          t('settings_page.destination.error_add_location_type', {
            locationType: t(`attractions.list.${locationType.toLowerCase()}`),
          }),
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    navigate(DESTINATION_PAGE_ROUTE);
  };

  return (
    <DestinationFormWrapper>
      {currentDestination?.id &&
        REGION_FORM_LOCATION_TYPE_LIST.includes(locationType) && (
          <RegionForm
            currentItem={{}}
            onSubmit={handleRegionFormSubmit}
            addLocation
            updateLocation={false}
            isEdit={false}
            setIsEdit={null}
            geoCoordinate={geoCoordinate}
            setGeoCoordinate={setGeoCoordinate}
            languages={selectedLanguages}
            status={globalSettings.filter(
              (item) => item.name.en === CONTENT_LANGUAGES
            )}
            globalSettings={globalSettings.filter(
              (item) => item.name.en === ACTIVITY_STATUS
            )}
            selectedLanguages={appState.selectedLanguage}
            isSave={showSaveAnimation}
            setIsSave={setShowSaveAnimation}
            locationType={locationType}
            onCancel={handleFormCancel}
          />
        )}
    </DestinationFormWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    settingsState: state.settingsReducer,
    globalSettings: state.appReducer.globalSettingsData,
    appState: state.appReducer,
  };
};

export default connect(mapStateToProps, {
  getDestinationThunk,
  addLocationThunk,
})(RegionAddFormContainer);
