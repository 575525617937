import { Controller, useFormContext } from 'react-hook-form';
import {Autocomplete, TextField} from "@mui/material";
import React from "react";
import {StyledFilterOption} from "../../styled";

const SetupFormCompanySelectField = ({disabled, optionsData, selectedOption, handleSetOption, name, emptyOption}) => {
   const { control, setValue } = useFormContext();

   const options = optionsData?.map((option) => {
       return {
           id: option.id,
           label: option.name
       };
   });

   const handleOnBlur = (e) => {
       const findMatch = options.find(
           (option) => option.label === e.target.value
       );
       if (!findMatch) {
           setValue(name, null)
           handleSetOption(null);
       }
   };

  return (
      <Controller
          control={control}
          name={name}
          render={() => {
              
              return(
              <Autocomplete
                  fullWidth
                  disabled={disabled}
                  options={options}
                  value={selectedOption}
                  getOptionLabel={(option) => option.label}
                  onChange={(_, selectedOption) => { 
                      if(!!selectedOption) {
                          setValue(name, selectedOption.id.toString())
                          handleSetOption(selectedOption)
                      }
                  }}
                   filterOptions={(options, state) => {
                        const cleanedInput = state.inputValue.trim().toLowerCase();
                        return options.filter(option =>
                        option.label.toLowerCase().includes(cleanedInput)
                        );
                    }}
                  renderOption={(props, option) => (
                      <StyledFilterOption
                          component="li"
                          {...props}
                          value={option.id}
                          label={option.label}
                      >
                          {option.label}
                      </StyledFilterOption>
                  )}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          fullWidth
                          size="small"
                          onBlur={handleOnBlur}
                      />
                  )}
              />
          )}}
      />
  );
};

export default SetupFormCompanySelectField;