import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Alert, AlertTitle } from '@mui/material';

import useAPIError from './useAPIError';

import './style.css';

const APIErrorAlert = () => {
  const { error, removeError } = useAPIError();

  const SuccessStatus = 'Success';

  const handleSubmit = () => {
    removeError();
  };

  const parseErrorDescription = (description) => {
    if (!description) return null;

    if(typeof description === 'string' ) {
      return description;
    }

    const errorStringDescription = [];

    for (const [_, value] of Object.entries(error.description)) {
      errorStringDescription.push(`${value}`);
    }

    return errorStringDescription.join(',');
  };
  const errorDescription = parseErrorDescription(error?.description);

  return (
    <CSSTransition
      classNames="notifications"
      in={!!error}
      timeout={300}
      mountOnEnter
      unmountOnExit
      onEntered={
        error && error.status === SuccessStatus
          ? () => setTimeout(handleSubmit, 4000)
          : null
      }
    >
      <Alert
        severity={error && error.status === SuccessStatus ? 'success' : 'error'}
        sx={{
          position: 'fixed',
          top: '160px',
          right: '20px',
          width: '100%',
          maxWidth: '500px',
          wordBreak: 'break-word',
          fontWeight: 500,
          transition: 'none',
        }}
        onClose={handleSubmit}
      >
        <AlertTitle>{error ? error.message : null}</AlertTitle>
        {errorDescription}
      </Alert>
    </CSSTransition>
  );
};

export default APIErrorAlert;
