import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link, Outlet } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { Tab } from '@mui/material';

import { a11yProps } from '../../components/tabPanel/TabPanel';
import { getOpenedTabIdByUrlParameter, usersTabs } from './usersTabs';

import { UsersPageContainer, UsersTabsContainer, UsersTabs } from './styled';
import { getCompaniesListThunk } from "../../redux/usersReducers/company/companyActions";
import { getUserSettingsThunk } from "../../redux/usersReducers/users/userActions";
import { getProductsThunk } from "../../redux/productsReducer/productActions";
import { setTitle } from '../../redux/appReducer';

const UserPage = (props) => {
  const {
    selectedDestinationId,
    getSmallAttractionsDataThunk,
    getSalesPointShortDataListThunk,
  } = props;
  const location = useLocation();
  const [selectedTabId, setSelectedTabId] = useState(
    getOpenedTabIdByUrlParameter(usersTabs, location.pathname)
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangeTab = (newValue) => {
    setSelectedTabId(newValue);
  };

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_users')));
  }, [])

  useEffect(() => {
    getSmallAttractionsDataThunk(selectedDestinationId);
    getSalesPointShortDataListThunk(selectedDestinationId);
    dispatch(getCompaniesListThunk(selectedDestinationId));
    dispatch(getUserSettingsThunk());
    dispatch(getProductsThunk(selectedDestinationId));
  }, [
    selectedDestinationId,
    getSmallAttractionsDataThunk,
    getSalesPointShortDataListThunk,
  ]);

  useEffect(() => {
    handleChangeTab(getOpenedTabIdByUrlParameter(usersTabs, location.pathname));
  }, [location.pathname]);

  return (
    <UsersPageContainer>
      <UsersTabsContainer>
        <UsersTabs
          value={selectedTabId}
          indicatorColor="primary"
          textColor="inherit"
        >
          {usersTabs.map((tab, index) => (
            <Tab
              label={t(tab.name)}
              sx={{ width: '20%' }}
              component={Link}
              to={tab.path}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </UsersTabs>
      </UsersTabsContainer>
      <Outlet />
    </UsersPageContainer>
  );
};

export default UserPage;
