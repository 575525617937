import { Controller, useFormContext } from 'react-hook-form';

import { DealFormSelectField } from '../styled';

import { USES_NUMBER_SELECT_OPTIONS } from '../../consts';

const DealUsesLimitedField = ({ name, required }) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DealFormSelectField
            native
            value={value}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            size="small"
            disabled={required}
          >
            {USES_NUMBER_SELECT_OPTIONS.map((usesNumber, idx) => (
              <option
                id={idx}
                value={usesNumber}
              >
                {usesNumber}
              </option>
            ))}
          </DealFormSelectField>
        )}
      />
    </>
  );
};

export default DealUsesLimitedField;
