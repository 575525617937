import { connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress } from '@mui/material';

import TranslationForm from './TranslationForm';

import {
  getTranslationsListThunk,
  getTranslationsSearchThunk,
  setCurrentKey,
  getCategoryDataThunk,
  addKeyThunk,
  editKeyThunk,
  deleteKeyThunk,
} from '../../redux/translationsReducer/translationsActions';
import useAPIError from '../../apIErrorProvider/useAPIError';
import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import { sortLanguageListInDestinationSettingsOrder } from '../../helpers/sortLanguageListInDestinationSettingsOrder';
import { CONTENT_LANGUAGES } from '../../consts/consts';

const TranslationEditFormContainer = (props) => {
  const {
    appState,
    translationsState,
    setCurrentKey,
    getTranslationsListThunk,
    getCategoryDataThunk,
    editKeyThunk,
    deleteKeyThunk,
  } = props;

  const { id } = useParams();
  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState();
  const [isInEditMode, setIsInEditMode] = useState(false);

  const destinationLanguageList = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );

  const contentLanguageList = appState.globalSettingsData.find(
    (item) => item.name.en === CONTENT_LANGUAGES
  );

  const sortedLanguageList = sortLanguageListInDestinationSettingsOrder(
    contentLanguageList.parameters,
    destinationLanguageList
  );

  useEffect(() => {
    const currentTranslationKey = translationsState.translationsData.find(
      (item) => item.id === id
    );

    setCurrentKey(currentTranslationKey);
  }, [
    id,
    setCurrentKey,
    translationsState.isLoadingData,
    translationsState.translationsData,
  ]);

  useEffect(() => {
    getTranslationsListThunk(appState.selectedDestination.id);
    getCategoryDataThunk();
  }, [
    getTranslationsListThunk,
    getCategoryDataThunk,
    appState.selectedDestination.id,
  ]);

  const handleFormSubmit = (values) => {
    setShowSaveAnimation(true);

    Object.keys(values.value).forEach((item) => {
      values.value[item] = validateTextFieldValue(values.value[item]);
    });

    const data = {
      key: validateTextFieldValue(values.key),
      value: values.value,
      categoryKey: values.category,
    };

    const updatedData = {
      ...data,
      id: translationsState.currentKey.id,
    };

    editKeyThunk(translationsState.currentKey.id, updatedData)
      .then(() => {
        setIsInEditMode(false);
        setShowSaveAnimation(false);
        addError(
          `${t('settings_page.translation.error_edit_text')}`,
          'Success'
        );
        navigate(-1);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.translation.error_not_edit_text')}`,
          'Error',
          error
        );
        setTimeout(() => {
          setShowSaveAnimation(false);
        }, 2000);
      });
  };

  const handleFormCancel = () => {
    navigate(-1);
  };
  const [showAlert, setShowAlert] = useState(false);

  const handleCloseSuccess = () => {
    setIsInEditMode(false);
    setShowSaveAnimation(true);
    setShowAlert(false);
    deleteKeyThunk(translationsState.currentKey.id)
      .then(() => {
        addError(
          `${t('settings_page.translation.error_deleted_text')}`,
          'Success'
        );
        navigate(-1);
        setShowSaveAnimation(false);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.translation.error_not_deleted_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  return (
    <>
      {translationsState.currentKey?.id ? (
        <TranslationForm
          onSubmit={handleFormSubmit}
          appState={appState}
          translationsState={translationsState}
          updateKey={true}
          isEdit={isInEditMode}
          isSave={showSaveAnimation}
          onCancel={handleFormCancel}
          setIsEdit={setIsInEditMode}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleCloseSuccess={handleCloseSuccess}
          languageList={sortedLanguageList}
        />
      ) : (
        <Box
          component="div"
          sx={{ position: 'absolute', top: '50%', right: '50%' }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appVersionsReducer: state.appVersionsReducer,
    appState: state.appReducer,
    translationsState: state.translationsReducer,
  };
};

export default connect(mapStateToProps, {
  getTranslationsListThunk,
  getTranslationsSearchThunk,
  setCurrentKey,
  getCategoryDataThunk,
  addKeyThunk,
  editKeyThunk,
  deleteKeyThunk,
})(TranslationEditFormContainer);
