import { connect } from 'react-redux';
import { compose } from 'redux';

import ReviewsTab from './ReviewsTab';

import {
  getAttractionReivewsListThunk,
  addAttractionReviewThunk,
  editAttractionReviewThunk,
  deleteAttractionReviewThunk,
  getFilteredAttractionReviewsThunk,
} from '../../../redux/attractionsReducers/reviews/reviewActions';
import { getSmallAttractionsDataThunk } from '../../../redux/attractionsReducers/attractions/attractionsActions';

const mapStateToProps = (state) => {
  return {
    reviewList: state.reviewReducer.reviewData,
    currentAttraction: state.attractionsReducer.currentAttraction,
    selectedLanguage: state.appReducer.selectedLanguage,
    globalSettings: state.appReducer.globalSettingsData,
    attractionList: state.attractionsReducer.attractionsSmallData,
    selectedDestinationId: state.appReducer.selectedDestination.id,
    isLoading: state.reviewReducer.isLoading,
  };
};

const ReviewsTabContainer = compose(
  connect(mapStateToProps, {
    addAttractionReviewThunk,
    getAttractionReivewsListThunk,
    editAttractionReviewThunk,
    deleteAttractionReviewThunk,
    getFilteredAttractionReviewsThunk,
    getSmallAttractionsDataThunk,
  })
)(ReviewsTab);

export default ReviewsTabContainer;
