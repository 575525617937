import { stopSubmit } from 'redux-form';

import {
  addCompany,
  getCompanyList,
  updateCompany,
  deleteCompany,
  getAttractionListOfCompanies,
  getCompany,
  getAllCompanyShortDataList,
} from '../../../api/usersApi/companyApi';

export const SET_COMPANY_LIST = 'company/setCompanyList';
export const IS_COMPANY_LIST_LOADING = 'company/isCompanyListLoading';
export const SET_COMPANY_SETTINGS = 'company/setCompanySettings';
export const SET_ATTRACTION_LIST_OF_COMPANY =
  'company/setAttractionListOfCompany';
export const IS_ATTRACTION_LIST_OF_COMPANY_LOADED =
  'company/isAttractionListOfCompanyLoaded';
export const SET_CURRENT_COMPANY = 'company/setCurrentCompany';
export const SET_IS_CURRENT_COMPANY_LOADING =
  'company/setIsCurrentCompanyLoading';
export const SET_ALL_COMPANY_SHORT_DATA_LIST =
  'company/setAllCompaniesShortDataList';

export const setCompanyList = (data) => {
  return { type: SET_COMPANY_LIST, payload: { data } };
};

export const setAllCompaniesShortDataList = (data) => {
  return { type: SET_ALL_COMPANY_SHORT_DATA_LIST, payload: data };
};

export const setCompanySettings = (data) => {
  return { type: SET_COMPANY_SETTINGS, payload: { data } };
};

export const setIsCompanyListLoading = (isLoading) => {
  return { type: IS_COMPANY_LIST_LOADING, payload: { isLoading } };
};

export const setAttractionListOfCompany = (data) => {
  return { type: SET_ATTRACTION_LIST_OF_COMPANY, payload: { data } };
};

export const setIsAttractionListOfCompanyLoaded = (isLoaded) => {
  return { type: IS_ATTRACTION_LIST_OF_COMPANY_LOADED, payload: { isLoaded } };
};

export const setCurrentCompany = (data) => {
  return { type: SET_CURRENT_COMPANY, payload: { data } };
};

export const setIsCurrentCompanyLoading = (data) => {
  return { type: SET_IS_CURRENT_COMPANY_LOADING, payload: { data } };
};

//Thunks
export const getCurrentCompanyThunk = (id) => async (dispatch) => {
  setIsCurrentCompanyLoading(true);
  const response = await getCompany(id);
  if (response.status === 200) {
    dispatch(setCurrentCompany(response.data));
    dispatch(setIsCurrentCompanyLoading(false));
  }
};

export const getCompaniesListThunk =
  (destinationId, status = '', companyOrCity = '') =>
  async (dispatch) => {
    setIsCompanyListLoading(true);
    const response = await getCompanyList(destinationId, status, companyOrCity);
    if (response.status === 200) {
      dispatch(setCompanyList(response.data));
      dispatch(setIsCompanyListLoading(false));
    }
  };

export const getAllCompaniesShortDataThunk = () => async (dispatch) => {
  setIsCompanyListLoading(true);

  const response = await getAllCompanyShortDataList();

  if (response.status === 200) {
    dispatch(setAllCompaniesShortDataList(response.data));
    dispatch(setIsCompanyListLoading(false));
  }
};

export const getAttractionListOfCompaniesThunk =
  (destinationId) => async (dispatch) => {
    setIsAttractionListOfCompanyLoaded(false);
    const response = await getAttractionListOfCompanies(destinationId);
    if (response.status === 200) {
      const attractionsAndSalesPointsLinkedToCompanies = response.data.map(
        (company) => {
          return {
            ...company,
            attractions: company.attractions.sort(function (a, b) {
              var textA = a.name.toUpperCase().trim();
              var textB = b.name.toUpperCase().trim();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            }),
          };
        }
      );

      dispatch(
        setAttractionListOfCompany(attractionsAndSalesPointsLinkedToCompanies)
      );
      dispatch(setIsAttractionListOfCompanyLoaded(true));
    }
  };

export const addCompanyThunk = (destinationId, data) => async (dispatch) => {
  const response = await addCompany(destinationId, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('CompanyForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const editCompanyThunk = (companyId, data) => async (dispatch) => {
  const response = await updateCompany(companyId, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('CompanyForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const deleteCompanyThunk = (companyId) => async () => {
  const response = await deleteCompany(companyId);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
