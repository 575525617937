import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { reset } from 'redux-form';

import useAPIError from '../../../../apIErrorProvider/useAPIError';

import ReviewForm from './ReviewForm';
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';

import {
  getAttractionReivewsListThunk,
  editAttractionReviewThunk,
  deleteAttractionReviewThunk,
} from '../../../../redux/attractionsReducers/reviews/reviewActions';
import { REVIEW_STATUS } from '../../../../consts/consts';
import { REVIEW_FORM } from '../constants';

const ReviewEditFormContainer = (props) => {
  const {
    editAttractionReviewThunk,
    reviewData,
    statusOptionList,
    selectedLanguage,
    resetActiveRow,
    updateReviewList,
    deleteAttractionReviewThunk,
  } = props;

  const { t } = useTranslation();
  const { addError } = useAPIError();
  const dispatch = useDispatch();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const handleFormSubmit = (formData) => {
    setShowSaveAnimation(true);

    const updatedReview = {
      ...reviewData,
      status: formData.status,
      text: formData.text,
      respondentPosition: formData.respondentPosition,
      reply: formData.reply,
    };

    editAttractionReviewThunk(updatedReview)
      .then(() => {
        addError(`${t('reviews.success_edit_review')}`, 'Success');
        resetActiveRow();
        updateReviewList();
        setShowSaveAnimation(false);
      })
      .catch((error) => {
        addError(`${t('reviews.error_edit_review')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleFormDelete = (reviewId) => {
    deleteAttractionReviewThunk(reviewId)
      .then(() => {
        addError(`${t('reviews.success_delete_review')}`, 'Success');
        resetActiveRow();
        updateReviewList();
        setShowSaveAnimation(false);
      })
      .catch((error) => {
        addError(`${t('reviews.error_delete_review')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const resetFormValues = () => {
    dispatch(reset(REVIEW_FORM + reviewData.id));
  };

  const handleFormEdit = () => {
    setIsInEditMode(true);
  };

  const handleFormCancel = () => {
    setIsInEditMode(false);
    resetFormValues();
  };

  const handleDeleteButtonClick = () => {
    setShowDeletePopup(true);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const handleDeleteConfirm = () => {
    handleFormDelete(reviewData.id);
  };

  return (
    <>
      <ReviewForm
        reviewData={reviewData}
        isInEditMode={isInEditMode}
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancel}
        onEdit={handleFormEdit}
        onDelete={handleDeleteButtonClick}
        statusOptionList={statusOptionList}
        showSaveAnimation={showSaveAnimation}
        selectedLanguage={selectedLanguage}
      />
      <ConfirmationDialog
        showAlert={showDeletePopup}
        handleClose={handleDeleteCancel}
        handleCloseSuccess={handleDeleteConfirm}
        text={t('reviews.delete_review_title')}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const statusOptionList = state.appReducer.globalSettingsData.find(
    (item) => item.name.en === REVIEW_STATUS
  );

  return {
    statusOptionList,
    currentAttraction: state.attractionsReducer.currentAttraction,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  editAttractionReviewThunk,
  getAttractionReivewsListThunk,
  deleteAttractionReviewThunk,
})(ReviewEditFormContainer);
