import { Navigate } from 'react-router-dom';

import UserPageContainer from '../../pages/users/UserPageContainer';
import UsersTab from '../../pages/users/usersTab/UsersTab';
import CompanyListContainer from '../../pages/users/companiesTab/CompanyListContainer';
import VisitorsListContainer from '../../pages/users/visitorsTab/VisitorsListContainer';
import VisitorAddFormContainer from '../../pages/users/visitorsTab/visitorsAddForm/VisitorAddFormContainer';
import UserAddFormContainer from '../../pages/users/usersTab/forms/UserAddFormContainer';
import CompanyAddFormContainer from '../../pages/users/companiesTab/forms/CompanyAddFormContainer';
import CompanyEditFormContainer from '../../pages/users/companiesTab/forms/CompanyEditFormContainer';
import UserFormContainer from '../../pages/users/usersTab/forms/UserFormContainer';

import { ADMIN, SYS_ADMIN } from '../consts';

export const usersPageRoutes = [
  {
    path: '/users',
    element: <UserPageContainer />,
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      {
        index: true,
        element: <Navigate to="users" replace />,
        showForRoles: [ADMIN, SYS_ADMIN],
      },
      {
        path: 'users',
        children: [
          {
            index: true,
            element: <UsersTab />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'new-user',
            element: <UserAddFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: ':userId',
            element: <UserFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
      {
        path: 'companies',
        children: [
          {
            index: true,
            element: <CompanyListContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'new-company',
            element: <CompanyAddFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: ':companyId',
            element: <CompanyEditFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
      {
        path: 'visitors',
        element: <VisitorsListContainer />,
        showForRoles: [ADMIN, SYS_ADMIN],
      },
      {
        path: 'new-visitor',
        element: <VisitorAddFormContainer />,
        showForRoles: [ADMIN, SYS_ADMIN],
      },
    ],
  },
];
