import { MobileDatePicker } from '@mui/lab';
import {
  Box,
  Button,
  Select,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

import {
  FormButton,
  SaveButton,
} from '../../../components/buttons/FormButtons';

export const VisitorTableFilterSelectField = styled(Select)`
  max-width: 215px;
  width: 100%;
`;

export const VisitorTableFilterFormWrapper = styled(Box)`
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  gap: 24px;
`;

export const VisitorsFormField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const VisitorsFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const VisitorsFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const VisitorsDialogFormFieldTitle = styled(Typography)`
  max-width: 150px;
  width: 100%;
  margin-left:50px;
`;

export const VisitorsDialogFormFieldSelect = styled(Select)`
  width: 100%;
  margin-right: 50px;
`;

export const VisitorsFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 30px;
`;

export const VisitorsFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const VisitorsFormButton = styled(FormButton)`
  max-width: fit-content;
`;

export const StyledVisitorsTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    background-color: #ffa94c;
  }
`;

export const StyledVisitorsTableHead = styled(TableHead)`
  .MuiTableCell-root {
    :nth-of-type(1) {
      width: 5%;
    }
    :nth-of-type(2) {
      width: 5%;
    }
    :nth-of-type(3) {
      width: 15%;
    }
    :nth-of-type(4) {
      width: 15%;
    }
    :nth-of-type(5) {
      width: 5%;
    }
    :nth-of-type(6) {
      width: 5%;
    }
    :nth-of-type(7) {
      width: 5%;
    }
    :nth-of-type(8) {
      width: 0%;
      text-align: center;
    }
  }
`;

export const VisitorsListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const VisitorsAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const StyledTableHeadRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const VisitorFormDateField = styled(MobileDatePicker)`
  max-width: 200px;
  width: 100%;
`;

export const VisitorFormDateTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }

  & .MuiOutlinedInput-input {
    cursor: pointer;
    max-width: 169px;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;
