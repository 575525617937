import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { change, reset } from 'redux-form';
import { format } from 'date-fns';

import SeasonForm from './SeasonForm';

import HoursModal from './HoursModal';
import SpecialDateModal from './SpecialDateModal';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import {
  addSeasonsThunk,
  editSeasonsThunk,
  getSeasonsThunk,
  setCurrentSeason,
  setSeason,
} from '../../../redux/attractionsReducers/availabilityReducer';
import {
  TYPE_ALL_WEEK,
  TYPE_OPENING_HOURS,
  TYPE_STARTING_TIMES,
  WEEK_DAYS,
  MANAGER_ROLE_LIST,
} from './constants';
import { getOpeningHoursValue, getTypeValue } from './utils';

const SeasonContainer = () => {
  const { t } = useTranslation();
  const { id, seasonId } = useParams();
  const { addError } = useAPIError();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );

  const languages = useSelector((state) =>
    state.settingsReducer.destinationData.filter(
      (destination) => destination.id === selectedDestinationId
    )
  );

  const seasonsList = useSelector(
    (state) => state.availabilityReducer.seasonsList
  );

  const [timeArray, setTimeArray] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
    allWeeks: [],
    openingHoursSpecialDates: [],
    startingTimesSpecialDates: [],
    isClosedOn: '',
  });

  const [isSave, setIsSave] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [currentDay, setCurrentDay] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openSpecialDateModal, setOpenSpecialDateModal] = useState(false);
  const [isHoursModalOpen, setIsHoursModalOpen] = useState(false);
  const [activeTimeArrayIndex, setActiveTimeArrayIndex] = useState(null);
  const [isSpecialDateClosed, setIsSpecialDateClosed] = useState(false);
  const [allWeekValue, setAllWeekValue] = useState(null);

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  const currentSeason = useMemo(() => {
    return seasonsList
      ? seasonsList.find((season) => season.id === seasonId)
      : {};
  }, [seasonId, seasonsList]);

  const handleCloseHoursModal = () => {
    setIsHoursModalOpen(false);
    setActiveTimeArrayIndex(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeSpecialDateIsClosed = () => {
    setIsSpecialDateClosed(!isSpecialDateClosed);
    dispatch(change('SpecialDateModal', 'validTo', null));
    dispatch(change('SpecialDateModal', 'validFrom', null));
  };

  const handleSpecialDateCloseModal = () => {
    setOpenSpecialDateModal(false);
    setActiveTimeArrayIndex(null);
  };

  const handleOpenModal = (day, timeData, index) => {
    setOpenModal(true);
    setCurrentDay(day);
    setActiveTimeArrayIndex(!timeData ? null : index);

    if (timeData) {
      const today = format(new Date(), 'yyyy-MM-dd');
      dispatch(change('modal', 'validFrom', new Date(`${today}T${timeData}`)));
    } else if (selectValue === TYPE_ALL_WEEK && !!allWeekValue) {
      const today = format(new Date(), 'yyyy-MM-dd');

      const validFromInitialValue = new Date(
        `${today}T${allWeekValue.timeInterval.validFrom}`
      );
      const validToInitialValue = new Date(
        `${today}T${allWeekValue.timeInterval.validTo}`
      );

      dispatch(change('modal', 'validFrom', validFromInitialValue));
      dispatch(change('modal', 'validTo', validToInitialValue));
      dispatch(change('modal', 'closedOn', allWeekValue.closedOn));
    } else {
      dispatch(reset('modal'));
    }
  };

  const handleOpenHoursModal = (day, timeData, index) => {
    setIsHoursModalOpen(true);
    setCurrentDay(day);
    setActiveTimeArrayIndex(!timeData ? null : index);

    if (timeData) {
      const today = format(new Date(), 'yyyy-MM-dd');

      dispatch(
        change('modal', 'validTo', new Date(`${today}T${timeData.validTo}`))
      );
      dispatch(
        change('modal', 'validFrom', new Date(`${today}T${timeData.validFrom}`))
      );
    } else {
      dispatch(reset('modal'));
    }
  };

  const handleSpecialDateOpenModal = (dateData, index) => {
    setOpenSpecialDateModal(true);
    setActiveTimeArrayIndex(!dateData ? null : index);

    if (dateData) {
      const today = format(new Date(), 'yyyy-MM-dd');

      if (selectValue === TYPE_STARTING_TIMES) {
        const isNoHours = dateData.hours === null;

        setIsSpecialDateClosed(isNoHours ? true : false);

        dispatch(
          change(
            'SpecialDateModal',
            'isSpecialDateClosed',
            isNoHours ? true : false
          )
        );

        dispatch(
          change(
            'SpecialDateModal',
            'validFrom',
            isNoHours ? null : new Date(`${today}T${dateData.hours}`)
          )
        );
      }

      if (selectValue === TYPE_OPENING_HOURS || selectValue === TYPE_ALL_WEEK) {
        const isNoTimeInterval = dateData.timeInterval === null;

        setIsSpecialDateClosed(isNoTimeInterval ? true : false);

        dispatch(
          change(
            'SpecialDateModal',
            'isSpecialDateClosed',
            isNoTimeInterval ? true : false
          )
        );

        dispatch(
          change(
            'SpecialDateModal',
            'validFrom',
            isNoTimeInterval
              ? null
              : new Date(`${today}T${dateData.timeInterval.validFrom}`)
          )
        );

        dispatch(
          change(
            'SpecialDateModal',
            'validTo',
            isNoTimeInterval
              ? null
              : new Date(`${today}T${dateData.timeInterval.validTo}`)
          )
        );
      }

      dispatch(change('SpecialDateModal', 'date', new Date(dateData.date)));
    } else {
      dispatch(reset('SpecialDateModal'));
      setIsSpecialDateClosed(false);
    }
  };

  const handleSubmitHoursModalForm = (values) => {
    let data;
    const currentTimeArray = timeArray[currentDay];

    if (selectValue === TYPE_OPENING_HOURS) {
      data = {
        validFrom: format(Date.parse(values.validFrom), 'HH:mm'),
        validTo: format(Date.parse(values.validTo), 'HH:mm'),
      };
    } else if (selectValue === TYPE_STARTING_TIMES) {
      data = format(Date.parse(values.validFrom), 'HH:mm');
    } else {
      data = {
        date: values.date,
        timeInterval: {
          validFrom: format(Date.parse(values.validFrom), 'HH:mm'),
          validTo: format(Date.parse(values.validTo), 'HH:mm'),
        },
      };
    }

    if (selectValue !== TYPE_ALL_WEEK) {
      if (activeTimeArrayIndex === null) {
        currentTimeArray.push(data);
      } else {
        currentTimeArray[activeTimeArrayIndex] = data;
      }
    }

    if (selectValue === TYPE_ALL_WEEK) {
      const newAllWeekValue = {
        closedOn: values.closedOn,
        timeInterval: {
          validFrom: format(Date.parse(values.validFrom), 'HH:mm'),
          validTo: format(Date.parse(values.validTo), 'HH:mm'),
        },
      };

      setAllWeekValue(newAllWeekValue);
    }

    if (
      selectValue === TYPE_OPENING_HOURS ||
      selectValue === TYPE_STARTING_TIMES
    ) {
      setTimeArray({ ...timeArray, [currentDay]: currentTimeArray });
    }

    setOpenModal(false);
    setIsHoursModalOpen(false);
    setActiveTimeArrayIndex(null);
  };

  const handleSubmitSpecialDateModalForm = (values) => {
    let currentSpecialDatesArray;
    let timeInterval;
    let hours;
    let data;

    const isAllWeek = selectValue === TYPE_ALL_WEEK;
    const isOpeningHours = selectValue === TYPE_OPENING_HOURS;
    const isStartingTimes = selectValue === TYPE_STARTING_TIMES;

    if (isOpeningHours || isAllWeek) {
      currentSpecialDatesArray = timeArray.openingHoursSpecialDates;

      timeInterval =
        values.validFrom === null && values.validTo === null
          ? null
          : {
              validFrom: format(Date.parse(values.validFrom), 'HH:mm'),
              validTo: format(Date.parse(values.validTo), 'HH:mm'),
            };
      data = {
        date: values.date,
        timeInterval,
      };
    }

    if (isStartingTimes) {
      currentSpecialDatesArray = timeArray.startingTimesSpecialDates;
      hours =
        values.validFrom === null
          ? null
          : [format(Date.parse(values.validFrom), 'HH:mm')];
      data = {
        date: values.date,
        hours,
      };
    }

    if (activeTimeArrayIndex === null) {
      currentSpecialDatesArray.push(data);
    } else {
      currentSpecialDatesArray[activeTimeArrayIndex] = data;
    }

    if (isOpeningHours || isAllWeek) {
      setTimeArray({
        ...timeArray,
        openingHoursSpecialDates: currentSpecialDatesArray,
      });
    }

    if (isStartingTimes) {
      setTimeArray({
        ...timeArray,
        startingTimesSpecialDates: currentSpecialDatesArray,
      });
    }

    setOpenSpecialDateModal(false);
    setIsSpecialDateClosed(false);
    setActiveTimeArrayIndex(null);
  };

  const handleSubmitSeason = (values) => {
    setIsSave(true);
    const data = {
      ...(seasonId && { id: seasonId }),
      placeId: id,
      name: values.name,
      type: values.type ? getTypeValue(values.type) : null,
      period: {
        validFrom: values.period.validFrom,
        validTo: values.period.validTo,
      },
      startingTimes:
        selectValue === TYPE_STARTING_TIMES
          ? {
              monday: {
                isClosed: timeArray.monday.length ? false : true,
                hours: timeArray.monday ? timeArray.monday : null,
              },
              tuesday: {
                isClosed: timeArray.tuesday.length ? false : true,
                hours: timeArray.tuesday ? timeArray.tuesday : null,
              },
              wednesday: {
                isClosed: timeArray.wednesday.length ? false : true,
                hours: timeArray.wednesday ? timeArray.wednesday : null,
              },
              thursday: {
                isClosed: timeArray.thursday.length ? false : true,
                hours: timeArray.thursday ? timeArray.thursday : null,
              },
              friday: {
                isClosed: timeArray.friday.length ? false : true,
                hours: timeArray.friday ? timeArray.friday : null,
              },
              saturday: {
                isClosed: timeArray.saturday.length ? false : true,
                hours: timeArray.saturday ? timeArray.saturday : null,
              },
              sunday: {
                isClosed: timeArray.sunday.length ? false : true,
                hours: timeArray.sunday ? timeArray.sunday : null,
              },
            }
          : null,
      openingHoursSpecialDates: timeArray.openingHoursSpecialDates.length
        ? timeArray.openingHoursSpecialDates
        : null,
      startingTimesSpecialDates: timeArray.startingTimesSpecialDates.length
        ? timeArray.startingTimesSpecialDates
        : null,
      isLastEntry: values.isLastEntry !== 0 ? values.isLastEntry : 0,
      isTemporaryClosed:
        values.isTemporaryClosed.validFrom !== null
          ? {
              validFrom: values.isTemporaryClosed.validFrom,
              validTo: values.isTemporaryClosed.validTo,
            }
          : null,
      isAllWeek: selectValue === TYPE_ALL_WEEK,
      openingHours: getOpeningHoursValue(selectValue, timeArray, allWeekValue),
    };

    if (seasonId) {
      dispatch(editSeasonsThunk(seasonId, data))
        .then(() => {
          setIsSave(false);
          addError(`${t('attractions.season.error_edit_text')}`, 'Success');
          navigate((isManagerRoleSelected ? '/atractions' : '/attractions') + `/${id}/availability`);
        })
        .catch((error) => {
          addError(
            `${t('attractions.season.error_not_edit_text')}`,
            'Error',
            error
          );
          setIsSave(false);
        });
    } else {
      dispatch(addSeasonsThunk(id, data))
        .then(() => {
          setIsSave(false);
          addError(`${t('attractions.season.error_add_text')}`, 'Success');
          navigate((isManagerRoleSelected ? '/atractions' : '/attractions') + `/${id}/availability`);
        })
        .catch((error) => {
          addError(
            `${t('attractions.season.error_not_add_text')}`,
            'Error',
            error
          );
          setIsSave(false);
        });
    }
  };

  const handleCurrentSeasonTimeArray = () => {
    if (currentSeason.openingHours !== null) {
      setTimeArray({
        ...timeArray,
        monday: [...currentSeason.openingHours.monday.hours],
        tuesday: [...currentSeason.openingHours.tuesday.hours],
        wednesday: [...currentSeason.openingHours.wednesday.hours],
        thursday: [...currentSeason.openingHours.thursday.hours],
        friday: [...currentSeason.openingHours.friday.hours],
        saturday: [...currentSeason.openingHours.saturday.hours],
        sunday: [...currentSeason.openingHours.sunday.hours],
        openingHoursSpecialDates: [...currentSeason.openingHoursSpecialDates],
        startingTimesSpecialDates: [...currentSeason.startingTimesSpecialDates],
      });
    } else if (currentSeason.startingTimes !== null) {
      setTimeArray({
        ...timeArray,
        monday: [...currentSeason.startingTimes.monday.hours],
        tuesday: [...currentSeason.startingTimes.tuesday.hours],
        wednesday: [...currentSeason.startingTimes.wednesday.hours],
        thursday: [...currentSeason.startingTimes.thursday.hours],
        friday: [...currentSeason.startingTimes.friday.hours],
        saturday: [...currentSeason.startingTimes.saturday.hours],
        sunday: [...currentSeason.startingTimes.sunday.hours],
        openingHoursSpecialDates: [...currentSeason.openingHoursSpecialDates],
        startingTimesSpecialDates: [...currentSeason.startingTimesSpecialDates],
      });
    }

    if (currentSeason.type === TYPE_OPENING_HOURS && currentSeason.isAllWeek)
      setSelectValue(TYPE_ALL_WEEK);
    else setSelectValue(currentSeason.type);
  };

  const handleCurrentSeasonAllWeekValue = () => {
    if (!currentSeason.isAllWeek) return;

    const closedOnValue = WEEK_DAYS.find((day) => {
      return currentSeason.openingHours[day].isClosed;
    });

    const newAllWeekValue = {
      timeInterval: currentSeason.openingHours.monday.hours[0],
      closedOn:
        closedOnValue &&
        closedOnValue.charAt(0).toUpperCase() + closedOnValue.slice(1),
    };

    setAllWeekValue(newAllWeekValue);
  };

  useEffect(() => {
      seasonId && id && dispatch(getSeasonsThunk(id));

      return () => dispatch(setSeason([]));
  }, [dispatch, id, seasonId]);

  useEffect(() => {
    if (currentSeason) {
      dispatch(setCurrentSeason(currentSeason));
      handleCurrentSeasonTimeArray();
      handleCurrentSeasonAllWeekValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeason, dispatch]);

  return (
    <>
      {languages && (
        <SeasonForm
          onSubmit={handleSubmitSeason}
          isSave={isSave}
          setCurrentDay={setCurrentDay}
          handleOpenModal={handleOpenModal}
          handleOpenHoursModal={handleOpenHoursModal}
          handleSpecialDateOpenModal={handleSpecialDateOpenModal}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          timeArray={timeArray}
          setTimeArray={setTimeArray}
          languages={languages[0]?.languages}
          currentSeason={currentSeason}
          handleCurrentSeasonTimeArray={handleCurrentSeasonTimeArray}
          allWeekValue={allWeekValue}
          setAllWeekValue={setAllWeekValue}
        />
      )}

      <HoursModal
        onSubmit={handleSubmitHoursModalForm}
        openModal={openModal}
        isHoursModalOpen={isHoursModalOpen}
        currentDay={currentDay}
        handleCloseModal={handleCloseModal}
        handleOpenHoursModal={handleOpenHoursModal}
        handleCloseHoursModal={handleCloseHoursModal}
        selectValue={selectValue}
        allWeekValue={allWeekValue}
      />
      <SpecialDateModal
        onSubmit={handleSubmitSpecialDateModalForm}
        openSpecialDateModal={openSpecialDateModal}
        handleSpecialDateOpenModal={handleSpecialDateOpenModal}
        handleSpecialDateCloseModal={handleSpecialDateCloseModal}
        handleChangeSpecialDateIsClosed={handleChangeSpecialDateIsClosed}
        isSpecialDateClosed={isSpecialDateClosed}
        selectValue={selectValue}
      />
    </>
  );
};

export default SeasonContainer;
