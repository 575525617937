import { Box, Tabs } from '@mui/material';
import { styled } from '@mui/system';

export const UsersPageContainer = styled(Box)`
  max-width: 1300px;
  width: 100%;
  margin: 95px auto;
`;

export const UsersTabsContainer = styled(Box)`
  border-bottom: 1;
  border-color: divider;
`;

export const UsersTabs = styled(Tabs)`
  & .MuiTabs-flexContainer {
    justifycontent: flex-start;
  }
`;
