import clsx from 'clsx';
import { connect, useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ArticlesFormContainer } from '../articlesForms';

import { ArticlesTableRow } from '../styled';
import { ARTICLES_TYPES } from '../constants';

const useStyles = makeStyles({
  activeRow: {
    backgroundColor: '#FFA94C',
  },
});

const ArticlesRow = (props) => {
  const {
    item,
    onClick,
    isExpanded,
    languageList,
    resetActiveRow,
    resetFilters,
  } = props;

  const classes = useStyles();

  const articleTypeList = useSelector((state) =>
    state.appReducer.globalSettingsData.find(
      (item) => item.name.en === ARTICLES_TYPES
    )
  );

  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );

  const translatedTypeValue = articleTypeList?.parameters.find(
    (type) => type.id === item.type
  );

  return (
    <>
      <ArticlesTableRow
        onClick={onClick}
        className={clsx({ [classes.activeRow]: isExpanded })}
      >
        <TableCell>
          {item.title[languageList.parameters[0].id] || item.title.en}
        </TableCell>
        <TableCell>{translatedTypeValue?.value[selectedLanguage]}</TableCell>
      </ArticlesTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={3}>
            <ArticlesFormContainer
              languageList={languageList}
              item={item}
              resetActiveRow={resetActiveRow}
              resetFilters={resetFilters}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps)(ArticlesRow);
