import { format } from 'date-fns';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { StyledAdvertContentTableRow, TableImageBox } from '../styled';

import { AdvertContentFormContainer } from '../forms';

import { ACTIVITY_STATUS } from '../../../../consts/consts';
import { STATUS_COLORS } from '../constants';

const useStyles = makeStyles({
  activeRow: {
    backgroundColor: '#FFA94C',
  },
});

const AdvertContentTableRow = (props) => {
  const {
    resetActiveRow,
    item,
    onExpand,
    isExpanded,
    languageList,
    selectedLanguage,
  } = props;
  const { date, subject, mobileImage, webImage } = item;

  const classes = useStyles();

  const currentDestinationLanguage = useSelector((state) =>
      Object.keys(state.settingsReducer.currentDestination).length > 0 && state.settingsReducer.currentDestination.languages[0]
  );
  
  const activityStatuses = useSelector((state) =>
    state.appReducer?.globalSettingsData.find(
      (item) => item.name.en === ACTIVITY_STATUS
    )
  );

  const statusValueTranslations = activityStatuses?.parameters.find(
    (status) => status.id === item.status
  );
  const handleExpandTableRow = () => {
    onExpand();
  };

  return (
    <>
      <StyledAdvertContentTableRow
        onClick={handleExpandTableRow}
        className={clsx({ [classes.activeRow]: isExpanded })}
      >
        <TableCell
          sx={{
            bgcolor: STATUS_COLORS[item.status],
          }}
        >
          {statusValueTranslations?.value[selectedLanguage] || null}
        </TableCell>
        <TableCell>{format(new Date(date), 'dd/MM/yyyy')}</TableCell>
        <TableCell>{subject[currentDestinationLanguage]}</TableCell>
        <TableCell>
          <TableImageBox component="img" src={mobileImage} loading="lazy" alt="" />
        </TableCell>
        <TableCell>
          <TableImageBox component="img" src={webImage} loading="lazy" alt="" />
        </TableCell>
      </StyledAdvertContentTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={5}>
            <AdvertContentFormContainer
              item={item}
              resetActiveRow={resetActiveRow}
              languageList={languageList}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default AdvertContentTableRow;
