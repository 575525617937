import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Typography,
  TableBody,
  Box,
  Table,
  TablePagination,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import {
  DATE_SORT_ASC_DIRECTION,
  DATE_SORT_DESC_DIRECTION,
  DEFAULT_ATTRACTION_VALUE,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from './constants';
import VisitsFilterForm from './visitsFilterForm/VisitsFilterForm';
import VisitsTableHeader from './visitsTable/VisitsTableHeader';
import VisitTableDataRow from './visitsTable/VisitTableDataRow';
import { VisitsPageContainer } from './styled';

import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import { setPaginationSettings } from '../../redux/visits/visitsActions';

import { setTitle } from '../../redux/appReducer';

const VisitsList = ({
  isDataLoading,
  visitsList,
  paginationSettings,
  attractionsShortData,
  currentDestinationData,
  getSmallAttractionsDataThunk,
  getVisitsListThunk,
  loadVisitsListThunk,
  benefitOptions,
  getPredefinedSettingsThunk,
  languages,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sortDirection, setSortDirection] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { pageNumberLazy } = paginationSettings
  const [isAllPagination, setIsAllPagination] = useState(true);
  const [attractionInputValue, setAttractionInputValue] = useState({
    id: '',
    name: '',
  });

  const handleChangeSortDirection = () =>
    setSortDirection(
      sortDirection === DATE_SORT_ASC_DIRECTION
        ? DATE_SORT_DESC_DIRECTION
        : DATE_SORT_ASC_DIRECTION
    );

  const { id } = currentDestinationData;

  const initialFormState = {
    dateAndTime: '',
    searchQuery: '',
    attractionName: '',
  };

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      ...initialFormState,
    },
  });

  const { watch, setValue, reset } = formMethods;

  const searchQueryValue = watch('searchQuery');
  const attractionNameValue = watch('attractionName');
  const dateAndTimeValue = watch('dateAndTime');

  const formattedDateTime =
    dateAndTimeValue && new Date(dateAndTimeValue).toISOString();

  const TABLE_PAGINATION_OPTIONS = [
    { label: t('all_pagination_option'), value: 50 },
    { label: '20', value: 20 },
    { label: '60', value: 60 },
    { label: '100', value: 100 },
  ];

  const handleResetFilters = () => {
    reset(initialFormState);
    setAttractionInputValue(DEFAULT_ATTRACTION_VALUE);
  };

  const handleResetDateSortDirection = () => setSortDirection('');

  const handleChangeSearchValue = (e) => {
    setValue('searchQuery', validateTextFieldValue(e.target.value));
    handlePaginationSettings();
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  })

  
  useEffect(() => {
    if(isFetching && id) {
       loadVisitsListThunk(
        id,
        pageNumberLazy,
        paginationSettings.pageSize,
        searchQueryValue,
        formattedDateTime,
        attractionNameValue,
        sortDirection
      ).then(() => {
        dispatch(setPaginationSettings({
          ...paginationSettings,
          pageNumberLazy: pageNumberLazy + 1,
        }))
        setIsFetching(false);
      });
    }
  }, [isFetching, isAllPagination])

  const scrollHandler = (e) => {
    const [ fullHeigth, heigthFromTop, windowHeight ] =  [
      e.target.documentElement.scrollHeight, 
      e.target.documentElement.scrollTop, 
      window.innerHeight
    ]
    if(
      fullHeigth - (heigthFromTop + windowHeight) < 800 && 
      visitsList.length < paginationSettings.totalCount &&
      isAllPagination
      ) {
      setIsFetching(true);
    }
  }

  const handlePaginationSettings = (
    pageSize = 50,
    pageNumber = DEFAULT_PAGE_NUMBER
  ) => {
    dispatch(
      setPaginationSettings({
        ...paginationSettings,
        pageSize,
        pageNumber,
      })
    );
    handleResetDateSortDirection();
  };

  const onChangeRowsPerPage = (e) => {
    if(e.target.value == 80) setIsAllPagination(true)
    else setIsAllPagination(false);

    setIsFetching(false);

    handlePaginationSettings(parseInt(e.target.value, 10), 0);
  }

  const onChangePage = (_, newPageNumber) =>
    handlePaginationSettings(paginationSettings.pageSize, newPageNumber);

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_visits')));
    getPredefinedSettingsThunk();
    id && getSmallAttractionsDataThunk(id);

    return () => handlePaginationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPredefinedSettingsThunk, getSmallAttractionsDataThunk, id]);

  useEffect(() => {
    id &&
      getVisitsListThunk(
        id,
        paginationSettings.pageNumber,
        paginationSettings.pageSize,
        searchQueryValue,
        formattedDateTime,
        attractionNameValue,
        sortDirection
      );
  }, [
    id,
    formattedDateTime,
    getVisitsListThunk,
    paginationSettings.pageNumber,
    paginationSettings.pageSize,
    searchQueryValue,
    attractionNameValue,
    sortDirection,
  ]);

  return (
    <VisitsPageContainer>
      <Box mb="10px">
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('visitsPage.headline')}
        </Typography>
      </Box>

      <FormProvider {...formMethods}>
        <VisitsFilterForm
          attractionsList={attractionsShortData}
          handlePaginationSettings={handlePaginationSettings}
          handleChangeSearchValue={handleChangeSearchValue}
          handleResetFilters={handleResetFilters}
          attractionInputValue={attractionInputValue}
          handleChangeAttractionInputValue={setAttractionInputValue}
        />
      </FormProvider>

      {isDataLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <TablePagination
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            rowsPerPage={paginationSettings.pageSize}
            page={paginationSettings.pageNumber}
            component="div"
            count={paginationSettings.totalCount}
            onRowsPerPageChange={onChangeRowsPerPage}
            onPageChange={onChangePage}
            labelRowsPerPage={t('attractions.deals.rows_per_page')}
            labelDisplayedRows={({ from, to, count }) => 
            isAllPagination 
              ? `${paginationSettings.totalCount}-${paginationSettings.totalCount} of ${paginationSettings.totalCount}`
              : `${from}-${to} of ${count}`
            }
             nextIconButtonProps={{
              style: isAllPagination ? { display: 'none' } : {}
            }}
            backIconButtonProps={{
              style: isAllPagination ? { display: 'none' } : {}
            }}
          />
          <Table>
            <VisitsTableHeader
              handleChangeSortDirection={handleChangeSortDirection}
              handleResetDateSortDirection={handleResetDateSortDirection}
              sortDirection={sortDirection}
            />
            <TableBody>
              {visitsList &&
                visitsList.map((visit, idx) => (
                  <VisitTableDataRow
                    key={idx}
                    visitData={visit}
                    benefitOptions={benefitOptions}
                    languages={languages}
                  />
                ))}
            </TableBody>
          </Table>
          {isFetching && 
            <Box display="flex" marginTop="50px" justifyContent="center">
              <CircularProgress />
            </Box>
          }
          <TablePagination
            rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
            rowsPerPage={paginationSettings.pageSize}
            page={paginationSettings.pageNumber}
            component="div"
            count={paginationSettings.totalCount}
            onRowsPerPageChange={onChangeRowsPerPage}
            onPageChange={onChangePage}
            labelRowsPerPage={t('attractions.deals.rows_per_page')}
            labelDisplayedRows={({ from, to, count }) => 
            isAllPagination 
              ? `${paginationSettings.totalCount}-${paginationSettings.totalCount} of ${paginationSettings.totalCount}`
              : `${from}-${to} of ${count}`
            }
            nextIconButtonProps={{
              style: isAllPagination ? { display: 'none' } : {}
            }}
            backIconButtonProps={{
              style: isAllPagination ? { display: 'none' } : {}
            }}
          />
        </TableContainer>
      )}
    </VisitsPageContainer>
  );
};

export default VisitsList;
