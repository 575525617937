import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { LanguageTabPanel } from './styled';

import {
  FACTS_TITLE_FIELD_MAX_LENGTH,
  FACTS_TEXT_FIELD_MAX_LENGTH,
  FACTS_TEXT_FIELD_ROWS,
} from '../../constants';
import FactsFormTextField from '../formFields/FactsFormTextField';
import FactsFormTextEditorField from '../formFields/FactsFormTextEditorField';
import { FactsFormFieldTitle, FactsFormFieldWrapper } from '../../styled';

const TabPanel = (props) => {
  const { fieldArrayName, activeTabId, tabId, disabled, ...other } = props;

  const { t } = useTranslation();

  const { control } = useFormContext();

  const { fields } = useFieldArray({
    name: fieldArrayName,
    control,
  });

  const isActiveTab = activeTabId === tabId;

  return (
    <LanguageTabPanel
      role="tabpanel"
      hidden={!isActiveTab}
      id={`simple-tabpanel-${tabId}`}
      aria-labelledby={`simple-tab-${tabId}`}
      {...other}
    >
      {isActiveTab && (
        <Box sx={{ p: 3 }}>
          {fields.map((field, index) => {
            return (
              <Box id={field.id} key={field.id}>
                <FactsFormFieldWrapper>
                  <FactsFormFieldTitle>
                    {t('facts.formFieldLabels.title')}
                  </FactsFormFieldTitle>
                  <FactsFormTextField
                    name={`${fieldArrayName}.${index}.title`}
                    maxLength={FACTS_TITLE_FIELD_MAX_LENGTH}
                    disabled={disabled}
                    required
                  />
                </FactsFormFieldWrapper>
                <FactsFormFieldWrapper>
                  <FactsFormFieldTitle>
                    {t('facts.formFieldLabels.text')}
                  </FactsFormFieldTitle>
                  <FactsFormTextEditorField
                    required
                    name={`${fieldArrayName}.${index}.text`}
                    maxLength={FACTS_TEXT_FIELD_MAX_LENGTH}
                    disabled={disabled}
                    multiline
                    rows={FACTS_TEXT_FIELD_ROWS}
                  />
                </FactsFormFieldWrapper>
              </Box>
            );
          })}
        </Box>
      )}
    </LanguageTabPanel>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  tabId: PropTypes.string.isRequired,
  activeTabId: PropTypes.string.isRequired,
};

export default TabPanel;
