import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormControlLabel } from '@mui/material';

const DealUsesLimitedCheckboxField = ({ name, disabled }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <FormControlLabel
          sx={{
            marginLeft: 0,
          }}
          label={t('attractions.deals.not_limited')}
          labelPlacement="end"
          control={
            <Checkbox
              disabled={disabled}
              disableRipple
              onChange={(event) => onChange(event.target.checked)}
              checked={value}
              name={name}
            />
          }
        />
      )}
    />
  );
};

export default DealUsesLimitedCheckboxField;
