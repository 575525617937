import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormHelperText } from '@mui/material';
import { styled } from '@mui/system';

import ImageDrop from '../../../HomePageTab/imageDrop/ImageDrop';

const StyledFormHelperText = styled(FormHelperText)`
  color: #d43131;
  margin-left: 0px;
`;

const AdvertContentFormImageDrop = ({
  name,
  disabled,
  required,
  columns,
  filesLimit,
  allowedFileTypes,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          return !!value.length || t('mainImage.validation.required');
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <ImageDrop
            fileList={value}
            onFileListChange={(value) => {
              onChange(value);
            }}
            filesLimit={filesLimit}
            disabled={disabled}
            columns={columns}
            allowedFileTypes={allowedFileTypes}
          />
          <StyledFormHelperText>{error?.message}</StyledFormHelperText>
        </>
      )}
    />
  );
};
export default AdvertContentFormImageDrop;
