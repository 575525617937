const cellStyle = {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    wordBreak:"break-all",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block",
    textOverflow: "ellipsis",
  }

  const mainDataCellStyle = {
    ...cellStyle,
    width:"100%",
    maxWidth:"400px"
  }
  
  const dataCellStyle={
    ...cellStyle,
    width: "30%"
  }

  export const tableCellsDataStyle = {
    mainDataStyle: mainDataCellStyle,
    dataStyle: dataCellStyle
  }