import { Navigate } from 'react-router-dom';

import FaqPageContainer from '../../pages/faq/FaqPageContainer';
import QuestionsFaqContainer from '../../pages/faq/question/QuestionsFaqContainer';
import CategoriesFaqContainer from '../../pages/faq/category/CategoriesFaqContainer';
import QuestionAddFormContainer from '../../pages/faq/question/QuestionAddFormContainer';
import CategoryFaqAddFormContainer from '../../pages/faq/category/CategoryFaqAddFormContainer';
import CategoryFaqEditFormContainer from '../../pages/faq/category/CategoryFaqEditFormContainer';

import { ADMIN, SYS_ADMIN } from '../consts';

export const faqPageRoutes = [
  {
    path: '/faq',
    element: <FaqPageContainer />,
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      {
        index: true,
        element: <Navigate to="questions" replace />,
        showForRoles: [ADMIN, SYS_ADMIN],
      },
      {
        path: 'questions',
        children: [
          {
            index: true,
            element: <QuestionsFaqContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'new-question',
            element: <QuestionAddFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
      {
        path: 'categories',
        element: <CategoriesFaqContainer />,
        showForRoles: [ADMIN, SYS_ADMIN],
        children: [
          {
            path: 'new-category',
            element: <CategoryFaqAddFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            path: 'view/:id',
            element: <CategoryFaqEditFormContainer />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
    ],
  },
];
