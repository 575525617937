import { InputAdornment } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProductFormNumberField } from '../../styled';

const ProductEditFormNumberField = ({
  name,
  disabled,
  numberValidator,
  handleChange,
  hasCurrency,
  currency,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: true,
          message: t('products.error_required'),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ProductFormNumberField
          value={value}
          disabled={disabled}
          onChange={(event) => {
            const validationCheck = numberValidator(event.target.value);

            if (!validationCheck.valid) {
              event.preventDefault();
              return;
            } else {
              onChange(validationCheck.value);
              handleChange && handleChange(validationCheck.value);
            }
          }}
          fullWidth
          size="small"
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: hasCurrency ? (
              <InputAdornment position="end">{currency}</InputAdornment>
            ) : null,
          }}
        />
      )}
    />
  );
};

export default ProductEditFormNumberField;
