import {
  SET_POINT_LIST,
  SET_POINT_SHORT_DATA_LIST,
  SET_CURRENT_POINT,
  SET_SELECTED_REGION,
  SET_SELECTED_AREA,
  SET_PAGE_SIZE,
  SET_PAGE_NUMBER,
  SET_TOTAL_COUNT,
  SET_TOTAL_PAGES,
  IS_POINT_LIST_LOADING,
  IS_POINT_LOADING,
  SET_ADD_ELEMENT,
  SET_STATUS_VALUE,
  SET_POINT_VALUE,
  SET_CATEGORY_VALUE,
  SET_REGION_VALUE,
  SET_AREA_VALUE,
  SET_LOCATION_VALUE,
} from './salesPointsActions';

const initialState = {
  pointList: [],
  pointShortDataList: [],
  currentPoint: {},
  selectedRegion: '',
  selectedArea: '',
  pageSize: 1000,
  pageNumber: 0,
  totalCount: 0,
  totalPages: 0,
  isPointListLoading: false,
  isPointLoading: false,
  addElement: false,
  statusValue: '',
  attractionsValue: '',
  categoryValue: '',
  regionValue: '',
  areaValue: '',
  locationValue: '',
};

const salesPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POINT_LIST:
      return {
        ...state,
        pointList: [...action.payload.data],
      };
    case SET_POINT_SHORT_DATA_LIST:
      return {
        ...state,
        pointShortDataList: [...action.payload.data],
      };
    case SET_CURRENT_POINT:
      return {
        ...state,
        currentPoint: action.payload.data,
      };
    case SET_SELECTED_REGION:
      return {
        ...state,
        selectedRegion: action.selectedRegion,
      };
    case SET_SELECTED_AREA:
      return {
        ...state,
        selectedArea: action.selectedArea,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber,
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.totalCount,
      };
    case SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.totalPages,
      };
    case IS_POINT_LIST_LOADING:
      return {
        ...state,
        isPointListLoading: action.payload.data,
      };
    case IS_POINT_LOADING:
      return {
        ...state,
        isPointLoading: action.payload.data,
      };
    case SET_ADD_ELEMENT:
      return {
        ...state,
        addElement: action.addElement,
      };

    case SET_STATUS_VALUE:
      return {
        ...state,
        statusValue: action.statusValue,
      };
    case SET_POINT_VALUE:
      return {
        ...state,
        attractionsValue: action.attractionsValue,
      };
    case SET_CATEGORY_VALUE:
      return {
        ...state,
        categoryValue: action.categoryValue,
      };
    case SET_REGION_VALUE:
      return {
        ...state,
        regionValue: action.regionValue,
      };
    case SET_AREA_VALUE:
      return {
        ...state,
        areaValue: action.areaValue,
      };
    case SET_LOCATION_VALUE:
      return {
        ...state,
        locationValue: action.locationValue,
      };
    default:
      return state;
  }
};

export default salesPointsReducer;
