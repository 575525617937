import { Controller, useFormContext } from 'react-hook-form';

import { DealFormSelectField } from '../styled';

const createOptions = (options, selectedLanguage) => {
  if (!options) return null;

  return options.parameters.map((option, index) => (
    <option value={option.id} key={index}>
      {option.value[selectedLanguage]}
    </option>
  ));
};

const DealStatusField = ({ name, options, selectedLanguage, disabled }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DealFormSelectField
          native
          value={value}
          disabled={disabled}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          size="small"
        >
          {createOptions(options, selectedLanguage)}
        </DealFormSelectField>
      )}
    />
  );
};

export default DealStatusField;
