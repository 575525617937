import { styled } from '@mui/system';
import {
  Box,
  FormHelperText,
  Select,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import {
  FormButton,
  SaveButton,
} from '../../../components/buttons/FormButtons';
import TextEditor from '../../../components/RichTextEditor/TextEditor';

export const ArticlesTabWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const ArticlesTableHead = styled(TableHead)`
  .MuiTableCell-root {
    :nth-of-type(1) {
      width: 50%;
    }
    :nth-of-type(2) {
      width: 50%;
    }
  }
`;

export const ArticlesTableHeadRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ArticlesTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  height: 57px;
  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    cursor: pointer;
    background-color: #ffa94c;
  }
`;

export const ArticlesFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 20px;
  align-items: center;
  width: 100%;
`;

export const ArticlesFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const ArticlesFormStyledTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9) !important;
  }
  textarea {
    margin: 8.5px 14px;
  }
`;

export const ArticlesFormStyledOrderTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9) !important;
  }
  textarea {
    margin: 8.5px 14px;
  }
  max-width: 215px;
`;

export const ControlPanelContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const ArticlesFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const ArticlesFormButton = styled(FormButton)`
  max-width: fit-content;
`;

export const LoaderWrapper = styled(Box)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArticlesFormTextEditor = styled(TextEditor)`
  width: 100%;
  overflow-y: auto;
  max-height: 600px;
`;

export const ArticlesFormSelectField = styled(Select)`
  max-width: 215px;
  width: 100%;
  ${
    '' /* .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  } */
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  color: #d43131;
  margin-left: 0px;
  margin-top: 0;
  position: absolute;
`;

export const ArticlesFormImageUploadBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
