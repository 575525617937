export const getAllowedUserAccounts = (users, currentAttraction) => {
  const usersFilteredByCompany = users.filter(
    (user) => user.idCompany === parseInt(currentAttraction.companyId)
  );

  const usersFilteredByRole = usersFilteredByCompany.filter((user) => {
    if (user.role === 'AttractionManager' && user.isAllAttractions) return true;

    const isUserHasAttraction = user.attractions.find(
      (attr) => attr.idAttraction === currentAttraction.id
    );

    if (isUserHasAttraction) return true;

    return false;
  });

  return usersFilteredByRole;
};
