import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const TabChangeAlert = (props) => {
  const { onCancel, onSubmit, show } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={show}
      onClose={onCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t('attractions.tab_change_alert.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('attractions.tab_change_alert.text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          {t('attractions.tab_change_alert.cancel')}
        </Button>
        <Button onClick={onSubmit} autoFocus>
          {t('attractions.tab_change_alert.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TabChangeAlert;
