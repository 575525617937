import { Controller, useFormContext } from 'react-hook-form';

import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Chip,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from '@mui/material';

import {
  NotificationFormSelectFieldInput,
  SelectHelperText,
  StyledFormControl,
} from '../styled';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const createOptions = (
  optionsData,
  selectedDestinationId,
  selectedLanguage
) => {
  return optionsData.map((item) => (
    <MenuItem value={item.id} key={item.id}>
      {item.name}
    </MenuItem>
  ));
};

const NotificationMultiselectField = ({
  name,
  required,
  disabled,
  optionsData,
  selectedLanguage,
  label,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) =>
          required && !value.length ? '* Required' : undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StyledFormControl error={!!error} fullWidth disabled={disabled}>
          <InputLabel disabled={disabled}>{label}</InputLabel>
          <NotificationFormSelectFieldInput
            disabled={disabled}
            multiple
            onChange={(e) => onChange(e.target.value)}
            value={value}
            input={<OutlinedInput label={label} />}
            size="small"
            MenuProps={MenuProps}
            renderValue={(selectedValues) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedValues.map((selectedValue) =>
                  optionsData.map(
                    (option) =>
                      option.id === selectedValue && (
                        <Chip
                          key={selectedValue}
                          label={option.name}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => {
                            const newValue = value.filter(
                              (category) => category !== selectedValue
                            );

                            onChange(newValue);
                          }}
                          deleteIcon={
                            <IconButton
                              disabled={disabled}
                              sx={{
                                padding: 0,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          }
                        />
                      )
                  )
                )}
              </Box>
            )}
          >
            {!!optionsData.length &&
              createOptions(optionsData, selectedLanguage)}
          </NotificationFormSelectFieldInput>

          <SelectHelperText>{error?.message}</SelectHelperText>
        </StyledFormControl>
      )}
    />
  );
};

export default NotificationMultiselectField;
