import { styled } from '@mui/system';
import { Box, Typography, Button, FormHelperText } from '@mui/material';
import { TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/lab';

import {
  ReduxTextField,
  ReduxSelect,
} from '../../../components/reduxFormComponents/reduxFormComponents';
import { ReduxAutocomplete } from '../../../components/notificationComponents/AutocompleteInput';

import {
  FormButton,
  SaveButton,
} from '../../../components/buttons/FormButtons';

export const ReviewFormDateFieldWrapper = styled(Box)`
  max-width: 215px;
  width: 100%;
`;

export const ReviewFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ReviewFormHeaderLabel = styled(Box)`
  display: flex;
  width: 100%;
`;

export const ReviewFormHeader = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const ReviewFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;

export const ReviewFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
  width: 100%;
`;

export const ReviewFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const ReviewFormTextField = styled(ReduxTextField)`
  width: 100%;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }

  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.35);
  }
`;

export const ReviewFormSelectField = styled(ReduxSelect)`
  max-width: 215px;
  width: 100%;
  margin-left: 0;
  margin-top: 0;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.35) !important;
  }
`;

export const ReviewFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const ReviewFormButton = styled(FormButton)`
  max-width: fit-content;
`;

export const ReviewsTabWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ReviewsFilterWrapper = styled(Box)`
  display: flex;
  alignitems: center;
  margin-bottom: 20px;
  margin-top: 30px;
`;

export const LoaderWrapper = styled(Box)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReviewAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const ReviewStatisticWrapper = styled(Box)`
  margin: 20px 0;
`;

export const ReviewFormDatePicker = styled(MobileDatePicker)`
  max-width: 215px;
  width: 100%;
`;

export const ReviewDatePickerTextField = styled(TextField)`
  & .MuiOutlinedInput-input {
    cursor: pointer;
    max-width: 169px;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  color: #d43131;
  position: absolute;
  margin-top: 0;
`;

export const ReviewFormAutocomplete = styled(ReduxAutocomplete)`
  max-width: 400px;
  width: 100%;
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
  .MuiChip-root.Mui-disabled {
    opacity: 0.9;
    pointer-events: none;
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;
