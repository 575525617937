import { stopSubmit } from 'redux-form';
import { getPredefinedSettingsApi } from '../../api/settingsApi.js';
const SET_PREDEFINED_SETTINGS = 'SET_PREDEFINED_SETTINGS';
const IS_LOADING_PREDEFINED_DATA = 'IS_LOADING_PREDEFINED_DATA';

const initialState = {
  predefinedData: [],
  isLoadingPredefinedData: false,
};

const predefinedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREDEFINED_SETTINGS:
      return {
        ...state,
        predefinedData: [...action.payload.data],
      };
    case IS_LOADING_PREDEFINED_DATA:
      return {
        ...state,
        isLoadingPredefinedData: action.isLoadingPredefinedData,
      };
    default:
      return state;
  }
};

const setPredefinedSettings = (data) => {
  return { type: SET_PREDEFINED_SETTINGS, payload: { data } };
};
const setIsLoadingPredefinedData = (isLoadingPredefinedData) => {
  return { type: IS_LOADING_PREDEFINED_DATA, isLoadingPredefinedData };
};

export const getPredefinedSettingsThunk = () => async (dispatch) => {
  dispatch(setIsLoadingPredefinedData(true));
  const response = await getPredefinedSettingsApi();
  if (response.status >= 200 && response.status < 300) {
    dispatch(setPredefinedSettings(response.data));
    dispatch(setIsLoadingPredefinedData(false));
  }
};

export default predefinedReducer;
