import { SET_REVIEWS_LIST, IS_REVIEW_LIST_LOADING } from './reviewActions';

const initialState = {
  reviewData: [],
  isLoading: false,
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REVIEWS_LIST:
      return {
        ...state,
        reviewData: [...action.payload.data],
      };
    case IS_REVIEW_LIST_LOADING:
      return {
        ...state,
        isLoading: action.payload.data,
      };
    default:
      return state;
  }
};

export default reviewReducer;
