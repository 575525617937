import { Controller, useFormContext } from 'react-hook-form';

import { SetupFormSelectFieldInput } from '../styled';

const createCompanyOptionElement = (optionsData) => {
  return optionsData.map((item) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));
};

const SetupFormCompanySelectField = ({
  disabled,
  optionsData,
  name,
  emptyOption,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <SetupFormSelectFieldInput
          native
          value={value}
          disabled={disabled}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          size="small"
        >
          {emptyOption && (
            <option value={emptyOption.value} id={emptyOption.id}>
              {emptyOption.label}
            </option>
          )}
          {!!optionsData.length && createCompanyOptionElement(optionsData)}
        </SetupFormSelectFieldInput>
      )}
    />
  );
};

export default SetupFormCompanySelectField;
