import React, { useState, useRef, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Chip,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  SelectHelperText,
  SetupFormSelectFieldInput,
  StyledFormControl,
} from '../styled';
import { useTranslation } from 'react-i18next';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const createOption = (optionsData, selectedLanguage, hasAttractionsList) => {
  return optionsData.map((item) => (
    <MenuItem value={item.id} key={item.id}>
      <Typography variant="inherit" noWrap>
        {hasAttractionsList
          ? item.name
          : item.name[selectedLanguage] || item.name.en}
      </Typography>
    </MenuItem>
  ));
};

const SetupFormCommonMultiselectField = ({
  name,
  disabled,
  optionsData,
  selectedLanguage,
  label,
  hasAttractionsList,
  enableSearch,
}) => {
  const { control } = useFormContext();
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef(null);
  const { t } = useTranslation();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchTerm]);
  
  const filteredOptions = optionsData.filter((option) => {
    const search = searchTerm.trim().toLowerCase();
    if (hasAttractionsList) {
      return option.name && option.name.toLowerCase().includes(search);
    } else {
      const optionName = option.name[selectedLanguage] || option.name.en;
      return optionName && optionName.toLowerCase().includes(search);
    }
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <StyledFormControl error={!!error} fullWidth disabled={disabled}>
          <InputLabel disabled={disabled}>{label}</InputLabel>
          <SetupFormSelectFieldInput
            disabled={disabled}
            multiple
            onChange={(e) => onChange(e.target.value)}
            value={value}
            input={<OutlinedInput label={label} />}
            size="small"
            MenuProps={MenuProps}
            renderValue={(selectedValues) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedValues.map((selectedValue) =>
                  optionsData.map(
                    (option) =>
                      option.id === selectedValue && (
                        <Chip
                          key={selectedValue}
                          label={
                            hasAttractionsList
                              ? option.name
                              : option.name[selectedLanguage] || option.name.en
                          }
                          onDelete={(event) => {
                            event.stopPropagation(); // Prevent default behavior
                            const newValue = value.filter(
                              (category) => category !== selectedValue
                            );
                            onChange(newValue);
                          }}
                          deleteIcon={
                            <IconButton
                              disabled={disabled}
                              sx={{
                                padding: 0,
                              }}
                              onMouseDown={(event) => {
                                event.stopPropagation(); // Prevent opening the dropdown
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          }
                        />
                      )
                  )
                )}
              </Box>
            )}
          >
            {
              enableSearch && (
                <Box sx={{ padding: 1 }}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={t('attractions.attractions_search.search')}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={(event) => event.stopPropagation()}
                    autoFocus={true}
                    inputRef={searchInputRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={handleClearSearch}
                          >
                            <CancelIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )
            }
            
            {!!filteredOptions.length &&
              createOption(filteredOptions, selectedLanguage, hasAttractionsList)}
          </SetupFormSelectFieldInput>

          <SelectHelperText>{error?.message}</SelectHelperText>
        </StyledFormControl>
      )}
    />
  );
};

export default SetupFormCommonMultiselectField;
