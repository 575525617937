import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableCell } from '@mui/material';

import {
    AttractionReportTableHead,
    AttractionReportTableRow,
    AttractionReportTableRowTotal,
} from '../styled';

const ReportSummaryTable = ({
    dealsData,
    benefitOptions,
    selectedLanguage,
}) => {
    const { t } = useTranslation();

    const tableHeaderLables = t('attractions.reports.summaryTableLabels', {
        returnObjects: true,
    });

    const visitsTotal = dealsData.reduce(
        (acc, current) => acc + Number(current.numberOfVisits),
        0
    );

    const getTranslatedBenefit = (benefit) => {
        return (
            benefitOptions &&
            benefitOptions.parameters.find(
                (benefitItem) => benefitItem.id === benefit
            ).value[selectedLanguage]
        );
    };

    return (
        <Table>
            <AttractionReportTableHead>
                {tableHeaderLables.map((label, idx) => (
                    <TableCell key={idx}>{label}</TableCell>
                ))}
            </AttractionReportTableHead>

            <TableBody>
                {dealsData.map((deal, idx) => (
                    <AttractionReportTableRow key={idx}>
                        <TableCell>{deal.number}</TableCell>
                        <TableCell>
                            {deal.title[selectedLanguage] || deal.title.en}
                        </TableCell>
                        <TableCell>{getTranslatedBenefit(deal.benefit)}</TableCell>
                        <TableCell>{deal.numberOfVisits}</TableCell>
                    </AttractionReportTableRow>
                ))}

                <AttractionReportTableRowTotal>
                    <TableCell>{t('attractions.reports.summaryTotalText')}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{visitsTotal}</TableCell>
                </AttractionReportTableRowTotal>
            </TableBody>
        </Table>
    );
};

export default ReportSummaryTable;
