import { Box, Select, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { CheckboxDisabledInput, CheckboxInput } from './FormComponents';

export const CategoryFaqFormWrapper = styled(Box)`
  margin-top: 40px;
`;

export const StyledSelect = styled(Select)`
  max-width: 300px;
`;

export const CategoriesFaqFilterWrapper = styled(Box)`
  display: flex;
  gap: 8px;
`;

export const CategoriesFaqTableHead = styled(TableHead)`
  .MuiTableCell-root {
    :nth-of-type(1) {
      width: 15%;
    }
    :nth-of-type(2) {
      width: 10%;
    }
    :nth-of-type(3) {
      width: 50%;
    }
  }
`;
export const CategoriesFaqTableRow = styled(TableRow)`
  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
`;

export const StyledCheckboxDisabledInput = styled(CheckboxDisabledInput)`
  white-space: nowrap;
  width: 100%;

  .MuiFormControlLabel-label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  white-space: nowrap;
  width: 100%;

  .MuiFormControlLabel-label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
