import * as axios from 'axios';
import authService from '../auth/AuthorizeService';
import { BASE_URL } from '../config.js';

export const getSalesPointListApi = async ({
  destinationId,
  pageNumber,
  pageSize,
  search,
  status,
  category,
  region,
  area,
  location,
  sort,
}) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/SalesPoints?destinationId=${destinationId}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&status=${status}&category=${category}&region=${region}&area=${area}&location=${location}&sort=${sort}`;

  return await axios
    .get(targetUrl, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getSalesPointShortDataListApi = async (destinationId) => {
  const token = await authService.getAccessToken();
  return await axios
    .get(`${BASE_URL}/api/SalesPoints/Search?destinationId=${destinationId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getSalesPointsSearchApi = async (
  destinationId,
  pageNumber,
  pageSize,
  attractions,
  status,
  category,
  region,
  area,
  location,
  sort
) => {
  const token = await authService.getAccessToken();
  let url = `${BASE_URL}/api/SalesPoints?destinationId=${destinationId}&pageNumber=${pageNumber}&pageSize=${pageSize}${
    attractions && `&search=${attractions}`
  }${status && `&status=${status}`}${category && `&category=${category}`}${
    region && `&region=${region}`
  }${area && `&area=${area}`}${location && `&location=${location}`}${
    sort && `&sort=${sort}`
  }
    `;
  return await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const createSalesPointApi = async (data) => {
  const token = await authService.getAccessToken();
  return await axios
    .post(`${BASE_URL}/api/SalesPoints`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const getSalesPointApi = async (pointId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/SalesPoints/${pointId}`;

  return await axios
    .get(targetUrl, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateSalesPointApi = async (id, data) => {
  const token = await authService.getAccessToken();
  return await axios
    .put(`${BASE_URL}/api/SalesPoints/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteSalesPointApi = async (id) => {
  const token = await authService.getAccessToken();
  return await axios
    .delete(`${BASE_URL}/api/SalesPoints/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
