import { useTranslation } from 'react-i18next';

import { Box, Typography, CircularProgress, Button } from '@mui/material';

import { ArticlesTable, ArticlesRow } from './articlesTable';
import ArticlesFilterForm from './articlesForms/ArticlesFilterForm';

import { ArticlesTabWrapper, LoaderWrapper } from './styled';

import { SORTING_OPTIONS } from './constants';

const ArticlesTab = (props) => {
  const {
    activeRowId,
    onRowExpand,
    languageList,
    articleList,
    isLoading,
    resetActiveRow,
    typeValue,
    setTypeValue,
    selectedLanguage,
    handleAddArticle,
    articleTypeList,
    resetFilters,
  } = props;

  const { t } = useTranslation();

  return (
    <ArticlesTabWrapper>
      <Box mb="10px">
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('articles.headline')}
        </Typography>
      </Box>
      <Box>
        <Button
          sx={{
            color: '#ffffff',
            maxWidth: 'fit-content',
            marginBottom: '10px',
          }}
          onClick={handleAddArticle}
          variant="contained"
          disableRipple
        >
          {t('articles.add_new_article')}
        </Button>
      </Box>

      <ArticlesFilterForm
        articleTypeListOptions={articleTypeList}
        sortingOptions={SORTING_OPTIONS}
        selectedLanguage={selectedLanguage}
        typeValue={typeValue}
        setTypeValue={setTypeValue}
      />

      {!isLoading && (
        <ArticlesTable>
          {articleList.map((article, i) => (
            <ArticlesRow
              isExpanded={i === activeRowId}
              onClick={() => onRowExpand(i)}
              languageList={languageList}
              item={article}
              resetActiveRow={resetActiveRow}
              resetFilters={resetFilters}
              key={i}
            />
          ))}
        </ArticlesTable>
      )}

      {isLoading && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </ArticlesTabWrapper>
  );
};

export default ArticlesTab;
