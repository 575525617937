import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TodayIcon from '@mui/icons-material/Today';

import {
  RedeemCodeFormDateField,
  RedeemCodeFormDateTextField,
} from '../../styled';

const useStyles = makeStyles({
  dateFieldInputDisabled: {
    '& .MuiOutlinedInput-input': {
      cursor: 'default',
    },

    '& .MuiInputAdornment-root': {
      cursor: 'default',
    },
  },
});

const RedeemCodeDateField = ({ name, disabled, rules, minDate }) => {
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RedeemCodeFormDateField
            ampm={false}
            inputFormat="dd/MM/yyyy HH:mm"
            onChange={(event) => onChange(event)}
            value={value}
            disabled={disabled}
            showToolbar={false}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onAccept={() => setIsOpen(false)}
            onClose={() => setIsOpen(false)}
            minDate={minDate}
            cancelText={t('cancel')}
            okText={t('ok')}
            renderInput={(params) => (
              <RedeemCodeFormDateTextField
                size="small"
                placeholder="dd/mm/yyyy"
                {...params}
                className={clsx(disabled && classes.dateFieldInputDisabled)}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      disablePointerEvents={disabled}
                      position="start"
                      onClick={() => setIsOpen(true)}
                    >
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default RedeemCodeDateField;
