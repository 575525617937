import { styled } from '@mui/system';
import { MobileDatePicker } from '@mui/lab';
import {
  Box,
  Typography,
  Button,
  TableHead,
  TableRow,
  TextField,
  FormHelperText,
} from '@mui/material';

import {
  ReduxTextField,
  CheckboxInput,
  ReduxSelect,
} from '../../../components/reduxFormComponents/reduxFormComponents';
import {
  FormButton,
  SaveButton,
} from '../../../components/buttons/FormButtons';

export const LoaderWrapper = styled(Box)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PricesTabContainer = styled(Box)`
  width: 100%;
  margin-top: 45px;
  position: relative;
`;

export const PriceAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const PricesTabHeader = styled(Box)`
  margin: 30px 0;
  text-decoration: none;
`;

export const PricesFormHeader = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const StyledPricesForm = styled('form')`
  width: 900px;
  margin-top: 30px;

  & .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 0;
  }
  & .MuiOutlinedInput-input {
    &.Mui-disabled {
      text-fill-color: rgba(0, 0, 0, 0.7);
    }
    &::placeholder {
      text-fill-color: rgba(0, 0, 0, 0.7);
    }
  }
  & .MuiInput-input {
    &::placeholder {
      color: #000;
      opacity: 0.6;
    }
  }
`;

export const PricesFormControlPanel = styled(Box)`
  margin-bottom: 30px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const PricesFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const PricesFormButton = styled(FormButton)`
  max-width: fit-content;
`;

export const PricesFormFieldWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
`;

export const PricesFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const CurrencyFieldContainer = styled(Box)`
  margin-top: 70px;
`;

export const PricesTableHead = styled(TableHead)`
  .MuiTableCell-root {
    :nth-of-type(n) {
      text-align: center;
    }
    :nth-of-type(1) {
      width: 20%;
    }
    :nth-of-type(n + 2) {
      width: 10%;
    }
  }
  td:last-child {
    text-align: center;
  }
`;
export const PricesTableHeadRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    justify-content: center;
    align-items: center;
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
`;
export const PricesTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    padding: 0;
  }

  td:nth-of-type(6),
  td:nth-of-type(7) {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
`;
export const PricesFormTableTextField = styled(ReduxTextField)`
  &.MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0) !important;
    border: none;
  }
  & .MuiOutlinedInput-root {
    padding-right: 0;
  }
  & .MuiInputBase-root-MuiOutlinedInput-root {
    padding-right: 0;
  }
`;

export const PricesFormFieldInputContainer = styled(Box)`
  max-width: 200px;
  width: 100%;
`;

export const PricesFormCheckbox = styled(CheckboxInput)`
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
  margin-left: 8px;
`;

export const ErrorMessageContainer = styled(Typography)`
  color: #d43131;
`;

export const PriceFormVatSelect = styled(ReduxSelect)`
  &.MuiFormHelperText-root {
    margin-left: 0;
  }
`;

export const PricesFormDatePicker = styled(MobileDatePicker)`
  max-width: 200px;
  width: 100%;
`;

export const PricesDatePickerTextField = styled(TextField)`
  & .MuiOutlinedInput-input {
    cursor: pointer;
    max-width: 169px;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  color: #d43131;
  position: absolute;
  margin-top: 0;
`;

export const PricesTabTitleWrapper = styled(Box)`
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 500;
`;
