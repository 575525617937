import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import authService from "../../auth/AuthorizeService";
import { ApplicationPaths } from "../../auth/ApiAuthorizationConstants";
import { makeStyles } from "@mui/styles";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    link: {
        textDecoration: "none",
        color: "inherit",
        padding: '6px 16px',
        width: '100%'

    },
});

const LoginMenu = () => {
    const classes = useStyles();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        const subscription = authService.subscribe(() => populateState());
        populateState();
        return () => authService.unsubscribe(subscription);
    }, []);

    const populateState = async () => {
        const [isAuth, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
        setIsAuthenticated(isAuth);
    };

    if (isAuthenticated) {
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        return (
            <Link to={logoutPath} className={classes.link}>
                <span>{t('top_bar.logout')}</span>
            </Link>
        );
    } else {
        return null;
    }
};

export default LoginMenu;
