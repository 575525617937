import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import ManagedAttractionsForm from './ManagedAttractionsForm';
import CompanyForm from './CompanyForm';

import { StyledIconButton, StyledClearIcon } from './styled';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import {
  editCompanyThunk,
  deleteCompanyThunk,
  getCurrentCompanyThunk,
  getAttractionListOfCompaniesThunk,
} from '../../../../redux/usersReducers/company/companyActions';
import { getUserSettingsThunk } from '../../../../redux/usersReducers/users/userActions';
import { getSmallAttractionsDataThunk } from '../../../../redux/attractionsReducers/attractions/attractionsActions';

import { USER_STATUS } from '../../../../consts/consts';
import { COMPANY_FORM, MANAGED_ATTRACTIONS_FORM, MANAGER_ROLE_LIST } from '../constants';
import {
  filledEmptyFields,
  mapAttractionsToAttractionContacts,
  excludeAttractionNames,
} from '../utils';

const CompanyEditFormContainer = (props) => {
  const {
    statusOptions,
    selectedLanguage,
    editCompanyThunk,
    selectedDestinationId,
    deleteCompanyThunk,
    getUserSettingsThunk,
    getCurrentCompanyThunk,
    getAttractionListOfCompaniesThunk,
    companyData,
    attractionListOfCompanies,
  } = props;
  
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addError } = useAPIError();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [isCompanyFormInEditMode, setIsCompanyFormInEditMode] = useState(false);
  const [
    isManagedAttractionsFormInEditMode,
    setIsManagedAttractionsFormInEditMode,
  ] = useState(false);

  const companyAttractionsList = attractionListOfCompanies.find(
    (company) => company.id.toString() === companyId
  );

  const resetForm = (formName) => {
    dispatch(reset(formName));
  };

  const handleCompanyFormSubmit = (formData) => {
    setIsCompanyFormInEditMode(false);
    setShowSaveAnimation(true);
    
    const updatedCompanyData = {
      id: companyData.id,
      status: formData.status,
      name: formData.name,
      website: formData.website,
      streetAddress: formData.streetAddress,
      city: formData.city,
      zipCode: parseInt(formData.zipCode) || null,
      country: formData.country,
      registrationNumber: parseInt(formData.registrationNumber) || null,
      valueAddedTaxNumber: formData.valueAddedTaxNumber,
      personContacts: filledEmptyFields(formData.personContacts),
      billingContacts: filledEmptyFields(formData.billingContacts),
      bankAccount: formData.bankAccount,
      attractionContacts: companyData.attractionContacts,
    };

    editCompanyThunk(updatedCompanyData.id, updatedCompanyData)
      .then(() => {
        getCurrentCompanyThunk(companyId);
        setShowSaveAnimation(false);
        addError(`${t('companies.success_edit_company')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('companies.error_edit_company')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleManagedAttractionsFormSubmit = (formData) => {
    setIsManagedAttractionsFormInEditMode(false);
    setShowSaveAnimation(true);

    const updatedCompanyData = {
      ...companyData,
      attractionContacts: excludeAttractionNames(formData.attractionContacts),
    };

    editCompanyThunk(updatedCompanyData.id, updatedCompanyData)
      .then(() => {
        getCurrentCompanyThunk(companyId);
        setShowSaveAnimation(false);
        addError(`${t('companies.success_edit_company')}`, 'Success');
      })
      .catch((error) => {
        addError(`${t('companies.error_edit_company')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleFormDelete = (setShowDeleteAccAlert) => {
    setIsCompanyFormInEditMode(false);

    deleteCompanyThunk(companyData.id)
      .then(() => {
        setShowSaveAnimation(false);
        addError(`${t('companies.success_delete_company')}`, 'Success');
        navigate('/users/companies');
      })
      .catch((error) => {
        addError(`${t('companies.error_delete_company')}`, 'Error', error);
        setShowSaveAnimation(false);
        setShowDeleteAccAlert(false);
      });
  };

  const handleReturnButtonClick = () => {
    navigate(-1);
  };

  const handleCompanyFormEdit = () => {
    setIsCompanyFormInEditMode(true);
  };

  const handleCompanyFormCancel = () => {
    setIsCompanyFormInEditMode(false);
    resetForm(COMPANY_FORM);
  };

  const handleManagedAttractionsFormEdit = () => {
    setIsManagedAttractionsFormInEditMode(true);
  };

  const handleManagedAttractionsFormCancel = () => {
    setIsManagedAttractionsFormInEditMode(false);
    resetForm(MANAGED_ATTRACTIONS_FORM);
  };

  useEffect(() => {
    if (companyId) getCurrentCompanyThunk(companyId);
    getUserSettingsThunk();
    if(selectedDestinationId) getAttractionListOfCompaniesThunk(selectedDestinationId);
  }, [
    getUserSettingsThunk,
    selectedDestinationId,
    getCurrentCompanyThunk,
    companyId,
    getAttractionListOfCompaniesThunk,
  ]);

  const mappedAttractionContacts = useMemo(
    () =>
      mapAttractionsToAttractionContacts(
        [
          ...(companyAttractionsList?.attractions || []),
          ...(companyAttractionsList?.salesPoints || []),
        ],
        companyData?.attractionContacts
      ),
    [companyAttractionsList, companyData?.attractionContacts]
  );

  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);

  return (
    <>
      {companyData && statusOptions && companyAttractionsList && (
        <>
          {!isManagerRoleSelected &&
            <>
              <Box display="flex" justifyContent="flex-end">
                <StyledIconButton onClick={handleReturnButtonClick}>
                  <StyledClearIcon />
                </StyledIconButton>
              </Box>
            </>
          }
          <CompanyForm
            item={companyData}
            onSubmit={handleCompanyFormSubmit}
            onFormDelete={handleFormDelete}
            onFormCancel={handleCompanyFormCancel}
            onFormEdit={handleCompanyFormEdit}
            isInEditMode={isCompanyFormInEditMode}
            showSaveAnimation={showSaveAnimation}
            statusOptions={statusOptions}
            selectedLanguage={selectedLanguage}
          />
          {!isManagerRoleSelected && (
            <ManagedAttractionsForm
              onSave={handleManagedAttractionsFormSubmit}
              onFormCancel={handleManagedAttractionsFormCancel}
              onFormEdit={handleManagedAttractionsFormEdit}
              isInEditMode={isManagedAttractionsFormInEditMode}
              companyData={companyData}
              attractionsData={companyAttractionsList.attractions}
              showSaveAnimation={showSaveAnimation}
              mappedAttractionContacts={mappedAttractionContacts}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const statusOptions = state.userReducer.userSettings.find(
    (item) => item.name.en === USER_STATUS
  );
  
  return {
    companyData: state.companyReducer.currentCompany,
    attractionListOfCompanies: state.companyReducer.attractionListOfCompanies,
    companyList: state.companyReducer.companiesData,
    statusOptions,
    userSettings: state.userReducer.userSettings,
    availableAttractions: state.attractionsReducer.attractionsSmallData,
    globalSettings: state.appReducer.globalSettingsData,
    selectedDestinationId: state.appReducer.selectedDestination.id,
    settingsReducer: state.settingsReducer,
    selectedLanguage: state.appReducer.selectedLanguage,
    userReducer: state.userReducer,
    attractionsData: state.attractionsReducer.attractionsSmallData,
  };
};

export default connect(mapStateToProps, {
  editCompanyThunk,
  getAttractionListThunk: getSmallAttractionsDataThunk,
  deleteCompanyThunk,
  getUserSettingsThunk,
  getCurrentCompanyThunk,
  getAttractionListOfCompaniesThunk,
})(CompanyEditFormContainer);
