import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Tabs, Tab, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getOpenedTabIdByUrlParameter, settingsTabs } from './settingsTabs';

import { a11yProps } from '../../components/tabPanel/TabPanel';
import { setTitle } from '../../redux/appReducer';

const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
  },
});

const SettingsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    getOpenedTabIdByUrlParameter(settingsTabs, location.pathname)
  );

  const handleChangeTab = (newValue) => {
    setSelectedTabIndex(newValue);
  };

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_settings')));
    handleChangeTab(
      getOpenedTabIdByUrlParameter(settingsTabs, location.pathname)
    );
  }, [location.pathname]);

  return (
    <Box maxWidth="1300px" width="100%" m="95px auto">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTabIndex}
          className={classes.tabs}
          aria-label="basic tabs example"
          indicatorColor="primary"
          textColor="inherit"
        >
          {settingsTabs.map((tab, index) => (
            <Tab
              label={t(tab.name)}
              sx={{ width: '17%' }}
              component={Link}
              to={tab.path}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default SettingsPage;
