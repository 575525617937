export const SET_TARGET_LOCATION = 'routeChange/setTargetLocation';
export const SET_SHOW_UNSAVED_CHANGES_ALERT =
  'routeChange/setShowUnsavedChangesAlert';
export const SET_IS_ROUTE_CHANGE_CONFIRMED =
  'routeChange/setIsRouteChangeConfirmed';

export const setTargetLocation = (data) => {
  return { type: SET_TARGET_LOCATION, payload: { data } };
};
export const setShowUnsavedChangesAlert = (data) => {
  return { type: SET_SHOW_UNSAVED_CHANGES_ALERT, payload: { data } };
};
export const setIsRouteChangeConfirmed = (data) => {
  return { type: SET_IS_ROUTE_CHANGE_CONFIRMED, payload: { data } };
};
