import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
  PredefinedContainer,
  PredefinedBox,
  PredefinedTitleWrapper,
  PredefinedTitleText,
  PredefinedFieldWrapper,
  PredefinedFieldText,
  LoaderWrapper,
  PredefinedWrapper,
} from './styled';

const Predefined = (props) => {
  const {
    selectedLanguage,
    appReducer,
    getPredefinedSettingsThunk,
    predefinedReducer,
    userSettings,
    getUserSettingsThunk,
  } = props;

  useEffect(() => {
    getPredefinedSettingsThunk();
    getUserSettingsThunk();
  }, [getPredefinedSettingsThunk, getUserSettingsThunk]);

  const settingsList = [
    ...predefinedReducer.predefinedData,
    ...appReducer.globalSettingsData,
    ...userSettings,
  ];
  const isDataLoading = predefinedReducer.isLoadingPredefinedData;
  return (
    <PredefinedContainer>
      {isDataLoading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <PredefinedWrapper>
          {settingsList.map((elem, index) => (
            <PredefinedBox key={index}>
              <PredefinedTitleWrapper>
                <PredefinedTitleText>
                  {elem.name[selectedLanguage] || elem.name.en}
                </PredefinedTitleText>
              </PredefinedTitleWrapper>
              {elem.parameters.map((item) =>
                item.value.en ? (
                  <PredefinedFieldWrapper key={item.id}>
                    <PredefinedFieldText>
                      {item.value.en
                        ? item.value[selectedLanguage]
                        : item.value}
                    </PredefinedFieldText>
                  </PredefinedFieldWrapper>
                ) : item.value.displayName ? (
                  <PredefinedFieldWrapper key={item.id}>
                    <PredefinedFieldText>
                      {item.value.displayName}
                    </PredefinedFieldText>
                  </PredefinedFieldWrapper>
                ) : (
                  <PredefinedFieldWrapper key={item.id}>
                    <PredefinedFieldText>{item.value}</PredefinedFieldText>
                  </PredefinedFieldWrapper>
                )
              )}
            </PredefinedBox>
          ))}
        </PredefinedWrapper>
      )}
    </PredefinedContainer>
  );
};

export default Predefined;
