import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  TablePagination,
  Box,
} from '@mui/material';

import Deal from './Deal';

import cl from './styles/DealsTable.module.scss';
import { TABLE_PAGINATION_OPTIONS } from '../consts';

const DealsTable = (props) => {
  const { t } = useTranslation();
  const { items, tableSettings, onPageSizeChange, onPageNumberChange } = props;

  const [expandedId, setExpandedId] = useState(null);

    const TABLE_PAGINATION_OPTIONS = [
        { label: t('all_pagination_option'), value: 1000 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
    ];

  const handlePanelExpandChange = (panelId) => (_, isExpanded) => {
    setExpandedId(isExpanded ? panelId : false);
  };

  const CreateTableHead = () => (
    <TableHead>
      <TableRow className={cl.tableRow}>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.status')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.number')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.benefit')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.title')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.start_date')}
        </TableCell>
        <TableCell className={cl.tableCell}>
          {t('attractions.deals.end_date')}
        </TableCell>
        <TableCell className={cl.tableCell} align="center">
          {t('attractions.deals.view')}
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const createTableItems = (items) => {
    return items.map((item, index) => (
      <Deal
        item={item}
        key={index}
        expanded={expandedId === item.id}
        onExpandChange={() => handlePanelExpandChange(item.id)}
      />
    ));
  };
  return (
    <Box>
      <TablePagination
        rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
        onRowsPerPageChange={onPageSizeChange}
        component="div"
        count={tableSettings.totalItems}
        page={tableSettings.pageNumber}
        onPageChange={onPageNumberChange}
        rowsPerPage={tableSettings.pageSize}
        labelRowsPerPage={t('attractions.deals.rows_per_page')}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} ${t('attractions.deals.of')} ${
            count !== -1 ? count : `more than ${to}`
          }`;
        }}
      />
      <TableContainer
        component={Paper}
        square
        elevation={0}
        className={cl.tableContainer}
      >
        <Table className={cl.table}>
          <CreateTableHead />
          <TableBody>{items && createTableItems(items)}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
        onRowsPerPageChange={onPageSizeChange}
        component="div"
        count={tableSettings.totalItems}
        page={tableSettings.pageNumber}
        onPageChange={onPageNumberChange}
        rowsPerPage={tableSettings.pageSize}
        labelRowsPerPage={t('attractions.deals.rows_per_page')}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} ${t('attractions.deals.of')} ${
            count !== -1 ? count : `more than ${to}`
          }`;
        }}
      />
    </Box>
  );
};

export default DealsTable;
