import { useTranslation } from 'react-i18next';

import { TableCell, TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { StyledVisitsTableHead, StyledTableHeadRow } from '../styled';

import { capitalize } from '../../../helpers/capitalize';

import { TABLE_HEADER_LABELS } from './constants';

const PointsTableHeader = ({
  onSort,
  value,
  status,
  showRegionColumn,
  showAreaColumn,
  showLocationColumn,
}) => {
  const { t } = useTranslation();

  const selectedValue = value !== null ? value.toLowerCase() : null;

  return (
    <StyledVisitsTableHead>
      <StyledTableHeadRow>
        {TABLE_HEADER_LABELS.map((item, idx) => (
          <>
            {(item.id === 'region' && showRegionColumn) ||
            (item.id === 'area' && showAreaColumn) ||
            (item.id === 'location' && showLocationColumn) ||
            !['region', 'area', 'location'].includes(item.id) ? (
              <TableCell padding="normal" key={idx}>
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={{ width: item.id === 'name' ? 'fit-content' : '100%' }}
                  >
                    {t(item.label)}
                  </Box>
                  {item.isShowStatus && (
                    <TableSortLabel
                      active={item.id === selectedValue}
                      aligh="right"
                      direction={
                        item.id === selectedValue
                          ? status === 'Desc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      }
                      onClick={() => {
                        item.isShowStatus && onSort(capitalize(item.id));
                      }}
                    >
                      {item.isShowStatus ? (
                        <Box component="span" sx={visuallyHidden}>
                          {item.id === selectedValue && status === 'Desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </Box>
              </TableCell>
            ) : null}
          </>
        ))}
      </StyledTableHeadRow>
    </StyledVisitsTableHead>
  );
};

export default PointsTableHeader;
