import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';

export const HELP_TOPIC_TITLE_FIELD_MAX_LENGTH = DEFAULT_TEXT_FIELD_MAX_LENGTH;

export const HELP_TOPIC_EDITOR_TEXT_FIELD_MAX_LENGTH = 50000;
export const ARTICLES_TEXT_FIELD_ROWS = 15;
export const EMPTY_HTML_TAG_LENGTH = 8;
export const DEFAULT_STATUS_VALUE = 'Inactive';
export const DEFAULT_TYPE_VALUE = 'WhyJoin';

// export const ARTICLE_ORDER_FIELD_MAX_LENGTH = 10;
const COLORS = {
  blue: '#32D7F0',
  orange: '#FFA94C',
  grey: '#A0A0A0',
  white: '#FFF',
};

export const STATUS_COLORS = {
  Inactive: COLORS.blue,
  Active: COLORS.orange,
};
