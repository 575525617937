import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HelpTopicFormContainer from '../helpTopicsForms/HelpTopicFormContainer';

import { HelpTopicTableRow } from '../styled';
import { ACTIVITY_STATUS } from '../../../../consts/consts';
import { STATUS_COLORS } from '../constants';

const useStyles = makeStyles({
  activeRow: {
    backgroundColor: '#FFA94C',
  },
});

const HelpTopicsTableRow = (props) => {
  const {
    item,
    onClick,
    isExpanded,
    languageList,
    resetActiveRow,
    resetFilters,
    selectedLanguage,
  } = props;

  const classes = useStyles();

  const currentDestinationLanguage = useSelector((state) =>
      Object.keys(state.settingsReducer.currentDestination).length > 0 && state.settingsReducer.currentDestination.languages[0]
  );

  const activityStatuses = useSelector((state) =>
    state.appReducer?.globalSettingsData.find(
      (item) => item.name.en === ACTIVITY_STATUS
    )
  );

  const statusValueTranslations = activityStatuses?.parameters.find(
    (status) => status.id === item.status
  );

  return (
    <>
      <HelpTopicTableRow
        onClick={onClick}
        className={clsx({ [classes.activeRow]: isExpanded })}
      >
        <TableCell
          sx={{
            bgcolor: STATUS_COLORS[item.status],
          }}
        >
          {statusValueTranslations?.value[selectedLanguage] || null}
        </TableCell>
        <TableCell>
          {item.title[currentDestinationLanguage]}
        </TableCell>
        <TableCell>
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
      </HelpTopicTableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={3}>
            <HelpTopicFormContainer
              languageList={languageList}
              item={item}
              resetActiveRow={resetActiveRow}
              resetFilters={resetFilters}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default HelpTopicsTableRow;
