import * as axios from 'axios';
import authService from "../auth/AuthorizeService";
import { BASE_URL } from '../config.js'

export const addDealApi = async (attractionId, data) => {
    const token = await authService.getAccessToken();
    if(!attractionId) {
        console.warn("Deals api: attractionId parameter is required");
        return
    }
    const targetUrl = `${BASE_URL}/api/attractions/${attractionId}/deals`;

    return await axios.post(targetUrl, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

export const getDealsListApi = async ({attractionId, pageNumber, pageSize, status}) => {
    const token = await authService.getAccessToken();
    if(!attractionId) {
        console.warn("Deals api: attractionId parameter is required");
        return
    }
    
    let targetUrl = `${BASE_URL}/api/attractions/${attractionId}/deals`;

    if (pageNumber && pageNumber >= 0) {
        targetUrl += `?pageNumber=${pageNumber}`;
    } else {
        console.warn("Deals api: pageNumber query parameter is required");
        targetUrl += `?pageNumber=${0}`;
    }

    if (pageSize && pageSize > 0) {
        targetUrl += `&pageSize=${pageSize}`;
    } else {
        console.warn("Deals api: pageSize query parameter is required");
        targetUrl += `&pageSize=${1}`;
    }

    if (status) {
        targetUrl += `&status=${status}`;
    }

    return await axios.get(targetUrl, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

export const getAllDealsListApi = async ({destinationId, attractionId, pageNumber, pageSize, status}) => {
    const token = await authService.getAccessToken();
    let targetUrl = `${BASE_URL}/api/deals`;

    if (destinationId) {
        targetUrl += `?destinationId=${destinationId}`;
    }

    if (pageNumber && pageNumber >= 0) {
        targetUrl += `&pageNumber=${pageNumber}`;
    } else {
        console.warn("Deals api: pageNumber query parameter is required");
        targetUrl += `&pageNumber=${0}`;
    }

    if (pageSize && pageSize > 0) {
        targetUrl += `&pageSize=${pageSize}`;
    } else {
        console.warn("Deals api: pageSize query parameter is required");
        targetUrl += `&pageSize=${1}`;
    }

    if (status) {
        targetUrl += `&status=${status}`;
    }

    if (attractionId) {
        targetUrl += `&attractionId=${attractionId}`;
    }

    return await axios.get(targetUrl, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

export const editDealApi = async (dealId, data) => {
    const token = await authService.getAccessToken();
    if(!dealId) {
        console.warn("Deals api: missing parameters");
        return
    }
    
    const targetUrl = `${BASE_URL}/api/deals/${dealId}`;

    return await axios.put(targetUrl, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}
export const editDealTranslationApi = async (dealId, data) => {
    const token = await authService.getAccessToken();
    if(!dealId) {
        console.warn("Deals api: missing parameters");
        return
    }
    
    const targetUrl = `${BASE_URL}/api/deals-translation/${dealId}`;

    return await axios.put(targetUrl, data, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

export const deleteDealApi = async (dealId) => {
    const token = await authService.getAccessToken();
    if(!dealId) {
        console.warn("Deals api: missing parameters");
        return
    }
    const targetUrl = `${BASE_URL}/api/deals/${dealId}`;

    return await axios.delete(targetUrl, {
        headers: { 
            Authorization: `Bearer ${token}`,
        }
    }).catch(error =>{
        return error.response
    })
}

export const addDealApproveApi = async (id, data) => {
    const token = await authService.getAccessToken();
    return await axios
      .post(`${BASE_URL}/api/deals/${id}/request-approving`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        return error.response;
      });
  };