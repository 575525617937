import React, { useEffect } from 'react';
import clsx from 'clsx';

import {
  TextField,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormHelperText,
  Select,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  errorMessage: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-18px',
      marginLeft: 0,
    },
  },
});

export const HelperText = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return (
      <FormHelperText sx={{ color: '#d43131', marginLeft: '0px' }}>
        * {touched && error}
      </FormHelperText>
    );
  }
};

export const CheckboxInput = ({ input, label, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value}
          onChange={input.onChange}
          sx={{ padding: '4px 4px 4px 8px' }}
          {...props}
        />
      }
      label={label}
      {...props}
    />
  );
};

export const CheckboxDisabledInput = ({ input, label, ...props }) => {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            disabled={props.disabled}
            checked={props.isChecked}
            sx={{ padding: '4px 4px 4px 8px' }}
          />
        }
        label={label}
      />
    </div>
  );
};

export const RadioButton = ({ input, ...props }) => (
  <FormControl>
    <RadioGroup {...input} {...props}>
      <FormControlLabel
        value="id_czech Republic"
        control={<Radio />}
        label="Czech Republic"
      />
      <FormControlLabel
        value="id_costa_blanka"
        control={<Radio />}
        label="Costa Blanka"
      />
      <FormControlLabel value="id_prague" control={<Radio />} label="Prague" />
    </RadioGroup>
  </FormControl>
);

export const SelectInput = ({
  input,
  label,
  meta: { touched, error },
  children,
  disabled,
  disabledValue,
  ...props
}) => {
  useEffect(() => {
    if (props.isAddingItem) {
      input.onChange(props.addingItemInitialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl error={touched && error} fullWidth disabled={disabled}>
      <Select
        displayEmpty
        native
        value={input.value}
        onChange={input.onChange}
        size="small"
        {...props}
      >
        {children}
      </Select>
      {HelperText({ touched, error })}
    </FormControl>
  );
};

export const Input = ({
  label,
  input,
  disabled,
  meta: { touched, error },
  ...props
}) => {
  return (
    <>
      <TextField
        label={label}
        error={touched && error}
        value={input.value}
        onChange={input.onChange}
        disabled={disabled}
        {...props}
        size="small"
      />
      {HelperText({ touched, error })}
    </>
  );
};

export const ReduxTextField = ({
  label,
  input,
  disabled,
  meta: { touched, error },
  className,
  ...props
}) => {
  const classes = useStyles();
  const hasError = touched && error;

  return (
    <TextField
      {...input}
      className={clsx(classes.errorMessage, className)}
      label={label}
      error={hasError}
      helperText={hasError ? `${error}` : ''}
      disabled={disabled}
      size="small"
      {...props}
    />
  );
};

export const ReduxSelect = ({
  input,
  label,
  meta: { touched, error },
  children,
  disabled,
  disabledValue,
  ...props
}) => {
  return (
    <FormControl error={touched && error} fullWidth disabled={disabled}>
      <Select {...input} {...props} native size="small">
        {children}
      </Select>
      {touched && error && (
        <FormHelperText {...props}>{touched && error}</FormHelperText>
      )}
    </FormControl>
  );
};
