import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {VisitorsDialogFormFieldSelect} from "../../styled";

const SelectField = ({ name, options, renderOptions, disabled, required, onChange }) => {
    const { control } = useFormContext();
    const { t } = useTranslation();

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: {
                    value: required,
                    message: t('attractions.deals.error_required'),
                },
            }}
            render={() => (
                <VisitorsDialogFormFieldSelect
                    native
                    disabled={disabled}
                    onChange={(event) => {
                        onChange(event.target.value);
                    }}
                    size="small"
                >
                    {renderOptions(options)}
                </VisitorsDialogFormFieldSelect>
            )}
        />
    );
};

export default SelectField;