//global settings data
export const CONTENT_LANGUAGES = 'Content languages';
export const SYSTEM_LANGUAGES = 'System languages';
export const CURRENCIES = 'Currencies';
export const FAQ_QUESTION_STATUS = 'FAQ Question Status';
export const NOTIFICATION_STATUS = 'Notification Status';
export const ACTIVITY_STATUS = 'Activity Status';
export const HELP_TOPIC_TYPE = 'Help Topic Types';
export const REVIEW_STATUS = 'Review Status';
export const TIME_ZONES = 'Time zones';
export const USER_ROLES = 'User Roles';
export const USER_STATUS = 'User Status';
export const ARTICLE_STATUS = 'Article Status';
export const POINT_TYPES = 'Point Types';
export const REDIRECTION_TARGETS = 'Redirection Targets';

//predefined settings data
export const ATTRACTION_ENTRY_TYPES = 'Attraction Entry Types';
export const DEAL_STATUS = 'Deal Status';
export const DEAL_FILTER_STATUS = 'Deal Filter Status';
export const DEAL_BENEFITS = 'Deal Benefits';
export const ATTRACTION_CATEGORY_STATUS = 'Attraction Category Status';
export const FAQ_CATEGORY_STATUS = 'FAQ Category Status';
export const ATTRACTION_STATUS = 'Attraction Status';
export const ATTRACTION_TYPES = 'Attraction Types';
export const NUMBER_OF_USES = 'Number of Uses';
export const SALES_POINT_STATUS = 'Sales Point Status';

export const ATTRACTION_MANAGER = 'attraction';
export const ATTRACTION_MANAGER_PRIMARY = 'attractionprimary'

export const ADMIN_ROLE_LIST = ['Administrator', 'SystemManager'];
export const MANAGER_ROLE_LIST = ['AttractionManager', 'AttractionManagerPrimary', 'RegionalManager'];

export const ADMIN_ROLE_LIST_APP = ['admin', 'systemmanager'];
export const MANAGER_ROLE_LIST_APP = [ATTRACTION_MANAGER, ATTRACTION_MANAGER_PRIMARY];

export const LOGOUT_MESSAGE = 'logout';