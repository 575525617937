import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import {
  TablePagination,
  TableContainer,
  Table,
  Box,
  TableBody,
} from '@mui/material';

import LogsTableDataRow from './LogsTableDataRow';
import LogsTableHeader from './LogsTableHeader';

import {
  setPageNumber,
  setPageSize,
} from '../../../redux/logsReducer/logsActions';

import { TABLE_PAGINATION_OPTIONS } from '../constants';

const LogsTable = () => {
  const { logList, paginationSettings } = useSelector(
    (state) => state.logsReducer
  );
  const { attractionsSmallData } = useSelector(
    (state) => state.attractionsReducer
  );
  const [expandedRowId, setExpandedRowId] = useState(-1);

  const resetExpandedRowId = () => setExpandedRowId(-1);

  const handleRowExpand = (id) => {
    setExpandedRowId((currentValue) => (currentValue !== id ? id : -1));
  };
  const { pageNumber, pageSize, totalCount } = paginationSettings;

  const dispatch = useDispatch();

  const handleChangePageNumber = (_, newPageNumber) => {
    dispatch(setPageNumber(newPageNumber));
    resetExpandedRowId();
  };

  const handleChangePageSize = (event) => {
    dispatch(setPageSize(event.target.value));
    resetExpandedRowId();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TablePagination
        rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
        onRowsPerPageChange={handleChangePageSize}
        component="div"
        count={totalCount}
        page={pageNumber}
        onPageChange={handleChangePageNumber}
        rowsPerPage={pageSize}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <LogsTableHeader />
          <TableBody>
            {logList.map((item, index) => (
              <LogsTableDataRow
                key={index}
                isExpanded={index === expandedRowId}
                onRowClick={() => handleRowExpand(index)}
                logData={item}
                attractionList={attractionsSmallData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={TABLE_PAGINATION_OPTIONS}
        onRowsPerPageChange={handleChangePageSize}
        component="div"
        count={totalCount}
        page={pageNumber}
        onPageChange={handleChangePageNumber}
        rowsPerPage={pageSize}
      />
    </Box>
  );
};

export default LogsTable;
