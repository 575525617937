export const sortLanguageListInDestinationSettingsOrder = (
  langList,
  destinationLangList
) => {
  if (!langList || !destinationLangList) return [];

  const sortedLanguageList = destinationLangList.map((destLang) =>
    langList.find((lang) => lang.id === destLang)
  );

  return sortedLanguageList;
};
