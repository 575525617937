import { connect } from 'react-redux';
import { compose } from 'redux';

import CompanyList from './CompanyList';

import {
  getCompaniesListThunk,
  addCompanyThunk,
  editCompanyThunk,
  deleteCompanyThunk,
} from '../../../redux/usersReducers/company/companyActions';
import { getUserSettingsThunk } from '../../../redux/usersReducers/users/userActions';

import { USER_STATUS } from '../../../consts/consts';

const mapStateToProps = (state) => {
  const availableStatuses = state.userReducer.userSettings.filter(
    (item) => item.name.en === USER_STATUS
  );

  return {
    companyReducer: state.companyReducer,
    availableStatuses,
    userSettings: state.userReducer.userSettings,
    availableAttractions: state.attractionsReducer.attractionsSmallData,
    globalSettings: state.appReducer.globalSettingsData,
    selectedDestinationId: state.appReducer.selectedDestination.id,
    settingsReducer: state.settingsReducer,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

const CompanyListContainer = compose(
  connect(mapStateToProps, {
    getCompaniesListThunk,
    addCompanyThunk,
    editCompanyThunk,
    deleteCompanyThunk,
    getUserSettingsThunk,
  })
)(CompanyList);

export default CompanyListContainer;
