import { connect } from 'react-redux';
import { compose } from 'redux';

import AttractionReportPage from './AttractionReportPage';

import { DEAL_BENEFITS } from '../../../consts/consts';

const mapStateToProps = (state) => {
  const benefitOptions =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_BENEFITS
    ) || null;

  return {
    benefitOptions,
    selectedLanguage: state.appReducer.selectedLanguage,
    attractionsData: state.attractionsReducer,
  };
};

export default compose(connect(mapStateToProps, null))(AttractionReportPage);
