import { TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledProductsTableHead, StyledTableHeadRow } from '../styled';

const ProductTableHeader = () => {
  const { t } = useTranslation();

  const tableHeaderLables = t('products.productsTableLabels', {
    returnObjects: true,
  });

  return (
    <StyledProductsTableHead>
      <StyledTableHeadRow>
        {tableHeaderLables.map((label, idx) => (
          <TableCell padding="normal" key={idx}>
            {label}
          </TableCell>
        ))}
      </StyledTableHeadRow>
    </StyledProductsTableHead>
  );
};

export default ProductTableHeader;
