import { useTranslation } from 'react-i18next';
import { FormSection, Field } from 'redux-form';

import { TableCell, Typography } from '@mui/material';

import PricesFormCurrencyField from '../formFields/PricesFormCurrencyField';

import { PricesTableRow, PriceFormVatSelect } from '../../styled';

import { PRICES_TABLE_ROW_FIELDS } from '../../constants';
import { negativeNumber, onlyNumber } from '../../validate';
import { parseToNumber } from '../../utils';

const PricesRow = (props) => {
  const {
    item,
    currencySymbol,
    vatOptions,
    isInEditMode,
    onVatPercentageChange,
    onContractPriceRowChange,
  } = props;
  const { sectionName, label, hasVatSelect, disabled } = item;

  const { t } = useTranslation();

  return (
    <FormSection name={sectionName}>
      <PricesTableRow>
        <TableCell>
          {hasVatSelect && (
            <Field
              component={PriceFormVatSelect}
              name="valueAddedTax"
              onChange={(_, newValue) => onVatPercentageChange(newValue)}
              disabled={!isInEditMode}
            >
              <option value={''} key="">{`${t('attractions.price.vat')} %`}</option>
              {vatOptions.map((option) => (
                <option key={option} value={option}>{`${t('attractions.price.vat')} ${option} %`}</option>
              ))}
            </Field>
          )}

          {!hasVatSelect && <Typography ml="8px"> {t(label)}</Typography>}
        </TableCell>
        {PRICES_TABLE_ROW_FIELDS.map((field, idx) => (
          <TableCell key={idx}>
            <Field
              component={PricesFormCurrencyField}
              name={field}
              currencySymbol={currencySymbol}
              disabled={disabled || !isInEditMode}
              validate={[onlyNumber, negativeNumber]}
              onBlur={() =>
                sectionName === 'contractPrice' && onContractPriceRowChange()
              }
              parse={(value) => parseToNumber(value)}
            />
          </TableCell>
        ))}
      </PricesTableRow>
    </FormSection>
  );
};

export default PricesRow;
