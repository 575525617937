import { Controller, useFormContext } from 'react-hook-form';

import { ProductFormSelectField } from '../../styled';

const createOptions = (options) => {
  if (!options) return null;

  return options.map((option, index) => (
    <option value={option} key={index}>
      {option}
    </option>
  ));
};

const ProductEditFormSelectField = ({
  name,
  options,
  disabled,
  handleChange,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <ProductFormSelectField
          native
          value={value}
          disabled={disabled}
          onChange={(event) => {
            const { value } = event.target;
            onChange(value);
            handleChange && handleChange(value);
          }}
          size="small"
        >
          {createOptions(options)}
        </ProductFormSelectField>
      )}
    />
  );
};

export default ProductEditFormSelectField;
