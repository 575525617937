import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CircularProgress, Box, Typography } from '@mui/material';

import CompanySearchForm from './forms/CompanySearchForm';
import { CompaniesTable, CompanyRow } from './companiesTable';

import {
  CompaniesTabWrapper,
  CompaniesAddButton,
  CompaniesFilterWrapper,
  LoaderWrapper,
} from './styled';

import { USER_STATUS } from '../../../consts/consts';
import { NEW_COMPANY_ROUTE } from './constants';

const CompanyList = (props) => {
  const {
    getCompaniesListThunk,
    getUserSettingsThunk,
    companyReducer,
    selectedDestinationId,
    selectedLanguage,
  } = props;
  const { companiesData, isLoading } = companyReducer;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [status, setStatus] = useState('');
  const [companyOrCity, setCompanyOrCity] = useState('');

  const options = {
    availableStatuses: props.userSettings.filter(
      (item) => item.name.en === USER_STATUS
    ),
    selectedLanguage,
  };

  const handleAddButtonClick = () => {
    navigate(NEW_COMPANY_ROUTE);
  };

  const handleCompanyItemSelect = (companyId) => {
    return () => navigate(companyId.toString());
  };

  const handleResetFilters = () => {
    setStatus('');
    setCompanyOrCity('');
  };

  useEffect(() => {
    getUserSettingsThunk();
  }, [getUserSettingsThunk]);

  useEffect(() => {
    if (selectedDestinationId) {
      getCompaniesListThunk(selectedDestinationId, status, companyOrCity);
    }
  }, [status, companyOrCity, getCompaniesListThunk, selectedDestinationId]);

  const sortedCompanies = companiesData.slice().sort((a, b) => {
    const getLanguagePriority = (company) => {
      const name = company.name.toLowerCase();
      if(localStorage.getItem('i18nextLng') == 'en'){
        if (name.match(/[á-ž]/)) return 1; 
        if (name.match(/[a-z]/)) return 0;
      }
      else {
        if (name.match(/[á-ž]/)) return 0; 
        if (name.match(/[a-z]/)) return 1;
      }
      return 2;
    };
  
    const priorityA = getLanguagePriority(a);
    const priorityB = getLanguagePriority(b);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    } else {
      return a.name.localeCompare(b.name);
    }
  });

  return (
    <CompaniesTabWrapper>
      <Box>
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('companies.list_of_companies')}
        </Typography>
        <Box mt="10px" mb="10px">
          <CompaniesAddButton
            variant="contained"
            onClick={handleAddButtonClick}
          >
            {t('companies.buttons.add_new_company')}
          </CompaniesAddButton>
        </Box>
        <CompaniesFilterWrapper>
          <CompanySearchForm
            options={options}
            status={status}
            setStatus={setStatus}
            companyOrCity={companyOrCity}
            setCompanyOrCity={setCompanyOrCity}
            handleResetFilters={handleResetFilters}
          />
        </CompaniesFilterWrapper>
        {!isLoading ? (
          <CompaniesTable
            items={sortedCompanies}
            renderItem={(item, i) => (
              <CompanyRow
                item={item}
                onClick={handleCompanyItemSelect(item.id)}
                key={i}
              />
            )}
          />
        ) : (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}
      </Box>
    </CompaniesTabWrapper>
  );
};

export default CompanyList;
