import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Tab, Tabs, StyledEngineProvider } from '@mui/material';

import cl from './ProductFormLanguageTabs.module.scss';

import { ProductFormFieldTitle, ProductFormFieldWrapper } from '../../styled';
import ProductEditFormTextField from '../productEditFormFields/ProductEditFormTextField';
import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../../consts/validation';

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const ProductFormLanguageTabs = ({
  languages,
  handleChangeLanguageTab,
  currentTabId,
  activeBenefitValue,
  disabled,
}) => {
  return (
    <Box className={cl.wrapper}>
      <StyledEngineProvider injectFirst>
        <Box className={cl.tabsContainer}>
          <Tabs
            value={currentTabId}
            onChange={handleChangeLanguageTab}
            indicatorColor={undefined}
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            {languages.map((tab, index) => (
              <Tab
                value={tab}
                label={tab}
                key={tab.id}
                {...a11yProps(index)}
                className={cl.tab}
              />
            ))}
          </Tabs>
        </Box>
        {createTabPanels({
          languages,
          currentTabId,
          activeBenefitValue,
          disabled,
        })}
      </StyledEngineProvider>
    </Box>
  );
};

const createTabPanels = ({
  languages,
  currentTabId,
  activeBenefitValue,
  disabled,
}) => {
  return languages.map((languageTab, index) => (
    <TabPanel
      value={currentTabId}
      index={languageTab}
      key={index}
      className={cl.tabPanel}
      fieldArrayName={languageTab}
      activeBenefitValue={activeBenefitValue}
      disabled={disabled}
    />
  ));
};

const TabPanel = (props) => {
  const { t } = useTranslation();
  const {
    fieldArrayName,
    value,
    index,
    activeBenefitValue,
    disabled,
    ...other
  } = props;

  const { control } = useFormContext();

  const { fields } = useFieldArray({
    name: fieldArrayName,
    control,
  });

  const isActiveTab = value === index;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isActiveTab && (
        <Box sx={{ p: 3 }}>
          {fields.map((field, index) => {
            return (
              <Box id={field.id} key={field.id}>
                <ProductFormFieldWrapper>
                  <ProductFormFieldTitle>
                    {t('products.formFieldLabels.name')}
                  </ProductFormFieldTitle>
                  <ProductEditFormTextField
                    name={`${fieldArrayName}.${index}.name`}
                    disabled={disabled}
                    maxLength={DEFAULT_TEXT_FIELD_MAX_LENGTH}
                    required
                  />
                </ProductFormFieldWrapper>

                <ProductFormFieldWrapper>
                  <ProductFormFieldTitle>
                    {t('products.formFieldLabels.specification')}
                  </ProductFormFieldTitle>
                  <ProductEditFormTextField
                    name={`${fieldArrayName}.${index}.specification`}
                    disabled={disabled}
                    maxLength={DEFAULT_TEXT_FIELD_MAX_LENGTH}
                    required
                  />
                </ProductFormFieldWrapper>
              </Box>
            );
          })}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default ProductFormLanguageTabs;
