import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import {
  StyledTextField,
  Container,
  RowContainer,
  TextFieldWrapper,
} from '../styled';

const AccountAccessForm = (props) => {
  const { userRoles, userList } = props;

  const { t } = useTranslation();

  const userRolesMap = userRoles.parameters.reduce((acc, current) => {
    acc[current.id] = current.value;
    return acc;
  }, {});

  return (
    <Container>
      <RowContainer>
        <TextFieldWrapper>
          <Typography textTransform="uppercase">
            {t('companies.account_access')}:
          </Typography>
        </TextFieldWrapper>
      </RowContainer>
      <RowContainer>
        <TextFieldWrapper>{t('companies.name')}:</TextFieldWrapper>
        <TextFieldWrapper>{t('companies.role')}:</TextFieldWrapper>
        <TextFieldWrapper>{t('companies.email')}:</TextFieldWrapper>
      </RowContainer>
      {userList.map((user, i) => (
        <RowContainer key={i}>
          <TextFieldWrapper>
            <StyledTextField disabled value={user.name} size="small" />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              disabled
              value={userRolesMap[user.role].en}
              size="small"
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField disabled value={user.email} size="small" />
          </TextFieldWrapper>
        </RowContainer>
      ))}
    </Container>
  );
};

export default AccountAccessForm;
