import { connect } from 'react-redux';

import Deals from './Deals';

import {
  addDealThunk,
  editDealThunk,
  deleteDealThunk,
} from '../../redux/attractionsReducers/deals/dealsActions';
import { getSmallAttractionsDataThunk } from '../../redux/attractionsReducers/attractions/attractionsActions';
import { getPredefinedSettingsThunk } from '../../redux/settingsReducers/predefinedReducer';

import {
  DEAL_STATUS,
  DEAL_FILTER_STATUS,
  DEAL_BENEFITS,
} from '../../consts/consts';

const mapStateToProps = (state) => {
  const dealFilterStatuses =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_FILTER_STATUS
    ) || null;

  const dealStatuses =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_STATUS
    ) || null;

  const benefitOptions =
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_BENEFITS
    ) || null;

  const languages = state.appReducer.selectedDestination.languages;

  return {
    dealFilterStatuses,
    dealStatuses,
    benefitOptions,
    languages,
    appReducer: state.appReducer,
    dealsList: state.dealsReducer.dealsList,
    isDataLoading: state.dealsReducer.isDataLoading,
    selectedLanguage: state.appReducer.selectedLanguage,
    currentAttractionId: state.attractionsReducer.currentAttraction.id,
    tableSettings: state.dealsReducer.tableSettings,
    attractionsReducer: state.attractionsReducer,
  };
};

const DealsGeneralContainer = connect(mapStateToProps, {
  addDealThunk,
  editDealThunk,
  deleteDealThunk,
  getSmallAttractionsDataThunk,
  getPredefinedSettingsThunk,
})(Deals);

export default DealsGeneralContainer;
