import * as axios from 'axios';
import authService from '../../auth/AuthorizeService';
import { BASE_URL } from '../../config.js';

const URL = `${BASE_URL}/api`;

export const getHelpTopicList = async (destinationId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/destinations/${destinationId}/helpTopics`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateHelpTopic = async (topicId, data) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/helpTopics/${topicId}`;

  return await axios
    .put(targetUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const createHelpTopic = async (data) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/helpTopics`;

  return await axios
    .post(targetUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteHelpTopic = async (topicId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/helpTopics/${topicId}`;

  return await axios
    .delete(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
