import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

import { CustomSelect, StyledFilterOption } from './styled';

import { StyledVisitsFilterAttractionsField } from './visits/styled';

import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import { TYPE_AREA, TYPE_REGION } from './constants';

export const SelectStatus = ({ data, ...props }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="statusValue"
      render={({ field: { onChange, value } }) => (
        <CustomSelect
          value={value}
          native
          size="small"
          onChange={(e) => onChange(e.target.value)}
        >
          <option value={''}>{t('faq_page.questions.all_status')}</option>
          {data[0]?.parameters.map((item, i) => (
            <option value={item.id} key={i}>
              {item.value[props.selectedLanguage] || item.value.en}
            </option>
          ))}
        </CustomSelect>
      )}
    />
  );
};

export const SearchField = () => {
  const { t } = useTranslation();
  const { control, resetField } = useFormContext();

  return (
    <Controller
      control={control}
      name="searchValue"
      render={({ field: { onChange, value, name } }) => (
        <TextField
          variant="outlined"
          size="small"
          placeholder={t('attractions.attractions_search.point_name')}
          value={value}
          onChange={(e) => {
            onChange(validateTextFieldValue(e.target.value));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value ? (
                  <IconButton
                    onClick={() => {
                      resetField('searchValue');
                    }}
                    sx={{ padding: 0 }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <SearchIcon sx={{ color: '#cfcaca' }} />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const SelectCategory = ({
  data,
  selectedLanguage,
  categoryInputValue,
  handleChangeCategoryInputValue,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const mappedCategories = data?.map((category) => {
    return {
      id: category.id,
      name: category.name[selectedLanguage] || category.name.en,
    };
  });

  const handleChangeField = (fieldName, value) => {
    setValue(fieldName, value);
  };

  const handleOnBlur = () => {
    const findMatch = mappedCategories.find(
      (category) => category.name === categoryInputValue.name
    );

    if (!findMatch) handleChangeCategoryInputValue({ id: '', name: '' });
  };

  return (
    <Controller
      control={control}
      name="categoryValue"
      render={({ field: { name } }) => (
        <Autocomplete
          clearIcon={
            !!categoryInputValue.name.length && <ClearIcon fontSize="small" />
          }
          sx={{ width: 200 }}
          options={mappedCategories}
          inputValue={categoryInputValue.name}
          value={categoryInputValue}
          getOptionLabel={(option) => option.name}
          onBlur={handleOnBlur}
          onChange={(_, newValue) => {
            handleChangeField(name, newValue?.id || '');
            handleChangeCategoryInputValue((prevState) => ({
              ...prevState,
              id: newValue?.id,
              name: newValue?.name || '',
            }));
          }}
          renderOption={(props, option) => (
            <StyledFilterOption
              component="li"
              {...props}
              value={option.id}
              label={option.name}
            >
              {option.name}
            </StyledFilterOption>
          )}
          renderInput={(params) => (
            <StyledVisitsFilterAttractionsField
              {...params}
              fullWidth
              size="small"
              placeholder={t('attractions.attractions_search.all_category')}
              onChange={(e) =>
                handleChangeCategoryInputValue((prevState) => ({
                  ...prevState,
                  name: validateTextFieldValue(e.target.value),
                }))
              }
            />
          )}
        />
      )}
    />
  );
};

export const SelectRegion = ({
  data,
  selectedLanguage,
  handleChangeRegionInputValue,
  regionInputValue,
  resetIncludedLocationsValue,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const mappedRegions = data?.map((region) => {
    return {
      id: region.id,
      title: region.title[selectedLanguage] || region.title.en,
    };
  });

  const handleChangeField = (fieldName, value) => {
    setValue(fieldName, value);
  };

  const handleOnBlur = () => {
    const findMatch = mappedRegions.find(
      (region) => region.title === regionInputValue.title
    );

    if (!findMatch) handleChangeRegionInputValue({ id: '', title: '' });
  };

  return (
    <Controller
      control={control}
      name="regionValue"
      render={({ field: { name } }) => {
        return (
          <Autocomplete
            clearIcon={
              !!regionInputValue.title.length && <ClearIcon fontSize="small" />
            }
            sx={{ width: 200 }}
            options={mappedRegions}
            inputValue={regionInputValue.title}
            value={regionInputValue}
            getOptionLabel={(option) => option.title}
            onBlur={handleOnBlur}
            onChange={(_, newValue) => {
              if (!newValue) resetIncludedLocationsValue(TYPE_REGION);
              handleChangeField(name, newValue?.id || '');
              handleChangeRegionInputValue((prevState) => ({
                ...prevState,
                id: newValue?.id,
                title: newValue?.title || '',
              }));
            }}
            renderOption={(props, option) => {
              return (
                <StyledFilterOption
                  component="li"
                  {...props}
                  value={option.id}
                  label={option.title}
                >
                  {option.title}
                </StyledFilterOption>
              );
            }}
            renderInput={(params) => (
              <StyledVisitsFilterAttractionsField
                {...params}
                fullWidth
                size="small"
                placeholder={t('attractions.attractions_search.all_regions')}
                onChange={(e) =>
                  handleChangeRegionInputValue((prevState) => ({
                    ...prevState,
                    title: validateTextFieldValue(e.target.value),
                  }))
                }
              />
            )}
          />
        );
      }}
    />
  );
};

export const SelectArea = ({
  data,
  selectedLanguage,
  handleChangeAreaInputValue,
  areaInputValue,
  selectedRegion,
  resetIncludedLocationsValue,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const mappedAreas = data?.map((area) => {
    return {
      id: area.id,
      title: area.title[selectedLanguage] || area.title.en,
    };
  });

  const handleChangeField = (fieldName, value) => {
    setValue(fieldName, value);
  };

  const handleOnBlur = () => {
    const findMatch = mappedAreas.find(
      (area) => area.title === areaInputValue.title
    );

    if (!findMatch) handleChangeAreaInputValue({ id: '', title: '' });
  };

  return (
    <Controller
      control={control}
      name="areaValue"
      render={({ field: { name } }) => (
        <Autocomplete
          clearIcon={
            !!areaInputValue.title.length && <ClearIcon fontSize="small" />
          }
          sx={{ width: 200 }}
          options={mappedAreas}
          inputValue={areaInputValue.title}
          value={areaInputValue}
          getOptionLabel={(option) => option.title}
          onBlur={handleOnBlur}
          onChange={(_, newValue) => {
            if (!newValue) resetIncludedLocationsValue(TYPE_AREA);
            handleChangeField(name, newValue?.id || '');
            handleChangeAreaInputValue((prevState) => ({
              ...prevState,
              id: newValue?.id,
              title: newValue?.title || '',
            }));
          }}
          renderOption={(props, option) => {
            return (
              <StyledFilterOption
                component="li"
                {...props}
                value={option.id}
                label={option.title}
              >
                {option.title}
              </StyledFilterOption>
            );
          }}
          renderInput={(params) => (
            <StyledVisitsFilterAttractionsField
              {...params}
              fullWidth
              size="small"
              placeholder={t('attractions.attractions_search.all_areas')}
              onChange={(e) =>
                handleChangeAreaInputValue((prevState) => ({
                  ...prevState,
                  title: validateTextFieldValue(e.target.value),
                }))
              }
            />
          )}
        />
      )}
    />
  );
};

export const SelectLocation = ({
  data,
  selectedLanguage,
  handleChangeLocationInputValue,
  locationInputValue,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const mappedLocations = data?.map((location) => {
    return {
      id: location.id,
      title: location.title[selectedLanguage] || location.title.en,
    };
  });

  const handleChangeField = (fieldName, value) => {
    setValue(fieldName, value);
  };

  const handleOnBlur = () => {
    const findMatch = mappedLocations.find(
      (location) => location.title === locationInputValue.title
    );

    if (!findMatch) handleChangeLocationInputValue({ id: '', title: '' });
  };

  return (
    <Controller
      control={control}
      name="locationValue"
      render={({ field: { name } }) => (
        <Autocomplete
          clearIcon={
            !!locationInputValue.title.length && <ClearIcon fontSize="small" />
          }
          sx={{ width: 200 }}
          options={mappedLocations}
          inputValue={locationInputValue.title}
          value={locationInputValue}
          getOptionLabel={(option) => option.title}
          onBlur={handleOnBlur}
          onChange={(_, newValue) => {
            handleChangeField(name, newValue?.id || '');
            handleChangeLocationInputValue((prevState) => ({
              ...prevState,
              id: newValue?.id,
              title: newValue?.title || '',
            }));
          }}
          renderOption={(props, option) => {
            return (
              <StyledFilterOption
                component="li"
                {...props}
                value={option.id}
                label={option.title}
              >
                {option.title}
              </StyledFilterOption>
            );
          }}
          renderInput={(params) => (
            <StyledVisitsFilterAttractionsField
              {...params}
              fullWidth
              size="small"
              placeholder={t('attractions.attractions_search.all_locations')}
              onChange={(e) =>
                handleChangeLocationInputValue((prevState) => ({
                  ...prevState,
                  title: validateTextFieldValue(e.target.value),
                }))
              }
            />
          )}
        />
      )}
    />
  );
};
