import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { HelpTopicFormSelect, StyledFormHelperText } from '../../styled';

const createOptions = (options, selectedLanguage) => {
  if (!options) return null;

  return options.parameters.map((option, index) => (
    <option value={option.id} key={index}>
      {option.value[selectedLanguage]}
    </option>
  ));
};

const HelpTopicFormSelectField = ({
  name,
  options,
  selectedLanguage,
  disabled,
  required,
  ...props
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('articles.validation.required'),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div>
          <HelpTopicFormSelect
            native
            value={value}
            disabled={disabled}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            size="small"
            error={!!error}
          >
            {createOptions(options, selectedLanguage)}
          </HelpTopicFormSelect>

          <StyledFormHelperText {...props}>
            {error?.message || ''}
          </StyledFormHelperText>
        </div>
      )}
    />
  );
};

export default HelpTopicFormSelectField;
