import axios from 'axios';

import authService from '../auth/AuthorizeService';
import { ACCOUNT_BASE_URL } from '../config';

export const getVisitorsList = async (
  pageNumber,
  pageSize,
  status,
  searchQuery
) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${ACCOUNT_BASE_URL}/api/Visitors?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&nameOrEmailOrNumber=${encodeURIComponent(
    searchQuery
  )}`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const addVisitor = async (data) => {
  const token = await authService.getAccessToken();
  const url = `${ACCOUNT_BASE_URL}/api/Visitors`;
  return await axios
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateVisitorData = async (visitorId, visitorData) => {
  const token = await authService.getAccessToken();
  const url = `${ACCOUNT_BASE_URL}/api/Visitors/${visitorId}`;

  return await axios
    .put(url, visitorData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const activateVisitor = async (visitorId,productId) => {
    const token = await authService.getAccessToken();
    const url = `${ACCOUNT_BASE_URL}/api/Visitors/${visitorId}/activate`;
    return await axios
        .post(url, 
            { id: visitorId, productId: productId }, 
            { headers: { Authorization: `Bearer ${token}`}
        })
        .catch((error) => {
            return error.response;
        });
};

export const createUser = async (visitorId, destinationId, companyId, role, regions = [], areas = [], attractions = [], isAllAttractions = false) => {
    const token = await authService.getAccessToken();
    const url = `${ACCOUNT_BASE_URL}/api/Visitors/${visitorId}/user`;
    return await axios
        .post(url,
            { destinationId: destinationId, companyId: companyId, role: role, regions, areas, attractions, isAllAttractions },
            { headers: { Authorization: `Bearer ${token}`}
            })
        .catch((error) => {
            return error.response;
        });
};

export const deleteVisitor = async (visitorId) => {
  const token = await authService.getAccessToken();
  const url = `${ACCOUNT_BASE_URL}/api/Visitors/${visitorId}`;

  return await axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const changeVisitorPassword = async (visitorId) => {
  const token = await authService.getAccessToken();
  const url = `${ACCOUNT_BASE_URL}/api/Visitors/${visitorId}/changePasswordByLink`;

  return await axios
    .post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((error) => {
      return error.response;
    });
};
