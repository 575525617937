import { useEffect, useMemo, useState, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  CircularProgress,
  Button,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { setFormData, setPageNumberLazy} from '../../redux/attractionsReducers/attractions/attractionsActions';
import { attractionTabs } from './attractionTabs';
import AttractionsList from './AttractionsList';
import {
  SelectStatus,
  SearchField,
  SelectCategory,
  SelectRegion,
  SelectArea,
  SelectLocation,
} from './Select';

import {
  AddButton,
  AttractionsPageContainer,
  Container,
  VisitsTableFilterFormWrapper,
} from './styled';

import { ATTRACTION_STATUS } from '../../consts/consts';
import {
  ATTRACTION_MANAGER_ROLE,
  ATTRACTION_MANAGER_PRIMARY_ROLE,
  REGIONAL_MANAGER_ROLE,
  MANAGER_ROLE_LIST,
  DEFAULT_CATEGORY_INPUT_VALUE,
  DEFAULT_AUTOCOMPLETE_VALUE,
  TYPE_AREA,
  TYPE_REGION,
  TYPE_LOCATION,
} from './constants';
import { getAreaOptionList, getLocationOptionList } from './utils';
import { setTitle } from '../../redux/appReducer';

const useStyles = makeStyles({
  button: {
    '&.MuiButton-root': {
      fontSize: '12px',
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&.Mui-selected': {
        borderRadius: '5px',
      },
    },
  },
  wrapper: {
    '& .MuiOutlinedInput-input': {
      color: '#000',
      '&::placeholder': {
        color: '#000',
      },
    },
  },
  clearButton: {
    position: 'relative',
    '&:hover': {
      backgroundColor: '#c5c5c7',
    },
    backgroundColor: '#a7a7a8',
    height: '36px',
    width: '36px',
  },
  container: {
    marginTop: '20px',
  }
});

const AttractionsPage = (props) => {
  const formData = useSelector((state) => state.attractionsReducer.formData);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllPagination, setIsAllPagination] = useState(true);
  const classes = useStyles();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const appState = props.appReducer;
  const settingsState = props.settingsReducer;
  const categoryState = props.categoryReducer;
  const predefinedState = props.predefinedReducer;
  const attractionsState = props.attractionsReducer;
  const currentUser = useSelector(state => state.userReducer.currentUser);

  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(props.authState.role);

  const hasAttractionManagerRole = Array.isArray(props.authState.role)
    ? props.authState.role.some((currentRole) =>
        currentRole.includes(MANAGER_ROLE_LIST)
      )
    : props.authState.role === ATTRACTION_MANAGER_ROLE || props.authState.role === ATTRACTION_MANAGER_PRIMARY_ROLE;

  const currentDestinationData = useMemo(
    () =>
      settingsState.destinationData.find(
        (dest) => dest.id === appState.selectedDestination.id
      ),
    [appState.selectedDestination.id]
  );

  const isRegionEnabledInDestination =
    currentDestinationData?.enabledLocationTypes.includes(TYPE_REGION);
  const isAreaEnabledInDestination =
    currentDestinationData?.enabledLocationTypes.includes(TYPE_AREA);
  const isLocationEnabledInDestination =
    currentDestinationData?.enabledLocationTypes.includes(TYPE_LOCATION);

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      statusValue: formData.statusValue,
      searchValue: formData.searchValue,
      categoryValue: formData.categoryValue,
      regionValue: formData.regionValue,
      areaValue: formData.areaValue,
      locationValue: formData.locationValue,
      sortValue:  formData.sortField,
      sortStatus: formData.sortStatus,
    },
  });

  const { watch, reset, resetField } = formMethods;

  const sortValue = watch('sortValue');
  const sortStatus = watch('sortStatus');

  useEffect(() => {
    setIsLoading(true);
    const regionParam = formData.regionValue;
    formMethods.setValue('regionValue', regionParam);

    const areaParam = formData.areaValue;
    formMethods.setValue('areaValue', areaParam);

    const locationParam = formData.locationValue;
    formMethods.setValue('locationValue', locationParam);

    const sortValueParam = formData.sortField || sortValue !== '' ? sortValue : '';
    formMethods.setValue('sortValue', sortValueParam);

    const sortStatusParam = formData.sortStatus || sortStatus !== '' ? sortStatus : '';
    formMethods.setValue('sortStatus', sortStatusParam);

    const timer = setTimeout(() => {
      props.setAttraction({});
      props.getAttractionsListThunk({
        destinationId: appState.selectedDestination.id,
        pageNumber: attractionsState.pageNumber,
        pageSize: isAllPagination ? 50 : attractionsState.pageSize,
        status: formData.statusValue,
        search: formData.searchValue,
        category: formData.categoryValue.id,
        region: formData.regionValue.id,
        area: formData.areaValue.id,
        location: formData.locationValue.id,
        sort: formData.sortField && formData.sortStatus ? `${formData.sortField}${formData.sortStatus}` : '',
      });
      dispatch(setPageNumberLazy(1))
    }, 300);
    return () => clearTimeout(timer);
  }, 
  [ appState.selectedDestination.id,
    formData.statusValue,
    formData.searchValue,
    formData.categoryValue.id,
    formData.regionValue.id,
    formData.areaValue.id,
    formData.locationValue.id,
    formData.sortStatus,
    formData.sortField,
    appState.selectedLanguage,
    attractionsState.pageSize,
    attractionsState.pageNumber]);

  useEffect(() => {
    formMethods.setValue('statusValue', formData.statusValue);
    formMethods.setValue('searchValue', formData.searchValue);
    formMethods.setValue('categoryValue', formData.categoryValue);
    formMethods.setValue('regionValue', formData.regionValue);
    formMethods.setValue('areaValue', formData.areaValue);
    formMethods.setValue('locationValue', formData.locationValue);
    formMethods.setValue('sortValue', formData.sortField);
    formMethods.setValue('sortStatus', formData.sortStatus);
  }, [categoryState.categoryData]);

  const onAddAttraction = () => {
    navigate(isManagerRoleSelected ? '/atractions/new' : '/attractions/new');
    props.setAddElement(true);
    props.setAttraction({});
  };
 const sortByField = (fieldName) => {
    let newSortStatus = 'Desc';
    if (formData.sortField === fieldName) {
      if (formData.sortStatus === 'Desc') {
        newSortStatus = 'Asc';
      } else if (formData.sortStatus === 'Asc') {
        newSortStatus = '';
        fieldName = '';
      }
    }
    formMethods.setValue('sortValue', fieldName);
    formMethods.setValue('sortStatus', newSortStatus);
    dispatch(setFormData({
      ...formData,
      sortField: fieldName,
      sortStatus: newSortStatus,
    }));
  };

  const handleClearButtonClick = () => {
    const defaultValues = {
      statusValue: '',
      searchValue: '',
      categoryValue: DEFAULT_CATEGORY_INPUT_VALUE,
      regionValue: DEFAULT_AUTOCOMPLETE_VALUE,
      areaValue: DEFAULT_AUTOCOMPLETE_VALUE,
      locationValue: DEFAULT_AUTOCOMPLETE_VALUE,
      sortField: '',
      sortStatus: '',
    };
    reset(defaultValues);
    dispatch(setFormData(defaultValues));
  };

  const onAttractionDataRowClick = (id) => {
    navigate(id + '/' + attractionTabs[0].path);
    props.setAddElement(false);
    props.setIsLoadingAttractions(false);
  };

  const resetIncludedLocationsValue = (locationType) => {
    if (locationType === TYPE_REGION) {
      if (currentDestinationData.enabledLocationTypes.includes(TYPE_AREA)) {
        resetField('areaValue');
        dispatch(setFormData({...formData , regionValue: DEFAULT_AUTOCOMPLETE_VALUE, areaValue: DEFAULT_AUTOCOMPLETE_VALUE})) 
      }

      if (currentDestinationData.enabledLocationTypes.includes(TYPE_LOCATION)) {
        resetField('locationValue');
        dispatch(setFormData({...formData, regionValue: DEFAULT_AUTOCOMPLETE_VALUE, locationValue: DEFAULT_AUTOCOMPLETE_VALUE, areaValue: DEFAULT_AUTOCOMPLETE_VALUE})) 
      }
    }

    if (locationType === TYPE_AREA) {
      if (currentDestinationData.enabledLocationTypes.includes(TYPE_LOCATION)) {
        resetField('locationValue');
        dispatch(setFormData({...formData, areaValue: DEFAULT_AUTOCOMPLETE_VALUE, locationValue: DEFAULT_AUTOCOMPLETE_VALUE})) 
      }
    }
  };

  const areaOptionList = useMemo(() => {
    return getAreaOptionList(currentDestinationData, formData.regionValue);
  }, [currentDestinationData, formData.regionValue]);

  const locationOptionList = useMemo(() => {
    return getLocationOptionList(currentDestinationData, formData.regionValue.id, formData.areaValue.id);
  }, [currentDestinationData, formData.regionValue, formData.areaValue]);

  useEffect(() => {
    dispatch((setTitle('top_bar.main_title_attractions')));
    Promise.all([props.getCategoryThunk(), props.getPredefinedSettingsThunk()]);
  }, []);

  useEffect(() => {
    props.getCompaniesListThunk(
      appState.selectedDestination.id !== undefined
        ? appState.selectedDestination.id
        : settingsState.destinationData[0].id
    );
  }, [appState.selectedDestination]);

  return (
    <>
      {appState.selectedDestination.id ? (
        <AttractionsPageContainer className={isManagerRoleSelected ? classes.container : null}>
          <Box mb="10px">
            <Typography
              fontSize="20px"
              fontWeight={500}
              textTransform="uppercase"
            >
              {t('attractions.headline')}
            </Typography>
          </Box>
          {!hasAttractionManagerRole && (
            <AddButton>
              <Button variant="contained" onClick={onAddAttraction}>
                {t('attractions.add_button')}
              </Button>
            </AddButton>
          )}
          <Container>
            <FormProvider {...formMethods}>
              <VisitsTableFilterFormWrapper>
                <SelectStatus
                  data={predefinedState.predefinedData.filter(
                    (item) => item.name.en === ATTRACTION_STATUS
                  )}
                  selectedLanguage={appState.selectedLanguage}
                />
                <SearchField />
                <SelectCategory
                  data={categoryState.categoryData.filter((item) =>
                    item.availableForDestination.includes(
                      appState.selectedDestination.id,
                      0
                    )
                  )}
                  selectedLanguage={appState.selectedLanguage}
                  categoryInputValue={formData.categoryValue}
                />
                {isRegionEnabledInDestination && (
                  <SelectRegion
                    resetIncludedLocationsValue={resetIncludedLocationsValue}
                    data={currentDestinationData.locations}
                    selectedLanguage={appState.selectedLanguage}
                    regionInputValue={formData.regionValue}
                    user={currentUser}
                  />
                )}

                {isAreaEnabledInDestination && (
                  <SelectArea
                    data={areaOptionList}
                    resetIncludedLocationsValue={resetIncludedLocationsValue}
                    selectedLanguage={appState.selectedLanguage}
                    areaInputValue={formData.areaValue}
                    user={currentUser}
                  />
                )}

                {isLocationEnabledInDestination && (
                  <SelectLocation
                    data={locationOptionList}
                    selectedLanguage={appState.selectedLanguage}
                    locationInputValue={formData.locationValue}
                  />
                )}
              </VisitsTableFilterFormWrapper>
            </FormProvider>
            <Box
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                disabled={false}
                className={clsx(classes.clearButton)}
                size="small"
                onClick={handleClearButtonClick}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Container>
          <AttractionsList
            isLoading = {isLoading}
            setIsLoading = {setIsLoading}
            appState={appState}
            pageNumber={attractionsState.pageNumber}
            pageSize={attractionsState.pageSize}
            sortBy={sortByField}
            handleAttractionClick={onAttractionDataRowClick}
            sortField={formData.sortField}
            sortStatus={formData.sortStatus}
            settingsState={settingsState}
            categoryState={categoryState}
            attractionsState={attractionsState}
            setIsLoadingListAttractions={
              attractionsState.setIsLoadingListAttractions
            }
            formData = {formData}
            isAllPagination = {isAllPagination}
            setIsAllPagination = {setIsAllPagination}
          />
        </AttractionsPageContainer>
      ) : (
        <Box
          component="div"
          sx={{ width: '100%', marginTop: '100px', position: 'relative' }}
        >
          <CircularProgress
            sx={{ position: 'absolute', right: '50%', top: '300px' }}
          />
        </Box>
      )}
    </>
  );
};

export default AttractionsPage;