import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import VisitsFilterDateFieldController from './formFields/VisitsFilterDateField';
import VisitsFilterAttractionsField from './formFields/VisitsFilterAttractionsField';

import { VisitsTableFilterFormWrapper } from '../styled';

import SearchField from '../../../components/SearchField';

const useStyles = makeStyles({
  searchField: {
    '&.MuiTextField-root': {
      maxWidth: '300px',
      width: '100%',
    },
  },
});

const VisitsFilterForm = ({
  attractionsList,
  handlePaginationSettings,
  handleChangeSearchValue,
  handleResetFilters,
  attractionInputValue,
  handleChangeAttractionInputValue,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <VisitsTableFilterFormWrapper>
      <SearchField
        name="searchQuery"
        className={classes.searchField}
        placeholder={t('visitsPage.visitsFilterFields.search')}
        handleChange={handleChangeSearchValue}
      />
      <VisitsFilterDateFieldController
        handlePaginationSettings={handlePaginationSettings}
      />
      {attractionsList && (
        <VisitsFilterAttractionsField
          attractionsList={attractionsList}
          attractionInputValue={attractionInputValue}
          handleChangeAttractionInputValue={handleChangeAttractionInputValue}
        />
      )}

      <IconButton onClick={handleResetFilters}>
        <DeleteIcon />
      </IconButton>
    </VisitsTableFilterFormWrapper>
  );
};

export default VisitsFilterForm;
