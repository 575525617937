import { useSelector } from 'react-redux';
import DealEditForm from '../../../attractions/deals/newDealForm/DealEditForm';
import { DEAL_BENEFITS, DEAL_STATUS } from '../../../../consts/consts';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { EXPIRED, NOT_LIMITED } from '../../../attractions/deals/consts';
import { isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';

const DealsLogFormContainer = ({ logData }) => {
  const item = logData.entity;
  const dealStatuses = useSelector((state) =>
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_STATUS
    )
  );

  const benefitOptions = useSelector((state) =>
    state.predefinedReducer.predefinedData.find(
      (item) => item.name.en === DEAL_BENEFITS
    )
  );
  const languages = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );
  const selectedLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );
  const parsedLanguageFormFieldArrays = useMemo(
    () =>
      languages.reduce((acc, lang) => {
        acc[lang] = [
          {
            title: item.title[lang] || '',
            formula: item.formula[lang] || '',
            note: item.note[lang] || '',
            redeemInstructions: item.redeemInstructions[lang] || '',
          },
        ];

        return acc;
      }, {}),
    [item.formula, item.note, item.title, item.redeemInstructions, languages]
  );

  const defaultValues = {
    dealStatus:
      item.status === EXPIRED
        ? dealStatuses.parameters[0].value[selectedLanguage]
        : item.status,
    benefits: item.benefit,
    percentage: item.percentage,
    dealStartDate: new Date(item.startDate),
    dealEndDate: item.endDate,
    numberOfUses: item.numberOfUses,
    isEndDateOpen: item.endDate ? false : true,
    isUsesLimited: item.numberOfUsesType === NOT_LIMITED ? true : false,
    ...parsedLanguageFormFieldArrays,
  };

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { watch } = formMethods;
  const isEndDateOpenCheckbox = watch('isEndDateOpen');
  const usesLimitCheckboxValue = watch('isUsesLimited');
  const activeBenefitValue = watch('benefits');
  const startDateFieldValue = watch('dealStartDate');

  const [currentTabId, setCurrentTabId] = useState(languages[0]);
  const { t } = useTranslation();

  const handleChangeLanguageTab = (_, newValue) => setCurrentTabId(newValue);
  const dealEndDateRules = {
    required: {
      value: !isEndDateOpenCheckbox,
      message: t('attractions.deals.error_required'),
    },
  };

  const dealStartDateRules = {
    validate: (value) =>
      isValid(value) ? value : t('attractions.deals.error_invalid_date'),
    required: {
      value: true,
      message: t('attractions.deals.error_required'),
    },
  };

  return (
    <FormProvider {...formMethods}>
      <DealEditForm
        dealStatuses={dealStatuses}
        benefitOptions={benefitOptions}
        languages={languages}
        selectedLanguage={selectedLanguage}
        disabled
        activeBenefitValue={activeBenefitValue}
        isEndDateOpenCheckbox={isEndDateOpenCheckbox}
        usesLimitCheckboxValue={usesLimitCheckboxValue}
        handleChangeLanguageTab={handleChangeLanguageTab}
        currentTabId={currentTabId}
        dealEndDateRules={dealEndDateRules}
        dealStartDateRules={dealStartDateRules}
        dealNumber={item.number}
        minDate={startDateFieldValue}
        isDirty={false}
      />
    </FormProvider>
  );
};

export default DealsLogFormContainer;
