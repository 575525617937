import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from '@mui/material';

import { PricesTableHead, PricesTableHeadRow } from '../../styled';

import { TABLE_HEAD_LABELS } from '../../constants';

const PricesTable = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <PricesTableHead>
          <PricesTableHeadRow>
            {TABLE_HEAD_LABELS.map((item, index) => (
              <TableCell key={index}>
                <Typography>{t(item)}</Typography>
              </TableCell>
            ))}
          </PricesTableHeadRow>
        </PricesTableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
export default PricesTable;
