import { useTranslation } from 'react-i18next';

import { Table, TableContainer, TableBody, TableCell } from '@mui/material';

import { CompanyTableHead, CompanyTableHeadRow } from './styled';

const CompaniesTable = ({ items, renderItem }) => {
  const { t } = useTranslation();
  const tableHeaderLables = t('companies.tableHeader', {
    returnObjects: true,
  });

  return (
    <TableContainer>
      <Table>
        <CompanyTableHead>
          <CompanyTableHeadRow>
            {tableHeaderLables.map((label) => (
              <TableCell key={label}>{label}</TableCell>
            ))}
          </CompanyTableHeadRow>
        </CompanyTableHead>
        <TableBody>{items.map(renderItem)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompaniesTable;
