import { stopSubmit } from 'redux-form';
import {
  getCategoryFaqListApi,
  getSearchCategoryApi,
  addCategoryFaqApi,
  editCategoryFaqApi,
  deleteCategoryFaqApi,
} from '../../api/faqApi';
const SET_CATEGORY_DATA = 'SET_CATEGORY_DATA';
const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
const SET_IS_LOADING_CATEGORY_LIST_DATA = 'SET_IS_LOADING_CATEGORY_LIST_DATA';

const initialState = {
  status: '',
  destination: '',
  categoryData: [],
  currentCategory: {},
  isLoadingData: false,
};

const categoryFaqReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY_DATA:
      return {
        ...state,
        categoryData: [...action.payload.data],
      };
    case SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: { ...action.payload.data },
      };
    case SET_IS_LOADING_CATEGORY_LIST_DATA:
      return {
        ...state,
        isLoadingData: action.isLoading,
      };
    default:
      return state;
  }
};

let setCategoryList = (data) => {
  return { type: SET_CATEGORY_DATA, payload: { data } };
};

export let setCurrentCategory = (data) => {
  return { type: SET_CURRENT_CATEGORY, payload: { data } };
};

let setIsLoadingQuestionsListData = (isLoading) => {
  return { type: SET_IS_LOADING_CATEGORY_LIST_DATA, isLoading };
};

export let getCategoryFaqListThunk = () => async (dispatch) => {
  let response = await getCategoryFaqListApi();
  if (response.status >= 200 && response.status < 300) {
    dispatch(setCategoryList(response.data));
    dispatch(setIsLoadingQuestionsListData(true));
  }
};

export let getSearchCategoryThunk =
  (status = '', destinationId = '') =>
  async (dispatch) => {
    let response = await getSearchCategoryApi(status, destinationId);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setCategoryList(response.data));
    }
  };

export let addCategoryFaqThunk = (data) => async (dispatch) => {
  let response = await addCategoryFaqApi(data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('CategoryFaqForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export let editCategoryFaqThunk = (id, data) => async (dispatch) => {
  let response = await editCategoryFaqApi(id, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('CategoryFaqForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export let deleteCategoryFaqThunk = (id) => async () => {
  let response = await deleteCategoryFaqApi(id);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export default categoryFaqReducer;
