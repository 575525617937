import { styled } from '@mui/system';
import {
  Box,
  TableRow,
  TableHead,
  TextField,
  Typography,
  Button,
  Select,
  FormHelperText,
} from '@mui/material';
import { MobileDatePicker } from '@mui/lab';

import {
  FormButton,
  SaveButton,
} from '../../../components/buttons/FormButtons';
import TextEditor from '../../../components/RichTextEditor/TextEditor';

export const AdvertisementTabWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const AdvertContentAddButton = styled(Button)`
  max-width: fit-content;
  color: #ffffff;
`;

export const StyledAdvertContentTableHead = styled(TableHead)`
  .MuiTableCell-root {
    :nth-of-type(1) {
      width: 0%;
    }
    :nth-of-type(2) {
      width: 10%;
    }
    :nth-of-type(3) {
      width: 30%;
    }
    :nth-of-type(4) {
      width: 28%;
    }
    :nth-of-type(5) {
      width: 28%;
    }
  }
`;

export const StyledAdvertContentTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
  height: 57px;
`;

export const ControlPanelContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const AdvertContentFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const AdvertContentFormButton = styled(FormButton)`
  max-width: fit-content;
`;

export const AdvertContentFormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 16px;
`;

export const AdvertContentFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const AdvertContentFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const AdvertContentFormRowContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledMobileDatePicker = styled(MobileDatePicker)`
  max-width: 200px;
  width: 100%;
`;

export const StyledTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const AdvertContentFormDatePickerTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }

  & .MuiOutlinedInput-input {
    cursor: pointer;
    max-width: 169px;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const ImageDropWrapper = styled(Box)`
  max-width: 500px;
  width: 100%;
`;

export const TableImageBox = styled(Box)`
  max-height: 200px;
  max-width: 300px;
  height: 100%;
  width: 100%;
  object-fit: scale-down;
`;

export const AdvertContentFormUploadInstructionWrapper = styled(Box)`
  width: 100%;
`;

export const AdvertContentFormImageUploadBlock = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const AdvertContentFormSelect = styled(Select)`
  max-width: 215px;
  width: 100%;
  ${
    '' /* .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  } */
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  color: #d43131;
  margin-left: 0px;
  margin-top: 0;
  position: absolute;
`;

export const AdvertContentFormTextEditor = styled(TextEditor)`
  width: 100%;
  overflow-y: auto;
  max-height: 600px;
`;
