import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  MenuItem,
  Drawer,
  CssBaseline,
  ListItemButton,
  AppBar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import {
  SideBarContainer,
  SideBarWrapper,
  TopBarControlPanelContainer,
} from './styled';

import { menuItems } from './consts';
import DestinationSelect from './DestinationSelect';
import LanguageSelect from './LanguageSelect';
import LoginMenu from './LoginMenu';
import SideBarMenuList from './SideBarMenuList';

import { setTitle } from '../../redux/appReducer';

import { SYSTEM_LANGUAGES, MANAGER_ROLE_LIST } from '../../consts/consts';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  menuItem: {
    padding: '0px',
  },

  text: {
    padding: '15px 10px 15px 15px',
    fontSize: '16px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    padding: '6px 16px',
  },
});

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - 240px)`,
    marginLeft: `240px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function LinkComponent({isManager}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleClick = () => {
    dispatch((setTitle(isManager ? 'home.profile_tab' : 'top_bar.main_title_dashboard')));
  };

  return (
    <Link to="/" className={classes.link} underline="none" color="inherit" onClick={handleClick}>
      <ListItemButton>
        <Typography variant="h6" sx={{ ml: '10px' }}>
          CoolPass
        </Typography>
      </ListItemButton>
    </Link>
  );
}

const TopBar = (props) => {
  const location = useLocation();
  const {
    appReducer,
    attractionsReducer,
    authReducer,
    settingsReducer,
    setCheckedDestination,
    getAttractionsListThunk,
    getSmallAttractionsDataThunk,
    setCurrentLanguage,
    currentUser,
    name,
    picture,
  } = props;

  const { t, i18n  } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [appState] = useState(appReducer);
  const [attractionsState] = useState(attractionsReducer);
  const [authState] = useState(authReducer);
  const [currentTitle, setCurrentTitle] = useState(undefined);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [drawerState, setState] = useState({ left: false });

  const LANGUAGES = SYSTEM_LANGUAGES;
  const settingsState = settingsReducer;

  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(props.currentUser.role);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...drawerState, [anchor]: open });
  };
  useEffect(() => {
    if(location.pathname === '/') {
      dispatch((setTitle(isManagerRoleSelected ? 'home.profile_tab' : 'top_bar.main_title_dashboard')));
    }
    setCurrentTitle(t(props.appReducer.mainTitle))
  }, [location, props.appReducer.mainTitle, i18n.language])

  return (
    <>
      <StyledAppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CssBaseline />
        <Toolbar>
          <SideBarWrapper>
            <SideBarContainer>
              {!isManagerRoleSelected &&
                <>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer('left', drawerState.left === false)}
                    edge="start"
                  >
                    {drawerState.left === false ? <MenuIcon /> : <ClearIcon />}
                  </IconButton>
                </>
              }
              <LinkComponent isManager={isManagerRoleSelected}/>
            </SideBarContainer>

            <Box flexGrow="2" pr="20px" maxWidth="400px" overflow="hidden">
              <Typography
                textAlign="center"
                variant="h6"
                maxWidth="400px"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {currentTitle}
              </Typography>
            </Box>
            <TopBarControlPanelContainer>
              <DestinationSelect
                appState={appState}
                settingsState={settingsState}
                attractionsState={attractionsState}
                setCheckedDestination={setCheckedDestination}
                getAttractionsListThunk={getAttractionsListThunk}
                getSmallAttractionsDataThunk={getSmallAttractionsDataThunk}
              />
              <LanguageSelect
                appState={appState.globalSettingsData.find(
                  (item) => item.name.en === LANGUAGES
                )}
                setCurrentLanguage={setCurrentLanguage}
              />
              <Typography textAlign="center" className={classes.text}>
                {currentUser?.name || name}
              </Typography>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Avatar" src={picture} />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {!isManagerRoleSelected &&
                  <MenuItem className={classes.menuItem} onClick={handleCloseUserMenu}>
                    <Link to={'/my-profile'} className={classes.link}>
                      <span>{t('top_bar.main_title_my_profile')}</span>
                    </Link>
                  </MenuItem>
                }
                <MenuItem className={classes.menuItem} onClick={handleCloseUserMenu}>
                  <LoginMenu />
                </MenuItem>
              </Menu>
            </TopBarControlPanelContainer>
          </SideBarWrapper>
        </Toolbar>
      </StyledAppBar>
      <Drawer
        anchor={'left'}
        open={drawerState['left']}
        onClose={toggleDrawer('left', false)}
      >
        <SideBarMenuList
          toggleDrawer={toggleDrawer}
          itemsList={menuItems}
          userRoles={authState}
        />
      </Drawer>
    </>
  );
};

export default TopBar;
