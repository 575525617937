import { styled } from '@mui/system';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const ButtonListContainer = styled(Box)`
  width: 80%;
  padding: 0 15px;
  margin: 100px auto;
  justify-content: flex-start;
`;

export const StyledButton = styled(Button)`
    &.MuiButton-root {
      box-shadow: 0px 1px 3px #ffa94c;
      width: 148px;
      height: 70px;
      margin-bottom: 15px;
      margin-right: 15px;
      &:hover {
        background-color: rgba(255, 169, 76, 0.08);
      },
    },
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
