const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} number or less` : undefined;
export const maxLength6 = maxLength(6);

export const onlyNumber = (value) =>
  value && isNaN(Number(value)) ? 'Enter the number' : undefined;

export function required(value) {
  return !value ? 'Required' : undefined;
}

export function isNotEmpty(value) {
  return value.trim().length === 0 ? 'Must be not empty' : undefined;
}

export const requiredAmount = (values) =>
  !values ? 'Enter the amount' : undefined;

export const negativeNumber = (value) =>
  value && value < 0 ? 'Price cannot be a negative' : undefined;
