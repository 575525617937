import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Box, Button, List, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  getSeasonsThunk,
  setSeason,
} from '../../../redux/attractionsReducers/availabilityReducer';

const useStyles = makeStyles({
  listItem: {
    '&.MuiListItem-root': {
      border: '1px solid #8b808000',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: '1200px',
      width: '100%',
      padding: '15px',
      borderRadius: '4.5px',
      marginBottom: '15px',
      boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

const AvailabilityList = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const seasonsList = useSelector(
      (state) => state.availabilityReducer.seasonsList
  );

  const pointName = useSelector(
      (state) => state.salesPointsReducer.currentPoint.name
  );

  const selectedDestinationFirstLanguage = useSelector((state) => {
    return (
        state.appReducer.selectedDestination.languages &&
        state.appReducer.selectedDestination.languages[0]
    );
  });

  const handleAddSeason = () => navigate('new-season');

  const handleEditSeason = (seasonId) => navigate(`edit/${seasonId}`);

  useEffect(() => {
    id && dispatch(getSeasonsThunk(id));

    return () => dispatch(setSeason([]));
  }, [dispatch, id]);

  return (
      <>
        <Box
            sx={{
              fontSize: '20px',
              fontWeight: 500,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
        >
          {pointName}
        </Box>
        <Box sx={{ margin: '30px 0', width: '250px', textDecoration: 'none' }}>
          <Button
              variant="contained"
              onClick={handleAddSeason}
              sx={{ color: '#fff', width: '100%' }}
              disabled={false}
          >
            {t('attractions.season.add_button')}
          </Button>
        </Box>
        <Box sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper' }}>
          {!seasonsList.length ? (
              <Box sx={{ margin: '125px 0 0 5px' }}>{t('attractions.season.no_season')}</Box>
          ) : (
              <List>
                {seasonsList.map((season) => (
                    <ListItem
                        className={classes.listItem}
                        disablePadding
                        onClick={() => handleEditSeason(season.id)}
                        key={season.id}
                    >
                      <Box sx={{ fontWeight: '500', marginBottom: '10px' }}>
                        {season.name[selectedDestinationFirstLanguage] ||
                            season.name.en}
                      </Box>
                      {!!Object.values(season.period).length && (
                          <Box>
                            {`Valid: ${format(
                                Date.parse(season.period.validFrom),
                                'dd MMMM'
                            )} - ${format(
                                Date.parse(season.period.validTo),
                                'dd MMMM'
                            )}`}
                          </Box>
                      )}
                      {season.isTemporaryClosed !== null && (
                          <Box>
                            {`Temporary closed: ${format(
                                Date.parse(season.isTemporaryClosed.validFrom),
                                'dd/MM/yyyy'
                            )} - ${format(
                                Date.parse(season.isTemporaryClosed.validTo),
                                'dd/MM/yyyy'
                            )}`}
                          </Box>
                      )}
                    </ListItem>
                ))}
              </List>
          )}
        </Box>
      </>
  );
};

export default AvailabilityList;
