export const IDENTITY_SERVER_URL = process.env.REACT_APP_IDENTITY_SERVER_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
export const LOGOUT_REDIRECT_URL = process.env.REACT_APP_LOGOUT_REDIRECT_URL;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ACCOUNT_BASE_URL = process.env.REACT_APP_ACCOUNT_BASE_URL;
export const PRODUCTS_BASE_URL = process.env.REACT_APP_PRODUCTS_BASE_URL;

export const settings = {
  authority: IDENTITY_SERVER_URL,
  client_id: CLIENT_ID,
  redirect_uri: REDIRECT_URL,
  post_logout_redirect_uri: LOGOUT_REDIRECT_URL,
  response_type: 'code',
  scope:
    'openid profile roles offline_access coolpass_attraction_management_api coolpass_account_management_api coolpass_identity_admin_api',
  automaticSilentRenew : true,
  silent_redirect_url : REDIRECT_URL
};
