import React, { useState } from 'react';
import { Field, FormSection } from 'redux-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Typography, Tab, Tabs, TextField, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Input } from './reduxFormComponents';

import {
  requiredTitle,
  requiredText,
  maxLength250,
  maxLength1000,
  noSpaceName,
} from '../FieldLevelFormValidation';

const useStyles = makeStyles({
  tabs: {
    minWidth: '320px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    marginBottom: '16px',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },
    '& .MuiTab-root': {
      marginRight: '7px',
    },
  },
  tabPanel: {
    width: '100%',
    fontSize: '0.875rem',
    '& .MuiBox-root': {
      padding: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& textarea': {
        height: '65px !important',
      },
      '& .Mui-disabled': {
        color: '#000',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.90)',
      },
    },
  },
  tipsBox: {
    marginBottom: '30px',
    marginTop: '30px',
  },
  highlightsBox: {
    marginBottom: '30px',
  },
  directionsBox: {
    marginBottom: '30px',
  },
});

const styleTab = {
  color: '#fff',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  backgroundColor: 'rgba(0,0,0, 0.51)',
  width: '100%',
  maxWidth: '50px',
  minWidth: '20px',
  maxHeight: '36px',
  minHeight: '20px',
  padding: '12px 16px',
  margin: '6px 0px',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  indicatorColor: 'secondary',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0, 0.40)',
  },
  '&:focus': {
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&:first-child': {
    marginLeft: '4px',
  },
  '&:last-child': {
    marginRight: '4px',
  },
  '&.Mui-selected': {
    backgroundColor: '#FFA94C',
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&.disabled': {
    opacity: '0.5',
    cursor: ' not-allowed',
  },
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const QuestionLanguageTabs = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const CreateTab = props.languages[0].parameters.map((tab, index) => (
    <Tab
      label={tab.value}
      {...a11yProps(index)}
      sx={styleTab}
      key={tab.value}
    />
  ));

  return (
    <>
      <Box className={classes.tabs}>
        <Tabs
          value={tabsValue}
          indicatorColor="none"
          onChange={handleChangeTabs}
        >
          {CreateTab}
        </Tabs>
      </Box>
      {props.languages[0].parameters.map((tabPanel, index) => (
        <TabPanel
          className={classes.tabPanel}
          value={tabsValue}
          index={index}
          key={index}
        >
          {props.addElement ? (
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={6}
                sx={{ height: '65px' }}
              >
                <Box sx={{ whiteSpace: 'nowrap', width: '10%' }}>
                  {t('faq_page.questions.title')}
                </Box>
                <Box sx={{ width: '85%' }}>
                  <FormSection name="title">
                    <Field
                      name={tabPanel.value}
                      component={Input}
                      placeholder={t('faq_page.questions.title')}
                      validate={[requiredTitle, maxLength250, noSpaceName]}
                      sx={{ width: '100%' }}
                    />
                  </FormSection>
                </Box>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={6}
              >
                <Box sx={{ whiteSpace: 'nowrap', width: '10%' }}>
                  {t('faq_page.questions.text')}
                </Box>
                <Box sx={{ width: '85%' }}>
                  <FormSection name="text">
                    <Field
                      name={tabPanel.value}
                      placeholder={t('faq_page.questions.text')}
                      component={Input}
                      multiline
                      rows={4}
                      sx={{ width: '100%' }}
                      validate={[requiredText, maxLength1000, noSpaceName]}
                    />
                  </FormSection>
                </Box>
              </Stack>
            </Stack>
          ) : props.updateElement && props.isEdit ? (
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={6}
                sx={{ height: '65px' }}
              >
                <Box sx={{ whiteSpace: 'nowrap', width: '10%' }}>
                  {t('faq_page.questions.title')}
                </Box>
                <Box sx={{ width: '85%' }}>
                  <FormSection name="title">
                    <Field
                      name={tabPanel.value}
                      component={Input}
                      placeholder={t('faq_page.questions.title')}
                      sx={{ width: '100%' }}
                      validate={[requiredTitle, maxLength250, noSpaceName]}
                    />
                  </FormSection>
                </Box>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={6}
              >
                <Box sx={{ whiteSpace: 'nowrap', width: '10%' }}>
                  {t('faq_page.questions.text')}
                </Box>
                <Box sx={{ width: '85%' }}>
                  <FormSection name="text">
                    <Field
                      name={tabPanel.value}
                      component={Input}
                      placeholder={t('faq_page.questions.text')}
                      multiline
                      rows={4}
                      validate={[requiredText, maxLength1000, noSpaceName]}
                      sx={{ width: '100%' }}
                    />
                  </FormSection>
                </Box>
              </Stack>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={6}
              >
                <Box sx={{ whiteSpace: 'nowrap', width: '10%' }}>
                  {t('faq_page.questions.title')}
                </Box>
                <Box sx={{ width: '85%' }}>
                  <TextField
                    size="small"
                    sx={{ width: '100%' }}
                    disabled
                    placeholder={
                      props.currentQuestion.title[tabPanel.value] ||
                      `${t('faq_page.questions.no_translation')}`
                    }
                  />
                </Box>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={6}
              >
                <Box sx={{ whiteSpace: 'nowrap', width: '10%' }}>
                  {t('faq_page.questions.text')}
                </Box>
                <Box sx={{ width: '85%' }}>
                  <TextField
                    multiline
                    rows={4}
                    sx={{ width: '100%' }}
                    disabled
                    placeholder={
                      props.currentQuestion.text[tabPanel.value] ||
                      `${t('faq_page.questions.no_translation')}`
                    }
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </TabPanel>
      ))}
    </>
  );
};

export default QuestionLanguageTabs;
