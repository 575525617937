import ImageDrop from '../../../publishing/HomePageTab/imageDrop/ImageDrop';

const CategoryFormImageDrop = (props) => {
  const { input, disabled, filesLimit, columns } = props;

  return (
    <ImageDrop
      {...props}
      fileList={input.value}
      onFileListChange={input.onChange}
      filesLimit={filesLimit}
      columns={columns}
      disabled={disabled}
    />
  );
};

export default CategoryFormImageDrop;
