import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  customRoot: {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      padding: '0 9px 0 9px'
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0'
    }
  },
});

const DealAttractionField = ({
  name,
  optionsData,
  label,
  required
}) => {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: '* Required' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          style={{width: '400px'}}
          options={optionsData}
          getOptionLabel={(option) => option.name}
          value={value !== undefined ? optionsData.find(option => option.id === value) : null}
          onChange={(_, newValue) => onChange(newValue ? newValue.id : null)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              helperText={error?.message}
              classes={{ root: classes.customRoot }}
            />
          )}
        />
      )}
    />
  );
};

export default DealAttractionField;