export const DEFAULT_COMPANY_STATUS = 'Inactive';
export const NEW_COMPANY_ROUTE = 'new-company';
export const COMPANY_FORM = 'CompanyForm';
export const MANAGED_ATTRACTIONS_FORM = 'ManagedAttractionsForm';
export const CONTACT_PERSON_LIST_SIZE = 3;
export const MAX_VISIBLE_ATTRACTION_CONTACTS_AMOUNT = 10;

export const ADMIN_ROLE_LIST = ['Administrator', 'SystemManager'];
export const MANAGER_ROLE_LIST = ['AttractionManager', 'AttractionManagerPrimary'];

export const ATTRACTION_MANAGER = 'AttractionManager';
export const ATTRACTION_MANAGER_PRIMARY = 'AttractionManagerPrimary';

export const EMPTY_COMPANY_OBJECT = {
  status: '',
  name: '',
  website: '',
  streetAddress: '',
  city: '',
  zipCode: null,
  country: '',
  registrationNumber: null,
  valueAddedTaxNumber: '',
  bankAccount: null,
  billingContacts: [],
  personContacts: [],
  attractionContacts: [],
};
