import { useTranslation } from 'react-i18next';

import ContactPersonItem from './ContactPersonItem';

import {
  PersonListContainer,
  ListHeader,
  RowWrapper,
  RowTitleWrapper,
  RowTitle,
  PersonList,
} from './styled';

import { CONTACT_PERSON_LIST_SIZE } from '../constants';

const ContactPersonList = (props) => {
  const { fields, disabled, personLabel } = props;

  const { t } = useTranslation();

  return (
    <PersonListContainer>
      <ListHeader>
        <RowWrapper>
          <RowTitleWrapper>
            <RowTitle>{personLabel}:</RowTitle>
          </RowTitleWrapper>
        </RowWrapper>
        <RowWrapper>
          <RowTitleWrapper>
            <RowTitle>{t('companies.email')}:</RowTitle>
          </RowTitleWrapper>
        </RowWrapper>
        <RowWrapper>
          <RowTitleWrapper>
            <RowTitle>{t('companies.phone')}:</RowTitle>
          </RowTitleWrapper>
        </RowWrapper>
      </ListHeader>
      <PersonList>
        {[...Array(CONTACT_PERSON_LIST_SIZE).keys()].map((_, i) => (
          <ContactPersonItem
            person={`${fields.name}[${i}]`}
            disabled={disabled}
            key={i}
          />
        ))}
      </PersonList>
    </PersonListContainer>
  );
};

export default ContactPersonList;
