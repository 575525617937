import { getLogList } from '../../api/logsApi';

export const SET_LOG_LIST = 'logs/setLogList';
export const SET_IS_LOADING = 'logs/setIsLoading';
export const SET_ERROR = 'logs/setError';
export const SET_PAGE_SIZE = 'logs/setPageSize';
export const SET_PAGE_NUMBER = 'logs/setPageNumber';
export const SET_TOTAL_COUNT = 'logs/setTotalCount';
export const SET_TOTAL_PAGES = 'logs/setTotalPages';

export const setLogList = (logList) => {
  return {
    type: SET_LOG_LIST,
    payload: logList,
  };
};

export const setIsLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  };
};

export const setError = (error) => {
  return {
    type: SET_ERROR,
    payload: error,
  };
};

export const setPageSize = (pageSize) => {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
};

export const setPageNumber = (pageNumber) => {
  return {
    type: SET_PAGE_NUMBER,
    payload: pageNumber,
  };
};

export const setTotalCount = (totalCount) => {
  return {
    type: SET_TOTAL_COUNT,
    payload: totalCount,
  };
};

export const setTotalPages = (totalPages) => {
  return {
    type: SET_TOTAL_PAGES,
    payload: totalPages,
  };
};

export const getLogListThunk =
  ({
    pageNumber = 0,
    pageSize = 10,
    attractionId = '',
    from = '',
    to = '',
    section = '',
    type = '',
    user = '',
  }) =>
  async (dispatch) => {
    dispatch(setIsLoading(true));

    const response = await getLogList({
      pageNumber,
      pageSize,
      attractionId,
      from,
      to,
      section,
      type,
      user,
    });

    if (response.status === 200) {
      dispatch(setLogList(response.data.items));

      if (response.data.totalPages <= pageNumber) dispatch(setPageNumber(0));
      else dispatch(setPageNumber(response.data.pageNumber));

      dispatch(setPageSize(pageSize));
      dispatch(setTotalPages(response.data.totalPages));
      dispatch(setTotalCount(response.data.totalCount));

      dispatch(setIsLoading(false));
    } else {
      dispatch(setError(response.data.errors));
    }
  };
