import {
  SET_DEALS_LIST,
  IS_DEALS_LOADING,
  IS_ITEM_UPDATING,
  SET_TABLE_SETTINGS,
  LOAD_DEALS_LIST,
  SET_DEAL,
} from './dealsActions';

const initialState = {
  dealsList: [],
  isDataLoading: false,
  tableSettings: {
    totalItems: 0,
    totalPages: 0,
    pageNumber: 0,
    pageNumberLazy: 1,
    pageSize: 50,
  },
  isItemUpdating: false,
};

const dealsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEALS_LIST:
      return {
        ...state,
        dealsList: [...action.payload.data],
      };
    case SET_DEAL:
      const {data} = action.payload;

      const newDealsList = state.dealsList.map(deal => {
        if(data.id === deal.id) {
          return data
        }
        return deal
      })

      return {
        ...state,
        dealsList: newDealsList,
      };
    case LOAD_DEALS_LIST:
      return {
        ...state,
        dealsList: [...state.dealsList, ...action.payload.data],
      };
    case IS_DEALS_LOADING:
      return {
        ...state,
        isDataLoading: action.isLoading,
      };
    case IS_ITEM_UPDATING:
      return {
        ...state,
        isItemUpdating: action.isUpdating,
      };
    case SET_TABLE_SETTINGS: {
      return {
        ...state,
        tableSettings: {
          ...action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default dealsReducer;
