import {
  SET_PRODUCTS,
  SET_IS_DATA_LOADING,
  SET_PRODUCT_SETTINGS,
  SET_IS_PRODUCT_SETTINGS_LOADING,
} from './productActions';

const initialState = {
  products: [],
  productSettings: [],
  isDataLoading: false,
  isProductSettingsLoading: false,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS: {
      return {
        ...state,
        products: [...action.payload.data],
      };
    }
    case SET_IS_DATA_LOADING: {
      return {
        ...state,
        isDataLoading: action.payload.data,
      };
    }
    case SET_PRODUCT_SETTINGS: {
      return {
        ...state,
        productSettings: [...action.payload.data],
      };
    }
    case SET_IS_PRODUCT_SETTINGS_LOADING: {
      return {
        ...state,
        isProductSettingsLoading: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default productsReducer;
