import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { MainImageTable, MainImageRow } from './mainImageTable';

import { HomePageTabWrapper, ImageAddButton } from './styled';

const HomePageTab = (props) => {
  const {
    resetActiveRow,
    imageRecordList,
    activeRowId,
    onExpandRow,
    onAddButtonClick,
  } = props;

  const { t } = useTranslation();

  return (
    <HomePageTabWrapper>
      <Box mt="10px" mb="10px">
        <ImageAddButton variant="contained" onClick={onAddButtonClick}>
          {t('mainImage.addImage')}
        </ImageAddButton>
      </Box>
      <MainImageTable>
        {imageRecordList.map((imageRecord, idx) => (
          <MainImageRow
            key={idx}
            imageRecord={imageRecord}
            isExpanded={activeRowId === idx}
            onExpand={() => onExpandRow(idx)}
            resetActiveRow={resetActiveRow}
          />
        ))}
      </MainImageTable>
    </HomePageTabWrapper>
  );
};
export default HomePageTab;
