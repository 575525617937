import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#ffa000",
        },
        secondary: {
            main: "#009688",
        },
    },
    typography: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
    },
});

export default theme;
