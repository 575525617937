import React, { useState, useCallback } from 'react';

export const APIErrorContext = React.createContext({
    error: null,
    addError: () => {},
    removeError: () => {}
});

const APIErrorProvider = ( {children} ) => {
    const [error, setError] = useState(null);
    
    const removeError = () => setError(null);

    const addError = (message, status, description = null) => setError({ message, status, description});

    const contextValue = {
        error,
        addError: useCallback((message, status, description) => addError(message, status, description), []),
        removeError: useCallback(() => removeError(), [])
    };

    return (
        <APIErrorContext.Provider value={contextValue}>
            {children}
        </APIErrorContext.Provider>
    );
}

export default APIErrorProvider;