import * as axios from 'axios';
import authService from '../../auth/AuthorizeService';
import { BASE_URL } from '../../config.js';

const URL = `${BASE_URL}/api/Articles`;

export const getArticleList = async (destinationId, type, sort) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/destinations/${destinationId}/articles?${
    type && `type=${type}`
  }${sort && `&sort=${sort}`}`;

  return await axios
    .get(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateArticleRecord = async (recordId, data) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${URL}/${recordId}`;

  return await axios
    .put(targetUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const createArticleRecord = async (data) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/articles`;

  return await axios
    .post(targetUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteArticleRecord = async (articleId) => {
  const token = await authService.getAccessToken();
  const targetUrl = `${BASE_URL}/api/articles/${articleId}`;

  return await axios
    .delete(targetUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      return error.response;
    });
};
