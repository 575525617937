import { SET_ARTICLE_LIST, SET_IS_LOADING } from './articlesActions';

const initialState = {
  articleList: [],
  isLoading: false,
};

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ARTICLE_LIST:
      return {
        ...state,
        articleList: [...action.payload.data],
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.data,
      };
    default:
      return state;
  }
};

export default articlesReducer;
