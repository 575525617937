import { FieldArray, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import AttractionContactsList from './AttractionContactsList';

import {
  ManagedAttractionsFormContainer,
  ManagedAttractionsFormFieldWrapper,
  ManagedAttractionsFormCellWrapper,
  ManagedAttractionsFormControlPanel,
  CompanyFormSubmitButton,
  CompanyFormButton,
} from './styled';

const mapDispatchToProps = (dispatch) => ({
  changeField: (form, field, value) => dispatch(change(form, field, value))
});

let ManagedAttractionsForm = (props) => {
  const {
    onFormEdit,
    isInEditMode,
    handleSubmit,
    attractionsData,
    onFormCancel,
    showSaveAnimation,
    attractionContactList,
    onSave,
    changeField
  } = props;

  const { t } = useTranslation();

  const handleFormSubmit = (formData) => {
    const cleanedFormData = {
      ...formData,
      attractionContacts: formData.attractionContacts.map(attraction => ({
        ...attraction,
        contacts: attraction.contacts?.map(contact => ({
          ...contact,
          name: contact.name?.trim() || '',
          phone: contact.phone?.trim() || '',
          email: contact.email?.trim() || '',
        })),
      })),
    };

    cleanedFormData.attractionContacts.forEach((attraction, index) => {
      attraction.contacts?.forEach((contact, contactIndex) => {
        changeField('ManagedAttractionsForm', `attractionContacts[${index}].contacts[${contactIndex}].name`, contact.name);
        changeField('ManagedAttractionsForm', `attractionContacts[${index}].contacts[${contactIndex}].phone`, contact.phone);
        changeField('ManagedAttractionsForm', `attractionContacts[${index}].contacts[${contactIndex}].email`, contact.email);
      });
    });

    onSave(cleanedFormData); 
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <ManagedAttractionsFormContainer>
        <ManagedAttractionsFormControlPanel>
          <CompanyFormButton
            onClick={onFormCancel}
            disabled={!isInEditMode}
            label={t('companies.buttons.cancel')}
          />
          <CompanyFormButton
            onClick={onFormEdit}
            disabled={isInEditMode || showSaveAnimation}
            label={t('companies.buttons.edit')}
          />
          <CompanyFormSubmitButton
            isLoading={showSaveAnimation}
            disabled={!isInEditMode}
            label={t('companies.buttons.save')}
          />
        </ManagedAttractionsFormControlPanel>
        <ManagedAttractionsFormFieldWrapper>
          <ManagedAttractionsFormCellWrapper>
            <div style={{ textTransform: 'uppercase' }}>
              {t('companies.managed_attractions_and_points')}:
            </div>
          </ManagedAttractionsFormCellWrapper>
          <ManagedAttractionsFormCellWrapper>
            {t('companies.contact_person')}:
          </ManagedAttractionsFormCellWrapper>
          <ManagedAttractionsFormCellWrapper>
            {t('companies.phone')}:
          </ManagedAttractionsFormCellWrapper>
          <ManagedAttractionsFormCellWrapper>
            {t('companies.email')}:
          </ManagedAttractionsFormCellWrapper>
        </ManagedAttractionsFormFieldWrapper>
        <FieldArray
          component={AttractionContactsList}
          name="attractionContacts"
          attractionsData={attractionsData}
          disabled={!isInEditMode}
          attractionContactList={attractionContactList}
        />
      </ManagedAttractionsFormContainer>
    </Box>
  );
};

const mapStateToProps = (_, ownProps) => {
  if (ownProps.companyData) {
    return {
      attractionContactList: ownProps.companyData.attractionContacts,
      initialValues: {
        ...ownProps.companyData,
        attractionContacts: ownProps.mappedAttractionContacts,
      },
    };
  }
  return { initialValues: {} };
};

ManagedAttractionsForm = reduxForm({
  form: 'ManagedAttractionsForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ManagedAttractionsForm);

export default connect(mapStateToProps, mapDispatchToProps)(ManagedAttractionsForm);