import { connect } from 'react-redux';
import { compose } from 'redux';

import //getAttractionThunk,
//setAddElement,
'../../redux/attractionsReducers/attractions/attractionsActions';

import {
  getSalesPointThunk,
  setAddElement,
} from '../../redux/salesPointsReducer/salesPointsActions';

import { getCategoryThunk } from '../../redux/settingsReducers/categoryReducer';

import { getPredefinedSettingsThunk } from '../../redux/settingsReducers/predefinedReducer';

import { getFacilitiesThunk } from '../../redux/settingsReducers/facilitiesReducer';

import { getTagsThunk } from '../../redux/settingsReducers/tagsReducer';

import { getCompaniesListThunk } from '../../redux/usersReducers/company/companyActions';

import SalesPointPage from './SalesPointPage';

const mapStateToProps = (state) => {
  return {
    appState: state.appReducer,
    //attractionsState: state.attractionsReducer,
    settingsState: state.settingsReducer,
    isAddElement: state.salesPointsReducer.addElement,
  };
};

export default compose(
  connect(mapStateToProps, {
    setAddElement,
    getSalesPointThunk,
    getCategoryThunk,
    getPredefinedSettingsThunk,
    getFacilitiesThunk,
    getTagsThunk,
    getCompaniesListThunk,
  })
)(SalesPointPage);
