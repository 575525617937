import {
  Box,
  Select,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

import { FormButton, SaveButton } from '../../components/buttons/FormButtons';

export const ProductPageContainer = styled(Box)`
  max-width: 1300px;
  width: 100%;
  margin: 95px auto 20px auto;
`;

export const StyledProductsTableHead = styled(TableHead)`
  .MuiTableCell-root {
    padding: 5px 16px;

    :nth-of-type(1) {
      width: 5%;
      text-align: center;
    }
    :nth-of-type(2) {
      width: 20%;
    }
    :nth-of-type(3) {
      width: 20%;
    }
    :nth-of-type(4) {
      width: 20%;
    }
    :nth-of-type(5) {
      width: 9%;
      text-align: center;
    }
    :nth-of-type(6) {
      width: 5%;
      text-align: center;
    }
    :nth-of-type(7) {
      width: 8%;
      text-align: center;
    }
    :nth-of-type(8) {
      width: 5%;
      text-align: center;
    }
    :nth-of-type(9) {
      text-align: center;
    }
  }
`;

export const StyledTableHeadRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    padding: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledProductsTableRow = styled(TableRow)`
  user-select: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  background-color: #ffa94c;

  th {
    border-right: 1px solid #e0e0e0;
  }

  td {
    border-right: 1px solid #e0e0e0;
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 16px;

    :nth-of-type(1) {
      text-align: center;
    }
    :nth-of-type(5) {
      text-align: center;
    }
    :nth-of-type(6) {
      text-align: center;
    }
    :nth-of-type(7) {
      text-align: center;
    }
    :nth-of-type(8) {
      text-align: center;
    }
    :nth-of-type(9) {
      text-align: center;
    }
  }

  td:last-child {
    text-align: center;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ProductFormField = styled(TextField)`
  & .MuiInputAdornment-root {
    margin-top: -2px;
  }

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const ProductFormFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const ProductFormFieldTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const ProductFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
`;

export const ProductFormSubmitButton = styled(SaveButton)`
  max-width: fit-content;
  color: #ffffff;
`;

export const ProductFormButton = styled(FormButton)`
  max-width: fit-content;
`;

export const ProductFormSelectField = styled(Select)`
  max-width: 215px;
  width: 100%;
`;

export const ProductFormNumberField = styled(TextField)`
  & .MuiOutlinedInput-root {
    max-width: 215px;
    width: 100%;
  }

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;
