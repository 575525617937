import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Select, Box } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import AutocompleteTextField from '../../../users/usersTab/forms/AutocompleteTextField';
import { ReviewFormDateField } from './formFields';

const useStyles = makeStyles({
  form: {
    '& .MuiButton-root': {
      marginRight: '10px',
      color: '#fff',
      height: '35px',
    },
  },
  order: {
    width: '185px',
    '& .MuiTextField-root': {
      width: '185px',
      margin: '0px 0px 30px 0px',
    },
  },
  status: {
    width: '185px',
    marginBottom: '30px',
    '& .MuiTextField-root': {
      width: '185px',
      margin: '0px 0px 30px 0px',
    },
  },
});

let ReviewSearchForm = (props) => {
  const {
    status,
    setStatus,
    rating,
    setRating,
    date,
    setDate,
    attraction,
    setAttraction,
    availableStatuses,
    availableRating,
    selectedLanguage,
    isGlobalReview,
    attractionList,
    onFilterReset,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" gap="24px" width="100%">
      <Select
        size="small"
        native
        className={classes.field}
        onChange={(e) => setStatus(e.target.value)}
        value={status}
      >
        <option value={''}>{t('reviews.status')}</option>
        {availableStatuses?.parameters.map((status, i) => (
          <option value={status.id} key={i}>
            {status.value[selectedLanguage] || status.value.en}
          </option>
        ))}
      </Select>
      {isGlobalReview && (
        <Box sx={{ minWidth: 300 }}>
          <AutocompleteTextField
            options={attractionList}
            getOptionLabel={(option) => option.name}
            disablePortal
            label={t('reviews.attraction')}
            value={attraction}
            onChange={(_, value) => {
              setAttraction(value);
            }}
          />
        </Box>
      )}
      <Select
        size="small"
        native
        className={classes.field}
        onChange={(e) => setRating(e.target.value)}
        value={rating}
      >
        <option value={''}>{t('reviews.rating')}</option>
        {availableRating.map((rating, i) => (
          <option value={rating.id} key={i}>
            {rating.id}
          </option>
        ))}
      </Select>
      <ReviewFormDateField
        input={{
          onChange: (value) => {
            setDate(value);
          },
          value: date,
        }}
        meta={{}}
      />
      <IconButton onClick={onFilterReset}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default ReviewSearchForm;
