import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Tab, Tabs, StyledEngineProvider } from '@mui/material';

import cl from './DealFormLanguageTabs.module.scss';
import {TranslateButton} from "../../../components/buttons/Buttons";
import {
  DealFormFieldTitle,
  DealFormLanguageTabField,
  DealLanguageTabField,
} from './styled';

import { DEAL_TEXT_FIELD_MAX_LENGTH } from '../consts';
import { formatLanguageFieldsData, validateTextFieldValue } from '../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { editDealTranslationThunk, setDeal } from '../../../redux/attractionsReducers/deals/dealsActions';

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const DealFormLanguageTabs = ({
  dealStatus,
  item,
  languages,
  handleChangeLanguageTab,
  currentTabId,
  activeBenefitValue,
  disabled,
}) => {
  return (
    <Box className={cl.wrapper}>
      <StyledEngineProvider injectFirst>
        <Box className={cl.tabsContainer}>
          <Tabs
            value={currentTabId}
            onChange={handleChangeLanguageTab}
            indicatorColor={undefined}
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            {languages.map((tab, index) => (
              <Tab
                value={tab}
                label={tab}
                key={index}
                {...a11yProps(index)}
                className={cl.tab}
              />
            ))}
          </Tabs>
        </Box>
        {createTabPanels({
          dealStatus,
          item,
          languages,
          currentTabId,
          activeBenefitValue,
          disabled,
        })}
      </StyledEngineProvider>
    </Box>
  );
};

const createTabPanels = ({
  dealStatus,
  item,
  languages,
  currentTabId,
  activeBenefitValue,
  disabled,
}) => {
  return languages.map((languageTab, index) => (
    <TabPanel
      dealStatus = {dealStatus}
      item = {item}
      value={currentTabId}
      index={languageTab}
      key={index}
      className={cl.tabPanel}
      fieldArrayName={languageTab}
      activeBenefitValue={activeBenefitValue}
      disabled={disabled}
    />
  ));
};

const TabPanel = (props) => {
  const { t } = useTranslation();
  const {
    dealStatus,
    item,
    fieldArrayName,
    value,
    index,
    activeBenefitValue,
    disabled,
    ...other
  } = props;

  const { control } = useFormContext();

  const { fields } = useFieldArray({
    name: fieldArrayName,
    control,
  });

  const dispatch = useDispatch();
  const { getValues, setValue} = useFormContext();
  const defaultLanguage = useSelector(state => state.appReducer.selectedDestination.languages[0]);
  const formValues = getValues();
  const tableSettings = useSelector(state => state.dealsReducer.tableSettings);
  const currentAttractionId = useSelector(state => state.attractionsReducer.currentAttraction.id);
  const selectedDestinationId = useSelector(state => state.appReducer.selectedDestination.id);

  const updateFormValuesWithTranslation = (formValues, responseData, translationField) => {
    const updatedFormValues = { ...formValues };
  
    const translations = responseData[translationField]; 
  
    Object.entries(translations).forEach(([lang, newValue]) => {
      if (updatedFormValues[lang] && Array.isArray(updatedFormValues[lang])) {
        updatedFormValues[lang] = updatedFormValues[lang].map((item) => ({
          ...item,
          [translationField]: newValue,
        }));
      }
    });
  
    return updatedFormValues;
  };

  const onTranslate = (translationField, translationLanguages) => {
    const filter = {
      destinationId: selectedDestinationId,
      attractionId: currentAttractionId,
      pageNumber: tableSettings.pageNumber,
      pageSize: tableSettings.pageSize,
      status: dealStatus,
    };

    const updatedDeal = {
      id: item.id,
      ...formatLanguageFieldsData(formValues),
      translationField,
      translationLanguages
    };

    return dispatch(editDealTranslationThunk(updatedDeal.id, updatedDeal))
      .then((response) => {
        const updatedFormValues = updateFormValuesWithTranslation(formValues, response, translationField);

        Object.keys(updatedFormValues).forEach((key) => {
          setValue(key, updatedFormValues[key]);
        });

        const newDeal = {
          ...response,
          [translationField]: response[translationField],
        };

        dispatch(
          setDeal(newDeal)
        );
      }).catch(error => {
        throw error;
      });
  };

  const isActiveTab = value === index;
  const langValue = value;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isActiveTab && (
        <Box sx={{ p: 3 }}>
          {fields.map((field, index) => {
            return (
              <Box id={field.id} key={field.id}>
                <DealLanguageTabField>
                  <DealFormFieldTitle>
                    {t('attractions.deals.title')}:
                  </DealFormFieldTitle>
                  <Controller
                    name={`${fieldArrayName}.${index}.title`}
                    control={control}
                    rules={{
                      required: {
                        value: isActiveTab,
                        message: t('attractions.deals.error_required'),
                      },
                      maxLength: {
                        value: DEAL_TEXT_FIELD_MAX_LENGTH,
                        message: t('attractions.deals.error_max_length', {
                          chars: DEAL_TEXT_FIELD_MAX_LENGTH,
                        }),
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div style={{display: 'flex', alignItems:'center', width: '100%', gap: '10px'}}>
                        <DealFormLanguageTabField
                          value={value}
                          disabled={disabled}
                          onChange={(event) =>
                            onChange(validateTextFieldValue(event.target.value))
                          }
                          fullWidth
                          size="small"
                          error={!!error}
                          helperText={error?.message}
                        />
                        <TranslateButton 
                          title={t('attractions.deals.title')} 
                          tab={langValue}
                          disabled={disabled}
                          field={'title'}
                          data = {formatLanguageFieldsData(formValues) && formatLanguageFieldsData(formValues).title}
                          notTranslated={item?.notTranslatedFields && item.notTranslatedFields.title}
                          differentData = {formatLanguageFieldsData(formValues)?.title[defaultLanguage] !== item?.title[defaultLanguage]}
                          handleSendTranslation={onTranslate}
                        />
                      </div>

                    )}
                  />
                </DealLanguageTabField>
                {/* TEMPORARY DISABLE SPECIFICATION FIELD DUE TO CUSTOMER REQUEST*/}
                {/* {activeBenefitValue === SPECIFICATION && (
                  <DealLanguageTabField>
                    <DealFormFieldTitle>
                      {t('attractions.deals.formula')}:
                    </DealFormFieldTitle>
                    <Controller
                      name={`${fieldArrayName}.${index}.formula`}
                      control={control}
                      rules={{
                        required: {
                          value: isActiveTab,
                          message: t('attractions.deals.error_required'),
                        },
                        maxLength: {
                          value: DEAL_TEXT_FIELD_MAX_LENGTH,
                          message: t('attractions.deals.error_max_length', {
                            chars: DEAL_TEXT_FIELD_MAX_LENGTH,
                          }),
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <DealFormLanguageTabField
                          value={value}
                          disabled={disabled}
                          onChange={(event) =>
                            onChange(validateTextFieldValue(event.target.value))
                          }
                          fullWidth
                          size="small"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </DealLanguageTabField>
                )} */}

                <DealLanguageTabField>
                  <DealFormFieldTitle>
                    {t('attractions.deals.note')}:
                  </DealFormFieldTitle>
                  <Controller
                    name={`${fieldArrayName}.${index}.note`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div style={{display: 'flex', alignItems:'center', width: '100%', gap: '10px'}}>
                        <DealFormLanguageTabField
                          value={value}
                          disabled={disabled}
                          onChange={(event) =>
                            onChange(validateTextFieldValue(event.target.value))
                          }
                          fullWidth
                          size="small"
                        />
                        <TranslateButton 
                          title={t('attractions.deals.note')} 
                          tab={langValue}
                          disabled={disabled}
                          field={'note'}
                          data = {formatLanguageFieldsData(formValues) && formatLanguageFieldsData(formValues).note}
                          notTranslated={item?.notTranslatedFields && item.notTranslatedFields.note}
                          differentData = {formatLanguageFieldsData(formValues)?.note[defaultLanguage] !== item?.note[defaultLanguage]}
                          handleSendTranslation={onTranslate}
                        />
                      </div>
                    )}
                  />
                </DealLanguageTabField>
                <DealLanguageTabField>
                  <DealFormFieldTitle>
                    {t('attractions.deals.redeem_instructions')}:
                  </DealFormFieldTitle>
                  <Controller
                    name={`${fieldArrayName}.${index}.redeemInstructions`}
                    control={control}
                    rules={{
                      maxLength: {
                        value: DEAL_TEXT_FIELD_MAX_LENGTH,
                        message: t('attractions.deals.error_max_length', {
                          chars: DEAL_TEXT_FIELD_MAX_LENGTH,
                        }),
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div style={{display: 'flex', alignItems:'center', width: '100%', gap: '10px'}}>
                        <DealFormLanguageTabField
                          value={value}
                          disabled={disabled}
                          onChange={(event) =>
                            onChange(validateTextFieldValue(event.target.value))
                          }
                          fullWidth
                          size="small"
                          error={!!error}
                          helperText={error?.message}
                        />
                        <TranslateButton 
                          title={t('attractions.deals.redeem_instructions')} 
                          tab={langValue}
                          disabled={disabled}
                          field={'redeemInstructions'}
                          data = {formatLanguageFieldsData(formValues) && formatLanguageFieldsData(formValues).redeemInstructions}
                          notTranslated={item?.notTranslatedFields && item.notTranslatedFields.redeemInstructions}
                          differentData = {formatLanguageFieldsData(formValues)?.redeemInstructions[defaultLanguage] !== item?.redeemInstructions[defaultLanguage]}
                          handleSendTranslation={onTranslate}
                        />
                      </div>
                    )}
                  />
                </DealLanguageTabField>
              </Box>
            );
          })}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default DealFormLanguageTabs;
