import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Tab,
  Typography,
} from '@mui/material';

import {
  LanguageTabsWrapper,
  StyledLanguageTabs,
  StyledTableCellKey,
  StyledTableCellValue,
  StyledTableContainer,
  TranslationTableHead,
  TranslationTableRow,
} from './styled';

const styleTab = {
  color: '#fff',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  backgroundColor: 'rgba(0,0,0, 0.51)',
  width: '100%',
  maxWidth: '42px',
  minWidth: '20px',
  maxHeight: '30px',
  minHeight: '20px',
  padding: '12px 16px',
  margin: '6px 7px 6px 0',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  indicatorColor: 'secondary',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0, 0.40)',
  },
  '&:focus': {
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&:first-of-type': {
    marginLeft: '4px',
  },
  '&:last-child': {
    marginRight: '4px',
  },
  '&.Mui-selected': {
    backgroundColor: '#FFA94C',
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&.disabled': {
    opacity: '0.5',
    cursor: ' not-allowed',
  },
};

const TranslationList = (props) => {
  const { t } = useTranslation();

  const handleChange = (event, value) => {
    props.setSelectedLang(value);
  };

  return (
    <StyledTableContainer component={Paper}>
      <Table>
        <TranslationTableHead>
          <TableRow>
            <StyledTableCellKey padding="normal" align="center">
              {t('settings_page.translation.key')}
            </StyledTableCellKey>
            <StyledTableCellValue align="center">
              <LanguageTabsWrapper>
                <Typography mr="10px">
                  {t('settings_page.translation.translation')}:
                </Typography>
                <StyledLanguageTabs
                  value={props.selectedLang}
                  onChange={handleChange}
                  indicatorColor="none"
                >
                  {props.languageList.map((item) => (
                    <Tab
                      sx={styleTab}
                      label={item.value}
                      value={item.value}
                      key={item.id}
                    />
                  ))}
                </StyledLanguageTabs>
              </LanguageTabsWrapper>
            </StyledTableCellValue>
          </TableRow>
        </TranslationTableHead>
        <TableBody>
          {!props.translationsState.translationsData.length ? (
            <TranslationTableRow>
              <TableCell colSpan={2}>
                <Typography padding="16px">
                  {t('settings_page.translation.no_key')}
                </Typography>
              </TableCell>
            </TranslationTableRow>
          ) : (
            <>
              {props.translationsState.translationsData.map((item, index) => (
                <TranslationTableRow
                  onClick={() => props.handleListItemClick(item)}
                  key={item.id}
                  hover
                >
                  <StyledTableCellKey>{item.key}</StyledTableCellKey>
                  <StyledTableCellValue>
                    {item.value[props.selectedLang]}
                  </StyledTableCellValue>
                </TranslationTableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default TranslationList;
