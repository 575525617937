import { useTranslation } from 'react-i18next';

import { Table, TableContainer, TableBody, TableCell } from '@mui/material';

import { StyledMainImageTableHead, StyledMainImageTableRow } from '../styled';

const MainImageTable = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  const headerLabels = t('mainImage.tableLabels', {
    returnObjects: true,
  });

  return (
    <TableContainer>
      <Table>
        <StyledMainImageTableHead>
          <StyledMainImageTableRow>
            {headerLabels.map((label, idx) => (
              <TableCell key={idx}>{label}</TableCell>
            ))}
          </StyledMainImageTableRow>
        </StyledMainImageTableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
export default MainImageTable;
