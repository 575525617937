import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import HelpTopicForm from './HelpTopicForm';
import useAPIError from '../../../../apIErrorProvider/useAPIError';
import ConfirmationDialog from '../../../../components/confirmationDialog/ConfirmationDialog';

import {
  deleteHelpTopicThunk,
  getHelpTopicsListThunk,
  updateHelpTopicThunk,
} from '../../../../redux/publishingReducers/helpTopicsReducer/helpTopicActions';

import { sortLanguageListInDestinationSettingsOrder } from '../../../../helpers/sortLanguageListInDestinationSettingsOrder';
import { formatLanguageFieldsData } from '../utils';
import {
  removeKeysWithEmptyValueArrays,
  removeTextEditorValueKeysWithNoPlainText,
} from '../../../../helpers/translationObjectUtils';

import {ACTIVITY_STATUS, CONTENT_LANGUAGES, HELP_TOPIC_TYPE} from '../../../../consts/consts';

const HelpTopicFormContainer = ({ item, resetActiveRow }) => {
  const { addError } = useAPIError();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const selectedAppLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );
  const languageList = useSelector((state) =>
    state.appReducer?.globalSettingsData?.find(
      (item) => item.name.en === CONTENT_LANGUAGES
    )
  );

  const selectedDestinationLanguages = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );
  const selectedDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );

  const destinationLanguageList = {
    ...languageList,
    parameters: sortLanguageListInDestinationSettingsOrder(
      languageList.parameters,
      selectedDestinationLanguages
    ),
  };
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const languageFormFieldArrays = useMemo(
    () =>
      languageList.parameters.reduce((acc, lang) => {
        const { value } = lang;

        acc[value] = [
          {
            title: item.title[value] || '',
            text: item.text[value] || '',
          },
        ];

        return acc;
      }, {}),
    [item.title, item.text, languageList.parameters]
  );

  const activityStatuses = useSelector((state) =>
    state.appReducer?.globalSettingsData.find(
      (item) => item.name.en === ACTIVITY_STATUS
    )
  );

  const helpTopicTypes = useSelector((state) =>
      state.appReducer?.globalSettingsData.find(
          (item) => item.name.en === HELP_TOPIC_TYPE
      )
  );

  const defaultValues = {
    ...languageFormFieldArrays,
    status: item.status,
    type: item.type,
  };

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { handleSubmit, reset } = formMethods;

  const handleFormSubmit = (formValues) => {
    setShowSaveAnimation(true);
    const fieldsWithTranslations = formatLanguageFieldsData(formValues);

    const newHelpTopicData = {
      id: item.id,
      destinationId: item.destinationId,
      status: formValues.status,
      type: formValues.type,
      title: removeKeysWithEmptyValueArrays(fieldsWithTranslations.title),
      text: removeTextEditorValueKeysWithNoPlainText(
        fieldsWithTranslations.text
      ),
    };

    dispatch(updateHelpTopicThunk(newHelpTopicData.id, newHelpTopicData))
      .then(() => {
        addError(`${t('helpTopics.notifications.update_success')}`, 'Success');
        dispatch(getHelpTopicsListThunk(selectedDestinationId));
        resetActiveRow();
        setShowSaveAnimation(false);
      })
      .catch((error) => {
        addError(
          `${t('helpTopics.notifications.update_error')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    setIsInEditMode(false);
    reset(defaultValues);
  };

  const handleFormEdit = () => {
    setIsInEditMode(true);
  };

  const handleFormDelete = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handlePopupConfirm = () => {
    dispatch(deleteHelpTopicThunk(item.id))
      .then(() => {
        resetActiveRow();
        addError(`${t('helpTopics.notifications.delete_success')}`, 'Success');
        dispatch(getHelpTopicsListThunk(selectedDestinationId));
      })
      .catch((e) => {
        addError(`${t('helpTopics.notifications.delete_error')}`, 'Error', e);
      });
    handleClosePopup();
  };

  useEffect(() => {
    if (selectedDestinationLanguages) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDestinationLanguages, reset]);

  return (
    <>
      {destinationLanguageList?.parameters?.[0]?.id && (
        <FormProvider {...formMethods}>
          <HelpTopicForm
            languageList={destinationLanguageList}
            isLoading={showSaveAnimation}
            onSubmit={handleSubmit(handleFormSubmit)}
            onCancel={handleFormCancel}
            helpTopicStatuses={activityStatuses}
            helpTopicTypes={helpTopicTypes}
            selectedLanguage={selectedAppLanguage}
            isInEditMode={isInEditMode}
            editableForm
            onEdit={handleFormEdit}
            onDelete={handleFormDelete}
          />
        </FormProvider>
      )}
      <ConfirmationDialog
        showAlert={isPopupOpen}
        handleClose={handleClosePopup}
        handleCloseSuccess={handlePopupConfirm}
        text={t('helpTopics.delete_topic_title')}
      />
    </>
  );
};

export default HelpTopicFormContainer;
