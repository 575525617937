import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import AdvertContentForm from './AdvertContentForm';
import AdvertContentFormControlPanel from './controlPanel/AdvertContentFormControlPanel';
import useAPIError from '../../../../apIErrorProvider/useAPIError';

import { uploadImageFile } from '../../../../api/publishing/mainImageApi';
import { createAdvertContentThunk } from '../../../../redux/publishingReducers/advertContentReducer/advertContentActions';

import { sortLanguageListInDestinationSettingsOrder } from '../../../../helpers/sortLanguageListInDestinationSettingsOrder';
import { formatLanguageFieldsData } from '../utils';
import { removeKeysWithEmptyValueArrays } from '../../../../helpers/translationObjectUtils';

import {
  ACTIVITY_STATUS,
  CONTENT_LANGUAGES,
  REDIRECTION_TARGETS,
} from '../../../../consts/consts';

import {DEFAULT_ADVERT_STATUS, DEFAULT_REDIRECT_TO} from "../constants";

const AddAdvertContentFormContainer = () => {
  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const selectedAppLanguage = useSelector(
    (state) => state.appReducer.selectedLanguage
  );
  const languageList = useSelector((state) =>
    state.appReducer?.globalSettingsData?.find(
      (item) => item.name.en === CONTENT_LANGUAGES
    )
  );

  const selectedDestinationLanguages = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );

  const destinationLanguageList = {
    ...languageList,
    parameters: sortLanguageListInDestinationSettingsOrder(
      languageList.parameters,
      selectedDestinationLanguages
    ),
  };

  const languageFormFieldArrays =
    selectedDestinationLanguages &&
    selectedDestinationLanguages.reduce((acc, lang) => {
      acc[lang] = [
        {
          subject: '',
          text: '',
        },
      ];

      return acc;
    }, {});

  const initialFormState = {
    status: DEFAULT_ADVERT_STATUS,
    redirectTo: DEFAULT_REDIRECT_TO,
    date: new Date(),
    mobileImage: [],
    webImage: [],
    ...languageFormFieldArrays,
  };

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...initialFormState,
    },
  });

  const { handleSubmit, reset } = formMethods;
  const currentDestinationId = useSelector(
    (state) => state.appReducer.selectedDestination.id
  );
  const handleFormSubmit = async (formData) => {
    setShowSaveAnimation(true);

    const imageFormData = new FormData();
    imageFormData.append('files', formData.mobileImage[0]?.file);

    const uploadMobileImageResponse = await uploadImageFile(imageFormData, 'Advert')

    imageFormData.delete('files');
    imageFormData.append('files', formData.webImage[0]?.file);

    const uploadWebImageResponse = await uploadImageFile(imageFormData, 'Web')

    const fieldsWithTranslations = formatLanguageFieldsData(formData);

    const newAdvertContent = {
      destinationId: currentDestinationId,
      status: formData.status,
      redirectTo: formData.redirectTo,
      date: formData.date,
      subject: removeKeysWithEmptyValueArrays(
          fieldsWithTranslations.subject
      ),
      text: removeKeysWithEmptyValueArrays(
          fieldsWithTranslations.text
      ),
      mobileImage: uploadMobileImageResponse.data[0],
      webImage: uploadWebImageResponse.data[0]
    };

    return dispatch(createAdvertContentThunk(newAdvertContent))
        .then(() => {
          setShowSaveAnimation(false);
          addError(
              `${t('advertWindow.notifications.create_success')}`,
              'Success'
          );
          navigate(-1);
        })
        .catch((e) => {
          addError(`${t('advertWindow.notifications.create_error')}`, 'Error', e);
          setShowSaveAnimation(false);
        });
  };

  const activityStatuses = useSelector((state) =>
    state.appReducer?.globalSettingsData.find(
      (item) => item.name.en === ACTIVITY_STATUS
    )
  );
  const redirectToOptions = useSelector((state) =>
    state.appReducer?.globalSettingsData.find(
      (item) => item.name.en === REDIRECTION_TARGETS
    )
  );

  const handleFormCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (selectedDestinationLanguages) {
      reset(initialFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDestinationLanguages, reset]);

  return (
    <>
      {destinationLanguageList?.parameters?.[0]?.id && (
        <FormProvider {...formMethods}>
          <AdvertContentFormControlPanel
            isInAddMode
            isInEditMode
            onSubmit={handleSubmit(handleFormSubmit)}
            onCancel={handleFormCancel}
            showSaveAnimation={showSaveAnimation}
          />
          <AdvertContentForm
            isInEditMode
            languageList={destinationLanguageList}
            advertContentStatuses={activityStatuses}
            selectedLanguage={selectedAppLanguage}
            redirectToOptions={redirectToOptions}
          />
        </FormProvider>
      )}
    </>
  );
};

export default AddAdvertContentFormContainer;
