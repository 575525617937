import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { FactsFormTextEditor } from '../../styled';

import { EMPTY_HTML_TAG_LENGTH } from '../../../ArticlesTab/constants';

const FactsFormTextEditorField = ({
  name,
  disabled,
  required,
  maxLength,
  ...props
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const [firstRender, setFirstRender] = useState(true);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) =>
          value.length > EMPTY_HTML_TAG_LENGTH
            ? null
            : t('articles.validation.required'),
        maxLength: {
          value: maxLength,
          message: t('articles.validation.max_length', {
            chars: maxLength,
          }),
        },
      }}
      render={({
        field: { onChange, value },
        fieldState: { error, isDirty },
      }) => (
        <FactsFormTextEditor
          {...props}
          meta={{
            dirty: isDirty,
            touched: !!error,
            error: error?.message,
          }}
          input={{ onChange, value }}
          isEdit={!disabled}
          disabled={disabled}
          valueDirty={firstRender}
          setValueDirty={setFirstRender}
        />
      )}
    />
  );
};

export default FactsFormTextEditorField;
