export const SETUP_ATTRACTION_FORM_NAME = 'attractionSetupForm';

export const ATTRACTION_SETUP_FORM_DEFAULT_VALUES = {
  name: '',
  type: '',
  entryType: '',
  mostPopular: '',
  status: 'Inactive',
  companyId: '',
  regionId: '',
  regionOrder: '',
  areaId: '',
  areaOrder: '',
  locationId: '',
  locationOrder: '',
  address: '',
  website: '',
  email: '',
  phone: '',
  categories: [],
  facilities: [],
  tags: [],
  nearby: [],
  similar: [],
  mapLatitude: 0,
  mapLongtitude: 0,
};

export const ATTRACTION_CONTENT_DEFAULT_VALUES = {
  title: {},
  subtitle: {},
  introduction: {},
  description: {},
  tips: {},
  highlights: {},
  directions: {},
  appImages: [],
  webImages: [],
};

export const ACTIVE_STATUS = 'Active';
export const PENDING_STATUS = 'Pending';
export const ALL_ATTRACTIONS_PAGE_SIZE = 1000;
export const DEFAULT_PAGE_SIZE = 10;
export const ATTRACTION_MANAGER_ROLE = 'attraction';

export const ADMIN_ROLE_LIST = ['Administrator', 'SystemManager'];
export const MANAGER_ROLE_LIST = ['AttractionManager', 'AttractionManagerPrimary'];
export const REGIONAL_MANAGER_ROLE = 'regionalmanager';
