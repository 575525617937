export const formatUserContacts = (userContacts) => {
  if (!userContacts) return [];

  const formated = userContacts.map((contact) => {
    return {
      ...contact,
      phone: contact.phone.value,
    };
  });

  return formated;
};

export const includeContactPhoneInObject = (userContacts) => {
  if (!userContacts) return [];

  const formated = userContacts.map((contact) => {
    return {
      ...contact,
      phone: { value: contact.phone },
    };
  });

  return formated;
};

export const removeEmptyObjects = (objList) => {
  if (!objList.length) return [];

  const filteredObjList = objList.filter((item) => !!Object.keys(item).length);

  return filteredObjList;
};

export const filledEmptyFields = (objList) => {
  if (!objList.length) return [];

  const filledObjList = objList.map(item => ({
    name: item.name || "",
    phone: item.phone || "",
    email: item.email || ""
  }));

  const filteredObjList = filledObjList.filter(item => !!Object.values(item).some(value => value !== ""));

  return filteredObjList;
}

export const mapAttractionsToAttractionContacts = (
  attractionList,
  attractionContactsList
) => {
  if (!attractionList || !attractionContactsList) return [];

  const reducer = (res, current) => {
    const attractionContact = attractionContactsList.find(
      (contact) => contact.idAttraction === current.id
    );

    if (!attractionContact) {
      res.push({
        attractionName: current.name,
        idAttraction: current.id,
        phone: '',
        name: '',
        email: '',
      });
    } else {
      res.push({ ...attractionContact, attractionName: current.name });
    }

    return res;
  };

  const mappedAttractionContacts = attractionList.reduce(reducer, []);

  return mappedAttractionContacts;
};

export const excludeAttractionNames = (attractionContactsList) => {
  const parsedAttractionContacts = attractionContactsList.map((contact) => {
    const contacts = contact.contacts || [];

    const parsedContact = {
      idAttraction: contact.idAttraction,
      name: contact.attractionName,
      contacts: contacts.map((c) => ({
        name: c.name.trim() || '',
        phone: c.phone.trim() || '',
        email: c.email.trim() || ''
      }))
    };

    return parsedContact;
  });

  return parsedAttractionContacts;
};
