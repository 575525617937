import { stopSubmit } from 'redux-form';
import {
    getTagsApi,
    getSearchTagsApi,
    addTagsApi,
    deleteTagsApi,
    editTagsApi,
} from '../../api/settingsApi.js';
const SET_TAGS = 'SET_TAGS';
const SET_CURRENT_TAG = 'SET_CURRENT_TAG';
const IS_LOADING_TAGS_DATA = 'IS_LOADING_TAGS_DATA';

const initialState = {
    tagsData: [],
    currentTag: {},
    isLoadingTagsData: false,
};

const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAGS:
            return {
                ...state,
                tagsData: [...action.payload.data]
            };
        case SET_CURRENT_TAG:
            return {
                ...state,
                currentTag: {...action.payload.data}
            };
        case IS_LOADING_TAGS_DATA:
            return {
                ...state,
                isLoadingTagsData: action.isLoadingTagsData
            };
        default:
            return state;
    }
};


let setTags = (data) => {
    return { type: SET_TAGS, payload: { data } };
}

export let setCurrentTag = (data) => {
    return { type: SET_CURRENT_TAG, payload: { data } };
}

let setIsLoadingTagsData = (isLoadingTagsData) => {
    return { type: IS_LOADING_TAGS_DATA, isLoadingTagsData };
}


export let getTagsThunk = () => async (dispatch) => {
    let response = await getTagsApi();
    if (response.status >= 200 && response.status < 300) {
        dispatch(setTags(response.data));
        dispatch(setIsLoadingTagsData(true));
    } else {
        dispatch(setIsLoadingTagsData(false));
    }

}

export let getSearchTagsThunk = (name) => async (dispatch) => {
    let response = await getSearchTagsApi(name);
    if (response.status >= 200 && response.status < 300) {
        dispatch(setTags(response.data));
    }
}

export let addTagsThunk = (data) => async (dispatch) => { 
    let response = await addTagsApi(data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Tags", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let editTagsThunk = (id, data) => async (dispatch) => {
    let response = await editTagsApi(id, data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Tags", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let deleteTagsThunk = (id) => async () => {
    let response = await deleteTagsApi(id);
    if(response.status < 200 || response.status >= 300) {
        return Promise.reject(response.data.errors)
    }
}

export default tagsReducer;