import { Controller, useFormContext } from 'react-hook-form';

import { RedeemCodeTextField } from '../../styled';

const RedeemCodeBenefitField = ({ name, selectedLanguage, disabled }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <RedeemCodeTextField
          disabled={disabled}
          fullWidth
          size="small"
          value={value}
        />
      )}
    />
  );
};

export default RedeemCodeBenefitField;
