import { useTranslation } from 'react-i18next';

import { Table, TableContainer, TableBody, TableCell } from '@mui/material';

import { NotificationTableHead, NotificationTableHeadRow } from './styled';

import { NOTIFICATION_TABLE_HEADER_LABELS } from '../constants';

const NotificationsTable = ({ children }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <NotificationTableHead>
          <NotificationTableHeadRow>
            {NOTIFICATION_TABLE_HEADER_LABELS.map((label, idx) => (
              <TableCell key={idx}>{t(label)}</TableCell>
            ))}
          </NotificationTableHeadRow>
        </NotificationTableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotificationsTable;
