import { useTranslation } from 'react-i18next';

import { Table, TableContainer, TableBody, TableCell } from '@mui/material';

import { HelpTopicTableHead, HelpTopicTableHeadRow } from '../styled';

const HelpTopicsTable = ({ children }) => {
  const { t } = useTranslation();

  const tableHeaderLables = t('helpTopics.tableLabels', {
    returnObjects: true,
  });

  return (
    <TableContainer>
      <Table>
        <HelpTopicTableHead>
          <HelpTopicTableHeadRow>
            {tableHeaderLables.map((label) => (
              <TableCell key={label}>{label}</TableCell>
            ))}
          </HelpTopicTableHeadRow>
        </HelpTopicTableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default HelpTopicsTable;
