import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TodayIcon from '@mui/icons-material/Today';
import { LocalizationProvider } from '@mui/lab';
import { Box, Button, InputAdornment } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { makeStyles } from '@mui/styles';

import {
  VisitsFilterDateTextField,
  VisitsFilterFormDateField,
} from '../../styled';

const useStyles = makeStyles({
  dialogActionBar: {
    '.MuiDialog-container': {
      '.MuiDialogActions-root': {
        position: 'absolute',
        right: '0px',
        bottom: '0px',
      },
    },
  },
  dialogCancelButton: {
    position: 'absolute',
    bottom: '8px',
    right: '72px',

    button: {
      zIndex: 1,
    },
  },
});

const AttractionDateFieldController = ({ handlePaginationSettings }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();

  const handleOpenCalendar = () => setIsOpen(true);
  const handleCloseCalendar = () => setIsOpen(false);

  return (
    <Controller
      name="dateAndTime"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <VisitsFilterFormDateField
            inputFormat="dd.MM.yyyy"
            onChange={(event) => {
              onChange(event);
              handlePaginationSettings();
            }}
            value={value}
            mask={'__.__.____'}
            open={isOpen}
            onOpen={handleOpenCalendar}
            onClose={handleCloseCalendar}
            disableCloseOnSelect={false}
            clearable
            cancelText=""
            clearText={t('clear')}
            okText={t('ok')}
            ToolbarComponent={() => (
              <Box className={classes.dialogCancelButton}>
                <Button variant="text" onClick={handleCloseCalendar}>
                  {t('cancel')}
                </Button>
              </Box>
            )}
            DialogProps={{
              className: classes.dialogActionBar,
            }}
            renderInput={(params) => (
              <VisitsFilterDateTextField
                {...params}
                size="small"
                placeholder={t('visitsPage.visitsFilterFields.date')}
                error={!!error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      onClick={handleOpenCalendar}
                    >
                      <TodayIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default AttractionDateFieldController;
