import { stopSubmit } from 'redux-form';
import {
    getFacilitiesApi,
    addFacilitiesApi,
    deleteFacilitiesApi,
    editFacilitiesApi,
} from '../../api/settingsApi.js';
const SET_FACILITIES = 'SET_FACILITIES';
const SET_CURRENT_FACILITY = 'SET_CURRENT_FACILITY';
const IS_LOADING_FACILITIES_DATA = 'IS_LOADING_FACILITIES_DATA';


const initialState = {
    facilitiesData: [],
    currentFacility: {},
    isLoadingFacilitiesData: false,
};

const facilitiesReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_FACILITIES:
            return {
                ...state,
                facilitiesData: [...action.payload.data]
            };
        case SET_CURRENT_FACILITY:
            return {
                ...state,
                currentFacility: {...action.payload.data}
            };
        case IS_LOADING_FACILITIES_DATA:
            return {
                ...state,
                isLoadingFacilitiesData: action.isLoadingFacilitiesData
            };
        default:
            return state;
    }
};

let setFacilities = (data) => {
    return { type: SET_FACILITIES, payload: { data }};
}

export let setCurrentFacility = (data) => {
    return { type: SET_CURRENT_FACILITY, payload: { data } };
}

let setIsFacilitiesData = (isLoadingFacilitiesData) => {
    return { type: IS_LOADING_FACILITIES_DATA, isLoadingFacilitiesData};
}

export let getFacilitiesThunk = () => async (dispatch) => {
    let response = await getFacilitiesApi();
    if (response.status >= 200 && response.status < 300) {
        dispatch(setFacilities(response.data));
        dispatch(setIsFacilitiesData(true));
    } else {
        dispatch(setIsFacilitiesData(false));
    }
}

export let addFacilitiesThunk = (data) => async (dispatch) => {
    let response = await addFacilitiesApi(data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Facility", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let editFacilitiesThunk = (id, data) => async (dispatch) => {
    let response = await editFacilitiesApi(id, data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("Facility", {_error: response.data.errors }))
        return Promise.reject(response.data.errors)
    }
}

export let deleteFacilitiesThunk = (id) => async () => {
    let response = await deleteFacilitiesApi(id);
    if(response.status < 200 || response.status >= 300) {
        return Promise.reject(response.data.errors)
    }
}


export default facilitiesReducer;