import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { DealFormFieldTitle, EditDealFormControlPanel } from './styled';

import { DealContext } from '../context/DealContext';

import {
  SaveButton,
  FormButton,
} from '../../../components/buttons/FormButtons';

import ConfirmationDialog from '../../../components/confirmationDialog/ConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { MANAGER_ROLE_LIST } from '../../attractions/deals/consts';
import { getSelectedUserDataThunk } from '../../../redux/usersReducers/users/userActions';

const DealEditFormContainer = ({
  formData,
  disabled,
  showDeleteAlert,
  onDeleteConfirmation,
  onDeleteCancellation,
  onDealApprove,
  onEdit,
  onCancel,
  onDelete,
  onSubmit,
  children,
}) => {
  const { t } = useTranslation();
  const { showSaveAnimation } = useContext(DealContext);

  const dispatch = useDispatch();

  const [approvedUserExist, setApprovedUserExist] = useState(false);
  
  const approved = formData.approved ? true : false;
  const currentUser = useSelector(state => state.userReducer.currentUser);
  const isManagerRoleSelected = MANAGER_ROLE_LIST.includes(currentUser.role);
  const approvedUser = useSelector(state => state.userReducer.selectedUser);

  const handleApproveButtonClick = () => {
    onDealApprove();
  };

  useEffect(() => {
    if(formData.approvingUserId) {
      dispatch(getSelectedUserDataThunk(formData.approvingUserId))
        .then(() => {
          setApprovedUserExist(true);
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            setApprovedUserExist(false);
          }
        });
    }
  }, [formData.approvingUserId])

  return (
    <Box display="flex" flexDirection="column">
      <EditDealFormControlPanel>
        {approved && (
          <DealFormFieldTitle fontWeight="bold" style={{maxWidth: '500px', alignSelf: 'center', textAlign: 'end', paddingRight: '20px'}}>
            {t('attractions.deals.approved')} {approvedUserExist ? (`${ t('attractions.deals.by')} ${approvedUser?.name.toUpperCase()}`) : t('attractions.deals.removed_user')} {new Date(formData?.approved).toLocaleString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
          </DealFormFieldTitle>
        )}
        <Box display="flex" gap="10px">
          {formData.status === 'Inactive' && (
            <FormButton
              label={t('attractions.deals.buttons.approve')}
              sx={{ color: '#ffffff' }}
              variant="contained"
              onClick={handleApproveButtonClick}
            />
          )}
          {!isManagerRoleSelected ? (
            <>
              <FormButton
                label={t('attractions.deals.buttons.cancel')}
                disabled={disabled}
                onClick={onCancel}
              />
              <FormButton
                label={t('attractions.deals.buttons.edit')}
                disabled={!disabled}
                onClick={onEdit}
              />
            </>
          ) : !formData.approved && (
            <>
              <FormButton
                label={t('attractions.deals.buttons.cancel')}
                disabled={disabled}
                onClick={onCancel}
              />
              <FormButton
                label={t('attractions.deals.buttons.edit')}
                disabled={!disabled}
                onClick={onEdit}
              />
            </>
          )}
          <FormButton
            label={t('attractions.deals.buttons.delete')}
            disabled={!disabled}
            onClick={() => onDelete()}
          />
          {!isManagerRoleSelected ? (
            <SaveButton
              label={t('attractions.deals.buttons.save')}
              disabled={disabled}
              isLoading={showSaveAnimation}
              onClick={onSubmit}
            />
          ) : !formData.approved && (
            <SaveButton
              label={t('attractions.deals.buttons.save')}
              disabled={disabled}
              isLoading={showSaveAnimation}
              onClick={onSubmit}
            />
          )}
        </Box>
      </EditDealFormControlPanel>
      {children}
      <ConfirmationDialog
        showAlert={showDeleteAlert}
        handleClose={onDeleteCancellation}
        handleCloseSuccess={() => {
          onDeleteConfirmation(formData.id);
        }}
        text={`${t('attractions.deals.delete_deal')} ${formData.number}?`}
      />
    </Box>
  );
};

export default DealEditFormContainer;
