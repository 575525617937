import { styled } from '@mui/system';
import { Box, TextField } from '@mui/material';

export const LoaderWrapper = styled(Box)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const TextFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
  width: 100%;
  margin-right: 16px;
`;

export const RowContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Container = styled(Box)`
  margin-top: 50px;
`;
