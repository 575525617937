import { stopSubmit } from 'redux-form';
import {
    getAppVersionApi,
    addAppVersionApi,
    editAppVersionApi,
} from '../../api/appVersionApi';
const SET_VERSION = 'SET_VERSION';
const SET_IS_LOADING = 'SET_IS_LOADING';

const initialState = {
    version: {},
    isLoading: false,
};

const appVersionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VERSION:
            return {
                ...state,
                version: {...action.payload.data}
            };
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        default:
            return state;
    }
};

let setVersion = (data) => {
    return { type: SET_VERSION, payload: { data } };
}
let setIsLoading = (isLoading) => {
    return { type: SET_IS_LOADING, isLoading };
}

export let getAppVersionThunk = () => async (dispatch) => {
    let response = await getAppVersionApi();
    if (response.status >= 200 && response.status < 300) {
        dispatch(setVersion(response.data));
        dispatch(setIsLoading(true));
    }
}

export let addAppVersionThunk = (data) => async (dispatch) => {
    let response = await addAppVersionApi(data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("AppVersionForm", {_error: response.data.errors }))
        return Promise.reject(response.data.errors);
    }
}

export let editAppVersionThunk = (id, data) => async (dispatch) => {
    let response = await editAppVersionApi(id, data);
    if (response.status < 200 || response.status >= 300) {
        dispatch(stopSubmit("AppVersionForm", {_error: response.data.errors }))
        return Promise.reject(response.data.errors);
    }
}

export default appVersionsReducer;