import { Box, Select } from '@mui/material';
import { styled } from '@mui/system';

export const SideBarContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-right: 20px;
`;

export const SideBarWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const TopBarControlPanelContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;
