import { connect } from 'react-redux';
import { compose } from 'redux';

import ReviewPage from './ReviewPage';

import {
  addAttractionReviewThunk,
  editAttractionReviewThunk,
  deleteAttractionReviewThunk,
  getFilteredAttractionReviewsThunk,
} from '../../redux/attractionsReducers/reviews/reviewActions';
import { getSmallAttractionsDataThunk } from '../../redux/attractionsReducers/attractions/attractionsActions';

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    attractionsReducer: state.attractionsReducer,
    reviewList: state.reviewReducer.reviewData,
    isReviewListLoading: state.reviewReducer.isLoading,
    globalSettings: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
    selectedDestinationId: state.appReducer.selectedDestination.id,
  };
};

const ReviewPageContainer = compose(
  connect(mapStateToProps, {
    addAttractionReviewThunk,
    editAttractionReviewThunk,
    deleteAttractionReviewThunk,
    getFilteredAttractionReviewsThunk,
    getSmallAttractionsDataThunk,
  })
)(ReviewPage);

export default ReviewPageContainer;
