import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormControlLabel } from '@mui/material';

const DealEndDateCheckboxField = ({ name, disabled }) => {
  const { t } = useTranslation();
  const { control, resetField } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <FormControlLabel
          label={t('attractions.deals.open')}
          labelPlacement="end"
          sx={{
            marginLeft: 0,
          }}
          control={
            <Checkbox
              disableRipple
              onChange={(event) => {
                onChange(event.target.checked);
                resetField('dealEndDate', {
                  defaultValue: null,
                  keepDirty: false,
                  keepError: false,
                  keepTouched: false,
                });
              }}
              disabled={disabled}
              checked={value}
              name={name}
            />
          }
        />
      )}
    />
  );
};

export default DealEndDateCheckboxField;
