import React, { useState, useEffect } from "react";
import useAPIError from '../../apIErrorProvider/useAPIError';
import {
    Box,
    CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

import AppVersionForm from './AppVersionForm';
import { setTitle } from "../../redux/appReducer";

const AppVersionPage = (props) => {
    const { addError } = useAPIError();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const appVersionState = props.appVersionsReducer;
    const [addAppVersion, setAppAppVersion] = useState(false);
    const [updateAppVersion, setUpdateAppVersion] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isSave, setIsSave] = useState(false);

    useEffect(() => {
        dispatch((setTitle('top_bar.main_title_app_version')));
        props.getAppVersionThunk();
    }, []);

    const onSubmitForm = (values) => {
        setIsSave(true);

        if (!appVersionState.version.version) {
            values.publishDate = new Date(values.publishDate.setHours(12, 0, 0, 0));
            props.addAppVersionThunk(
                {
                'version': values.version, 
                'urlAppStore': values.urlAppStore, 
                'urlGooglePlay': values.urlGooglePlay, 
                'publishDate': values.publishDate.toISOString()
            })
            .then(() => {
                props.getAppVersionThunk();
                setAppAppVersion(false);
                setUpdateAppVersion(false);
                setIsSave(false);
                setIsEdit(setIsEdit);
                addError(`${t("settings_page.app_version.error_add_text")}`, 'Success');
            })
            .catch((error) => {
                addError(`${t("settings_page.app_version.error_not_add_text")}`, 'Error', error);
                setIsSave(false);
            }) 
        } else if (appVersionState.version.version) {

            if(typeof values.publishDate.getMonth === 'function'){
                values.publishDate = new Date(values.publishDate.setHours(12,0,0,0)).toISOString();
            }

            props.editAppVersionThunk(
                appVersionState.version.id, 
                {'id': appVersionState.version.id, 
                'version': values.version, 
                'urlAppStore': values.urlAppStore, 
                'urlGooglePlay': values.urlGooglePlay, 
                'publishDate': values.publishDate
            })
            .then(() => {
                props.getAppVersionThunk();
                setIsEdit(false);
                setAppAppVersion(false);
                setUpdateAppVersion(false);
                setIsSave(false);
                addError(`${t("settings_page.app_version.error_edit_text")}`, 'Success');
            })
            .catch((error) => {
                addError(`${t("settings_page.app_version.error_not_edit_text")}`, 'Error', error);
                setIsSave(false);
            }) 
        }
    } 

    const onEditAppVersion = () => {
        setAppAppVersion(false);
        setIsEdit(true);
        setUpdateAppVersion(true);
    }


    if (!appVersionState.isLoading) {
        return (
            <Box component='div' sx={{position: 'absolute',top: '50%', right: '50%'}}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box component='div' sx={{ width: '100%', marginTop: '100px'}}>
            <AppVersionForm
                onSubmit={onSubmitForm}
                appVersionState={appVersionState}
                addAppVersion={addAppVersion}
                updateAppVersion={updateAppVersion}
                isEdit={isEdit}
                isSave={isSave}
                setIsSave={setIsSave}
                onEditAppVersion={onEditAppVersion}
            />
        </Box>
    );
}

export default AppVersionPage;