import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs, StyledEngineProvider } from '@mui/material';

import cl from './NotificationLanguageTabFields.module.scss';

import {
  NotificationLanguageTabField,
  NotificationFormFieldTitle,
  NotificationFormLanguageTabField,
} from '../styled';

import { validateTextFieldValue } from '../../../../helpers/validateTextFieldValue';
import { TEXT_EDITOR_FIELD_MAX_LENGTH } from '../constants';
import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../../consts/validation';
import NotificationFormTextEditorField from './NotificationFormTextEditorField';
import { TranslateButton } from '../../../../components/buttons/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { editDestinationNotificationTranslationThunk, setGeneralNotification } from '../../../../redux/generalNotificationsReducer/generalNotificationsActions';
import { removeKeysWithEmptyValueArrays, removeTextEditorValueKeysWithNoPlainText } from '../../../../helpers/translationObjectUtils';
import { formatLanguageFieldsData } from '../helpers';

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const NotificationLanguageTabFields = ({
  item,
  languages,
  handleChangeLanguageTab,
  currentTabId,
  disabled,
}) => {
  return (
    <Box className={cl.wrapper}>
      <StyledEngineProvider injectFirst>
        <Box className={cl.tabsContainer}>
          <Tabs
            value={currentTabId}
            onChange={handleChangeLanguageTab}
            indicatorColor={undefined}
            sx={{
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            {languages.map((tab, index) => (
              <Tab
                value={tab}
                label={tab}
                key={tab.id}
                {...a11yProps(index)}
                className={cl.tab}
              />
            ))}
          </Tabs>
        </Box>
        {createTabPanels({
          item,
          languages,
          currentTabId,
          disabled,
        })}
      </StyledEngineProvider>
    </Box>
  );
};

const createTabPanels = ({ item, languages, currentTabId, disabled }) => {
  return languages.map((languageTab, index) => (
    <TabPanel
      item = {item}
      value={currentTabId}
      index={languageTab}
      key={index}
      className={cl.tabPanel}
      fieldArrayName={languageTab}
      disabled={disabled}
    />
  ));
};

const TabPanel = (props) => {
  const { t } = useTranslation();
  const { item, fieldArrayName, value, index, disabled, ...other } = props;
  const dispatch = useDispatch();
  const { control, watch, setValue } = useFormContext();
  const values = watch();

  const { fields } = useFieldArray({
    name: fieldArrayName,
    control,
  });

  const defaultLanguage = useSelector(state => state.appReducer.selectedDestination.languages[0]);
  const selectedDestinationId =  useSelector(state => state.appReducer.selectedDestination.id);
  
  const updateFormValuesWithNotificationTranslation = (formValues, responseData, translationField) => {
    const updatedValues = { ...formValues };
    const translations = responseData[translationField];
  
    if (translations) {
      updatedValues[translationField] = { ...updatedValues[translationField], ...translations };
    }
  
    Object.keys(updatedValues).forEach((langKey) => {
      if (Array.isArray(updatedValues[langKey]) && updatedValues[langKey].length) {
        updatedValues[langKey] = updatedValues[langKey].map((langObj) => {
          if (langObj[translationField] !== undefined && translations[langKey]) {
            return { ...langObj, [translationField]: translations[langKey] };
          }
          return langObj;
        });
      }
    });
  
    return updatedValues;
  };

  const onTranslate = (translationField,translationLanguages) => {
    const fieldsWithTranslations = formatLanguageFieldsData(values);
    
    const updatedNotification = {
      destinationId: selectedDestinationId,
      notificationId: values.id,
      title: removeKeysWithEmptyValueArrays(fieldsWithTranslations.title),
      text: removeTextEditorValueKeysWithNoPlainText(fieldsWithTranslations.text),
      attractions: [...values.attractions],
      isGeneralNotification: values.isGeneralNotification,
      translationField,
      translationLanguages,
    };
  
    return dispatch(editDestinationNotificationTranslationThunk(updatedNotification))
      .then((response) => {
        const updatedFormValues = updateFormValuesWithNotificationTranslation(
          values,
          response,
          translationField
        );
        Object.keys(updatedFormValues).forEach((key) => {
          setValue(key, updatedFormValues[key]);
        });
        
        const newNotification = {
          ...response,
          [translationField]: response[translationField],
        }
        
        dispatch(
          setGeneralNotification(newNotification)
        );
      }).catch(error => {
        throw error;
      });
  };

  const isActiveTab = value === index;
  const langValue = value;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {isActiveTab && (
        <Box>
          {fields.map((field, index) => {
            return (
              <Box id={field.id} key={field.id}>
                <NotificationLanguageTabField>
                  <NotificationFormFieldTitle>
                    {t('notifications.title')}:
                  </NotificationFormFieldTitle>
                  <Controller
                    name={`${fieldArrayName}.${index}.title`}
                    control={control}
                    rules={{
                      required: {
                        value: isActiveTab,
                        message: t('attractions.deals.error_required'),
                      },
                      maxLength: {
                        value: DEFAULT_TEXT_FIELD_MAX_LENGTH,
                        message: t('attractions.deals.error_max_length', {
                          chars: DEFAULT_TEXT_FIELD_MAX_LENGTH,
                        }),
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <div style={{display: 'flex', alignItems:'center', width: '100%', gap: '10px'}}>
                        <NotificationFormLanguageTabField
                          value={value}
                          disabled={disabled}
                          onChange={(event) =>
                            onChange(validateTextFieldValue(event.target.value))
                          }
                          fullWidth
                          size="small"
                          error={!!error}
                          helperText={error?.message}
                        />
                        <TranslateButton 
                          title={t('notifications.title')} 
                          tab={langValue}
                          disabled = {disabled}
                          field={'title'}
                          data = {values && values.title}
                          notTranslated={item?.notTranslatedFields && item.notTranslatedFields.title}
                          differentData = {removeKeysWithEmptyValueArrays(formatLanguageFieldsData(values)?.title)[defaultLanguage] !== item?.title[defaultLanguage]}
                          handleSendTranslation={onTranslate}
                        />
                      </div>
                    )}
                  />
                </NotificationLanguageTabField>

                <NotificationLanguageTabField>
                  <NotificationFormFieldTitle>
                    {t('notifications.text')}:
                  </NotificationFormFieldTitle>
                  <div style={{display: 'flex', width: '100%', gap: '10px'}}>
                    <NotificationFormTextEditorField
                      name={`${fieldArrayName}.${index}.text`}
                      disabled={disabled}
                      maxLength={TEXT_EDITOR_FIELD_MAX_LENGTH}
                      required
                    />
                    <TranslateButton 
                      title={t('notifications.text')} 
                      tab={langValue}
                      disabled = {disabled}
                      field={'text'}
                      data = {values && values.text}  
                      notTranslated={item?.notTranslatedFields && item.notTranslatedFields.text}
                      differentData = {removeKeysWithEmptyValueArrays(formatLanguageFieldsData(values)?.text)[defaultLanguage] !== item?.text[defaultLanguage]}
                      handleSendTranslation={onTranslate}
                    />
                  </div>
                </NotificationLanguageTabField>
              </Box>
            );
          })}
        </Box>
      )}
    </div>
  );
};

export default NotificationLanguageTabFields;
