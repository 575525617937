import { SET_FACTS_LIST, SET_IS_FACTS_LOADING } from './factsActions';

const initialState = {
  factsList: [],
  isLoading: false,
};

const factsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FACTS_LIST:
      return {
        ...state,
        factsList: [...action.payload.data],
      };
    case SET_IS_FACTS_LOADING:
      return {
        ...state,
        isLoading: action.payload.data,
      };
    default:
      return state;
  }
};

export default factsReducer;
