import { MobileDatePicker } from '@mui/lab';
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { styled } from '@mui/system';

import { FormButton } from '../../../components/buttons/FormButtons';

export const AttractionReportFormDateField = styled(MobileDatePicker)`
  width: 100%;
`;

export const AttractionReportDateTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }

  & .MuiOutlinedInput-input {
    cursor: pointer;
  }

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }

  & .MuiInputAdornment-root {
    cursor: pointer;
  }
`;

export const AttractionReportFieldWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const AttractionReportTextInput = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
`;

export const ShowDetailsButton = styled(FormButton)`
  width: 100%;
`;

export const AttractionReportTableHead = styled(TableHead)`
  background-color: #d9d9d9;

  .MuiTableCell-root {
    padding: 5px 16px;
    font-weight: bold;

    :nth-of-type(1) {
      width: 10%;
    }
    :nth-of-type(2) {
      width: 40%;
    }
    :nth-of-type(3) {
      width: 20%;
    }
    :nth-of-type(4) {
      width: 30%;
    }
  }
`;

export const AttractionReportTableRow = styled(TableRow)`
  td {
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: none;
  }
`;

export const AttractionReportTableRowTotal = styled(TableRow)`
  background-color: #d9d9d9;

  td {
    font-weight: bold;
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: none;
  }
`;

export const AttractionReportDealTableHead = styled(TableHead)`
  background-color: #d9d9d9;

  .MuiTableCell-root {
    padding: 5px 16px;
    font-weight: bold;

    :nth-of-type(1) {
      width: 20%;
    }
    :nth-of-type(2) {
      width: 20%;
    }
    :nth-of-type(3) {
      width: 35%;
    }
    :nth-of-type(4) {
      width: 15%;
    }
  }
`;

export const AttractionReportDealTableRow = styled(TableRow)`
  td {
    line-height: 0;
    max-width: 100px;
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: none;
  }
`;

export const AttractionReportDealTable = styled(Table)`
  tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
`;

export const AttractionReportFormWrapper = styled(Box)`
  display: flex;
  flex-basis: 320px;
  max-width: 320px;
  flex: 1;
  flex-direction: column;
`;

export const LoaderWrapper = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AttractionReportSummaryHeadline = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export const AttractionReportHeaderContainer = styled(Box)`
  padding-left: 10px;
`;

export const AttractionReportPdfButton = styled(Button)`
  width: 100%;
`;
