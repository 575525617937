import { useTranslation } from 'react-i18next';

import {
  MainImageFormSubmitButton,
  MainImageFormButton,
  ControlPanelContainer,
} from '../styled';

const MainImageControlPanel = (props) => {
  const {
    showSaveAnimation,
    isInEditMode,
    onSubmit,
    onCancel,
    onEdit,
    onDelete,
    isInAddMode,
  } = props;

  const { t } = useTranslation();

  return (
    <ControlPanelContainer>
      <MainImageFormButton
        onClick={onCancel}
        label={t('cancel')}
        disabled={!isInEditMode}
      />
      {!isInAddMode && (
        <>
          <MainImageFormButton
            onClick={onEdit}
            label={t('edit')}
            disabled={isInEditMode}
          />
          <MainImageFormButton onClick={onDelete} label={t('delete')} />
        </>
      )}
      <MainImageFormSubmitButton
        onClick={onSubmit}
        label={t('save')}
        disabled={!isInEditMode}
        isLoading={showSaveAnimation}
      />
    </ControlPanelContainer>
  );
};

export default MainImageControlPanel;
