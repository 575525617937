import { useTranslation } from 'react-i18next';

import { Box, TableCell } from '@mui/material';

import { StyledAttractionTableBox, StyledVisitsTableRow } from '../styled';

import { STATUS_COLORS } from './constants';

const PointsTableDataRow = ({
  onClickRow,
  attractionRow,
  showRegionColumn,
  showAreaColumn,
  showLocationColumn,
}) => {
  const { t } = useTranslation();

  const {
    id,
    status,
    name,
    title,
    region,
    regionOrder,
    area,
    areaOrder,
    location,
    locationOrder,
  } = attractionRow;

  return (
    <StyledVisitsTableRow onClick={() => onClickRow(id)}>
      <TableCell
        sx={{
          backgroundColor: STATUS_COLORS[status],
        }}
      >
        {status && t(`attractions.attractions_status.${status?.toLowerCase()}`)}
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{title}</TableCell>
      {showRegionColumn && (
        <TableCell>
          <Box sx={{ display: 'flex' }}>
            <StyledAttractionTableBox>{region}</StyledAttractionTableBox>
            <Box>{regionOrder}</Box>
          </Box>
        </TableCell>
      )}
      {showAreaColumn && (
        <TableCell>
          <Box sx={{ display: 'flex' }}>
            <StyledAttractionTableBox>{area}</StyledAttractionTableBox>
            <Box>{areaOrder}</Box>
          </Box>
        </TableCell>
      )}
      {showLocationColumn && (
        <TableCell>
          <Box sx={{ display: 'flex' }}>
            <StyledAttractionTableBox>{location}</StyledAttractionTableBox>
            <Box>{locationOrder}</Box>
          </Box>
        </TableCell>
      )}
    </StyledVisitsTableRow>
  );
};

export default PointsTableDataRow;
