import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArticlesAddForm from './ArticlesAddForm';

import { formatLanguageFieldsData } from '../utils';

import {
  getExistingImages,
  getUniqueImages,
  uploadImageFiles,
} from '../../../attractions/content/reduxImageDrop/utils';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import { uploadImageFile } from '../../../../api/publishing/mainImageApi';
import { ARTICLE_STATUS } from '../../../../consts/consts';
import { createArticleRecordThunk } from '../../../../redux/publishingReducers/articlesReducer/articlesActions';
import {
  removeKeysWithEmptyValueArrays,
  removeTextEditorValueKeysWithNoPlainText,
} from '../../../../helpers/translationObjectUtils';

import { ARTICLES_TYPES } from '../constants';

const ArticlesAddFormContainer = ({
  createArticleRecordThunk,
  currentDestinationId,
  articleStatuses,
  selectedLanguage,
}) => {
  const { t } = useTranslation();
  const { addError } = useAPIError();
  const destinationLanguages = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );
  const articleTypeList = useSelector((state) =>
    state.appReducer.globalSettingsData.find(
      (item) => item.name.en === ARTICLES_TYPES
    )
  );
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const navigate = useNavigate();

  const mappedLanguages =
    destinationLanguages &&
    destinationLanguages.reduce((acc, lang) => {
      acc.push({
        id: lang,
        value: lang,
      });

      return acc;
    }, []);

  const languageList = Object.assign(
    {},
    {
      parameters: mappedLanguages,
    }
  );

  const languageFormFieldArrays =
    destinationLanguages &&
    destinationLanguages.reduce((acc, lang) => {
      acc[lang] = [
        {
          title: '',
          text: '',
        },
      ];

      return acc;
    }, {});

  const defaultValues = {
    ...languageFormFieldArrays,
    images: [],
    status: '',
    type: '',
  };

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { reset } = formMethods;

  const handleSubmit = (formData) => {
    setShowSaveAnimation(true);
    const uniqueAppImageFiles = getUniqueImages(formData.images);
    const existingAppImageFiles = getExistingImages(formData.images);

    Promise.all([uploadImageFiles(uniqueAppImageFiles, uploadImageFile, 'App')])
      .then((responses) => {
        const updatedAppImageUrlList = [
          ...existingAppImageFiles,
          ...responses[0].data,
        ];

        const fieldsWithTranslations = formatLanguageFieldsData(formData);

        const submitData = {
          destinationId: currentDestinationId,
          status: formData.status.length ? formData.status : null,
          images: updatedAppImageUrlList,
          type: formData.type,
          title: removeKeysWithEmptyValueArrays(fieldsWithTranslations.title),
          text: removeTextEditorValueKeysWithNoPlainText(
            fieldsWithTranslations.text
          ),
          ...formatLanguageFieldsData(formData),
        };

        return createArticleRecordThunk(submitData);
      })
      .then(() => {
        setShowSaveAnimation(false);
        addError(`${t('articles.notifications.create_success')}`, 'Success');
        navigate('/publishing/articles');
      })
      .catch((e) => {
        setShowSaveAnimation(false);
        addError(`${t('articles.notifications.create_error')}`, 'Error', e);
      });
  };

  const onCancel = () => navigate('/publishing/articles');

  useEffect(() => {
    if (destinationLanguages) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationLanguages, reset]);

  return (
    <>
      {destinationLanguages && mappedLanguages && articleStatuses && (
        <FormProvider {...formMethods}>
          <ArticlesAddForm
            languageList={languageList}
            isLoading={showSaveAnimation}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            articleStatuses={articleStatuses}
            selectedLanguage={selectedLanguage}
            articleTypeList={articleTypeList}
          />
        </FormProvider>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const articleStatuses =
    state.appReducer.globalSettingsData.find(
      (item) => item.name.en === ARTICLE_STATUS
    ) || null;

  return {
    currentDestinationId: state.appReducer.selectedDestination.id,
    selectedLanguage: state.appReducer.selectedLanguage,
    articleStatuses,
  };
};

export default connect(mapStateToProps, {
  createArticleRecordThunk,
})(ArticlesAddFormContainer);
