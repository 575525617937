import { connect } from 'react-redux';
import { compose } from 'redux';
import Predefined from './Predefined';

import { getPredefinedSettingsThunk } from '../../../redux/settingsReducers/predefinedReducer';
import { getUserSettingsThunk } from '../../../redux/usersReducers/users/userActions';

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.appReducer.selectedLanguage,
    appReducer: state.appReducer,
    predefinedReducer: state.predefinedReducer,
    userSettings: state.userReducer.userSettings,
  };
};

const PredefinedContainer = compose(
  connect(mapStateToProps, {
    getPredefinedSettingsThunk,
    getUserSettingsThunk,
  })
)(Predefined);

export default PredefinedContainer;
