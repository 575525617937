import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import { STATUS_FILTER_OPTIONS } from '../constants';
import {
  VisitorTableFilterFormWrapper,
  VisitorTableFilterSelectField,
} from '../styled';

import { setPaginationSettings } from '../../../../redux/usersReducers/visitors/visitorsActions';
import SearchField from '../../../../components/SearchField';
import { IconButton } from '@mui/material';

const useStyles = makeStyles({
  searchField: {
    '& .MuiOutlinedInput-root': {
      maxWidth: '235px',
    },
  },
});

const VisitorSearchForm = ({ paginationSettings, handleChangeSearchValue }) => {
  const { t } = useTranslation();
  const { control, reset } = useFormContext();

  const classes = useStyles();

  const dispatch = useDispatch();

  return (
    <form>
      <VisitorTableFilterFormWrapper
        display="flex"
        gap="24px"
        mt="30px"
        mb="20px"
      >
        <Controller
          name="status"
          control={control}
          render={({ field: { onChange, value } }) => (
            <VisitorTableFilterSelectField
              native
              onChange={(e) => {
                onChange(e.target.value);
                dispatch(
                  setPaginationSettings({
                    ...paginationSettings,
                    pageNumber: 0,
                  })
                );
              }}
              value={value}
              size="small"
            >
              {STATUS_FILTER_OPTIONS.map(({ value, label }) => (
                <option key={value} value={value}>
                  {t(label)}
                </option>
              ))}
            </VisitorTableFilterSelectField>
          )}
        />

        <SearchField
          name="searchQuery"
          placeholder={t('visitors.filterLabels.other')}
          className={classes.searchField}
          handleChange={handleChangeSearchValue}
        />
        <IconButton onClick={() => reset()}>
          <DeleteIcon />
        </IconButton>
      </VisitorTableFilterFormWrapper>
    </form>
  );
};

export default VisitorSearchForm;
