import { useTranslation } from 'react-i18next';

import {
  ArticlesFormSubmitButton,
  ArticlesFormButton,
  ControlPanelContainer,
} from '../styled';

const ArticlesFormControlPanel = (props) => {
  const { showSaveAnimation, isInEditMode, onSubmit, onCancel, onEdit,isLoading, onDelete, } = props;

  const { t } = useTranslation();

  return (
    <ControlPanelContainer>
      <ArticlesFormButton
        onClick={onCancel}
        label={t('cancel')}
        disabled={isLoading || !isInEditMode}
      />
      <>
        <ArticlesFormButton
          onClick={onEdit}
          label={t('edit')}
          disabled={isLoading || isInEditMode}
        />
        <ArticlesFormButton
          onClick={onDelete}
          label={t('delete')}
          disabled={isLoading || isInEditMode}
        />
      </>
      <ArticlesFormSubmitButton
        onClick={onSubmit}
        isLoading={isLoading}
        label={t('save')}
        disabled={!isInEditMode}
      />
    </ControlPanelContainer>
  );
};

export default ArticlesFormControlPanel;
