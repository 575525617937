const SET_PROFILE = 'SET_PROFILE';

const initialState = {
    name: '',
    picture: '',
    role: '',
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE:
            return {
                ...state,
                ...action.payload,  
            };
        default:
            return state;
    }
};

export let getProfileData = (name, picture, role) => {
    return { type: SET_PROFILE, payload: {name, picture, role}};
}

export default authReducer;