import { Tabs } from '@mui/material';

import LanguageTabPanel from './LanguageTabPanel';

import {
  LanguageTabsWrapper,
  LanguageTabsContainer,
  LanguageTabButton,
} from './styled';

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const FactsFormLanguageTabs = ({
  languages,
  onLanguageTabChange,
  activeTabId,
  disabled,
}) => {
  return (
    <LanguageTabsWrapper>
      <LanguageTabsContainer>
        <Tabs
          value={activeTabId}
          onChange={onLanguageTabChange}
          indicatorColor={undefined}
          sx={{
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          {languages.map((tab, index) => (
            <LanguageTabButton
              value={tab}
              label={tab}
              key={tab}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </LanguageTabsContainer>
      {languages.map((languageTab, index) => (
        <LanguageTabPanel
          activeTabId={activeTabId}
          tabId={languageTab}
          key={index}
          fieldArrayName={languageTab}
          disabled={disabled}
        />
      ))}
    </LanguageTabsWrapper>
  );
};

export default FactsFormLanguageTabs;
