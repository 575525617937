import { connect } from 'react-redux';
import { compose } from 'redux';

import Content from './Content';

import {
  updateSalesPointThunk,
  getSalesPointThunk,
} from '../../../redux/salesPointsReducer/salesPointsActions';

const mapStateToProps = (state) => {
  return {
    appState: state.appReducer,
    settingsState: state.settingsReducer,
    salesPointsReducer: state.salesPointsReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    updateSalesPointThunk,
    getSalesPointThunk,
  })
)(Content);
