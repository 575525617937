/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Box, CircularProgress } from '@mui/material';

import cl from './Categories.module.css';

import CategoryList from './CategoryList';
import { SelectStatus, SelectDestinationInput } from './Select';

import { FAQ_CATEGORY_STATUS } from '../../../consts/consts';
import CategoriesFilterForm from './filterForm/CategoriesFilterForm';
import { useSelector } from 'react-redux';

const CategoriesFaq = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const categoryState = props.categoryFaqReducer;
  const settingsState = props.settingsReducer;
  const appState = props.appReducer;
  const predefinedState = props.predefinedReducer;

  const statusValue = useSelector(
    (state) => state.form.CategoryFaqFilterForm?.values?.status
  );

  const destinationValue = useSelector(
    (state) => state.form.CategoryFaqFilterForm?.values?.destination
  );

  useEffect(() => {
    props.getCategoryFaqListThunk();
    props.getPredefinedSettingsThunk();

    return () => props.getSearchCategoryThunk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddCategory = () => {
    navigate('new-category');
  };

  const handleListItemClick = (index, item) => {
    navigate(`view/${item.id}`);
  };

  useEffect(() => {
    props.getSearchCategoryThunk(statusValue, destinationValue);
  }, [destinationValue, statusValue]);

  if (!categoryState.isLoadingData) {
    return (
      <Box sx={{ position: 'absolute', top: '50%', right: '50%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={cl.wrapper}>
      <div className={cl.leftBox}>
        <div className={cl.addButton}>
          <Button
            sx={{ color: '#fff', width: '100%', height: '100%' }}
            onClick={onAddCategory}
            variant="contained"
          >
            {t('faq_page.categories.add_button')}
          </Button>
        </div>
        <div className={cl.searchBox}>
          <CategoriesFilterForm
            selectedLanguage={appState.selectedLanguage}
            statusOptionsData={predefinedState.predefinedData.filter(
              (item) => item.name.en === FAQ_CATEGORY_STATUS
            )}
            destinationOptionsData={settingsState.destinationData}
          />
        </div>
        <CategoryList
          categoryState={categoryState.categoryData}
          handleListItemClick={handleListItemClick}
          selectedLanguage={appState.selectedLanguage}
        />
      </div>
      <div className={cl.rightBox}>
        <Outlet />
      </div>
    </div>
  );
};

export default CategoriesFaq;
