import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const PredefinedContainer = styled(Box)`
  margin-top: 40px;
`;

export const PredefinedWrapper = styled(Box)`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 15px;
  column-gap: 15px;
  position: relative;
  grid-auto-rows: min-content;
`;
export const PredefinedBox = styled(Box)``;

export const PredefinedTitleWrapper = styled(Box)`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -1px;
  color: #000000;
  margin: 0;
  text-align: left;
`;

export const PredefinedTitleText = styled('h4')`
  margin-bottom: 30px;
  width: 246px;
`;

export const PredefinedFieldWrapper = styled(Box)`
  width: 246px;
  border: 2px solid rgb(128, 128, 128);
  border-radius: 3.5px;
  margin-bottom: 16px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 7px 16px;
`;

export const PredefinedFieldText = styled('p')`
  margin: 0;
`;

export const LoaderWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  right: 50%;
`;
