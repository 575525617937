import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getAttractionsListThunk,
  getAttractionThunk,
  addAttractionsThunk,
  deleteAttractionsThunk,
  setAttraction,
  setSelectedRegion,
  setSelectedArea,
  setPageSize,
  setPageNumber,
  getAttractionSearchThunk,
  setIsLoadingListAttractions,
  setIsLoadingAttractions,
  setAddElement,
  setStatusValue,
  setAttractionsValue,
  setCategoryValue,
  setRegionValue,
  setAreaValue,
  setLocationValue,
} from '../../redux/attractionsReducers/attractions/attractionsActions';

import { setTitle } from '../../redux/appReducer';

import { getCategoryThunk } from '../../redux/settingsReducers/categoryReducer';

import { getPredefinedSettingsThunk } from '../../redux/settingsReducers/predefinedReducer';

import { getCompaniesListThunk } from '../../redux/usersReducers/company/companyActions';

import AttractionsPage from './AttractionsPage';

const mapStateToProps = (state) => {
  return {
    appReducer: state.appReducer,
    settingsReducer: state.settingsReducer,
    attractionsReducer: state.attractionsReducer,
    predefinedReducer: state.predefinedReducer,
    categoryReducer: state.categoryReducer,
    authState: state.authReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    setTitle,
    setAttraction,
    setSelectedRegion,
    setSelectedArea,
    setPageSize,
    setPageNumber,
    setIsLoadingListAttractions,
    setIsLoadingAttractions,
    setAddElement,
    setStatusValue,
    setAttractionsValue,
    setCategoryValue,
    setRegionValue,
    setAreaValue,
    setLocationValue,
    getAttractionsListThunk,
    getAttractionThunk,
    getAttractionSearchThunk,
    getCategoryThunk,
    getPredefinedSettingsThunk,
    getCompaniesListThunk,
    addAttractionsThunk,
    deleteAttractionsThunk,
  })
)(AttractionsPage);
