import { Controller, useFormContext } from 'react-hook-form';

import { LogFormSelectField } from '../../styled';
import { t } from 'i18next';

const createOptions = (options, selectedLanguage) => {
  if (!options) return null;

  return options.map((option, index) => (
    <option value={option.id} key={index}>
      {option.value[selectedLanguage]}
    </option>
  ));
};

const SelectField = ({
  name,
  options,
  selectedLanguage,
  disabled,
  defaultOptionLabel,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <LogFormSelectField
          native
          value={value}
          disabled={disabled}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          size="small"
        >
          <option value="">{t(defaultOptionLabel)}</option>
          {createOptions(options, selectedLanguage)}
        </LogFormSelectField>
      )}
    />
  );
};

export default SelectField;
