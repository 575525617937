import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import appVersionsReducer from './appVersionReducers/appVersionsReducer';
import translationsReducer from './translationsReducer/translationsReducer';
import settingsReducer from './settingsReducers/settingsReducer';
import categoryReducer from './settingsReducers/categoryReducer';
import facilitiesReducer from './settingsReducers/facilitiesReducer';
import predefinedReducer from './settingsReducers/predefinedReducer';
import tagsReducer from './settingsReducers/tagsReducer';
import authReducer from './authReducer';
import appReducer from './appReducer';
import attractionsReducer from './attractionsReducers/attractions/attractionsReducer';
import priceReducer from './attractionsReducers/priceReducer';
import faqQuestionReducer from './faqReducers/faqQuestionReducer';
import categoryFaqReducer from './faqReducers/categoryFaqReducer';
import thunkMiddleware from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import notificationReducer from './attractionsReducers/notificationReducer/notificationReducer';
import reviewReducer from './attractionsReducers/reviews/reviewReducer';
import availabilityReducer from './attractionsReducers/availabilityReducer';
import generalNotificationsReducer from './generalNotificationsReducer/generalNotificationsReducer';
import userReducer from './usersReducers/users/userReducer';
import companyReducer from './usersReducers/company/companyReducer';
import dealsReducer from './attractionsReducers/deals/dealsReducer';
import visitorsReducer from './usersReducers/visitors/visitorsReducer';
import productsReducer from './productsReducer/productsReducer';
import visitsReducer from './visits/visitsReducer';
import mainImageReducer from './publishingReducers/mainImageReducer/mainImageReducer';
import articlesReducer from './publishingReducers/articlesReducer/articlesReducer';
import routeChangeReducer from './routeChangeReducer/routeChangeReducer';
import factsReducer from './publishingReducers/factsReducer/factsReducer';
import salesPointsReducer from './salesPointsReducer/salesPointsReducer';
import logsReducer from './logsReducer/logsReducer';
import helpTopicsReducer from './publishingReducers/helpTopicsReducer/helpTopicsReducer';
import advertContentReducer from './publishingReducers/advertContentReducer/advertContentReducer';

let reducerAll = combineReducers({
  appVersionsReducer: appVersionsReducer,
  translationsReducer: translationsReducer,
  settingsReducer: settingsReducer,
  categoryReducer: categoryReducer,
  facilitiesReducer: facilitiesReducer,
  predefinedReducer: predefinedReducer,
  tagsReducer: tagsReducer,
  authReducer: authReducer,
  appReducer: appReducer,
  attractionsReducer: attractionsReducer,
  priceReducer: priceReducer,
  notificationReducer: notificationReducer,
  reviewReducer: reviewReducer,
  availabilityReducer: availabilityReducer,
  generalNotificationsReducer: generalNotificationsReducer,
  faqQuestionReducer: faqQuestionReducer,
  categoryFaqReducer: categoryFaqReducer,
  userReducer: userReducer,
  companyReducer: companyReducer,
  dealsReducer: dealsReducer,
  visitorsReducer,
  productsReducer,
  visitsReducer,
  mainImageReducer,
  articlesReducer,
  routeChangeReducer,
  factsReducer,
  salesPointsReducer,
  logsReducer,
  helpTopicsReducer,
  advertContentReducer,
  form: formReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducerAll,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
