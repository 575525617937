export const priceNumberValidator = (value) => {
  const isAllowed =
    /^(0|[1-9]\d*)([.]\d{0,2})?$/.test(value) &&
    value.replace(/(?!\.).$/g, '').length < 10;

  return value.length
    ? isAllowed
      ? { valid: true, value }
      : { valid: false }
    : { valid: true, value: '' };
};

export const validityNumberValidator = (value) => {
  const isAllowed = /[1-9]\d*$/.test(value) && value.length < 3;

  return value.length
    ? isAllowed
      ? { valid: true, value }
      : { valid: false }
    : { valid: true, value: '' };
};

export const formatLanguageFieldsData = (values) =>
  Object.keys(values).reduce(
    (acc, item) => {
      if (Array.isArray(values[item])) {
        const currLangObj = values[item][0];

        if (currLangObj.name.length) acc.name[item] = currLangObj.name;

        if (currLangObj.specification.length)
          acc.specification[item] = currLangObj.specification;
      }

      return acc;
    },
    { name: {}, specification: {} }
  );

export const mapProductTypeTranslations = (languages, products) => {
  return languages.reduce((acc, lang) => {
    acc[lang] = [
      {
        name: products.name[lang] || '',
        specification: products.specification[lang] || '',
      },
    ];

    return acc;
  }, {});
};
