import SalesPointListPageContainer from '../../pages/sales-points/SalesPointListPageContainer';
import SalesPointPageContainer from '../../pages/sales-points/SalesPointPageContainer';
import SetupContainer from '../../pages/sales-points/setup/SetupContainer';
import ContentContainer from '../../pages/sales-points/content/ContentContainer';
import AvailabilityList from '../../pages/sales-points/availability/AvailabilityList';
import SeasonContainer from '../../pages/sales-points/availability/SeasonContainer';

import { ADMIN, SYS_ADMIN } from '../consts';

export const salesPointsPageRoutes = [
  {
    path: '/points',
    element: <SalesPointListPageContainer />,
    showForRoles: [ADMIN, SYS_ADMIN],
  },
  {
    path: '/points',
    element: <SalesPointPageContainer />,
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      {
        element: <SetupContainer />,
        path: 'new',
        showForRoles: [ADMIN, SYS_ADMIN],
      },
    ],
  },
  {
    path: '/points/:id',
    element: <SalesPointPageContainer />,
    showForRoles: [ADMIN, SYS_ADMIN],
    children: [
      {
        element: <SetupContainer />,
        path: 'setup',
        showForRoles: [ADMIN, SYS_ADMIN],
      },
      {
        element: <ContentContainer />,
        path: 'content',
        showForRoles: [ADMIN, SYS_ADMIN],
      },
      {
        path: 'availability',
        children: [
          {
            index: true,
            element: <AvailabilityList />,
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            element: <SeasonContainer />,
            path: 'new-season',
            showForRoles: [ADMIN, SYS_ADMIN],
          },
          {
            element: <SeasonContainer />,
            path: 'edit/:seasonId',
            showForRoles: [ADMIN, SYS_ADMIN],
          },
        ],
      },
    ],
  },
];
