import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TextField, Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { dateIsValid } from '../../../notifications/forms/validation';

import { SelectInput } from '../../../../components/reduxFormComponents/reduxFormComponents';
import { DatePicker } from '../../../../components/notificationComponents/DatePicker';

const useStyles = makeStyles({
  form: {
    '& .MuiButton-root': {
      marginRight: '10px',
      color: '#fff',
      height: '35px',
    },
  },
  order: {
    width: '185px',
    '& .MuiTextField-root': {
      width: '185px',
      margin: '0px 0px 30px 0px',
    },
  },
  status: {
    width: '185px',
    marginBottom: '30px',
    '& .MuiTextField-root': {
      width: '185px',
      margin: '0px 0px 30px 0px',
    },
  },
});

let NotificationSearchForm = ({
  onSubmit,
  selectedLanguage,
  status,
  setStatus,
  attraction,
  setAttraction,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleResetFilters,
  ...props
}) => {
  const classes = useStyles();
  const availableStatuses = [...props.availableStatuses];
  const { t } = useTranslation();

  const getFilteredNotifications = (newStatus, newStartDate, newEndDate) => {
    const filter = {
      status: newStatus,
      startDate: dateIsValid(newStartDate) ? newStartDate : null,
      endDate: dateIsValid(newEndDate) ? newEndDate : null,
    };
    onSubmit(filter);
  };

  return (
    <form className={classes.form} onSubmit={props.handleSubmit}>
      <Stack direction="row" spacing={3} alignItems="center">
        <Box sx={{ minWidth: 120 }}>
          <Field
            name="status"
            component={SelectInput}
            onChange={(event, newStatus, prevValue, name) => {
              setStatus(newStatus);
              getFilteredNotifications(newStatus, startDate, endDate);
            }}
          >
            <option value={''}>{t('notifications.status')}</option>
            {availableStatuses[0].parameters.map((status, idx) => (
              <option key={idx} value={status.id}>
                {status.value[selectedLanguage] || status.value.en}
              </option>
            ))}
          </Field>
        </Box>
        <Field
          component={DatePicker}
          name="startDate"
          onChange={(event, newStartDate, prevValue, name) => {
            setStartDate(newStartDate);
            getFilteredNotifications(status, newStartDate, endDate);
          }}
          maxDate={endDate}
          label={t('notifications.start_date')}
          clearable
          clearText={t('clear')}
          renderInput={({ inputProps, ...restParams }) => (
            <TextField
              {...restParams}
              inputProps={{
                ...inputProps,
                placeholder: t('date'),
              }}
              size="small"
            />
          )}
        />
        <Field
          component={DatePicker}
          name="endDate"
          onChange={(event, newEndDate, prevValue, name) => {
            setEndDate(newEndDate);
            getFilteredNotifications(status, startDate, newEndDate);
          }}
          minDate={startDate}
          label={t('notifications.end_date')}
          clearable
          clearText={t('clear')}
          renderInput={({ inputProps, ...restParams }) => (
            <TextField
              {...restParams}
              inputProps={{
                ...inputProps,
                placeholder: t('date'),
              }}
              size="small"
            />
          )}
        />
        <IconButton onClick={handleResetFilters}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </form>
  );
};

export default NotificationSearchForm = reduxForm({
  form: 'NotificationSearchForm',
})(NotificationSearchForm);
