import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useState } from 'react';

import useAPIError from '../../../../apIErrorProvider/useAPIError';

import ReviewForm from './ReviewForm';
import {
  getAttractionReivewsListThunk,
  addAttractionReviewThunk,
} from '../../../../redux/attractionsReducers/reviews/reviewActions';
import { REVIEW_STATUS } from '../../../../consts/consts';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_REVIEW_LANGUAGE } from '../constants';

const ReviewAddFormContainer = (props) => {
  const {
    addAttractionReviewThunk,
    getAttractionReivewsListThunk,
    statusOptionList,
    selectedLanguage,
    currentAttraction,
  } = props;

  const { t } = useTranslation();
  const { addError } = useAPIError();
  const navigate = useNavigate();

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormSubmit = (formData) => {
    setShowSaveAnimation(true);

    formData.date = new Date(formData.date.setHours(12, 0, 0, 0));

    const newReview = {
      attractionId: currentAttraction.id,
      status: formData.status,
      userName: formData.userName,
      text: formData.text,
      language: DEFAULT_REVIEW_LANGUAGE,
      rating: parseInt(formData.rating),
      date: formData.date.toISOString(),
      userPlace: formData.userPlace,
      respondentPosition: formData.respondentPosition,
      reply: formData.reply,
    };

    addAttractionReviewThunk(newReview)
      .then(() => {
        setShowSaveAnimation(false);
        addError(`${t('reviews.success_add_review')}`, 'Success');
        getAttractionReivewsListThunk(currentAttraction.id);

        navigate(-1);
      })
      .catch((error) => {
        addError(`${t('reviews.error_add_review')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    navigate(-1);
  };

  return (
    <ReviewForm
      isInEditMode
      isInAddMode
      onSubmit={handleFormSubmit}
      onCancel={handleFormCancel}
      statusOptionList={statusOptionList}
      showSaveAnimation={showSaveAnimation}
      selectedLanguage={selectedLanguage}
    />
  );
};

const mapStateToProps = (state) => {
  const statusOptionList = state.appReducer.globalSettingsData.find(
    (item) => item.name.en === REVIEW_STATUS
  );

  return {
    statusOptionList,
    currentAttraction: state.attractionsReducer.currentAttraction,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  addAttractionReviewThunk,
  getAttractionReivewsListThunk,
})(ReviewAddFormContainer);
