import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../consts/validation';

const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength10 = maxLength(10);
export const maxLength250 = maxLength(DEFAULT_TEXT_FIELD_MAX_LENGTH);
export const maxLength100 = maxLength(100);

export const onlyNumber = (value) =>
  value && isNaN(Number(value)) ? 'Enter the number' : undefined;
export const negativeNumber = (value) =>
  value && value < 0 ? 'Order cannot be a negative number' : undefined;

export const requiredDestinationsName = (values) =>
  !values ? 'Enter name' : undefined;
export const requiredLocationsName = (values) =>
  !values ? 'Enter name' : undefined;
export const requiredTitle = (values) => (!values ? 'Enter title' : undefined);
export const requiredSubtitle = (values) =>
  !values ? 'Enter subtitle' : undefined;
export const noSpaceName = (values) =>
  values && values.trim() === ''
    ? 'Name cannot consist of just spaces'
    : undefined;

export const requiredLevels = (values) =>
  !values ? 'Enter VAT levels' : undefined;

export const requiredRegionSpace = (values) =>
  /^[ \s]+|[ \s]+$/g.test(values) ? 'Name cannot end with a space' : undefined;
