import {
  SET_NOTIFICATIONS_LIST,
  IS_LOADED_NOTIFICATIONS,
  SET_NOTIFICATION,
} from './notificationActions.js';

const initialState = {
  notificationData: [],
  isLoaded: false,
};

const attractionsNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS_LIST:
      return {
        ...state,
        notificationData: [...action.payload.data],
      };
    
    case SET_NOTIFICATION:
        const {data} = action.payload;
        
        const updatednotificationData = state.notificationData.map(notification => {
          if(notification.id === data.id) {
            return data
          }
          return notification
        })
        
      return {
        ...state,
        notificationData: updatednotificationData,
      };
    case IS_LOADED_NOTIFICATIONS:
      return {
        ...state,
        isLoaded: action.isLoadedNotification,
      };
    default:
      return state;
  }
};

export default attractionsNotificationReducer;
