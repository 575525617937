import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { VisitorFormSelectField } from '../styled';

const SelectField = ({ name, options, renderOptions, disabled, required }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required,
          message: t('attractions.deals.error_required'),
        },
      }}
      render={({ field: { onChange, value } }) => (
        <VisitorFormSelectField
          native
          value={value}
          disabled={disabled}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          size="small"
        >
          {renderOptions(options)}
        </VisitorFormSelectField>
      )}
    />
  );
};

export default SelectField;
