import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { makeStyles } from '@mui/styles';
import { Box, Typography, Tab, Tabs } from '@mui/material';

import { TextArea } from './FormComponent';

import { requiredName, maxLength1000, noSpaceName } from './validate';

const useStyles = makeStyles({
  tabs: {
    minWidth: '320px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    marginBottom: '7px',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    '& .MuiTab-root': {
      marginRight: '7px',
    },
  },
  tabPanel: {
    width: '100%',
    fontSize: '0.875rem',
    '& .MuiBox-root': {
      padding: '15px 0px',
    },
    '& .MuiOutlinedInput-root': {
      '& .Mui-disabled': {
        color: '#000',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.90)',
      },
    },
    '& .MuiOutlinedInput-input': {
      minHeight: '250px',
    },
  },
});

const styleTab = {
  color: '#fff',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  backgroundColor: 'rgba(0,0,0, 0.51)',
  width: '100%',
  maxWidth: '50px',
  minWidth: '20px',
  maxHeight: '36px',
  minHeight: '20px',
  padding: '12px 16px',
  margin: '6px 0px',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  indicatorColor: 'secondary',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0, 0.40)',
  },
  '&:focus': {
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&:first-child': {
    marginLeft: '4px',
  },
  '&:last-child': {
    marginRight: '4px',
  },
  '&.Mui-selected': {
    backgroundColor: '#FFA94C',
    color: '#fff',
    borderRadius: '3px',
    outline: '2px solid #FFA94C',
    outlineOffset: '2px',
  },
  '&.disabled': {
    opacity: '0.5',
    cursor: ' not-allowed',
  },
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const LanguageTabs = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabsValue, setTabsValue] = useState(0);

  const handleChangeTabs = (_, newValue) => {
    setTabsValue(newValue);
  };

  const CreateTab = props.languageList.map((tab, index) => (
    <Tab label={tab.value} key={tab.id} {...a11yProps(index)} sx={styleTab} />
  ));

  return (
    <>
      <Box className={classes.tabs}>
        <Tabs
          value={tabsValue}
          indicatorColor="none"
          onChange={handleChangeTabs}
        >
          {CreateTab}
        </Tabs>
      </Box>
      {props.languageList.map((tabPanel, index) => (
        <TabPanel
          className={classes.tabPanel}
          value={tabsValue}
          index={index}
          sx={{ position: 'relative' }}
          key={tabPanel.id}
        >
          <Box component="div" sx={{ marginBottom: '30px' }}>
            <Field
              name={tabPanel.value}
              component={TextArea}
              text={`${t('settings_page.translation.no_translation')}`}
              validate={[maxLength1000, requiredName, noSpaceName]}
              disabled={!props.isEdit}
            />
            {props.errorText && (
              <Typography
                sx={{ color: '#d43131' }}
                variant="caption"
                display="block"
                component="span"
              >
                {`* ${props.errorText}`}
              </Typography>
            )}
          </Box>
        </TabPanel>
      ))}
    </>
  );
};

export default LanguageTabs;
