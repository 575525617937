import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { getCurrentUserThunk, confirmAgreementUserThunk } from '../../redux/usersReducers/users/userActions';
import { getArticleListThunk } from '../../redux/publishingReducers/articlesReducer/articlesActions';
import { getCurrentCompanyThunk } from '../../redux/usersReducers/company/companyActions';

import useAPIError from '../../apIErrorProvider/useAPIError';

import {
  AgreementPageWrapper,
  AgreementFieldWrapper,
  AgreementFieldTitle,
  AgreementPageContainer,
  AgreementConfirmButton,
} from './styled';


const HTMLRenderer = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

const AgreementPage = () => {
  const { t } = useTranslation();
  const { addError } = useAPIError();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.userReducer.currentUser);
  const currentCompany = useSelector(state => state.companyReducer.currentCompany);
  const articlesList = useSelector(state => state.articlesReducer);
  const selectedLanguage = useSelector(state => state.appReducer.selectedLanguage);
  const currentDestinationId = useSelector(state => state.appReducer.selectedDestination.id);

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);
  const [showAgreementText, setShowAgreementText] = useState(false);
  const [showConfirmationButton, setShowConfirmationButton] = useState(false);

  const handleConfirmAgreement = () => {
    setShowSaveAnimation(true);

    dispatch(confirmAgreementUserThunk())
      .then(() => {
        addError(`${t('agreement.success_agreement_confirmed')}`, 'Success');
        dispatch(getCurrentUserThunk());
        setShowSaveAnimation(false);
      })
      .catch(() => {
        addError(`${t('agreement.error_agreement_confirmed')}`, 'Error');
        setShowSaveAnimation(false);
      });
  };

  useEffect(() => {
    dispatch(getCurrentUserThunk());
    if(currentUser.idCompany) dispatch(getCurrentCompanyThunk(currentUser.idCompany));
    dispatch(getArticleListThunk(currentDestinationId, "Agreement"));
  }, []);

  useEffect(() => {
    if(currentUser && currentUser.agreementConfirmed) {
      setShowConfirmationButton(true);
    }
    else {
      setShowConfirmationButton(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if(articlesList?.articleList[0] && articlesList?.articleList[0]?.status !== 'Draft') {
      setShowAgreementText(true);
    }
    else {
      setShowAgreementText(false);
    }
  }, [articlesList]);

  return (
    <AgreementPageWrapper>
      <AgreementPageContainer>
        <AgreementFieldWrapper>
          <AgreementFieldTitle fontWeight="bold" textTransform="uppercase">
            {t('agreement.coolpass_info')}:
          </AgreementFieldTitle>
        </AgreementFieldWrapper>
        <AgreementFieldWrapper>
          <Typography>CoolPass, spol. s.r.o.</Typography>
        </AgreementFieldWrapper>
        <AgreementFieldWrapper>
          <Typography>Na Strži 2102/61a, Praha 4</Typography>
        </AgreementFieldWrapper>
        <AgreementFieldWrapper>
          <Typography>IČ: 25059572</Typography>
        </AgreementFieldWrapper>
        <AgreementFieldWrapper>
          <Typography>Slavomír Horský, jednatel</Typography>
        </AgreementFieldWrapper>

        <AgreementFieldWrapper>
          <AgreementFieldTitle fontWeight="bold" textTransform="uppercase">
            {t('agreement.company_info')}:
          </AgreementFieldTitle>
        </AgreementFieldWrapper>
        <AgreementFieldWrapper>
          <AgreementFieldTitle>
            {t('agreement.company_name')}:
          </AgreementFieldTitle>
          <Typography>{currentCompany?.name}</Typography>
        </AgreementFieldWrapper>
        <AgreementFieldWrapper>
          <AgreementFieldTitle>
            {t('agreement.adress')}:
          </AgreementFieldTitle>
          <Typography>{`${currentCompany?.streetAddress}, ${currentCompany?.city}`}</Typography>
        </AgreementFieldWrapper>
        <AgreementFieldWrapper>
          <AgreementFieldTitle>
            {t('agreement.company_reg_number')}:
          </AgreementFieldTitle>
          <Typography>{currentCompany?.registrationNumber}</Typography>
        </AgreementFieldWrapper>
        <AgreementFieldWrapper>
          <AgreementFieldTitle>
            {t('agreement.username')}:
          </AgreementFieldTitle>
          <Typography>{currentUser?.name}</Typography>
        </AgreementFieldWrapper>
        <AgreementFieldWrapper>
          <AgreementFieldTitle>
            {t('agreement.position')}:
          </AgreementFieldTitle>
          <Typography>{currentUser?.position}</Typography>
        </AgreementFieldWrapper>

        {showAgreementText && (
          <AgreementFieldWrapper>
            <Typography
              component={'span'}
              style={{ padding:'10px', width: '67%', maxHeight: '500px', overflowY: 'auto', border: '1px solid' }}
            >
              <HTMLRenderer 
                htmlContent={articlesList?.articleList[0]?.status !== 'Draft' ? articlesList?.articleList[0]?.text[selectedLanguage] : null}
              />
            </Typography>
          </AgreementFieldWrapper>
        )}

        {showConfirmationButton ? (
          <Typography style={{paddingTop: '15px', fontWeight: 'bold'}} variant="body1">
            {t('agreement.confirmed_by')} {currentUser.name.toUpperCase()}, {currentUser.position.toUpperCase()} {t('agreement.on')} {new Date(currentUser.agreementConfirmed).toLocaleDateString()}
          </Typography>
        ) : (
          <Box mt="10px" mb="10px">
            <AgreementConfirmButton
              label={t('agreement.confirm')}
              isLoading={showSaveAnimation}
              disabledIcon={true}
              variant="contained"
              disabled={!showAgreementText}
              onClick={handleConfirmAgreement}
            />
          </Box>
        )}
      </AgreementPageContainer>
    </AgreementPageWrapper>
  )
}

export default AgreementPage
