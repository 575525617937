export const ATTRACTION_MANAGER_ROLE = 'attraction';
export const DEFAULT_CATEGORY_INPUT_VALUE = {
  id: '',
  name: '',
};

export const DEFAULT_AUTOCOMPLETE_VALUE = {
  id: '',
  title: '',
};

export const SALES_POINTS_PAGE_ROUTE = '/points';

export const SETUP_TAB_INDEX = 0;
export const LAST_ACTIVE_TAB_INDEX = 2;

export const TYPE_LOCATION = 'Location';
export const TYPE_AREA = 'Area';
export const TYPE_REGION = 'Region';
