import { TYPE_AREA, TYPE_LOCATION, TYPE_REGION } from './constants';

export const getAreaOptionList = (destinationData, regionData) => {
  if (!destinationData) return [];

  if (destinationData.enabledLocationTypes.includes(TYPE_REGION)) {
    if (!regionData) return [];

    const selectedRegion = destinationData.locations.find(
      (reg) => reg.id === regionData.id
    );

    if (!selectedRegion) return [];

    return selectedRegion.children;
  }

  return destinationData.locations;
};

export const getLocationOptionList = (destinationData, regionData, areaId) => {
  if (!destinationData) return [];

  if (destinationData.enabledLocationTypes.includes(TYPE_REGION)) {
    if (!regionData) return [];

    const selectedRegion = destinationData.locations.find(
      (reg) => reg.id === regionData
    );

    if (!selectedRegion) return [];

    if (destinationData.enabledLocationTypes.includes(TYPE_AREA)) {
      if (!areaId) return [];

      const selectedArea = selectedRegion.children.find(
        (area) => area.id === areaId
      );

      return selectedArea.children;
    }

    return selectedRegion.children;
  }

  if (destinationData.enabledLocationTypes.includes(TYPE_AREA)) {
    const selectedArea = destinationData.locations.find(
      (area) => area.id === areaId
    );

    if (!selectedArea) return [];

    return selectedArea.children;
  }

  if (destinationData.enabledLocationTypes.includes(TYPE_LOCATION)) {
    return destinationData.locations;
  }

  return [];
};
