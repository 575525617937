export const faqTabs = [
  {
    name: 'faq_page.questions_tab',
    path: 'questions',
    matchUrls: ['questions'],
  },
  {
    name: 'faq_page.categories_tab',
    path: 'categories',
    matchUrls: ['categories'],
  },
];

const firstTabIndex = 0;

export const getOpenedTabIdByUrlParameter = (tabs, location) => {
  if (!location) return firstTabIndex;

  const splitedLocation = location.split('/');
  const tabName = splitedLocation[2];

  if (!tabName) return firstTabIndex;

  const tabId = tabs.findIndex((tab) => tab.matchUrls.includes(tabName));

  if (tabId === -1) return firstTabIndex;

  return tabId;
};
