export const createSeparatedOTPinput = (
  customClass,
  separatorElementName = 'span',
  separatorText = '-'
) => {
  const otpInputs = document.querySelectorAll('[aria-label]');

  Array.from(otpInputs).forEach((el) => {
    const elementLabel = el.ariaLabel;

    if (
      elementLabel.match(/Character 1$/) ||
      elementLabel.match(/^Character 4$/) ||
      elementLabel.match(/^Character 7$/)
    ) {
      const separatorElement = document.createElement(separatorElementName);
      separatorElement.classList.add(customClass);
      separatorElement.innerHTML = separatorText;

      el.after(separatorElement);
    }
  });
};

export const concatConfirmationCode = (code) => {
  const firstCodeSymbol = code[0];
  const codeWithSeparator =
    code &&
    code
      .substring(1)
      .match(/.{1,3}/g)
      .join('-');

  return `${firstCodeSymbol}-${codeWithSeparator}`;
};
