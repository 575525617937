import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import AttractionReportDateFieldController from './formFields/AttractionReportDateFieldController';
import AttractionReportTextField from './formFields/AttractionReportTextField';
import AttractionReportShowDetailsField from './formFields/AttractionReportShowDetailsField';

import { AttractionReportFieldWrapper, ShowDetailsButton } from '../styled';

const ReportForm = ({ onSubmit, dateFromValue, dateToValue }) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();

  return (
    <form style={{ width: '100%' }}>
      <Box display="flex" flexDirection="column" gap="15px">
        <AttractionReportFieldWrapper>
          <Typography mb="5px" lineHeight="16px">
            {t('attractions.reports.reportFormLabels.attraction')}
          </Typography>
          <AttractionReportTextField name="attraction" disabled />
        </AttractionReportFieldWrapper>

        <AttractionReportFieldWrapper>
          <Typography mb="5px" lineHeight="16px">
            {t('attractions.reports.reportFormLabels.from')}
          </Typography>
          <AttractionReportDateFieldController
            name="reportDateFrom"
            required
            maxDate={dateToValue ? dateToValue : new Date()}
          />
        </AttractionReportFieldWrapper>

        <AttractionReportFieldWrapper>
          <Typography mb="5px" lineHeight="16px">
            {t('attractions.reports.reportFormLabels.to')}
          </Typography>
          <AttractionReportDateFieldController
            name="reportDateTo"
            required
            minDate={dateFromValue}
            maxDate={new Date()}
          />
        </AttractionReportFieldWrapper>

        <AttractionReportShowDetailsField name="showDetailedReport" />

        <ShowDetailsButton
          label={t('attractions.reports.showReportButton')}
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
    </form>
  );
};

export default ReportForm;
