import { LocalizationProvider } from '@mui/lab';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledMobileDateTimePicker } from '../../styled';
import { useState } from 'react';
import { LogFormDatePickerTextField } from '../../styled';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyles = makeStyles({
  dateFieldInputDisabled: {
    '& .MuiOutlinedInput-input': {
      cursor: 'default',
    },

    '& .MuiInputAdornment-root': {
      cursor: 'default',
    },
  },
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AutocompleteField = (props) => {
  const { name, disabled, options, getOptionLabel } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={error} fullWidth>
          <Autocomplete
            id="combo-box-demo"
            options={options}
            value={value}
            onChange={(_, newValue) => {
              onChange(newValue);
            }}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} key={option.id}>
                  {getOptionLabel(option)}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.label}
                size="small"
                required={props.required}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                error={!!error}
                helperText={error}
              />
            )}
            renderTags={(value, getTagProps) => {
              return (
                <Box>
                  {value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      label={getOptionLabel(option)}
                    />
                  ))}
                </Box>
              );
            }}
            {...props}
          />
        </FormControl>
      )}
    />
  );
};
