export const formatLanguageFieldsData = (values) =>
  Object.keys(values).reduce(
    (acc, item) => {
      if (item !== 'attractions' && item !== 'notTranslatedFields') {
        if (Array.isArray(values[item])) {
          const currLangObj = values[item][0];

          if (currLangObj.title.length)
            acc.title[item] = currLangObj.title.trim();

          if (currLangObj.text.length) acc.text[item] = currLangObj.text.trim();
        }
      }
      return acc;
    },
    { title: {}, text: {} }
  );
