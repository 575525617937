import * as React from 'react';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTitle } from '../../redux/appReducer';

const Reports = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch((setTitle('top_bar.main_title_reports')));
    }, [])

    return (
        <Box component='div' sx={{ width: '1300px', m: '95px auto' }}>
            Page is in development
        </Box>
    );
}

export default Reports;
