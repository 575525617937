import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { AdvertContentFormTextEditor } from '../../styled';
import { EMPTY_HTML_TAG_LENGTH } from '../../constants';
import { stripHtmlTags } from '../../utils';

const AdvertContentFormTextEditorField = ({
  name,
  disabled,
  required,
  maxLength,
  ...props
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const [firstRender, setFirstRender] = useState(true);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        ...(required && {
          validate: (value) =>
            value.length > EMPTY_HTML_TAG_LENGTH
              ? null
              : t('advertWindow.validation.required'),
        }),
        validate: (value) => {
          const textContent = stripHtmlTags(value);
          return textContent.length <= maxLength
            ? null
            : t('advertWindow.validation.max_length', {
                chars: maxLength,
              });
        },
      }}
      render={({
        field: { onChange, value },
        fieldState: { error, isDirty, isTouched },
      }) => (
        <AdvertContentFormTextEditor
          {...props}
          meta={{
            dirty: isDirty,
            touched: !!error,
            error: error?.message,
          }}
          input={{ onChange, value }}
          isEdit={!disabled}
          valueDirty={firstRender}
          setValueDirty={setFirstRender}
        />
      )}
    />
  );
};

export default AdvertContentFormTextEditorField;
