import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const TagFormWrapper = styled(Box)`
  margin-top: 30px;
`;

export const TagFormControlPanel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
