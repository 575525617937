import {
  TextField,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Select,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  errorMessage: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-18px',
      marginLeft: 0,
    },
  },
});

const HelperText = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return (
      <FormHelperText sx={{ color: '#d43131' }}>
        * {touched && error}
      </FormHelperText>
    );
  }
};

export const CheckboxInput = ({ input, label, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value}
          onChange={input.onChange}
          sx={{ padding: '4px 4px 4px 8px' }}
        />
      }
      {...props}
      label={label}
    />
  );
};

export const CheckboxDisabledInput = ({ input, label, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={props.disabled}
          checked={props.isChecked}
          sx={{ padding: '4px 4px 4px 8px' }}
        />
      }
      {...props}
      label={label}
    />
  );
};

export const SelectInput = ({
  input,
  label,
  meta: { touched, error },
  children,
  disabled,
  ...props
}) => {
  return (
    <FormControl error={touched && error} fullWidth disabled={disabled}>
      <Select
        displayEmpty
        native
        value={input.value}
        onChange={input.onChange}
        size="small"
      >
        {children}
      </Select>
      {HelperText({ touched, error })}
    </FormControl>
  );
};

export const InputForm = ({
  label,
  input,
  meta: { touched, invalid, error },
  text,
  length,
  ...props
}) => {
  const classes = useStyles();
  const hasError = touched && error;

  return (
    <TextField
      className={classes.errorMessage}
      label={label}
      placeholder={text}
      error={hasError}
      helperText={hasError ? `${error}` : ''}
      {...input}
      {...props}
      size="small"
    />
  );
};
