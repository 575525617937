import { stopSubmit } from 'redux-form';
import {
  getTranslationsApi,
  getSearchKeyApi,
  getTranslationCategoryApi,
  addKeyApi,
  editKeyApi,
  deleteKeyApi,
} from '../../api/translationsApi';

export const SET_TRANSLATIONS_DATA = 'translations/setTranslationsData';
export const SET_CURRENT_KEY = 'translations/setCurrentKey';
export const SET_IS_LOADING_TRANSLATIONS_DATA =
  'translations/setIsLoadingTranslationsData';
export const SET_CATEGORY_DATA = 'translations/setCategoryData';
export const SET_IS_LOADING_CATEGORY_DATA =
  'translations/setIsLoadingCategoryData';

const setTranslationsList = (data) => {
  return { type: SET_TRANSLATIONS_DATA, payload: { data } };
};

export const setCurrentKey = (data) => {
  return { type: SET_CURRENT_KEY, payload: { data } };
};

const setIsLoadingTranslationsListData = (isLoading) => {
  return { type: SET_IS_LOADING_TRANSLATIONS_DATA, isLoading };
};

const setCategoryData = (data) => {
  return { type: SET_CATEGORY_DATA, payload: { data } };
};

const setIsLoadingCategoryData = (isLoading) => {
  return { type: SET_IS_LOADING_CATEGORY_DATA, isLoading };
};

export const getTranslationsListThunk = (destinationId) => async (dispatch) => {
  const response = await getTranslationsApi(destinationId);
  if (response.status >= 200 && response.status < 300) {
    dispatch(setTranslationsList(response.data));
    dispatch(setIsLoadingTranslationsListData(true));
  }
};
export const getTranslationsSearchThunk =
  (destinationId, category) => async (dispatch) => {
    const response = await getSearchKeyApi(destinationId, category);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setTranslationsList(response.data));
      dispatch(setIsLoadingTranslationsListData(true));
    }
  };

export const getCategoryDataThunk = () => async (dispatch) => {
  const response = await getTranslationCategoryApi();
  if (response.status >= 200 && response.status < 300) {
    dispatch(setCategoryData(response.data));
    dispatch(setIsLoadingCategoryData(true));
  }
};

export const addKeyThunk = (data) => async (dispatch) => {
  const response = await addKeyApi(data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('TranslationForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const editKeyThunk = (id, data) => async (dispatch) => {
  const response = await editKeyApi(id, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('TranslationForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const deleteKeyThunk = (id) => async () => {
  const response = await deleteKeyApi(id);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
