import { Box, FormHelperText } from '@mui/material';
import { styled } from '@mui/system';

export const TextEditorContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextEditorWrapper = styled(Box)((props) => ({
  border: '1px solid #d6d6d6',
  padding: '10px !important',
  minHeight: '250px',
  borderRadius: '4px',
  '&::placeholder': {
    opacity: props.isEdit ? 1 : 0.8,
  },
  '& span': {
    opacity: props.isEdit ? 1 : 0.8,
  },
  width: '100%',
}));

export const TextEditorErrorMessage = styled(Box)`
  color: #d43131;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  height: 0.75rem;
`;
