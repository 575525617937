import {
  SET_ADVERT_CONTENT_LIST,
  SET_IS_LOADING,
} from './advertContentActions';

const initialState = {
  contentList: [],
  isLoading: false,
};

const advertContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADVERT_CONTENT_LIST:
      return {
        ...state,
        contentList: [...action.payload],
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default advertContentReducer;
