import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddNewFactForm from './AddNewFactForm';

import { formatLanguageFieldsData } from '../utils';

import useAPIError from '../../../../apIErrorProvider/useAPIError';
import { addFactThunk } from '../../../../redux/publishingReducers/factsReducer/factsActions';

const AddNewFactFormContainer = ({ addFactThunk, currentDestinationId }) => {
  const { t } = useTranslation();
  const { addError } = useAPIError();
  const destinationLanguages = useSelector(
    (state) => state.appReducer.selectedDestination.languages
  );

  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const navigate = useNavigate();

  const languageFormFieldArrays =
    destinationLanguages &&
    destinationLanguages.reduce((acc, lang) => {
      acc[lang] = [
        {
          title: '',
          text: '',
        },
      ];

      return acc;
    }, {});

  const defaultValues = {
    ...languageFormFieldArrays,
  };

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { reset } = formMethods;

  const handleSubmit = (formData) => {
    setShowSaveAnimation(true);

    const submitData = {
      destinationId: currentDestinationId,
      ...formatLanguageFieldsData(formData),
    };

    addFactThunk(submitData)
      .then(() => {
        addError(`${t('facts.notifications.update_success')}`, 'Success');
        setShowSaveAnimation(false);
        navigate('/publishing/did-you-know');
      })
      .catch((error) => {
        addError(`${t('facts.notifications.update_error')}`, 'Error', error);
        setShowSaveAnimation(false);
      });
  };

  const onCancel = () => navigate('/publishing/did-you-know');

  useEffect(() => {
    if (destinationLanguages) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationLanguages, reset]);

  return (
    <>
      {destinationLanguages && (
        <FormProvider {...formMethods}>
          <AddNewFactForm
            languageList={destinationLanguages}
            isLoading={showSaveAnimation}
            onSubmit={handleSubmit}
            onCancel={onCancel}
          />
        </FormProvider>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentDestinationId: state.appReducer.selectedDestination.id,
  };
};

export default connect(mapStateToProps, {
  addFactThunk,
})(AddNewFactFormContainer);
