import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TextField, Box, Select } from '@mui/material';

const useStyles = makeStyles({
  field: {
    maxWidth: '215px',
    width: '100%',
  },
  iconButton: {
    position: 'absolute',
    right: '10px',
    top: '7px',
    width: '25px',
    height: '25px',
    color: '#cfcaca',
  },
  searchFieldWrapper: {
    position: 'relative',
    width: '300px',
    height: '40px',
    '& .MuiOutlinedInput-root': {
      height: '40px',
      width: '300px',
    },
  },
});

const ArticlesFilterForm = (props) => {
  const { selectedLanguage, typeValue, setTypeValue, articleTypeListOptions } =
    props;

  const classes = useStyles();
  const { t } = useTranslation();

  const handleArticleTypeChange = (e) => {
    setTypeValue(e.target.value);
  };

  return (
    <Box display="flex" gap="24px" mt="30px" mb="20px">
      <Box sx={{ minWidth: 120 }}>
        <Select
          size="small"
          native
          className={classes.field}
          onChange={handleArticleTypeChange}
          value={typeValue}
        >
          <option value={''}>{t('articles.all_types')}</option>
          {articleTypeListOptions?.parameters.map((status, i) => (
            <option value={status.id} key={i}>
              {status.value[selectedLanguage] || status.value.en}
            </option>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default ArticlesFilterForm;
