import { useTranslation } from 'react-i18next';

import ArticlesFormImageField from './formFields/ArticlesFormImageField';
import ArticlesFormStatusField from './formFields/ArticlesFormStatusField';
import ArticlesFormLanguageTabs from './languageTabs/ArticlesFormLanguageTabs';

import { ALLOWED_FILE_TYPES } from '../../HomePageTab/constants';
import { UNLIMITED_FILES_LIMIT } from '../../HomePageTab/imageDrop/constants';

import {
  ArticlesFormFieldTitle,
  ArticlesFormFieldWrapper,
  ArticlesFormImageUploadBlock,
} from '../styled';

const ArticlesForm = (props) => {
  const {
    activeTabId,
    onLanguageTabChange,
    languageList,
    isInEditMode,
    selectedLanguage,
    articleStatuses,
    articleTypeList,
  } = props;

  const { t } = useTranslation();

  return (
    <form>
      <ArticlesFormFieldWrapper>
        <ArticlesFormFieldTitle>
          {t('articles.formFieldLabels.status')}
        </ArticlesFormFieldTitle>
        <ArticlesFormStatusField
          name="status"
          selectedLanguage={selectedLanguage}
          options={articleStatuses}
          disabled={!isInEditMode}
          required
        />
      </ArticlesFormFieldWrapper>

      <ArticlesFormFieldWrapper>
        <ArticlesFormFieldTitle>
          {t('articles.formFieldLabels.type')}
        </ArticlesFormFieldTitle>
        <ArticlesFormStatusField
          name="type"
          selectedLanguage={selectedLanguage}
          options={articleTypeList}
          required
          disabled={!isInEditMode}
        />
      </ArticlesFormFieldWrapper>

      <ArticlesFormLanguageTabs
        languages={languageList}
        onLanguageTabChange={onLanguageTabChange}
        activeTabId={activeTabId}
        disabled={!isInEditMode}
      />
      <ArticlesFormFieldWrapper>
        <ArticlesFormFieldTitle>
          {t('articles.formFieldLabels.image')}
        </ArticlesFormFieldTitle>
        <ArticlesFormImageUploadBlock>
          <ArticlesFormImageField
            name="images"
            filesLimit={UNLIMITED_FILES_LIMIT}
            allowedFileTypes={ALLOWED_FILE_TYPES}
            disabled={!isInEditMode}
          />
        </ArticlesFormImageUploadBlock>
      </ArticlesFormFieldWrapper>
    </form>
  );
};

export default ArticlesForm;
