import { connect } from 'react-redux';
import { compose } from 'redux';

import Content from './Content';

import {
  getAttractionsListThunk,
  editAttractionContentThunk,
  getAttractionThunk,
} from '../../../redux/attractionsReducers/attractions/attractionsActions';

const mapStateToProps = (state) => {
  return {
    appState: state.appReducer,
    attractionsState: state.attractionsReducer,
    settingsState: state.settingsReducer,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default compose(
  connect(mapStateToProps, {
    getAttractionsListThunk,
    editAttractionContentThunk,
    getAttractionThunk,
  })
)(Content);
