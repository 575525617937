import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Box, Typography } from '@mui/material';

import ArticlesFormLanguageTabs from './languageTabs/ArticlesFormLanguageTabs';
import ArticlesFormImageField from './formFields/ArticlesFormImageField';
import ArticlesFormStatusField from './formFields/ArticlesFormStatusField';

import {
  ControlPanelContainer,
  ArticlesFormButton,
  ArticlesFormSubmitButton,
  ArticlesFormFieldWrapper,
  ArticlesFormFieldTitle,
  ArticlesFormImageUploadBlock,
} from '../styled';

import { ALLOWED_FILE_TYPES } from '../../HomePageTab/constants';
import { UNLIMITED_FILES_LIMIT } from '../../HomePageTab/imageDrop/constants';

const ArticlesAddForm = ({
  languageList,
  isLoading,
  onSubmit,
  onCancel,
  articleStatuses,
  selectedLanguage,
  articleTypeList,
}) => {
  const { t } = useTranslation();
  const [activeTabId, setActiveTabId] = useState(
    languageList.parameters[0].value
  );

  const handleLanguageTabChange = (_, newValue) => setActiveTabId(newValue);

  const { handleSubmit } = useFormContext();

  return (
    <form>
      <Box mt="30px" display="flex" justifyContent="space-between">
        <Typography fontSize="20px" fontWeight={500} textTransform="uppercase">
          {t('articles.new_article_headline')}
        </Typography>
        <ControlPanelContainer>
          <ArticlesFormButton onClick={onCancel} label={t('cancel')} />
          <ArticlesFormSubmitButton
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            label={t('save')}
          />
        </ControlPanelContainer>
      </Box>

      <ArticlesFormFieldWrapper>
        <ArticlesFormFieldTitle>
          {t('articles.formFieldLabels.status')}
        </ArticlesFormFieldTitle>
        <ArticlesFormStatusField
          name="status"
          selectedLanguage={selectedLanguage}
          options={articleStatuses}
          required
        />
      </ArticlesFormFieldWrapper>

      <ArticlesFormFieldWrapper>
        <ArticlesFormFieldTitle>
          {t('articles.formFieldLabels.type')}
        </ArticlesFormFieldTitle>
        <ArticlesFormStatusField
          name="type"
          selectedLanguage={selectedLanguage}
          options={articleTypeList}
          required
        />
      </ArticlesFormFieldWrapper>

      {languageList && (
        <ArticlesFormLanguageTabs
          activeTabId={activeTabId}
          onLanguageTabChange={handleLanguageTabChange}
          languages={languageList}
        />
      )}

      <ArticlesFormFieldWrapper>
        <ArticlesFormFieldTitle>
          {t('articles.formFieldLabels.image')}
        </ArticlesFormFieldTitle>
        <ArticlesFormImageUploadBlock>
          <ArticlesFormImageField
            name="images"
            filesLimit={UNLIMITED_FILES_LIMIT}
            allowedFileTypes={ALLOWED_FILE_TYPES}
          />
        </ArticlesFormImageUploadBlock>
      </ArticlesFormFieldWrapper>
    </form>
  );
};

export default ArticlesAddForm;
