import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    button: {
        height: "36.5px"
    }
})

const FormButton = ({label, disabled, onClick, ...props}) => {
    const classes = useStyles();
    return (
        <Button 
        onClick={onClick}
        variant="outlined" 
        disabled={disabled} 
        type="button"
        className={classes.button}
        {...props}
    >
        {label}
    </Button>
    )
}

export default FormButton;