import { stopSubmit } from 'redux-form';
import {
  getPricesListApi,
  getCurrentPriceApi,
  addPricesApi,
  editPriceApi,
  deletePriceApi,
} from '../../api/attractionsApi.js';
const SET_PRICES = 'SET_PRICES';
const SET_CURRENT_PRICE = 'SET_CURRENT_PRICE';
const SET_IS_LOADING_LIST = 'SET_IS_LOADING_LIST';
const SET_IS_LOADING = 'SET_IS_LOADING';

const initialState = {
  pricesData: [],
  currentPrice: {},
  isLoadingList: false,
  isLoading: false,
};

const priceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICES:
      return {
        ...state,
        pricesData: [...action.payload.data],
      };
    case SET_CURRENT_PRICE:
      return {
        ...state,
        currentPrice: { ...action.payload.data },
      };
    case SET_IS_LOADING_LIST:
      return {
        ...state,
        isLoadingList: action.isLoadingList,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export const setPrices = (data) => {
  return { type: SET_PRICES, payload: { data } };
};
export const setIsLoadingList = (isLoadingList) => {
  return { type: SET_IS_LOADING_LIST, isLoadingList };
};
export const setCurrentPrice = (data) => {
  return { type: SET_CURRENT_PRICE, payload: { data } };
};
export const setIsLoading = (isLoading) => {
  return { type: SET_IS_LOADING, isLoading };
};

export let getPricesListThunk = (attractionId) => async (dispatch) => {
  let response = await getPricesListApi(attractionId);
  if (response.status >= 200 && response.status < 300) {
    dispatch(setPrices(response.data));
    dispatch(setIsLoadingList(true));
  }
};

export let getCurrentPriceThunk =
  (attractionId, pricingId) => async (dispatch) => {
    let response = await getCurrentPriceApi(attractionId, pricingId);
    if (response.status >= 200 && response.status < 300) {
      dispatch(setCurrentPrice(response.data));
      dispatch(setIsLoading(true));
    }
  };

export let addPricesThunk = (attractionId, data) => async (dispatch) => {
  let response = await addPricesApi(attractionId, data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('Prices', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export let editPricesThunk =
  (attractionId, pricingId, data) => async (dispatch) => {
    let response = await editPriceApi(attractionId, pricingId, data);
    if (response.status < 200 || response.status >= 300) {
      dispatch(stopSubmit('Prices', { _error: response.data.errors }));
      return Promise.reject(response.data.errors);
    }
  };

export let deletePriceThunk = (attractionId, pricingId) => async (dispatch) => {
  let response = await deletePriceApi(attractionId, pricingId);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};

export default priceReducer;
