import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import cl from './FormButtons.module.scss';

export const FormButton = ({label, disabled, onClick, ...props}) => {

    return (
        <Button 
        onClick={onClick}
        variant="outlined" 
        disabled={disabled} 
        type="button"
        className={cl.formButton}
        {...props}
    >
        {label}
    </Button>
    )
}

export const SaveButton = ({label, disabled, isLoading, disabledIcon, ...props}) => {
    
    return (
        <LoadingButton
        loading={isLoading}
        loadingPosition={!disabledIcon ? "start" : undefined}
        startIcon={!disabledIcon ? <SaveIcon /> : null}
        variant="contained"
        className={cl.saveButton}
        disabled={disabled} 
        type="submit"
        {...props}
        >
            {label}
        </LoadingButton>
    )
}


export const AddButton = ({label, disabled, onClick, ...props}) => {
    return (
        <Button
        variant="contained"
        onClick={onClick}
        className={cl.addButton}
        disabled={disabled} 
        {...props}
        >
            {label}
        </Button>
    )
}