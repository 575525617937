export const TABLE_HEADER_LABELS = [
  {
    id: 'status',
    isShowStatus: false,
    label: 'attractions.list.status',
  },
  {
    id: 'name',
    isShowStatus: true,
    label: 'attractions.list.name',
  },
  {
    id: 'title',
    isShowStatus: false,
    label: 'attractions.list.title',
  },
  {
    id: 'region',
    isShowStatus: true,
    label: 'attractions.list.region',
  },
  {
    id: 'area',
    isShowStatus: true,
    label: 'attractions.list.area',
  },
  {
    id: 'location',
    isShowStatus: true,
    label: 'attractions.list.location',
  },
];

export const STATUS_COLORS = {
  Active: '#FFA94C',
  Inactive: '#A0A0A0',
};
