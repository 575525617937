import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getSalesPointListThunk,
  setCurrentPoint,
  setSelectedRegion,
  setSelectedArea,
  setPageSize,
  setPageNumber,
  getSalesPointSearchThunk,
  setIsPointListLoading,
  setIsPointLoading,
  setAddElement,
  setStatusValue,
  setPointValue,
  setCategoryValue,
  setRegionValue,
  setAreaValue,
  setLocationValue,
} from '../../redux/salesPointsReducer/salesPointsActions';

import { setTitle } from '../../redux/appReducer';

import { getCategoryThunk } from '../../redux/settingsReducers/categoryReducer';

import { getPredefinedSettingsThunk } from '../../redux/settingsReducers/predefinedReducer';

import { getCompaniesListThunk } from '../../redux/usersReducers/company/companyActions';

import SalesPointListPage from './SalesPointListPage';

const mapStateToProps = (state) => {
  return {
    appState: state.appReducer,
    settingsState: state.settingsReducer,
    attractionsState: state.attractionsReducer,
    predefinedState: state.predefinedReducer,
    categoryState: state.categoryReducer,
    authState: state.authReducer,
    salesPointsReducer: state.salesPointsReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    setTitle,
    setSelectedRegion,
    setSelectedArea,
    setPageSize,
    setPageNumber,
    setAddElement,
    setStatusValue,
    setCategoryValue,
    setRegionValue,
    setAreaValue,
    setLocationValue,
    getCategoryThunk,
    getPredefinedSettingsThunk,
    getCompaniesListThunk,
    getSalesPointListThunk,
    setCurrentPoint,
    getSalesPointSearchThunk,
    setIsPointListLoading,
    setIsPointLoading,
    setPointValue,
  })
)(SalesPointListPage);
