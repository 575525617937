import { styled } from '@mui/system';
import { Box, ListItem, Typography, List } from '@mui/material';

import { ReduxTextField } from '../../../../components/reduxFormComponents/reduxFormComponents';

export const PersonListContainer = styled(Box)`
  width: 100%;
  display: flex;
  margin-top: 50px;
`;

export const ListHeader = styled(Box)`
  max-width: 215px;
  width: 100%;
`;

export const RowWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

export const RowTitleWrapper = styled(Box)`
  height: 40px;
  align-items: center;
  display: flex;
`;

export const RowTitle = styled(Typography)`
  max-width: 215px;
  width: 100%;
`;

export const PersonItemContainer = styled(ListItem)`
  padding: 0;
  max-width: 350px;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 10px;
`;

export const PersonItemFieldWrapper = styled(Box)`
  display: flex;
  margin-top: 16px;
  align-items: center;
  width: 100%;
`;

export const PersonItemTextField = styled(ReduxTextField)`
  width: 100%;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
    margin-left: 0;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
  }
`;

export const PersonList = styled(List)`
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 100%;
  max-width: 975px;
  height: 100%;
  max-height: 255px;
`;

export const PersonListControlPanel = styled(Box)`
  display: flex;
  width: 10%;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
`;

export const PersonItemControlPanel = styled(Box)`
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;
