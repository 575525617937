import { useTranslation } from 'react-i18next';

import {
  MainImageFormDatePicker,
  MainImageFormTextField,
  MainImageFormImageDrop,
} from './formFields';

import {
  MainImageFormWrapper,
  MainImageFormFieldWrapper,
  MainImageFormFieldTitle,
  ImageDropWrapper,
  MainImageFormUploadInstructionWrapper,
  MainImageFormImageUploadBlock,
} from '../styled';

import { DEFAULT_TEXT_FIELD_MAX_LENGTH } from '../../../../consts/validation';
import { ALLOWED_FILE_TYPES } from '../constants';
import { useSelector } from 'react-redux';
import MainImageFormTextLanguageTabs from '../MainImageFormTextLanguageTabs';
import { useState } from 'react';

const MainImageForm = (props) => {
  const { isInEditMode } = props;

  const { t } = useTranslation();
  const languages = useSelector(state => state.appReducer.selectedDestination.languages);
  
  const [currentTabId, setCurrentTabId] = useState(languages[0]);
  const handleChangeLanguageTab = (_, newValue) => setCurrentTabId(newValue);

  const formLabels = t('mainImage.tableLabels', {
    returnObjects: true,
  });
  return (
    <MainImageFormWrapper>
      <form>
        <MainImageFormFieldWrapper>
          <MainImageFormFieldTitle>{formLabels[0]}</MainImageFormFieldTitle>
          <MainImageFormDatePicker disabled={!isInEditMode} name="startDate" />
        </MainImageFormFieldWrapper>
        <MainImageFormTextLanguageTabs
          formLabels = {formLabels}
          name="subjectTitle"
          languages={languages}
          disabled={!isInEditMode}
          handleChangeLanguageTab={handleChangeLanguageTab}
          currentTabId={currentTabId}
          maxLength={DEFAULT_TEXT_FIELD_MAX_LENGTH}
        />
        <MainImageFormFieldWrapper>
          <MainImageFormFieldTitle>{formLabels[2]}</MainImageFormFieldTitle>
          <MainImageFormImageUploadBlock>
            <MainImageFormUploadInstructionWrapper>
              {t('settings_page.destination.main_image_text')}
            </MainImageFormUploadInstructionWrapper>
            <ImageDropWrapper>
              <MainImageFormImageDrop
                name="image"
                disabled={!isInEditMode}
                columns={1}
                filesLimit={1}
                allowedFileTypes={ALLOWED_FILE_TYPES}
              />
            </ImageDropWrapper>
          </MainImageFormImageUploadBlock>
        </MainImageFormFieldWrapper>
      </form>
    </MainImageFormWrapper>
  );
};

export default MainImageForm;
