import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';

import {
  SALES_POINT_CONTENT_DEFAULT_VALUES,
  SALES_POINT_SETUP_FORM_DEFAULT_VALUES,
  DEFAULT_PAGE_SIZE,
} from './constants';
import SetupForm from './SetupForm';

import { salesPointTabs } from '../salesPointTabs';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import { useNavigationPrompt } from '../../../hooks/navigation';
import { SALES_POINTS_PAGE_ROUTE } from '../constants';

const ATTRACTION_MANAGER_ROLE = 'attraction';

const Setup = (props) => {
  const {
    appReducer,
    tagsReducer,
    facilitiesReducer,
    categoryReducer,
    predefinedReducer,
    settingsReducer,
    companyReducer,
    authState,
    salesPointsReducer,
    createSalesPointThunk,
    getSalesPointListThunk,
    getSalesPointThunk,
    updateSalesPointThunk,
    deleteSalesPointThunk,
    setAddElement,
    setCurrentPoint,
  } = props;
  const { currentPoint } = salesPointsReducer;
  const pageNumber = salesPointsReducer.pageNumber;

  const { t } = useTranslation();
  const { addError } = useAPIError();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isEdit, setIsEdit] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const editPointDefaultValues = useMemo(() => {
    return {
      ...currentPoint,
      type: currentPoint.type === null ? '' : currentPoint.type,
      entryType: currentPoint.entryType === null ? '' : currentPoint.entryType,
      mostPopular: currentPoint.mostPopularInDestinationOrder || 0,
      mapLatitude:
        (currentPoint.geoCoordinate && currentPoint.geoCoordinate.lat) || 0,
      mapLongtitude:
        (currentPoint.geoCoordinate && currentPoint.geoCoordinate.lng) || 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPoint.id]);

  const hasAttractionManagerRole = Array.isArray(authState.role)
    ? authState.role.some((currentRole) =>
        currentRole.includes(ATTRACTION_MANAGER_ROLE)
      )
    : authState.role === ATTRACTION_MANAGER_ROLE;

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...SALES_POINT_SETUP_FORM_DEFAULT_VALUES,
    },
  });

  const {
    watch,
    reset,
    setValue,
    formState: { isSubmitted, isDirty },
  } = formMethods;

  const blockNavigation = isDirty && !isSubmitted;

  useNavigationPrompt(blockNavigation);

  const locationValue = watch('locationId');
  const regionValue = watch('regionId');
  const areaValue = watch('areaId');
  const statusValue = watch('status');
  const mapLatitudeValue = watch('mapLatitude');
  const mapLongtitudeValue = watch('mapLongtitude');
  const [coordinates, setCoordinates] = useState({
    lat: mapLatitudeValue,
    lng: mapLongtitudeValue,
  });

  const handleUpdateCoordinates = (e) => {
    setCoordinates((prevState) => ({
      ...prevState,
      lat: +e.latLng.lat(),
      lng: +e.latLng.lng(),
    }));
    setValue('mapLatitude', +e.latLng.lat());
    setValue('mapLongtitude', +e.latLng.lng());
  };

  const handleChangeMapLatitude = (value) => {
    setCoordinates((prevState) => ({
      ...prevState,
      lat: Number(value),
    }));
  };

  const handleChangeMapLongtitude = (value) => {
    setCoordinates((prevState) => ({
      ...prevState,
      lng: Number(value),
    }));
  };

  const handleCancelChanges = () => {
    reset(id ? editPointDefaultValues : SALES_POINT_SETUP_FORM_DEFAULT_VALUES, {
      keepDefaultValues: true,
    });
    setIsEdit(false);
  };

  const handleSubmit = (data) => {
    setIsSave(true);

    const submitData = {
      ...data,
      address: data.address || null,
      areaId: data.areaId || null,
      companyId: parseInt(data.companyId) || null,
      email: data.email || null,
      locationId: data.locationId || null,
      phone: data.phone || null,
      regionId: data.regionId || null,
      website: data.website || null,
      ...SALES_POINT_CONTENT_DEFAULT_VALUES,
      type: data.type || null,
      regionOrder: data.regionOrder || null,
      areaOrder: data.areaOrder || null,
      locationOrder: data.locationOrder || null,
      destinationId: appReducer.selectedDestination.id,
      mapCoordinate: {
        lat: data.mapLatitude || 0,
        lng: data.mapLongtitude || 0,
      },
    };

    if (id) {
      const updateData = {
        ...submitData,
        id: currentPoint.id,
        title: currentPoint.title,
        subtitle: currentPoint.subtitle,
        introduction: currentPoint.introduction,
        description: currentPoint.description,
        tips: currentPoint.tips,
        highlights: currentPoint.highlights,
        directions: currentPoint.directions,
        appImages: currentPoint.appImages,
        webImages: currentPoint.webImages,
      };

      updateSalesPointThunk(salesPointsReducer.currentPoint.id, updateData)
        .then(() => {
          getSalesPointThunk(id);
          setIsEdit(false);
          setIsSave(false);
          addError(
            `${t('attractions.setup.error_edit_point_text')}`,
            'Success'
          );
        })
        .catch((error) => {
          setIsSave(false);
          addError(
            `${t('attractions.setup.error_not_edit_point_text')}`,
            'Error',
            error
          );
        });
    } else {
      createSalesPointThunk(submitData)
        .then((response) => {
          getSalesPointListThunk({
            destinationId: appReducer.selectedDestination.id,
            pageNumber,
            pageSize: DEFAULT_PAGE_SIZE,
          });

          setAddElement(true);
          setCurrentPoint(response.data);
          navigate(
            `${SALES_POINTS_PAGE_ROUTE}/${response.data.id}/${salesPointTabs[0].path}`
          );
          setIsEdit(false);
          setIsSave(false);
          addError(`${t('attractions.setup.error_add_point_text')}`, 'Success');
        })
        .catch((error) => {
          setIsSave(false);
          addError(
            `${t('attractions.setup.error_not_add_point_text')}`,
            'Error',
            error
          );
        });
    }
  };

  const handleDeletePoint = () => {
    deleteSalesPointThunk(salesPointsReducer.currentPoint.id)
      .then(() => {
        navigate(SALES_POINTS_PAGE_ROUTE);
        setShowAlert(false);
        setIsEdit(false);
        addError(
          `${t('attractions.setup.error_deleted_point_text')}`,
          'Success'
        );
      })
      .catch((error) => {
        addError(
          `${t('attractions.setup.error_not_deleted_point_text')}`,
          'Error',
          error
        );
        setShowAlert(false);
      });
  };

  useEffect(() => {
    if (id && editPointDefaultValues?.name) {
      reset(editPointDefaultValues);
      setCoordinates((prevState) => ({
        ...prevState,
        lat: editPointDefaultValues.mapLatitude,
        lng: editPointDefaultValues.mapLongtitude,
      }));
    }
  }, [editPointDefaultValues, id, reset]);

  return (
    <Box sx={{ width: '100%' }}>
      {id && !editPointDefaultValues?.name ? (
        <Box component="div" sx={{ width: '100%', position: 'relative' }}>
          <CircularProgress
            sx={{ position: 'absolute', right: '50%', top: '300px' }}
          />
        </Box>
      ) : (
        <FormProvider {...formMethods}>
          <SetupForm
            handleDeleteAttraction={handleDeletePoint}
            settingsState={settingsReducer}
            appState={appReducer}
            salesPointsReducer={salesPointsReducer}
            categoryState={categoryReducer}
            facilityState={facilitiesReducer}
            tagsState={tagsReducer}
            predefinedState={predefinedReducer}
            companyState={companyReducer}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            isSave={isSave}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            hasAttractionManagerRole={hasAttractionManagerRole}
            locationValue={locationValue}
            regionValue={regionValue}
            areaValue={areaValue}
            statusValue={statusValue}
            onSubmit={handleSubmit}
            handleCancelChanges={handleCancelChanges}
            handleUpdateCoordinates={handleUpdateCoordinates}
            coordinates={coordinates}
            handleChangeMapLatitude={handleChangeMapLatitude}
            handleChangeMapLongtitude={handleChangeMapLongtitude}
          />
        </FormProvider>
      )}
    </Box>
  );
};

export default Setup;
