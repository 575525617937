import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { reset } from 'redux-form';
import { useNavigate, useParams } from 'react-router-dom';

import TagsForm from './TagsForm';

import { TagFormWrapper } from './styled';

import useAPIError from '../../../apIErrorProvider/useAPIError';
import { CONTENT_LANGUAGES } from '../../../consts/consts';
import {
  getTagsThunk,
  getSearchTagsThunk,
  deleteTagsThunk,
  editTagsThunk,
  setCurrentTag,
} from '../../../redux/settingsReducers/tagsReducer';
import { TAGS_PAGE_ROUTE } from './constants';

const TagEditFormContainer = (props) => {
  const {
    tagsState,
    globalSettings,
    selectedLanguage,
    editTagsThunk,
    getTagsThunk,
    setCurrentTag,
    deleteTagsThunk,
  } = props;

  const { addError } = useAPIError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [showAlert, setShowAlert] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showSaveAnimation, setShowSaveAnimation] = useState(false);

  const handleFormSubmit = (values) => {
    setShowSaveAnimation(true);
    setIsInEditMode(false);

    const updateData = {
      id: tagsState.currentTag.id,
      name: values,
    };

    editTagsThunk(tagsState.currentTag.id, updateData)
      .then(() => {
        getTagsThunk();
        setShowSaveAnimation(false);
        addError(`${t('settings_page.tags.error_edit_text')}`, 'Success');
        navigate(TAGS_PAGE_ROUTE);
      })
      .catch((error) => {
        addError(
          `${t('settings_page.tags.error_not_edit_text')}`,
          'Error',
          error
        );
        setShowSaveAnimation(false);
      });
  };

  const handleFormCancel = () => {
    setIsInEditMode(false);
    dispatch(reset('Tags'));
  };

  const handleCloseSuccess = () => {
    setIsInEditMode(false);
    setShowSaveAnimation(true);

    deleteTagsThunk(tagsState.currentTag.id)
      .then(() => {
        setShowAlert(false);
        getTagsThunk();
        addError(`${t('settings_page.tags.error_deleted_text')}`, 'Success');
        navigate(TAGS_PAGE_ROUTE);
        setShowSaveAnimation(false);
      })
      .catch((error) => {
        setShowSaveAnimation(false);
        addError(
          `${t('settings_page.tags.error_not_deleted_text')}`,
          'Error',
          error
        );
      });
  };

  useEffect(() => {
    if (id) {
      const currentTag = tagsState.tagsData.find((tag) => tag.id === id);
      setCurrentTag(currentTag);
    }

    return () => setIsInEditMode(false);
  }, [id, setCurrentTag, tagsState.tagsData]);

  return (
    <TagFormWrapper>
      {tagsState.currentTag?.id && (
        <TagsForm
          onSubmit={handleFormSubmit}
          tagsState={tagsState}
          isEdit={isInEditMode}
          setIsEdit={setIsInEditMode}
          updateTag={true}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          handleCloseSuccess={handleCloseSuccess}
          languages={globalSettings.filter(
            (item) => item.name.en === CONTENT_LANGUAGES
          )}
          selectedLanguage={selectedLanguage}
          isSave={showSaveAnimation}
          onCancel={handleFormCancel}
        />
      )}
    </TagFormWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    tagsState: state.tagsReducer,
    globalSettings: state.appReducer.globalSettingsData,
    selectedLanguage: state.appReducer.selectedLanguage,
  };
};

export default connect(mapStateToProps, {
  getTagsThunk,
  getSearchTagsThunk,
  deleteTagsThunk,
  editTagsThunk,
  setCurrentTag,
})(TagEditFormContainer);
