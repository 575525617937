import { stopSubmit } from 'redux-form';
import {
  addAttractionReviewApi,
  getAttractionReivewsListApi,
  getFilteredAttractionReviewsApi,
  editAttractionReviewApi,
  deleteAttractionReviewApi,
} from '../../../api/attractionsApi.js';

export const SET_REVIEWS_LIST = 'SET_REVIEWS_LIST';
export const IS_REVIEW_LIST_LOADING = 'reviews/IS_REVIEW_LIST_LOADING';

//Action Creator
export const setReviewsList = (data) => {
  return { type: SET_REVIEWS_LIST, payload: { data } };
};

export const setIsDataLoading = (data) => {
  return { type: IS_REVIEW_LIST_LOADING, payload: { data } };
};

//Thunks
export const getAttractionReivewsListThunk =
  (attractionId) => async (dispatch) => {
    dispatch(setIsDataLoading(true));
    const response = await getAttractionReivewsListApi(attractionId);
    if (response.status === 200) {
      dispatch(setReviewsList(response.data));
      dispatch(setIsDataLoading(false));
    }
  };

export const getFilteredAttractionReviewsThunk =
  (filter) => async (dispatch) => {
    dispatch(setIsDataLoading(true));
    const response = await getFilteredAttractionReviewsApi(filter);
    if (response.status === 200) {
      dispatch(setReviewsList(response.data));
      dispatch(setIsDataLoading(false));
    }
  };

export const addAttractionReviewThunk = (data) => async (dispatch) => {
  const response = await addAttractionReviewApi(data);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('ReviewForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const editAttractionReviewThunk = (reviewData) => async (dispatch) => {
  const response = await editAttractionReviewApi(reviewData);
  if (response.status < 200 || response.status >= 300) {
    dispatch(stopSubmit('ReviewForm', { _error: response.data.errors }));
    return Promise.reject(response.data.errors);
  }
};

export const deleteAttractionReviewThunk = (reviewId) => async () => {
  const response = await deleteAttractionReviewApi(reviewId);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response.data.errors);
  }
};
