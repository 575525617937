export const formatLanguageFieldsData = (values) =>
  Object.keys(values).reduce(
    (acc, item) => {
      if (Array.isArray(values[item])) {
        if (item !== 'images') {
          const currLangObj = values[item][0];

          if (currLangObj.title) acc.title[item] = currLangObj.title.trim();

          if (currLangObj.text) acc.text[item] = currLangObj.text.trim();
        }
      }

      return acc;
    },
    { title: {}, text: {} }
  );
