import { Box, Dialog, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  closeIcon: {
    position: 'absolute',
    zIndex: 2,
    top: '15px',
    right: '19px',
    width: '34px',
    height: '34px',
    padding: '7px',
    borderRadius: '50%',
    cursor: 'pointer',

    '&:active': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
});

const RedeemCodePopup = ({ isOpen, title, children, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: '900px',
          width: '100%',
        },
      }}
    >
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        padding="20px"
        position="relative"
      >
        <Box>
          <Typography
            fontSize="16px"
            fontWeight="bold"
            textTransform="uppercase"
            lineHeight="24px"
            marginBottom="30px"
          >
            {title}
          </Typography>
        </Box>

        <Box>{children}</Box>
        <IconButton className={classes.closeIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default RedeemCodePopup;
