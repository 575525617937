import { connect } from 'react-redux';
import { compose } from 'redux';

import Prices from './Prices';

import {
  addPricesThunk,
  getPricesListThunk,
  getCurrentPriceThunk,
  editPricesThunk,
  deletePriceThunk,
} from '../../../redux/attractionsReducers/priceReducer';

const mapStateToProps = (state) => {
  return {
    appState: state.appReducer,
    settingsState: state.settingsReducer,
    attractionsState: state.attractionsReducer,
    priceState: state.priceReducer,
  };
};

export default compose(
  connect(mapStateToProps, {
    addPricesThunk,
    getPricesListThunk,
    getCurrentPriceThunk,
    editPricesThunk,
    deletePriceThunk,
  })
)(Prices);
