import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Box, Button, Typography, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  TimePickerInput,
  DatePickerInput,
  SpecialDateClosedCheckbox,
} from './FormComponents';
import { required } from './validate';
import { useTranslation } from 'react-i18next';

const styleWeeksModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4.5px',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  '& .MuiTextField-root': {
    margin: 0,
  },
};

const SpecialDateModal = (props) => {
  const { t } = useTranslation();

  return (
      <div>
        <Modal
            open={props.openSpecialDateModal}
            onClose={props.handleSpecialDateCloseModal}
        >
          <Box
              sx={styleWeeksModal}
              component="form"
              onSubmit={props.handleSubmit}
          >
            <Typography variant="h6" component="h6">
              {props.currentDay}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box margin="20px 0 15px" width="100%">
                <Field
                    label={t('attractions.season.from')}
                    name="validFrom"
                    required
                    component={TimePickerInput}
                    validate={!props.isSpecialDateClosed && [required]}
                    disabled={props.isSpecialDateClosed}
                />
              </Box>
              {(props.selectValue === 'OpeningHours' || props.selectValue === 'AllWeek') && (
                  <Box width="100%">
                    <Field
                        label={t('attractions.season.to')}
                        name="validTo"
                        required
                        component={TimePickerInput}
                        validate={!props.isSpecialDateClosed && [required]}
                        disabled={props.isSpecialDateClosed}
                    />
                  </Box>
              )}

              <Box width="100%" mb="15px">
                <Field
                    name="isSpecialDateClosed"
                    component={SpecialDateClosedCheckbox}
                    value={props.isSpecialDateClosed}
                    label={t('attractions.season.closed')}
                    onChange={props.handleChangeSpecialDateIsClosed}
                />
              </Box>
              <Box mb="10px" width="100%">
                <Field
                    name="date"
                    required
                    component={DatePickerInput}
                    periodViews={['day']}
                    formatViews="dd/MM/yyyy"
                    validate={[required]}
                />
              </Box>
            </Box>
            <Button
                type="submit"
                variant="contained"
                sx={{ color: '#fff', marginTop: '10px' }}
            >
              {t('attractions.season.setup_time')}
            </Button>
            <Box
                sx={{
                  position: 'absolute',
                  right: '3px',
                  top: '3px',
                  cursor: 'pointer',
                }}
                onClick={props.handleSpecialDateCloseModal}
            >
              <Close />
            </Box>
          </Box>
        </Modal>
      </div>
  );
};

export default reduxForm({
  form: 'SpecialDateModal',
})(SpecialDateModal);