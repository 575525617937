import { getAttractionVisits, getVisitsList } from '../../api/visitsApi.js';

export const SET_VISITS_LIST = 'SET_VISITS_LIST';
export const LOAD_VISITS_LIST = 'LOAD_VISITS_LIST';
export const IS_VISITS_LOADING = 'IS_VISITS_LOADING';
export const IS_ITEM_UPDATING = 'IS_ITEM_UPDATING';
export const SET_VISITS_PAGINATION_SETTINGS = 'SET_VISITS_PAGINATION_SETTINGS';

export const setVisitsList = (data) => {
  return { type: SET_VISITS_LIST, payload: { data } };
};
export const loadVisitsList = (data) => {
  return { type: LOAD_VISITS_LIST, payload: { data } };
};

export const setIsVisitsLoading = (isLoading) => {
  return { type: IS_VISITS_LOADING, isLoading };
};

export const setIsItemUpdating = (isUpdating) => {
  return { type: IS_ITEM_UPDATING, isUpdating };
};

export const setPaginationSettings = (data) => {
  return { type: SET_VISITS_PAGINATION_SETTINGS, payload: { data } };
};

export const getVisitsListThunk =
  (
    destinationId,
    pageNumber,
    pageSize,
    searchQuery = '',
    date = '',
    attraction = '',
    dateSortDirection = ''
  ) =>
  async (dispatch) => {
    dispatch(setIsVisitsLoading(true));

    const response = await getVisitsList(
      destinationId,
      pageNumber,
      pageSize,
      searchQuery,
      date,
      attraction,
      dateSortDirection
    );

    if (response.status === 200) {
      dispatch(setVisitsList(response.data.items));
      dispatch(
        setPaginationSettings({
          totalCount: response.data.totalCount,
          totalPages: response.data.totalPages,
          pageNumber: pageNumber,
          pageNumberLazy: 1,
          pageSize: pageSize,
        })
      );
      dispatch(setIsVisitsLoading(false));
    }
  };

export const loadVisitsListThunk =
  (
    destinationId,
    pageNumberLazy,
    pageSize,
    searchQuery = '',
    date = '',
    attraction = '',
    dateSortDirection = ''
  ) =>
  async (dispatch) => {
    const response = await getVisitsList(
      destinationId,
      pageNumberLazy,
      pageSize,
      searchQuery,
      date,
      attraction,
      dateSortDirection
    );

    if (response.status === 200) {
      dispatch(loadVisitsList(response.data.items));
    }
  };

export const getAttractionVisitsThunk =
  (
    attractionId,
    pageNumber,
    pageSize,
    searchQuery = '',
    date = '',
    attraction = '',
    dateSortDirection = ''
  ) =>
  async (dispatch) => {
    dispatch(setIsVisitsLoading(true));

    const response = await getAttractionVisits(
      attractionId,
      pageNumber,
      pageSize,
      searchQuery,
      date,
      attraction,
      dateSortDirection
    );

    if (response.status === 200) {
      dispatch(setVisitsList(response.data.items));
      dispatch(
        setPaginationSettings({
          totalCount: response.data.totalCount,
          totalPages: response.data.totalPages,
          pageNumber: pageNumber,
          pageSize: pageSize,
        })
      );
      dispatch(setIsVisitsLoading(false));
    }
  };
