import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Autocomplete } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { DEFAULT_ATTRACTION_VALUE } from '../../constants';
import { StyledVisitsFilterAttractionsField } from '../../styled';

import { validateTextFieldValue } from '../../../users/visitorsTab/helpers';

const VisitsFilterAttractionsField = ({
  attractionsList,
  attractionInputValue,
  handleChangeAttractionInputValue,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const handleChangeField = (fieldName, value) => {
    setValue(fieldName, value);
  };

  const handleOnBlur = () => {
    const findMatch = attractionsList.find(
      (attraction) => attraction.name === attractionInputValue.name
    );

    if (!findMatch) handleChangeAttractionInputValue(DEFAULT_ATTRACTION_VALUE);
  };

  return (
    <Controller
      control={control}
      name="attractionName"
      render={({ field: { name } }) => {
        return (
          <Autocomplete
            sx={{
              width: 300,
            }}
            options={attractionsList}
            getOptionLabel={(option) => option.name}
            onChange={(_, newValue) => {
              handleChangeField(name, newValue?.name || '');
              handleChangeAttractionInputValue((prevState) => ({
                ...prevState,
                id: newValue?.id,
                name: newValue?.name || '',
              }));
            }}
            clearIcon={
              !!attractionInputValue.name.length && (
                <ClearIcon fontSize="small" />
              )
            }
            inputValue={attractionInputValue.name}
            value={attractionInputValue}
            clearOnEscape
            onBlur={handleOnBlur}
            renderInput={(params) => (
              <StyledVisitsFilterAttractionsField
                {...params}
                fullWidth
                size="small"
                placeholder={t('visitsPage.visitsFilterFields.attractions')}
                onChange={(e) =>
                  handleChangeAttractionInputValue((prevState) => ({
                    ...prevState,
                    name: validateTextFieldValue(e.target.value),
                  }))
                }
              />
            )}
          />
        );
      }}
    />
  );
};

export default VisitsFilterAttractionsField;
